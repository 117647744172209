import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import TableComponent from "../SharedComponents/Table";
import { repTalentConfig, renderTalentRepList } from "./config";
import { MDBRow } from "mdb-react-ui-kit";
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import "./Representative.scss";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { MDBContainer, MDBCol } from "mdbreact";
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { talentAnimationList } from '../../../constants/NavList';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';

class RepresentativeProfileTalent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTalentModal: false,
      renderList: this.props?.renderList,
      config: repTalentConfig,
      rowsPerPage: 10,
      page: 0,
      selectedTalentRep: null,
      is_delete: 0,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    }, () => {
      this.props?.handleChangePage(newPage)
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10), page: 0,
      renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.props?.repTalentList)
    });
  }

  handleTalentModal = () => {
    this.setState({ addTalentModal: true })
  }

  hideTalentModal = () => {
    this.setState({ addTalentModal: false })
  }

  handleDeleteRow = (item, node, is_delete = 1) => {
    this.setState({ selectedTalentRep: node.talent_rep_company_mtrx_id, is_delete: 1 })
    this.setState({ postInitiated: true })
    let payload = {};
    payload['talent_id'] = node.talent_id;
    payload['representative_id'] = this.props?.repId;
    payload['company_id'] = this.props?.RepCompanyId;
    payload['is_delete'] = is_delete
    is_delete === 1 ? payload['talent_rep_company_mtrx_id'] = node.talent_rep_company_mtrx_id : payload['talent_rep_company_mtrx_id'] = null;
    this.setState({ isPosting: true })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostTalentRep`,
      payload,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        this.props?.getrepresentativeDetails()
      },
        (err) => {
          console.log('Error is submitting Talent' + err);
          this.props.fieldChanged(true, "fail");
          this.setState({ isPosting: false, postInitiated: false })
        });
  }

  navigationCallback = (dataItem, node) => {
    this.setState({ openTalentModal: true, selectedTalentId: dataItem.talent_id })
  }

  handleHideTalentPopup = () => {
    this.setState({ openTalentModal: false });
  }

  render() {
    return (
      <div>
        <MDBContainer fluid className="TalentReprContainer p-0">
          <MDBRow id={"talentTable"}>
            <TableComponent
              list={this.props?.renderList || []}
              config={this.state.config}
              handleDelCallBack={(item, node) => this.handleDeleteRow(item, node)}
              sortCallback={this.props?.handleTableColumnClick}
              hyperLinkNavigationCallback={this.navigationCallback}
            />
          </MDBRow>

          <MDBRow className="Pagination">
            <MDBCol md={9}>
              {this.props?.repId ? <BasicButton
                variant="outlined"
                className="repBtn"
                type="inline"
                icon={"plus-circle"}
                text="Add Talent"
                align={"start"}
                onClick={this.handleTalentModal}
              /> : <BasicButton
                variant="outlined"
                className="repBtn"
                type="inline"
                icon={"plus-circle"}
                text="Add Talent"
                align={"start"}
                onClick={this.handleTalentModal}
                disabled={true}
              />}
            </MDBCol>
            <MDBCol>
              <TablePagination
                component="div"
                count={this.props?.repTalentList?.length || 0}
                rowsPerPageOptions={[]}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                align={"end"}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {this.state.addTalentModal &&
          <MainViewModalComponent
            open={this.state.addTalentModal}
            handleClose={this.hideTalentModal}
            headerText={"Add Talent"}
            mode={"New"}
            modalName={"AddTalentModal"}
            repId={this.props?.repId}
            RepCompanyId={this.props?.representativeDetails?.company_id}
            selectedTalentRep={this.state.selectedTalentRep}
            is_delete={this.state.is_delete}
            toggleRefresh={this.props?.getrepresentativeDetails}
          />}
        {this.state.openTalentModal &&
          <ModalComponent
            navFuns={this.functions}
            open={this.state.openTalentModal}
            handleClose={this.handleHideTalentPopup}
            tabList={talentAnimationList}
            bodyText={"Search for Talent to view their Data, or create a new Talent"}
            searchPlaceholder={"Search Talent by name or SSN"}
            headerText={"Talent"}
            addButtonText={"New Talent"}
            objectType={'TALENT'}
            dataUrl='talentDetails'
            talentId={this.state.selectedTalentId}
            {...this.props}
          />
        }
      </div>
    );
  }
}
export default withUserContext(RepresentativeProfileTalent);
