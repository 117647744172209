import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import './EditSendPaymentsTo.scss'

class EditSendPaymentsTo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: null,
            selectedEntity: null,
            isPosting: false,
            postInitiated: false,
            talentOptions: [],
            addressOptions: [],
            backUpTalentOptions: [],
            isFetchingData: false,
            notes: null,
        }

    }

    componentDidMount = () => {
        this.getEditSendPaymentToDetails()
    }


    getEditSendPaymentToDetails = () => {
        this.setState({ isFetchingData: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContactPaymentsTo?performer_deal_id=${this.props?.deal_id}`)
            .then(response => {
                let talentsArray = response?.data?.map(item => {
                    let newItem = { ...item }
                    // newItem.is_active = item?.address?.length > 0 ? 1 : 0
                    newItem.value = item?.code
                    newItem.text = item?.entity_type === 'T' ? `Talent(${item?.selected_alias_name ? item?.selected_alias_name : ''})` :
                        item?.entity_type === 'R' ? `Representative(${item?.selected_alias_name ? item?.selected_alias_name : ''})` :
                            `(Guardians${item?.selected_alias_name ? item?.selected_alias_name : ''})`
                    newItem.address = item?.address?.map((ele => {
                        let newEle = { ...ele }
                        newEle.value = `${ele?.code ? ele?.code : ''}${ele?.primary_address_id ? ele?.primary_address_id : ''}`
                        newEle.label = ele?.entity_full_address || null
                        return newEle
                    }))
                    return newItem

                })
                let selectedEntity = talentsArray?.filter(i => i?.entity_id == this.props?.sendToPaymentList?.[0]?.entity_id);
                let selectedAddress = selectedEntity?.[0]?.address?.filter(i => i?.entity_full_address == this.props?.sendToPaymentList?.[0]?.address)?.[0]?.value || null;
                this.setState({
                    talentOptions: talentsArray,
                    backUpTalentOptions: response.data,
                    isFetchingData: false,
                    selectedEntity: selectedEntity?.[0] || null,
                    addressOptions: selectedEntity?.[0]?.address || []

                }, () => {
                    this.setState({ selectedAddress })
                });

            },
                (err) => {
                    console.log("Error in fetching Production Details:", err)
                })
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let postObj = {}
        if (this.state.selectedAddress && this.state.selectedEntity?.value) {
            let addressInfo = this.state.addressOptions?.find(item => item?.value === this.state.selectedAddress)
            postObj.selected_alias_name = this.state.selectedEntity?.selected_alias_name
            postObj.project_id = this.props?.project_id || null
            postObj.performer_deal_id = this.props?.deal_id || null
            postObj.is_send_payment_to = 1,
                postObj.entity_id = this.state.selectedEntity?.entity_id || null
            postObj.entity_type = this.state.selectedEntity?.entity_type || null
            postObj.address_type = addressInfo?.address_type || null
            postObj.entity_full_address = addressInfo?.entity_full_address || null
            postObj.entity_notes = this.state.notes || null
            postObj.entity_address = addressInfo?.entity_address || null
            postObj.entity_state =  addressInfo?.entity_state || null
            postObj.entity_city =  addressInfo?.entity_city || null
            postObj.entity_country =  addressInfo?.entity_country || null
            postObj.entity_zip =  addressInfo?.entity_zip || null
            this.postRepresentativeDetails(postObj)
        }
    }

    postRepresentativeDetails = (sendPaymentsTo) => {
        this.setState({ isPosting: true, postInitiated: false })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePerformerDeal', sendPaymentsTo)
            .then((response) => {
                if (response.data.error) {
                    this.props?.notificationComponent(true, 'fail')
                } else {
                    if (this.props?.toggleRefreshPage) {
                        this.props.toggleRefreshPage()
                    }
                    this.props?.notificationComponent(true, 'success')
                }
                this.setState({ isPosting: false })
            }, (err) => {
                this.setState({ isPosting: false })
                this.props?.notificationComponent(true, 'fail')
            });
    }

    onChange = (value) => {
        let selectedEntity = this.state.talentOptions?.find(item => item?.value === value?.value)
        this.setState({
            selectedEntity: selectedEntity,
            selectedAddress: selectedEntity?.address?.length > 1 ? selectedEntity?.address?.find(item => item?.is_primary === 1)?.value || null : selectedEntity?.address[0]?.value || null,
            addressOptions: selectedEntity?.address
        })

    }

    render() {
        return (
            <div className="loaderEditSendPaymentsTo">
                {this.state.isFetchingData ? <CircularProgress className="loadEditSendPaymentsTo" /> :
                    <MDBContainer className="mt-5 editSendPaymentsTo">
                        <MDBRow>
                            <MDBCol md={6} className="talentDrop">
                                <SearchSelectField
                                    id={"talentSendPaymentsTo"}
                                    label={"Talent"}
                                    value={this.state.selectedEntity || []}
                                    options={this.state?.talentOptions || []}
                                    // limitTags={4}
                                    onChange={(e, value) => {
                                        this.props.fieldChanged(true, "change");
                                        this.onChange(value)
                                    }}
                                    // multiple={true}
                                    width={'100%'}
                                    isMandatory={true}
                                    placeholder={"-Select-"}
                                />
                                {/* <SelectField
                                    label={"Talent"}
                                    value={this.state.selectedEntity?.value || null}
                                    options={this.state.talentOptions}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    onChange={(e) => {
                                        this.props.fieldChanged(true, "change");
                                        let selectedEntity = this.state.talentOptions?.find(item => item?.value === e.target.value)
                                        this.setState({
                                            selectedEntity: selectedEntity,
                                            selectedAddress: selectedEntity?.address?.find(item => item?.is_primary === 1)?.value || null,
                                            addressOptions: selectedEntity?.address
                                        })
                                    }}
                                /> */}
                            </MDBCol>
                            <MDBCol md={6} className="addressDrop">
                                <SelectField
                                    label={"Address"}
                                    value={this.state.selectedAddress || null}
                                    options={this.state.addressOptions}
                                    onChange={(e) => {
                                        this.props.fieldChanged(true, "change");
                                        this.setState({ selectedAddress: e.target.value })
                                    }}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    placeHolderText={'-Select Address-'}
                                />
                            </MDBCol>
                            <MDBCol md={12} className="addressDrop">
                                <BasicTextArea
                                    label={"Notes"}
                                    value={this.state.notes || null}
                                    onChange={(e) => {
                                        this.props.fieldChanged(true, "change");
                                        this.setState({ notes: e.target.value })
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-5'>
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                    type="inline"
                                    onClick={() => this.handleSubmit()}
                                    className='FR MR'
                                    icon='save'
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    variant="outlined"
                                    text="Cancel"
                                    className='FR MR'
                                    icon='times'
                                    onClick={this.props.onClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>}
            </div>
        );
    }
}

export default EditSendPaymentsTo;
