import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicTextArea from "../SharedComponents/BasicTextArea/BasicTextArea";
import './BillingSection.scss'


export default class BillingNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: this.props?.dataItem?.notes,
        }
    }


 
    render() {
        return (
            <div className="casterFeatureBillingNotes">
                <MDBRow>
                <BasicTextArea
                    value={this.state.notes} rows={10}
                    onChange={(e) => this.setState({notes:e.target.value.length >0 ? e.target.value : null})}
                />
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2}>
                    <BasicButton 
                        text='Save' 
                        icon={"save"}
                        onClick={() => {this.props?.toggleRefreshPage(this.state.notes)}} 
                        variant="contained"
                        type="inline"/>
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton 
                         text="Cancel"
                         variant="outlined"
                         type="inline"
                         icon="times"
                         onClick={this.props?.handleClose} />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}