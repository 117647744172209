export const lookupType = [
    { value: "ADDRESS_TYPE", label: "Address Type" },
    { value: "COMPANY_TYPE", label: "Company Type" },
    { value: "COUNTRY", label: "Country" },
    { value: "ETHNICITY", label: "Ethnicity" },
    { value: "GENDER", label: "Project Type" },
    { value: "I9_STATUS", label: "Role Status" },
    { value: "REP_TYPE", label: "Rep Type" },
    { value: "ROLE_TYPE", label: "Role Type"},
    { value: "SAG_STATUS", label: "Sag Status"},
    {value: "SEASONS", label: "Seasons"},
    {value: "SHOW_STATUS", label: " Show Status"},
    {value: "SHOW_TYPE", label: "Show Type"},
    {value: "STATE", label: " State"},
    {value: "STATUS_DATES" ,label: "Status Dates"},
    {value:"UNION", label: "Union"},
    {value:"WORK_ACTIVITY_CODE", label: "Work Activity" },
]
export const lookupConfig = {
    headings: [
        {
            headingLabel: "Value",
            icon: "sort-alpha-up",
            width: '85%'
        },
        {
            headingLabel: "Active",
            icon: "",
            width: '15%'
        }
    ],
    dataNodes: ["label", "check"],
    primaryKey: "value"
}