import React from "react";
import { withRouter } from "react-router-dom";
import "./LandingPage.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import IconTextField from "../../components/SharedComponents/IconTextField/IconTextField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicCheckBox from "../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import TablePagination from "@material-ui/core/TablePagination";
import { handlePaginationList } from "../../Common/TableHelper";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFormattedDate, getAge } from "../../Common/Helper";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import LandingPageTable from "./LandingPageTable";
import LandingPageCard from "./LandingPageCard";
import { MDBContainer } from "mdbreact";
import axios from 'axios';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

class LandingPageAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setNewProjectModal: false,
      optionsName: [],
      selectedShow: null,
      isLoading: false,
      lastAccessedProjectName: '',
      page: 0,
      searchCancelToken: null,
      renderListOption: [],
      lastAccessedProjectId:''
    };
  }

  handleshowNewProjectModal = () => {
    this.setState({ setNewProjectModal: true });
  };

  hideNewProjectModal = () => {
    this.setState({ setNewProjectModal: false });
  };

  updatePreviousProjectName = (childState) => {
    this.setState({ page: childState })
  }

  handleSearch = (event) => {
    this.setState({ searchValue: event.target.value });
  }

  fetchSearchDetails = (event) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (event.target.value !== "") {
      CasterService.getDataWithCancel(
        Constants.CasterServiceBaseUrl +
        `/casterTvJwpEntitySearch?entity=${"project"}&searchString=${event.target.value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let formattedList = response?.data?.map(item => ({
            text: `${item.name} (${item.sap_gl_code})`,
            value: item.id
          }));
          this.setState({ optionsName: formattedList });
        },
          (err) => {
            console.log(err, "error in option")
          })
    } else {
      this.setState({ optionsName: [] });
    }
  }

  componentDidMount = () => {
    this.getLandingPageTableData();
  };

  componentWillUnmount = () => {
    this.getLandingPageTableData();
  };


  getLandingPageTableData = () => {
    const myObject = {
      order_by_clause: "project_name",
      order_by: "desc",
      search_text: this.state.searchValue,
      page_count: 10,
      page_no: 1,
    };
    this.setState({ isLoading: true })
    const base64String = btoa(JSON.stringify(myObject));
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpDashboardLandingDetails?input=${base64String}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      true
    )
      .then((res) => {
        const tableDataWithFavorite = res.data.records.map(row => ({ ...row, is_favorite: false }))
        this.setState({
          lastAccessedProjectId: res.data.last_accessed_project_id,
          lastAccessedProjectName: res.data.last_accessed_project_name,
          renderListOption: tableDataWithFavorite,
          selectedShow: null,
          page: 0,
          isLoading: false
        }
        );
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        console.log(error, "error in Landingpagetable get method");
      });

  };

  handleSelectedShow = (newValue) => {
    this.setState({ selectedShow: newValue })
    if (newValue) {
      let selectedShow = this.state.renderListOption?.find(item => item?.project_id === newValue?.value)
      this.setState({
        renderListOption: [selectedShow],
        page: 0,
      })
    } else {
      this.getLandingPageTableData();

    }
  };

  render() {
    const url= `/caster-tv-jwp/project-view/prid/${this.state.lastAccessedProjectId}`
    return (
      <div className="LandingContainer">
        <MDBRow>
          <MDBCol xs={6} md={6} sm={4}>
            <span className="landingPageUserName">
              Welcome,{`${this.props?.userContext?.user_profile?.name ? (' ' + this.props?.userContext?.user_profile?.name) : null}`}
            </span>
          </MDBCol>
          <MDBCol xs={6} md={6} sm={4}>
            <span className="floatRight">
              <span className="firstLabel">Most Recently Viewed</span>
              <br />
              {this.state.lastAccessedProjectName !== null ?
                <span className="floatRight">
                  <a href={url}>{`${this.state.lastAccessedProjectName}`}</a>
                </span>
                : ''
              }
            </span>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="search-program display-flex field-style">
            <SearchSelectField
              loading={this.state.isLoading}
              id={"program"}
              placeholder={"Search For a Project"}
              options={this.state.optionsName || []}
              width={'100%'}
              detail_selected={this.state.selectedShow}
              valueSelected={(e, value) => this.handleSelectedShow(value)}
              searchText={this.fetchSearchDetails}
              multiple={false}
              searchSelect={true}
            />
          </MDBCol>
          <MDBCol md={3} className={"newProjectButton"}>
            <BasicButton
              variant="contained"
              type="inline"
              className="outlined-btn"
              text="New Project"
              icon={"plus-circle"}
              onClick={this.handleshowNewProjectModal}
            />
          </MDBCol>
          <MDBCol md={5} className="HeaderSearchField">
            <span className="floatRight sortPadding">
            </span>
          </MDBCol>
        </MDBRow>
        <MDBRow className="projectTableComponet">
          <LandingPageTable updateParentPreviousProjectName={this.updatePreviousProjectName} renderListOption={this.state.renderListOption} onSearch={this.handleSearch} history = {this.props.history}/>
        </MDBRow>
        {this.state.setNewProjectModal && (
          <MainViewModalComponent
            open={this.state.setNewProjectModal}
            handleClose={this.hideNewProjectModal}
            headerText={"Project"}
            mode={"New"}
            modalName={"New Project"}
            getLandingPageTableData={() => this.getLandingPageTableData()}
          />
        )}
      </div>
    );
  }
}
export default withRouter(withUserContext(LandingPageAdmin));
