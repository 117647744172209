import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import { AddCircle } from '@material-ui/icons';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import * as Constants from "../../../../constants/constants";
import CasterService from "../../../../services/service";
import './CompensationTab.scss';
import RichTextEditor from '../../SharedComponents/RichTextEditor/RichTextEditor';
import { CircularProgress } from "@material-ui/core";


export default class AddInfoAndText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoTextValue: this.props?.infoTextValue,
            newContract: this.props?.newContract
        }
    }


    // onSave = () => {
    //     console.log("onSave RTE", this.state.newContract);
    //     this.props?.handleOnsaveRTE(this.state.newContract);

    // }
    componentWillUnmount = () => {
        this.props?.handleOnsaveRTE(this.state.newContract, this.props?.identifier);
    }
    handleOnChange = (value) => {
        console.log("value in handleOnChange", value);
        let newContract = this.state.newContract;
        newContract[this.props?.tooltipBtnPressed] = value;
        if (document.getElementById("CompensationNewInfoTextquill").textContent.length > 0 || this.state?.infoTextValue.length > 0) {
            this.setState({ newContract, infoTextValue: value });
        } else {
            newContract[this.props?.tooltipBtnPressed] = null;
            this.setState({ newContract, infoTextValue: value });
        }
    }
    render() {
        return (
            <div className="EditRiderModalContainer">
                <MDBRow>
                    <MDBCol md={12}>
                        <RichTextEditor
                            id={`CompensationNewInfoText`}
                            value={this.state?.infoTextValue || ""}
                            onChange={(value) => this.handleOnChange(value)}>
                        </RichTextEditor>
                    </MDBCol>
                </MDBRow>
                {/* <MDBRow className="marginTop10">
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2} className="d-flex justify-content-end">
                        <BasicButton
                            type="inline"
                            variant="contained"
                            text={"Save"}
                            icon="save"
                            onClick={() => this.onSave()}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="d-flex justify-content-end">
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            icon="times"
                            onClick={this.props?.handleClose}
                        />
                    </MDBCol>
                </MDBRow> */}
            </div>
        )
    }
}