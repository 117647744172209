import React from 'react';
import RepresentativeProfilePersonal from './RepresentativeProfilePersonal';
import RepresentativeProfileTalent from './RepresentativeProfileTalent';
import { withUserContext } from "../../../contexts/UserContext";
import {
    repInitialDetails, talentAddress, talentPhone,
    talentEmail, talentAka, talentRepresentation, talentPersonalLimit, repTalentConfig
} from './config';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from '@material-ui/core';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class RepresentativeProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            representativeDetails: JSON.parse(JSON.stringify({ ...repInitialDetails })),
            postInitiated: false,
            akaIndex: 2,
            checkedMultiPrimary: {
                email_list: false,
                representation_list: false,
            },
            representiveOption: [],
            companyOptions: [],
            renderList: [],
            rowsPerPage: 10,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: repTalentConfig,
            talentPaginationCount: 0
        }
    }

    componentDidMount() {
        if (this.props?.repId) {
            this.getrepresentativeDetails("id", this.props?.repId, 1)
        } else {
            this.getCompanyData("COMPANY", " ")
            this.geRepresentiveData("REP_TYPE")
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {
            return {
                tabValue: props.tabValue,
                mode: props.mode,
                navObj: props.navObj || {}
            }
        }
        return null
    }

    getrepresentativeDetails = (searchKey, searchString) => {
        this.setState({ isFetchingRepDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetRepresentative?searchKey=${searchKey}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("get representive data", response)
                this.setState({
                    representativeDetails: response.data[0],
                    renderList: handlePaginationList(0, this.state.rowsPerPage, response.data[0].talents),
                    repTalentList: response.data[0].talents,
                    isFetchingRepDetails: false
                }, () => {
                    this.getCompanyData("COMPANY", " ")
                    this.geRepresentiveData("REP_TYPE")
                    this.handleTableColumnClick("Talent")
                })
            },
                (err) => {
                    this.setState({ isFetchingRepDetails: false })
                    console.log("Error in fetching representive details", err)
                })
    }

    handleTableColumnClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent": return this.state.tabValue === 1 ? "talent_name" : "date_time_added";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }

    getCompanyData = (entity, searchString) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.company_id,
                    text: item.company_name,
                }));
                this.setState({
                    companyOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let representativeDetails = { ...this.state.representativeDetails };
                    representativeDetails.company = (representativeDetails?.company_id && representativeDetails?.company_name) ? 
                        { value: representativeDetails?.company_id, text: representativeDetails?.company_name } : null;
                    this.setState({ representativeDetails: representativeDetails });
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getCompanyData method");
            });
    };

    geRepresentiveData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                }));
                this.setState({
                    representiveOption: filteredList,
                    isSubmit: false
                }, () => {
                    let repDetails = { ...this.state.representativeDetails }
                    repDetails.representative_type = this.state?.representiveOption?.find(item => item.label === this.state?.representativeDetails?.representative_type)?.value
                    this.setState({ representativeDetails: repDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getCompanyData method");
            });
    };

    handleChangePage = (newPage) => {
        this.setState({
          renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.repTalentList)
        });
      }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        this.setState(prevState => ({
            representativeDetails: {
                ...prevState.representativeDetails,
                [field]: newValue
            },
            postInitiated: false
        }))
    }

    getTabComponent = () => {
        switch (this.props.tabValue) {
            case 0: return <RepresentativeProfilePersonal
                representativeDetails={this.state.representativeDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                companyOptions={this.state.companyOptions}
                representiveOption={this.state.representiveOption}
                getCompanyData={this.getCompanyData}
            />
            case 1: return <RepresentativeProfileTalent
                fieldChanged={this.props?.fieldChanged}
                representativeDetails={this.state.representativeDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                renderList={this.state.renderList}
                repTalentList={this.state.repTalentList}
                mode={this.props?.mode}
                repId={this.props?.repId}
                RepCompanyId={this.props?.RepCompanyId}
                getrepresentativeDetails={() => this.getrepresentativeDetails('id', this.props?.repId, 1)}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                notificationComponent={this.props?.notificationComponent}
                handleChangePage={this.handleChangePage}
            />
        }
    }

    canSubmit = (repDetails) => {
        let canSubmit = repDetails?.first_name && repDetails?.representative_type && repDetails?.company_id && repDetails?.email;
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let representativeDetailsPostJson = { ...this.state.representativeDetails };
        representativeDetailsPostJson.company_id = this.state.representativeDetails.company.value
        representativeDetailsPostJson['representative_type'] = this.state.representiveOption?.find(item => item.value === representativeDetailsPostJson.representative_type)?.label;
        if (this.canSubmit(representativeDetailsPostJson)) {
            this.props?.setPostFlag(true)
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/animationPostRepresentative', representativeDetailsPostJson, this.props.userContext?.active_tenant?.tenant_id, 1)
                .then((response) => {
                    if (response?.data?.error) {
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "fail")
                    } else {
                        representativeDetailsPostJson['representative_id'] = response.data[0].representative_id
                        this.setState({
                            representativeDetails: {
                                ...this.state.representativeDetails,
                            }
                        });
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "success", "refresh-rep")
                        console.log("post response", response);
                    }
                },
                    (err) => {
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "fail")
                        console.log("Post details error: " + err);
                    });
        }
    }

    render() {
        return (
            <div className="RepresentativeProfileContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleSubmit()}></input>
                {this.state.isFetchingRepDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <>
                            {this.getTabComponent()}
                        </>
                    )
                }
            </div>
        );
    }

}
export default withUserContext(RepresentativeProfileContainer);