import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdbreact";
import TableComponent from "../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import "./UserRoleList.scss";
import { projectInfo, projectConfigRecord, renderProjectRecord, projectAssignMentDetails, userConfigRecords } from "./Config";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import { withUserContext } from "../../../contexts/UserContext";
import { CircularProgress } from "@material-ui/core";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import axios from 'axios';

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            projectDetails: [],
            config: projectConfigRecord,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            isProjectDetailsLoader: false,
            isNavigation: false,
            setEditProjectModal: false,
            optionsName: [],
            selectedShow: null,
            isLoading: false,
            searchCancelToken: null,
            isDelete: false,
            user_id: null,
            user_project_id: null,
            userList: [],
            userConfig: userConfigRecords,
            isEdit: false
        };
    }

    componentDidMount() {
        if (this.props?.project_id === 0 || this.props?.project_id) {
            this.getProjectAssignmentDetails(this.props?.project_id)
            this.getProjectDetails(this.props?.project_id)
        }
        this.getAllUsersData()
        if (this.props?.userRef) {
            this.props.userRef.current = {
                getAllUsersData: this.getAllUsersData.bind(this),
                getUserList: this.getUserList.bind(this)
            }
        }
        this.getAccessLevels();
    }

    getAllUsersData = (searchString = null, clearFilter = false) => {
        if (!searchString) {
            this.setState({ isProjectDetailsLoader: true })
        }
        if (clearFilter) {
            this.setState({ full_name: null })
        }
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        const myObject = {
            entity_search_text: searchString,
            page_count: this.state.rowsPerPage,
            page_no: this.state.page + 1,
        };
        const base64String = btoa(JSON.stringify(myObject));
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpUsersAll?input=${base64String}`,
            // cancelToken,
            this.props?.userContext?.user_profile?.tenant_id,
            1
        )
            .then((res) => {
                let formattedList = res?.data?.records;
                formattedList?.map(item => {
                    item.status = item.is_active === 1 ? "Active" : "Inactive"
                    return item;
                })
                formattedList?.sort(function (a, b) {
                    return (a.full_name > b.full_name) ?
                        1 : ((b.full_name > a.full_name) ? -1 : 0);
                });
                this.setState({
                    userList: handlePaginationList(0, this.state.rowsPerPage, formattedList),
                    setEditEpisodeProject: false,
                    isProjectEpisodeList: false,
                    isProjectDetailsLoader: false,
                    userCount: res?.data?.query_record_count,
                    sortCount: 0,
                    isSortByAsc: true,
                    tableData: formattedList
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    };

    getUserList = () => {
        let tableData = [...this.state.tableData]
        return tableData;
    }

    getProjectAssignmentDetails = (projectId) => {
        this.setState({ isProjectDetailsLoader: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpProjectAssignmentDetails?projectId=${projectId}`,
            this.props?.userContext?.user_profile?.tenant_id, 1
        )
            .then((res) => {
                console.log("resAssign", res)
                let filteredList = res.data[0].user_list
                let configData = {...this.state.config}
                configData.actions = []
                this.setState({
                    renderList: filteredList,
                    projectUserList: filteredList,
                    projectDetails: res.data[0],
                    config: configData,
                    isProjectDetailsLoader: false,
                    setEditProjectModal: false,
                    setCopyModal: false
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    };

    getAccessLevels = () => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpLookupDetails?objectType=lu_access_level`,
            this.props?.userContext?.user_profile?.tenant_id, 1
        )
            .then((res) => {
                let formattedList = res.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                let config = this.state.config;
                config.headings[1].selectOptions = formattedList
                this.setState({
                    accessLevelOptions: formattedList,
                    config: config
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    }

    getProjectDetails = (projectId) => {
        this.setState({ isProjectDetailsLoader: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpProject?projectId=${projectId}`,
            this.props?.userContext?.user_profile?.tenant_id, 1
        )
            .then((res) => {
                let filteredList = res.data[0]
                this.setState({
                    // projectDetails: filteredList,
                    setEditEpisodeProject: false,
                    isProjectDetailsLoader: false,
                    setEditProjectModal: false,
                    setCopyModal: false
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    };

    handleEditProjectModal = () => {
        this.setState({ setEditProjectModal: true });
    };

    hideEditProjectModal = () => {
        this.setState({ setEditProjectModal: false });
    };

    handleSelectedShow = (newValue) => {
        this.setState({ selectedShow: newValue })
    };

    fetchSearchDetails = (event) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        if (event.target.value !== "") {
            CasterService.getDataWithCancel(
                Constants.CasterServiceBaseUrl +
                `/casterTvJwpEntitySearch?entity=user&searchString=${event.target.value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response?.data?.map(item => ({
                        text: item.name,
                        value: item.id
                    }));
                    this.setState({ optionsName: formattedList });
                },
                    (err) => {
                        console.log(err, "error in option")
                    })
        } else {
            this.setState({ optionsName: [] });
        }
    }

    handleSubmit = (updateAccessLevel = false) => {
        let payload = { ...projectAssignMentDetails };
        payload.project_id = this.props?.project_id || this.state.projectDetails?.project_id
        if (!updateAccessLevel) {
            payload.user_list[0].user_id = this.state.selectedShow ? this.state.selectedShow.value : this.state.user_id
            payload.user_list[0].is_delete = this.state.isDelete ? 1 : 0;
            payload.user_list[0].user_project_id = this.state.isDelete ? this.state.user_project_id : null
            payload.user_list[0].access_level_id = this.state.selectedAccessLevel || null;
        } else {
            let updatedUserList = [];
            let projectUserList = [...this.state.renderList]
            projectUserList?.map(item => {
                let payloadItem = {};
                payloadItem['user_id'] = item.user_id;
                payloadItem['is_delete'] = 0;
                payloadItem['user_project_id'] = item.user_project_id
                payloadItem['access_level_id'] = item.access_level_id
                updatedUserList?.push(payloadItem);
            })
            payload.user_list = updatedUserList;
        }
        this.setState({ postInitiated: true, isSubmit: true });
        CasterService.postDataParams(
            // Constants.CasterServiceBaseUrl + "/casterTvJwpProjectAssignment",
            Constants.CasterServiceBaseUrl + "/casterTvJwpProjectAssignmentDetails",
            payload,
            this.props.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.notificationComponent(true, "fail");
                } else {
                    console.log("projectAssigment", response.data[0])
                    this.getProjectAssignmentDetails(this.props?.project_id)
                }
                this.setState({ isSubmit: false, postInitiated: false, selectedShow: null, selectedAccessLevel: null, updatedItemNodeValue: false });
            },
            (err) => {
                this.setState({ isSubmit: false, postInitiated: false });
                this.notificationComponent(true, "fail");
            }
        );

    };

    handleProjectDelete = (val, item) => {
        let data = this.state.projectDetails
        data.project_id = item.project_id
        this.setState({ projectDetails: data, isDelete: true, user_id: item.user_id, user_project_id: item.user_project_id }, () => {
            this.handleSubmit()
        })
    }

    navigationCallback = (item, node) => {
        this.setState({ openUserModal: true, userId: item?.user_id })
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getAllUsersData();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getAllUsersData();
                });
                break;
        }
    }

    handleUserEdit = (dataItem, node) => {
        console.log("check the user edit detail", dataItem, node)
        this.setState({ userId: dataItem?.user_id, openUserModal: true })
    }

    handleProjectUserFilter = (searchString) => {
        let projectUsersList = [...this.state.projectUserList];
        projectUsersList = projectUsersList?.filter(item => (item?.user_name)?.toLowerCase()?.indexOf(searchString) !== -1)
        this.setState({ renderList: projectUsersList })
    }

    handleEditProject = () => {
        this.setState({ openProjectModal: true })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                config: updateTableSortConfigObject(JSON.parse(JSON.stringify({ ...userConfigRecords })), this.state.isSortByAsc, this.state.sortBy, colName),
                sortBy: colName,
                userList: tableSortList("Name", this.getSortNode(colName), this.state.userList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "User Name": return "full_name";
            case "User Type": return "role_name";
            case "#Project": return "project_count";
            case "Email": return "login_email";
            case "Phone": return "phone";
            case "Status": return "status";
        }
    }

    handleEditAccess = () => {
        let projectUserList = [...this.state.renderList];
        projectUserList?.map(item => {
            item['editing'] = true;
            // item.access_level = item?.access_level_name
            return item;
        })
        this.setState({ renderList: projectUserList, showSaveButton: true,isEdit: true },()=>{
            let configRecord = {...this.state.config}
            configRecord.actions=["times"]
            this.setState({config : configRecord})
        })
    }

    handleSaveAccess = () => {
        this.setState({ updatedItemNodeValue: true })
        let projectUserList = [...this.state.renderList];
        projectUserList?.map(item => {
            item['editing'] = false;
            return item;
        })
        this.setState({ renderList: projectUserList, showSaveButton: false })
    }

    handleCancelAccess=()=>{
        let projectUserList = [...this.state.renderList];
        projectUserList?.map(item => {
            item['editing'] = false;
            return item;
        })
        this.setState({ renderList: projectUserList, showSaveButton: false })
    }

    updateTableInlineValues = (listEdits) => {
        console.log("update list check", listEdits)
        let projectUserList = [...this.state.renderList]
        projectUserList?.map((item, index) => {
            listEdits?.map((item1, index1) => {
                if (index === index1) {
                    item.access_level_id = item1.access_level_id
                }
                return item;
            })
        })
        this.setState({ renderList: projectUserList }, () => {
            this.handleSubmit(true)
        })
    }

    render() {
        return (
            <div className="ProjectAdmin">
                {this.state.isProjectDetailsLoader ? <div style={{ textAlign: "center" }}>
                    <CircularProgress size={34} />
                </div> :
                    <>
                        {this.props?.isUrm ? " " :
                            <div className="header-font">
                                <span className="arrow-font">
                                    <MDBIcon fas icon="arrow-left" className='icon'
                                        onClick={() => {
                                            this.props?.tabChangeFun(false)
                                            this.props?.toggleRefresh()
                                        }}
                                    />
                                </span>
                                <span className="ml">{this.state.projectDetails?.project_id === 0 ? "All Projects" :  this.state.projectDetails?.project_name}</span>
                            </div>}
                        {this.props?.isUrm ?
                            <>
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <BasicTextField
                                            id="full_name"
                                            placeholder={"Search by User Name"}
                                            value={this.state.full_name || ""}
                                            onChange={(e) => {
                                                this.setState({ full_name: e.target.value })
                                                this.getAllUsersData(e.target.value)
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="g-0 projectAssignment-Table" id={"project-table"}>
                                    <TableComponent
                                        list={this.state.userList || []}
                                        config={this.state.userConfig}
                                        sortCallback={this.handleTableColumnClick}
                                        buttonWithFieldFun={(val, item) => this.handleUserEdit(val, item)}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <TablePagination
                                        component="div"
                                        count={this.state.userCount}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        page={this.state.page}
                                        onChangePage={(e, page) => {
                                            this.setJson('page', page)
                                        }}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onRowsPerPageChange={(e) => {
                                            this.setJson('pagePerRow', e.target.value)
                                        }}
                                    />
                                </MDBRow>
                            </> :

                            <MDBRow>{this.state.projectDetails?.project_id !== null &&
                                <>
                                {this.state.projectDetails?.project_id !== 0 && 
                                <MDBCol md={3}>
                                    <div className="existing-records">
                                        <div >
                                            <span className="entity-item ">
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"Project Type"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.project_type_name || "-"}
                                                    </span>
                                                </div>
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"Season Dates"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.season_year || "-"}
                                                    </span>
                                                </div>
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"GL/SAP Code"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.gl_code || "-"}
                                                    </span>
                                                </div>
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"Production Entity"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.production_entity_name || "-"}
                                                    </span>
                                                </div>
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"Union"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.union_name || "-"}
                                                    </span>
                                                </div>
                                                <div className="project-details">
                                                    <span>
                                                        <BasicLabel text={"Outlet"} />
                                                    </span>
                                                    <span>
                                                        {this.state.projectDetails?.outlet_name || "-"}
                                                    </span>
                                                </div>
                                            </span>
                                        </div>

                                        <div className="edit-project-btn">
                                            {this.props?.projectLastUpdated &&
                                                <div className="access-last-update">
                                                    <BasicLabel text="Access Last Updated" />
                                                    <span className="updated-date">{this.props?.projectLastUpdated}</span>
                                                </div>
                                            }
                                            <span className="btn-holder">
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    className="outlined-btn"
                                                    icon={"pen"}
                                                    text="Edit Project"
                                                    onClick={this.handleEditProject}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </MDBCol>}</>}
                                <MDBCol md={this.state.projectDetails?.project_id ===0 ? 12 : 9}>
                                {this.state.projectDetails?.project_id !== 0 &&
                                    <MDBRow className="edit-access-btn">
                                        {this.state.showSaveButton ?
                                            <>
                                            <div className="display-flex-acces">
                                            <BasicButton
                                                variant="outlined"
                                                type="inline"
                                                icon={"times"}
                                                text="Cancel"
                                                onClick={this.handleCancelAccess}
                                            /> 
                                             <BasicButton
                                                variant="contained"
                                                type="inline"
                                                className="outlined-btn"
                                                icon={"save"}
                                                text="Save"
                                                onClick={this.handleSaveAccess}
                                            /> 
                                            </div>
                                            </>
                                            :
                                            <BasicButton
                                                variant="contained"
                                                type="inline"
                                                className="outlined-btn"
                                                icon={"pen"}
                                                text="Edit Access"
                                                onClick={this.handleEditAccess}
                                            />
                                        }
                                    </MDBRow>}
                                    <MDBRow>
                                        <div className="project-users">
                                            <span className="access-list-name">Access List</span>
                                            <div className="project-user-filter">
                                                <BasicTextField
                                                    id="user_name"
                                                    placeholder={"- Search by name -"}
                                                    value={this.state.user_name || ""}
                                                    onChange={(e) => {
                                                        this.setState({ user_name: e.target.value })
                                                        this.handleProjectUserFilter(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <MDBRow className="g-0 projectAssignment-Table" id={"project-table"}>
                                            {this.state.isProjectDetailsLoader ? <div style={{ textAlign: "center" }}>
                                                <CircularProgress size={34} />
                                                </div> :
                                                <TableComponent
                                                    list={this.state.renderList || []}
                                                    config={this.state.config}
                                                    sortCallback={this.handleTableColumnClick}
                                                    isLoading={this.state.isSubmit}
                                                    updatedItemNodeValue={this.state.updatedItemNodeValue}
                                                    updateTableInlineValues={(listEdits) => this.updateTableInlineValues(listEdits)}
                                                    handleDelCallBack={(val, item) => this.state.isEdit && this.handleProjectDelete(val, item)}
                                                    hyperLinkNavigationCallback={(item, node) => this.navigationCallback(item, node)}
                                                />}
                                            </MDBRow>
                                            <div className="add-user">
                                                {this.state.isEdit &&
                                                <>
                                                <MDBCol md={3}>
                                                    <SearchSelectField
                                                        loading={this.state.isLoading}
                                                        id={"program"}
                                                        placeholder={"- Search By Name to add -"}
                                                        options={this.state.optionsName || []}
                                                        width={'100%'}
                                                        detail_selected={this.state.selectedShow}
                                                        valueSelected={(e, value) => this.handleSelectedShow(value)}
                                                        searchText={this.fetchSearchDetails}
                                                        multiple={false}
                                                        searchSelect={true}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={2} className="p-0 access-menu">
                                                    <SelectField
                                                        id={"access-level"}
                                                        defaultMenuText={"- Access Level -"}
                                                        options={this.state.accessLevelOptions || []}
                                                        value={this.state.selectedAccessLevel}
                                                        onChange={(e, value) => this.setState({ selectedAccessLevel: e.target.value })}
                                                    />
                                                </MDBCol>
                                                <MDBCol className="p-0 chevron">
                                                    <MDBIcon
                                                        icon={"plus-circle"}
                                                        onClick={() => this.handleSubmit(false)}
                                                    />
                                                </MDBCol>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>}
                    </>}
                {this.state.openProjectModal && (
                    <MainViewModalComponent
                        open={this.state.openProjectModal}
                        handleClose={() => this.setState({ openProjectModal: false })}
                        headerText={"Project"}
                        mode={"Edit"}
                        modalName={"New Project"}
                        id={this.state.projectDetails?.project_id}
                        isProjectAssignment={true}
                        toggleRefresh={() => this.getProjectDetails(this.state.projectDetails?.project_id)}
                    />
                )}
                {this.state.setEditProjectModal && (
                    <MainViewModalComponent
                        open={this.state.setEditProjectModal}
                        handleClose={this.hideEditProjectModal}
                        headerText={"Project"}
                        mode={"Edit"}
                        modalName={"New Project"}
                        id={this.props?.project_id}
                        getProjectDetails={this.getProjectDetails.bind(this)}
                    />
                )}
                {this.state.openUserModal && (
                    <MainViewModalComponent
                        open={this.state.openUserModal}
                        handleClose={() => this.setState({ openUserModal: false })}
                        headerText={"User"}
                        mode={"Edit"}
                        modalName={"User"}
                        userId={this.state.userId}
                        toggleRefresh={() => this.getAllUsersData(null, true)}
                    />
                )}
            </div>
        );
    }
}

export default withUserContext(UsersList);
