import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import TableComponent from "../SharedComponents/Table";
import { repConfig, renderRepList } from "./config";
import { MDBRow } from "mdb-react-ui-kit";
import TablePagination from '@material-ui/core/TablePagination';
import Popover from '@material-ui/core/Popover';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";

class TalentActingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            config: repConfig,
            rowsPerPage: 10,
            page: 0,
            sortBy: "Show",
            isSortByAsc: true,
            sortCount: 0,
        };
    }

    componentDidMount() {
        if (this.props?.talentId) {
            this.getActingHistory()
        }
    }

    getActingHistory = () => {
        this.setState({ isFetchingActingHistory: true })
        let payload = {
            "talent_id": this.props?.talentId,
            "order_by_clause": this.state.sortBy,
            "order_by": this.state.isSortByAsc === true ? 'ASC' : 'DESC',
            'page_count': this.state.rowsPerPage,
            "page_no": this.state.page + 1
        }
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetTalentActingHistory?input=${btoa(JSON.stringify(payload))}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1
        )
            .then((response) => {
                let actingHistoryDetails = response?.data?.records;
                actingHistoryDetails?.map(item => {
                    if (item.show_configuration === 'Episodic') {
                        item.show_name = item.show_name + ' (' + item.season + ', ' + item.season_name + ')'
                    } else {
                        item.show_name = item.show_name
                    }
                    return item;
                })
                this.setState({
                    renderList: handlePaginationList(0, this.state.rowsPerPage, actingHistoryDetails),
                    actingHistoryCount: response?.data?.query_record_count,
                    isFetchingActingHistory: false
                })
            })
            .catch((error) => {
                this.setState({ isFetchingActingHistory: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getActingHistory();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getActingHistory();
                });
                break;
        }
    }

    handleOpenContractPopOver = (id, dataItem, event) => {
        this.setState({
            contractAnchorEl: event.currentTarget,
            selectedTalentShow: dataItem
        })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                talentConfig: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
                renderList: tableSortList(colName, this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            }, () => {
                this.getActingHistory();
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Show": return "Show";
            case "Prim. Representative": return "Representative";
        }
    }

    navigationCallback = (dataItem, node) => {
        this.props?.history?.push(`/caster-animation/show-season/${dataItem.show_id}`)
    }


    render() {
        const open = Boolean(this.state.contractAnchorEl);
        return (
            <div className="TalentActionContainer">
                <MDBRow id={"actionTable"}>
                    <TableComponent
                        list={this.state.renderList || []}
                        config={this.state.config}
                        isLoading={this.state.isFetchingActingHistory}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        sortCallback={this.handleTableColumnClick}
                        handleOpenInlineModal={this.handleOpenContractPopOver.bind(this)}
                    />
                    {this.state.selectedTalentShow?.contracts?.length > 0 &&
                        <Popover
                            id={'contract-popover'}
                            open={open}
                            anchorEl={this.state.contractAnchorEl}
                            onClose={() => this.setState({ contractAnchorEl: null })}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {this.state.selectedTalentShow?.contracts?.map(item => (
                                <MDBRow onClick={() => this.handleContractRedirection(item)}>
                                    <p className="performer-contract">
                                        {`${item?.gl_code ? item?.gl_code : ''}${item?.episode_name ? (' - ' + item?.episode_name) : ''}${item?.contract_date ? (' - ' + moment(item?.contract_date).format("MM/DD/YYYY")) : ''}`}
                                    </p>
                                </MDBRow>
                            ))}
                        </Popover>
                    }
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.actingHistoryCount || 0}
                        rowsPerPageOptions={[]}
                        page={this.state.page}
                        onChangePage={(e, page) => {
                            this.setJson('page', page)
                        }}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                </MDBRow>
            </div>
        );
    }
}
export default withUserContext(TalentActingHistory);
