
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import './Modal.scss';
import { withUserContext } from "../../../../contexts/UserContext"
import  NewProject from "../../../pages/Modals/ProjectModal";
import EditEpisode from '../../../pages/Modals/EpisodeModal';
import SetRecurringRole from '../../../pages/Modals/RecurringRole';
import CastListModal from '../../../pages/Modals/CastListModal';
import User from '../../../pages/Modals/User';
import LookupValues from '../../../components/LookupValues/LookupValues';
import DealMemoListModal from '../../../pages/Modals/DealMemoListModal';

class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            saveFlag: true
        }
        this.submitButtonRef = React.createRef();
    }



    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false, showEmailSave: false, showNoDataFound: false });
        this.props?.handleClose();
    }

    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
            case "emailSaved": return this.setState({ showEmailSave: value, saveFlag: true });
            case "noData": return this.setState({ showNoDataFound: value })
        }
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, showEmailSave: false, showNoDataFound: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'New Project': return <NewProject
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            handleSubmit={this.props?.handleSubmit}
            id={this.props?.id}
            getLandingPageTableData={this.props?.getLandingPageTableData}
            getProjectDetails={this.props?.getProjectDetails}
            getProjectAssignmentDetails={this.props?.getProjectAssignmentDetails}
            isProjectAssignment={this.props?.isProjectAssignment}
            toggleRefresh={this.props?.toggleRefresh}
            handleCopyModalClose={this.props?.handleCopyModalClose}
            handleEditModalClose={this.props?.handleEditModalClose}
            notificationComponent={this.notificationComponent}
            fromProject={this.props.fromProject}
        />
            case 'Episode': return <EditEpisode
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            handleSubmit={this.props?.handleSubmit}
            navigationCallback={this.props?.navigationCallback}
            selectedEpisodeId={this.props?.selectedEpisodeId}
            getProjectEpisodesList={this.props?.getProjectEpisodesList}
            match={this.props?.match}
            season_id={this.props.season_id}
            episodeList={this.props?.episodeList}
            notificationComponent={this.notificationComponent}
        />
            case "Set Recurring Role": return <SetRecurringRole 
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            handleSubmit={this.props?.handleSubmit}
            project_id={this.props?.project_id}
            confirmationFun={this.props.confirmationFun}
            />
            case "User" : return <User 
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            userId={this.props?.userId}
            handleSubmit={this.props?.handleSubmit}
            toggleRefresh={this.props?.toggleRefresh}
            />
            case "CastList PDF": return <CastListModal 
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            handleSubmit={this.props?.handleSubmit}
            renderList={this.props?.renderList}
            selectedEpisodeId={this.props?.selectedEpisodeId}
            />
            case "Lookup Values" : return <LookupValues 
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            />
            case "Deal Memo PDF": return <DealMemoListModal 
            onClose={this.onClose}
            mode={this.props.mode || "New"}
            fieldChanged={this.fieldChanged}
            handleSubmit={this.props?.handleSubmit}
            dealMemoId={this.props?.dealMemoId}
            roleTypeName={this.props?.roleTypeName}
            />
        }
    }

    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'New Project': return 'md';
            case 'Episode': return 'md';
            case 'Set Recurring Role': return 'lg';
            case 'User': return 'md';
            case 'CastList PDF': return 'sm'
            case 'Lookup Values': return 'sm'
            default: return 'md';
        }
    }

    getWidthForModal = () => {
        switch (this.props?.headerText) {
            case 'Talent': return 'talentModalWidth'
            case 'Agency': return 'talentModalWidth'
            case 'Representatives': return 'talentModalWidth'
            case 'Production Companies': return 'prodcompModalWidth'
            case 'Crew': return 'talentModalWidth'
            case 'Reports': return 'reportModalWidth'
            default: return '';
            
        }
    }

    componentDidMount() {
        //document.getElementById("sidenav").setAttribute("disabled", true);
    }

    componentWillUnmount() {
        //document.getElementById("sidenav").removeAttribute("disabled");
    }
/*
    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }
    deletePost = (save_flag) => {
        this.setState({ saveFlag: save_flag });
    }
    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'Roles': return <RoleContainer
                fieldChanged={this.fieldChanged}
                onClose={this.onClose}
                deletePost={this.deletePost}
                setPostFlag={this.setPostFlag}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                isFeature={this.props.isFeature}
                productionOrSeason={this.props.productionOrSeason}
                navFuns={this.props.navFuns}
                handleSubmit={this.props?.handleSubmit}
                name={this.props?.name}
            />;
            case 'Main Production': return <NewProductionDetails
                fieldChanged={this.fieldChanged}
                onClose={this.onClose}
                setPostFlag={this.setPostFlag}
                isFeature={true}
                production_id={this.props?.id}
                mode={this.props.mode}
                primaryButtonText={this.props.mode === "New" ? 'Create' : 'Save & Close'}
                secondaryButtonText={'Cancel'}
                handleSubmit={this.props?.handleSubmit}
            />;
            case 'Episode': return <NewEpisode
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                onClose={this.onClose}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                handleSubmit={this.props?.handleSubmit}
                {...this.props}
            />;
            case 'Status Date': return <AddStatusDate
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                onClose={this.onClose}
                isFeature={false}
                handleSubmit={this.props?.handleSubmit}
            />;
            case 'Add Crew': return <AddCrew
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                onClose={this.onClose}
                isFeature={false}
                handleSubmit={this.props?.handleSubmit}
            />;
            case 'Add Location': return <AddLocation
                mode={this.props.mode || "New"}
                onClose={this.onClose}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Add Staff Member': return <AddStaffMember
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                handleSubmit={this.props?.handleSubmit}
                allStaff={this.props?.allStaff}
            />
            case 'Add Work Week': return <AddWorkWeek
                mode={this.props.mode || "New"}
                onClose={this.onClose}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'New Season': return <NewSeason
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                onClose={this.onClose}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                primaryButtonText={this.props.mode === "New" ? 'Create' : 'Save & Close'}
                secondaryButtonText={'Cancel'}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'New Show': return <NewShow
                onClose={this.onClose}
                isFeature={false}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                id={this.props.id || ""}
                production_id={this.props?.production_id}
                primaryButtonText={this.props.mode === "New" ? 'Create' : 'Save & Close'}
                secondaryButtonText={'Cancel'}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Change Location': return <ChangeLocation
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                mode={this.props.mode || "New"}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'New Audition': return <NewAudition
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                isFeature={false}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                id={this.props?.id}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Edit Rider': return <EditRider
                mode={this.props.mode || "New"}
                onclose={this.onClose}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                performerContractId={this.props?.performerContractId}
                performerName={this.props?.performerName}
                performerId={this.props?.performerId}
                performerDealId={this.props?.performerDealId}
                performerContractRiderId={this.props?.performerContractRiderId}
                psId={this.props?.psId}
                episodeId={this.props?.episodeId}
                productionId={this.props?.productionId}
                talentId={this.props?.talentId}
                loadPerformerRiderFilesData={this.props?.loadPerformerRiderFilesData}
            />
            case 'Add Performer': return <AddPerformer
                onClose={this.onClose}
                navFunc={this.props.navFunc}
                allPerformer={this.props.allPerformer}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                isFeature={this.props.isFeature}
                performerContactsData={this.props.performerContactsData}
                name={this.props?.name}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                episode_id={this.props?.episode_id}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Add New Performer': return <AddNewPerformer
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Add Performer to Episode': return <CopyPerformer
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                {...this.props}
            />
            case 'Manage Lookups': return <ManageLookup
                onClose={this.onClose}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                primaryButtonText={'Save & Close'}
                secondaryButtonText={'Cancel'}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                id={this.props?.id}
                episode_id={this.props?.episode_id}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Data Query': return <DataQuery
                onClose={this.onClose}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                primaryButtonText1={'See Results'}
                primaryButtonText2={'Export to Excel'}
                secondaryButtonText={'Cancel'}
                navFuns={this.props.navFuns}
                production_season_id={this.props?.production_season_id}
                production_id={this.props?.production_id}
                id={this.props?.id}
                episode_id={this.props?.episode_id}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Edit Work Activity': return <EditWorkActivity
                onClose={this.onClose}
                isFeature={false}
                workId={this.props?.id}
                fieldChanged={this.fieldChanged}
                handlePostEditCallback={this.props.handlePostEditCallback}
                primaryButtonText={'Save & Close'}
                secondaryButtonText={'Cancel'}
                handleSubmit={this.props?.handleSubmit}
            />
            case 'Send Contract': return <SendContract
                mode={this.props.mode || "New"}
                onclose={this.onClose}
                isFeature={false}
                fieldChanged={this.fieldChanged}
                {...this.props}
            />
        }
    }

    getWidthForModal = () => {
        switch (this.props?.modalName) {
            case 'New Season': return 'newEditSeason';
            case 'Episode': return 'newEditEpisode';
            case 'Add Performer': return 'newEditPerformer';
            case 'Roles': return 'newEditRoles';
            case 'Data Query': return 'dataQuerySearch';
            case 'Manage Lookups': return 'managelookup';
            default: return '';
            
        }
    }


    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'Add Work Week': return classes.addWorkweek;
            case 'Episode': return classes.newEpisode;
            case 'New Season': return classes.newSeason;
            case 'New Show': return classes.newShow;
            case 'Add Staff Member': return classes.staffMember;
            case 'Add Location': return classes.addLocation;
            case 'Main Production': classes.MainProduction;
            // case 'New Audition': return classes.NewAudition;
            case 'Edit Rider': return classes.EditRider;
            case 'Roles': return classes.roles;
            case 'Add Performer': return classes.AddPerformer;
            case 'Add New Performer': return classes.AddNewPerformer;
            case 'Add Performer to Episode': return classes.CopyPerformer;
            case 'Manage Lookups': return classes.ManageLookup;
            case 'Data Query': return classes.DataQuery;
            case 'Send Contract' : return classes.SendContract;
            default: return '';
        }
    }
    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'Status Date': return 'sm';
            case 'Add Work Week': return 'sm';
            case 'New Episode': return 'md';
            case 'New Season': return 'md';
            case 'Roles': return 'lg';
            case 'New Audition': return 'sm';
            case 'Main Production': return 'md';
            case 'Edit Work Activity': return 'sm';
            case 'Add New Performer': return 'sm';
            case 'Add Performer to Epiosde': return 'sm';
            case 'Data Query': return 'lg';
            case 'EditRider': return 'lg';
            // case 'New Show': return classes.newShow;
            // case 'Add Staff Member': return classes.staffMember;
            // case 'Change Location': return classes.ChangeLocation;
            case 'Add Location': 'md';
            // case 'Add Location': return classes.addLocation;
            default: return 'md';
        }
    }

    handlePermissions = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let showSave = false;
        switch (this.props?.modalName) {
            case 'Roles':
                showSave = userPermissions?.isCreateRoles ? userPermissions?.isCreateRoles : false
                break;
            case 'Main Production':
                showSave = userPermissions?.isModifyShows ? userPermissions?.isModifyShows : false
                break;
            case 'Episode':
                showSave = userPermissions?.isModifyEpisodes ? userPermissions?.isModifyEpisodes : false
                break;
            case 'Status Date':
                showSave = userPermissions?.isModifyStatusDate ? userPermissions?.isModifyStatusDate : false
                break;
            case 'Add Location':
                showSave = userPermissions?.isModifyLocation ? userPermissions?.isModifyLocation : false
                break;
            case 'Add Staff Member':
                showSave = userPermissions?.isModifyStaffCrew ? userPermissions?.isModifyStaffCrew : false
                break;
            case 'Add Work Week':
                showSave = userPermissions?.isModifyWorkWeek ? userPermissions?.isModifyWorkWeek : false
                break;
            case 'New Season':
                showSave = userPermissions?.isModifySeasons ? userPermissions?.isModifySeasons : false
                break;
            case 'New Show':
                showSave = userPermissions?.isModifyShows ? userPermissions?.isModifyShows : false
                break;
            case 'Change Location':
                showSave = userPermissions?.isModifyLocation ? userPermissions?.isModifyLocation : false
                break;
            case 'New Audition':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Edit Rider':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Add Performer':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break;
            case 'Add New Performer':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break; 
            case 'Add Performer to Episode':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break;        
            case 'Manage Lookups':
                showSave = userPermissions?.isManageLookups ? userPermissions?.isManageLookups : false
                break;
            case 'Data Query':
                showSave = userPermissions?.isManageDataQuery ? userPermissions?.isManageDataQuery : false
                break;
            case 'Edit Work Activity':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Send Contract':
                showSave = true
                break;
        }
        return showSave;
        // this.setState({ showSave: showSave });
    }
*/
    render() {

       const { classes } = this.props;
       let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? `Edit ${this.props?.headerText} has been created!`:
                (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
                    (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : ""; 
        return (
            <div className={"MainViewModalContainer"}>
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog  size={this.getClassesForModal()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className="padding-hor-12">
                                        <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader" id={"modal_header"}>
                                                    <MDBCol 
                                                        md={(this.props?.modalName === 'CastList PDF' || this.props?.modalName === "Deal Memo PDF") ? 6 : 5}
                                                        className="HeaderText" >{(this.props.mode === 'Both' ? "" : this.props.mode) + " " + this.props?.headerText}</MDBCol>
                                                    <MDBCol 
                                                        md={(this.props?.modalName === 'CastList PDF' || this.props?.modalName === "Deal Memo PDF") ? 5 : 6}
                                                        className="HeaderText" ></MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content">
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withUserContext(withStyles(useStyles)(MainViewModalComponent));
