import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import { intialReportDetails, sortList } from './Config';
import './Reports.scss'
import BillingList from './BillingList';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import CircularProgress from '@material-ui/core/CircularProgress';
import CastingDataReport from './CastingDataReport';
import CastList from './CastList';
import ContractSatusReport from './ContractSatusReport';
import CrewListWorkSheet from './CrewListWorkSheet';
import DealWorksheet from './DealWorksheet';
import MissingBirthdayReport from './MissingBirthdayReport';
import I9StatusReport from './I9StatusReport';
import PostSyncTimeLog from './PostSyncTimeLog';
import Station12Report from './Station12Report';
import VoiceOverPayMemo from './VoiceOverPayMemo';

class ReportsContaner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      reportDetails: {
        0: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        1: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        2: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        3: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        4: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        5: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        6: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        7: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        8: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        9: JSON.parse(JSON.stringify({ ...intialReportDetails })),
        10: JSON.parse(JSON.stringify({ ...intialReportDetails }))
      },
      postInitiated: false,
      unionList: [],
      companyList: [],
      projectList: [],
      sortList: sortList,
      saveFlag: false,
      performerTypeOptions: [],
      feeOptions: [],
      I9StatusOptions: [],
      invalidDate: false,
      PerformerValue: [],
      contract_type: null,
    }
  }

  componentDidMount = () => {
    this.getLookupValues('PROJECT', '')
    // this.getLookupValues('PRODUCTION_COMPANIES', '')
    // this.getLookupTypeValues('PERFORMER_TYPE')
    // this.getLookupTypeValues('FEE_TYPE')
    this.getLookupTypeValues('I9_STATUS');
    this.getLookupTypeValues('UNION');
    this.getProductionCompanies('PROJECT_PROD_COMPANIES','',null)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tabValue !== state.tabValue) {
      return {
        tabValue: props.tabValue,
      }
    }
    return null
  }

  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success": return this.setState({ showNotifSuccess: value, saveFlag: true });
      case "fail": return this.setState({ showNotifFail: value });
      case "noData": return this.setState({ noData: value, saveFlag: true });
    }
  }

  handleResetNotify = () => {
    this.setState({ showNotifFail: false, showNotifSuccess: false, noData: false });
    if (this.state.saveFlag) {
      this.setState({ saveFlag: false }, () => { this.props?.handleClose })
    }
  }

  getSearchValues = (objectType, searchString = "") => {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
        .then(response => {
            if (!response || response?.data?.error) {
                console.log("error", response?.data?.error)
            }
            else {
                if (objectType === "PERFORMER_TYPE") {
                    let formattedList = response?.data?.map((item) => ({ label: item.performer_type, value: item.performer_type, contract_type: item?.contract_type }))
                    this.setState({ performerTypeOptions: formattedList })
                }
                else if (objectType === "FEE_TYPE") {
                    let formattedList = response?.data?.map((item) => ({ label: item.fee_type, value: item.fee_type }))
                    this.setState({ feeOptions: formattedList })
                } 
            }
        }, err => {
            console.log("error from lookup")
        })
}

  handleOnChange = (field, value) => {
    const { tabValue } = this.state;
    let newValue = value?.value
    if (field === "project_id") {
      this.setState((prevState) => ({
        reportDetails: {
          ...prevState.reportDetails,
          [tabValue]: {
            ...prevState.reportDetails[tabValue],
            [field]: newValue,
            ["selectedprojectValue"]: value
          },
        }
      }
      ), () => {
        this.setState({ selectedprojectValue: value }, () => {
          if (this.state.tabValue === 10 && this.state.reportDetails[tabValue].project_id) {
            this.getVoiceOverPerformerValues("VOICE_OVER_PERFORMER", this.state.reportDetails[tabValue]?.project_id)
          }else if(this.state.tabValue === 0 || this.state.tabValue === 5 && this.state.reportDetails[tabValue].project_id){
            this.getPerformerValues("PROJECT_PERFORMERS","",this.state.reportDetails[tabValue]?.project_id)
            this.getSearchValues("PERFORMER_TYPE",value?.contract_type);
          }
          this.getProductionCompanies('PROJECT_PROD_COMPANIES','',value?.value)
          this.getSearchValues("PERFORMER_TYPE",value?.contract_type);
          this.setState((prevState) => ({
            reportDetails: {
              ...prevState.reportDetails,
              [tabValue]: {
                ...prevState.reportDetails[tabValue],
              submitted_by: value?.submitted_by || "", 
            },}
          }));
        })
      })
    } else if(field === "talent_id"){
      this.setState((prevState) => ({
        reportDetails: {
          ...prevState.reportDetails,
          [tabValue]: {
            ...prevState.reportDetails[tabValue],
            [field]: newValue,
            ["selectedTalentValue"]: value,
          },
        }
      }
      ), () => {
        this.setState({selectedTalentValue: value})
      })
    }else if(field === "performer_type"){
      this.setState((prevState) => ({
        reportDetails: {
          ...prevState.reportDetails,
          [tabValue]: {
            ...prevState.reportDetails[tabValue],
            [field]: value,
          },
        }
      }
      ), () => {
        this.setState({},()=>{
          this.getSearchValues("FEE_TYPE",this.state.reportDetails[tabValue]?.selectedprojectValue.contract_type + "_" + this.state.reportDetails[tabValue].performer_type)
        })
      })
    }else if (field === "is_deal_info" || field === "is_performer_address" || field === "is_include_unset_contract" || field === "is_deal_worksheet_schedule_f" || field === "stunt_only") {
      this.setState((prevState) => ({
        reportDetails: {
          ...prevState.reportDetails,
          [tabValue]: {
            ...prevState.reportDetails[tabValue],
            [field]: value ? 1 : 0,
          },
        },
      }))
    } else {
      this.setState((prevState) => ({
        reportDetails: {
          ...prevState.reportDetails,
          [tabValue]: {
            ...prevState.reportDetails[tabValue],
            [field]: value,
          },
        }
      }), () => {
        // if (this.state.reportDetails[tabValue].project_id && this.state.reportDetails[tabValue].production_company) {
        //   this.getProductionCompaneyUnions('PROJECT_PRODUCTION_COMPANIES_UNION', this.state.reportDetails[tabValue]?.production_company, this.state.reportDetails[tabValue]?.project_id)
        // }
        let invaildId = this.state.reportDetails[tabValue]?.start_date > this.state.reportDetails[tabValue]?.last_date ? true : false
        if (this.state.tabValue === 9) {
          this.setState({ invalidDate: invaildId })
        }
      }
      )
    }
  }

  getVoiceOverPerformerValues(objectType, project_id) {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&project_id=${project_id}`, this.props.userContext?.active_tenant?.tenant_id, true)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          let formattedList = response?.data?.map((item) => ({ label: item.selected_alias_name, value: item.performer_deal_id }))
          this.setState({ voiceOverPerformerList: formattedList })
        }
      }, err => {
        console.log("error from lookup")
      })
  }

  getPerformerValues(objectType,searchString,project_id) {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&project_id=${project_id}`, this.props.userContext?.active_tenant?.tenant_id, true)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          let formattedList = response?.data?.map((item) => ({ text: item.talent_name, value: item.talent_id }))
          this.setState({ PerformerValue: formattedList })
        }
      }, err => {
        console.log("error from lookup")
      })
  }

  getLookupValues(objectType, searchString) {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, true)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          if (objectType === 'PROJECT') {
            let projectData = response?.data?.map(item => ({ value: item?.project_id, text: item?.project_name,contract_type: item?.contract_type,submitted_by:item?.submitted_by }))
            this.setState({ projectList: projectData });
          // } else if (objectType === 'PRODUCTION_COMPANIES') {
          //   let formattedList = response?.data?.map((item) => ({ value: item?.production_company, label: item?.production_company }));
          //   this.setState({ companyList: formattedList })
           } 
          // else if (objectType === 'PERFORMER_TYPE') {
          //   let formattedList = response?.data?.map((item) => ({ label: item.performer_type, value: item.performer_type, contract_type: item?.contract_type }))
          //   this.setState({ performerTypeOptions: formattedList })
          // } else if (objectType === "FEE_TYPE") {
          //   let formattedList = response?.data?.map((item) => ({ label: item.fee_type, value: item.fee_type }))
          //   this.setState({ feeOptions: formattedList })
          // }

        }
      }, err => {
        console.log("error from lookup")
      })
  }

  getProductionCompanies = (objectType, searchString, projectId) => {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&project_id=${projectId}`, this.props.userContext?.active_tenant?.tenant_id, true)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          let formattedList = response?.data?.map((item) => ({ value: item?.production_company, label: item?.production_company }));
          this.setState({ companyList: formattedList })
        }
      }, err => {
        console.log("error from lookup")
      })
  }

  getLookupTypeValues(objectType) {
    let endPoint = null
    if (objectType === "I9_STATUS"|| objectType === "UNION" ) {
      endPoint = 'casterFeatureLookup'
    } else {
      endPoint = 'casterFeatureSearch'
    }
    CasterService.getData(Constants.CasterServiceBaseUrl + `/${endPoint}?objectType=${objectType}`, this.props.userContext?.active_tenant?.tenant_id, true)
      .then(response => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error)
        }
        else {
          if (objectType === "PERFORMER_TYPE") {
            let formattedList = response?.data?.map((item) => ({ label: item.performer_type, value: item.performer_type, contract_type: item?.contract_type }))
            this.setState({ performerTypeOptions: formattedList })
          } else if (objectType === "FEE_TYPE") {
            let formattedList = response?.data?.map((item) => ({ label: item.fee_type, value: item.fee_type }))
            this.setState({ feeOptions: formattedList })
          } else if (objectType === "I9_STATUS") {
            let formattedList = response?.data?.map((item) => ({ value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ I9StatusOptions: formattedList })
          } else if (objectType === "UNION") {
            let formattedList = response?.data?.map((item) => ({ value: item?.lookup_value, label: item?.lookup_value }))
            this.setState({ unionList: formattedList })
          }
        }
      }, err => {
        console.log("error from lookup")
      })
  }



  // getProductionCompaneyUnions = (objectType, searchString, projectId) => {
  //   this.setState({ loading: true });
  //   CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&project_id=${projectId}`, this.props.userContext?.active_tenant?.tenant_id, true)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item?.union, label: item?.union }));
  //       this.setState({
  //         unionList: formattedList,
  //         loading: false
  //       })

  //     },
  //       (err) => {
  //         console.log("Error in fetching Company Details:", err)
  //       })
  // }


  getTabComponent = () => {
    switch (this.props.tabValue) {
      case 0: return <BillingList
        newUnionOptions={this.state.newUnionOptions}
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[0]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[0]?.selectedprojectValue}
        selectedTalentValue={this.state.reportDetails[0]?.selectedTalentValue}
        PerformerValue={this.state.PerformerValue}
        getPerformerValues={this.getPerformerValues.bind(this)}
      />
      case 1: return <CastingDataReport
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[1]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[1]?.selectedprojectValue}
        submitted_by={this.state.reportDetails[1]?.submitted_by}
      />
      case 2: return <CastList
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[2]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[2]?.selectedprojectValue}
      />
      case 3: return <ContractSatusReport
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[3]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[3]?.selectedprojectValue}
        performerTypeOptions={this.state.performerTypeOptions}
        feeOptions={this.state.feeOptions}
      />
      case 4: return <CrewListWorkSheet
        projectList={this.state.projectList}
        reportDetails={this.state.reportDetails[4]}
        handleOnChange={this.handleOnChange}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[4]?.selectedprojectValue}
      />
      case 5: return <DealWorksheet
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[5]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[5]?.selectedprojectValue}
        performerTypeOptions={this.state.performerTypeOptions}
        feeOptions={this.state.feeOptions}
        getProductionCompanies={this.getProductionCompanies}
        selectedTalentValue={this.state.reportDetails[5]?.selectedTalentValue}
        PerformerValue={this.state.PerformerValue}
        getPerformerValues={this.getPerformerValues.bind(this)}
      />
      case 6: return <I9StatusReport
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[6]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[6]?.selectedprojectValue}
        performerTypeOptions={this.state.performerTypeOptions}
        feeOptions={this.state.feeOptions}
        I9StatusOptions={this.state.I9StatusOptions}
      />
      case 7: return <MissingBirthdayReport
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[7]}
        handleOnChange={this.handleOnChange}
        sortList={this.state.sortList}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[7]?.selectedprojectValue}
        performerTypeOptions={this.state.performerTypeOptions}
        feeOptions={this.state.feeOptions}
      />
      case 8: return <PostSyncTimeLog
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[8]}
        handleOnChange={this.handleOnChange}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[8]?.selectedprojectValue}
      />
      case 9: return <Station12Report
        unionList={this.state.unionList}
        projectList={this.state.projectList}
        companyList={this.state.companyList}
        reportDetails={this.state.reportDetails[9]}
        handleOnChange={this.handleOnChange}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[9]?.selectedprojectValue}
        performerTypeOptions={this.state.performerTypeOptions}
        feeOptions={this.state.feeOptions}
      />
      case 10: return <VoiceOverPayMemo
        projectList={this.state.projectList}
        reportDetails={this.state.reportDetails[10]}
        handleOnChange={this.handleOnChange}
        getLookupValues={() => this.getLookupValues.bind(this)}
        selectedprojectValue={this.state.reportDetails[10]?.selectedprojectValue}
        voiceOverPerformerList={this.state.voiceOverPerformerList}
      />
    }
  }

  onClickGenratePdf(identifier) {
    identifier === "Pdf" ?
      this.setState({ postInitiated: true }) : identifier === "Word" ? this.setState({ wordLoder: true }) : this.setState({ excelLoader: true })
    let searchPayload = null
    if (this.state.tabValue === 0) {
      searchPayload = {
        project_id: this.state.reportDetails[0].project_id,
        union: this.state.reportDetails[0]?.union,
        production_company: this.state.reportDetails[0]?.production_company,
        sort_by_clause: this.state.reportDetails[0]?.sort_by_clause,
        talent_id: this.state.reportDetails[0]?.talent_id
      }
    } else if (this.state.tabValue === 1) {
      searchPayload = {
        project_id: this.state.reportDetails[1]?.project_id,
        production_company: this.state.reportDetails[1]?.production_company,
        phone: this.state.reportDetails[1]?.phone,
        stunt_only: this.state.reportDetails[1]?.stunt_only,
        submitted_by: this.state.reportDetails[1]?.submitted_by
      }
    }
    else if (this.state.tabValue === 2) {
      searchPayload = {
        project_id: this.state.reportDetails[2]?.project_id,
        union: this.state.reportDetails[2]?.union,
        production_company: this.state.reportDetails[2]?.production_company,
        sort_by_clause: this.state.reportDetails[2]?.sort_by_clause,
        is_performer_address: this.state.reportDetails[2]?.is_performer_address,
        is_deal_info: this.state.reportDetails[2]?.is_deal_info
      }
    } else if (this.state.tabValue === 3) {
      searchPayload = {
        project_id: this.state.reportDetails[3]?.project_id,
        union: this.state.reportDetails[3]?.union,
        production_company: this.state.reportDetails[3]?.production_company,
        is_include_unsent_contract: this.state.reportDetails[3]?.is_include_unset_contract,
        performer_type: this.state.reportDetails[3]?.performer_type,
        fee_type: this.state.reportDetails[3]?.fee_type
      }
    } else if (this.state.tabValue === 4) {
      searchPayload = {
        project_id: this.state.reportDetails[4]?.project_id,
      }
    } else if (this.state.tabValue === 5) {
      searchPayload = {
        project_id: this.state.reportDetails[5]?.project_id,
        union: this.state.reportDetails[5]?.union,
        production_company: this.state.reportDetails[5]?.production_company,
        performer_type: this.state.reportDetails[5]?.performer_type,
        fee_type: this.state.reportDetails[5]?.fee_type,
        type: this.state.reportDetails[5]?.is_deal_worksheet_schedule_f === 1 ? "ScheduleF" : null,
        talent_id: this.state.reportDetails[5]?.talent_id
      }
    } else if (this.state.tabValue === 6) {
      searchPayload = {
        project_id: this.state.reportDetails[6]?.project_id,
        union: this.state.reportDetails[6]?.union,
        production_company: this.state.reportDetails[6]?.production_company,
        is_include_unset_contract: this.state.reportDetails[6]?.is_include_unset_contract,
        performer_type: this.state.reportDetails[6]?.performer_type,
        fee_type: this.state.reportDetails[6]?.fee_type,
        i9_status: this.state.reportDetails[6]?.i9_status
      }
    } else if (this.state.tabValue === 7) {
      searchPayload = {
        project_id: this.state.reportDetails[7]?.project_id,
        union: this.state.reportDetails[7]?.union,
        production_company: this.state.reportDetails[7]?.production_company,
        performer_type: this.state.reportDetails[7]?.performer_type,
        fee_type: this.state.reportDetails[7]?.fee_type
      }
    } else if (this.state.tabValue === 8) {
      searchPayload = {
        project_id: this.state.reportDetails[8]?.project_id,
        union: this.state.reportDetails[8]?.union,
        production_company: this.state.reportDetails[8]?.production_company,
        location: this.state.reportDetails[8]?.location,
        work_date: this.state.reportDetails[8]?.work_date
      }
    } else if (this.state.tabValue === 9) {
      searchPayload = {
        project_id: this.state.reportDetails[9]?.project_id,
        union: this.state.reportDetails[9]?.union,
        production_company: this.state.reportDetails[9]?.production_company,
        start_date: this.state.reportDetails[9]?.start_date,
        last_date: this.state.reportDetails[9]?.last_date
      }
    } else if (this.state.tabValue === 10) {
      searchPayload = {
        project_id: this.state.reportDetails[10]?.project_id,
        performer_deal_id: this.state.reportDetails[10]?.performer_deal_id,
      }
    }
    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    let payload = {}
    let load = {
      payload: "casterFeatureGenerateReport",
      reportName: this.state.tabValue === 0 ? "rptBillingList" : (this.state.tabValue === 1 && this.state.reportDetails[1]?.stunt_only === 0) ? "rptCastingData" : (this.state.tabValue === 1 && this.state.reportDetails[1]?.stunt_only === 1) ? "rptCastingDataStuntOnly" : this.state.tabValue === 2 ? "rptCastList" : this.state.tabValue === 3 ? "rptContractStatus" : this.state.tabValue === 4 ? "rptCrewListWorksheet" :
        (this.state.tabValue === 5 && this.state.reportDetails[5]?.is_deal_worksheet_schedule_f === 1) ? "rptDealWorksheetScheduleF" : (this.state.tabValue === 5 && this.state.reportDetails[5]?.is_deal_worksheet_schedule_f === 0) ? "rptDealWorksheet" : this.state.tabValue === 6 ? "rptI9Status" : this.state.tabValue === 7 ? "rptMissingBirthDate" :
          this.state.tabValue === 8 ? "rptPostSyncTimeLog" : this.state.tabValue === 9 ? "rptStation12" : this.state.tabValue === 10 ? "rptVoiceOverPayMemo" : null,
      fileName: this.state.tabValue === 0 ? "Billing List" : (this.state.tabValue === 1 && this.state.reportDetails[1]?.stunt_only === 0) ? "Casting Data" : (this.state.tabValue === 1 && this.state.reportDetails[1]?.stunt_only === 1) ? "Casting Data StuntOnly" : this.state.tabValue === 2 ? "Cast List" : this.state.tabValue === 3 ? "Contract Status Report" : this.state.tabValue === 4 ? "Crew List Worksheet" : (this.state.tabValue === 5 && this.state.reportDetails[5]?.is_deal_worksheet_schedule_f === 0) ? "Deal Worksheet" : (this.state.tabValue === 5 && this.state.reportDetails[5]?.is_deal_worksheet_schedule_f === 1) ? "Deal Worksheet ScheduleF" :
        this.state.tabValue === 6 ? "I9 Status Report" : this.state.tabValue === 7 ? "Missing Birthdate Report" : this.state.tabValue === 8 ? "Post Sync Time Log" : this.state.tabValue === 9 ? "Station 12" : this.state.tabValue === 10 ? "Voice Over Pay Memo" : null,
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1
    };
    if (identifier === "Word") {
      load.docFlag = 1
    } else {
      load = load
    }
    CasterService.getDataWS(load).then(
      (webSocketConn) =>
      (webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data);
        if (response?.body === "No Data Found" || response?.body === "Report template not found") {
          this.setState({ postInitiated: false, wordLoder: false, excelLoader: false })
          this.notificationComponent(true, "noData")
        }
        else if (response?.statusCode == 200) {
          this.setState({ postInitiated: false, wordLoder: false, excelLoader: false })
          const filename = extractFilenameFromUrl(response?.body);
          let decodedFile = decodeURIComponent(filename)
          downloadUrl(response?.body, decodedFile);
          this.notificationComponent(true, "success")
        }
        // }else{
        //   this.setState({ postInitiated: false })
        //   this.notificationComponent(true,"fail")
        // }
      })
    );
  }

  render() {
    let severity = this.state.showNotifFail ? "error" : this.state.showNotifSuccess ? "success" : this.state.noData ? "success" : "";
    let messageNotification = this.state.showNotifSuccess ? "The changes are saved successfully!" : this.state.showNotifFail ? "Saving changes failed!" : this.state.noData ? "No data Found" : "";
    return (
      <div className="ReportsContainer">
        <NotificationComponent style={{ marginTop: "1000px" }} open={this.state.showNotifSuccess || this.state.showNotifFail || this.state.noData} message={messageNotification} severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)} />
        <MDBContainer>
          {this.getTabComponent()}
        </MDBContainer>
        <MDBRow className='report-btn'>
          <MDBCol md={3}>
            <BasicButton className="wordBtn"
              icon="file-export"
              disabled={this.state.invalidDate ? true : false}
              text={this.state.wordLoder ? (
                <CircularProgress color="inherit" size={18} />
              ) : "Word"} onClick={() => this.onClickGenratePdf("Word")} />
          </MDBCol>
          {/* <MDBCol md={3} >
                <BasicButton  className='excleBtn'  
                disabled={this.state.invalidDate ? true : false}
                text={this.state.excelLoader ? (
                  <CircularProgress color="inherit" size={18} />
                ) :"Excel"} icon="file-export" 
                //  onClick={()=>this.onClickGenratePdf("Excel")}
                 />
              </MDBCol> */}
          <MDBCol md={3}>
            <BasicButton
              className='pdfBtn'
              icon="file-export"
              disabled={this.state.invalidDate ? true : false}
              text={this.state.postInitiated ? (
                <CircularProgress color="inherit" size={18} />
              ) : "PDF"}
              onClick={() => this.onClickGenratePdf("Pdf")} />
          </MDBCol>
          <MDBCol md={3}>
            <BasicButton variant="outlined"
              className='cancelBtn'
              text={"Cancel"} onClick={this.props?.handleClose} />
          </MDBCol>
        </MDBRow>
      </div>


    );
  }

}
export default withUserContext(withNotificationContext(ReportsContaner));