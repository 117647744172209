import React from 'react';
import ProjectDetailsTab from './ProjectDetailsTab';
import Credits from './Credits';
import ContactsAndPayments from './ContactsAndPayments';
import Perks from '../Project/Perks';
import WorkActivity from './WorkActivity';
import CompensationTab from './Compensation/CompensationTab';
import './Performer.scss'

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <div className='performerSummaryContainer'>
                <div className='udtext'>Names/Project Details</div>
                <ProjectDetailsTab performerDetails={this.props.performerDetails} age={this.props.age} />
                <div className='udtext mt-2'>Contacts & Payments</div>
                <ContactsAndPayments summary={true}
                    talent_name={this.props?.talent_name}
                    performer_deal_id={this.props?.dealId}
                    toggleRefreshPage={this.props?.toggleRefreshPage}
                    refreshPage={this.props?.refreshPage}
                    notificationComponent={this.props?.notificationComponent}
                    project_id={this.props?.project_id}
                />
                <div className='udtext mt-2'>Compensation</div>
                <CompensationTab summary={true}
                    performer_deal_id={this.props?.performer_deal_id}
                    contract_type={this.props?.contract_type}
                    loanout_present={this.props?.loanout_present}
                    notificationComponent={this.props?.notificationComponent}
                    default_currency={this.props?.default_currency}
                    deal_date={this.props.performerDetails?.deal_date}
                    project_id={this.props?.project_id} />
                <div className='udtext mt-2'>Credits</div>
                <Credits dealId={this.props?.dealId} summary={true} />
                <div className='udtext mt-2'>Perks</div>
                <Perks
                    summary={true}
                    performer_deal_id={this.props?.dealId}
                    toggleRefreshPage={this.props?.toggleRefreshPage}
                    refreshPage={this.props?.refreshPage}
                    notificationComponent={this.props?.notificationComponent}
                    project_id={this.props?.project_id}
                    union={this.props?.performerDetails?.union}
                />
                <div className='udtext mt-2'>Work Activity</div>
                <WorkActivity summary={true}
                    performer_deal_id={this.props?.dealId}
                    toggleRefreshPage={this.props?.toggleRefreshPage}
                    refreshPage={this.props?.refreshPage}
                    notificationComponent={this.props?.notificationComponent}
                    project_id={this.props?.project_id}
                />
            </div>
        );
    }
}

export default Summary;
