import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import SelectField from  './SelectField/SelectField';
import BasicTextArea from './BasicTextArea/BasicTextArea';

class TableInlineEdits extends React.Component {
  constructor(props) {
    super(props);    
  }

  pickInlineEdits(inputType, inputValue, index, nodeName, selectOptions){
   
    switch(inputType) {
        case 'text':
          return <BasicTextField id={"txt-"+nodeName+"-"+index} 
                    value={inputValue}    
                    isAlphabetVar={this.props?.isAlphabetVar || false}   
                    {...(this.props?.isAlphabetVar ? {handleAlphabetVariantSelect:((val) => {this.props.handleInlineEditChange(val, index, nodeName)})} :{})}                 
                    onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}          
                    />;
          break;
        case 'textarea':
          return <BasicTextArea id={"txtarea-"+nodeName+"-"+index} 
                    value={inputValue}
                    rows ={2}  
                    showAsLabel = {false}  
                    onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}          
          />;
          break;
        case 'checkbox':
          return (<BasicCheckbox className="inlineCheckbox" id={"cb-"+nodeName+"-"+index}  checked ={inputValue == 1 ? true : false}
                      //onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1": "0"))}                          
                      onChange={(e) => this.props.handleInlineEditChange((e.target.checked ? 1: 0), index, nodeName)}
                        />);
          break;
          case 'datefield':
            return <DateField
              id={"date-" + nodeName + "-" + index}
              size="small"
              width={this.props?.width}
              value={inputValue || ""}
              onChange={(e) => this.props.handleInlineEditChange(e.target.value.length > 0 ? e.target.value : null, index, nodeName)}
              disabled={this.props?.fieldDisabled}
            />;
            break;
        case 'select':
          return <SelectField
                      id={"sel-"+nodeName+"-"+index} 
                      size="small"
                      label={name}
                      value={inputValue || ""}
                      options={selectOptions}
                      onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}                                   
                                  />;   
          break;                     
        default:
          return <></>;
      }

  }


  render() {
    return (
    <>
    {this.pickInlineEdits(this.props?.inputType, this.props?.inputValue, this.props?.index, this.props?.nodeName, this.props?.selectOptions)}
    </>) 
  }
}

export default TableInlineEdits;