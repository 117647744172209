export const projectConfig = {
    headings: [
        {
            headingLabel: " ",
            width: "5%"
        },
        {
            headingLabel: "Project",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Season Number",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Season Year",
            width: "15%"
        },
        {
            headingLabel: "Outlet",
            width: "15%"
        },
        {
            headingLabel: "Type",
            width: "15%"
        },
        {
            headingLabel: "Episodes",
            width: "10%"
        },
        {
            headingLabel: "Proj.Notes",
            width: "10%",
        }
    ],
    dataNodes: ["is_favorite","project_name", "season_no","season_year", "outlet_name", "project_type_name","episode_count","project_notes"],
    primaryKey: "project_id",
    actions:["pen","copy"],
    fileField:["project_notes"],
    nodeFields:["is_favorite"],
    hyperlinks: ["project_name"],
    // tooltips: ["project_name",],
    // toggleFields: ["show_season_year"],
    // fileAttachments: ["project_notes"," "],
    // nodeFields: [
    //     {
    //         column: " ",
    //         icon: "pen"
    //     },
    //     {
    //         column: "project_notes",
    //         icon: "file"
    //     },
    // ]
};

export const renderProjectList =[
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",

 },
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",
 },
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",

 },
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",
 },
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",

 },
 {
    project_name : "project",
    show_season_year: "2000-2001",
    outlet:"123",
    type:"type",
    episodes:"episoded",
 }
]

export const InitialCardData = [
    {
        title: "Pilot",
        season: "2020-2021",
        content: "Lego Batman(Season 1)",
        prodEntity: "HBO"
    },
    {
        title: "Episodic",
        season: "2020-2021",
        content: "Shameless (Season 1)",
        prodEntity: "Netflix"
    },
    {
        title: "Episodic",
        season: "2021-2022",
        content: "Shameless (Season 2)",
        prodEntity: "Netflix"
    },
    {
        title: "Mow",
        season: "2021-2022",
        content: "Shameless (Season 3)",
        prodEntity: "Netflix"
    },
    {
        title: "Mow",
        season: "2021-2022",
        content: "Shameless (Season 4)",
        prodEntity: "Netflix"
    },
    {
        title: "Mow",
        season: "2021-2022",
        content: "Shameless (Season 5)",
        prodEntity: "Netflix"
    },
    {
        title: "Mow",
        season: "2021-2022",
        content: "Shameless (Season 6)",
        prodEntity: "Netflix"
    },
    {
        title: "[Program Name]",
        season: "[Season]",
        content: "[x]",
        prodEntity: "[Prod Entity]"
    }
]