import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioGroup, Radio } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import './ExistingFile.scss';

class ExistingFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            existing_file_type: null,
        }
    }

    handleOnchange = (field, value) => {
        this.setState({ [field]: value })
    }

    handleSubmit = () => {
        this.props?.handleFileConfirm(this.state.existing_file_type)
    }

    render() {
        return (
            <MDBContainer fluid className="existingFileContainer">
                <MDBRow>
                    <span className="contract-heading">
                        {`An existing Contract was created on ${this.props?.createdDate} by
                        ${this.props?.createdBy}`}.
                    </span>
                </MDBRow>
                <MDBRow className="contract-type">
                    <RadioGroup
                        row
                        name="contract_to"
                        size="small"
                        value={this.state.existing_file_type}
                        onChange={(e) => this.handleOnchange('existing_file_type', e.target.value)}
                    >
                        <FormControlLabel
                            value="Open Existing"
                            control={<Radio size="small" />}
                            label={<span className="paragraph-small">Open Existing</span>}
                            size="small"
                        />
                        <FormControlLabel
                            value="Re-create"
                            control={<Radio size="small" />}
                            label={<span className="paragraph-small">Re-create</span>}
                            size="small"
                        />
                    </RadioGroup>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={7}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            text={
                                this.props?.isPosting ? (
                                    <CircularProgress color="inherit" size={18} />
                                ) : 'Confirm'
                            }
                            onClick={() => this.handleSubmit()}
                        />
                    </MDBCol>
                    <MDBCol>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={() => this.props?.handleFileCancel()}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default ExistingFile;