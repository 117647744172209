export const projectTabList = [
    { label: "Performers", index: 0, name: "performer" },
    { label: "Billing", index: 1, name: "billing" },
    { label: "Locations", index: 2, name: "locations" },
    { label: "Work Week", index: 3, name: "workweek" },
    { label: "Crew", index: 4, name: "crew" },
    { label: "Status Dates", index: 5, name: "statusdates" },
    { label: "Files", index: 6, name: "files" },
];
export const productionCompanyConfig = {
    headings: [
        {
            headingLabel: " ",
            width: "46%",
        },
        {
            headingLabel: " ",
            width: "24%"
        },
        {
            headingLabel: " ",
            width: "10%"
        },
        {
            headingLabel: " ",
            width: "20%",
        }],
    dataNodes: ["production_company", "notes", "union", "signator"],
    primaryKey: "project_production_company_id",
    hyperlinks: ['production_company'],
    projectDetailsProdCompanyTable: true
}
export const projectCrewConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "18%",
            icon: "sort-alpha-up"
        },
        {
            headingLabel: "Occupation",
            width: "17%",
            icon: ""
        },
        {
            headingLabel: "Phone",          
            width: "20%",
        },
        {
            headingLabel: "Email",          
            width: "29%",
        },
        {
            headingLabel: "Company",          
            width: "18%",
            icon: ""
        },
        {
            headingLabel: "Location",          
            width: "18%",
            icon: ""
        },
        {
            headingLabel: " ",
            width: "5%",
        }
    ],
    dataNodes: ["crew_name", "occupation", "phone", "email", "company", "location_name"],
    primaryKey: "project_crew_id",
    hyperlinks: ['crew_name'],
    actions: ["pen", "times"],
    isList: ["phone", "email"]
}

export const BillingListConfig = {
    headings: [
        {
            headingLabel: "Performer",
            icon: "sort-alpha-up",
            width: "15%",
            inputType: "text",
            border: true,
            isAlphabetVar: true,
            dataNode: "performer_name",
            placeholder: "Search Performer"
        },
        {
            headingLabel: "Role #",
            icon: "",
            width: "4.5%",
            inputType: "text",
            border: true,
            dataNode: "role_no",
            placeholder: "Search"
        },
        {
            headingLabel: "Role Name",
            icon: "",
            width: "12.1%",
            inputType: "text",
            border: true,
            dataNode: "role",
            placeholder: "Search Role Name"
        },
        {
            headingLabel: "Union",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true,
            dataNode: "union",
            placeholder: "Search"
        },
        {
            headingLabel: "Deal Date",
            icon: "",
            width: "7.3%",
            inputType: "datefield",
            border: true,
            dataNode: "deal_date",
            placeholder: "mm/dd/yyyy"
        },
        {
            headingLabel: "MT",
            icon: "",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            //filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "mt_credits",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "ET",
            icon: "",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            // filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "et_credits",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "PA",
            icon: "",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            // filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "pa_credits",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "Cut",
            icon: "",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            // filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "cut",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "NC",
            icon: "",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            // filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "nc",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "Confirmed By",
            icon: "",
            width: "8.1%",
            inputType: "select",
            selectOptions: [{ label: "-Select-", value: null },
            { label: "Not Confirmed", value: 'Not Confirmed' },
            { label: "Phone", value: 'Phone' },
            { label: "Email", value: 'Email' }],
            border: true,
            filterType: 'text',
            // filterOptions: [{ label: "Not Confirmed", value: 'Not Confirmed' },
            // { label: "Phone", value: 'Phone' },
            // { label: "Email", value: 'Email' }],
            dataNode: "confirmed_by",
            placeholder: "Search"
        },
        {
            headingLabel: "",
            icon: "",
            chipAlternateText: "Attachment",
            width: "4%",
            inputType: "checkbox",
            border: true,
            filterType: 'text',
            //filterOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            dataNode: "currentFileName",
            placeholder: "Yes/No"
        },
        {
            headingLabel: "Notes",
            icon: "",
            width: "21%",
            inputType: "textarea",
            border: true,
            dataNode: "notes",
            placeholder: "Search Notes",
            filterType: 'text',
        },
        {
            headingLabel: "",
            icon: "",
            width: "3%",
            border: true,
        }
    ],
    dataNodes: ["performer_name", "role_no", "role", "union", "deal_date", "mt_credits", "et_credits", "pa_credits",
        "cut", "nc", "confirmed_by", "currentFileName", "notes"],
    borders: ["performer_name", "role_no", "role", "union", "deal_date", "mt_credits", "et_credits", "pa_credits",
        "cut", "nc", "confirmed_by", "currentFileName", "notes"],
    primaryKey: "performer_deal_billing_id",
    hyperlinks: ["performer_name"],
    inlineEdits: ["credit_as", "mt_credits", "et_credits", "pa_credits", "cut", "nc", "confirmed_by", "notes"],
    tooltips: ["mt_credits", "et_credits", "pa_credits"],
    creditTooltipsText: { mt_credits: "mt_text", et_credits: "et_text", pa_credits: "pa_text" },
    colouredCoulms: [{
        columnName: 'confirmed_by', colours: {
            Not: 'red',
        }
    }],
    dataNodeGroups: { "performer_name": [{ nodeHeader: 'PKA : ', nodeValue: "pka_name", textColor: null }, { nodeHeader: 'Credit As : ', nodeValue: "credit_as", showLabelWhenNull:true,textColor: 'darkGreenText', isAlphabetVar: true }] },
    filters: true,
    toggleFields: ["mt_credits", "et_credits", "pa_credits", "cut", "nc"],
    fileAttachments: ["currentFileName"],
    actions: ["pen"],
    showEditIcon: true,
    titleAction: true,
    popUpField: ['notes'],
    identityKey: "billingTable",
    filtersLanding: true,
};

export const WorkWeekConfig = {
    headings: [
        {
            headingLabel: "Start",
            icon: "",
            width: "10%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Finish",
            icon: "",
            width: "10%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "# Days",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Sun",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Mon",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Tue",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Wed",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Thu",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Fri",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Sat",
            icon: "",
            width: "5%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Comments",
            icon: "",
            width: "35%",
            inputType: "textarea",
            border: true
        }
    ],
    dataNodes: ["start_date", "finish_date", "days", "sun", "mon", "tue", "wed", "thu", "fri",
        "sat", "comments"],
    borders: ["days", "comments"],
    primaryKey: "project_work_week_id",
    actions: ["pen", "trash-alt"],
};

export const StatusDatesConfig = {
    headings: [
        {
            headingLabel: "From",
            icon: "",
            width: "15%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "To",
            icon: "",
            width: "15%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Status",
            icon: "",
            width: "20%",
            inputType: "text",
            border: true
        },
        {
            headingLabel: "Notes",
            icon: "",
            width: "40%",
            inputType: "textarea",
            border: true
        }
    ],
    dataNodes: ["from_date", "to_date", "status", "notes"],
    primaryKey: "project_status_dates_id",
    actions: ["pen", "trash-alt"],
};

export const initialWorkWeekDetails = {
    "project_work_week_id": null,
    "project_id": null,
    "start_date": null,
    "finish_date": null,
    "mon": null,
    "tue": null,
    "wed": null,
    "thu": null,
    "fri": null,
    "sat": null,
    "sun": null,
    "days": null,
    "comments": null
}

export const initialStatusDatesDetails = {
    "project_status_dates_id": null,
    "project_id": null,
    "from_date": null,
    "to_date": null,
    "notes": null,
    "status": null,
    "is_delete": "0",
}

export const standardLanguageCompensationOptions = [
    {
        value: 'Add Compensation Language',
        text: "Add Compensation Language"
    }
]
export const standardLanguageExpenseOptions = [
    {
        value: 'Add Resident/Overseas Trans. Language',
        text: "Add Resident/Overseas Trans. Language"
    },
    {
        value: "Add Local Trans. Language",
        text: "Add Local Trans. Language"
    }
]
export const PerkNewConfig = {
    "is_delete": 0,
    "performer_deal_perks_id": null,
    "performer_deal_id": null,
    "performer_deal_contract_id": null,
    "contract_date": null,
    "memo_type": null,
    "memo_text": null
}
export const blueResidentPerkText = `<pre>
    Where the Performer is required by the Producer to render services at a Resident Location or an Overseas Location, the Producer shall provide the following:  
(a)     one (1) economy class return transportation, or non-exclusive return transportation by car, as appropriate, between the Artist's residence and such Location (on an "if available and if used" basis);
(b)     hotel accommodation and a non-accountable living allowance of [£               ] for each day on which the Artist is required by the Producer to be present at such Location;
(c)     non-exclusive ground transportation between the Artist's home and any airport/station which the Artist is required to transit, and between the Artist's hotel accommodation and the place where the Artist's services are required.</pre>`

export const greenLocalPerkText = `<pre>Where the Artist is required by the Producer to render services during principal photography of the Film, the Producer shall provide the following:  
(a)	non-exclusive ground transportation between the Artist's home and  the place where the Artist's services are required.

While the Artist is rendering services during principal photography of the Film, the Producer will provide the Artist with one-third of a three-way trailer for the Artist's exclusive use.</pre>
` 