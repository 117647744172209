import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import { initialWorkWeekDetails } from './config';;
import CircularProgress from "@material-ui/core/CircularProgress";
import './WorkWeekModal.scss';

export default class WorkWeekModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workWeekDetails: {...initialWorkWeekDetails}, 
            allSelectedDates:[],
            postInitiated: false,
            isPosting: false,
            weekDays: [{value : 0 , label: 'Sun'},{value : 1 , label: 'Mon'},{value : 2 , label: 'Tue'},{value : 3 , label: 'Wed'},
                        {value : 4 , label: 'Thu'},{value : 5 , label: 'Fri'},{value : 6 , label: 'Sat'}]
        }

    }

    handleChange = (field, value) => {
        this.setState(prevState => ({
            workWeekDetails: {
                ...prevState.workWeekDetails,
                [field]: value
            },
            postInitiated: false,
        }), () => {
            if (field === 'start_date' || field === 'finish_date') {
                let workWeekDetails = {...this.state.workWeekDetails}
                let allDates = []
                if(workWeekDetails?.start_date && workWeekDetails?.finish_date) {
                    workWeekDetails.days = ((new Date(workWeekDetails?.finish_date?.replaceAll('-','/'))?.getTime() - new Date(workWeekDetails.start_date?.replaceAll('-','/'))?.getTime()) / (1000 * 3600 * 24)) + 1;
                    for(let i = 0 ; i < workWeekDetails?.days && workWeekDetails?.days > 0 && workWeekDetails.days < 8  ; i++) {
                        allDates.push({label: this.getDateOfWeek(workWeekDetails?.start_date, i), value: 1 , date: this.getDateforPost(workWeekDetails?.start_date, i)})
                    }
                }
                this.setState({workWeekDetails:workWeekDetails, allSelectedDates:allDates})
            }
        })

        this.props.fieldChanged(true, "change");
    }

    getDateOfWeek = (dateToChange, plusDays) => {
        const dateOfDay = new Date(dateToChange?.replaceAll('-','/'))
        dateOfDay.setDate(dateOfDay.getDate() + plusDays)
        const exactDate = dateOfDay.getDate()
        let day = this.state.weekDays?.find(item => item?.value === (new Date(dateOfDay)?.getDay()))?.label
        return `${day}, ${exactDate}`
      }

    handleCheck(field, value, index) {
        let allSelectedDates = [...this.state.allSelectedDates]
        allSelectedDates[index].value = value
        this.setState({allSelectedDates:allSelectedDates})
    }
    getDateforPost = (dateToChange, plusDays) => {
        const dateOfDay = new Date(dateToChange?.replaceAll('-','/'))
        dateOfDay.setDate(dateOfDay.getDate() + plusDays)
        const yyyy = dateOfDay.getFullYear();
        let mm = dateOfDay.getMonth() + 1; 
        let dd = dateOfDay.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return yyyy+'-'+mm+'-'+dd
    }

    postWorkWeekDetails = () => {
        this.setState({ postInitiated: true})
        let workWeekDetails = {...this.state.workWeekDetails}
        if (workWeekDetails?.start_date && workWeekDetails?.days < 8 && workWeekDetails?.finish_date && new Date(workWeekDetails?.start_date) <= new Date(workWeekDetails?.finish_date)) {
            let dayCount = 0
            for(let i = 0; i < this.state.weekDays?.length ; i++ ) {
               let checkDay = this.state.allSelectedDates?.find(item => item?.label?.slice(0,3) === this.state.weekDays[i]?.label)
               if(checkDay && checkDay?.value === 1) {
                workWeekDetails[this.state.weekDays[i]?.label?.toLowerCase()] =  checkDay?.date || null;
                dayCount = dayCount+1 
               } else {
                workWeekDetails[this.state.weekDays[i]?.label?.toLowerCase()] =  null
               }
            }
            workWeekDetails.days = dayCount?.toString()
            workWeekDetails.is_delete = "0"
               this.setState({ postInitiated:false,isPosting:true })
                CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProjectWorkWeek', workWeekDetails )
                    .then((response) => {
                        if (response.data.error) {
                            this.props?.notificationComponent(true, 'fail')
                        } else {
                            if (this.props?.toggleRefreshPage) {
                                this.props.toggleRefreshPage()
                            }
                            this.props?.notificationComponent(true, 'success')
                        }
                        this.setState({ isPosting: false });
                    },
                        (err) => {
                            this.setState({ isPosting: false })
                            this.props?.notificationComponent(true, 'fail')
                        });
        }
    }

    componentDidMount() {
        if (this.props.mode === "new") {
            let workWeekData = {...this.state.workWeekDetails}
            workWeekData.project_id = this.props.projectId
            this.setState({ workWeekDetails: workWeekData })
        } else {
            let allDates = []
            let days = ((new Date(this.props?.workWeekData?.finish_date)?.getTime() - new Date(this.props?.workWeekData.start_date)?.getTime()) / (1000 * 3600 * 24)) + 1;
            for(let i = 0 ; i < days && days > 0 && days < 8  ; i++) {
                let dayLabel = this.getDateOfWeek(this.props?.workWeekData?.start_date, i)
                allDates.push({label:dayLabel, value: this.props?.workWeekData[dayLabel?.slice(0,3)?.toLowerCase()] ? 1 : 0, date: this.getDateforPost(this.props?.workWeekData?.start_date, i)})
            }
            let workWeekData = {...this.props?.workWeekData}
            let startDate = this.props?.workWeekData?.start_date?.split('/')
            let finishDate = this.props?.workWeekData?.finish_date?.split('/')
            workWeekData.start_date = startDate ? `${startDate[2]}-${startDate[0]}-${startDate[1]}` : null
            workWeekData.finish_date = finishDate ? `${finishDate[2]}-${finishDate[0]}-${finishDate[1]}` : null
            this.setState({ workWeekDetails: workWeekData , allSelectedDates : allDates})
        }
    }


    render() {
        return (
            <div className='casterFeatureWorkWeekModal'>
                <MDBRow>
                    <MDBCol md={4}>
                        <DateField
                            id="workStartDate"
                            label={"Start Date"}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            value={this.state.workWeekDetails?.start_date || ""}
                            onChange={(e) => this.handleChange('start_date', e.target.value ? e.target.value : null)}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <DateField
                            id="workEndtDate"
                            label={"Finish Date"}
                            isMandatory={true}
                            showErrorBorder={this.state.workWeekDetails?.finish_date && this.state.workWeekDetails?.start_date ? 
                             new Date(this.state.workWeekDetails?.start_date) > new Date(this.state.workWeekDetails?.finish_date) ? true : false : false}
                            onChange={(e) => this.handleChange('finish_date', e.target.value ? e.target.value : null)}
                            showMandatory={this.state.postInitiated || false}
                            value={this.state.workWeekDetails?.finish_date || ""}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    {this.state.allSelectedDates?.length  > 0 ? this.state.allSelectedDates?.map((item,i) => <MDBCol md={3}><BasicCheckbox label={item?.label} 
                     checked={item?.value === 1}
                                            onChange={e => this.handleCheck(item?.label, e.target?.checked ? 1 : 0,i)}
                                            align="start"
                                            id={item?.label}/></MDBCol>) : <span>Please choose appropriate start and finish dates(A week can have max 7 days)</span>}
                </MDBRow>
                <MDBRow id='workWeekTextArea'>
                    <BasicTextArea
                        label={"Comments"}
                        id={"textarea-performer"}
                        rows={6}
                        value={this.state.workWeekDetails?.comments || ""}
                        onChange={(e) => this.handleChange('comments', e.target.value.length > 0 ? e.target.value : null)}
                    />
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={6} >
                    </MDBCol>
                    <MDBCol md={3} className='d-flex justify-content-right'>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            type="inline"
                            icon='save'
                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props.mode === "new" ? "Create" : "Save"}
                            disabled={false}
                            onClick={this.postWorkWeekDetails}
                        />
                    </MDBCol>
                    <MDBCol md={3} className='d-flex justify-content-right'> 
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            icon='times'
                            disabled={false}
                            onClick={this.props.onClose}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}