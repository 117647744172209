import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import './Reports.scss';
import DateField from "../../components/SharedComponents/DateField/DateField";
import { genReportName } from "../../Common/Helper"
import ReportButton from './ReportButton';
import { withUserContext } from '../../../contexts/UserContext';
import SelectField from '../SharedComponents/SelectField/SelectField';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import SearchSelectField from '../SharedComponents/SearchSelectField';
import './SagTimeReport.scss'
import MessageModal from '../SharedComponents/MessageModal';

class SagTimeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails: [],
            sagTimeReportModal: false,
            pdfRes: null
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true })

        let searchPayload = {
            show_id: this.props?.reportDetails.show_id.value,
            show_season_id: this.props?.reportDetails.show_season_id,
            episode_id: this.props?.reportDetails.episode_id,
            work_date: this.props?.reportDetails?.work_date,
        };

        Object.keys(searchPayload).map((obj) => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        });
        let encryptTedStr = btoa(JSON.stringify(searchPayload));

        let load = {
            payload: "casterAnimationGenerateReport",
            reportName: "rptSAGTime",
            fileName: "rptSAGTime",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1
        };
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200 && response?.body?.message) {
                    this.setState({ sagTimeReportModal: true, pdfRes: response.body.message })
                }
                else {
                    this.setState({ postInitiated: false })
                    const filename = extractFilenameFromUrl(response?.body);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(response?.body, decodedFile);
                }
            })
        );
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1 SagContainer">
                    <span className="header" style={{ fontSize: "10px" }}>
                        The file will generate in your browser as PDF.
                    </span>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SearchSelectField
                                id={"show"}
                                label={"Select Show"}
                                defaultMenuText={"-"}
                                options={this.props?.showOption}
                                valueSelected={(e, value) =>
                                    this.props.handleGeneralEdit("show_id", value)
                                }
                                searchSelect={true}
                                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                                detail_selected={this.props?.reportDetails?.show_id}
                                />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"season"}
                                value={this.props?.reportDetails?.show_season_id}
                                label={"Select season"}
                                defaultMenuText={"-"}
                                options={this.props?.seasonOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("show_season_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"episode"}
                                value={this.props?.reportDetails?.episode_id}
                                label={"Select episode"}
                                defaultMenuText={"-"}
                                options={this.props?.episodeOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("episode_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <DateField
                                id="selectWorkDate"
                                value={this.props?.reportDetails.work_date || ""}
                                label={"Select Work Date"}
                                onChange={(e) => this.props.handleGeneralEdit('work_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                        postInitiated={this.state.postInitiated}
                    />
                </MDBContainer>
                {
                    this.state.sagTimeReportModal &&
                    <MessageModal
                        title={"PDF Download Failed"}
                        open={this.state.sagTimeReportModal}
                        message={
                            this.state.pdfRes
                        }
                        primaryButtonText={""}
                        secondaryButtonText={"Cancel"}
                        handleClose={(e) => this.setState({ sagTimeReportModal: false })}
                    />
                }
            </div>
        )
    }
}

export default withUserContext(SagTimeReport)
