import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from '../error-boundary/PageNotFound';
import { withOktaAuth } from '@okta/okta-react';
import { withUserContext } from '../contexts/UserContext';
import LandingPageAdmin from '../caster-tv-jwp/pages/LandingPage/LandingPageAdmin';
import ProjectAssignmentAdmin from '../caster-tv-jwp/pages/ProjectAssignment/ProjectAssignmentAdmin';
import Project from '../caster-tv-jwp/pages/Project/Project';
import LandingPageContainer from '../caster-tv-jwp/pages/LandingPage/LandingPageContainer';
import FeatureLandingPage from '../caster-feature/components/Dashboard/Dashboard'
import UserroleManagement from '../caster-tv-jwp/pages/UserroleManagement/UserroleManagement';
import SearchTalent from '../caster-tv-jwp/pages/SearchTalent/SearchTalent';
import ProjectDetails from '../caster-feature/components/Project/ProjectDetails';
import AnimationLandingPage from '../caster-animation/pages/LandingPage/LandingPage';
import ShowSeasonContainer from '../caster-animation/pages/ShowSeason/ShowSeasonContainer';
import LandingPage from '../caster-feature/components/Dashboard/DashboardNew';
import LandingPageNew from '../caster-feature/components/Dashboard/DashboardLatest';
import AdvanceSearch from '../caster-feature/components/AdvanceSearch/AdvanceSearch';
import SearchTalentContainer from '../caster-animation/pages/Search/SearchTalentContainer';
import SearchRepresentativeContainer from '../caster-animation/pages/Search/SearchRepresentativeContainer';
import SearchCompanyContainer from '../caster-animation/pages/Search/SearchCompanyContainer';
import UserRoleManagement from '../caster-animation/pages/UserRoleManagement/UserRoleManagement';
import UserRoleManagementContainer from '../caster-feature/components/UserRoleManagement/UserRoleManagementContainer';

export default withUserContext(withOktaAuth(class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabToDisplay: null,
      navigationField: null,
      obj: {}
    }
  }
  handleTabChange = (tabToDisplay, navigationField, obj) => {
    console.log("handleTabChange", tabToDisplay, navigationField, obj);
    this.setState({ tabToDisplay, navigationField, obj });
  }
  render() {
    return (

      <Switch>
        <Route exact={true} path='/caster-tv-jwp' render={(props) => <LandingPageContainer {...props} />} />
        <Route exact={true} path='/caster-tv-jwp/project-assignment' render={(props) => <ProjectAssignmentAdmin {...props} />} />
        <Route exact={true} path='/caster-tv-jwp/search-talent' render={(props) => <SearchTalent {...props} />} />
        <Route exact={true} path='/caster-tv-jwp/project-view/prid/:prid' render={(props) => <Project {...props} />} />
        <Route exact={true} path='/caster-tv-jwp/user-role-management' render={(props) => <UserroleManagement {...props} />} />
        {/* <Route exact={true} path='/caster-feature' render={(props) => <LandingPage {...props} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage} />} /> */}
        <Route exact={true} path='/caster-feature' render={(props) => <LandingPageNew {...props} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage} handleTabChange={this.handleTabChange.bind(this)} />} />
        <Route exact={true} path='/caster-feature/new-landing' render={(props) => <FeatureLandingPage {...props} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage} />} />
        <Route exact={true} path='/caster-feature/project/:projectId' render={(props) => <ProjectDetails {...props} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage} tabToDisplay={this.state.tabToDisplay} navigationField={this.state.navigationField} obj={this.state.obj}  />} />
        <Route exact={true} path='/caster-feature/user-role-management' render={(props) => <UserRoleManagementContainer {...props} />} />
        <Route exact={true} path='/caster-feature/adc' render={(props) => <AdvanceSearch {...props} />} />
        <Route exact={true} path='/caster-animation' render={(props) => <AnimationLandingPage {...props} />} />
        <Route exact={true} path='/caster-animation/show-season/:ssid' render={(props) => <ShowSeasonContainer {...props} />} />
        <Route exact={true} path='/caster-animation/search-talent' render={(props) => <SearchTalentContainer {...props} />} />
        <Route exact={true} path='/caster-animation/search-representatives' render={(props) => <SearchRepresentativeContainer {...props} />} />
        <Route exact={true} path='/caster-animation/search-companies' render={(props) => <SearchCompanyContainer {...props} />} />
        <Route exact={true} path='/caster-animation/user-role-management' render={(props) => <UserRoleManagement {...props} />} />
        <Route
          render={function () {
            return <PageNotFound />;
          }}
        />

      </Switch>
    );
  }
}
));