import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import DateField from "../../../components/SharedComponents/DateField/DateField";
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../Helper';
import { withUserContext } from '../../../../contexts/UserContext';

class Station12 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract_end_date: null,
            contract_start_date: null,
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            postInitiated: false,
        }
    }
    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true});
        let { contract_start_date, contract_end_date, selectSeason, selectEpisode, selectProduction } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        if (!contract_start_date || !contract_end_date || !production_id || !ps_id || (this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting' ? !episode_id : false) || this.handleDateValidation()) {
            return;
        }
        let payload = {
            contract_start_date,
            contract_end_date,
            production_id,
            ps_id,
            episode_id
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({postInitiated: false})
    }
    handleDateValidation() {
        return (this.state?.contract_end_date && (new Date(this.state?.contract_end_date) < new Date(this.state?.contract_start_date))) || false
    }
    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting'}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <BasicLabel text={"Date Range"} isMandatory={true} />
                        </MDBRow>
                        <MDBCol md={6}>
                            <DateField
                                id="selectStartDate"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_start_date || ""}
                                label={""}
                                onChange={(e) => this.onChangeCallBack('contract_start_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <DateField
                                id="selectEndDate"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_end_date || ""}
                                label={""}
                                showErrorBorder={(this.state?.contract_end_date &&
                                    (new Date(this.state?.contract_end_date) < new Date(this.state?.contract_start_date))) || false}
                                onChange={(e) => this.onChangeCallBack('contract_end_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        )
    }
}

export default withUserContext(Station12)
