import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MDBIcon } from "mdbreact";
import './shared.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip'
import BasicButton from './BasicButton/BasicButton';
import TableFilters from './TableFilters';
import TableInlineEdits from './TableInlineEdits';
import { withUserContext } from "../../../contexts/UserContext";
import Tooltip from '@mui/material/Tooltip';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';


class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        var headings = this.props?.config?.headings;
        var colArr = {};
        var dataTypeArr = {};
        var selectOptionsArr = {};
        headings.forEach((heading, index) => {
            colArr[heading.headingLabel] = "";
            dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
            if (heading.inputType == "select")
                selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        });
        this.state = {
            inputValues: colArr,
            filterValues: [],
            dropDownValues: [],
            listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
            editInputValues: [],
            dataTypeValues: dataTypeArr,
            selectOptionValues: selectOptionsArr,
            newItem: {},
        }
    }

    componentDidMount() {
        //var headings = this.props?.config?.headings;
        // var colArr = {};
        // var dataTypeArr = {};
        // var selectOptionsArr = {};

        // headings.forEach((heading) => {
        //     colArr[heading.headingLabel] = "";        
        //     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
        //     if(heading.inputType == "select")
        //     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        // });  
        // this.setState({
        //     inputValues : colArr,
        //     filterValues: [],
        //     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
        //     dataTypeValues: dataTypeArr,
        //     selectOptionValues: selectOptionsArr
        // });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.list !== this.props.list) {
            this.setState({ listEdits: JSON.parse(JSON.stringify(this.props?.list || [])) });
        }
        this.props?.config?.headings?.map((heading, index) => {
            if (heading.inputType === "select") {
                if (prevState?.selectOptionValues[this.props?.config.dataNodes[index]] !== prevProps?.config?.headings[index].selectOptions) {
                    prevState.selectOptionValues[this.props.config.dataNodes[index]] =
                        heading.selectOptions;
                    this.setState({ selectOptionValues: prevState?.selectOptionValues })
                }
            }
        })
    }


    addFilterChangeHandler = (index, name, type, value) => {
		var inputValues = this.state.inputValues;
		var filterValues = this.state.filterValues;
		var dropDownValues = this.state.dropDownValues;
		if (type === "dropdown") {
			const dropDownValue = { value: inputValues[name], label: value }
			dropDownValues.push(dropDownValue)
		} else if (type === "autocomplete") {
			const dropDownValue = { value: inputValues[name].value, label: value }
			dropDownValues.push(dropDownValue)
		}
		if (filterValues.length > 0) {
			let presentInd = filterValues.findIndex((item) => item.split(":")[0] === name);
			if (presentInd != -1) {
				if (type === "daterange") {
					if (inputValues[name] != "") {
						filterValues[presentInd] = (name + ": " + value + " : " + inputValues[name]);
					}
				} else {
					filterValues[presentInd] = name + ": " + value;
				}
			} else {
				if (type === "daterange") {
					if (inputValues[name] != "") {
						filterValues.push(name + ": " + value + " : " + inputValues[name]);
					}
				} else {
					filterValues.push(name + ": " + value);
				}
			}
		} else {
			if (type === "daterange") {
				if (inputValues[name] != "") {
					filterValues.push(name + ": " + value + " : " + inputValues[name]);
				}
			} else {
				filterValues.push(name + ": " + value);
			}
		}
		inputValues[name] = "";
		this.setState({
			inputValues: inputValues,
			filterValues: filterValues,
			dropDownValues: dropDownValues
		});
		var filtersNone = false;
		if (filterValues.length == 0)
			filtersNone = true;
		this.props.applyFilter(filterValues, filtersNone, dropDownValues);
	}

    filterChangeHandler = (index, name, value, filter) => {

        var inputValues = this.state.inputValues;
        inputValues[name] = value;
        this.setState({
            inputValues: inputValues
        });
    }

    onChangeNewItemHandler = (value, index, nodeName) => {
        this.setState({ currentFocusItem: null })
        let newInputValues = { ...this.state.newItem }
        if (typeof value === "boolean") {
            newInputValues[nodeName] = value || false;
        } else {
            newInputValues[nodeName] = value || null;
        }
        if (this.props?.checkColumnUpdate && this.props?.updateColumnData) {
            if (nodeName === this.props?.checkColumnUpdate) {
               let selectedNodeValue = this.state.selectOptionValues[nodeName]?.find(item => item.value === value);
               newInputValues[this.props?.updateColumnData] = selectedNodeValue?.description || null;
            }
        }
        this.setState({ newItem: newInputValues })
    }

    handleAddNewItem = () => {
        if (this.props?.validateNewItem != undefined) {
            if (this.props?.validateNewItem(this.state.newItem)) {
                this.props?.handleAddNewItem(this.state.newItem)

                if (this.props?.config?.isClearFields) {
                    this.setState({ newItem: {} }, () => { })
                }
            }
        }
        else {
            this.props?.handleAddNewItem(this.state.newItem)

            if (this.props?.config?.isClearFields) {
                this.setState({ newItem: {} }, () => { })
            }
        }

    }

    handleInlineEditChange = (value, index, nodeName) => {
        var editInputValues = [...this.state.listEdits];
        editInputValues[index][nodeName] = value;
        if (this.props?.checkColumnUpdate && this.props?.updateColumnData) {
            if (nodeName === this.props?.checkColumnUpdate) {
               let selectedNodeValue = this.state.selectOptionValues[nodeName]?.find(item => item.value === value);
               editInputValues[index][this.props?.updateColumnData] = selectedNodeValue?.description || null;
            }
        }
        this.setState({ listEdits: editInputValues, filterValues: [] });
    }

    handleFilterDelete = (removeNode, index) => {
		var filterValues = this.state.filterValues;
		var dropDownValues = this.state.dropDownValues
		filterValues.splice(index, 1);
		this.setState({ filterValues: filterValues });
		var filtersNone = false;
		if (filterValues.length == 0)
			filtersNone = true;
		this.props.applyFilter(filterValues, filtersNone, dropDownValues);
	}

    render() {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        return (
            <>
                <div>
                    <div className={this.props?.maxHeight ? "tableContainer " + "max-height-" + this.props?.maxHeight : "tableContainer"}>
                        <TableContainer component={Paper} className={this.props?.tableMode == "light" ? "bg-white overflow-x-unset" : "bg-light overflow-x-unset"}>
                            <Table id={this.props.className + "-table"} stickyHeader aria-label="collapsible table" size="small" style={{ tableLayout: "fixed" }} >
                                <colgroup>
                                    {this.props?.config?.headings?.map((heading, index) => (
                                        <col key={"colWidth_" + index} width={heading.width ? heading.width : (100 / this.props?.config?.headings?.length) + "%"} />))
                                    }
                                </colgroup>
                                {this.props?.config?.isSearchBar ? <>
                                    <TableHead class="searchHeaders">
                                        <TableRow key={this.props.className + "-searchHeaderRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index + "-" + heading.headingLabel} className="searchHeaderRows">
                                                        {index === 0 ? 'Search Results for :' + '"' + this.props.searchText + '"' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableHead class="searchHeadersSecond">
                                        <TableRow key={this.props.className + "-searchHeaderSecondRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index} className="searchHeadersSecondRows">
                                                        {index === 0 ? this.props.resultsCount + ' search results for ' + '[' + this.props.searchType + ']' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead></> : null}
                                <TableHead id={this.props.className + "-tablehead"} className={this.props?.tableMode == "light" ? "bg-light" : "bg-dark"}>

                                    {this.props?.config?.filters ?
                                        <TableRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
                                            {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                                            <div style={{ display: "flex" }}>
                                                {
                                                    this.state.filterValues && this.state.filterValues?.map((item, index) =>
                                                        <Chip
                                                            label={item}
                                                            onDelete={this.handleFilterDelete.bind(this, item, index)}
                                                        />
                                                    )
                                                }
                                            </div>
                                            {/* </TableCell> */}
                                        </TableRow> :
                                        <></>
                                    }

                                    <TableRow>
                                        {
                                            this.props?.config?.headings?.map((heading, index) => (
                                                <TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
                                                    className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} >
                                                    {(this.props?.config?.filters || this.props?.config?.filtersLanding) ?
                                                        <><TableFilters
                                                            inputType={heading.inputType}
                                                            options={this.props?.options[heading?.selectOptions]}
                                                            filterValues={this.state.filterValues}
                                                            name={heading.headingLabel} inputValue={this.state.inputValues[heading.headingLabel]}
                                                            filterChangeHandler={this.filterChangeHandler.bind(this, index, heading.headingLabel)}
                                                            addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index, heading.headingLabel, heading.inputType)} /> </> : <> </>}
                                                    <span className="action-icon" onClick={() => this.props?.sortCallback(heading.headingLabel)}>
                                                        <b> {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                            : heading.headingLabel} </b>
                                                    </span>

                                                </TableCell>
                                            ))
                                        }
                                        {this.props?.config?.actions &&
                                            <TableCell key={this.props.className + "-" + this.props?.config?.headings?.length}
                                                className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} style={{ padding: "4px" }}  >
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.props?.isLoading ? (
                                        <div className="tableLoader">
                                            <CircularProgress />
                                        </div>
                                    ) : (

                                        <TableBody key={this.props.className + "body"}>
                                            {this.props?.list?.length > 0 && this.props?.list?.map((dataItem, rowIndex) => (
                                                <TableRow id={this.props.className + dataItem[this.props.config.primaryKey]}
                                                    key={this.props.className + "-" + dataItem[this.props.config.primaryKey] + "-" + rowIndex}>
                                                    {this.props?.config?.dataNodes?.map((node, index) => (node == 'check' ?
                                                        <TableCell className='FW800'>
                                                            <input type='checkbox' checked={dataItem[node]}
                                                                onClick={(event, newValue) => {
                                                                    dataItem[node] = event.target.checked;
                                                                    this.props.arrayCheckUncheck(event.target.checked, dataItem);
                                                                }}></input>
                                                        </TableCell> :
                                                        <TableCell
                                                            key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node] + "-" + index}
                                                            className={(this.props?.config?.borders?.includes(node) ? "border-left-1-secondary " : "") +
                                                                (this.props?.config?.changeColourToRed?.includes(dataItem[node]) ? "textColorRed " : "") +
                                                                (this.props?.config?.changeColourToOrange?.includes(dataItem[node]) ? "textColorOrange " : "") +
                                                                (this.props?.config?.changeColourToBlue?.includes(dataItem[node]) ? "textColorBlue " : "") +
                                                                (this.props?.config?.changeColourToGreen?.includes(dataItem[node]) ? "textColorGreen " : "") +
                                                                //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                                                "border-ver-1-secondary padding-5 word-break"} >
                                                            {dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === node) ?
                                                                <> </> :
                                                                node == "groups" ?
                                                                    dataItem[node]?.map(item => (<div>{<a href="javascript:void(0);" onClick={(e) => this.props.groupElementCallback(item?.value, node, dataItem)}>{item?.label === 'add a button' ? <BasicButton text="Add to Episode" /> : item?.label}</a>}</div>)) :
                                                                    node == "label" ?
                                                                        <div 
                                                                        // className="labelNames" 
                                                                        className={`${(this.props?.highlightValue === dataItem[node]) ? 'highlight-value' : ''} labelNames`}
                                                                        onClick={(e) => this.props.editData(dataItem, node)}>
                                                                            {dataItem[node]}</div> :
                                                                        this.props?.config?.hyperlinks?.includes(node) ?
                                                                            <a href="javascript:void(0);" onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node)}>
                                                                                {dataItem[node]}</a> :
                                                                            this.props?.config?.toggleFields?.includes(node) ? (dataItem[node] != null ?
                                                                                (this.props?.config?.tooltips?.includes(node) && dataItem[node]) ?
                                                                                    <Tooltip clickable title={dataItem[node + "_text"] ? <div
                                                                                        dangerouslySetInnerHTML={{ __html: [dataItem[node + "_text"]] }}
                                                                                    /> : ""}>
                                                                                        <span>
                                                                                            <MDBIcon icon={dataItem[node] == true ? "check-circle" : "times"}
                                                                                                className={dataItem[node] == true ? "text-success" : "text-dark"} />
                                                                                        </span>
                                                                                    </Tooltip> : <MDBIcon icon={dataItem[node] == true ? "check-circle" : "times"}
                                                                                        className={dataItem[node] == true ? "text-success" : "text-dark"} /> : <> </>) :
                                                                                this.props?.config?.nodeFields?.includes(node) ?
                                                                                    <React.Fragment>
                                                                                        {dataItem.is_favourite === 0 ? (
                                                                                            <StarBorderIcon
                                                                                                variant="outlined"
                                                                                                id={dataItem[this.props.config.primaryKey]}
                                                                                                onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                className={dataItem.is_favourite === 0 ? '' : 'star-border'}
                                                                                            />
                                                                                        ) : (
                                                                                            <StarIcon
                                                                                                variant="outlined"
                                                                                                id={dataItem[this.props.config.primaryKey]}
                                                                                                onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                className={dataItem.is_favourite === 0 ? '' : 'star-border'}
                                                                                            />
                                                                                        )}
                                                                                    </React.Fragment> : this.props?.config?.fileField?.includes(node) ?
                                                                                        <MDBIcon variant="outlined" icon={"file"}
                                                                                            id={dataItem[[this.props.config.primaryKey]]} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                        /> :this.props?.config?.buttonWithText?.includes(node) ?
                                                                                        <BasicButton variant="contained" type="inline" className="outlined-btn" text={node} onClick={(e) => this.props?.buttonWithFieldFun(dataItem, node,e)} /> :
                                                                                            // this.props?.config?.buttonWithTextAndIcon?.includes(node) ?
                                                                                            //     <BasicButton variant="outline" type="inline" className="outlined-btn" icon="file" text={node} /> :
                                                                                            this.props?.config?.buttonWithTextAndIcon?.some(e => e.buttonText === node) ? 
                                                                                                <BasicButton 
                                                                                                    variant="outlined" 
                                                                                                    type="inline" 
                                                                                                    className="outlined-btn" 
                                                                                                    icon={this.props?.config?.buttonWithTextAndIcon?.find(i => i.buttonText === node)?.icon} 
                                                                                                    text={node} 
                                                                                                    disabled={dataItem['disableNavButton'] || false}
                                                                                                    onClick={() => this.props?.handleButtonClick(dataItem)}
                                                                                                /> :
                                                                                                this.props?.config?.fileAttachments?.includes(node) ?
                                                                                                    <BasicButton variant="outlined" icon="paperclip"
                                                                                                        type="inline" inputType="file" id={dataItem[[this.props.config.primaryKey]]} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                                        uploadFile={(file) => this.props?.handleFileSelect(file, dataItem)} />
                                                                                                    :
                                                                                                    (this.state.selectOptionValues?.[node] && !this.props?.config?.searchFields?.find(
                                                                                                        searchItem => searchItem?.label === node
                                                                                                    )) ? (
                                                                                                            <>
                                                                                                                {this.state.selectOptionValues?.[node].map(
                                                                                                                    (item) => {
                                                                                                                        if (item.value == dataItem[node])
                                                                                                                            return <> {item?.label ? item.label : item.text} </>;
                                                                                                                    }
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) :
                                                                                                        <> {(this.props?.config?.tooltips?.includes(node) && dataItem[node]) ? <Tooltip title={dataItem[node]}>
                                                                                                            <span>{dataItem[node]?.length > 40 ? dataItem[node]?.substring(0, 40) + "..." : dataItem[node]}</span>
                                                                                                        </Tooltip> : dataItem[node]|| "-"}</>

                                                            }
                                                            {
                                                                this.props?.config?.dataNodeGroups?.[node] ? (
                                                                    <>
                                                                        {this.props?.config?.dataNodeGroups?.[node]?.map((item) => (
                                                                            dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === item) ? <> </>
                                                                                : <div>{dataItem[item]}</div>))}
                                                                    </>) : <> </>
                                                            }

                                                            {dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === node) ?
                                                            <>
                                                                <TableInlineEdits 
                                                                    nodeName={node} 
                                                                    inputType={this.state.dataTypeValues[node]}
                                                                    inputValue={this.state.listEdits?.[rowIndex]?.[node]} 
                                                                    index={rowIndex}
                                                                    handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                    selectOptions={this.state.selectOptionValues?.[node]}
                                                                    WrapInlineError={this.props?.WrapInlineError}
                                                        />{(node==="wrap") && rowIndex===this.props.errorIndex ?<span style={{ display: 'block', color:'red',fontSize: '6px'}}>
                                                                {this.props?.WrapInlineError}</span>:""}</> : <> </>}

                                                            {
                                                                this.props?.config?.dataNodeGroups?.[node] ? (
                                                                    <>
                                                                        {this.props?.config?.dataNodeGroups?.[node]?.map((item) =>
                                                                            dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === item) ?
                                                                                (<TableInlineEdits nodeName={item} inputType={"text"}
                                                                                    inputValue={this.state.listEdits?.[rowIndex]?.[item]} index={rowIndex}
                                                                                    handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                    WrapInlineError={this.props?.WrapInlineError}
                                                                                    />) : <> </>)}
                                                                    </>) : <> </>
                                                            }

                                              

                                                        </TableCell>
                                                    ))
                                                    }
                                                    {this.props?.config?.actions &&
                                                        <TableCell key={this.props.className + "action"} className={`border-ver-1-secondary ${this.props?.config?.actionClassName}`} > <div className="row">
                                                            {this.props?.config?.actions?.map((action, indexNo) => (

                                                                <div key={this.props.className + "-" + indexNo} className="col-md padding-3"
                                                                    disabled={this.props?.tabValue === 1 ? (!dataItem?.is_edit) : false}
                                                                    onClick={(event) => action === "pen" ?
                                                                        (dataItem["editing"] ? this.props?.saveCallback(this.state.listEdits, rowIndex, dataItem[this.props.config.primaryKey], dataItem) :
                                                                            this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem)
                                                                        ) :(action=='minus'? this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem,true):
                                                                        (action === "plus" ? this.props?.handleAddCallback(dataItem[this.props.config.primaryKey]) :
                                                                            ((action === "trash-alt" || action === "times") ? this.props?.handleDelCallBack(dataItem[this.props.config.primaryKey], dataItem) : 
                                                                                (action === "arrows-alt-v" ? this.props?.handleOpenInlineModal(dataItem[this.props.config.primaryKey], dataItem, event) : null))))}>
                                                                    {action === "view" ? (<a href="#">{action}</a>) :
                                                                        ((action === "pen" && dataItem["editing"]
                                                                            ? <><MDBIcon icon={"save"} color="primary" className="action-icon" />
                                                                            </>
                                                                            :(action==='minus' && dataItem["editing"] ?<MDBIcon icon={"minus"} color="primary" className="action-icon" />
                                                                            : ((action === "trash-alt" || action === "times") ?
                                                                                (userPermissions?.isRegular || userPermissions?.isReadOnly ?
                                                                                    null : 
                                                                                    <Tooltip clickable arrow placement="bottom"
                                                                                    componentsProps={{
                                                                                            tooltip: {
                                                                                                sx: {
                                                                                                    maxWidth: '500px',
                                                                                                    fontSize: '13px',
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                        title={"Delete"} >                                                                                               
                                                                                        <span class="padding-3">
                                                                                            <MDBIcon className='action-icon delete-tooltip'  icon={action} />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    ) :
                                                                                (userPermissions?.isReadOnly
                                                                                    ? null
                                                                                    : action!='minus' && <MDBIcon icon={action} className="action-icon" />)
                                                                            ))))}
                                                                </div>

                                                            ))
                                                            }
                                                        </div></TableCell>
                                                    }
                                           

                                                </TableRow>
                                            ))
                                            }
                                            {
                                                this.props?.list?.length <= 0 &&
                                                <TableRow >
                                                    <TableCell className="text-align-center" colSpan={this.props?.config?.headings?.length}>
                                                        <b>No results</b>
                                                    </TableCell>
                                
                                                </TableRow>
                                            }
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </TableContainer>
                    </div>
                    {this.props?.addItemToList ?
                        this.props?.postInitiated === 'newItemPost' ? <CircularProgress size={20} /> :
                        <>
                            <div className="inlineAdd" id="table-buttoncolor">
                                {this.props?.config?.inlineEdits?.map((nodeItem, nodeIndex) => (
                                    <TableInlineEdits
                                        newRowForTable={true}
                                        inlineObject={nodeItem}
                                        fieldDisabled={false}
                                        placeholder={nodeItem?.placeholder}
                                        validateMethod={nodeItem?.validateMethod}
                                        validateMessage={nodeItem?.validateMessage}
                                        nodeName={nodeItem?.dataNode}
                                        inputType={this.state.dataTypeValues[nodeItem?.dataNode]}
                                        inputValue={this.state?.newItem?.[nodeItem?.dataNode]}
                                        width={nodeItem.width}
                                        icon={nodeItem.icon}
                                        inlineIcon={nodeItem?.inlineIcon}
                                        tooltip={nodeItem.iconTooltip}
                                        index={nodeIndex}
                                        handleInlineEditChange={this.onChangeNewItemHandler}
                                        selectOptions={
                                            this.state.selectOptionValues?.[nodeItem?.dataNode]
                                        }
                                        searchString={this.props?.searchString}
                                        WrapError={this.props?.WrapError}
                                        />
                                ))
                                }
                                <MDBIcon
                                    icon={"plus-circle"}
                                    onClick={this.handleAddNewItem}
                                />
                                {this.props?.showClear &&
                                    <div className="clear-btn">
                                        <BasicButton
                                            variant="text"
                                            text="Clear"
                                            onClick={() => this.setState({ newItem: {} })}
                                        />
                                    </div>
                                }
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                 paddingLeft:'95px',
                                color:'red',
                                fontSize: '9px',
                                }}>
                                <p>
                                {this.props?.WrapError}
                                </p>
                            </div>

                            </>
                        : ""
                    }
                </div>
            </>
        )
    }
}


export default withUserContext(TableComponent);