import React from 'react';
import TextField from '@material-ui/core/TextField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './BasicTextField.scss';
// import { MDBBtnGroup, MDBBtn} from "mdb-react-ui-kit";
// import Popover from '@material-ui/core/Popover';
// import Typography from '@material-ui/core/Typography';
// import alphabetVariantsJson from '../AlphabetVariants.json';
import AlphabetVarPopover from '../AlphabetVarPopover/AlphabetVarPopover';

export default class BasicTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            anchorEl: null,
            currentChar: 'A',
            value: props?.value
        }
    }

    onKeyUp = (event) => {
        if ((event.keyCode === 32 && event.ctrlKey == true) || event.keyCode === 115) {
            var char = 'A';
            var textValue = event?.target?.value;
            var textLen = textValue?.length;
            if (textLen > 0) {
                char = textValue[textLen - 1];
            }
            this.setState({
                isOpen: true,
                anchorEl: event.currentTarget,
                currentChar: char
            });
        }
        else if (this.props?.onKeyUp) {
            this.props?.onKeyUp(event);
        }
    }

    handlePopoverClose = () => {
        this.setState({ isOpen: false, anchorEl: null });
    }
    //     handleAlphabetVariantSelect = (val) => {
    //         console.log("Selected Alphabet::"+val);
    //         var len = this.props?.value?.length || 0;
    //         var value = (len > 0 ? this.props?.value.substring(0,len - 1): "" );
    //         value += val;
    // //        this.setState({value: value});
    //         this.handlePopoverClose();
    //         this.props?.handleAlphabetVariantSelect(value);
    //     }

    render() {
        let error = this.props?.value?.length > this.props.limit || (this.props.showMandatory && !this.props?.value) ||
            this.props.fieldValid || this.props.showDuplicate || false;
        let mandatoryWarning = "Please complete this mandatory field";
        let showAsLabel = this.props.showAsLabel || false;
        return (
            <div className={this.props?.disabled ? ("BasicTextFieldContainer Field-disabled") :
                "BasicTextFieldContainer"}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className={`showAsLabel ${this.props?.isPrimary ? 'primaryStarIcon' : ''} ${this.props?.isTalent ? 'marginWidth' : ''}`}>{this.props.value || ""} </div> :
                    <><TextField
                        id={this.props?.id}
                        variant="outlined"
                        autoComplete='off'
                        className={this.props?.className || ""}
                        value={this.props?.value || ""}
                        onChange={this.props?.onChange}
                        inputProps={this.props?.inputProps || {}}
                        InputProps={this.props?.InputProps || {}}
                        disabled={this.props?.disabled || false}
                        placeholder={this.props?.placeholder || ""}
                        required={this.props?.isMandatory || false}
                        onKeyUp={this.onKeyUp.bind(this)}
                        onBlur={this.props?.onBlur}
                        readOnly={this.props?.readOnly || false}
                    />

                        {this.props?.isAlphabetVar && <AlphabetVarPopover isOpen={this.state.isOpen && this.props?.isAlphabetVar}
                            anchorEl={this.state.anchorEl}
                            handlePopoverClose={this.handlePopoverClose.bind(this)}
                            handleAlphabetVariantSelect={this.props?.handleAlphabetVariantSelect}
                            currentChar={this.state.currentChar}
                            value={this.props?.value}
                        />}
                    </>
                }
                {error && !showAsLabel && <div className="errorText">{((this.props.showMandatory && !this.props?.value) ? this.props?.mandatoryWarning ? this.props?.mandatoryWarning : mandatoryWarning :
                    this.props.fieldValid ? this.props.inValidInput : this.props?.showDuplicate ? this.props?.showDuplicateMesg : this.props.limitWarning) || ""}</div>}
            </div>
        );
    }
}

// BasicTextField.defaultProps = {
//     onKeyUp: () => null
// }
