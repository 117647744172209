import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea"
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import DateField from "../../../components/SharedComponents/DateField/DateField";
import ReportButton from './ReportButton';
import { genReportName, validateCharNumOnlyWithSpace, validateNumDotUptoTwoPlacesOnly, validateNumDotOnly, validateNumbersOnly, getFormattedDate } from "../../../Common/Helper";
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import messages from '../../../Common/Messages.json'
import { withUserContext } from "../../../../contexts/UserContext";
class EPTimesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: null,
           // account_details: "",
            days: null,
            //rate: null,
            //selectProduction: null,
            selectSeason: null,
            selectEpisode: null,
            //pse_performer_contract_id: null,
            postInitiated: false,
            performerNamesList: [],
            selectedPerformer: null,
            loading: false,
            performerDisabled: true,
            dealDateOptions: [],
            disableDeals: true,
        }
    }
    getPerformerDetails() {
        if (this.state.selectProduction?.value) {
            this.setState({ loading: true, performerDisabled: false });
            let url = Constants.CasterServiceBaseUrl + '/productionSeasonEpisodePerformers?productionId=' + this.state.selectProduction?.value + '&psId=' + (this.state.selectSeason?.value === undefined ? null : this.state.selectSeason?.value) + '&episodeId=' + (this.state.selectEpisode?.value === undefined ? null : this.state.selectEpisode?.value);
            CasterService.getData(url, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let filteredList = response.data?.map(item => ({ value: item.pse_performer_id, text: item.performer_name, loanout_flag: item.talent_legal_id }));
                    this.setState({
                        performerNamesList: filteredList,
                        loading: false
                    })
                }, (err) => {
                    console.log("Err while fetching performer");
                    this.setState({ loading: false });
                })
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        }, () => {
            if (field === "selectedPerformer" && this.props?.withContract) {
                this.loadContract();
            }
        });

    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true });
        let { start_date, selectSeason, selectEpisode, days, selectedPerformer} = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;

        let pse_performer_id = selectedPerformer?.value;
        if (!production_id || !ps_id (this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting' ? !episode_id : false) || (!this.props?.withContract && !pse_performer_id)) {
            return;
        }
        let payload = {
            start_date,
            //contract_text,
            //account_details,
            pse_performer_id,
            days,
           // rate,
            production_id,
            ps_id,
            episode_id,
            pse_performer_contract_id
        }
        // let reportName = this.props?.reportName === "rptTalentVoucherWithContract" ? this.state?.selectedPerformer?.loanout_flag !== null ? this.props?.reportsToDownloadLoanout : this.props?.reportsToDownload : this.props?.reportName;
        // let allDownLoadReports = null
        // if (this.props?.reportName === "rptTalentVoucherWithContract") {
        //     let reportsName = this.state?.selectedPerformer?.loanout_flag !== null ? this.props?.reportsToDownloadLoanout.split(",") : this.props?.reportsToDownload.split(",");
        //     let reportsToDownLoadName = this.props?.reportDownloadName.split(",");
        //     reportsName.map((key, i) => {
        //         let obj = {
        //             reportName: reportsToDownLoadName[i],
        //             production: selectProduction?.label,
        //             season: selectSeason?.label,
        //             episode: selectEpisode?.label,
        //             performer: "",
        //         }
        //         let reportNameFinal = genReportName(obj)
        //         allDownLoadReports.push(reportNameFinal)
        //     })
        // }
        // else {
        //     let obj = {
        //         reportName: this.props?.reportDownloadName,
        //         production: selectProduction?.label,
        //         season: selectSeason?.label,
        //         episode: selectEpisode?.label,
        //         performer: selectedPerformer?.text,
        //     }
        //     allDownLoadReports = genReportName(obj)
        // }
        let obj = {
            reportName: this.props?.reportsToDownLoadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer:  selectedPerformer?.text,
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })
    }
    // loadContract() {
    //     let id = this.state.selectedPerformer?.value;
    //     CasterService.getData(Constants.CasterServiceBaseUrl + `/performerDealContractsAll?psePerformerId=${id}`, this.props?.userContext?.active_tenant?.tenant_id)
    //         .then(response => {
    //             let filteredList = [];
    //             response.data?.map(item => {
    //                 item?.deals.map(item => {
    //                     item?.contracts.map(contracts => {
    //                         filteredList.push({ value: contracts.pse_performer_contract_id, label: getFormattedDate(contracts?.contract_date) })
    //                     })
    //                 })
    //             });
    //             this.setState({ dealDateOptions: filteredList, disableDeals: false });
    //         },
    //             (err) => {
    //                 console.log("Error in fetching deal details", err)
    //             })
    // }
    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            report={"EP Timesheet"}
                            getTalentOptions={this.getPerformerDetails.bind(this)}
                            episodeReq={this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting'}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    <MDBRow>
                        {/* {!this.props?.withContract && (

                            <MDBCol md={6}>
                                <BasicTextField id="accountDetails"
                                    value={this.state.account_details}
                                    onChange={(e) => this.onChangeCallBack("account_details", e.target.value.length > 0 ? e.target.value : null)}
                                    label="Account Details"
                                />
                            </MDBCol>
                        )} */}
                        <MDBCol md={6} className='searchSelectBorder'>
                            <BasicLabel text={"Performer Name"} isMandatory={true} />
                            {/* <SearchSelectField
                                    id={"performer-select"}
                                    disabled={this.state?.performerDisabled || false}
                                    value={this.state?.selectedPerformer || null}
                                    options={this.state?.performerNamesList || []}
                                    width={'100%'}
                                    onChange={(e, value) => this.onChangeCallBack('selectedPerformer', value)}
                                /> */}
                            <Autocomplete size="medium"
                                isMandatory={true}
                                id={"performerName"}
                                className={(this.state.postInitiated && !this.state.selectedPerformer) ? "tags-standard error" : "tags-standard"}
                                options={this.state?.performerNamesList || []}
                                disabled={this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting' ? this.state?.performerDisabled || !this.state.selectProduction?.value || !this.state.selectSeason?.value || !this.state.selectEpisode?.value : this.state?.performerDisabled || false}
                                value={(this.state?.selectedPerformer || null)}
                                getOptionLabel={(option) => option.text ? option.text : ''}
                                getOptionSelected={(option, value) => option.value === value.value}
                                fullWidth={true}
                                onChange={(event, newValue) => {
                                    this.onChangeCallBack("selectedPerformer", newValue)
                                }}
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        id={"performerNameText"}
                                        placeholder="-Search or Select-"
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                        }}
                                    />
                                )}
                            />
                            {<div className="errorText">{(this.state.postInitiated && !this.state.selectedPerformer) ? messages.mandatoryWarning : ""}</div>}
                        </MDBCol>

                    </MDBRow>

                    <MDBRow>
                        {!this.props?.withContract ?
                            <MDBCol md={6}>
                                <DateField
                                    id="selectWorkDate"
                                    value={this.state.start_date || ""}
                                    label={"Start Date"}
                                    onChange={(e) => this.onChangeCallBack('start_date', (e.target.value ? e.target.value : null))}
                                />
                            </MDBCol>
                    
                            // : <MDBCol md={6}>
                            //     <SelectField
                            //         label={"Contract Date"}
                            //         disabled={this.state.disableDeals}
                            //         value={this.state.pse_performer_contract_id}
                            //         options={this.state.dealDateOptions || []}
                            //         onChange={(e) => {  this.onChangeCallBack('pse_performer_contract_id', e.target.value) }}
                            //     />
                            // </MDBCol>}

                        /* <MDBCol md={3}>
                            <BasicTextField id="rate"
                                inValidInput={messages.invalidInput || ""}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.rate}
                                onChange={(e) => validateNumDotUptoTwoPlacesOnly(e.target.value) ? this.onChangeCallBack("rate", e.target.value.length > 0 ? e.target.value : null) : null}
                                label="Rate"
                                showAsLabel={this.props?.disabled || false} />
                        </MDBCol> */
                              :""}
                    </MDBRow>
                    <MDBRow>
                        <BasicTextArea
                            id="contractText"
                            label={"Contract Text"}
                            rows={3}
                            value={this.state.contract_text || ""}
                            onChange={(e) => this.onChangeCallBack('contract_text', e.target.value.length > 0 ? e.target.value : null)} />
                    </MDBRow>

                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>

            </div>
        )
    }
}

export default withUserContext(EPTimesheet);