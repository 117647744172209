import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import RichTextEditor from '../../../components/SharedComponents/RichTextEditor/RichTextEditor';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import './Rider.scss';

class Rider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rider: null
        }
    }

    componentDidMount() {
        if (this.props?.rider) {
            this.setState({ rider: this.props?.rider })
        }
    }

    handleOnChange = (value) => {
        this.setState({ rider: value })
    }

    render() {
        return (
            <MDBContainer fluid className="riderContainer">
                <MDBRow>
                    <RichTextEditor
                        id={`rider`}
                        value={this.state.rider || ""}
                        onChange={(value) => this.handleOnChange(value)}
                    >
                    </RichTextEditor>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={10}></MDBCol>
                    <MDBCol md={1}>
                        <BasicButton
                            type="inline"
                            variant="contained"
                            text={"Save"}
                            onClick={() => {
                                this.props?.handleOnChange('rider', this.state.rider)
                                this.props?.onClose()
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={1}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={() => this.props?.onClose()}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default Rider;