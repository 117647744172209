import React, { Component } from 'react'
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import { withUserContext } from "../../../../contexts/UserContext";
import { MDBRow, MDBCol } from 'mdbreact';
import CircularProgress from '@material-ui/core/CircularProgress';
class ReportButton extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <MDBRow className="ButtonRow">
                {this.props.userContext?.active_tenant?.tenant_name === "Feature Casting" && this.props?.generateExcel &&
                <MDBCol md={3}>
                        <BasicButton
                            variant="contained"
                            type="inline"
                            text={"Generate Excel"}
                            onClick={() => this.props?.onClickGenratePdf(0,1)}
                            disabled={false}
                        />
                    
                </MDBCol>
                }
                {this.props.userContext?.active_tenant?.tenant_name === "Feature Casting" && this.props?.generateDoc &&
                <MDBCol md={3}>
                        <BasicButton
                            variant="contained"
                            type="inline"
                            text={"Generate Doc"}
                            onClick={() => this.props?.onClickGenratePdf(1)}
                            disabled={false}
                        />
                    
                </MDBCol>
                }
                <MDBCol className="ButtonCol" md={this.props.userContext?.active_tenant?.tenant_name === "Feature Casting" ? 3: 4}>
                    <BasicButton
                        variant="contained"
                        type="inline"
                        text={"Generate PDF"}
                        onClick={() => this.props?.onClickGenratePdf()}
                        disabled={false}
                    />
                </MDBCol>
                <MDBCol md={3}>
                    <BasicButton
                        variant="outlined"
                        type="inline"
                        text={"Cancel"}
                        onClick={this.props?.handleClose}
                    />
                </MDBCol>
            </MDBRow>
        )
    }
}
export default withUserContext(ReportButton);
