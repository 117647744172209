import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import { MDBCol, MDBRow } from 'mdbreact';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../SharedComponents/SelectField/SelectField';


class PostSyncTimeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="PostSyncTimeLogContainer">
             <MDBRow>
                <MDBCol md={7}>
                <SearchSelectField
                    id="project_id"
                    options={this.props?.projectList}
                    searchSelect={true}
                    multiple={false}
                    label={"Select Project"}
                    placeholder={"Select Project"}
                    detail_selected={this.props?.selectedprojectValue}
                    valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                    searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                />
                </MDBCol>
             </MDBRow>
            <MDBRow>
                <MDBCol md={10} className="longWidth">
                    <BasicTextField 
                        label={"Recording Location"}
                        id={"Recording_Location"}
                        value={this.state.reportDetails?.location}
                        onChange={(e) => this.props?.handleOnChange("location", e.target.value)}
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md={5}>
                    <DateField 
                        id={"work_date"}
                        label={"Work Date"}
                        value={this.state.reportDetails?.work_date}
                        onChange={(e) => this.props?.handleOnChange("work_date", e.target.value)}
                    />
                </MDBCol>
            </MDBRow>
             {/* <MDBRow>
                <span className='optionFilterColor'>Optional Filters</span>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5}>
                    <SelectField 
                        label={"Production Company"}
                        value={this.state.reportDetails?.production_company}
                        options={this.props.companyList}
                        onChange={(e) => this.props?.handleOnChange("production_company", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5}>
                    <SelectField 
                        label={"Union"}
                        value={this.state.reportDetails?.union}
                        options={this.props.unionList}
                        onChange={(e) => this.props?.handleOnChange("union", e.target.value)}
                    />
                </MDBCol>
             </MDBRow> */}
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(PostSyncTimeLog));