import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from 'mdbreact';
import { agencyRepsConfig, agencyRepsList } from './Config';
import TableComponent from '../SharedComponents/Table';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import './Agent.scss';


class AgencyRepresentatives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyRepSearch: null,
            searchValues: [],
            config: JSON.parse(JSON.stringify({ ...agencyRepsConfig }))
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.rep_list_to_display !== state.rep_list_to_display) {
            return {
                rep_list_to_display: props.rep_list_to_display
            }
        }
        if (props.config !== state.config) {
            return {
                config: props.config
            }
        }
        if (props?.selectedRep !== state.selectedRep) {
            return {
                selectedRep: props?.selectedRep
            }
        }
        if (props?.representative_options !== state.representative_options) {
            return {
                representative_options: props?.representative_options
            }
        }
        if (props?.isEditing !== state.isEditing) {
            return {
                isEditing: props?.isEditing
            }
        }
        return null
    }

    navigationCallback = (obj, node) => {
        if (node.includes("covered_talent")) {
            this.props.navFuns.handleHideAgencyPopup();
            this.props.navFuns.handleShowTalentPopup(obj.talent_id, obj.covered_talent);
        } else if (node.includes("representative_name")) {
            this.props.navFuns.handleHideAgencyPopup();
            this.props.navFuns.handleShowRepresentativePopup(obj.representative_id, obj.representative_name);
        }
    }

    render() {
        let list = this.props?.rep_list_to_display?.filter(item => item.is_delete != 1)
        return (
            <div className="agencyReps">
                {this.props?.isEditing ?
                    <MDBRow className="searchField">
                        <MDBCol md={5}>
                            <SearchSelectField
                                id={"alias"}
                                options={this.props?.representative_options || []}
                                width={'100%'}
                                detail_selected={this.props?.selectedRep}
                                valueSelected={(e, value) => this.props?.handleSelectedRep(value)}
                                searchText={(e) => this.props?.getRepresentativesList("agency_representative", e.target.value)}
                                multiple={false}
                                searchSelect={true}
                                placeholder={"- Search By Alias or Company to Add -"}
                            />
                        </MDBCol>
                        {this.props?.selectedRep &&
                            <>
                                <MDBCol md={3} className={"agencyData"}>
                                    <div>{this.props?.selectedRep?.occupation ? this.props?.selectedRep?.occupation + ' | ' : ''} {this.props?.selectedRep?.phone ? this.props?.selectedRep?.phone + ' | ' : ''}{this.props?.selectedRep?.email ? this.props?.selectedRep?.email + ' | ' : ''} </div>
                                </MDBCol>
                                <MDBCol md={1} className={"plus-circle"} onClick={this.props?.agencyRepAdd}>
                                    <AddCircleIcon />
                                </MDBCol>
                            </>}
                    </MDBRow>
                    : null}
                <MDBRow>
                    <MDBCol md={6}>
                        <BasicTextField
                            id="representative_name"
                            value={this.props?.filterList?.representative_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("representative_name", e.target.value)
                                this.props?.addFilterChangeHandler("Representative Name", e.target.value)
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={6}>
                        <BasicTextField
                            id="covered_talent"
                            value={this.props?.filterList?.covered_talent}
                            onChange={(e) => {
                                this.props?.onChangeHandler("covered_talent", e.target.value)
                                this.props?.addFilterChangeHandler("Covered Talent", event.target.value);
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={12} id="agencyRepsTable">
                        <TableComponent
                            config={this.props?.config}
                            list={list}
                            stickyHeader={true}
                            loading={this.props?.isFetchingAgencyDetails}
                            sortCallback={this.props?.handleTableColumnClick}
                            handleDelCallBack={this.props?.handleDeleteRep}
                            hyperLinkNavigationCallback={this.navigationCallback}
                        />
                    </MDBCol>
                </MDBRow>
                <hr />
                {/* {this.props?.isEditing ?
                <MDBRow className="searchField">
                    <MDBCol md={5}>
                        <SearchSelectField
                            id={"alias"}
                            options={this.props?.representative_options || []}
                            width={'100%'}
                            detail_selected={this.props?.selectedRep}
                            valueSelected={(e,value) => this.props?.handleSelectedRep(value)}
                            searchText={(e) => this.props?.getRepresentativesList("agency_representative", e.target.value)}
                            multiple={false}
                            searchSelect={true} 
                            placeholder={"- Search By Alias or Company to Add -"}
                        />
                    </MDBCol>
                    {this.props?.selectedRep &&
                    <>
                    <MDBCol md={3} className={"agencyData"}>
                        <div>{this.props?.selectedRep?.occupation ? this.props?.selectedRep?.occupation +' | ' : ''} {this.props?.selectedRep?.phone ? this.props?.selectedRep?.phone + ' | ' : '' }{this.props?.selectedRep?.email ? this.props?.selectedRep?.email + ' | ' : ''} </div>
                    </MDBCol>
                    <MDBCol md={1} className={"plus-circle"} onClick ={this.props?.agencyRepAdd}>
                        <AddCircleIcon/>
                    </MDBCol>
                    </>}
                </MDBRow>
                : null } */}
            </div>
        );
    }
}

export default withUserContext(AgencyRepresentatives);

