import React from "react";
import { withRouter } from "react-router-dom";
import "./UserRoleList.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import ProjectAssignmentTable from "./ProjectAssignmentTable";
import ProjectAssignment from "./UsersList";


class ProjectAssignmentAdmin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      projectList:[],
    };
    
  }
 

  render() {
    return (
      <div className="ProjectContainer">
       <MDBRow>
        <span className="project-font">Project Assignment</span>
       </MDBRow>
        <MDBRow>
            <span className="font-user">Select a project to allocate user access.</span>
        </MDBRow>
        <MDBRow>
          <ProjectAssignmentTable module="project_assignment_admin" />
        </MDBRow>
      </div>
    );
  }
}
export default withRouter(withUserContext(ProjectAssignmentAdmin));
