import React from 'react';
import CompanyprofileDetails from './CompanyProfileDetails';
// import RepresentativeProfileTalent from './RepresentativeProfileTalent';
import { withUserContext } from "../../../contexts/UserContext";
import {
    companyInitialDetails, talentAddress, talentPhone,
    talentEmail, talentAka, talentRepresentation, talentPersonalLimit, companyTalentConfig, repTalentConfig
} from './config';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import CompanyRepresentative from './CompanyRepresentative';
import CompanyTalent from './CompanyTalent';
import { CircularProgress } from '@material-ui/core';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class CompanyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            companyDetails: JSON.parse(JSON.stringify({ ...companyInitialDetails })),
            postInitiated: false,
            akaIndex: 2,
            checkedMultiPrimary: {
                email_list: false,
                representation_list: false,
            },
            companyTypeOption: [],
            stateOptions: [],
            addressTypeOptions: [],
            countryOptions: [],
            renderList: [],
            config: companyTalentConfig,
            config1: repTalentConfig,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
        }
    }

    componentDidMount() {
        if (this.props?.companyId) {
            this.getcompanyDetails("id", this.props?.companyId, 1)
        } else {
            this.getLookupData('COMPANY_TYPE')
            this.getAdresssData("ADDRESS_TYPE")
            this.getCountryData("COUNTRY")
            this.getStateData("STATE")
        }
    }

    getcompanyDetails = (searchKey, searchString) => {
        this.setState({ isFetchingCompanyDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetCompany?searchKey=${searchKey}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("get company data", response)
                this.setState({ 
                    companyDetails: response.data[0], 
                    renderList: response.data[0].representatives, 
                    isFetchingCompanyDetails: false 
                }, () => {
                    this.handleTableColumnClick("Talent")
                    this.handleTableClick("Representative")
                    this.getLookupData('COMPANY_TYPE')
                    this.getAdresssData("ADDRESS_TYPE")
                    this.getCountryData("COUNTRY")
                    this.getStateData("STATE")
                })
            },
                (err) => {
                    console.log("Error in fetching company details", err)
                    this.setState({ isFetchingCompanyDetails: false })
                })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        } else {
            this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
        } 
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent": return this.state.tabValue === 1 ? "talent_name" : "representative_name";
            case "Representative": return this.state.tabValue === 1 ? "representative_name" : "representative_type";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }

    handleTableClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableColSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableColSort(column);
        })
    }

    getSortNodes = (col) => {
        switch (col) {
            case "Representative": return this.state.tabValue === 1 ? "representative_name" : "representative_type";
            case "Rep. Type": return this.state.tabValue === 1 ? "representative_type" : "talent_name";
            case "Covered Talent": return this.state.tabValue === 1 ? "talent_name" : "datetime_added";
        }
    }

    handleTableColSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config1: updateTableSortConfigObject(this.state.config1, this.state.isSortByAsc, this.state.sortBy, colName),
                renderList: tableSortList("Name", this.getSortNodes(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }
    getLookupData = (entity) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));
                this.setState({ companyTypeOption: formattedList }, () => {
                    let compDetails = { ...this.state.companyDetails }
                    compDetails.company_type = this.state?.companyTypeOption?.find(item => item.label === this.state?.companyDetails?.company_type)?.value
                    this.setState({ companyDetails: compDetails })
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getAdresssData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    addressTypeOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let compaddDetails = { ...this.state.companyDetails }
                    compaddDetails.address_type = this.state?.addressTypeOptions?.find(item => item.label === compaddDetails?.address_type)?.value
                    this.setState({ companyDetails: compaddDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getCountryData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    countryOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let compCountryDetails = { ...this.state.companyDetails }
                    compCountryDetails.country = this.state?.countryOptions?.find(item => item.label === compCountryDetails?.country)?.value
                    this.setState({ companyDetails: compCountryDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getStateData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    stateOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let compStateDetails = { ...this.state.companyDetails }
                    let country = this.state.countryOptions?.find(item => item.value === compStateDetails?.country);
                    if (country?.label === 'United States') {
                        compStateDetails.state = this.state.stateOptions?.find(item => item.label === compStateDetails?.state)?.value;
                    }
                    this.setState({ companyDetails: compStateDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {
            return {
                tabValue: props.tabValue,
                mode: props.mode,
                navObj: props.navObj || {}
            }
        }
        return null
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        this.setState(prevState => ({
            companyDetails: {
                ...prevState.companyDetails,
                [field]: newValue
            },
            postInitiated: false
        }))
    }


    getTabComponent = () => {
        switch (this.props.tabValue) {
            case 0: return <CompanyprofileDetails
                companyDetails={this.state.companyDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                companyTypeOption={this.state.companyTypeOption}
                addressTypeOptions={this.state.addressTypeOptions}
                stateOptions={this.state.stateOptions}
                countryOptions={this.state.countryOptions}
            />
            case 1: return <CompanyRepresentative
                companyDetails={this.state.companyDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                renderList={this.state.renderList}
                companyId={this.props?.companyId}
                getcompanyDetails={() => this.getcompanyDetails("id", this.props?.companyId, 1)}
                handleTableClick={this.handleTableClick.bind(this)}
            />
            case 2: return <CompanyTalent
                companyDetails={this.state.companyDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                renderList={this.state.renderList}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                companyId={this.props?.companyId}
                getcompanyDetails={() => this.getcompanyDetails("id", this.props?.companyId, 1)}
            />
        }
    }

    canSubmit = (companyDetails) => {
        let canSubmit = companyDetails?.company_name && companyDetails?.company_type;
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true, showError: false })
        let companyDetailsPostJson = { ...this.state.companyDetails };
        companyDetailsPostJson['company_id'] = this.props?.companyId || null
        companyDetailsPostJson['company_type'] = this.state.companyTypeOption?.find(item => item.value === companyDetailsPostJson.company_type)?.label;
        companyDetailsPostJson['address_type'] = this.state.addressTypeOptions?.find(item => item.value === companyDetailsPostJson.address_type)?.label;
        companyDetailsPostJson['country'] = this.state.countryOptions?.find(item => item.value === companyDetailsPostJson.country)?.label;
        if (companyDetailsPostJson['country'] === 'United States') {
            companyDetailsPostJson['state'] = this.state.stateOptions?.find(item =>
                item.value === companyDetailsPostJson.state)?.label;
        }
        companyDetailsPostJson.representatives = this.state.representativesDetails
        if (this.canSubmit(companyDetailsPostJson)) {
            this.props?.setPostFlag(true)
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/animationPostCompany', companyDetailsPostJson, this.props.userContext?.active_tenant?.tenant_id, 1)
                .then((response) => {
                    if (response?.data?.error) {
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "fail")
                        this.props?.setPostFlag(false)
                    } else {
                        companyDetailsPostJson['company_id'] = response.data[0].company_id
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "success", "refresh-company")
                        this.props?.setPostFlag(false)
                    }
                },
                    (err) => {
                        this.setState({ postInitiated: false })
                        this.props?.notificationComponent(true, "fail")
                        this.props?.setPostFlag(false)
                        console.log("Post company details error: " + err);
                    });
        }
    }


    render() {
        return (
            <div className="CompanyProfile">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleSubmit()}></input>
                {this.state.isFetchingCompanyDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <>
                            {this.getTabComponent()}
                        </>
                    )
                }
            </div>
        );
    }

}
export default withUserContext(CompanyProfile);