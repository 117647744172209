import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import './SideNav.scss';
import { MDBIcon } from 'mdb-react-ui-kit';


export default withRouter(withOktaAuth(class SideNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isManageUser: false,
            isAdmin: false,
            isManager: false
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser,
            isAdmin: nextProps.userContext?.active_tenant?.permissions?.isAdmin,
            isManager: nextProps.userContext?.active_tenant?.permissions?.isManager
        });
    }
    render() {
        return (
            <>
                {!this.state.isManageUser ?
                    <div id="sidenav" className="bg-warning">
                        <a href="javascript:void(0);" id="lookups" onClick={this.props.showDataQuery}>
                            <MDBIcon fas fixed icon="search" className="mr-2" /> Data Query
                        </a>
                        <a href="javascript:void(0);" id="Reports" onClick={this.props.handleReports}>
                            <MDBIcon far fixed icon="file-alt" className="mr-2" /> Reports
                        </a>

                        <a href="javascript:void(0);" id="talent" onClick={this.props.handleShowTalentPopup}>
                            <MDBIcon fas fixed icon="theater-masks" className="mr-2" /> Talent
                        </a>
                        <a href="javascript:void(0);" id="agency" onClick={this.props.handleShowAgencyPopup}>
                            <MDBIcon fas fixed icon="briefcase" className="mr-2" /> Agency
                        </a>

                        <a href="javascript:void(0);" id="representatives" onClick={this.props.handleShowRepresentativePopup}>
                            <MDBIcon fas fixed icon="user-tie" className="mr-2" /> Representatives
                        </a>

                        <a href="javascript:void(0);" id="productionCompany" onClick={this.props.handleShowProductionPopup}>
                            <MDBIcon fas fixed icon="film" className="mr-2" /> Production Company
                        </a>

                        <a href="javascript:void(0);" id="productionCompany" onClick={this.props.handleShowCrewPopup}>
                            <MDBIcon fas fixed icon="users" className="mr-2" /> Crew
                        </a>
                        {this.props?.userContext?.active_tenant?.permissions?.isManageLookups ?
                            <a href="javascript:void(0);" id="lookups" onClick={this.props.showLookupPopup}>
                                <MDBIcon fas fixed icon="server" className="mr-2" /> Manage Lookups
                        </a> : null}
                    </div> 
                    : null 
                }
            </>
        );

    }

}
));