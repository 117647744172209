import React from "react";
import { withUserContext } from "../../../../contexts/UserContext";
import './CreateNewShow.scss';
import messages from "../../../Common/Messages.json";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import sizeLimits from "../../../Common/SizeLimits.json";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import { validateCharNumWithHyphenSlash, validateEmail } from "../../../Common/Helper";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import { statusDatesConfig, initialShowDetails, initialEpisodeDetails } from '../Config';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';
import { withRouter } from "react-router-dom";

class CreateNewShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            episodeDetails: this.formatEpisodeList(JSON.parse(JSON.stringify({ ...initialEpisodeDetails }))),
            createNewShowData: initialShowDetails,
            isEpisodic: false,
            isDTVShows: false,
            showConfigrationOptions: [],
            startYearOptions: [],
            endYearOptions: [],
            showTypeOptions: [],
            statusOptions: [],
            productionCompanyOption: [],
            unionOption: [],
            statusDateOptions: [],
            postInitiated: false,
            isSubmit: false,
            isPosting: false,
            showSuccessMessage: false,
            showFailMessage: false,
        };
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    handleOnChangeDtv(field, value) {
        if (field === 'end_date') {
            if (this.formatDate(value) < this.formatDate(this.state.episodeDetails?.start_date)) {
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        [field]: null,
                    },
                    showEndDateError: true
                }));
            } else {
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        [field]: value,
                    },
                    showEndDateError: false
                }));
            }
        } else {
            this.setState((prevState) => ({
                episodeDetails: {
                    ...prevState.episodeDetails,
                    [field]: value,
                },
            }));
        }
    }

    handleOnchange(field, value) {
        if (field === "show_configuration") {
            this.setState((prevState) => ({
                createNewShowData: {
                    ...prevState.createNewShowData,
                    [field]: value,
                },
            }));

            const selectedOption = this.state.showConfigrationOptions.find(
                option => option.value === value
            );
            if (selectedOption && selectedOption.label === "Episodic") {
                this.setState({ isEpisodic: true, isDTVShows: false });
            } else if (selectedOption && (selectedOption.label === "DTV" ||
                selectedOption.label === "Animatic" ||
                selectedOption.label === "Theatrical")) {
                this.setState({ isEpisodic: false, isDTVShows: true });
            }
        } else if (field === "is_pilot") {
            this.setState((prevState) => ({
                createNewShowData: {
                    ...prevState.createNewShowData,
                    [field]: value ? 1 : 0,
                },
            }));
        }
        else {
            this.setState((prevState) => ({
                createNewShowData: {
                    ...prevState.createNewShowData,
                    [field]: value,
                    // isEpisodic: false, isDTVShows: false
                },
            }));
        }
    }

    addCallback = (index) => {
        let allShowData = [...this.state.episodeDetails.status_dates];
        allShowData.push(JSON.parse(JSON.stringify({ ...statusDatesConfig })))

        this.setState((prevState) => ({
            episodeDetails: {
                ...prevState.episodeDetails,
                ["status_dates"]: allShowData
            }

        }));
    }

    removeCallback = (index) => {
        let allShowData = [...this.state.episodeDetails.status_dates];
        allShowData.splice(index, 1);
        this.setState((prevState) => ({
            episodeDetails: {
                ...prevState.episodeDetails,
                ["status_dates"]: allShowData
            }
        }));
    }

    formatEpisodeList = (response) => {
        if (response?.status_dates?.length === 0) {
            response?.status_dates.push(JSON.parse(JSON.stringify({ ...statusDatesConfig })));
        }

        return response;
    }

    onChangeStatus = (list, field, value, index) => {
        let episodeData = { ...this.state.episodeDetails }
        if (field === "end_date") {
            if (this.formatDate(value) < this.formatDate(episodeData.status_dates[index]?.start_date)) {
                this.setState({ showStatusEndDateError: true, selectedStatusIndex: index })
                episodeData.status_dates[index][field] = null
            } else {
                this.setState({ showStatusEndDateError: false, selectedStatusIndex: index })
                episodeData.status_dates[index][field] = value
            }
        } else {
            episodeData.status_dates[index][field] = value
        }
        this.setState({ episodeDetails: episodeData })
    }

    componentDidMount = () => {
        this.getShowconfigrationData("SHOW_CONFIGURATION")
        this.getShowTypeData("SHOW_TYPE")
        this.getStatusData("SHOW_STATUS")
        this.getProductionCompanyData("PRODUCTION_COMPANY", null)
        this.getUnionData("UNION")
        this.getSeasonData("SEASONS")
        this.getStatuses()
    }

    componentWillUnmount = () => {
        this.getShowconfigrationData("SHOW_CONFIGURATION")
        this.getShowTypeData("SHOW_TYPE")
        this.getStatusData("SHOW_STATUS")
        this.getProductionCompanyData("PRODUCTION_COMPANY", null)
        this.getUnionData("UNION")
        this.getSeasonData("SEASONS")
        this.getStatuses()
    }

    getStatuses = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=STATUS_DATES`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
                this.setState({ statusDateOptions: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getShowconfigrationData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    showConfigrationOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getShowTypeData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    showTypeOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowTypeData method");
            });
    };

    getStatusData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    statusOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getStatusData method");
            });
    };

    getProductionCompanyData = (entity, searchString) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.production_companies_id,
                    label: item.production_company_name,
                    // is_active: item.is_active
                }));
                this.setState({
                    productionCompanyOption: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getProductionCompanyData method");
            });
    };

    getUnionData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    unionOption: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getUnionData method");
            });
    };

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success":
                return this.setState({ showSuccessMessage: value });
            case "fail":
                return this.setState({ showFailMessage: value });
        }
    };

    canSubmit = () => {
        let createNewShowData = { ...this.state.createNewShowData }
        let canSubmit = false;
        if (this.state.isEpisodic) {
            canSubmit = createNewShowData?.show_name && createNewShowData?.show_configuration &&
                createNewShowData?.show_type && createNewShowData?.status && createNewShowData?.season_name &&
                createNewShowData?.production_company && createNewShowData?.union_id && createNewShowData?.start_year &&
                createNewShowData?.end_year;
        } else if (this.state.isDTVShows) {
            canSubmit = createNewShowData?.show_name && createNewShowData?.show_configuration && createNewShowData?.show_type &&
                createNewShowData?.status && createNewShowData?.production_company && createNewShowData?.union_id &&
                this.state.episodeDetails.start_date && this.state.episodeDetails.end_date &&
                (!this.state.episodeDetails?.status_dates?.some(statusDate => (!(statusDate.start_date && statusDate?.end_date && statusDate?.status) || false)))
        }
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let payload = JSON.parse(JSON.stringify({ ...this.state?.createNewShowData }));
        payload['show_type'] = this.state.showTypeOptions?.find(item => item.value === payload.show_type)?.label;
        payload['show_configuration'] = this.state.showConfigrationOptions?.find(item => item.value === payload.show_configuration)?.label;
        payload['start_year'] = this.state.startYearOptions?.find(item => item.value === payload.start_year)?.label;
        payload['end_year'] = this.state.endYearOptions?.find(item => item.value === payload.end_year)?.label;
        payload['status'] = this.state.statusOptions?.find(item => item.value === payload.status)?.label;
        payload['production_company'] = this.state.productionCompanyOption?.find(item => item.value === payload.production_company)?.label;
        payload['union'] = this.state.unionOption?.find(item => item.value === payload.union_id)?.label;
        payload['is_season'] = 1;
        let dtvPayload = { ...JSON.parse(JSON.stringify({ ...this.state?.episodeDetails })), ...JSON.parse(JSON.stringify({ ...this.state?.createNewShowData })) };
        dtvPayload['production_company'] = this.state.productionCompanyOption?.find(item => item.value === dtvPayload.production_company)?.label;
        dtvPayload['union'] = this.state.unionOption?.find(item => item.value === dtvPayload.union_id)?.label;
        dtvPayload['status'] = this.state.statusOptions?.find(item => item.value === dtvPayload.status)?.label;
        dtvPayload['show_type'] = this.state.showTypeOptions?.find(item => item.value === dtvPayload.show_type)?.label;
        dtvPayload['show_configuration'] = this.state.showConfigrationOptions?.find(item => item.value === dtvPayload.show_configuration)?.label;
        dtvPayload['start_year'] = this.state.startYearOptions?.find(item => item.value === dtvPayload.start_year)?.label;
        dtvPayload['end_year'] = this.state.endYearOptions?.find(item => item.value === dtvPayload.end_year)?.label;
        dtvPayload['is_season'] = 0;
        dtvPayload.status_dates?.map(statusDate => {
            if (statusDate.status) {
                let statusDateLabel = this.state.statusDateOptions?.find(item => item.value === statusDate.status)?.label;
                statusDate.status = statusDateLabel
            }
            return statusDate;
        })

        if (this.canSubmit()) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostShow`,
                this.state.isDTVShows ? dtvPayload : payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.notificationComponent(true, "fail");
                    } else {
                        this.props.notificationComponent(true, "success");
                        this.props.history.push(`/caster-animation/show-season/${response.data[0].show_id}`);
                        this.setState({
                            isPosting: false,
                            postInitiated: false
                        })
                    }
                },
                    (err) => {
                        console.log('Error is submitting Show' + err);
                        this.props.notificationComponent(true, "fail");
                        this.setState({ isPosting: false, postInitiated: false })
                    });
        } else {
            console.log("Error in submitting show")
        }
    };

    getSeasonData = (entity) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("check the season response", response);
                let formattedStartYearList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
                let formattedEndYearList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value2, is_active: item?.is_active }));
                this.setState({
                    startYearOptions: formattedStartYearList,
                    endYearOptions: formattedEndYearList
                }, () => {
                    let createNewShowData = { ...this.state.createNewShowData };
                    createNewShowData.start_year = this.state.startYearOptions?.find(item =>
                        item.label === createNewShowData.start_year)?.value || null;
                    createNewShowData.end_year = this.state.endYearOptions?.find(item =>
                        item.label === createNewShowData.end_year)?.value || null;
                    this.setState({ createNewShowData: createNewShowData })
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    render() {
        return (
            <MDBContainer className="CreateNewShowContainer">
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicLabel text={"Show Configration"} isMandatory={true} />
                        <SelectField
                            id={"showConf"}
                            placeholder={"Select"}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.createNewShowData?.show_configuration}
                            options={this.state.showConfigrationOptions}
                            onChange={(e, value) => this.handleOnchange('show_configuration', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicLabel text={"Show Type"} isMandatory={true} />
                        <SelectField
                            id={"showType"}
                            placeholder={"Select"}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.createNewShowData?.show_type}
                            options={this.state.showTypeOptions}
                            onChange={(e, value) => this.handleOnchange('show_type', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicLabel text={"Status"} isMandatory={true} />
                        <SelectField
                            id={"Status"}
                            placeholder={"Select"}
                            value={this.state.createNewShowData?.status}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            options={this.state.statusOptions}
                            onChange={(e, value) => this.handleOnchange('status', e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={7}>
                        <BasicTextField
                            className="text"
                            placeholder={"eg. The Best Show Ever"}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            label="Show Name"
                            value={this.state.createNewShowData?.show_name}
                            onChange={(e) =>
                                this.handleOnchange("show_name", e.target.value)
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={7}>
                        <BasicLabel text={"Production Company"} isMandatory={true} />
                        <SelectField
                            id={"production"}
                            placeholder={"Select"}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            multiple={true}
                            value={this.state.createNewShowData?.production_company}
                            options={this.state.productionCompanyOption}
                            onChange={(e, value) => this.handleOnchange('production_company', e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicLabel text={"Union"} isMandatory={true} />
                        <SelectField
                            id={"union"}
                            placeholder={"Select"}
                            showMandatory={this.state.postInitiated}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.createNewShowData?.union_id}
                            options={this.state.unionOption}
                            onChange={(e, value) => this.handleOnchange('union_id', e.target.value)}
                        />
                    </MDBCol>
                    {/* {this.state.isDTVShows &&
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    label="SAP Code"
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    value={this.state.createNewShowData?.sap_code || ""}
                                    limit={sizeLimits.nameCharacterLimit}
                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        validateCharNumWithHyphenSlash(e.target.value) &&
                                            this.handleOnchange(
                                                "sap_code",
                                                e.target.value.length > 0 ? e.target.value : null
                                            );
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={5}>
                                <BasicTextField
                                    label="MPM#"
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    value={this.state.createNewShowData?.mpm || ""}
                                    limit={sizeLimits.nameCharacterLimit}
                                    limitWarning={messages.exceed50CharacterWarning || ""}
                                    onChange={(e) => {
                                        validateCharNumWithHyphenSlash(e.target.value) &&
                                            this.handleOnchange(
                                                "mpm",
                                                e.target.value.length > 0 ? e.target.value : null
                                            );
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    } */}
                    {/* <MDBRow>
                        <MDBCol md={5}>
                            <BasicTextField
                                className="text"
                                label={"Signatory"}
                                value={this.state.createNewShowData.signatory}
                                onChange={(e) =>
                                    this.handleOnchange("signatory", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow> */}
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                className="text"
                                // placeholder={"eg. The Best Show Ever"}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated}
                                // mandatoryWarning={messages.mandatoryWarning}
                                label="Signatory Name"
                                value={this.state.createNewShowData?.signatory_name}
                                onChange={(e) =>
                                    this.handleOnchange("signatory_name", e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label={"Signatory Email"}
                                value={this.state.createNewShowData?.signatory_email || ""}
                                fieldValid={!validateEmail(this.state.createNewShowData?.signatory_email) && this.state.createNewShowData?.signatory_email}
                                inValidInput={messages.invalidInput}
                                onChange={(e) => this.handleOnchange('signatory_email', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                className="text"
                                // placeholder={"eg. The Best Show Ever"}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated}
                                // mandatoryWarning={messages.mandatoryWarning}
                                label="Production Rep Name"
                                value={this.state.createNewShowData?.production_rep_name}
                                onChange={(e) =>
                                    this.handleOnchange("production_rep_name", e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label={"Production Rep Email"}
                                value={this.state.createNewShowData?.production_rep_email || ""}
                                fieldValid={!validateEmail(this.state.createNewShowData?.production_rep_email) && this.state.createNewShowData?.production_rep_email}
                                inValidInput={messages.invalidInput}
                                onChange={(e) => this.handleOnchange('production_rep_email', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                className="text"
                                // placeholder={"eg. The Best Show Ever"}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated}
                                // mandatoryWarning={messages.mandatoryWarning}
                                label="Executive Name "
                                value={this.state.createNewShowData?.executive_name}
                                onChange={(e) =>
                                    this.handleOnchange("executive_name", e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label={"Executive Email"}
                                value={this.state.createNewShowData?.executive_email || ""}
                                fieldValid={!validateEmail(this.state.createNewShowData?.executive_email) && this.state.createNewShowData?.executive_email}
                                inValidInput={messages.invalidInput}
                                onChange={(e) => this.handleOnchange('executive_email', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={5}>
                            <BasicTextField
                                className="text"
                                label={"Voice Director"}
                                value={this.state.createNewShowData.voice_director}
                                onChange={(e) =>
                                    this.handleOnchange("voice_director", e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md={5}>
                            <BasicTextField
                                className="text"
                                label={"Casted By"}
                                value={this.state.createNewShowData.casted_by}
                                onChange={(e) =>
                                    this.handleOnchange("casted_by", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    {this.state.isEpisodic &&
                        <MDBContainer >
                            <span className="font-header">Create the first season for this Episode Show</span>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <BasicTextField
                                        className="text"
                                        label={"Season Name"}
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.createNewShowData.season_name}
                                        onChange={(e) =>
                                            this.handleOnchange("season_name", e.target.value)
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={2} className="pilot-check">
                                    <BasicCheckbox
                                        id={"is_pilot111"}
                                        label={"Pilot"}
                                        checked={this.state.createNewShowData?.is_pilot === 1 ? true : false}
                                        onChange={(e) =>
                                            this.handleOnchange("is_pilot", e.target.checked)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3} className="season-start-date pr-0">
                                    <SelectField
                                        label={"Dates"}
                                        className={"fromDate"}
                                        value={this.state.createNewShowData?.start_year || ""}
                                        options={this.state.startYearOptions}
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        onChange={(e) => {
                                            this.handleOnchange("start_year", e.target.value);
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="season-end-date pl-0">
                                    <SelectField
                                        label={" "}
                                        className={"toDate"}
                                        value={this.state.createNewShowData?.end_year || ""}
                                        options={this.state.endYearOptions}
                                        onChange={(e) => {
                                            this.handleOnchange("end_year", e.target.value);
                                        }}
                                    />
                                </MDBCol>
                                {/* <MDBCol md={3}>
                                    <BasicTextField
                                        label="SAP/GL Code"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.createNewShowData?.sap_code || ""}
                                        limit={sizeLimits.nameCharacterLimit}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => {
                                            validateCharNumWithHyphenSlash(e.target.value) &&
                                                this.handleOnchange(
                                                    "sap_code",
                                                    e.target.value.length > 0 ? e.target.value : null
                                                );
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        label="MPM#"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.createNewShowData?.mpm || ""}
                                        limit={sizeLimits.nameCharacterLimit}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => {
                                            validateCharNumWithHyphenSlash(e.target.value) &&
                                                this.handleOnchange(
                                                    "mpm",
                                                    e.target.value.length > 0 ? e.target.value : null
                                                );
                                        }}
                                    />
                                </MDBCol> */}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={12}>
                                    <BasicTextArea
                                        label={"Season Notes"}
                                        value={this.state.createNewShowData?.season_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("season_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={10}>
                                    <BasicTextArea
                                        label={"Show Notes"}
                                        value={this.state.createNewShowData?.show_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("show_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    }
                    {
                        this.state.isDTVShows &&
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md={10}>
                                    <BasicTextArea
                                        label={"Show Notes"}
                                        value={this.state.createNewShowData?.show_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("show_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <span className="font-header">Create the First Episode For DTV Shows</span>
                            <MDBRow >
                                <MDBCol md={3} className="start-date">
                                    <DateField
                                        id="selectStartDate"
                                        value={this.state.episodeDetails.start_date || ""}
                                        label={"Start/End Date"}
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        onChange={(e) =>
                                            this.handleOnChangeDtv(
                                                "start_date",
                                                e.target.value ? e.target.value : null
                                            )
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="end-date" style={{ marginLeft: "-25px" }}>
                                    <DateField
                                        id="selectEndDate"
                                        value={this.state.episodeDetails.end_date || ""}
                                        label={" "}
                                        onChange={(e) =>
                                            this.handleOnChangeDtv(
                                                "end_date",
                                                e.target.value ? e.target.value : null
                                            )
                                        }
                                    />
                                    {this.state.showEndDateError &&
                                        <p className="errorText">End Date should be greater than or equal to start date</p>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {this.state.episodeDetails?.status_dates?.map((item, index) =>
                                    <MDBRow className="status-dates">
                                        <MDBCol md={3} className="start-date">
                                            <DateField
                                                id="selectStartDate"
                                                value={item.start_date || ""}
                                                label={index === 0 ? "Status Dates" : ""}
                                                isMandatory={true}
                                                showMandatory={this.state.postInitiated || false}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) =>
                                                    this.onChangeStatus("status_dates",
                                                        "start_date",
                                                        e.target.value ? e.target.value : null, index
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md={3} className="end-date">
                                            <DateField
                                                id="selectEndDate"
                                                value={item.end_date || ""}
                                                label={index === 0 ? " " : ""}
                                                showMandatory={this.state.postInitiated || false}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) =>
                                                    this.onChangeStatus("status_dates",
                                                        "end_date",
                                                        e.target.value ? e.target.value : null, index
                                                    )
                                                }
                                            />
                                            {(index === this.state.selectedStatusIndex && this.state.showStatusEndDateError) &&
                                                <p className="errorText">End Date should be greater than or equal to start date</p>
                                            }
                                        </MDBCol>
                                        <MDBCol md={3} style={{ marginTop: "-5px" }}>
                                            <SelectField
                                                className={"status"}
                                                label={index === 0 ? "Status" : ""}
                                                value={item?.status || ""}
                                                options={this.state.statusDateOptions}
                                                showMandatory={this.state.postInitiated || false}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) => {
                                                    this.onChangeStatus("status_dates", "status", e.target.value, index);
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md={2} className={`${index === 0 ? 'firstRowIcons' : 'icons'} status-actions`}>
                                            {this.state.episodeDetails?.status_dates.length - 1 === index ?
                                                <AddCircleIcon fontSize="small" onClick={() => this.addCallback(index)} /> :
                                                null}
                                            {!(this.state.episodeDetails?.status_dates.length === 1) ?
                                                <RemoveCircleIcon fontSize="small" onClick={() => this.removeCallback(index)} /> :
                                                null}
                                        </MDBCol>
                                    </MDBRow>)}
                            </MDBRow>
                        </MDBContainer>
                    }
                    <MDBRow className="p-2 margin">
                        <MDBCol md={8} lg={7}></MDBCol>
                        <MDBCol md={2} lg={2}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                text={
                                    this.state.isPosting ? (
                                        <CircularProgress color="inherit" size={18} />
                                    ) : "Finish"
                                }
                                icon={"Finish"}
                                disabled={!this.canSubmit()}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={() => {
                                    this.props?.onClose();
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default withUserContext(withRouter(CreateNewShow));
