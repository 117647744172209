export const initialNewCrewDetails = {
  crew_id: null,
  first_name: null,
  last_name: null,
  middle_name: null,
  suffix: null,
  location_name: null,
  company: null,
  phone_list: [{
    object_id: null,
    project_crew_phone_id: null,
    object_type: null,
    phone_type: null,
    value: null,
    phone: null,
    is_primary: 0,
    is_delete: 0,
    ext: null
  }],
  email_list: [{
    object_id: null,
    project_crew_email_id: null,
    object_type: null,
    email_type: null,
    value: null,
    email: null,
    is_primary: 0,
    is_delete: 0,
    ext: null
  }]
};
export const initialObjectConfig = {
  object_id: null,
  object_type: null,
  value: null,
  is_primary: 0,
  is_delete: 0,
  ext: null
}
export const initialPhoneObjectConfig = {
  project_crew_phone_id: null,
  object_id: null,
  phone_type: null,
  object_type: null,
  phone: null,
  value: null,
  is_primary: 0,
  is_delete: 0,
  ext: null
}
export const initialEmailObjectConfig = {
  project_crew_email_id: null,
  object_id: null,
  email_type: null,
  object_type: null,
  email: null,
  value: null,
  is_primary: 0,
  is_delete: 0,
  ext: null
}