import * as React from 'react';

export const NotificationContext = React.createContext({
 });

export const withNotificationContext = Component => {
    class NotificationContextComponent extends React.Component {
      render() {
        return (
          <NotificationContext.Consumer>
            {(value) => <Component key={this.props?.id || false} notificationContext = {value} {...this.state} {...this.props}/>}
          </NotificationContext.Consumer>
        );
      }
    }
  
    return NotificationContextComponent;
  };

