import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MDBContainer, MDBIcon } from "mdbreact";
import './HorizontalTabComponent.scss';
import {withStyles} from '@material-ui/styles';


const styles = {
    root: {
        minWidth: 30, // a number of your choice
        width: 40, // a number of your choice
        flexGrow: 1,
    },
    iconError: {
        flexDirection: "row-reverse",
    }
};

// const useStyles = withStyles({
//     scrollButtons: {
//       "&.Mui-disabled": {
//         opacity: 0.3
//       }
//     }
//   });

class HorizontalTabComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            productionTabSelected: 0
        }
    }

    a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    }

    handleTabChange = (event, value) => {
        
        // this.setState({
        //     productionTabSelected: value           
        // });
        this.props.handleTabChange(value);
    }
    
    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue
            }
        }
        return null
    }

    render() {
        const { classes } = this.props;
        return (
            <MDBContainer fluid className="HorTabComponentContainer">
                <div className="tabs">
                            <Tabs
                                orientation="horizontal"
                                variant="scrollable"
                                scrollButtons = "auto"                                
                                value={this.props.selectedTab}
                                onChange={this.handleTabChange.bind(this)}
                                textColor="inherit"
                                indicatorColor="innherit"
                                // classes={useStyles}
                            >
                                {
                                    this.props.tabList?.map(item => {
                                        if(item?.errorFlag === 0) {
                                            return <Tab className={`tab ${item.index == this.state?.tabValue ? 'selected': ''}`} classes={{
                                                wrapper: classes.iconError,
                                              }} label={item.label}  icon={<MDBIcon className='redColor' icon='exclamation-circle'/>} iconPosition={'end'}/>
                                        } else {
                                            return <Tab className={`tab ${item.index == this.state?.tabValue ? 'selected': ''}`} label={item.label} {...this.a11yProps(item.index)} style={{width: "15%"}} classes={styles.root } value={item?.index}  />

                                        }
                                    }) 
                                }
                            </Tabs>
                    </div>
            </MDBContainer>
        );
    }
}

export default withStyles(styles)(HorizontalTabComponent);