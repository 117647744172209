import React from 'react';
import './AddRepresentive.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import messages from "../../../Common/Messages.json";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { CircularProgress } from '@material-ui/core';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../../components/SharedComponents/SearchSelectField';
import { repInitialConfig } from './Config';


class AddRepresentive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addRepresentativeData: {},
            companyOptions: [],
            representativeOptions: [],
            isSubmit: false,
            repIndex: 2,
            selectedRep: null,
            selectedCompany: null
        };
    }

    componentDidMount = () => {
        this.getCompanyData("COMPANY", " ")
    }

    handleOnchange(field, newValue) {
        this.setState((prevState) => ({
            addRepresentativeData: {
                ...prevState.addRepresentativeData,
                [field + '_id']: newValue?.value,
                [field + '_name']: newValue?.text
            },
        }), () => {
            if (field === "company") {
                this.getRepresentiveData("REPRESENTATIVE", this.state.addRepresentativeData?.company_id);
            }
        });
    }

    getCompanyData = (entity, searchString) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.company_id,
                    text: item.company_name,
                    address: item.address,
                    phone: item.phone,
                    email: item.email
                }));
                this.setState({
                    companyOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getCompanyData method");
            });
    };

    getRepresentiveData = (entity, companyId) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&companyId=${companyId}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.representative_id,
                    text: item.representative_name,
                    phone: item.phone,
                    email: item.email
                }));
                this.setState({
                    representativeOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getRepresentiveData method");
            });
    };

    handleSubmit = () => {
        let repDetails = { ...repInitialConfig };
        repDetails['representative_id'] = this.state.addRepresentativeData?.representative_id;
        repDetails['representative_name'] = this.state.representativeOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.representative_id)?.label;
        repDetails['phone'] = this.state.representativeOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.representative_id)?.phone;
        repDetails['email'] = this.state.representativeOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.representative_id)?.email;
        repDetails['company_id'] = this.state.addRepresentativeData?.company_id;
        repDetails['company_address'] = this.state.companyOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.company_id)?.address;
        repDetails['company_phone'] = this.state.companyOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.company_id)?.phone;
        repDetails['company_email'] = this.state.companyOptions?.find(item =>
            item.value === this.state.addRepresentativeData?.company_id)?.email;
        this.props.handleRepData(repDetails);
        this.props?.notificationComponent(true, "success");
        this.props?.onClose();
        this.setState({ repIndex: this.state.repIndex + 1 })
    }


    render() {
        return (
            <MDBContainer className="AddRepresentiveContainer">
                <span className='header'>Select a new representative for this talent</span>
                <MDBRow>
                    <SearchSelectField
                        id="company"
                        label="Company"
                        placeholder="- Search -"
                        width="100%"
                        searchSelect={true}
                        multiple={false}
                        detail_selected={this.state.selectedCompany || null}
                        options={this.state.companyOptions || []}
                        valueSelected={(e, newValue) =>  {
                            this.setState({ selectedCompany: newValue })
                            this.handleOnchange('company', newValue)
                        }}
                        searchText={(e) => this.getCompanyData('COMPANY', e.target.value)}
                    />
                </MDBRow>
                <MDBRow>
                    <SearchSelectField
                        id="representative"
                        label="Representative"
                        placeholder="- Search -"
                        width="100%"
                        searchSelect={true}
                        multiple={false}
                        detail_selected={this.state.selectedRep || null}
                        options={this.state.representativeOptions || []}
                        valueSelected={(e, newValue) =>  {
                            this.setState({ selectedRep: newValue })
                            this.handleOnchange('representative', newValue)
                        }}
                        searchText={(e) => this.getRepresentiveData('REPRESENTATIVE', e.target.value)}
                    />
                </MDBRow>
                <MDBRow className="p-2 margin">
                    <MDBCol md={6} lg={6}></MDBCol>
                    <MDBCol md={3} lg={3}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            text={
                                this.state.isPosting ? (
                                    <CircularProgress color="inherit" size={18} />
                                ) : "Add"
                            }
                            icon={"Add"}
                            onClick={this.handleSubmit}
                        />
                    </MDBCol>
                    <MDBCol>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={() => {
                                this.props?.onClose();
                            }}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
export default withUserContext(AddRepresentive);
