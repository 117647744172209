export const recipientInitialConfig = {
    "type": null,
    "name": null,
    "email": null,
    "sign_type": null
}

export const ccInitialConfig = {
    "object_name": null,
    "object_email": null,
    "object_type": 'C',
    "index": 1,
    "is_delete": 0
}

export const eSignInitialConfig = {
    "contract_id": null,
    "template_name": "rptContract",
    "reportName": "rptContract",
    "showHtml": 0,
    "filename": "Contract",
    "to_recipients": [],
    "cc_recipients": [],
    "save_as_draft": null,
    "url": null,
    "show_id": null,
    "show_type": null,
    "show_name": null,
    "show_season_id": null,
    "season_name": null,
    "episode_id": null,
    "episode_name": null,
    "episodeGlCode": null,
    "seasonStartYear": null,
    "seasonEndYear": null,
    "document_type": 'Unsigned',
    "preview": null,
    "performer_id": null,
    "rider": null,
    "is_rider": 0,
    "is_already_signed": 0,
    "agency_address_on_contract": null,
    "rep_email_on_contract": null,
    "esign_contract_type": 'Contract Packet'
}