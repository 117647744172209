import * as React from 'react';

export const FilterContext = React.createContext({

 });

 export const withFilterContext = Component => {
    class FilterContextComponent extends React.Component {
      render() {
        return (
          <FilterContext.Consumer>
            {(value) => <Component filterContext = {value} {...this.state} {...this.props}/>}
          </FilterContext.Consumer>
        );
      }
    }

    return FilterContextComponent;
  };
