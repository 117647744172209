import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import TableComponent from "../SharedComponents/Table";
import { repTalentConfig, renderTalentRepList } from "./config";
import { MDBRow } from "mdb-react-ui-kit";
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import './CompanyProfile.scss'
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { representativeAnimationList } from '../../../constants/NavList';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { Popover } from "@material-ui/core";

class CompanyRepresentative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: this.props?.renderList,
      config: repTalentConfig,
      rowsPerPage: 10,
      page: 0,
      addCompanyRepresentive: false,
      openRepresentativeModal: false,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.renderList) });
  }

  handleAddRepresentiveModal = () => {
    this.setState({ addCompanyRepresentive: true })
  }

  hideAddRepresentiveModal = () => {
    this.setState({ addCompanyRepresentive: false })
  }

  handleDeleteRow = (item, node, is_delete = 1) => {
    this.setState({ selectedTalentRep: node.talent_rep_company_mtrx_id, is_delete: 1 })
    this.setState({ postInitiated: true })
    let payload = {};
    payload['talent_id'] = null;
    payload['representative_id'] = node.representative_id;
    payload['company_id'] = this.props?.companyId;
    payload['is_delete'] = is_delete
    is_delete === 1 ? payload['talent_rep_company_mtrx_id'] = node.talent_rep_company_mtrx_id : payload['talent_rep_company_mtrx_id'] = null;
    this.setState({ isPosting: true })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostRepCompany`,
      payload,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        // this.props.fieldChanged(true, "success");
        this.setState({
          isPosting: false,
          postInitiated: false
        })
        this.props?.getcompanyDetails()
      },
        (err) => {
          console.log('Error is submitting Show' + err);
          this.props.fieldChanged(true, "fail");
          this.setState({ isPosting: false, postInitiated: false })
        });
  }

  navigationCallback = (dataItem, node) => {
    this.setState({ openRepresentativeModal: true, selectedRepId: dataItem.representative_id })
  }

  handleHideRepPopup = () => {
    this.setState({ openRepresentativeModal: false });
  }

  handleOpenContractPopOver = (id, dataItem, event) => {
    this.setState({
      contractAnchorEl: event.currentTarget,
      selectedTalentName: dataItem.talent_name
    })
  }

  render() {
    const open = Boolean(this.state.contractAnchorEl);
    return (
      <div className="CompanyReprContainer">
        <MDBRow id={"companytalentTable"}>
          <TableComponent
            list={this.props?.renderList || []}
            config={this.state.config}
            handleDelCallBack={(item, node) => this.handleDeleteRow(item, node)}
            sortCallback={this.props?.handleTableClick}
            hyperLinkNavigationCallback={this.navigationCallback}
            handleOpenInlineModal={this.handleOpenContractPopOver.bind(this)}
          />
          {this.state.selectedTalentName &&
            <Popover
              id={'contract-popover'}
              open={open}
              anchorEl={this.state.contractAnchorEl}
              onClose={() => this.setState({ contractAnchorEl: null })}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {this.state.selectedTalentName &&
                <MDBRow onClick={() => this.handleContractRedirection(item)}>
                  <p className="performer-contract">
                    {this.state.selectedTalentName}
                  </p>
                </MDBRow>
              }
            </Popover>
          }
        </MDBRow>

        <MDBRow className="Pagination">
          {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
            <BasicButton
              variant="outlined"
              className="repBtn"
              type="inline"
              icon={"plus-circle"}
              text="Add Representative"
              align={"start"}
              onClick={this.handleAddRepresentiveModal}
              disabled={!this.props?.companyId || false}
            />}
          <TablePagination
            component="div"
            count={this.props?.renderList ? this.props?.renderList?.length : 0}
            rowsPerPageOptions={[]}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            align={"end"}
          />
        </MDBRow>
        {this.state.addCompanyRepresentive &&
          <MainViewModalComponent
            open={this.state.addCompanyRepresentive}
            handleClose={this.hideAddRepresentiveModal}
            headerText={"Add Representative"}
            mode={"New"}
            modalName={"AddCompanyRepresentive"}
            companyId={this.props?.companyId}
            toggleRefresh={this.props?.getcompanyDetails}
          />}
        {
          this.state.openRepresentativeModal &&
          <ModalComponent
            navFuns={this.functions}
            open={this.state.openRepresentativeModal}
            handleClose={this.handleHideRepPopup}
            tabList={representativeAnimationList}
            headerText={"Representatives"}
            objectType={'REPRESENTATIVE'}
            mode={"New"}
            repId={this.state.selectedRepId}
          />
        }
      </div>

    );
  }
}
export default withUserContext(CompanyRepresentative);
