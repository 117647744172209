export const TalentShortDetails={
    "talent_id": null,
  "first_name": null,
  "last_name": null,
  "is_active": 1,
  "middle_name": null,
  "suffix": null,
  "sag_id": null,
  "ssn": null,
  "gender": null,
  "ethnicity_name": null
}


