import React from 'react';
import { MDBIcon } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import Button from '@material-ui/core/Button';
import BasicLabel from '../BasicLabel/BasicLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import InlineSelectField from '../InlineSelectField/InlineSelectField';
import './InlineButtonField.scss';

export default class InlineButtonField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textFieldInputs: [],
        }
    }

    componentDidMount() {
        this.handleTextFieldInput();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value != this.props?.value) {
            this.handleTextFieldInput();
        }
        if (this.props?.options !== prevProps?.options) {
            this.handleTextFieldInput();
        }
    }

    handleTextFieldInput = () => {
        let inputArray = [];
        if (this.props.showSelect) {
            inputArray.push(<InlineSelectField
                className="Select"
                textFieldLength={6}
                selectFieldLength={6}
                label=""
                options={this.props?.options || []}
                selectValue={this.props?.value[1] || null}
                fieldValue={this.props?.value[0] || null}
                onSelectChange={(e) => this.props?.onChange(e, 2)}
                onFieldChange={(e) => this.props?.onChange(e, 1)}
            />)
        } else {
            if (this.props.totalTextFieldCount) {
                for (let i = 1; i <= this.props?.totalTextFieldCount; i++) {
                    inputArray.push(
                        <BasicTextField
                            id={`inlineTextField${i}`}
                            className={`TextField${i}`}
                            value={this.props?.value || ""}
                            placeholder={this.props?.placeholder}
                            onChange={(e) => this.props?.onChange(e, i)}
                            disabled={this.props?.disabled || false}
                            fieldValid={this.props?.fieldValid}
                            inValidInput={i - 1 == 0 ? this.props?.inValidInput : ""}
                            limit={this.props?.limit || ""}
                            limitWarning={this.props?.limitWarning || ""}
                        />
                    );
                }
            }
        }
        this.setState({ textFieldInputs: inputArray })
    }

    render() {
        return (
            <div className="InlineButtonFieldContainer">
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                <div className="InlineButtonField">
                    {this.state.textFieldInputs}
                    <Button 
                        onClick={this.props?.buttonOnClick}
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={this.props?.disabled || false}>
                        {this.props?.editIcon ?
                            <MDBIcon icon={this.props?.editIcon} /> :
                            <>
                                {this.props?.placeholder?.length > 0 && this.props?.placeholder[0] === "Edit Value" ?
                                    <EditIcon fontSize="small" /> : <AddCircleIcon fontSize="small" />
                                }
                            </>
                        }

                    </Button>
                </div>
                <span className="errorText">{this.props.fieldValid && this.props?.options ? this.props.inValidInput : null}</span>
            </div>
        );
    }
}