import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import TablePagination from "@material-ui/core/TablePagination";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../components/SharedComponents/Table';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { handlePaginationList } from "../../Common/TableHelper";
import { talentAnimationList } from '../../../constants/NavList';
import { withUserContext } from '../../../contexts/UserContext';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { searchTalentConfig, talentFilterJson, talentSortByConfig, minorOptions } from './Config';
import SearchFilters from './SearchFilters';
import './Search.scss';

class SearchTalentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            config: searchTalentConfig,
            filterValues: JSON.parse(JSON.stringify({ ...talentFilterJson })),
            rowsPerPage: 10,
            page: 0,
            sortBy: "Date Created",
            isSortByAsc: false,
            sortCount: 0,
            talentFilters: [],
            clearFilter: false,
            selectedSort: {
                value: 1,
                label: 'Date Created',
                sortBy: 'Date Created'
            },
            sortOrder: 'DESC'
        }
    }

    componentDidMount() {
        this.getTalentDetails();
        this.getLookupData('GENDER', 'genderOptions');
        this.getLookupData('ETHNICITY', 'ethnicityOptions');
    }

    getTalentDetails = () => {
        this.setState({ isFetchingTalentList: true })
        let payload = { ...this.state.filterValues };
        payload['order_by_clause'] = this.state.selectedSort?.sortBy;
        payload['order_by'] = this.state.isSortByAsc === true ? 'ASC' : 'DESC';
        payload['page_count'] = this.state.rowsPerPage;
        payload['page_no'] = this.state.page + 1;
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/animationGetSearchTalent?input=${btoa(JSON.stringify(payload))}`,
            this.props.userContext.active_tenant.tenant_id, 1)
            .then(response => {
                let tableData = response?.data?.records || [];
                tableData?.map(item => {
                    if (!item.show_id) {
                        item['disableNavButton'] = true;
                    }
                    return item;
                })
                this.setState({
                    list: handlePaginationList(0, this.state.rowsPerPage, tableData),
                    isFetchingTalentList: false,
                    talentCount: response?.data?.query_record_count
                })
            },
                (err) => {
                    console.log("Error in fetching performers:", err)
                    this.setState({ isFetchingTalentList: false })
                })
    }

    getLookupData = (entity, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data;
                if (entity === 'REP_TYPE' || entity === 'GENDER' || entity === 'ETHNICITY') {
                    formattedList = formattedList?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));
                } else {
                    formattedList = formattedList?.map(item => ({ value: item.lookup_data_id, text: item.lookup_value, is_active: item.is_active }));
                }
                this.setState({ [optionName]: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getEntitySearch = (entity, optionName, searchString = null, showId = null) => {
        let url = '';
        if (entity === 'SHOW_SEASON') {
            url = `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}&showId=${showId}`
        } else {
            url = `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + url,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data
                if (entity === 'REPRESENTATIVE') {
                    formattedList = formattedList?.map(item => ({ value: item.representative_id, text: item.representative_name, company_id: item.company_id, company_name: item.company_name }));
                } else if (entity === 'SHOW') {
                    formattedList = formattedList?.map(item => ({ value: item.show_id, text: item.show_name }));
                } else if (entity === 'SHOW_SEASON') {
                    formattedList = formattedList?.map(item => ({ value: item.show_season_id, label: item.season_name }));
                }
                this.setState({ [optionName]: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleFilterValues = (field, value) => {
        this.setState((prevState) => ({
            filterValues: {
                ...prevState.filterValues,
                [field]: value,
            },
        }))
    }

    handleFilterOnChange = (filterField, filterName, value) => {
        if ((filterField === 'recording_date_range_start' || filterField === 'recording_date_range_start') &&
            this.state.filterValues?.recording_date_range_start && this.state.filterValues?.recording_date_range_end
        ) {
            console.log("this if condition is called", this.state.filterValues, this.state.talentFilters);
            this.handleRecordingDateFilters(filterField, filterName, value)
        } else {
            this.setState((prevState) => ({
                filterValues: {
                    ...prevState.filterValues,
                    [filterField]: value,
                },
            }), () => {
                let talentFilters = [...this.state.talentFilters];
                let filteredShowName = this.state.showList?.find(item => item.value === value)?.text;
                let filterSeasonName = this.state.seasonOptions?.find(item => item.value === value)?.label;
                let filteredTalent = talentFilters?.find(item => item.filter === filterField);
                let filteredMinorLabel = minorOptions?.find(item => item.value === value)?.label;
                let filterValue = {};
                filterValue['filter'] = filterField;
                if (filteredTalent) {
                    talentFilters?.map(item => {
                        if (item?.filter === filterField) {
                            item.value = filterField === 'show_id' ? (filterName + ': ' + filteredShowName) :
                                filterField === 'show_season_id' ? (filterName + ': ' + filterSeasonName) :
                                    filterField === 'is_minor' ? (filterName + ': ' + filteredMinorLabel) :
                                        (this.state.filterValues?.recording_date_range_start && this.state.filterValues?.recording_date_range_end) ?
                                            (filterName + ': ' + this.state.filterValues?.recording_date_range_start + ' - ' + this.state.filterValues?.recording_date_range_end) :
                                            (this.state.filterValues?.rate_range_start && this.state.filterValues?.rate_range_end) ?
                                                (filterName + ': ' + this.state.filterValues?.rate_range_start + ' - ' + this.state.filterValues?.rate_range_end) :
                                                filterName + ': ' + value;
                        }
                        return item;
                    })
                } else {
                    filterValue['value'] = filterField === 'show_id' ? (filterName + ': ' + filteredShowName) :
                        filterField === 'show_season_id' ? (filterName + ': ' + filterSeasonName) :
                            filterField === 'is_minor' ? (filterName + ': ' + filteredMinorLabel) :
                                (this.state.filterValues?.recording_date_range_start && this.state.filterValues?.recording_date_range_end) ?
                                    (filterName + ': ' + this.state.filterValues?.recording_date_range_start + ' - ' + this.state.filterValues?.recording_date_range_end) :
                                    (this.state.filterValues?.rate_range_start && this.state.filterValues?.rate_range_end) ?
                                        (filterName + ': ' + this.state.filterValues?.rate_range_start + ' - ' + this.state.filterValues?.rate_range_end) :
                                        filterName + ': ' + value;
                }
                if (filterValue?.value) {
                    talentFilters?.push(filterValue);
                }
                this.setState({ talentFilters: talentFilters })
                this.getTalentDetails();
            })
        }
    }

    handleRecordingDateFilters = (filterField, filterName, value) => {
        this.setState((prevState) => ({
            filterValues: {
                ...prevState.filterValues,
                [filterField]: value,
            },
        }), () => {
            let talentFilters = [...this.state.talentFilters];
            let filteredIndex = talentFilters?.findIndex(item => item.filter === "recording_date_range_start" || item.filter === 'recording_date_range_end');
            talentFilters[filteredIndex]['value'] = filterName + ': ' + this.state.filterValues?.recording_date_range_start + ' - ' + this.state.filterValues?.recording_date_range_end;
            this.setState({ talentFilters: talentFilters }, () => {
                this.getTalentDetails()
            })
        })

    }

    handleFilterDelete = (filterIndex, filter) => {
        let talentFilters = [...this.state.talentFilters];
        talentFilters = talentFilters.filter(function (item) {
            return item?.filter !== filter
        })
        let filterValues = { ...this.state.filterValues }
        filterValues[filter] = null;
        this.setState({
            talentFilters: talentFilters,
            filterValues: filterValues,
            deletedFilter: filter
        }, () => {
            this.getTalentDetails();
        })
    }

    handleClearCriteria = () => {
        let filterValues = {}
        this.setState({
            talentFilters: [],
            filterValues: JSON.parse(JSON.stringify({ ...talentFilterJson })),
            clearFilter: true
        }, () => {
            this.getTalentDetails()
        })
    }

    handleSetClearFilter = () => {
        this.setState({ clearFilter: false })
    }

    functions = {
        handleHideTalentPopup: () => {
            this.setState({ openTalentModal: false });
        },
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getTalentDetails();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getTalentDetails();
                });
                break;
        }
    }

    navigationCallback = (dataItem, node) => {
        this.setState({
            openTalentModal: true,
            selectedTalentId: dataItem.talent_id,
            selectedTalentName: dataItem.talent_name
        })
    }

    handleRecordNavigation = (dataItem) => {
        this.props?.history.push({
            pathname: `/caster-animation/show-season/${dataItem.show_id}`,
            state: {
                talent_id: dataItem.talent_id,
                season_id: dataItem.show_season_id
            }
        });
    }

    handleSortClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSortClose = (selectedSort = null) => {
        if (this.state.selectedSort?.value !== selectedSort?.value) {
            this.setState({
                anchorEl: null,
                selectedSort: selectedSort,
                sortBy: selectedSort?.sortBy
            }, () => {
                this.getTalentDetails()
            })
        } else {
            this.setState({ anchorEl: null })
        }
    }

    handleSortDesc = () => {
        this.setState({
            sortBy: 'Talent',
            isSortByAsc: false,
            sortOrder: 'DESC'
        }, () => {
            this.getTalentDetails();
        })
    }

    handleSortAsc = () => {
        this.setState({
            sortBy: 'Talent',
            isSortByAsc: true,
            sortOrder: 'ASC'
        }, () => {
            this.getTalentDetails();
        })
    }

    render() {
        return (
            <MDBContainer fluid className="searchTalentContainer">
                <MDBRow>
                    <MDBCol>
                        <span className="searchTalentHeading">
                            {'Search Talent'}
                        </span>
                    </MDBCol>
                    {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
                        <MDBCol className="talent-profile-btn">
                            <BasicButton
                                variant="contained"
                                type="inline"
                                className="outlined-btn"
                                icon={"plus-circle"}
                                text="New Talent Profile"
                                onClick={() => this.setState({
                                    openTalentModal: true,
                                    selectedTalentId: null,
                                    selectedTalentName: null
                                })}
                            />
                        </MDBCol>
                    }
                </MDBRow>
                <MDBRow>
                    <span className="helper-text">
                        Search for Talent records. To add a filter, either select a value, or type in a field below and click ENTER.
                    </span>
                </MDBRow>
                <MDBRow className="search-filters">
                    <SearchFilters
                        isTalent={true}
                        handleFilterOnChange={this.handleFilterOnChange}
                        handleFilterValues={this.handleFilterValues}
                        getEntitySearch={this.getEntitySearch}
                        handleSetClearFilter={this.handleSetClearFilter}
                        genderOptions={this.state.genderOptions}
                        ethnicityOptions={this.state.ethnicityOptions}
                        minorOptions={minorOptions}
                        seasonOptions={this.state.seasonOptions}
                        representativeOptions={this.state.representativeOptions}
                        showList={this.state.showList}
                        clearFilter={this.state.clearFilter}
                        deletedFilter={this.state.deletedFilter}
                    />
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBRow className="talentTableContainer">
                            <MDBCol md={8} className="margin-right">
                                {this.state.talentFilters?.length > 0 ?
                                    <div className="filter-section">
                                        <div>
                                            {this.state.talentFilters?.map((item, index) => (
                                                <Chip
                                                    label={item?.value}
                                                    onDelete={() => this.handleFilterDelete(index, item?.filter)}
                                                />
                                            ))}
                                        </div>
                                        <div className="clear-criteria-btn">
                                            <BasicButton
                                                variant="outlined"
                                                text="Clear Filters"
                                                type="inline"
                                                onClick={this.handleClearCriteria}
                                            />
                                        </div>
                                    </div> : <p className="no-filters">No Filters added</p>
                                }
                            </MDBCol>
                            <MDBCol md={3}>
                                <div className="filter-section sort-section">
                                    <span className="sort-by-label">Sort By:</span>
                                    <div className="sort-by-menu">
                                        <Button
                                            aria-controls="sort-menu"
                                            aria-haspopup="true"
                                            onClick={this.handleSortClick}
                                            endIcon={<MDBIcon icon="chevron-right" />}
                                        >
                                            {this.state.selectedSort?.label || ''}
                                        </Button>
                                        <Menu
                                            id="sort-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => this.handleSortClose(this.state.selectedSort)}
                                        >
                                            {talentSortByConfig?.map(item => (
                                                <MenuItem onClick={() => this.handleSortClose(item)}>
                                                    {item?.label}
                                                </MenuItem>

                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                {this.state.sortOrder === 'ASC' ?
                                    <MDBIcon fas icon="sort-alpha-down" onClick={this.handleSortDesc} /> :
                                    <MDBIcon fas icon="sort-alpha-up" onClick={this.handleSortAsc} />
                                }
                            </MDBCol>
                            <div className="search-talent-table">
                                <TableComponent
                                    list={this.state.list || []}
                                    isLoading={this.state.isFetchingTalentList}
                                    config={this.state.config}
                                    hyperLinkNavigationCallback={this.navigationCallback}
                                    handleButtonClick={this.handleRecordNavigation.bind(this)}
                                />
                            </div>
                            <TablePagination
                                component="div"
                                count={this.state.talentCount}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                page={this.state.page}
                                onChangePage={(e, page) => {
                                    this.setJson('page', page)
                                }}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={(e) => {
                                    this.setJson('pagePerRow', e.target.value)
                                }}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {this.state.openTalentModal &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.openTalentModal}
                        handleClose={this.functions.handleHideTalentPopup}
                        tabList={talentAnimationList}
                        headerText={"Talent"}
                        addButtonText={"New Talent"}
                        objectType={'TALENT'}
                        dataUrl='talentDetails'
                        talentId={this.state.selectedTalentId}
                        toggleRefresh={this.getTalentDetails}
                        selectedTalentName={this.state.selectedTalentName}
                        {...this.props}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withRouter(withUserContext(SearchTalentContainer));