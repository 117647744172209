import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import EpisodeList from './EpisodeList';
import AllTalent from './AllTalent';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import TabsComponent from '../../components/SharedComponents/Tabs/Tabs';
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import { seasonMenuItem, showSeasonTabList } from "./ShowSeasonConfig";
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import './ShowSeason.scss';

class ShowSeasonContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            tabValue: 1,
            selectedSeason: {},
            hideTabList: false,
            selectedEpisodeId:null,
            selectedPerformerId:null
        },
        this.talentRef = React.createRef();
        this.episodeListRef = React.createRef();
    }

    componentDidMount() {
        this.props.userContext.seasonData= "test";
        if (this.props?.match?.params?.ssid) {
            this.getShowSeasonDetails();
        }
        if (this.props?.history?.location?.state?.talent_id) {
            this.setState({ talentId: this.props?.history?.location?.state?.talent_id })
        }
    }

    getShowSeasonDetails = () => {
        this.setState({ isFetchingShowSeasonDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetShowSeasonEpisodes?showId=${this.props?.match?.params?.ssid}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({
                    showSeasonDetails: response?.data[0],
                    isFetchingShowSeasonDetails: false
                }, () => {
                    let signerDetails = {
                        signatory_name: this.state.showSeasonDetails?.signatory_name,
                        signatory_email: this.state.showSeasonDetails?.signatory_email,
                        production_rep_name: this.state.showSeasonDetails?.production_rep_name,
                        production_rep_email: this.state.showSeasonDetails?.production_rep_email,
                        executive_name: this.state.showSeasonDetails?.executive_name,
                        executive_email: this.state.showSeasonDetails?.executive_email
                    }
                    if (this.props?.history?.location?.state?.season_id) {
                        let selectedSeason = this.state.showSeasonDetails?.seasons?.find(item => item.show_season_id === this.props?.history?.location?.state?.season_id);
                        this.setState({ selectedSeason: selectedSeason })
                    } else {
                        this.setState({
                            selectedSeason: this.state.showSeasonDetails?.seasons[0]
                        })
                    }
                    this.setState({ signerDetails: signerDetails })                  
                })
            },
                (err) => {
                    console.log('Error is fetching Show Season Episode' + err);
                    this.setState({ isFetchingShowSeasonDetails: false })
                });
    }

    handleSeasonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSeasonClose = (selectedSeason = null, closePopUp = false) => {
        this.setState({ anchorEl: null, selectedSeason: selectedSeason }, () => {
            if (!closePopUp) {
                this.episodeListRef.current.getEpisodePerformers();
            }
        })
    };

    handleNewSeason = () => {
        this.setState({ openNewSeasonModal: true, anchorEl: null })
    };

    handleSetTalentId = () => {
        this.setState({ talentId: null })
    }

 
    selectedEpisode = (episodeId) => {
        this.setState({ selectedEpisodeId: episodeId },()=>{
            this.props?.userContext?.handleSeasonReportData(
                {text: this.state.showSeasonDetails?.show_name, value: this.props?.match?.params?.ssid},                
                this.state.showSeasonDetails?.show_name,
                this.state.selectedSeason?.show_season_id,
                this.state.selectedEpisodeId,
                this.state.selectedPerformerId
            );
        })     
    }

    selectedPerformer=(selectedPerformerId)=>{
        console.log(selectedPerformerId);
        this.setState({selectedPerformerId: selectedPerformerId},()=>{
              this.props?.userContext?.handleSeasonReportData(
                {text: this.state.showSeasonDetails?.show_name, value: this.props?.match?.params?.ssid},                
                this.state.showSeasonDetails?.show_name,
                this.state.selectedSeason?.show_season_id,
                this.state.selectedEpisodeId,
                this.state.selectedPerformerId
            );
        });
    }

    getTabDetails = () => {
        
        switch (this.state.tabValue) {
            case 1: return <EpisodeList
                episodeListRef={this.episodeListRef}
                showId={this.props?.match?.params?.ssid}
                showType={this.state.showSeasonDetails?.show_configuration}
                showName={this.state.showSeasonDetails?.show_name}
                productionCompany={this.state.showSeasonDetails?.production_company}
                union={this.state.showSeasonDetails?.union}
                seasonDetails={this.state.selectedSeason}
                showSeasonId={this.state.selectedSeason?.show_season_id}
                seasonName={this.state.selectedSeason?.season_name}
                episodeDetails={this.state.selectedSeason?.episodes}
                toggleRefresh={this.getShowSeasonDetails}
                handleHideTabList={() => this.setState({ hideTabList: true })}
                handleShowTabList={() => this.setState({ hideTabList: false, tabValue: 1 })}
                talentId={this.state.talentId}
                handleSetTalentId={this.handleSetTalentId}
                signerDetails={this.state.signerDetails}
                selectedEpisode = {this.selectedEpisode}
                selectedPerformer = {this.selectedPerformer}
            />
            case 2: return <AllTalent
                talentRef={this.talentRef}
                showId={this.props?.match?.params?.ssid}
                showType={this.state.showSeasonDetails?.show_configuration}
                showName={this.state.showSeasonDetails?.show_name}
                seasonDetails={this.state.selectedSeason}
                productionCompany={this.state.showSeasonDetails?.production_company}
                union={this.state.showSeasonDetails?.union}
                showSeasonId={this.state.selectedSeason?.show_season_id}
                seasonName={this.state.selectedSeason?.season_name}
                episodeDetails={this.state.selectedSeason?.episodes}
                handleHideTabList={() => this.setState({ hideTabList: true })}
                handleShowTabList={() => this.setState({ hideTabList: false })}
                signerDetails={this.state.signerDetails}
                selectedEpisode = {this.selectedEpisode}
                selectedPerformer = {this.selectedPerformer}
            />
        }
    }

    handlePerformersReload = () => {
        //handle refresh using ref to all talent
        this.talentRef.current.getAllPerformers();
    }

    render() {    
        return (
            <>
                {
                    this.state.isFetchingShowSeasonDetails ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                            <MDBContainer fluid className="showSeasonContainer">
                                <MDBRow className="showName">
                                    <MDBCol>
                                        <>
                                            <div className="heading-col">
                                                <div>
                                                    <span className="showSeasonHeading">
                                                        {this.state.showSeasonDetails?.show_name}
                                                    </span>
                                                </div>
                                                <div className="edit-show-btn">
                                                    <MDBIcon icon="pen" onClick={() => this.setState({ openEditShowModal: true })} />
                                                </div>
                                            </div>
                                            {this.state.showSeasonDetails?.show_configuration === 'Episodic' &&
                                                <div className="season-menu">
                                                    <Button
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={this.handleSeasonClick}
                                                        endIcon={<MDBIcon icon="angle-down" />}
                                                    >
                                                        {(this.state.selectedSeason?.season_name && this.state.selectedSeason?.start_year &&
                                                            this.state.selectedSeason?.end_year) ?
                                                            (this.state.selectedSeason?.season_name + ' (' + this.state.selectedSeason?.start_year + ' - ' + this.state.selectedSeason?.end_year + ')') :
                                                            ''
                                                        }
                                                    </Button>
                                                    <Menu
                                                        id="season-menu"
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={() => this.handleSeasonClose(this.state.selectedSeason, true)}
                                                    >
                                                        {this.state.showSeasonDetails?.seasons?.map(item => (
                                                            <MenuItem onClick={() => this.handleSeasonClose(item)}>
                                                                {(item?.season_name && item?.start_year &&
                                                                    item?.end_year) ?
                                                                    (item?.season_name + ' (' + item?.start_year + ' - ' + item?.end_year + ')') :
                                                                    ''
                                                                }
                                                            </MenuItem>

                                                        ))}
                                                        {this.state.showSeasonDetails?.show_configuration === 'Episodic' &&
                                                            <BasicButton
                                                                className="btnwidth"
                                                                variant="contained"
                                                                text={"New Season"}
                                                                // icon={"pen"}
                                                                onClick={() => this.handleNewSeason()}
                                                            />
                                                        }
                                                    </Menu>
                                                </div>
                                            }
                                        </>
                                    </MDBCol>
                                    {this.state.showSeasonDetails?.show_configuration === 'Episodic' &&
                                        <MDBCol className="edit-season-btn">
                                            <BasicButton
                                                variant="contained"
                                                type="inline"
                                                className="outlined-btn"
                                                icon={"pen"}
                                                text="Edit Season"
                                                onClick={() => this.setState({ openEditSeasonModal: true })}
                                            />
                                        </MDBCol>
                                    }
                                </MDBRow>
                                {!this.state.hideTabList &&
                                    <MDBRow>
                                        <MDBCol md={10} className="show-season-tab-list p-0">
                                            <TabsComponent
                                                tabList={showSeasonTabList}
                                                value={this.state.tabValue}
                                                onChange={(e, v) => this.setState({ tabValue: v })}
                                            />
                                        </MDBCol>
                                        {this.state.tabValue === 2 &&
                                            <MDBCol className="talent-record-btn">
                                                <BasicButton
                                                    variant="contained"
                                                    text={'New Talent Record'}
                                                    icon={'plus-circle'}
                                                    onClick={() => this.setState({ openNewTalentRecordModal: true })}
                                                />
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                }
                                <MDBRow>
                                    {this.getTabDetails()}
                                </MDBRow>
                                {this.state.openEditShowModal && (
                                    <MainViewModalComponent
                                        open={this.state.openEditShowModal}
                                        handleClose={() => this.setState({ openEditShowModal: false })}
                                        headerText={"Edit Show"}
                                        mode={"Edit"}
                                        modalName={"Show"}
                                        showId={this.props?.match?.params?.ssid}
                                        toggleRefresh={this.getShowSeasonDetails}
                                    />
                                )}
                                {this.state.openEditSeasonModal && (
                                    <MainViewModalComponent
                                        open={this.state.openEditSeasonModal}
                                        handleClose={() => this.setState({ openEditSeasonModal: false })}
                                        headerText={"Edit Season"}
                                        mode={"Edit"}
                                        modalName={"Season"}
                                        showId={this.props?.match?.params?.ssid}
                                        showSeasonId={this.state.selectedSeason?.show_season_id}
                                        toggleRefresh={this.getShowSeasonDetails}
                                    />
                                )}
                                {this.state.openNewSeasonModal && (
                                    <MainViewModalComponent
                                        open={this.state.openNewSeasonModal}
                                        handleClose={() => this.setState({ openNewSeasonModal: false })}
                                        headerText={"New Season"}
                                        mode={"New"}
                                        modalName={"Season"}
                                        showId={this.props?.match?.params?.ssid}
                                        toggleRefresh={this.getShowSeasonDetails}
                                    // showSeasonId={this.state.selectedSeason?.show_season_id}
                                    />
                                )}
                                {this.state.openNewTalentRecordModal &&
                                    <MainViewModalComponent
                                        open={this.state.openNewTalentRecordModal}
                                        handleClose={() => this.setState({ openNewTalentRecordModal: false })}
                                        headerText={"New Talent Record"}
                                        mode={"New"}
                                        modalName={"Talent Record"}
                                        showId={this.props?.match?.params?.ssid}
                                        toggleRefresh={this.handlePerformersReload}
                                        showSeasonId={this.state.selectedSeason?.show_season_id}
                                        navigationCallback={(talentDetails) => {
                                            this.talentRef.current.navigationCallback(talentDetails)
                                        }}
                                    />
                                }
                            </MDBContainer>
                        )
                }
            </>
        )
    }
}

export default withRouter(withUserContext(ShowSeasonContainer));