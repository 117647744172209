import React from 'react';
import Button from '@material-ui/core/Button';
import { MDBIcon } from 'mdbreact';
import './BasicButton.scss';

export default class BasicButton extends React.Component {

    handleFileSelect = (event) => {
        var fileName = event.target.files?.[0]?.name;
        if(fileName == undefined || fileName == "")
          alert("Please select file");
        else
          this.props?.uploadFile(event.target.files?.[0]);
    }
    render() {
        return (
            <div className={this.props?.type === "inline" ? "BasicButtonContainer Button-inline" : "BasicButtonContainer"}>
                {this.props?.inputType == "file" ? 
            
                <label htmlFor={"upload-file-"+ this.props?.id} disabled={this.props?.disabled ? this.props?.disabled : false}>
                <input
                            type="file"
                            style={{ display: 'none' }}
                            id={"upload-file-"+ this.props?.id}
                            onChange={this.handleFileSelect.bind(this)}                            
                            value=""
                            {...(this.props?.accept ? {accept:this.props?.accept} :{})}
                            onClick={(event)=> { 
                                event.target.value = null
                            }}
                            disabled={this.props?.disabled ? this.props?.disabled : false}
                    />
                {this.props.text ?
                <Button
                    id={this.props?.id}
                    component="div"
                    variant={this.props?.variant || "contained"}
                    size="small"
                    // color="tertiary"
                    ref={this.props.ref || ''}
                    className={"text-tertiary " +(this.props?.className ? this.props?.className : "")}
                    disabled={this.props?.disabled ? this.props?.disabled : false}>
                    {this.props?.icon && !this?.props?.iconRight ? <MDBIcon icon={this.props?.icon} /> : null}{this.props?.text ? this.props?.text : "Button"}
                    {this.props?.icon && this?.props?.iconRight ? <MDBIcon icon={this.props?.icon} /> : null}
                </Button> : <MDBIcon className={"action-icon"} icon={this.props?.icon} onClick={this.props?.onClick} /> }
                </label>                
                :
                this.props?.text ? <Button onClick={this.props?.onClick}
                id={this.props?.id}
                    variant={this.props?.variant || "contained"}
                    size="small"
                    // color="tertiary"
                    ref={this.props.ref || ''}
                    className={"text-tertiary " +(this.props?.className ? this.props?.className : "")}
                    disabled={this.props?.disabled ? this.props?.disabled : false}>
                    {this.props?.icon && !this?.props?.iconRight ? <MDBIcon icon={this.props?.icon} /> : null}{this.props?.text ? this.props?.text : "Button"}
                    {this.props?.icon && this?.props?.iconRight ? <MDBIcon icon={this.props?.icon} /> : null}
                </Button> :  <MDBIcon className={"action-icon"} onClick={this.props?.onClick} icon={this.props?.icon} /> 
                }
            </div>
        );
    }
}