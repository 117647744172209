export const contractConfig = {
	is_delete: 0,
	performer_deal_contract_id: null,
	performer_deal_id: null,
	contract_date: null,
	deal_date: null,
	currency: null,
	contract_status: null,
	rate: null,
	guarentee_period: null,
	guarentee_period_type: null,
	total_episodes: null,
	period: null,
	start_date: null,
	start_date_qualifier: "Approx",
	sent_date: null,
	plus_or_minus_type: null,
	plus_or_minus: "+/-",
	plus_or_minus_period: null,
	principal_free: null,
	principal_free_period: null,
	post_free: null,
	post_free_period: null,
	returned_date: null,
	revised_date: null,
	text: null,
	info: null,
	total_amount: null,
	is_dropout: null,
	dropout_from: null,
	memo_type: null,
	void: null,
	per: null,
	per_period: null,
	basic_free_type: null,
	basic_free_period: null,
	postInitiatedRateNew: false,
	postInitiatedGuarenteeNew: false,
	disableRider: true
}

export const initialCCConfig = {
	object_email: null,
	object_id: null,
	object_name: null,
	object_type: "CC",
	status: "Copied"
}