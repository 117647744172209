import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchResultsLayout from './SearchResultsLayout';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import './AutoCompleteSearch.scss';
import AlphabetVarForSearch from '../AlphabetVarPopover/AlphabetVarForSearch'
import SearchIcon from "@material-ui/icons/Search";
import ArrowIcon from "@material-ui/icons/FontDownloadOutlined";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import axios from 'axios';
import { MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import { withUserContext } from '../../../../contexts/UserContext';

export default withUserContext(class AutoCompleteSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            loading: false,
            isOpen: false,
            searchString:null,
            anchorEl:null,
            parentDropDownSelection: 1,
            childDropDownSelection: [],
            defautSelection: [],
            searchKeyWord: '',
            isSearchPopUp:false,
            searchCancelToken: null,
            aesKey: ''
        }
    }
    componentDidMount() {
        if (this.props.encryptionType == 2) {
            this.getAes();
        }
    }
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    sleep = (delay = 0) => {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        let active = true;
        this.setState({ searchKeyWord: value,loading:true })
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });

        if (!this.state.loading) {
            return undefined;
        }
        if (value == '') {
            this.setState({ loading: false, options: [] })
            return undefined;
        }
        let path = '';
        let detailsPath = '';
        if (this.props.objectType) {
            let objectType = this.props.objectType;
            path = Constants.CasterServiceBaseUrl + '/search' + `?objectType=${objectType}&searchString=${value}`
        } else {
            path = Constants.CasterServiceBaseUrl + '/' + this.props.dataUrl + `?searchString=${value}`
        }
        // if (this.props.dataUrl == 'talentDetails') {
        //     // path = Constants.CasterServiceBaseUrl + '/' + this.props.dataUrl + `?searchString=${value}&showSSN=1`
        //     path = Constants.CasterServiceBaseUrl + '/search' + `?objectType=Talent&searchString=${value}`
        // } else {
        //     // path = Constants.CasterServiceBaseUrl + '/' + this.props.dataUrl + `?searchString=${value}`
        //     path = Constants.CasterServiceBaseUrl + '/' + this.props.dataUrl + `?searchString=${value}`
        // }
        (async () => {
            let encryptionType = this.props.headerText === "Talent" ? 2 : 1;
            let talentEncryption = this.props.headerText === "Talent" ? '&encryption=true' : null;
            CasterService.getDataWithCancel(talentEncryption ? path + talentEncryption : path,
                // cancelToken, this.props.userContext?.active_tenant?.tenant_id, (this.props.encryptionType || 1), this.state.aesKey)
                cancelToken, this.props.userContext?.active_tenant?.tenant_id, encryptionType, this.state.aesKey)
                .then(response => {
                    this.setState({searchString:null})
                    if (active) {
                        if (this.props.headerText === "User Management Group") {
                            let formattedList = response.data?.map(item => ({ name: item.name, id: item.production_company_id }));
                            this.setState({ options: formattedList, loading: false });
                        } if (this.props.headerText === "Talent") {
                            this.setState({ options: response?.map(item=> ({name : item.name + (!item.ssn ? ' ' : ('-' + item.ssn) )  , id:item.id})) || [], loading: false });
                        }
                        else {
                            this.setState({ options: response?.data, loading: false });
                        }
                    }
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err)
                    })
        })();
        return () => {
            active = false;
        };
    };
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    handleParentSearchChange = (event) => {
        this.setState({ parentDropDownSelection: event.target.value, defautSelection: [] })
    };
    getAes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/aesSecretKey`)
            .then(response => {
                let secretKey = response?.data;
                this.setState({ aesKey: secretKey })
            },
                (err) => {
                    console.log("Error in AES:", err)
                })
    }
    render() {
        return (
            <div id='searchAutoComplete' disabled={this.props?.disabled || false}>
                <AlphabetVarForSearch
                    isOpen={this.state.isOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={() => { this.setState({ isOpen: false, anchorEl: null }) }}
                    searchString={this.state.searchString}
                    onChangeString={(value) => {this.setState({ searchString: value})}}
                    onClickSearch={() => {
                        this.setState({ isOpen: false, anchorEl: null, loading: true, isSearchPopUp:true, }, () => {
                            this.onChangeHandle(this.state.searchString) })
                    }}>
                </AlphabetVarForSearch>
                <Autocomplete
                    id="asynchronous-demo" className='searchautoCompleteStyle'
                    open={this.state.open || this.state.isSearchPopUp}
                    onOpen={() => {
                        this.setState({ open: true })
                    }}
                    onClose={() => {
                        this.setState({ open: false })
                    }}
                    renderOption={(props, option) => (
                         <>
                             <li {...props}>
                                 <SearchResultsLayout searchResult={props}
                                    headerText={this.props.headerText}></SearchResultsLayout>
                            </li>
                        </>
                    )}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            this.setState({searchString:null, isSearchPopUp:false})
                            let path = ''
                            if (this.props.objectType == 'AGENCY' || this.props.objectType == 'REPRESENTATIVE' ||
                                this.props.objectType == 'PRODUCTION_COMPANY' ||
                                this.props.objectType == 'TALENT' || this.props.objectType == 'CREW') {
                                if (this.props.objectType == 'TALENT') {
                                    path = Constants.CasterServiceBaseUrl + `/talentDetails?searchKey=id&searchString=${newValue.id}&showSSN=1`
                                } else {
                                    path = Constants.CasterServiceBaseUrl + '/' + this.props.dataUrl + `?searchKey=id&searchString=${newValue.id}`
                                }
                                var cancelToken = axios.CancelToken.source();
                                // CasterService.getEncryptedData(path, this.props.userContext?.active_tenant?.tenant_id, this.state.aesKey)
                                CasterService.getDataWithCancel(path, cancelToken, this.props.userContext?.active_tenant?.tenant_id, (this.props.encryptionType || 1), this.state.aesKey)
                                    .then(response => {
                                        this.props.handleSearchSelect(this.props.objectType == 'TALENT' ? response[0] : response?.data[0]);
                                    },
                                        (err) => {
                                        })
                            }
                            else {
                                this.props.handleSearchSelect(newValue);
                            }
                        }
                    }}
                    getOptionLabel={(option) => (this.props.headerText === "Agency" ?
                        option?.name : (this.props.headerText === "User Management Group" ? option?.name :
                            (this.props.headerText === "Production Companies" ?
                                //  option?.name : option.first_name + ' ' + option.last_name)))}
                                option?.name : option.name)))}
                    options={this.state.options}
                    loading={this.state.loading}
                    renderTags={() => null}
                    noOptionsText="No labels"
                    renderInput={(params) => {
                        this.props.headerText === "Talent"  ? params.inputProps.value = params.inputProps.value?.split('-')[0] : null
                        return (
                        <TextField
                            {...params}
                            placeholder={this.props?.placeholder}
                            variant="outlined"
                            onChange={ev => {
                                if (ev.target.value !== "" || ev.target.value !== null) {
                                    this.setState({ loading: true, searchString: ev.target.value,isSearchPopUp:false, }, () => { this.onChangeHandle(ev.target.value) })
                                } else { this.setState({ loading: true, options: [],isSearchPopUp:false, }) }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ?
                                            <CircularProgress color="inherit" size={20} /> : null}
                                        {this.props.headerText === "User Management Group" ? <MDBIcon icon="chevron-right" /> : <>{ this.props.searchAlphabetVariant && <ArrowIcon onClick={(event) => this.setState({isOpen:true, anchorEl: event.currentTarget, isSearchPopUp:false,})} /> }<SearchIcon /></>}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}}
                />
            </div>
        );
    }
}
);