import React from 'react';
import './AlphabetVarPopover.scss';
import { MDBBtnGroup, MDBBtn} from "mdb-react-ui-kit";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import alphabetVariantsJson from './AlphabetVariants.json';

export default class AlphabetVarPopover extends React.Component {
    constructor(props){
        super(props);
        // this.state={
        //     isOpen: false,
        //     anchorEl: null,
        //     currentChar: 'A',
        //     value: props?.value
        // }
    }

    // onKeyUp = (event) => {                      
    //     if ((event.keyCode === 32 && event.ctrlKey == true) || event.keyCode === 115){
    //        var char = 'A';
    //        var textValue = event?.target?.value;
    //        var textLen = textValue?.length;
    //        if(textLen > 0){
    //             char = textValue[textLen - 1];
    //        }
    //        this.setState({isOpen: true, 
    //         anchorEl: event.currentTarget,
    //         currentChar: char});
    //     } 
    //     else if(this.props?.onKeyUp){
    //         this.props?.onKeyUp(event);        
    //     }
    // } 

    // handlePopoverClose = () => {
    //     this.setState({isOpen : false, anchorEl: null});
    // }

    handleAlphabetVariantSelect = (val) => {
        console.log("Selected Alphabet::"+val);
        var len = this.props?.value?.length || 0;
        var value = (len > 0 ? this.props?.value.substring(0,len - 1): "" );
        value += val;
//        this.setState({value: value});
        this.props?.handlePopoverClose();
        this.props?.handleAlphabetVariantSelect(value);
    }

    render() {
        return (
            < div className="alphabetVariantsContainer">
            <Popover                        
                open={this.props?.isOpen}
                anchorEl={this.props?.anchorEl}
                onClose={this.props?.handlePopoverClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >                
                <MDBBtnGroup aria-label='Alphabet Variants'>
                    {alphabetVariantsJson?.[this.props?.currentChar] && alphabetVariantsJson?.[this.props?.currentChar].map((char) => {
                        return (<span className="margin-right-3 width-25"  onClick={this.handleAlphabetVariantSelect.bind(this, char)}>{char}</span>)                       
                    })}
                </MDBBtnGroup>
            </Popover>
             </div>       
        );
    }
}

// BasicTextField.defaultProps = {
//     onKeyUp: () => null
// }
