import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import DateField from "../SharedComponents/DateField/DateField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import { akaConfig, talentPersonalLimit, talentAka } from "./config";
import messages from "../../Common/Messages.json";
import {
  validateAlpVarCharNumOnlyWithSpace,
  formatPhoneNumbers,
  validateEmail,
  ssnFormat,
  validateAllPhone,
  validateNumbersOnly,
  formatZipCode,
  checkZipCode,
} from "../../Common/Helper";
import "./Talent.scss";
import ListField from "../SharedComponents/ListField/ListField";

class TalentContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      showSelectField: false
    };
  }

  render() {
    return (
      <div className="TalentContainer">
        <MDBRow>
          <MDBCol md={2}>
            <SelectField
              id={"address"}
              value={this.props?.talentDetails?.address_type || ""}
              label={"Address Type"}
              defaultMenuText={"- Select -"}
              options={this.props?.adressOptions}
              onChange={(e) =>
                this.props.handleGeneralEdit("address_type", e.target.value)
              }
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={5}>
            <BasicTextField
              label={"Address"}
              limit={talentPersonalLimit.address_1}
              limitWarning={messages.exceed200CharacterWarning || ""}
              value={this.props?.talentDetails?.address_1 || ""}
              onChange={(e) =>
                this.props?.handleGeneralEdit(
                  "address_1",
                  e.target.value.length > 0 ? e.target.value : null
                )
              }
            />
            <BasicTextField
              limit={talentPersonalLimit.address_2}
              limitWarning={messages.exceed200CharacterWarning || ""}
              value={this.props?.talentDetails?.address_2 || ""}
              onChange={(e) =>
                this.props?.handleGeneralEdit(
                  "address_2",
                  e.target.value.length > 0 ? e.target.value : null
                )
              }
            />
          </MDBCol>
          <MDBCol md={6}>
            <MDBRow>
              <MDBCol md={6}>
                <BasicTextField
                  label={"City"}
                  limit={talentPersonalLimit.city}
                  limitWarning={messages.exceed200CharacterWarning || ""}
                  value={this.props?.talentDetails?.city || ""}
                  onChange={(e) =>
                    this.props?.handleGeneralEdit(
                      "city",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol md={3}>
                {(this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'United States') ?
                  <SelectField
                    id={"state"}
                    value={this.props?.talentDetails?.state || ""}
                    label={"State"}
                    defaultMenuText={"- Select -"}
                    options={this.props?.stateOptions}
                    onChange={(e) =>
                      this.props.handleGeneralEdit("state", e.target.value)
                    }
                  /> :
                  <BasicTextField
                    id={"state"}
                    value={this.props?.talentDetails?.state || ""}
                    label={"State"}
                    onChange={(e) =>
                      this.props.handleGeneralEdit("state", e.target.value)
                    }
                  />
                }
              </MDBCol>
              <MDBCol md={3}></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={4}>
               {/*  <BasicTextField
                  label={"Zip Code"}
                  limit={talentPersonalLimit.zip}
                  limitWarning={
                    messages.exceed10CharacterWarning || "enter 10 numbers only"
                  }
                  // fieldValid={
                  //   !validateNumbersOnly(this.props?.talentDetails?.zip) &&
                  //   this.props?.talentDetails?.zip
                  // }
                  // inValidInput={messages.invalidInput}
                  // value={this.props?.talentDetails?.zip || ""}
                  // onChange={(e) =>
                  //   this.props?.handleGeneralEdit(
                  //     "zip",
                  //     e.target.value.length > 0 ? e.target.value : null
                  //   )
                  // }
                  value={this.props?.talentDetails?.zip || ""}
                  onChange={(e) => (this.props?.countryOptions?.find(item => item.value === e.target.value)?.label == 'United States' || this.props?.countryOptions?.find(item => item.value === e.target.value)?.label == 'USA' || this.props?.countryOptions?.find(item => item.value === e.target.value)?.label == 'US') ?
                    this.props?.handleGeneralEdit('zip', formatZipCode(e.target.value)) :
                    this.props?.handleGeneralEdit('zip', e.target.value)}
                  inValidInput={messages.invalidInput}
                  fieldValid={this.props?.talentDetails?.zip ? ((this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'United States' || this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label== 'USA' || this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'US') ? (this.props?.talentDetails?.zip?.length > 9 ? !checkZipCode(this.props?.talentDetails?.zip) ? true : false : false) : false) : false}
                /> */}
                <BasicTextField
                  label={"Zip Code"}
                  value={
                    (this.props?.countryOptions?.find(
                      item => item.value === this.props?.talentDetails?.country
                    )?.label === 'United States' ||
                      this.props?.countryOptions?.find(
                        item => item.value === this.props?.talentDetails?.country
                      )?.label === 'USA' ||
                      this.props?.countryOptions?.find(
                        item => item.value === this.props?.talentDetails?.country
                      )?.label === 'US')
                      ? formatZipCode(this.props?.talentDetails?.zip)
                      : this.props?.talentDetails?.zip || ""
                  }
                  onChange={e => {
                    const selectedCountryLabel = this.props?.countryOptions?.find(
                      item => item.value === this.props?.talentDetails?.country
                    )?.label;

                    if (
                      selectedCountryLabel === 'United States' ||
                      selectedCountryLabel === 'USA' ||
                      selectedCountryLabel === 'US'
                    ) {
                      this.props?.handleGeneralEdit('zip', formatZipCode(e.target.value));
                    } else {
                      this.props?.handleGeneralEdit('zip', e.target.value);
                    }
                  }}
                  inValidInput={messages.invalidInput}
                  fieldValid={this.props?.talentDetails?.zip ? ((this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'United States' || this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'USA' || this.props?.countryOptions?.find(item => item.value == this.props?.talentDetails?.country)?.label == 'US') ? (this.props?.talentDetails?.zip?.length > 9 ? !checkZipCode(this.props?.talentDetails?.zip) ? true : false : false) : false) : false}
                />
              </MDBCol>
              <MDBCol md={8}>
                <SelectField
                  id={"country"}
                  value={this.props?.talentDetails?.country || ""}
                  label={"Country"}
                  defaultMenuText={"- Select -"}
                  options={this.props?.countryOptions}
                  onChange={(e) => {
                    if (this.props?.countryOptions?.find(item => item.value === e.target.value)?.label !== 'United States') {
                      this.props?.handleGeneralEdit("state", null)
                    }
                    this.props.handleGeneralEdit("country", e.target.value)
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={6}>
            <BasicTextField
              label="Email"
              value={this.props?.talentDetails?.email || ""}
              fieldValid={
                !validateEmail(this.props?.talentDetails?.email) &&
                this.props?.talentDetails?.email
              }
              inValidInput={messages.invalidInput}
              onChange={(e) =>
                this.props.handleGeneralEdit("email", e.target.value)
              }
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label={"Phone"}
              placeholder="(xxx)xxx-xxxx"
              fieldValid={
                this.props?.talentDetails?.phone
                  ? !validateAllPhone(this.props?.talentDetails?.phone)
                  : false
              }
              inValidInput={"Please enter valid phone"}
              value={this.props?.talentDetails?.phone?formatPhoneNumbers(this.props?.talentDetails?.phone):""  || ""}
              onChange={(e) =>
                this.props.handleGeneralEdit("phone",formatPhoneNumbers(e.target.value))
              }
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
export default withUserContext(TalentContacts);
