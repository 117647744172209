import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import { getFormattedDate } from '../../Common/Helper';
import './Performer.scss';
import ReactHtmlParser from 'react-html-parser';

class ProjectDetailsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSSNVisible: false
        }

    }

    onEyeClick = () => {
        this.setState({ isSSNVisible: !this.state.isSSNVisible })
    }

    render() {
        let currentDate = new Date();
        let expDate = new Date(this.props?.performerDetails?.expiration_date);
       let ethnicityValues = this.props?.performerDetails?.ethnicity_list?.length > 0 ? this.props?.performerDetails?.ethnicity_list?.map(item => item.ethnicity) : []
        return (
            <div>
                <MDBRow className="project-details">
                    <MDBRow>
                        <MDBCol md={4}>
                            <MDBRow><MDBCol md={4} className="details-heading">DOB:</MDBCol><MDBCol md={8} className={(this.props?.age === 0 && this.props?.monthsAge) || this.props?.age < 18 ? "minor-details-heading" : ""}>{this.props?.performerDetails?.birth_date != "0000-00-00" && this.props?.performerDetails?.birth_date != "00/00/0000" && this.props?.performerDetails?.birth_date != null ? getFormattedDate(this.props?.performerDetails?.birth_date) + ' ' + '(' + this.props?.age + `yrs.old)` : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">SSN:</MDBCol><MDBCol md={4}>{!this.state.isSSNVisible ? this.props?.performerDetails?.masked_ssn : this.props?.performerDetails?.ssn}</MDBCol><MDBCol md={4}>{this.props?.performerDetails?.ssn ? <MDBIcon onClick={() => this.onEyeClick()} icon={!this.state.isSSNVisible ? "eye" : "eye-slash"} /> : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">Gender:</MDBCol><MDBCol md={8}>{this.props?.performerDetails?.gender ? this.props?.performerDetails?.gender : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">Ethnicity:</MDBCol><MDBCol md={8}>{ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}</MDBCol></MDBRow>
                        </MDBCol>
                        <MDBCol md={4}>
                            <MDBRow><MDBCol md={6} className="details-heading">Citizenship:</MDBCol><MDBCol md={6}>{this.props?.performerDetails?.citizen_of ? this.props?.performerDetails?.citizen_of : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={6} className="details-heading">Immigration Status:</MDBCol><MDBCol md={6}>{`${this.props?.performerDetails?.immigration_status ? this.props?.performerDetails?.immigration_status?.value ? this.props?.performerDetails?.immigration_status?.value : "" : ""}`}<span className={currentDate > expDate ? "expDate" : ""}>{`${this.props?.performerDetails?.expiration_date ? '(Exp ' + getFormattedDate(this.props?.performerDetails?.expiration_date) + ')' : "--"}`}</span></MDBCol></MDBRow>
                            <MDBRow><MDBCol md={6} className="details-heading">SAG ID:</MDBCol><MDBCol md={6}>{this.props?.performerDetails?.sag_id ? this.props?.performerDetails?.sag_id : "--"}</MDBCol></MDBRow>
                        </MDBCol>
                        <MDBCol md={4}>
                            <MDBRow><MDBCol md={4} className="details-heading">Prod Company:</MDBCol><MDBCol md={8}>{this.props?.performerDetails?.production_company?.value ? this.props?.performerDetails?.production_company?.value : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">Union:</MDBCol><MDBCol md={8}>{this.props?.performerDetails?.union ? this.props?.performerDetails?.union : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">SAG Status:</MDBCol><MDBCol md={8}>{this.props?.performerDetails?.sag_status ? this.props?.performerDetails?.sag_status : "--"}</MDBCol></MDBRow>
                            <MDBRow><MDBCol md={4} className="details-heading">I9 Status:</MDBCol><MDBCol md={8}>{this.props?.performerDetails?.i9_status ? this.props?.performerDetails?.i9_status : "--"}</MDBCol></MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}>
                            <MDBRow><MDBCol md={4} className="details-heading">Notes:</MDBCol><MDBCol md={8} className="comments">{this.props?.performerDetails?.comments ? ReactHtmlParser(this.props?.performerDetails?.comments) : "--"}</MDBCol></MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBRow>
            </div>
        )
    }
}
export default ProjectDetailsTab;