import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import CasterService from "../../../services/service";
import { CircularProgress } from "@material-ui/core";
import "./CastListModal.scss";

class CastListModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_confidential:1,
            role_type_name:null,
            isCastListModal:false
        };
    }

    handleChange = (field,value) => {
      if(value==="Confidential_display_rates"){
        this.setState({is_confidential:1})
      }else if(value==="Non-Confidential"){
        this.setState({is_confidential:0})
      }
    }

    handleTalentChange=(field,value)=>{
     if(value==="All"){
        this.setState({role_type_name:null})
     }else if(value==="regular"){
        this.setState({role_type_name:"Regular"})
     }else{
        this.setState({role_type_name:"Special"})
     }
    }

    handleSubmit = () => {
        this.setState({ isCastListModal: true })
    
        let searchPayload = {
          is_confidential: this.state.is_confidential,
          role_type_name: this.state?.role_type_name,
          episode_id : this.props?.selectedEpisodeId
        };

        Object.keys(searchPayload).map((obj) => {
          if (searchPayload[obj] === undefined) {
            searchPayload[obj] = null;
          }
        });
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
    
        let load = {
          payload: "casterTvJwpGenerateReport",
          reportName: "rptEpisodeCastList",
          fileName: "EpisodeCast",
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.division_id,
        };
        CasterService.getDataWS(load).then(
          (webSocketConn) =>
          (webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (response?.statusCode == 200) {
              this.setState({ isCastListModal: false })
              const filename = extractFilenameFromUrl(response?.body);
              let decodedFile = decodeURIComponent(filename)
                downloadUrl(response?.body, decodedFile);
            }
          })
        );
    
      }

    render() {
        return (
            <div className="SetRecurringRoleContainer">
                <div className="p-right">
                    <span className="font-header">Cast List Will be generated in your machine's browser as PDF.</span>
                    <MDBRow>
                        <MDBCol className="radio-font">
                            <FormControl>
                                <BasicLabel
                                    text={"Confidentiality"}
                                />
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className="radio-font"
                                    defaultValue={"Confidential_display_rates"}
                                    onChange={(e) => this.handleChange("Confidential_display_rates", e.target.value)}
                                >
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Confidential_display_rates"
                                        control={<Radio />}
                                        label="Confidential(display-rates)"
                                        onChange={(e) => this.handleChange("Confidential_display_rates", e.target.value)}
                                    />
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Non-Confidential"
                                        control={<Radio />}
                                        label="Non-Confidential"
                                        onChange={(e) => this.handleChange("Non-Confidential", e.target.value)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="radio-font">
                            <FormControl>
                                <BasicLabel
                                    text={"Talent"}
                                />
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className="radio-font"
                                    defaultValue={"All"}
                                    onChange={(e) => this.handleTalentChange("All", e.target.value)}
                                >
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="All"
                                        control={<Radio />}
                                        label="All"
                                        onChange={(e) => this.handleTalentChange("All", e.target.value)}
                                    />
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="regular"
                                        control={<Radio />}
                                        label="Series Regulars Only"
                                        onChange={(e) => this.handleTalentChange("regular", e.target.value)}
                                    />
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="special"
                                        control={<Radio />}
                                        label="Guest Cast Only"
                                        onChange={(e) => this.handleTalentChange("special", e.target.value)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="p-2 margin">
                        <MDBCol md={6} lg={6}></MDBCol>
                        <MDBCol md={3} lg={3}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                text={this.state.isCastListModal ? <CircularProgress color="inherit" size={18} /> : "Generate"}
                                icon={"Generate"}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={() => {
                                    this.props?.onClose();
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        );
    }
}
export default withUserContext(CastListModal);