import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import DateField from "../SharedComponents/DateField/DateField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import { akaConfig, talentPersonalLimit, talentAka } from "./config";
import messages from "../../Common/Messages.json";
import {
    validateAlpVarCharNumOnlyWithSpace,
    formatPhoneNumbers,
    validateEmail,
    ssnFormat,
    validateAllPhone,
} from "../../Common/Helper";
import "./Talent.scss";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { repInitialConfig } from "../../pages/Modals/AddRepresentive/Config";

class TalentRepresentative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            representativesDetails: this.props?.representativesDetails,
            addRepresentive: false,
            showMultiPrimaryCheckError: false,
            repIndex: 2
        };
    }

    handleAddRepresentiveModal = () => {
        this.setState({ addRepresentive: true })
    }

    hideAddRepresentiveModal = () => {
        this.setState({ addRepresentive: false })
    }

    handleRepData = (repData) => {
        repData['index'] = this.state.repIndex;
        repData['canRemove'] = true;
        let data = []
        let backup = this.state.representativesDetails
        data.push(repData)
        backup = backup.concat(data)
        this.setState({ representativesDetails: backup, repIndex: this.state.repIndex + 1 }, () => {
            this.props.getRepDetails(this.state.representativesDetails)
        })
    }

    handlePrimaryCheck = (field, index, value) => {
        let repDetails = [...this.state.representativesDetails];
        repDetails[index][field] = value === true ? 1 : 0
        let multiplePrimaryReps = repDetails?.filter(item => item.is_primary == 1);
        if (value === true && multiplePrimaryReps?.length > 1) {
            repDetails[index]['primaryError'] = true
        } else if (!value && multiplePrimaryReps?.length == 1) {
            repDetails?.map(item => {
                item['primaryError'] = false;
                return item;
            })
        } else {
            repDetails[index]['primaryError'] = false
        }
        this.setState({ representativesDetails: repDetails }, () => {
            this.props.getRepDetails(this.state.representativesDetails)
        })
    }

    handleRemoveRepDetail = (callbackItem, index) => {
        let representativesDetails = [...this.state.representativesDetails];
        console.log("check the rep details on remove", representativesDetails)
        representativesDetails[index].is_delete = 1;
        if (callbackItem?.talent_rep_company_mtrx_id) {
            let temp = [];
            this.props?.inActiveRepresentatives?.map((item) => {
                temp.push(item);
            });
            let repList = representativesDetails?.filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(repList[0]);
            this.props?.handleInActiveReps(temp)
        }
        let repList = representativesDetails?.filter(item =>
            (item.talent_rep_company_mtrx_id !== callbackItem.talent_rep_company_mtrx_id) || (item.index !== callbackItem.index)
        );
        // if (repList.length === 0) {
        //     representativesDetails = [{ ...repInitialConfig }];
        // } else {
        representativesDetails = repList;
        // }
        this.setState({ representativesDetails: representativesDetails });
    }

    render() {
        console.log("rep", this.state.representativesDetails)
        return (
            <div className="TalentRepresentativeContainer">
                {this.state.representativesDetails && this.state.representativesDetails.map((item, index) => (
                    <>
                        {item.is_delete != 1 &&
                            <>
                                <MDBRow>
                                    <MDBCol md={1} className="rep-primary-check">
                                        <BasicCheckbox
                                            label="primary"
                                            checked={item?.is_primary}
                                            onChange={(e) => this.handlePrimaryCheck('is_primary', index, e.target.checked)}
                                            align="start"
                                            id={"email" + index}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3}>
                                        <BasicLabel text={"Representative"} />
                                        <div className="repFont">
                                            <p>Agent:{item.representative_name}</p>
                                            <p>
                                                {item.phone ? item.phone : item.representative_phone ?
                                                    item.representative_phone : '-'
                                                }
                                            </p>
                                            <p>
                                                {item.email ? item.email : item.representative_email ?
                                                    item.representative_email : '-'
                                                }
                                            </p>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicLabel text={"Agency"} />
                                        <div className="repFont">
                                            <p>{item?.company_name || '-'}</p>
                                            <div className="agency-display">
                                                <p>Cell:{item?.company_phone || '-'}</p>
                                                <p>Work:{item?.work || '-'}</p>
                                                <p>{item?.company_email || '-'}</p>
                                            </div>
                                            <p>{item?.company_address || '-'}</p>
                                        </div>
                                    </MDBCol>
                                    {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
                                        <MDBCol className="removeBtn">
                                            <BasicButton
                                                variant="outlined"
                                                type="inline"
                                                icon={"minus-circle"}
                                                text="Remove"
                                                onClick={() => this.handleRemoveRepDetail(item, index)}
                                            />
                                        </MDBCol>
                                    }
                                </MDBRow>
                                {item.primaryError &&
                                    <p className="errorText">"Only one representative can be a primary. Multiple selection is not allowed</p>
                                }
                            </>
                        }
                    </>
                ))}
                <MDBRow>
                    {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
                        <MDBCol md={1} className="removeBtn">
                            <BasicButton
                                variant="outlined"
                                type="inline"
                                icon={"plus-circle"}
                                text="Add"
                                onClick={this.handleAddRepresentiveModal}
                            />
                        </MDBCol>
                    }
                </MDBRow>
                {this.state.addRepresentive &&
                    <MainViewModalComponent
                        open={this.state.addRepresentive}
                        handleClose={this.hideAddRepresentiveModal}
                        headerText={"Add Representative"}
                        mode={"New"}
                        modalName={"AddRepresentative"}
                        handleSelectedData={this.props.handleSelectedData}
                        handleRepData={this.handleRepData.bind(this)}
                    />}
            </div>
        );
    }
}
export default withUserContext(TalentRepresentative);
