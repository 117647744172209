export const uploadProfileParams = {
    "signedURLType": "download",
    "fileCategory": "",
    "tenantName": null,
    "objectType": "",
    "fileName": "",
    "uploadLocation": "",
    "showName": "",
    "seasonName": "",
    "showId": null,
    "seasonId": null,
    "episodeId": null,
    "episodeName": null,
    "performerName": null,
    "performerId": null
  }