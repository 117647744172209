import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon, MDBCardTitle } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBContainer,
} from "mdbreact";
import BasicLabel from "../../../caster-feature/components/SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import { DealMemoDateType,DealMemoPickUpDrops,DealMemoPickUpDropscount } from "./DealMemoConfig";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import defaultImg from "../../../assets/WBDLogo.png";
import DateField from "../../components/SharedComponents/DateField/DateField";
import messages from "../../Common/Messages.json";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import {validateNumDotOnly, validateNumbersOnly,validateDate, convertDateFormat} from '../../Common/Helper';
import moment from 'moment';
import BasicCheckbox from "../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import {createDealMemoRecords} from './DealMemoConfig'


class DealPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealPointDetails: { ...this.props?.dealPointDetails },
      roleTypeOptions: [],
      isDropAndPickUp: false,
      contractTypeOptions: [],
      perTypeOptions: [],
      perTypeOptionsBackup: [],
      initialLoad: true,
      isRegularType: false,
      talentDetails:[],
      showDropPickUps: this.props?.dealPointDetails?.drop_pickup_count > 0 ? true :false,
      dateType: DealMemoDateType
    };
  }

  componentDidMount(){
   
    this.getContactAndRoleTypes("lu_contract_type",this.props?.union_id)
    this.getContactAndRoleTypes("lu_period",null)
    this.setState({ initialLoad: false });
    let data= {...this.state.dealPointDetails}
    this.setState({dealPointDetails: data})
    if(this.props.isContract && this.props.dealPointDetails?.role_type_id === 1){
      let data={...this.state.dealPointDetails}
      data = {...createDealMemoRecords?.dealPointDetails}
      this.setState({ dealPointDetails: data})
    }
    let addContract_no= {...this.state.dealPointDetails}
    addContract_no.contract_no = this.props.dealPointDetails?.contract_no;
    this.setState({ dealPointDetails: addContract_no});
   // this.setState({ dealPointDetails: {contract_no: this.props.dealPointDetails?.contract_no }})
  }


  handleChange=(listname,field,value)=>{   
    this.setState({
      dealPointDetails: {
        ...this.state.dealPointDetails,
        [field]: value
      }
    },()=>{ this.props.handleDealPointChange(this.state.dealPointDetails)});
   
  }

  updatePerTypeByContractType(field, value) {
    if (field === 'contract_type') {
      const contractType = this.state.contractTypeOptions?.find(contractType => {
        return contractType.value === value;
      });
      if (contractType?.type_map) {
        let filteredPerType = this.state.perTypeOptionsBackup.filter(perType => {
          if(perType?.type_map === contractType?.type_map){
              return perType;
            }else if(this.state.dealPointDetails?.per_period_id == perType?.value){
              perType['is_active']=0;
              return perType;
            }
          // return perType?.type_map === contractType?.type_map || 
          // this.state.dealPointDetails?.per_period_id == perType?.value;
        });
        this.setState({ perTypeOptions: filteredPerType});
      } else {
        this.setState({ perTypeOptions: perTypeOptionsBackup });
      }
    }
  }

  getContactAndRoleTypes = (objectType,SearchString) => {
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpLookupDetails?objectType=${objectType}&searchString=${SearchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item?.is_active,
          type_map: item?.type_map || null
        }));
        if(objectType === "lu_contract_type"){
          this.setState({contractTypeOptions: filteredList});
        }else{
          this.setState({perTypeOptions: filteredList,perTypeOptionsBackup:filteredList});
        }
       
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dealPointDetails !== this.props.dealPointDetails) {
      this.setState({ dealPointDetails: this.props?.dealPointDetails });
    } if (prevProps.isRegularType !== this.props.isRegularType) {
      if(this.props.isRegularType){
      let data = {...this.state.dealPointDetails}
      data.billing =  data.billing ? data.billing : (!data.contract_no ? "Deal in Business Affairs" : " "),
      data.dressing =  data.dressing ? data.dressing: (!data.contract_no ? "Deal in Business Affairs" : " ")
      this.setState({dealPointDetails: data})
      } else{
        let data = {...this.props.dealPointDetails}
        data.billing = data.billing,
        data.dressing =  data.dressing
        this.setState({dealPointDetails: data})
      }
    }
  }


  render() {
    let startDate = null
    let frimDate = null
    if(this.props.dateFields?.start_date === "0000-00-00"||this.props.dateFields?.start_date ===null){
      // startDate = moment().format('YYYY-MM-DD')
    }if(this.props.dateFields?.firm_date === "0000-00-00"||this.props.dateFields?.firm_date ===null){
      // frimDate = moment().format('YYYY-MM-DD')
    }
    let is_non_sag_mandatory_fields = null;
    if ((!this.props?.isRegularType && this.props?.talentDetails?.non_sag === 1) || (this.props?.isRegularType)) {
      is_non_sag_mandatory_fields = false;
    } else {
      is_non_sag_mandatory_fields = true;
    }
    return (
      <div className="DealPointsContainer">
        <MDBCard className="mt">
          <MDBCardBody>
            <MDBCardText>
            <MDBRow>
            <MDBCol md={6}>
              <BasicLabel className="mainlabel-text" text={"Deal Points"} />
              </MDBCol>
              <MDBCol md={6}>
              <span className="contractor-text">
              {(this.props?.mode === "view" || this.props?.mode === "edit") && this.state.dealPointDetails?.contract_no === DealMemoPickUpDropscount.oneX && DealMemoPickUpDrops.oneX}
              {(this.props?.mode === "view" || this.props?.mode === "edit") && this.state.dealPointDetails?.contract_no === DealMemoPickUpDropscount.twoX && DealMemoPickUpDrops.twoX}
              {(this.props?.mode === "view" || this.props?.mode === "edit") && this.state.dealPointDetails?.contract_no === DealMemoPickUpDropscount.threeX && DealMemoPickUpDrops.threeX}
               </span>
              {/* {(this.props?.mode === "new" && this.props?.isContract) ||
               this.state.dealPointDetails?.drop_deal_memo_id !== null ?
              <span className="contractor-text">2nd Contract</span> : " "} */}
               </MDBCol>
              </MDBRow>
              <MDBRow className="label-color">
                {(this.props?.mode === "view" ) ?
                   <MDBCol md={2}>
                  <BasicLabel text={"Start Date"}/>
                  <span className={"subText-font"}>{this.props?.dateFields?.start_date && moment(this.props?.dateFields?.start_date).format('MM/DD/YYYY') }</span>
                 </MDBCol>: " "}
                 {/* {(this.props?.mode === "view") ?
                   <MDBCol md={2}>
                  <BasicLabel text={"On/About"}/>
                  <span className={"subText-font"}>{this.state.dealPointDetails?.is_on_about_start_date === 1 ? `Yes` : `No`}</span>
                 </MDBCol>: " "} */}
                 {this.props?.mode === "view" ?   " " :
                     <MDBCol md={2}>
                      <DateField
                        id={"start-date"}
                        size="small"
                        label={"Start Date"}
                        isMandatory={is_non_sag_mandatory_fields}
                        showMandatory={is_non_sag_mandatory_fields ===true && this.props?.postInitiated || false}
                        mandatoryWarning={is_non_sag_mandatory_fields === true && !validateDate(this.props?.dateFields?.start_date ) && messages.mandatoryWarning}
                        value={this.props?.dateFields?.start_date }
                        onChange={(e) => {
                            this.props?.handleDateChange("start_date", e.target.value);  
                            (!e.target.value && this.handleChange("dealPointDetails","date_type", null) )
                        }}
                      />
                      {/* {!validateDate(this.props?.dateFields?.start_date  ) ? (
                        <span className={"subText-font"} style={{color:"red"}}>please enter the current or future dates only</span> 
                      ): " "} */}
                    
                    </MDBCol> 
                    }
                     {this.props?.mode === "view" ?   
                  (<MDBCol md={2}>
                 <BasicLabel text={"Date Type"}/>
                 <span className={"subText-font"}>{
                 this.state.dateType.find(date_type=>{
                  return (date_type.value == this.state.dealPointDetails?.date_type);
                })?.label
                  }</span>
                </MDBCol>)
                 :
                  <MDBCol md="2">
                    <SelectField
                        id={"Date-Type"}
                        label={"Date Type"}
                        size="small"
                        value={this.state.dealPointDetails?.date_type || ""}
                        options={this.state.dateType}
                        isMandatory={ this.props?.dateFields?.start_date && is_non_sag_mandatory_fields}
                        showMandatory={this.props?.dateFields?.start_date && is_non_sag_mandatory_fields===true && this.props?.postInitiated || false}
                        onChange={(e) =>{
                         this.handleChange("dealPointDetails","date_type", e.target.value)
                        }
                        }
                      />
                    </MDBCol>}
                    {/* {((this.props?.mode === "view")) ?   " " :
                     <MDBCol md={1}>
                      <div class="BasicLabelContainer"><label class="text"> </label></div>
                        <BasicCheckbox
                          id={"is_on_about_start_ date"}
                          label={"On/About"}
                          checked={this.state.dealPointDetails?.is_on_about_start_date === 1 ? true : false}
                          onChange={(e) =>
                              this.handleChange('dealPointDetails',"is_on_about_start_date", e.target.checked)
                          }
                        />
                     
                    </MDBCol> 
                    } */}
                 
                <MDBCol md={this.props?.mode === "view" ? 4 : 3}>
                  <MDBRow className="continuesSelect">
                    <MDBCol md={this.props?.mode === "view" ? 8 : 5} id="rate">
                      {this.props?.mode === "view" ? <div md={6}>
                        <BasicLabel text={"Rate"}/>
                        <span className={"subText-font"}>{"$" + (this.state.dealPointDetails?.rate ? this.state.dealPointDetails?.rate.toLocaleString('en-US') :"")}{this.state.dealPointDetails?.is_10_percentage=== 1? "(+10%)":" "}{(this.state.dealPointDetails?.per || this.state.per_period_id) ? "/" : " "}{this.state.dealPointDetails?.per}{" "}
                        { this.state.dealPointDetails?.per_period ?
                       this.state.dealPointDetails?.per_period: " "}{" "}{this.state.dealPointDetails?.guarantee ? `,${this.state.dealPointDetails?.guarantee +`${"Guarantee"}`}` : " "}</span>
                      </div> :
                      <BasicTextField
                        label={"Rate"}
                        placeholder="$0.00"
                        isMandatory={ is_non_sag_mandatory_fields}
                        showMandatory={this.props?.postInitiated || false}
                        mandatoryWarning={messages.mandatoryWarning}
                        value={("$" + (this.state.dealPointDetails?.rate || ""))}
                        onChange={(e) => {
                          const rateValue = e.target.value.replace("$", "");
                          if (validateNumDotOnly(rateValue)) {
                            this.handleChange('dealPointDetails', 'rate', rateValue);
                          }
                        }}
                      />
                    }
                    </MDBCol>
                    
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={" "}/>
                        {/* <span className={"subText-font"}>{this.state.dealPointDetails?.per}</span> */}
                      </div> :
                      <MDBCol md={2} id="per">
                      <BasicTextField
                        label={"Per"}
                        isMandatory={ is_non_sag_mandatory_fields}
                        showMandatory={is_non_sag_mandatory_fields===true && this.props?.postInitiated || false}
                        mandatoryWarning={is_non_sag_mandatory_fields===true && messages.mandatoryWarning}
                        value={this.state.dealPointDetails?.per || ""}
                        onChange={(e) => validateNumbersOnly(e.target.value) ? this.handleChange('dealPointDetails','per', e.target.value) : null}
                      />
                       </MDBCol>
                    }
                   
                 
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={" "}/>
                        {/* <span className={"subText-font"}>{this.state.dealPointDetails?.per_period_id === 1 ? "Days" : "Weeks"}</span> */}
                      </div> :
                         <MDBCol md={5} id={this.props?.mode === "view" ? "daysView": "days"}>
                      <SelectField
                        id={"Rate-Type"}
                        label={"Type"}
                        size="small"
                        isMandatory={ is_non_sag_mandatory_fields}
                        showMandatory={is_non_sag_mandatory_fields===true && this.props?.postInitiated || false}
                        mandatoryWarning={is_non_sag_mandatory_fields===true && messages.mandatoryWarning}
                        value={this.state.dealPointDetails?.per_period_id || ""}
                        options={this.state.perTypeOptions}
                        onChange={(e) =>{
                         this.handleChange("dealPointDetails","per_period_id", e.target.value)
                        }
                        }
                      />
                       </MDBCol>
                    }
                   
                  </MDBRow>
                </MDBCol>
                { this.props?.mode !== "view" ?
                <MDBCol md={1} className="perCheckbox">
                  <div class="BasicLabelContainer"><label class="text"> </label></div>
                    <BasicCheckbox
                label="+10%"
                checked={this.state.dealPointDetails?.is_10_percentage}
                onChange={(e) =>this.handleChange("dealPointDetails","is_10_percentage", e.target.checked)}
                id={"is"}
              />
                    </MDBCol>: " "}
                    {this.state.dealPointDetails?.deal_id_OLD &&
                    <MDBCol md={this.props?.mode !== "view" ? 1 : 2}>
                    <div>
                    <BasicLabel text={"Salary"}/>
                    <span className={"subText-font"}>{this.state.dealPointDetails?.salary}</span>
                  </div> 
                    </MDBCol>}
                { this.props?.mode !== "view" ? 
                    <MDBCol md={1}>
                       <BasicTextField
                    label={"Guarantee"}
                    // isMandatory={true}
                    // showMandatory={this.props?.postInitiated || false}
                    // mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.dealPointDetails?.guarantee || null}
                    onChange={(e) =>
                     this.handleChange("dealPointDetails","guarantee", e.target.value)
                    }
                  />
                    </MDBCol> : " "
                }
                <MDBCol md={2}>
                  <BasicLabel text={"No. of Guaranteed Episodes"} />
                  <MDBRow className="text-al">
                    {(this.props?.mode === "view" && this.state.dealPointDetails?.guarantee_episodes) ?
                    <MDBCol md={3} className="no_display">
                      <div className={"subText-font"}>{this.state.dealPointDetails?.guarantee_episodes || 0}</div>
                      <div>{"  "}</div>
                      <div className="ep-font ">out</div>
                      <div> </div>
                      <div className="ep-font ">of</div>
                      <div>{"  "}</div>
                      <div className={"subText-font"}>{this.state.dealPointDetails?.out_of_episodes}</div>
                        {/* <span className={"subText-font"}>{ (this.state.dealPointDetails?.guarantee_episodes || "")}{" "}   {<span className="ep-font space-change">out of </span>}<span >{`${ this.props?.totalEpisodes}`}</span></span> */}
                       {/* <span className={"subText-font"}>{this.state.dealPointDetails?.guarantee_episodes}</span>{" "}
                       <p className="ep-font mr-out">out of</p>{" "}
                       <span className={"subText-font"}>{this.state.dealPointDetails?.out_of_episodes }</span> */}
                    </MDBCol>: " "}
                    <MDBCol md={3} className="guearenteeEpisode">
                      {this.props?.mode === "view" ? <div>
                        {/* <span className={"subText-font"}>{this.state.dealPointDetails?.guarantee_episodes}</span> */}
                      </div> :
                      <div>
                      <BasicTextField
                        value={this.state.dealPointDetails?.guarantee_episodes || null}
                        onChange={(e) =>{this.handleChange('dealPointDetails','guarantee_episodes', e.target.value)}}
                      />
                      <>
                      {this.state.dealPointDetails?.guarantee_episodes  ? this.state.dealPointDetails?.out_of_episodes ? " " :<span className={"subText-font space-change"} style={{color:"red"}}>Please Enter Both Episode Values</span> : " "}

                      </>
                      {(this.state.dealPointDetails?.out_of_episodes && this.state.dealPointDetails?.guarantee_episodes) && 
                        ((parseInt(this.state.dealPointDetails?.guarantee_episodes)<= parseInt(this.state.dealPointDetails?.out_of_episodes))?" ":<span className={"subText-font space-change"} style={{color:"red"}}>Please enter less than or equal total episodes  {this.state.dealPointDetails?.out_of_episodes}</span>)}
                        
                      </div>
                    }
                    </MDBCol>
                    <MDBCol md={3}>{this.props?.mode !== "view" ?
                      <p className="ep-font mr-out">out of</p> : " "}
                    </MDBCol>                    <MDBCol md={3} className="outOfEpisode">
                      {this.props?.mode !== "view"&&
                        // <span className={"subText-font"}>{this.props?.totalEpisodes || 0 }</span>}
                      
                      <BasicTextField
                        value={this.state.dealPointDetails?.out_of_episodes || null }
                        onChange={(e) =>this.handleChange("dealPointDetails",'out_of_episodes', e.target.value)}
                      />} 
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="label-color">
                <MDBCol md={2}>
                  {this.props?.mode === "view" ? <div>
                    <BasicLabel text={"Contract Type"}/>
                    <span className={"subText-font"}>{this.state.dealPointDetails?.contract_type_name}</span>
                  </div> :
                  <SelectField
                    id={"contact-type"}
                    size="small"
                    label={"Contract Type"}
                    isMandatory={ is_non_sag_mandatory_fields}
                    showMandatory={is_non_sag_mandatory_fields===true && this.props?.postInitiated || false}
                    mandatoryWarning={is_non_sag_mandatory_fields===true && messages.mandatoryWarning}
                    value={this.state.dealPointDetails?.contract_type || null}
                    options={this.state.contractTypeOptions}
                    onChange={(e) =>
                      {
                        this.handleChange("dealPointDetails","contract_type", e.target.value);
                        this.updatePerTypeByContractType("contract_type",  e.target.value);
                      }
                      }
                  />
                }
                </MDBCol>
                {/* {this.state.dealPointDetails?.drop_deal_memo_id === null?  */}
                {/* {this.props.isRegularType ? " " :
                <> */}
                { //this.state.dealPointDetails?.is_drop_pickup > 0 ? " " :
                <MDBCol md={5} className="radio-font droppickupcontainer">
                    {(this.props?.mode === "view" || this.props?.mode === "edit") && 
                      <> <BasicLabel text={"Drop-and-Pick-up"} />
                        <span className="subText-font">
                          {!this.state?.dealPointDetails?.contract_no && this.props?.mode === "view" ?  this.state.dealPointDetails?.drop_pickup_count : ""}
                          {(this.state?.dealPointDetails?.contract_no) ? "This is pickup " +this.state?.dealPointDetails?.contract_no + " of " +this.state.dealPointDetails?.drop_pickup_total : ""}
                        </span></>
                    }

               { (this.props?.mode === "edit" || this.props?.mode === "new") && !this.state?.dealPointDetails?.contract_no &&
                 <div className="dropandPickup">
                  {/* <div class="BasicLabelContainer"><label class="text"> </label>
                  </div>                  */}
                    <BasicCheckbox
                      id={"drop-pick-up"}
                      label={"Select Drop and Pick-up"}
                      checked={this.state.showDropPickUps ? true : false}
                      onChange={(e) =>
                        {
                        this.setState({showDropPickUps: e.target.checked});
                        !e.target.checked && this.handleChange('dealPointDetails', "drop_pickup_count", null)
                        }
                      }
                    />
                    </div> 
                  }
               { (this.props?.mode === "edit" || this.props?.mode === "new") && this.state.showDropPickUps &&  !this.state?.dealPointDetails?.contract_no && 
               <MDBRow className="pickupdropMulticheckbox">
                <MDBCol className="col-md-auto pickupdropNos">
                <BasicLabel text={"Performer will be picked up a total of :"} />
                </MDBCol>
                    <MDBCol className="col-md-auto">
                   
                    <BasicCheckbox
                      id={"drop-pick-up-1x"}
                      label={"1x "}
                      checked={this.state.dealPointDetails?.drop_pickup_count === 1 ? true :false}
                      onChange={(e) =>
                        this.handleChange('dealPointDetails', "drop_pickup_count", DealMemoPickUpDropscount.oneX)
                      }
                    />
                    </MDBCol>
                    <MDBCol className="col-md-auto">
                    <BasicCheckbox
                      id={"drop-pick-up-2x"}
                      label={"2x "}
                      checked={this.state.dealPointDetails?.drop_pickup_count === 2 ? true :false}
                      onChange={(e) =>
                        this.handleChange('dealPointDetails', "drop_pickup_count", DealMemoPickUpDropscount.twoX)
                      }
                    />
                    </MDBCol>
                    <MDBCol className="col-md-auto">
                    <BasicCheckbox
                      id={"drop-pick-up-3x"}
                      label={"3x "}
                      checked={this.state.dealPointDetails?.drop_pickup_count === 3 ? true :false}
                      onChange={(e) =>
                        this.handleChange('dealPointDetails', "drop_pickup_count", DealMemoPickUpDropscount.threeX)
                      }
                    />
                    </MDBCol>
                  </MDBRow>
  }
                </MDBCol>} 
              
                { /*
                   (this.props?.mode === "view" && this.state.dealPointDetails?.drop_deal_memo_id === null )? <MDBCol md={2}>
                   <BasicLabel text={"Firm Recall Date"}/>
                   <span className={"subText-font"}>{this.state.dealPointDetails?.is_on_about=== 1 ? "None" : this.props.dateFields?.firm_date && moment(this.props.dateFields?.firm_date).format('MM/DD/YYYY')}</span> </MDBCol> : null
                   */
                }
               
                  {/*
                   (this.props?.mode === "view" && this.state.dealPointDetails?.drop_deal_memo_id === null )? <MDBCol md={2}>
                   <BasicLabel text={"On/About"}/>
                   <span className={"subText-font"}>{this.state.dealPointDetails?.is_on_about === null ? null : this.state.dealPointDetails?.is_on_about === 0 ? "No" : "Yes"}</span> </MDBCol> : null
                   */
                }
                 
                 {/* {((this.props?.mode === "view") || (this.props.mode === "new" && this.props?.isContract) || this.state.dealPointDetails?.drop_deal_memo_id) ?   " " :
                  <MDBCol md={4} className="firm-display">
                 
                          <div>
                            <BasicCheckbox 
                              id={"frim_date"}
                              label={<DateField
                                id={"firm-date"}
                                size="small"
                                label={"Firm Recall Date"}
                                // isMandatory={ this.props?.isRegularType  ? false :true}
                                disabled ={this.state.dealPointDetails?.is_on_about=== 1 }
                                // showMandatory={this.props.postInitiated || false}
                                // mandatoryWarning={messages.mandatoryWarning}
                                value={this.props.dateFields?.firm_date || null}
                                onChange={(e) => {
                                    this.props?.handleDateChange("firm_date", e.target.value); 
                                }}/>}
                                disabled ={this.state.dealPointDetails?.is_on_about=== 1 }
                                checked={this.state.dealPointDetails?.is_frim_check === 1 ? true : false}
                                onChange={(e) =>
                                    this.handleChange('dealPointDetails',"is_frim_check", e.target.checked)
                                }
                            />
                            {(this.state.dealPointDetails?.is_frim_check === 1 && this.props.dateFields?.firm_date === null)? 
                    <span className={"subText-font"} style={{color:"red"}}>please enter the firm recall date</span> 
                    : " "}
                          </div>
                          <div>
                          <div class="BasicLabelContainer"><label class="text"> </label></div>
                          <BasicCheckbox
                          id={"is_on_about"}
                          label={"On/About"}
                          disabled ={this.state.dealPointDetails?.is_frim_check=== 1 }
                          checked={ this.state.dealPointDetails?.is_on_about === 1 ? true : false}
                          onChange={(e) =>
                              this.handleChange('dealPointDetails',"is_on_about", e.target.checked)
                          }
                        />
                          </div>
                          </MDBCol>} */}
              </MDBRow>
              <MDBRow className="label-color">
                <MDBCol md={4}>
                  <MDBRow>
                    {this.props?.mode === "view" ? <div>
                      <BasicLabel text={"Billing"}/>
                      <span className={"subText-font"}>{this.state.dealPointDetails?.billing }</span>
                    </div>:
                    <BasicTextArea
                      label={"Billing"}
                      isMandatory={ this.props?.isRegularType  ? false :true}
                      showMandatory={this.props?.isRegularType ? false : this.props?.postInitiated || false}
                      mandatoryWarning={this.props?.isRegularType ? false : messages.mandatoryWarning}
                      value={this.state.dealPointDetails?.billing || null}
                      onChange={(e) =>
                       this.handleChange("dealPointDetails","billing", e.target.value)
                      }
                    />
                  }
                  </MDBRow>
                  <MDBRow>
                    {this.props?.mode === "view" ? <div>
                      <BasicLabel text={"To Be Billed As"}/>
                      <span className={"subText-font"}>{this.state.dealPointDetails?.to_be_billed_as }</span>
                    </div>:
                    <BasicTextField
                      label={"To Be Billed As"}
                      value={
                        this.state.dealPointDetails?.to_be_billed_as || null
                      }
                      onChange={(e) =>
                       this.handleChange("dealPointDetails","to_be_billed_as", e.target.value)
                      }
                    />}
                  </MDBRow>
                </MDBCol>
                <MDBCol md={4}>
                  {this.props?.mode === "view" ? <div>
                    <BasicLabel text={"Dressing"}/>
                    <span className={"subText-font"}>{this.state.dealPointDetails?.dressing}</span>
                  </div> :
                  <BasicTextArea
                    label={"Dressing"}
                    // isMandatory={ this.props?.isRegularType  ? false :true}
                    // showMandatory={this.props.postInitiated || false}
                    // mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.dealPointDetails?.dressing || null}
                    onChange={(e) =>
                     this.handleChange("dealPointDetails","dressing", e.target.value)
                    }
                  />}
                </MDBCol>
                <MDBCol md={4}>
                  {this.props?.mode === "view" ? <div>
                    <BasicLabel text={"Travel/Accomodations"}/>
                    <span className={"subText-font"}>{this.state.dealPointDetails?.travel_accommodations}</span>
                  </div>:
                  <BasicTextArea
                    label={"Travel/Accomodations"}
                    value={
                      this.state.dealPointDetails?.travel_accommodations || null
                    }
                    onChange={(e) =>
                     this.handleChange("dealPointDetails","travel_accommodations", e.target.value)
                    }
                  />}
                </MDBCol>
              </MDBRow>
              <MDBRow className="label-color">
                {this.props?.mode === "view" ? <div>
                  <BasicLabel text={"Additional Terms"}/>
                  <span className={"subText-font"}>{this.state.dealPointDetails?.additional_terms}</span>
                </div>:
                <BasicTextArea
                  label={"Additional Terms"}
                  value={this.state.dealPointDetails?.additional_terms || null}
                  onChange={(e) =>
                   this.handleChange("dealPointDetails","additional_terms", e.target.value)
                  }
                />}
              </MDBRow>
              <MDBRow className="label-color">
                {this.props?.mode === "view" ? <div>
                  <BasicLabel text={"Notes for Production"}/>
                  <span className={"subText-font"}>{this.state.dealPointDetails?.notes_for_production}</span>
                </div>:
                <BasicTextArea
                  label={"Notes for Production"}
                  value={this.state.dealPointDetails?.notes_for_production || null}
                  onChange={(e) =>
                   this.handleChange("dealPointDetails","notes_for_production", e.target.value)
                  }
                />}
              </MDBRow>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </div>
    );
  }
}
export default withUserContext(DealPoints);