export const repInitialDetails = {
  representative_id: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  representative_type: null,
  email: null,
  phone: null,
  company_id:null,
  company:{value:null,text:null},
};

export const repPersonalLimit = {
  firstName: 150,
  middleName: 150,
  lastName: 150,
  email: 200,
  phone: 13,
};

export const repTalentConfig = {
  headings: [
    {
      headingLabel: "Talent",
      width: "70%",
    },
    {
      headingLabel: "Datetime added",
      icon: "",
      width: "25%",
    },
  ],
  dataNodes: ["talent_name", "date_time_added"],
  hyperlinks: ["talent_name"],
  primaryKey: "talent_id",
  actions: ["times"],
};

export const renderTalentRepList = [
  {
    talent: "project",
    datetime_added: "2000-2001",
  },
  {
    talent: "project",
    datetime_added: "2000-2001",
  },
  {
    talent: "project",
    datetime_added: "2000-2001",
  },
  {
    talent: "project",
    datetime_added: "2000-2001",
  },
  {
    talent: "project",
    datetime_added: "2000-2001",
  },
  {
    episode_name: "project",
    datetime_added: "2000-2001",
  },
];
