export const statusOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "In-Active",
        value: 2
    },
]

export const userMockData = {
    "user_id": null,
    "is_active": null,
    "user_name": null,
    "title": null,
    "role_id": null,
    "role_name": null,
    "login_email": null,
    "notification_email": null,
    "phone": null,
    "all_projects": null,
    "user_projects_list": []
}

export const projectConfig = {
    "user_project_id": null,
    "project_id": null,
    "project_name": null,
    "index": 1,
    "is_delete": 0,
    "access_level_id": 0,
}