import React from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import TableComponent from "../SharedComponents/Table";
import {projectHistoryConfig,projectHistoryList} from "./Config";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import TablePagination from '@material-ui/core/TablePagination';
class ProjectHistory extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            project_history_list: this.props?.project_history_list,
            current_crewProjectHistory_List: this.props?.current_crewProjectHistory_List,
            config: JSON.parse(JSON.stringify({ ...projectHistoryConfig}))
        }
    }

    
    static getDerivedStateFromProps(props, state) {
        if (props.project_history_list !== state.project_history_list) {
            return {
                project_history_list: props.project_history_list
            }
        }
        if (props.config !== state.config) {
            return {
                config: props.config
            }
        }
        if(props?.current_crewProjectHistory_List !== state.current_crewProjectHistory_List) {
            return {
                current_crewProjectHistory_List: props?.current_crewProjectHistory_List
            }
        }
        // if(props?.representative_options !== state.representative_options) {
        //     return {
        //         representative_options: props?.representative_options
        //     }
        // }
        // if(props?.isEditing !== state.isEditing) {
        //     return {
        //         isEditing: props?.isEditing
        //     }
        // }
        // return null
    }

    navigationCallback =(obj, node) => {
        if(node.includes("project_name")) {
            this.props.navFuns.handleHideCrewPopup();           
            this.props?.history.push(`/caster-feature/project/${obj?.project_id}`) ;
            // this.props.navFuns.handleShowTalentPopup(obj.talent_id, obj.talent_name);
            this.props?.toggleRefreshPage('ProjectDetails');
           
        }
    }


    render() {
        return (
            <div className="projectHistoryTable">
                <MDBRow className="mt-1">
                    <MDBCol md={4} className="" >
                        <BasicTextField
                          id="project_name"
                          value={this.props?.filterList?.project_name}
                          onChange={(e) => {
                              this.props?.onChangeHandler("project_name", e.target.value)
                              this.props?.addFilterChangeHandler("Project", e.target.value)
                          }}/>
                    </MDBCol>  
                    <MDBCol md={4} className="pl-0">
                        <BasicTextField
                          id="location_name"
                          value={this.props?.filterList?.location_name}
                          onChange={(e) => {
                              this.props?.onChangeHandler("location_name", e.target.value)
                              this.props?.addFilterChangeHandler("Location", event.target.value);
                          }}/>
                    </MDBCol>
                    <MDBCol md={4} className="pl-0">
                        <BasicTextField
                          id="occupation"
                          value={this.props?.filterList?.occupation}
                          onChange={(e) => {
                              this.props?.onChangeHandler("occupation", e.target.value)
                              this.props?.addFilterChangeHandler("Occupation", event.target.value);
                          }}/>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <TableComponent
                        config={this.props?.config}
                        list={this.state.project_history_list}
                        hyperLinkNavigationCallback={this.navigationCallback}
                    />
                          <TablePagination
                            component="div"
                            count={this.props?.current_crewProjectHistory_List ? this.props?.current_crewProjectHistory_List?.length : 0}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            page={this.props?.page}
                            onChangePage={this.props?.handleChangePage}
                            rowsPerPage={this.props?.rowsPerPage}
                            onChangeRowsPerPage={this.props?.handleChangeRowsPerPage}
                        />
                </MDBRow>
            </div>
        )
    }
}
export default ProjectHistory