import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import SelectField from '../SharedComponents/SelectField/SelectField';
import { MDBCol, MDBRow } from 'mdbreact';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';


class MissingBirthdayReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="MissingBirthdayReportContainer">
             <MDBRow>
                <MDBCol md={7}>
                <SearchSelectField
                    id="project_id"
                    options={this.props?.projectList}
                    searchSelect={true}
                    multiple={false}
                    label={"Select Project"}
                    placeholder={"Select Project"}
                    detail_selected={this.props?.selectedprojectValue}
                    valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                    searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <span className='optionFilterColor'>Optional Filters</span>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Production Company"}
                        value={this.state.reportDetails?.production_company}
                        options={this.props.companyList}
                        onChange={(e) => this.props?.handleOnChange("production_company", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Union"}
                        value={this.state.reportDetails?.union}
                        options={this.props.unionList}
                        onChange={(e) => this.props?.handleOnChange("union", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow >
                <MDBCol md={10}  >
                    <MDBRow>
                    <MDBCol id={"perId"} md={6}>
                        <SelectField 
                            label={"Performer Type"}
                            value={this.state.reportDetails?.performer_type}
                            options={this.props.performerTypeOptions}
                            onChange={(e) => this.props?.handleOnChange("performer_type", e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol id={"feeId"} md={6}>
                        <SelectField 
                            label={"Fee Type"}
                            value={this.state.reportDetails?.fee_type}
                            options={this.props.feeOptions}
                            onChange={(e) => this.props?.handleOnChange("fee_type", e.target.value)}
                        />
                    </MDBCol>
                    </MDBRow>
                </MDBCol>
             </MDBRow>
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(MissingBirthdayReport));