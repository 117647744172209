import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import SearchSelectField from '../../../components/SharedComponents/SearchSelectField';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import ReportButton from './ReportButton';
import { genReportName } from '../../../Common/Helper';
import { withUserContext } from '../../../../contexts/UserContext';

class WorkingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            setEpisode: false,
            talentOptions: [],
            selectedTalentValues: [],
            postInitiated: false,
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({postInitiated : false})
        if (field === 'selectProduction' || field === 'selectSeason' || field === 'selectEpisode') {
            this.setState({
                [field]: val, 
                selectedTalentValues: []
            })
        } else {
            this.setState({
                [field]: val
            });
        }
    }

    getTalentOptions = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + '/productionSeasonEpisodePerformers?productionId=' + this.state.selectProduction?.value + '&psId=' + (this.state.selectSeason?.value === undefined ? null : this.state.selectSeason?.value) + '&episodeId=' + (this.state.selectEpisode?.value === undefined ? null : this.state.selectEpisode?.value) + '&performerOnly=' + 1, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let filteredList = response.data?.map(item => ({ value: item.talent_id, text: item.performer_name }));
                let removeDuplicates = filteredList.filter((value, index, arr) =>
                    index === arr.findIndex((item) => (
                        item.value === value.value))
                )
                this.setState({
                    talentOptions: removeDuplicates
                })
            })
    }

    handleSubmitWorkingHistory = () => {
        this.setState({ postInitiated: true});
        let { selectSeason, selectEpisode, selectProduction } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        let list = this.state.selectedTalentValues?.map(item => item.value)
        let result = list.join();
        let talent_list = "(" + result + ")";
        let talentLabels = this.state.selectedTalentValues?.map(item => item.text)
        let talent_label_list = talentLabels.join(" ");

        if (!production_id) {
            return;
        }
        let payload = {
            production_id,
            ps_id,
            episode_id,
            talent_list
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: talent_label_list,
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({postInitiated: false})
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer WorkingHistory">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            onChangeCallBack={this.onChangeCallBack}
                            report={"WorkingHistory"}
                            getTalentOptions={this.getTalentOptions}
                            productionReq={true}
                            seasonReq={false}
                            episodeReq={false}
                            postInitiated={this.state.postInitiated} />
                    </MDBRow>
                    <MDBRow className="searchSelect" id='talentsDropDown'>
                        <BasicLabel text={'Talent Name'} />
                        <SearchSelectField
                            id={"roles-select"}
                            placeholder={this.state.selectedTalentValues.length === 0 ? "-All Performers-" : ""}
                            multiple={true}
                            value={this.state.selectedTalentValues}
                            options={this.state.talentOptions}
                            width={300}
                            onChange={(e, value) => this.onChangeCallBack('selectedTalentValues', value)}
                        />
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.handleSubmitWorkingHistory}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(WorkingHistory);