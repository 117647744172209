import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import { withUserContext } from "../../../contexts/UserContext";
import ReportButton from './ReportButton';
import { genReportName } from '../../Common/Helper';
import './ResidualsPackage.scss';
import DateField from '../SharedComponents/DateField/DateField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import CasterService from "../../../services/service";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import MessageModal from '../SharedComponents/MessageModal';

class ResidualsPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDetails: [],
      residualpackageModal: false,
      pdfRes:null
    }
  }
  onChangeCallBack = (field, val) => {
    this.setState({
      [field]: val,
      postInitiated: false
    });
  }

  onClickGenratePdf() {
    this.setState({ postInitiated: true })

    let searchPayload = {
      show_season_id: this.props?.reportDetails.show_season_id,
      start_date: this.props?.reportDetails.start_date,
      end_date: this.props?.reportDetails.end_date,
      // episode_id: this.props?.reportDetails.episode_id,
      // performer_id: this.props?.reportDetails.performer_id,

    };

    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));

    let load = {
      payload: "animationGetResidualPackage",
      reportName: "rptresidualpackage",
      fileName: "rptresidualpackage",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1
    };
    CasterService.getDataWS(load).then(
      (webSocketConn) =>
      (webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data);
        if (response?.statusCode == 200 && response?.body?.msg) {
          this.setState({residualpackageModal:true,pdfRes:response.body.msg})
        }else if(response.message==="Endpoint request timed out"){
          this.setState({ postInitiated: true})
        }else{
          this.setState({ postInitiated: false})
          const filename = extractFilenameFromUrl(response?.body);
          let decodedFile = decodeURIComponent(filename)
          downloadUrl(response?.body, decodedFile);
        }
      })
    );
  }

  render() {
    return (
      <div>
        <MDBContainer className="reportContainer ResidualContainer">
          <span className='header'>Before generating,confirm End Credit reports are uploaded for each Episode in Episode List screen. A.zip File will be generated with folder for each report.</span>
          <MDBRow>
            <MDBCol md={8}>
              <SearchSelectField
                id={"show"}
                label={"Select Show"}
                defaultMenuText={"-"}
                options={this.props?.showOption}
                valueSelected={(e, value) =>
                  this.props.handleGeneralEdit("show_id", value)
                }
                searchSelect={true}
                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                detail_selected={this.props?.reportDetails?.show_id}
                />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={8}>
              <SelectField
                id={"season"}
                value={this.props?.reportDetails?.show_season_id}
                label={"Select season"}
                defaultMenuText={"-"}
                options={this.props?.seasonOption}
                onChange={(e) =>
                  this.props.handleGeneralEdit("show_season_id", e.target.value)
                }
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="dealDateRange">
            <MDBRow>
              <BasicLabel text={"Contract Date Range (optional)"} />
            </MDBRow>
            <MDBCol className="dealDateCol" md="5">
              <DateField
                id="selectStartDate"
                value={this.props?.reportDetails?.start_date || ""}
                label={""}
                onChange={(e) =>
                  this.props.handleGeneralEdit(
                    "start_date",
                    e.target.value
                  )
                }
              />
            </MDBCol>
            <MDBCol className="dealDateCol" md="1">
              <BasicLabel text={" "} />
            </MDBCol>
            <MDBCol className="dealDateCol" md="5" style={{ marginLeft: "-58px" }}>
              <DateField
                id="selectEndDate"
                value={this.props?.reportDetails?.end_date || ""}
                label={""}
                onChange={(e) =>
                  this.props.handleGeneralEdit(
                    "end_date",
                    e.target.value
                  )
                }
              />
            </MDBCol>
          </MDBRow>
          <ReportButton
            onClickGenratePdf={this.onClickGenratePdf.bind(this)}
            handleClose={this.props?.handleClose}
            postInitiated={this.state.postInitiated}
          />
        </MDBContainer>
        {
          this.state.residualpackageModal &&
          <MessageModal
            title={"PDF Download Failed"}
            open={this.state.residualpackageModal}
            message={
              this.state.pdfRes
            }
            primaryButtonText={""}
            secondaryButtonText={"Cancel"}
            handleClose={(e) => this.setState({ residualpackageModal: false })}
          />
        }
      </div>

    )
  }
}

export default withUserContext(ResidualsPackage)
