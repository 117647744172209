import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from 'mdbreact';
import { repTalentConfig } from './config';
import TableComponent from '../SharedComponents/Table';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import './Representative.scss';
import TablePagination from '@material-ui/core/TablePagination';


class RepresentativeTalent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyRepSearch: null,
            searchValues: [],
            config: JSON.parse(JSON.stringify({ ...repTalentConfig }))
        }
    }

    componentDidMount = () => {
        let repTalentConfig = this.state.config
        this.props?.isEditing ? repTalentConfig.actions = ["minus-circle"] : repTalentConfig.actions = []
        this.setState({ config: repTalentConfig })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.isEditing != this.props?.isEditing) {
            let repTalentConfig = this.state.config
            this.props?.isEditing ? repTalentConfig.actions = ["minus-circle"] : repTalentConfig.actions = []
            this.setState({ config: repTalentConfig })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.talent_list_to_display !== state.talent_list_to_display) {
            return {
                talent_list_to_display: props.talent_list_to_display
            }
        }
        if (props.config !== state.config) {
            return {
                config: props.config
            }
        }
        if (props?.selectedAgency !== state.selectedAgency) {
            return {
                selectedAgency: props?.selectedAgency
            }
        }
        if(props?.talent_options !== state.talent_options) {
            return {
                talent_options: props?.talent_options
            }
        }
        if (props?.isEditing !== state.isEditing) {
            return {
                isEditing: props?.isEditing
            }
        }
        return null
    }

    navigationCallback =(obj, node) => {
        if(node.includes("talent_name")) {
            this.props.navFuns.handleHideRepresentativePopup();
            this.props.navFuns.handleShowTalentPopup(obj.talent_id, obj.talent_name);
        }
    }

    render() {
        let list = this.props?.talent_list_to_display?.filter(item => item.is_delete != 1)
        let current_list_update = this.props?.current_list?.filter(item => item.is_delete != 1)
        return (
            <div className="agencyReps">
                <MDBRow>
                    {/* <MDBCol md={6}>
                        <BasicTextField
                            id="representative_name"
                            value={this.props?.filterList?.representative_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("representative_name", e.target.value)
                                this.props?.addFilterChangeHandler("Representative Name", e.target.value)
                            }}
                        />
                    </MDBCol> */}
                    <MDBCol md={6}>
                        <BasicTextField
                            id="covered_talent"
                            value={this.props?.filterList?.covered_talent}
                            onChange={(e) => {
                                this.props?.onChangeHandler("covered_talent", e.target.value)
                                this.props?.addFilterChangeHandler("Covered Talent", event.target.value);
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={12} id="agencyRepsTable">
                        <TableComponent
                            config={this.props?.config}
                            list={list}
                            stickyHeader={true}
                            loading={this.props?.isFetchingAgencyDetails}
                            sortCallback={this.props?.handleTableColumnClick}
                            handleDelCallBack={this.props?.handleDeleteTalent}
                            hyperLinkNavigationCallback={this.navigationCallback}
                        // hyperLinkNavigationCallback={this.navigationCallback}
                        />
                        <TablePagination
                            component="div"
                            count={current_list_update ? current_list_update?.length : 0}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            page={this.props?.page}
                            onChangePage={this.props?.handleChangePage}
                            rowsPerPage={this.props?.rowsPerPage}
                            onChangeRowsPerPage={this.props?.handleChangeRowsPerPage}
                        />
                    </MDBCol>
                </MDBRow>
                <hr />
                {this.props?.isEditing ?
                    <MDBRow>
                        <MDBCol md={5}>
                            <SearchSelectField
                                id={"alias"}
                                options={this.props?.talent_options || []}
                                width={'100%'}
                                detail_selected={this.props?.selectedTalent}
                                valueSelected={(e, value) => this.props?.handleSelectedTalent(value)}
                                searchText={(e) => this.props?.getTalentList("Talent", e.target.value)}
                                multiple={false}
                                searchSelect={true}
                                placeholder={"- Search By Alias or Company to Add"}
                            />
                        </MDBCol>
                        {this.props?.selectedTalent &&
                            <>
                                {/* <MDBCol md={3} className={"agencyData"}>
                                    <div>{this.props?.selectedRep?.occupation ? this.props?.selectedRep?.occupation + ' | ' : ''} {this.props?.selectedRep?.phone ? this.props?.selectedRep?.phone + ' | ' : ''}{this.props?.selectedRep?.email ? this.props?.selectedRep?.email + ' | ' : ''} </div>
                                </MDBCol> */}
                                <MDBCol md={1} className={"plus-circle"} onClick={this.props?.agencyRepAdd}>
                                    <AddCircleIcon />
                                </MDBCol>
                            </>}
                    </MDBRow>
                    : null}
            </div>
        );
    }
}

export default withUserContext(RepresentativeTalent);

