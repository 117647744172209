import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import TableComponent from "../../components/SharedComponents/Table";
import { existingDemoRenderList, existingDemoConfig } from "./DealMemoConfig";
import ExistingDealMemoDetails from "./ExistingDealMemoDetails";
import { updateTableSortConfigObject, tableSortList } from "../../Common/TableHelper";
import CreateDealMemo from "./CreateDealMemo";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import {downloadUrl,extractFilenameFromUrl} from "../../Common/Helper";
import moment from 'moment';
import MessageModal from "../../components/SharedComponents/MessageModal";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";

class ExistingDealMemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: existingDemoConfig,
      isNavigation: false,
      renderList:[],
      deal_memo_id: null,
      isExistingDealLoader: false,
      isSaveNewDeal:this.props?.isSaveNewDeal,
      contractTypePopup: false,
      sortCount: 0,
      sortBy: "Value",
      isSortByAsc: true,
      dealMemoListPdf:false,
      roleTypeName:"",
    };
  }

  componentDidMount(){
    if(this.props.isSearchFlag){
      this.setState({isNavigation: true, deal_memo_id: this.props?.searchItem?.deal_memo_id})
    }
    this.episodeDealMemoList(this.props?.selectedEpisodeId)
  }

  navigationCallback=(item, node)=>{
    this.setState({isNavigation: true, deal_memo_id: item.deal_memo_id})
  }

  episodeCallBack=()=>{
    this.setState({isNavigation: false ,isSaveNewDeal: false})
    this.episodeDealMemoList(this.props?.selectedEpisodeId)
  }

  episodeDealMemoList = (episodeId) => {
    this.setState({isExistingDealLoader: true})
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpEpisodeDealMemoList?episodeId=${episodeId}`,
        this.props?.userContext?.user_profile?.tenant_id,1
    )
      .then((res) => {
        if(res.data.length > 0){
          let filteredList = res.data[0]?.deal_memo_list
          const formattedStartDateList = filteredList.map(item => ({
            ...item,
            start_date: item.start_date === "0000-00-00" || (item?.start_date) ? moment(item.start_date).format('MM/DD/YYYY') : '',
            rate:`${(item?.rate ? '$'+item?.rate.toLocaleString('en-US') :"")}${item?.is_10_percentage=== 1? "(+10%)":" "}${`${item.per || item.per_period ? `/` : " "}`}${item?.per ? item.per : " "}${" "}
            ${item?.period_name ? item?.period_name : " "}${item?.guarantee ? `,${item?.guarantee}` : " "}`,
            updated_at: (item?.updated_at) ? (moment(item?.updated_at).format('MM/DD/YYYY')): ((item?.created_at) ? moment(item?.created_at).format('MM/DD/YYYY') : '')
          }));
          this.setState({
            renderList: formattedStartDateList,
            isExistingDealLoader: false
          });
        }
        else{
          this.setState({
            renderList: [],
            isExistingDealLoader: false
          });
        }
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  getSortNode = (col) => {
    switch (col) {
       case "Role Name": return "role";
       case "Talent": return "talent_name";
       case "To Be Billed As": return "to_be_billed_as";
       case "Role#": return "role_id";
       case "Role Type": return "role_type_name";
       case "Start Date": return "start_date";
       case "Contract Type": return "contract_type_name";
       case "Rate/Guarantee": return "rate";
       case "Last Revised Date": return "updated_at";
    }
}

getColumnType = (col) => {
  switch (col) {
     case "Role Name": return "Name";
     case "Talent": return "Name";
     case "To Be Billed As": return "Name";
     case "Role#": return "Name";
     case "Role Type": return "Name";
     case "Start Date": return "Date";
     case "Contract Type": return "Name";
     case "Rate/Guarantee": return "Name";
     case "Revised Date": return "Date";
  }
}

handleTableSort = (colName) => {
    this.setState({
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
        this.setState({
            sortBy: colName,
            renderList: tableSortList(this.getColumnType(colName), this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)

        });
    })
}


  selectPdf = (item, node, is_confidential = 1) => {

    let selectedDataItem = {
      primaryKeyId: item?.deal_memo_id,
      actionLabel: node
    }
    this.setState({ isGeneratingPdf: true, selectedDataItem: selectedDataItem })
    let searchPayload = {
      deal_memo_id : item?.deal_memo_id 
    };
    
    if (node === "DealMemo") {
      searchPayload.is_confidential = is_confidential;
      searchPayload.role_type_name=item?.role_type_name
    } 
    
    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    // const output = `rpt${item.union_name}${item.contract_type_name.replace(" ", "")}`;
    let load = {
      payload: "casterTvJwpGenerateReport",
      reportName: "rptDealMemo",
      fileName: "DealMemo",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1,
      tenantId: this.props?.userContext?.active_tenant?.tenant_id,
      divisionId: this.props?.division_id,
    };
    let contactLoad = {
      payload: "casterTvJwpGenerateReport",
      reportName: "rptContract",
      // loanout : item.loanout === null || item.loanout === 0 ? 0 : 1,
      fileName: "contactPdf",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1,
      tenantId: this.props?.userContext?.active_tenant?.tenant_id,
      divisionId: this.props?.division_id,
    };

    if(item.contract_type_name && (item.contract_type_name.includes("Pending") || item.role_type_name.includes("Regular")) && node !== "DealMemo" ){
      this.setState({contractTypePopup:true,isGeneratingPdf:false})
    }else{
      CasterService.getDataWS(node === "DealMemo" ? load :contactLoad).then(
        (webSocketConn) =>
        (webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data);
          if (response?.statusCode == 200) {
            this.setState({ isGeneratingPdf: false })
            const filename = extractFilenameFromUrl(response?.body);
            let decodedFile = decodeURIComponent(filename)
            downloadUrl(response?.body, decodedFile);
            if(node !== "DealMemo" && item.contract_type_name !== "Regular" && item.loanout !== null){
              let selectedDataItem = {
                primaryKeyId: item?.deal_memo_id,
                actionLabel: node
              }
              this.setState({ isGeneratingPdf: true, selectedDataItem: selectedDataItem })
              let searchPayload = {
                deal_memo_id : item?.deal_memo_id,
                loanout: 1
              };
              
              if (node === "DealMemo") {
                searchPayload.is_confidential = is_confidential;
                searchPayload.role_type_name=item?.role_type_name
              } 
              
              Object.keys(searchPayload).map((obj) => {
                if (searchPayload[obj] === undefined) {
                  searchPayload[obj] = null;
                }
              });
              let encryptTedStr = btoa(JSON.stringify(searchPayload));
              let contactLoad = {
                payload: "casterTvJwpGenerateReport",
                reportName: "rptContract",
                loanout : 1,
                fileName: "contactPdf",
                searchJson: encryptTedStr,
                showHtml: 0,
                encryptionType: 1,
                tenantId: this.props?.userContext?.active_tenant?.tenant_id,
                divisionId: this.props?.division_id,
              };
              if(item.contract_type_name && (item.contract_type_name.includes("Pending") || item.role_type_name.includes("Regular")) && node !== "DealMemo" ){
                this.setState({contractTypePopup:true,isGeneratingPdf:false})
              }else{
                CasterService.getDataWS(node === "DealMemo" ? load :contactLoad).then(
                  (webSocketConn) =>
                  (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                      this.setState({ isGeneratingPdf: false })
                      const filename = extractFilenameFromUrl(response?.body);
                      let decodedFile = decodeURIComponent(filename)
                      downloadUrl(response?.body, decodedFile);
                    }

                  }))}
                 
            }
          else{
            return("No loanout")
          }
          }
        })
      );
    }
    

  }

  dropAndPickupFun=(value)=>{
    this.props?.contractFun(value)
  }

  confirmationFun=(value)=>{
    this.props?.confirmationFun(value)
  }
  handleDealMemoListPdf = (item, node)=>{
    this.setState({dealMemoListPdf : true,deal_memo_id : item?.deal_memo_id,roleTypeName:item?.role_type_name })
  }
  hideDealMemoListPdf = ()=>{
    this.setState({dealMemoListPdf : false})
  }
  render() {
    console.log("selectedEpisodeId",this.props?.selectedEpisodeId)
    return (
      <div className="existingDemoContainer">
        {this.state.isNavigation || this.state.isSaveNewDeal?
        <MDBRow>
          <>
            <div>
              <MDBIcon fas
                icon="arrow-left"
                className="arrow-color"
                onClick={this.episodeCallBack}
              />
              <span className="ep-font">
                {" "}
                Back to Existing Deal Memos
              </span>
            </div>
            <CreateDealMemo  isExisitingDealMemoId={true} deal_memo_id={this.state.isSaveNewDeal ? this.props?.dealMemoId : this.state.deal_memo_id} union_id={this.props?.union_id}
              episodeCallBack={this.episodeCallBack} dropAndPickupFun={this.dropAndPickupFun.bind(this)} confirmationFun={this.confirmationFun.bind(this)} totalEpisodes={this.props.totalEpisodes}/>
          </>
        </MDBRow>
        :
        <MDBRow id={"existingDemo-table"} className="mt">
           {this.state.isExistingDealLoader ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={34} />
                </div>
              ) : 
          <TableComponent
            list={this.state.renderList || []}
            config={this.state.config}
            hyperLinkNavigationCallback={(item, node) =>
              this.navigationCallback(item, node)
            }
            sortCallback={this.handleTableColumnClick}
            selectedDataItem={this.state.selectedDataItem}
            isGeneratingPdf={this.state.isGeneratingPdf}
            handleDealMemoListPdf = {(item,node)=>this.handleDealMemoListPdf(item, node)}
            handleExistingDealMemoPdf={(item, node) =>
              this.selectPdf(item, node)
            }
          />
        }
        </MDBRow>
        }
        {this.state.contractTypePopup &&
        <MessageModal
        title={"Contract Type PDF Download Failed"}
        open={this.state.contractTypePopup}
        message={
          "Contract Type is an pending state not able to generate the PDF"
        }
        primaryButtonText={""}
        secondaryButtonText={"Cancel"}
        handleClose={(e) => this.setState({ contractTypePopup: false})}
      />
        }
        {this.state?.dealMemoListPdf && (
         <MainViewModalComponent
         open={this.state.dealMemoListPdf}
            handleClose={this.hideDealMemoListPdf}
            headerText={"Deal Memo PDF Options"}
            mode={" "}
            modalName={"Deal Memo PDF"}
            dealMemoId={this.state.deal_memo_id}
            roleTypeName={this.state.roleTypeName}
          />
        )}
      </div>
    );
  }
}
export default withUserContext(ExistingDealMemo);