import React, { Component } from 'react';
import { withUserContext } from '../../../contexts/UserContext';
import LandingPageCard from './LandingPageCard';
import PageNotFound from '../../../error-boundary/PageNotFound';
import LandingPageTable from './LandingPageTable';

class LandingPageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdminUser: false,
            isUserProvisioner: false,
            isShowUser: false
        }
    }

    componentDidMount() {  
        if (this.props?.userContext.user_profile) {
            if (this.props?.userContext.user_profile.user_role_name === "Admin") {
                this.setState({ isAdminUser: true });
            } else if (this.props?.userContext.user_profile.user_role_name === "User Provisioner") {
                this.setState({ isUserProvisioner: true })
            } else {
                this.setState({ isShowUser: true })
            }
        }
    }


    render() {
        return (
            <>
                {
                this.state.isAdminUser &&
                    <LandingPageTable/>
                }
                {
                    this.state.isShowUser &&
                    <LandingPageCard/>
                }
                {
                    this.state.isUserProvisioner &&
                    <PageNotFound/>
                }
            </>);
    }
}
export default withUserContext(LandingPageContainer);

