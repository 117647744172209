import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "./LandingPage.scss";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { withRouter } from "react-router-dom";

class LandingPageCardDetails extends Component {
  constructor(props) {
    super(props);
  }

  navigationCallback = () => {
    this.props.history.push(`/caster-tv-jwp/project-view/prid/${this.props?.obj.project_id}`);
  };

  render() {
    return (
      <div className="LandingCardContainer">
        <MDBRow>
          <MDBCol className="mb-4" key={this.props?.obj.project_type_name}>
            <MDBCard className="box-card">
              <MDBCardBody>
                <div className="bg-primary text-white p-2 small text-truncate header-container">
                  <div className="text-truncate project-type">
                    {this.props?.obj.project_type_name}
                  </div>
                  <div className="text-truncate season-year">
                    {this.props?.obj.season_year}{this.props?.obj.season_no ? `(${this.props?.obj.season_no})`: " "}
                  </div>
                </div>

                <MDBCardText className="card-text-container">
                  <MDBRow className="mb-4">
                    <span>{`${this.props?.obj?.project_name} (${this.props?.obj?.sap_gl_code})`}</span>
                  </MDBRow>
                  <MDBRow className="content-container">
                    <MDBCol md={8}>
                      {this.props.obj.outlet_name}
                    </MDBCol>
                    <MDBCol md={4} className="d-flex justify-content-end">
                      <BasicButton
                        variant="contained"
                        type="inline"
                        className="outlined-btn rounded-button"
                        text="Open"
                        onClick={this.navigationCallback}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardText>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
export default withRouter(LandingPageCardDetails);