import React from "react";
import { withRouter } from "react-router-dom";
import "./Project.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import {
  projectDetails,
  projectConfig,
  renderProjectList,
} from "./ProjectConfig";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableComponent from "../../components/SharedComponents/Table";
import DealMemo from "../DealMemo/DealMemo";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";


class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDeails: { ...projectDetails },
      expanded: false,
      isNavigation: false,
      setEditEpisodeProject: false,
      setCopyModal: false,
      setEditProjectModal: false,
      episodeList:[],
      episodeMode: null,
      selectedEpisodeId: null,
      episodeItem:{},
      isProjectDetailsLoader: false,
      totalEpisodes: null,
      isSearchFlag: false,
      searchItem: null
    };
  }

  componentDidMount(){
    if(this.props?.history.location.state){
      let data = this.props?.history.location.list
      this.setState({isNavigation: true, episodeItem: data, selectedEpisodeId:data?.episode_id , season_id: data.season_id,project_id: data.project_id})
    }
    if(this.props?.history.location.navigationFromSearchTalent){
      let item = this.props?.history.location.item
      this.setState({isNavigation: true, isSearchFlag: true ,searchItem: item,episodeItem: item, selectedEpisodeId:item?.episode_id , season_id: item.season_id,project_id: item.project_id})
    
    }
    this.getProjectEpisodesList(this.props?.match?.params?.prid)
    this.getProjectDetails(this.props?.match?.params?.prid)
  }

  handleshowCopyModal = () => {
    this.setState({ setCopyModal: true });
  };
  hideCopyModal = () => {
    this.setState({ setCopyModal: false });
  };
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded });
  };
  episodeCallBack=()=>{
    this.setState({isNavigation: false})
  }
  navigationCallback = (item, node) => {
    if (node === "no") {
      this.setState({ setEditEpisodeProject: true });
    }else if(node === "episode_name"){
      this.setState({isNavigation: true, setEditEpisodeProject: false, selectedEpisodeId:item?.episode_id, episodeItem: item})
    }
  };

  handleshowEditModal = (mode) => {
    this.setState({ setEditEpisodeProject: true, episodeMode: mode });
  };

  hideEditEpisodeModal = () => {
    this.setState({ setEditEpisodeProject: false });
  };

  handleEditProjectModal = () => {
    this.setState({ setEditProjectModal: true });
  };

  hideEditProjectModal = () => {
    this.setState({ setEditProjectModal: false });
  };

  handleInlineEdits = (mode,b,dataItem) => {
    this.setState({ setEditEpisodeProject: true, episodeMode: mode, selectedEpisodeId: dataItem?.episode_id });
  }

  getProjectEpisodesList = (projectId) => {
    this.setState({isProjectEpisodeList: true})
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpProjectEpisodeList?projectId=${projectId}`,
        this.props?.userContext?.user_profile?.tenant_id,1
    )
      .then((res) => {
        if (res.data.length > 0) {
        let filteredList = res.data[0]?.episode_list
        this.setState({
          episodeList: filteredList,
          totalEpisodes: filteredList.length,
          setEditEpisodeProject: false,
          isProjectEpisodeList: false
        });
      }else{
        this.setState({
          episodeList: [],
          setEditEpisodeProject: false,
          isProjectEpisodeList: false
        });
      }
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  getProjectDetails = (projectId) => {
    this.setState({isProjectDetailsLoader: true})
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpProject?projectId=${projectId}`,
        this.props?.userContext?.user_profile?.tenant_id,1
    )
      .then((res) => {
        let filteredList = res.data[0]
        this.setState({
          projectDeails: filteredList,
          setEditEpisodeProject: false,
          isProjectDetailsLoader: false,
          setEditProjectModal: false,
          setCopyModal: false
        });
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  projectListCallBack=()=>{
    this.props.history.push(`/caster-tv-jwp`)
  }

  render() {
    return (
      <div className="Project_Container">
        {this.state.isProjectDetailsLoader ? <div style={{ textAlign: "center" }}>
          <CircularProgress size={34} />
        </div> :
          <>
            <MDBRow>
              <MDBCol md={8}>
                <MDBIcon fas icon="arrow-left" className="arrow-color" onClick={this.projectListCallBack} />
                <div>
                  <span className="header-font">
                    {`${this.state.projectDeails?.project_name}${(this.state.isNavigation && this.state.episodeItem?.episode_no) ? `-Ep.${this.state.episodeItem?.episode_no}` : this.state.projectDeails.season_no ? `(season-${this.state.projectDeails.season_no})`: " "}`}
                    {this.state.projectDeails?.project_title}
                  </span>
                </div>
                <div className="display-sub">
                  <span>
                    {this.state.projectDeails?.season_year} {"                        "}
                  </span>
                  <span>
                    {this.state.projectDeails?.gl_code}
                  </span>
                </div>
              </MDBCol>
              <MDBCol md={3} className="btn-display mleft">
                <div className="mleft">
                  {this.props?.userContext.user_profile.user_role_name === "Show User" ?
                    <BasicButton
                      variant="outlined"
                      type="inline"
                      icon={"copy"}
                      text="copy"
                      onClick={this.handleshowCopyModal}
                      disabled={true}
                    /> :
                    <BasicButton
                      variant="outlined"
                      type="inline"
                      icon={"copy"}
                      text="copy"
                      onClick={this.handleshowCopyModal}
                    />
                  }
                </div>
                <div className="mleft2">
                  {this.props?.userContext.user_profile.user_role_name === "Show User" ?
                    <BasicButton
                      variant="contained"
                      type="inline"
                      icon={"pen"}
                      text="Edit Project"
                      disabled={true}
                      onClick={this.handleEditProjectModal}
                    /> : <BasicButton
                      variant="contained"
                      type="inline"
                      icon={"pen"}
                      text="Edit Project"
                      onClick={this.handleEditProjectModal}
                    />}
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="lable-color">
              <div className="mt accordion-display">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="project-header"
                  >
                    <Typography className="project-header">Project Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <MDBRow> */}
                    <MDBCol md={6}>
                      <MDBRow>
                        <MDBCol md={4}>
                          <div>
                            <BasicLabel text={"Project Types"} />
                            <span className="pr-font">
                              {this.state.projectDeails?.project_type_name}
                            </span>
                          </div>
                          <div>
                            <BasicLabel text={"Unions"} />
                            <span className="pr-font">
                              {this.state.projectDeails?.union_name}
                            </span>
                          </div>
                        </MDBCol>
                        <MDBCol md={4}>
                          <div>
                            <BasicLabel text={"Production Entity"} />
                            <span className="pr-font">
                              {this.state.projectDeails?.production_entity_name}
                            </span>
                          </div>
                          <div>
                            {/* <BasicLabel text={"Signatory"} />
                          <span className="pr-font">
                            {this.state.projectDeails?.signator}
                          </span> */}
                        </div>
                      </MDBCol>
                      <MDBCol md={4}>
                        <div>
                          <BasicLabel text={"Outlet"} />
                          <span className="pr-font">
                            {this.state.projectDeails?.outlet_name}
                          </span>
                        </div>
                        <div>
                          <BasicLabel text={"Intended Market"} />
                          <span className="pr-font">
                            {this.state.projectDeails?.intended_market_name}
                          </span>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={6}>
                    <BasicLabel text={"Project Notes"} />
                    <span className="pr-font">
                      {this.state.projectDeails?.project_notes}
                    </span>
                  </MDBCol>
                {/* </MDBRow> */}
              </AccordionDetails>
            </Accordion>
          </div>
        </MDBRow>
        {this.state.isNavigation ? 
        <>
        <div>
          <MDBIcon fas icon="arrow-left" className="arrow-color" onClick={this.episodeCallBack}/>
          <span className="ep-font">     Episodes/Ep -{this.state.episodeItem?.episode_no}-{this.state.episodeItem?.gl_code}-{this.state.episodeItem?.episode_name}</span>
        </div>
        <DealMemo selectedEpisodeId={this.state.selectedEpisodeId}  season_id={this.state.projectDeails?.season_id} project_id={this.state.projectDeails?.project_id} union_id={this.state.projectDeails?.union_id} totalEpisodes={this.state.totalEpisodes} isSearchFlag={this.state.isSearchFlag} searchItem={this.state.searchItem}/> 
        </>
        :
        <>
        <MDBRow className="mt">
          <MDBCol md={10}>
            {/* <BasicLabel text={"Episodes"} /> */}
            <span className="episode-font">Episodes</span>
          </MDBCol>
          <MDBCol md={2} className="episode-btn">
            <BasicButton
              variant="contained"
              type="inline"
              icon={"plus-circle"}
              text="New Episode"
              onClick={()=>this.handleshowEditModal("New")}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow id={"pr-table"} className="mt">
        {this.state.isProjectEpisodeList ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={34} />
                </div>
              ) : 
          <TableComponent
            list={this.state.episodeList || []}
            config={projectConfig}
            hyperLinkNavigationCallback={(item, node) =>
              this.navigationCallback(item, node)
            }
            editCallback={this.handleInlineEdits.bind(this, "Edit")}
          />
        }
        </MDBRow>
        </>
        }
        </>
      }
        {this.state.setEditEpisodeProject && (
          <MainViewModalComponent
            open={this.state.setEditEpisodeProject}
            handleClose={this.hideEditEpisodeModal}
            headerText={"Episode"}
            mode={this.state.episodeMode}
            modalName={"Episode"}
            navigationCallback = {this.navigationCallback.bind(this)}
            selectedEpisodeId= {this.state.selectedEpisodeId}
            getProjectEpisodesList={this.getProjectEpisodesList.bind(this)}
            match={this.props?.match}
            season_id={this.state.projectDeails?.season_id}
            episodeList={this.state.episodeList}
          />
        )}
        {this.state.setCopyModal && (
          <MainViewModalComponent
            open={this.state.setCopyModal}
            handleClose={this.hideCopyModal}
            headerText={"to new Project"}
            mode={"Copy"}
            modalName={"New Project"}
            id={this.props?.match?.params?.prid}
            getProjectDetails={this.getProjectDetails.bind(this)}
            season_id={this.state.projectDeails?.season_id}
            fromProject={true}
          />
        )}
        {this.state.setEditProjectModal && (
          <MainViewModalComponent
            open={this.state.setEditProjectModal}
            handleClose={this.hideEditProjectModal}
            headerText={"Project"}
            mode={"Edit"}
            modalName={"New Project"}
            id={this.props?.match?.params?.prid}
            getProjectDetails={this.getProjectDetails.bind(this)}
            fromProject={true}
          />
        )}
      </div>
    );
  }
}
export default withRouter(withUserContext(Project));