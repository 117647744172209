import React from 'react';
import {MDBRow, MDBCol } from 'mdbreact';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import { initialStatusDatesDetails } from './config';;
import CircularProgress from "@material-ui/core/CircularProgress";
import './StatusDates.scss';
import SelectField from '../SharedComponents/SelectField/SelectField';

export default class StatusDatesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusDatesDetails: {...initialStatusDatesDetails}, 
            postInitiated: false,
            isPosting: false,
            statusOptions:[],
        }

    }

    handleChange = (field, value) => {
        this.setState(prevState => ({
            statusDatesDetails: {
                ...prevState.statusDatesDetails,
                [field]: value
            },
            postInitiated: false,
        }))

        this.props.fieldChanged(true, "change");
    }


    postStatusDatesDetails = () => {
        this.setState({ postInitiated: true})
        let statusDatesDetails = {...this.state.statusDatesDetails}
        if (statusDatesDetails?.from_date && statusDatesDetails?.to_date ? new Date(statusDatesDetails?.from_date) < new Date(statusDatesDetails?.to_date) : true) {
                statusDatesDetails.is_delete = '0'
                this.setState({postInitiated:false,isPosting:true })
                CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProjectStatusDates', statusDatesDetails )
                    .then((response) => {
                        if (response.data.error) {
                            this.props?.notificationComponent(true, 'fail')
                        } else {
                            if (this.props?.toggleRefreshPage) {
                                this.props.toggleRefreshPage()
                            }
                            this.props?.notificationComponent(true, 'success')
                        }
                        this.setState({ isPosting: false });
                    },
                        (err) => {
                            this.setState({ isPosting: false })
                            this.props?.notificationComponent(true, 'fail')
                        });
           } 
    }


    getProjectStatus = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=STATUS_DATES_STATUS`)
          .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value }));
            this.setState({ statusOptions: formattedList });
          },
            (err) => {
              console.log("Error in fetching Text:", err)
            })
      }

    componentDidMount() {
        this.getProjectStatus()
        if (this.props.mode === "new") {
            let statusDatesData = {...this.state.statusDatesDetails}
            statusDatesData.project_id = this.props.projectId
            this.setState({ statusDatesDetails: statusDatesData })
        } else {
            let statusDatesData = {...this.props?.statusDate}
            let fromDate = this.props?.statusDate?.from_date?.split('/')
            let toDate = this.props?.statusDate?.to_date?.split('/')
            statusDatesData.from_date = fromDate ? `${fromDate[2]}-${fromDate[0]}-${fromDate[1]}` : null
            statusDatesData.to_date = toDate ? `${toDate[2]}-${toDate[0]}-${toDate[1]}` : null
            this.setState({ statusDatesDetails: statusDatesData})
        }
    }


    render() {
        return (
            <div className='casterFeatureStatusDateModal'>
                <MDBRow>
                    <MDBCol md={4}>
                        <DateField
                            id="statusDatefromDate"
                            label={"From"}
                            // isMandatory={true}
                            // showMandatory={this.state.postInitiated || false}
                            value={this.state.statusDatesDetails?.from_date || ""}
                            onChange={(e) => this.handleChange('from_date', e.target.value ? e.target.value : null)}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <DateField
                            id="statusDateToEnd"
                            label={"To"}
                            //isMandatory={true}
                            showErrorBorder={this.state.statusDatesDetails?.to_date && this.state.statusDatesDetails?.from_date ? 
                             new Date(this.state.statusDatesDetails?.from_date) >= new Date(this.state.statusDatesDetails?.to_date) ? true : false : false}
                            onChange={(e) => this.handleChange('to_date', e.target.value ? e.target.value : null)}
                            //showMandatory={this.state.postInitiated || false}
                            value={this.state.statusDatesDetails?.to_date || ""}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mt-2'>
                    <MDBCol md={8}>
                            <SelectField
                            id={'statusdatestatus'}
                            label={'Status'}
                            //isMandatory={true}
                            value={this.state.statusOptions?.find(item => item?.label === this.state.statusDatesDetails?.status)?.value  || null}
                            placeHolderText={"-Select-"}
                            options={this.state.statusOptions|| []}
                            //showMandatory={this.state.postInitiated || false}
                            onChange={(e) => this.handleChange('status', e.currentTarget.outerText)}
                            />
                    </MDBCol>
                </MDBRow>
                <MDBRow className={'mt-2'} id='statusDateTextArea'>
                    <BasicTextArea
                        label={"Notes"}
                        id={"textarea-statusDateTextArea"}
                        rows={6}
                        value={this.state.statusDatesDetails?.notes || ""}
                        onChange={(e) => this.handleChange('notes', e.target.value.length > 0 ? e.target.value : null)}
                    />
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={8} >
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            type="inline"
                            icon='save'
                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props.mode === "new" ? "Create" : "Save"}
                            onClick={this.postStatusDatesDetails.bind(this)}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            icon='times'
                            onClick={this.props.onClose}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}