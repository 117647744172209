import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { MDBIcon } from 'mdbreact';
import BasicLabel from '../BasicLabel/BasicLabel';
import './SelectField.scss';

export default class SelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            mandatoryWarning: "Please complete this mandatory field",
            selectUnion: "Please select Union",
            placeHolderText: props.placeHolderText ? props.placeHolderText : 'Select'
        }
    }
    onChange = (e) => {
        if (this.props?.id !== null && this.props?.id !== undefined) {
            let el = document.getElementById(this.props?.id);
            if(e?.target?.value!==""){
                el?.classList?.remove("emptyValue");
            }else{
                el?.classList?.add("emptyValue");
            }
            this.props?.onChange(e);
        } else {
            this.props?.onChange(e);
        }

    }
    componentDidMount() {
        if (this.props?.id !== null && this.props?.id !== undefined) {
            let el = document.getElementById(this.props?.id)?.nextSibling;
            let el2 = document.getElementById(this.props?.id);
            if (el?.value === '') {
                el2?.classList?.add("emptyValue");
            } else {
                el2?.classList?.remove("emptyValue");
            }
        }
    }

    render() {
        let selectOptions = this.props?.options || [];
        let error = (this.props.showMandatory && !this.props?.value) || this.props.fieldValid || false;
        let showAsLabel = this.props.showAsLabel || false;
        let stateLabel = this.props.stateLabel || false;
        const Placeholder = () => {
            return <div>{this.state.placeHolderText}</div>;
        };
        return (
            <div className={`SelectFieldContainer ${this.props?.className} ${showAsLabel ? 'showAsLabelContainer' : ""} ${error ? "error" : ""}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ?
                    (stateLabel ? <div className="showAsLabel">{this.props.value || ""} </div> :
                        <div className="showAsLabel">{selectOptions?.find(option => option.value === this.props?.value)?.label || ""} </div>) :
                    <div className='MuiFormControl-root'>
                        <Select
                            id={this.props?.id}
                            value={this.props?.value || ""}
                            variant="outlined"
                            displayEmpty
                            clearButton={true}
                            required={this.props?.isMandatory || false}
                            className={this.props?.className || ""}
                            disabled={this.props?.disabled || false}
                            onChange={this.onChange}
                            onOpen={() => this.setState({ isOpen: true })}
                            onClose={() => this.setState({ isOpen: false })}
                            IconComponent={() => this.state.isOpen ? (
                                <MDBIcon icon="chevron-down" />
                            ) : (<MDBIcon icon="chevron-right" />)}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                }
                            }}
                        >
                            {/* <MenuItem disabled value="">{this.props?.defaultMenuText || "- Select -"}</MenuItem> */}
                            {/* disabled={!this.props?.disabledPlaceHolder ? false : true} */}
                            <MenuItem {...((this.props?.disabledPlaceHolder || this.props?.disabledPlaceHolder == undefined) && { disabled: true })}

                                className='disabled' value="">{this.props?.defaultMenuText || this.state.placeHolderText}</MenuItem>
                            {
                                selectOptions?.map((item, index) => {
                                    return <MenuItem key={index} disabled={item.is_active === 0 ? true : false} value={item.value}>
                                        <span className={item?.showRed ? 'redColorOption' : null}>{item.label}</span>
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </div>
                }
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                    (this.props.fieldValid ? this.props.inValidInput : (this.props.disableSignatory ? this.state.selectUnion : ""))}</div>}
            </div>
        );
    }
}
