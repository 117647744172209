import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import SelectField from '../SharedComponents/SelectField/SelectField';
import { MDBCol, MDBRow } from 'mdbreact';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';


class VoiceOverPayMemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="VoiceOverPayMemoContainer">
             <MDBRow>
                <MDBCol md={7}>
                <SearchSelectField
                    id="project_id"
                    options={this.props?.projectList}
                    searchSelect={true}
                    multiple={false}
                    label={"Select Project"}
                    placeholder={"Select Project"}
                    detail_selected={this.props?.selectedprojectValue}
                    valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                    searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Select Voice Over Performer"}
                        value={this.state.reportDetails?.performer_deal_id}
                        options={this.props.voiceOverPerformerList}
                        onChange={(e) => this.props?.handleOnChange("performer_deal_id", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(VoiceOverPayMemo));