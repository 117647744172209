import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import './Reports.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CasterService from '../../../../services/service';
import axios from 'axios';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';

class ProductionSeasonEpisode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productionDetails: [],
            seasonDetails: [],
            episodeDetails: [],
            setProd: false,
            setEpisode: false,
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            searchCancelToken:null
        }
    }

    getProductionNames = (param) => {
        this.setState({ loading: true });
        //var cancelToken = axios.CancelToken.source();
        if (this.state.searchCancelToken != null) {
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");}
        let cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        CasterService.getDataWithCancelWithTenant(Constants.CasterServiceBaseUrl + '/production' + '?searchString=' + param, cancelToken, this.props.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.production_id, label: item.name }));
                this.setState({
                    productionDetails: formattedList,loading: false,
                });
            },
                (err) => {
                    console.log("Error in fetching Role Details:", err)
                })
    }
    handleSearchSelect = (val) => {
        this.props?.onChangeCallBack('selectProduction', val);
        this.props?.onChangeCallBack('selectSeason', null);
        this.setState({ selectProduction: val, selectSeason: null, setProd: true }, () => {
            if (this.props?.report === "WorkingHistory" || this.props?.report === "DealMemo" || this.props?.report === "TalentVoucher") {
                this.props?.getTalentOptions();
            }
        });
        CasterService.getData(Constants.CasterServiceBaseUrl + '/productionSeasons?productionId=' + val.value, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.production_season_id, label: `${item.season_start_year} - ${item.season_end_year}` }));
                this.setState({ seasonDetails: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    handleSeasonChange = (val) => {
        this.props?.onChangeCallBack('selectSeason', val);
        this.props?.onChangeCallBack('selectEpisode', null);
        this.props?.onChangeCallBack('setEpisode', true);
        this.setState({ selectSeason: val, selectEpisode: null, setEpisode: true, postInitiated: false }, () => {
            if (this.props?.report === "WorkingHistory" || this.props?.report === "DealMemo" || this.props?.report === "TalentVoucher") {
                this.props?.getTalentOptions();
            }
        })
        CasterService.getData(Constants.CasterServiceBaseUrl + '/productionSeasonEpisodeDetails?psId=' + val?.value, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data[0]?.episode_list?.map(item => ({ value: item.episode_id, label: `${item.episode_name ? item.episode_name + "_" : ""}${item.gl_no ? item.gl_no : ""}`, name: item.episode_name }));
                this.setState({ episodeDetails: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    handleEpisodeChange = (val) => {
        this.props?.onChangeCallBack('selectEpisode', val);
        this.setState({ selectEpisode: val }, () => {
            if (this.props?.report === "WorkingHistory" || this.props?.report === "DealMemo" || this.props?.report === "TalentVoucher") {
                this.props?.getTalentOptions();
            }
        })
    }

    render() {
        return (
            <div className="ProductionSeasonEpisode">
                <MDBRow>
                    <BasicLabel text={"Show/Season/Episode"} isMandatory={true} />
                </MDBRow>
                <MDBRow className='searchSelectBorder'>
                    <Autocomplete
                        isMandatory={this.props?.productionReq || false}
                        showMandatory={this.props?.productionReq && this.props?.postInitiated || false}
                        options={this.state.productionDetails ? this.state.productionDetails : []}
                        getOptionLabel={(option) => (option.label)}
                        value={this.state.selectProduction ? this.state.selectProduction : null}
                        onChange={(e, v) => this.handleSearchSelect(v)}
                        style={{ width: '100%' }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder="-ShowName-"
                                margin="normal"
                                onChange={ev => {
                                    // dont fire API if the user delete or not entered anything
                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                        this.getProductionNames(ev.target.value)
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                    {this.props?.productionReq && this.props?.postInitiated && !this.state.selectProduction && <span className="errorText">Please complete mandatory field</span>}
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <SelectField
                            className={"SelectSeason"}
                            id={"key17"}
                            value={this.state.selectSeason?.value}
                            isMandatory={this.props?.seasonReq || false}
                            showMandatory={this.props?.seasonReq && this.props?.postInitiated || false}
                            options={this.state.seasonDetails}
                            disabled={this.state.setProd === false ? true : false}
                            defaultMenuText={"-Season-"}
                            onChange={(e) => this.handleSeasonChange({ value: e.target.value, label: e.currentTarget.innerText })}
                        />
                    </MDBCol>
                    {!this.props?.episodeHide && <MDBCol>
                        <SelectField
                            className={"SelectEpisode"}
                            id={"key18"}
                            value={this.state.selectEpisode?.value}
                            isMandatory={this.props?.episodeReq || false}
                            showMandatory={this.props?.episodeReq && this.props?.postInitiated || false}
                            options={this.state.episodeDetails?.sort((a,b) => a.label.localeCompare(b.label))}
                            disabled={this.state.setEpisode === false ? true : false}
                            defaultMenuText={"-Episode-"}
                            onChange={(e) => this.handleEpisodeChange({ value: e.target.value, label: e.currentTarget.innerText })}
                        />
                    </MDBCol>}
                </MDBRow>
            </div>
        );
    }
}

export default withUserContext(ProductionSeasonEpisode);