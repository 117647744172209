export const crewNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Project History",
        index: 1
    }
]
export const initialPersonalDetails = {
    company: "WM",
    location: "New york",
    occupation:"Stunt Coordinator",
    title: "Lead Stunt Coordination",
    dob:"02/05/21970",
    socialTypeDetails: [{
        social_type_id: null,
        social_type: null,
        handle: null,
        is_fav:null
    },,{
        social_type_id: 1,
        social_type: "Instagram",
        handle: "test1",
        is_fav:0
    },
    {social_type_id: 2,
        social_type: "twitter",
        handle: "test2",
        is_fav:1
        }],
    crewDetails: [{
        crew_name: null,
        crew_id: null,
        notes:null,
    },{
        crew_name: "David smith Jr",
        crew_id: 1,
        notes:"test",
    }, {
        crew_name: "Mary Johnson",
        crew_id: 2,
        notes:"test 2"
        }],
    aliases: [{ is_selected: 1, person: "Tony Crewman Jr." }, { is_selected: 0, person: "Tony Speranda" }, { is_selected: 0, person: "Tony Joseph" }]
    
}
export const socialList={
    "crew_social_media_id": null,
    "social_media_type":null,
    "social_media_account": null,
    "is_primary": 0,
    "is_delete": 0
}
export const asistantList={
    "crew_assistant_id": null,
    "crew_member_id": null,
    "notes": null,
    "assistant_name": null,
    "is_delete": 0
}
export const projectHistoryConfig = {
    headings:[
        {
            headingLabel: "Project",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Location",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Occupation",
            border: true,
            width: "30%"
        },
        // {
        //     headingLabel: " ",
        //     border: true,
        //     width: "10%"
        // }
    ],
    dataNodes: ["project_name", "location_name", "occupation"], //"project_crew_id"
    // actions:["times"],
    hyperlinks: ["project_name"],
    // nodeFields: [
    //     {
    //         column: "crew_record",
    //         icon: "level-up-alt"
    //     },]
}

export const projectHistoryList = [
    {
        project: "THE CONJURING",
        location: "New York",
        occupation: "Stunt Coordinator",
        crew_record:"Crew Record"
    },
    {
        project: "THE HURT LOCKER",
        location: "New Zealand Prod",
        occupation: "Stunt Coordinator Lead",
        crew_record:"Crew Record"
    },
    {
        project: "[PROJECT TITLE",
        location: "None",
        occupation: "[Occupation]",
        crew_record:"Crew Record"
    }
]
export const phoneObjData={
    "phone_id": null,
    "phone_type": null,
    "phone": null,
    "ext": null,
    "is_primary": 0,
    "is_delete": 0
}
export const emailObjData={
    "email_id": null,
    "email_type": null,
    "email":null,
    "is_primary": 0,
    "is_delete": 0
}
export const emailConfig = {
    "emailTypeId": "email_id",
    "emailType": "email_type",
    "email": "email",
    "isPrimary": "is_primary",
    "isDelete":"is_delete"
}
export const crewPersonalLimit = {
    "firstName": 50,
    "lastName": 50,
    "mi": 50,
    "prefix": 10,
    "suffix": 10,
    "aka": 50,
    "email": 50,
}
export const phoneConfig = {
    "phoneTypeId": "phone_id",
    "phoneType": "phone_type",
    "phone": "phone",
    "isPrimary": "is_primary",
    "isDelete": "is_delete",
    "ext":"ext",
}
export const initialObjectConfig={    
    object_id: null,
    object_type: null,
    value: null,
    is_primary: 0,
    is_delete: 0,
    ext:null
}
export const initialPhoneObjectConfig={    
    project_crew_phone_id: null,
    object_id: null,
    phone_type: null,
    object_type:null,
    phone: null,
    value: null,
    is_primary: 0,
    is_delete: 0,
    ext:null
}
export const initialEmailObjectConfig={    
    project_crew_email_id: null,
    object_id: null,
    email_type: null,
    object_type:null,
    email: null,
    value: null,
    is_primary: 0,
    is_delete: 0,
    ext:null
}
export const initialCrewAddress = {
    "crew_address_id": null,
    "address_type": null,
    "address":null,
    "city": null,
    "state": null,
    "country": null,
    "zip": null,
    "is_primary": 0,
    "is_international": 0,
    "is_delete":0
}
export const initialAliasConfig = {
    "crew_aliases_id": null,
    "aka": null,
    "aka_first_name": null,
    "aka_last_name": null,
    "aka_middle_name": null,
    "aka_suffix": null,
    "is_primary": 0,
    "is_delete": 0
}

export const crewProjectHistoryFilterConfig = {
    "project_name": null,
    "location_name": null,
    "occupation": null
}