import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from 'mdbreact';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import TableComponent from '../SharedComponents/Table';
import { agencyTalentsList, agencyTalentsConfig } from './Config';
import './Agent.scss';
import TablePagination from '@material-ui/core/TablePagination';

class AgencyRepresentatives extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agency_talent_list !== state.agency_talent_list) {
            return {
                agency_talent_list: props.agency_talent_list
            }
        }
        if (props.page !== state.page) {
            return {
                page: props.page
            }
        }
        if (props.rowsPerPage !== state.rowsPerPage) {
            return {
                rowsPerPage: props.rowsPerPage
            }
        }
        if (props.agencyTalentsConfig != state.agencyTalentsConfig) {
            return {
                agencyTalentsConfig: props?.agencyTalentsConfig
            }
        }
    }

    navigationCallback =(obj, node) => {
        if(node.includes("talent_name")) {
            this.props.navFuns.handleHideAgencyPopup();
            this.props.navFuns.handleShowTalentPopup(obj.talent_id, obj.talent_name);
        } else if(node.includes("representative_name")) {
            this.props.navFuns.handleHideAgencyPopup();
            this.props.navFuns.handleShowRepresentativePopup(obj.representative_id, obj.representative_name);
        }
    }

    render(){
        return(
            <div className="agencyTalents">
                <MDBRow>
                    <MDBCol md={3}>
                        <BasicTextField
                            id="talent_name"
                            value={this.props?.talentsFilterList?.talent_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("talent_name", e.target.value)
                                this.props?.addFilterChangeHandler("Covered Talent", e.target.value)
                            }}
                        />
                    </MDBCol>  
                    <MDBCol md={3}>
                        <BasicTextField
                            id="rep_name"
                            value={this.props?.talentsFilterList?.rep_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("rep_name", e.target.value)
                                this.props?.addFilterChangeHandler("Rep. Name", e.target.value)
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <SelectField
                            id="type"
                            value={this.props?.talentsFilterList?.type}
                            options={this.props?.typeOptions}
                            onChange={(e) => {
                                this.props?.onChangeHandler("type", e.target.value)
                                this.props?.addFilterChangeHandler("Type", e.target.value)
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow id="agencyTalentsTable">
                    <TableComponent
                        config={this.props?.agencyTalentsConfig}
                        list={this.props?.agency_talent_list}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        sortCallback={this.props?.handleTableColumnClick}
                    />
                    <TablePagination
                        component="div"
                        count={this.props?.backup_agency_talent_list ? this.props?.backup_agency_talent_list?.length : 0}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        page={this.props?.page}
                        onChangePage={this.props?.handleChangePage}
                        rowsPerPage={this.props?.rowsPerPage}
                        onChangeRowsPerPage={this.props?.handleChangeRowsPerPage}
                    />
                </MDBRow>
            </div>
        );
    }

}

export default withUserContext(AgencyRepresentatives);