import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import { CircularProgress } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { validatePlusCharacter, validateIntlNumber, validateUSNumber, validatePhoneNumber, validateEmail } from '../../Common/Helper';
import './User.scss';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import TableComponent from "../../components/SharedComponents/Table";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import MessageModal from '../../components/SharedComponents/MessageModal';
import { statusOptions, userMockData, projectConfig, accessLevelOptions } from './UserConfig';
import axios from 'axios';

class NewProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: this.formatUserList(JSON.parse(JSON.stringify({ ...userMockData }))),
            rowsPerPage: 10,
            page: 0,
            searchCancelToken: null,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            projectIndex: 2,
            phoneValid: true
        };
    }

    componentDidMount() {
        this.gerUserRoles();
        this.getAccessLevels();
        if (this.props?.mode === 'Edit') {
            this.getUserDetails()
        }
    }

    formatUserList = (response) => {
        if (response?.user_projects_list?.length === 0) {
            response?.user_projects_list.push(JSON.parse(JSON.stringify({ ...projectConfig })));
        }
        return response;
    }

    getUserDetails = () => {
        this.setState({ isFetchingUserDetails: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpUser?userId=${this.props?.userId}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                console.log("check the user get", response)
                let userDetails = response?.data[0];
                this.setState({ userDetails: userDetails, isFetchingUserDetails: false })
            },
                (err) => {
                    console.log(err, "error in option")
                    this.setState({ isFetchingUserDetails: false })
                })
    }

    gerUserRoles = () => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpLookupDetails?objectType=user_role&searchString=''`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label: item?.user_role_name,
                    value: item.user_role_id,
                    is_active: item.is_active
                }));
                this.setState({ roleOptions: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    handleOnchange = (field, value) => {
        if (field === 'phone') {
            this.setState({
                phoneValid:
                    value.length > 0 ?
                        (validatePlusCharacter((value.trim()).charAt(0)) ?
                            validateIntlNumber(value) : validateUSNumber(value) || validatePhoneNumber(value)) : false,
            });
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value || null,
                },
            }));
        } else if (field === 'login_email' || field === 'notification_email') {
            if (value) {
                if (field === 'login_email') {
                    this.setState({ fieldValid: validateEmail(value) });
                } else {
                    this.setState({ notiEmailFieldValid: validateEmail(value) });
                }
            } else {
                this.setState({ fieldValid: true, notiEmailFieldValid: true });
            }
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        } else {
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        }
    };

    getProjects = (value, listName) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        CasterService.getDataWithCancel(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpEntitySearch?entity=project&searchString=${value}`,
            cancelToken, this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    text: `${item.name}`,
                    value: item.id,
                    season_id: item.season_id
                }));
                this.setState({ projectList: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    getAccessLevels = () => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpLookupDetails?objectType=lu_access_level`,
            this.props?.userContext?.user_profile?.tenant_id, 1
        )
            .then((res) => {
                let formattedList = res.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                let config = this.state.config;
                // config.headings[1].selectOptions = formattedList
                this.setState({
                    accessLevelOptions: formattedList,
                    // config: config
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    }

    handleListEdit = (field, value, listName) => {
        this.setState({ projectSelected: value })
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        this.setState(prevState => ({
            userDetails: {
                ...prevState.userDetails,
                [field]: newValue || null
            },
            postInitiated: false
        }))
    }

    handleProjectAccess = (value, index) => {
        let userDetails = { ...this.state.userDetails };
        userDetails.user_projects_list[index].access_level_id = value;
        this.setState({ userDetails: userDetails })
    }

    addCallback = (listName) => {
        this.setState({ postInitiated: false });
        let userDetails = JSON.parse(JSON.stringify({ ...this.state.userDetails }));
        let initialProjectArray = JSON.parse(JSON.stringify({ ...projectConfig }));
        let user_projects_list = [...userDetails.user_projects_list];
        initialProjectArray.project_name = this.state.projectSelected?.text;
        initialProjectArray.project_id = this.state.projectSelected?.value || null;
        initialProjectArray.index = this.state.projectIndex;
        initialProjectArray.canRemove = true;
        initialProjectArray.access_level_id = this.state.accessLevelSelected || null;
        user_projects_list.push(initialProjectArray)
        userDetails.user_projects_list = user_projects_list;
        this.setState({ projectIndex: this.state.projectIndex + 1, userDetails: userDetails }, () => {
            console.log("check the user details", this.state.userDetails)
            this.setState({ projectSelected: null, accessLevelSelected: null })
        });
    }

    removeCallback = (listName, callbackItem, index) => {
        this.setState({
            callbackItem: callbackItem,
            postInitiated: false,
            index: index
        }, () => {
            if (callbackItem.user_project_id) {
                this.setState({ isOpen: true })
            } else {
                this.deleteRow(listName, callbackItem, 'new');
            }
        })
    }

    deleteRow = (listName, callbackItem, type, index = null) => {
        let userDetails = this.state.userDetails;
        userDetails[listName][this.state.index]['is_delete'] = 1;
        if (type === 'edit') {
            let temp = [];
            this.state.inActiveProjects?.map((item) => {
                temp.push(item);
            });
            let user_projects_list = userDetails[listName].filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(user_projects_list[0]);
            this.setState({ inActiveProjects: temp })
        }
        let user_projects_list = userDetails[listName].filter(item =>
            (item.user_project_id !== callbackItem.user_project_id) || (item.index !== callbackItem.index)
        );
        if (user_projects_list.length === 0) {
            userDetails[listName] = [{ ...projectConfig }];
        } else {
            userDetails[listName] = user_projects_list;
        }
        this.setState({
            userDetails: userDetails,
            listName: null,
            callbackItem: null,
            postInitiated: false,
            isOpen: false,
        });
    }

    getFilteredJson = (userDetailsJson) => {
        let projectTemp = [];
        this.state.inActiveProjects?.map(item => {
            projectTemp?.push(item);
        });
        userDetailsJson.user_projects_list?.map(item => {
            projectTemp?.push(item);
        });
        userDetailsJson.user_projects_list = projectTemp;
        userDetailsJson.user_projects_list = userDetailsJson?.user_projects_list?.filter(item => item?.project_id !== null)
        return userDetailsJson;
    }

    canSubmit = (userDetails) => {
        let phoneValid = userDetails?.phone ?
            (validatePlusCharacter((userDetails?.phone.trim()).charAt(0)) ?
                validateIntlNumber(userDetails?.phone) && userDetails?.phone.length <= sizeLimits.phoneLimitIntl :
                (validateUSNumber(userDetails?.phone) || validatePhoneNumber(userDetails?.phone)) && userDetails?.phone.length <= sizeLimits.phoneLimitUS) : true;
        let canSubmit = userDetails.is_active &&
            userDetails.full_name &&
            userDetails.role_id &&
            userDetails.login_email && validateEmail(userDetails.login_email) &&
            (userDetails?.notification_email ? validateEmail(userDetails.notification_email) : true) &&
            (userDetails?.phone ? phoneValid : true);
        if (userDetails?.phone) {
            this.setState({ phoneValid: phoneValid })
        }
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let userDetails = { ...this.state.userDetails }
        let postJson = this.getFilteredJson(userDetails);
        postJson.is_active = postJson?.is_active == 2 ? 0 : postJson?.is_active;
        postJson["projrct_id"] = (postJson.role_id === 1 || postJson.role_id === 4) ? 1 : (postJson.role_id === 2 || postJson.role_id === 3) ? 0 : null
        console.log("check the payload on submit", postJson);
        if (this.canSubmit(postJson)) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterTvJwpUser', postJson,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.fieldChanged(true, "fail");
                    } else {
                        this.setState({ postInitiated: false, isPosting: false });
                        this.props.fieldChanged(true, "success");
                        this.props?.onClose();
                        this.props?.toggleRefresh();
                    }
                },
                    (err) => {
                        console.log("Error in posting submission:", err)
                        this.setState({ postInitiated: false, isPosting: false });
                        this.props.fieldChanged(true, "fail");
                    })
        } else {
            console.log("Submission Failed")
        }
    }

    render() {
        return (
            <MDBContainer fluid className="userContainer">
                <MessageModal
                    open={this.state.isOpen}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteRow('user_projects_list', this.state.callbackItem, 'edit')}
                    handleClose={(e) => this.setState({ isOpen: false })}
                />
                {this.state.isFetchingUserDetails ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <>
                        <MDBRow>
                            <p className="user-note">
                                Note: User must be seperately assigned OKTA Verify credentials to access the system.
                    </p>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBRow className="detail-heading">
                                    <BasicLabel text="Details" />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id={"status"}
                                            label={"Status"}
                                            defaultMenuText={"-Select-"}
                                            isMandatory={true}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            showMandatory={this.state.postInitiated}
                                            options={statusOptions || []}
                                            value={this.state.userDetails?.is_active}
                                            onChange={(e, value) => this.handleOnchange('is_active', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="name"
                                            label={"Full User Name"}
                                            value={this.state.userDetails.full_name || null}
                                            isMandatory={true}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            showMandatory={this.state.postInitiated}
                                            onChange={(e, value) => this.handleOnchange('full_name', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="title"
                                            label={"Title"}
                                            value={this.state.userDetails.title || null}
                                            onChange={(e, value) => this.handleOnchange('title', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <SelectField
                                            id={"role"}
                                            label={"Role"}
                                            defaultMenuText={"-Select-"}
                                            options={this.state.roleOptions || []}
                                            value={this.state.userDetails.role_id || null}
                                            isMandatory={true}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            showMandatory={this.state.postInitiated}
                                            onChange={(e, value) => this.handleOnchange('role_id', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="login-email"
                                            label={"Login Email"}
                                            value={this.state.userDetails.login_email || null}
                                            isMandatory={true}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            showMandatory={this.state.postInitiated}
                                            fieldValid={this.state.fieldValid === false ? true : false}
                                            inValidInput={messages.invalidInput}
                                            onChange={(e, value) => this.handleOnchange('login_email', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="notification-email"
                                            label={"Notification Email"}
                                            value={this.state.userDetails.notification_email || null}
                                            fieldValid={this.state.notiEmailFieldValid === false ? true : false}
                                            inValidInput={messages.invalidInput}
                                            onChange={(e, value) => this.handleOnchange('notification_email', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextField
                                            label="Phone"
                                            placeholder="(___)-___-____"
                                            value={this.state.userDetails.phone || null}
                                            fieldValid={this.state.phoneValid === false ? true : false}
                                            inValidInput={messages.invalidInput}
                                            // limit={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS) : null}
                                            // limitWarning={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs) : null}
                                            onChange={(e, value) => this.handleOnchange('phone', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            {(this.state.userDetails.role_id === 2 || this.state.userDetails.role_id === 3) &&
                            <MDBCol>
                                <MDBRow className="project-access-heading">
                                    <BasicLabel text="Project Access" />
                                </MDBRow>
                                <MDBRow className="project-access-heading">
                                    <div className="project-access-list">
                                        {this.state.userDetails?.user_projects_list?.map((project, index) => (
                                            <MDBRow>
                                                <MDBCol className="project-name pr-0">{project?.project_name}</MDBCol>
                                                {project?.project_name &&
                                                    <MDBCol className="p-0">
                                                        <SelectField
                                                            id={"access-level"}
                                                            defaultMenuText={"-Select-"}
                                                            options={this.state.accessLevelOptions || []}
                                                            value={project?.access_level_id}
                                                            onChange={(e, value) => this.handleProjectAccess(e.target.value, index)}
                                                        />
                                                    </MDBCol>
                                                }
                                                <MDBCol md={1} className="removeActionIcon">
                                                    {
                                                        (project.project_name?.length > 0) ?
                                                            <MDBIcon
                                                                icon="times"
                                                                className="float-right"
                                                                onClick={() => this.removeCallback('user_projects_list', project, index)}
                                                            />
                                                            : null
                                                    }
                                                </MDBCol>
                                            </MDBRow>
                                        ))}
                                    </div>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol className="p-0">
                                        <SearchSelectField
                                            id={"projectAccess"}
                                            placeholder={'- Search Projects to Add -'}
                                            options={this.state?.projectList || []}
                                            detail_selected={this.state.projectSelected?.value && this.state.projectSelected?.text ? {
                                                value: this.state.projectSelected?.value,
                                                text: this.state.projectSelected?.text
                                            } : null}
                                            valueSelected={(e, selected) => this.handleListEdit("project_id", selected, "user_projects_list")}
                                            searchText={(e) => this.getProjects(e.target.value, 'user_projects_list')}
                                            multiple={false}
                                            searchSelect={true}
                                        />
                                    </MDBCol>
                                    <MDBCol className="p-0 access-dropdown">
                                        <SelectField
                                            id={"access-level"}
                                            defaultMenuText={"-Select-"}
                                            options={this.state.accessLevelOptions || []}
                                            value={this.state.accessLevelSelected}
                                            onChange={(e, value) => this.setState({ accessLevelSelected: e.target.value })}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2} className="add-project-icon">
                                        <MDBRow>
                                            <MDBCol md={1}>
                                                <AddCircleIcon
                                                    onClick={(e) =>
                                                        this.addCallback("user_projects_list")
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={2} className="save-btn">
                                <BasicButton
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                    onClick={() => this.handleSubmit()}
                                />
                            </MDBCol>
                            <MDBCol md={1} className="cancel-btn">
                                <BasicButton
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose} />
                            </MDBCol>
                        </MDBRow>
                    </>
                }

            </MDBContainer>
        )
    }
}
export default withUserContext(NewProject)