import React, { useState, Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import './ProfilePictureUpload.scss';
import { withUserContext } from "../../../../contexts/UserContext"
import defaultImg from '../../../assets/defaultUserImg.png';
import { Popover } from '@material-ui/core';

class ProfilePictureUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navObj: {},
            invalidImageType: false,
            openPopOver: false,
            anchorEl: null
        }
        this.myRef = React.createRef();
    }
    static getDerivedStateFromProps(props, state) {
        if ((JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {          
            return {
                navObj: props.navObj || {}
            }
        }
        if (props.isEditing !== state.isEditing) {
            return {
                isEditing: props.isEditing
            }
        }
        return null;
    }
    componentDidMount() {
        console.log("Loaded Profile");
        if (this.props.mode !== "new" && this.props.imgMode === "download") {
            if (this.props?.navObj) {
                let { talent_id,representative_id,crew_id } = this.props?.navObj;
                let id = this.props?.headerText === "Talent" ? talent_id: this.props?.headerText === "Representatives" ? representative_id : this.props?.headerText === "Crew"? crew_id : null ;
                let objectType = this.props?.headerText;                    
                this.props.uploadProfile(id,objectType);
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.navObj !== this.state.navObj) {         
            console.log("ProfilePictureUpload Updated");
            if (this.props.mode !== "new" && this.props.imgMode === "download" && !this.props.isProfileChanged) {
                if (this.props?.navObj) {
                    let { talent_id,representative_id,crew_id } = this.props?.navObj;
                    let id = this.props?.headerText === "Talent" ? talent_id: this.props?.headerText === "Representatives" ? representative_id : this.props?.headerText === "Crew"? crew_id : null ;
                    let objectType = this.props?.headerText;                    
                    this.props.uploadProfile(id,objectType);
                }
            }
            
        }
        if (this.state.invalidImageType) {
            setTimeout(()=>{
                this.setState({ invalidImageType: false })
            },10000);
        }
    }

    imageHandler = e => {
        const reader = new FileReader();
        try {
            let file = e.target.files[0];
            if(file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/gif"){
                this.props?.handleProfileImg(null)
                this.props?.handleProfileChange(false);
                this.setState({ invalidImageType: true })
               
                return;
            }
            reader.onload = () => {
         
                if (reader.readyState === 2) {
                    // this.setState({ profileImg: reader.result, isProfileChanged: true });
                    this.props?.handleProfileImg(reader.result)
                    this.props?.handleProfileChange(true);
                    this.props?.handleImageType(file.type);
                    // this.props.onFileUpload(reader.result);
                   
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            this.props?.handleProfileImg(null)
            this.props?.handleProfileChange(false);
        }
      
    }  
    
    onProfileChangeClick = (event, node) => {
        if(this.props?.profileImg === null || node === "replace") {
            if(!this.props?.isUpload){
                return;
            }
            this.myRef.current.click();
            // this.setState({ imgMode: 'upload' })
            this.setState({ openPopOver: false })
            this.props?.handleImgMode('upload');
        }
        else if (node === "remove") {
            this.props?.removeProfileImg();
        }
        else {
            this.setState({ openPopOver: true, anchorEl: event.currentTarget })
        }
    }

    handleCloseModal = () => {
        this.setState({ openPopOver: false, anchorEl: null })
    }

    onError = (e) => {       
        // this.props?.handleProfileImg(null)       
        e.target.src= defaultImg;
    }  

    render() {
        return (
            <>
                    <MDBContainer fluid className="Profile-Picture-Container">
                        <MDBRow className="justify-content-md-center">
                            <MDBCol className={`profilePic ${!this.props?.profileImg ? 'defaultImg':''}`}>
                                <img 
                                src={this.props?.profileImg || defaultImg}
                                alt="profile image" 
                                id="img" className={`img`}
                                onError={this.onError}
                                onClick={(e) => this.props?.isEditing ? this.onProfileChangeClick(e) : null} />
                                {this.state.openPopOver && 
                                    <Popover
                                        open={this.state.openPopOver}
                                        anchorEl={this.state.anchorEl}
                                        anchorReference="anchorOrigin" 
                                        onClose={this.handleCloseModal}                                 
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}                                    v
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        PaperProps={{ style: { width: '6%', height: '60px', backgroundColor: 'white', border: '1px solid black', borderRadius: '0px' } }}
                                    >
                                        <div>
                                            <label className="popover-label" onClick={(e) => this.onProfileChangeClick(e,"replace")}>Replace</label>
                                            <label className="popover-label" onClick={(e) => this.onProfileChangeClick(e, "remove")}>Remove</label>
                                        </div>
                                    </Popover>}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <input
                                type="file"
                                ref={this.myRef}
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                onChange={this.imageHandler}
                            />
                        </MDBRow>
                        {this.state.invalidImageType &&
                            <div className="errorText textAlignCenter">
                                {"Please upload an image file"}
                            </div>
                        }
                    </MDBContainer>
               
            </>
        );
    }
}

export default withUserContext(ProfilePictureUpload);