export const performerConfig = {
    headings: [
        {
            headingLabel: "From-To",
            icon: "sort-alpha-up",
            width: "15%",
        },
        {
            headingLabel: "Location Name",
            icon: "",
            width: "15%",
            border: false
        },
        {
            headingLabel: "Email",
            icon: "",
            width: "20%",
            border: false
        },
        {
            headingLabel: "Phone",
            icon: "",
            width: "15%",
            border: false
        },
        {
            headingLabel: "Address",
            icon: "",
            width: "23%",
            border: false
        },
        {
            headingLabel: "Comments",
            icon: "",
            width: "12%",
            border: false
        },
        // {
        //     headingLabel: "",
        //     icon: "", 
        //     width: "15%",
        //     // inputType: "text",
        //     //  border: true
        // }
    ],
    dataNodes: ["from-to", "location_name", "email", "phone", "address"],
    // borders: ["performer_name", "role_no", "performer_notes"],
    primaryKey: "project_location_id",
    // actions:["file"],
    actions: ["file", "pen", "times"],
    isLocation: true
    // buttonFields: [
    //     {
    //         column: "comments",
    //         icon: "file",
    //         hideBtnText: true
    //     }
    // ],
    // hyperlinks: ['performer_name'],
    // dataNodeGroups: {"performer_name": ["aka"]},
    // filters: true,
    // isSearchBar: true
};

export const newLocationDetails =
{
	"project_location_id": null,
	"is_delete": 0,
	"project_id": null,
	"location_name": "",
	"from_date": null,
	"to_date": null,
	"email": "",
	"phone": null,
	"address": "",
	"city": "",
	"state": "",
	"postal_code": "",
	"country": "",
	"comments": ""
}