import * as React from 'react';

export const ReportContext = React.createContext({
  
 });

 export const withReportContext = Component => {
    class ReportContextComponent extends React.Component {
      render() {
        return (
          <ReportContext.Consumer>
            {(value) => <Component reportContext = {value} {...this.state} {...this.props}/>}
          </ReportContext.Consumer>
        );
      }
    }
  
    return ReportContextComponent;
  };
