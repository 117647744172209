export const companyInitialDetails = {
  company_id: null,
  company_name: null,
  company_aka: null,
  company_type: null,
  address_type: null,
  address_1: null,
  address_2: null,
  city: null,
  state: null,
  country: null,
  zip: null,
  email: null,
  phone: null,
};

export const companyLimit = {
  companyName: 150,
  company_aka: 150,
  lastName: 150,
  email: 200,
  phone: 13,
  aka: 200,
  ssn: 9,
  guardian_name: 200,
  loanout_company: 150,
  loanout_no: 10,
  address_1: 200,
  address_2: 200,
  city: 200,
  zip: 10,
};

export const repTalentConfig = {
  headings: [
    {
      headingLabel: "Representative",
      width: "40%",
    },
    {
      headingLabel: "Rep. Type",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Datetime added",
      icon: "",
      width: "25%",
    },
    {
      headingLabel: "Covered Talent",
      icon: "",
      width: "25%",
    }
  ],
  dataNodes: ["representative_name","representative_type","datetime_added"],
  hyperlinks: ["representative_name"],
  primaryKey: "representative_id",
  actions: ["arrows-alt-v","times"],
};

export const renderTalentRepList = [
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
  {
    representative: "project",
    rep_type: "rep_type",
    covered_talent: "covered_talent",
    datetime_added: "2000-2001",
  },
];

export const companyTalentConfig = {
  headings: [
    {
      headingLabel: "Talent",
      width: "40%",
    },
    {
      headingLabel: "Representative Name",
      icon: "",
      width: "40%",
    },
    {
      headingLabel: "Rep. Type",
      icon: "",
      width: "20%",
    },
  ],
  dataNodes: ["talent_name", "representative_name", "representative_type"],
  hyperlinks: ["talent_name", "representative_name"],
  primaryKey: "representative_id",
  // actions: ["times"],
};

export const companyTalentRepList = [
  {
    talent: "project",
    representative_name: "rep_type",
    rep_type: "covered_talent",
  },
  {
    talent: "project",
    representative_name: "rep_type",
    rep_type: "covered_talent",
  },
  {
    talent: "project",
    representative_name: "rep_type",
    rep_type: "covered_talent",
  },
  {
    talent: "project",
    representative_name: "rep_type",
    rep_type: "covered_talent",
  },
  {
    talent: "project",
    representative_name: "rep_type",
    rep_type: "covered_talent",
  },
];
