import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TablePagination from "@material-ui/core/TablePagination";
import Chip from '@material-ui/core/Chip'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../components/SharedComponents/Table';
import { searchTalentConfig, talentFilterConfig } from './SearchTalentConfig';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import {downloadUrl,extractFilenameFromUrl} from "../../Common/Helper";
import axios from 'axios';
import './SearchTalent.scss';
import MessageModal from '../../components/SharedComponents/MessageModal';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

class SearchTalent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            config: searchTalentConfig,
            rowsPerPage: 10,
            page: 0,
            searchCancelToken: null,
            filters: null,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            contractTypePopup: false,
            isProjectLoading: false
        }
    }

    componentDidMount() {
        this.getTalentDetails();
        // this.getProjectDetails("");
    }

    getTalentDetails = () => {
        this.setState({ isFetchingTalentDetails: true })
        const talentInput = {
            order_by_clause: this.state.sortBy,
            order_by: (this.state.isSortByAsc === true ? 'DESC' : 'ASC'),
            search_text: this.props.searchValue,
            page_count: this.state.rowsPerPage,
            page_no: this.state.page + 1,
            first_name: this.state.filters?.first_name || null,
            last_name: this.state.filters?.last_name || null,
            project_id: this.state.filters?.project_id || null,
            episode_id: this.state.filters?.episode_id || null,
        };
        const base64String = btoa(JSON.stringify(talentInput));
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpTalentList?input=${base64String}`,
            this.props?.userContext?.active_tenant?.tenant_id,
            true
        )
            .then((res) => {
                console.log("check the api data", res)
                let talentList = res?.data?.records;
                talentList?.map(item => {
                    item['talent_name'] = item?.talent_first_name + ' ' + item?.talent_last_name;
                    return item;
                })
                let sortedTalentDetails = [...talentList]?.sort((a, b) => b.talent_name - a.talent_name);
                this.setState({
                    list: handlePaginationList(0, this.state.rowsPerPage, sortedTalentDetails),
                    talentCount: res?.data?.query_record_count,
                    isFetchingTalentDetails: false,
                })
            })
            .catch((error) => {
                this.setState({ isFetchingTalentDetails: false });
                console.log(error, "error in Landingpagetable get method");
            });
    }

    getProjectDetails = (event) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken,isProjectLoading:true });
        if (event.target.value !== "") {
            CasterService.getDataWithCancel(
                Constants.CasterServiceBaseUrl +
                `/casterTvJwpEntitySearch?entity=${"project"}&searchString=${event.target.value}`,
                cancelToken, this.props.userContext?.active_tenant?.tenant_id
            )
                .then(response => {
                    let formattedList = response?.data?.map(item => ({
                        text:  `${item.name}${item.season_no ? `(${item.season_no})` : " "}${item.season_year ? `(${item.season_year})` : " "}`,
                        value: item.id,
                        season_id: item.season_id
                    }));
                    this.setState({ projectOptions: formattedList ,isProjectLoading: false});
                },
                    (err) => {
                        console.log(err, "error in option")
                    })
        }else{
            this.setState({ projectOptions: [] });
        }
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getTalentDetails();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getTalentDetails();
                });
                break;
        }
    }

    getProjectEpisodes = (projectId, seasonId) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpEpisode?projectId=${projectId}&seasonId=${seasonId}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label: item?.episode_name,
                    value: item.episode_id
                }));
                this.setState({ episodeOptions: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    handleFilterOnChange = (field, value) => {
        if (field === 'projectId') {
            let project = this.state.projectOptions?.find(item => item.value === value);
            this.getProjectEpisodes(value, project.season_id)

            this.setState({ projectIdFilter: value,page:0,rowsPerPage:10 })

        } else {
            this.setState({ episodeIdFilter: value,page:0,rowsPerPage:10 })
        }

        this.setState({ [field]: value,page:0,rowsPerPage:10  }, () => {

            this.addFilterChangeHandler(field, value)
        })
    }

    addFilterChangeHandler = (field, value) => {
        let filters = { ...this.state.filters }
        filters["first_name"] = this.state.firstNameFilter
        filters["last_name"] = this.state.lastNameFilter
        filters["project_id"] = this.state.projectIdFilter
        filters["episode_id"] = this.state.episodeIdFilter

        this.setState({ filters: filters,page:0 ,rowsPerPage:10 }, () => {
            this.setState({ [field]: null ,page:0,rowsPerPage:10  })

            this.getTalentDetails()
        })
    }

    handleFilterLabel = (filterLabel) => {
        if (filterLabel === 'first_name' || filterLabel === 'last_name') {
            var i, frags = filterLabel.split('_');
            for (i = 0; i < frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ') + ': ' + this.state.filters[filterLabel];
        } else if (filterLabel === 'project_id') {
            let project = this.state.projectOptions?.find(item => item.value === this.state.filters[filterLabel])
            return 'Project:' + ' ' + project?.text;
        } else if (filterLabel === 'episode_id') {
            let episode = this.state.episodeOptions?.find(item => item.value === this.state.filters[filterLabel])
            return 'Episode:' + ' ' + episode?.label
        }
    }

    handleFilterDelete = (filterLabel) => {
        let filters = { ...this.state.filters }
        filters[filterLabel] = null;
        this.setState({
            filters: filters,
            page:0,
            rowsPerPage:10 

        }, () => {
            let previousFilters = filterLabel?.replace(/_./g, (m) => m[1].toUpperCase())
            this.setState({ [previousFilters + 'Filter']: null ,selectedShow: null})
            this.getTalentDetails()
        })
    }

    navigationCallback = (item, node) => {
        if (node == 'talent_name') {
            // this.props.history.push(`/caster-tv-jwp/project-view/prid/${item.project_id}`)
            this.setState({isEpisodeFlage: true},()=>{
                this.props.history.push({    // no need
                  pathname: `/caster-tv-jwp/project-view/prid/${item.project_id}`,
                  navigationFromSearchTalent: this.state.isEpisodeFlage,item
                });
            })
        }
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(JSON.parse(JSON.stringify({ ...searchTalentConfig })), this.state.isSortByAsc, this.state.sortBy, colName),
        }, () => {
            let list =tableSortList("Name", this.getSortNode(colName), this.state.list, this.state.isSortByAsc)
            this.setState({
                sortBy: colName,
                list: list,
                page:0
            },()=>{
                this.getTalentDetails()
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Talent": return "talent_name";
            case "Role": return "role_name";
            case "Project": return "project_name";
            case "Episode": return "episode_name";
            case "Role Type": return "role_type_name";
            case "To Be Billed As": return "to_be_filled_as";
        }
    }

    handleTableActions = (actionLabel, dataItem) => {
        let selectedDataItem = {
            primaryKeyId: dataItem?.deal_memo_id,
            actionLabel: actionLabel
        }
        this.setState({ selectedDataItem: selectedDataItem, isGeneratingPdf: true })
        let payload = {
            deal_memo_id: dataItem?.deal_memo_id
        };
        if (actionLabel === "Deal Memo") {
            payload.role_type_name = dataItem?.role_type_name
        }
        let encryptTedStr = btoa(JSON.stringify(payload));
        let dealMemoLoad = {
            payload: "casterTvJwpGenerateReport",
            reportName: "rptDealMemo",
            fileName: "DealMemo",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: this.props?.userContext?.active_tenant?.tenant_id,
        };
        let contactLoad = {
            payload: "casterTvJwpGenerateReport",
            reportName: "rptContract",
            // loanout: 0,
            fileName: "contactPdf",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: this.props?.userContext?.active_tenant?.tenant_id,
        };
        if (dataItem.contract_type_name && dataItem.contract_type_name.includes("Pending")||dataItem.role_type_name.includes("Regular") && actionLabel !== "Deal Memo") {
            this.setState({ contractTypePopup: true, isGeneratingPdf: false })
        } else {
            CasterService.getDataWS(actionLabel === "Deal Memo" ? dealMemoLoad : contactLoad).then(
                (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                        this.setState({ isExistingDealLoader: false, isGeneratingPdf: false })
                        const filename = extractFilenameFromUrl(response?.body);
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                        if(actionLabel !== "DealMemo" && dataItem.contract_type_name !== "Regular" && dataItem.loanout !== null){
                            let selectedDataItem = {
                                primaryKeyId: dataItem?.deal_memo_id,
                                actionLabel: actionLabel
                            }
                            this.setState({ selectedDataItem: selectedDataItem, isGeneratingPdf: true })
                            let payload = {
                                deal_memo_id: dataItem?.deal_memo_id,
                                loanout: 1
                            };
                            if (actionLabel === "Deal Memo") {
                                payload.role_type_name = dataItem?.role_type_name
                            }
                            let encryptTedStr = btoa(JSON.stringify(payload));
                            let contactLoad = {
                                payload: "casterTvJwpGenerateReport",
                                reportName: "rptContract",
                                loanout: 1,
                                fileName: "contactPdf",
                                searchJson: encryptTedStr,
                                showHtml: 0,
                                encryptionType: 1,
                                tenantId: this.props?.userContext?.active_tenant?.tenant_id,
                            };
                            if (dataItem.contract_type_name && dataItem.contract_type_name.includes("Pending")||dataItem.role_type_name.includes("Regular") && actionLabel !== "Deal Memo") {
                                this.setState({ contractTypePopup: true, isGeneratingPdf: false })
                            } else {
                                CasterService.getDataWS(actionLabel === "Deal Memo" ? dealMemoLoad : contactLoad).then(
                                    (webSocketConn) =>
                                    (webSocketConn.onmessage = (e) => {
                                        let response = JSON.parse(e.data);
                                        if (response?.statusCode == 200) {
                                            this.setState({ isExistingDealLoader: false, isGeneratingPdf: false })
                                            const filename = extractFilenameFromUrl(response?.body);
                                            let decodedFile = decodeURIComponent(filename)
                                            downloadUrl(response?.body, decodedFile);
                                        }

                                    }))}
                                   
                              
                            
                        }
                        else{
                            return("pdf ")
                        }
                    }
                })
            );
        }
    }

    handleSelectedShow = (newValue) => {
        this.setState({ selectedShow: newValue });
        if (newValue) {
            let project = this.state.projectOptions?.find(item => item.value === newValue.value);
        this.getProjectEpisodes(newValue.value, project.season_id)

        this.setState({ projectIdFilter: newValue.value , ["projectId"]: newValue.value,page:0,rowsPerPage:10 }, () => {

            this.addFilterChangeHandler("projectId", newValue.value)
        })
        //   let selectedShow = this.state.list?.find(item => item?.deal_memo_id === newValue?.value);
        //   this.setState({
        //     renderList: selectedShow ? [selectedShow] : [],
        //     page: 0,
        //   });
        } else {
          this.getProjectEpisodes(this.props?.project_id)
        }
    };

    filtersFun=()=>{
        let data = {...this.state.filters}
            data["episode_id"] = null,
            data["project_id"] = null,
            data["first_name"] = null,
            data["last_name"] = null
            this.setState({filters: data,firstNameFilter: null,lastNameFilter: null,episodeIdFilter: null,projectIdFilter:null,page:0,rowsPerPage:10},()=>{this.getTalentDetails()})
    }


    render() {
        return (
            <MDBContainer fluid className="SearchTalentContainer">
                <MDBRow>
                    <MDBCol xs={6} md={6} sm={4}>
                        <span className="searchTalentHeading">Search Talent</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol xs={6} md={6} sm={4}>
                        <span className="searchTalentSubHeading">
                            Search for any Talent record in the system. To add a filter, type in a field below and click ENTER
                        </span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={2}>
                        <BasicTextField
                            id="first_name"
                            label={"First Name"}
                            value={this.state.firstName || ""}
                            onKeyUp={(event) => {
                                if (event.keyCode === 13) {
                                    this.addFilterChangeHandler('firstName', event.target.value);
                                }

                            }}
                            onChange={(e) => this.setState({
                                firstName: e.target.value,
                                firstNameFilter: e.target.value
                            })}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            id="last_name"
                            label={"Last Name"}
                            value={this.state.lastName || ""}
                            onKeyUp={(event) => {
                                if (event.keyCode === 13) {
                                    this.addFilterChangeHandler('lastName', event.target.value);
                                }

                            }}
                            onChange={(e) => this.setState({
                                lastName: e.target.value,
                                lastNameFilter: e.target.value
                            })}
                        />
                    </MDBCol>
                    <MDBCol md={2} className='project_field'>
                        {/* <SelectField
                            id={"project"}
                            label={"Project"}
                            defaultMenuText={"-Select-"}
                            options={this.state.projectOptions || []}
                            value={this.state.projectId || ""}
                            onChange={(e, value) => this.handleFilterOnChange('projectId', e.target.value, value)}
                        /> */}
                        <SearchSelectField 
                            loading={this.state.isProjectLoading}
                            id={"project"}
                            label={"Project"}
                            placeholder={"Search For a Project"}
                            options={this.state.projectOptions || []}
                            width={'100%'}
                            detail_selected={this.state.selectedShow}
                            valueSelected={(e, value) => this.handleSelectedShow(value)}
                            searchText={this.getProjectDetails}
                            multiple={false}
                            searchSelect={true}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="p-0 episode-filter">
                        <SelectField
                            id={"episode"}
                            label={"Episode"}
                            defaultMenuText={"-Select-"}
                            options={this.state.episodeOptions || []}
                            value={this.state.episodeId || ""}
                            disabled={!this.state.projectIdFilter || false}
                            onChange={(e, value) => this.handleFilterOnChange('episodeId', e.target.value)}
                        />
                    </MDBCol>
                    {this.state.filters !== null && (this.state.filters.episode_id  || this.state.filters.first_name || this.state.filters.last_name || this.state.filters.project_id) ?
                        <MDBCol className="clear-search-btn">
                            <BasicButton
                                variant="contained"
                                text="Clear Search"
                                type="inline"
                                onClick={() => {
                                    this.setState({
                                        // filters: null,
                                        talentCount: 0,
                                        projectIdFilter: null,
                                        selectedShow: null
                                    }, () => {
                                        this.filtersFun();
                                    })
                                }}
                            />
                        </MDBCol>: " "
                    }
                </MDBRow>
                <MDBRow className="talentFilters">
                    {(!this.state.filters) ?
                        <MDBCol xs={6} md={6} sm={4}>
                            <span className="noFilter">
                                No filters added.
                        </span>
                        </MDBCol> :
                        <MDBCol>
                            {this.state.filters?.first_name &&
                                <Chip
                                    label={this.handleFilterLabel('first_name')}
                                    onDelete={() => this.handleFilterDelete('first_name')}
                                />
                            }
                            {this.state.filters?.last_name &&
                                <Chip
                                    label={this.handleFilterLabel('last_name')}
                                    onDelete={() => this.handleFilterDelete('last_name')}
                                />
                            }
                            {this.state.filters?.project_id &&
                                <Chip
                                    label={this.handleFilterLabel('project_id')}
                                    onDelete={() => this.handleFilterDelete('project_id')}
                                />
                            }
                            {this.state.filters?.episode_id &&
                                <Chip
                                    label={this.handleFilterLabel('episode_id')}
                                    onDelete={() => this.handleFilterDelete('episode_id')}
                                />
                            }
                        </MDBCol>
                    }

                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <TableComponent
                            list={this.state.list || []}
                            config={this.state.config}
                            isLoading={this.state.isFetchingTalentDetails}
                            sortCallback={this.handleTableColumnClick}
                            hyperLinkNavigationCallback={(item, node) => this.navigationCallback(item, node)}
                            handleTableActions={this.handleTableActions}
                            selectedDataItem={this.state.selectedDataItem}
                            isGeneratingPdf={this.state.isGeneratingPdf}
                        />
                        <TablePagination
                            component="div"
                            count={this.state.talentCount}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            page={this.state.page}
                            onChangePage={(e, page) => {
                                this.setJson('page', page)
                            }}
                            rowsPerPage={this.state.rowsPerPage}
                            onRowsPerPageChange={(e) => {
                                this.setJson('pagePerRow', e.target.value)
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                {this.state.contractTypePopup &&
                    <MessageModal
                        title={"Contract Type PDF Download Failed"}
                        open={this.state.contractTypePopup}
                        message={
                            "Contract Type is an pending state not able to generate the PDF"
                        }
                        primaryButtonText={""}
                        secondaryButtonText={"Cancel"}
                        handleClose={(e) => this.setState({ contractTypePopup: false })}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(SearchTalent);