import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import DateField from '../../../components/SharedComponents/DateField/DateField';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import { validateCharctersSpaceOnly } from '../../Helper';
import { s2ab, genReportName } from '../../Helper';

class PostSyncTimeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            dateValue: "",
            recordingLocation: "",
        }
    }

    handleSubmitPostSyncTimeLog = (docFlag = 0, excelFlag = 0) => {
        this.setState({ 
            postInitiated: true 
        });
        let { recordingLocation: location, dateValue: work_date, selectProduction } = this.state;
        let production_id = selectProduction?.value;

        if (!production_id) {
            return;
        }
        let payload = {
            production_id,
            location,
            work_date
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal,docFlag, excelFlag)
        this.setState({postInitiated: false})
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow>
                        <DateField
                            id="WorkDate"
                            label={"Work Date"}
                            value={this.state.dateValue || ""}
                            onChange={(e) => this.onChangeCallBack('dateValue', e.target.value ? e.target.value : null)}
                        />
                    </MDBRow>
                    <MDBRow>
                        <BasicTextField
                            id="RecordingLocation"
                            label={"Recording Location"}
                            value={this.state.recordingLocation || ""}
                            onChange={(e) => validateCharctersSpaceOnly(e.target.value) ? this.onChangeCallBack('recordingLocation', e.target.value.length > 0 ? e.target.value : null) : null}
                        />
                    </MDBRow>
                    <ReportButton
                        generateDoc={true}
                        generateExcel={true}
                        onClickGenratePdf={this.handleSubmitPostSyncTimeLog}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default PostSyncTimeLog;