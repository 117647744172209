import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../../contexts/UserContext";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import { CircularProgress } from "@material-ui/core";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import { validateCharNumWithHyphenSlash } from "../../../Common/Helper";
import messages from "../../../Common/Messages.json";
import sizeLimits from "../../../Common/SizeLimits.json";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { initialSeasonDetails } from '../Config';
import './Season.scss';

class EditSeason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonDetails: initialSeasonDetails,
            isPosting: false,
        };
    }

    async componentDidMount() {
        if (this.props?.showSeasonId || this.props?.mode === 'Edit') {
            await this.getSeasonDetails();
        } else {
            this.getLookupData('SEASONS', '');
        }
    }

    getSeasonDetails = () => {
        this.setState({ isFetchingSeasonDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetShowSeason?showSeasonId=${this.props?.showSeasonId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({
                    seasonDetails: response.data[0],
                    isFetchingSeasonDetails: false
                }, () => {
                    this.getLookupData('SEASONS', '');
                })
            },
                (err) => {
                    console.log('Error is fetching Show' + err);
                    this.setState({ isFetchingSeasonDetails: false })
                });
    }

    getLookupData = (entity, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                // this.setState({ [optionName]:  })
                console.log("check the season response", response);
                let formattedStartYearList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
                let formattedEndYearList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value2, is_active: item?.is_active }));
                this.setState({
                    startYearOptions: formattedStartYearList,
                    endYearOptions: formattedEndYearList
                }, () => {
                    if (this.props?.mode === 'Edit') {
                        let seasonDetails = { ...this.state.seasonDetails };
                        seasonDetails.start_year = this.state.startYearOptions?.find(item =>
                            item.label === seasonDetails.start_year)?.value || null;
                        seasonDetails.end_year = this.state.endYearOptions?.find(item =>
                            item.label === seasonDetails.end_year)?.value || null;
                        this.setState({ seasonDetails: seasonDetails })
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleOnchange = (field, value) => {
        if(field === "is_pilot"){
            this.setState((prevState) => ({
                seasonDetails: {
                    ...prevState.seasonDetails,
                    [field]: value ? 1 : 0,
                },
            }));
        }else{
            this.setState((prevState) => ({
                seasonDetails: {
                    ...prevState.seasonDetails,
                    [field]: value,
                },
            }));
        }
    };

    canSubmit = () => {
        let seasonDetails = JSON.parse(JSON.stringify({ ...this.state?.seasonDetails }));
        let canSubmit = this.props?.mode === 'Edit' ? (seasonDetails?.season_name) :
            (seasonDetails?.season_name && seasonDetails?.start_year &&
                seasonDetails?.end_year);
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let payload = { ...this.state.seasonDetails };
        payload.show_id = this.props?.showId;
        payload.is_pilot = payload.is_pilot === true ? 1 : 0;
        payload['start_year'] = this.state.startYearOptions?.find(item => item.value === payload.start_year)?.label;
        payload['end_year'] = this.state.endYearOptions?.find(item => item.value === payload.end_year)?.label;
        if (this.canSubmit()) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostSeason`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.notificationComponent(true, "fail");
                    } else {
                        this.props.notificationComponent(true, "success", "show-refresh");
                        this.setState({
                            isPosting: false,
                            postInitiated: false
                        })
                    }
                },
                    (err) => {
                        console.log('Error is submitting Season' + err);
                        this.props.notificationComponent(true, "fail");
                        this.setState({ isPosting: false, postInitiated: false })
                    });
        } else {
            console.log("Error in submitting season")
        }
    };
    render() {
        return (
            <div>
                {this.state.isFetchingSeasonDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="seasonContainer">
                            <MDBRow>
                                <MDBCol md={10}>
                                    <BasicTextField
                                        className="text"
                                        label={"Season Name"}
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.seasonDetails.season_name}
                                        onChange={(e) =>
                                            this.handleOnchange("season_name", e.target.value)
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={2} className="pilot-check">
                                    <BasicCheckbox
                                        id={"is_pilot111"}
                                        label={"Pilot"}
                                        checked={this.state.seasonDetails?.is_pilot === 1 ? true : false}
                                        onChange={(e) =>
                                            this.handleOnchange("is_pilot", e.target.checked)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6} className="Dates">
                                    <BasicLabel isMandatory={this.props?.mode === 'Edit'?false:true} text={"Dates"} />
                                    <MDBRow className="g-0">
                                        <MDBCol md={6}>
                                            <SelectField
                                                className={"fromDate"}
                                                placeholder={"- Select -"}
                                                value={this.state.seasonDetails?.start_year || ""}
                                                options={this.state.startYearOptions}
                                                isMandatory={this.props?.mode === 'Edit'?false:true}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) => {
                                                    this.handleOnchange("start_year", e.target.value);
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md={6}>
                                            <SelectField
                                                className={"toDate"}
                                                placeholder={"- Select -"}
                                                value={this.state.seasonDetails?.end_year || ""}
                                                options={this.state.endYearOptions}
                                                isMandatory={this.props?.mode === 'Edit'?false:true}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) => {
                                                    this.handleOnchange("end_year", e.target.value);
                                                }}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md={6}></MDBCol>
                            </MDBRow>
                            {/* <MDBRow>
                                <MDBCol md={4} className="pr-0">
                                    <BasicTextField
                                        label="SAP/GL Code"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.seasonDetails?.sap_code || ""}
                                        limit={sizeLimits.nameCharacterLimit}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => {
                                            validateCharNumWithHyphenSlash(e.target.value) &&
                                                this.handleOnchange(
                                                    "sap_code",
                                                    e.target.value.length > 0 ? e.target.value : null
                                                );
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={4} className="pr-0">
                                    <BasicTextField
                                        label="MPM#"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.seasonDetails?.mpm || ""}
                                        limit={sizeLimits.nameCharacterLimit}
                                        limitWarning={messages.exceed50CharacterWarning || ""}
                                        onChange={(e) => {
                                            validateCharNumWithHyphenSlash(e.target.value) &&
                                                this.handleOnchange(
                                                    "mpm",
                                                    e.target.value.length > 0 ? e.target.value : null
                                                );
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                            <MDBRow>
                                <MDBCol md={12}>
                                    <BasicTextArea
                                        label={"Season Notes"}
                                        value={this.state.seasonDetails.season_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("season_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={7}></MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        text={
                                            this.state.isPosting ? (
                                                <CircularProgress color="inherit" size={18} />
                                            ) : (this.props?.mode === 'Edit' ? 'Save' : 'Finish')
                                        }
                                        icon={"Save"}
                                        disabled={!this.canSubmit()}
                                        onClick={() => this.handleSubmit()}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={() => this.props?.onClose()}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    )
                }
            </div>
        );
    }
}
export default withUserContext(EditSeason);
