import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../BasicTextField/BasicTextField';
import SelectField from '../SelectField/SelectField';
import BasicLabel from '../BasicLabel/BasicLabel';
import './InlineSelectField.scss';

export default class InlineSelectField extends React.Component {
    render() {
        let error = this.props?.fieldValue?.length > this.props.limit || (this.props.showMandatory && (this.props?.fieldValue === "" || this.props?.fieldValue === null || !this?.props?.selectValue)) ||
            this.props.fieldValid === "" || this.props.fieldValid === null || false;
        let mandatoryWarning = "Please complete this mandatory field";
        let showAsLabel = this.props.showAsLabel || false;
        return (
            <div className={`InlineSelectFieldContainer ${(this.props?.textFieldposition === "end" && this.props?.ext) ? "endPositionExt" : this.props?.textFieldposition === "end" ? "endPosition" : ''} ${this.props?.showAsLabel ? 'showAsLabel' : ''}`}>
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                <MDBRow className="g-0">
                    <MDBCol md={this.props?.selectFieldLength || 4} className="SelectField">
                        <SelectField id={this.props?.id + this.props?.id}
                        className={!this.props?.selectValue ? "emptyValue" :""}
                            value={this.props?.selectValue || ""}
                            placeHolderText={this.props?.placeHolderText}
                            options={this.props?.options || []}
                            showAsLabel={this.props?.showAsLabel}
                            disabled={this.props?.disabled || false}
                            onChange={this.props?.onSelectChange}
                            inValidInput={this.props.selectFieldinValidInput}
                            fieldValid={this.props.selectfieldValid === false ? true : false}
                            isMandatory={this.props?.isMandatory || false}
                            showMandatory={this.props?.showMandatory || false}
                            label={this.props?.showLabel ? "Type" : ""}
                        />
                    </MDBCol>
                    <MDBCol md={(this.props?.ext ? 6 : this.props?.textFieldLength) || 8} className={this.props?.ext ? "TextFieldForPhone" : "TextField"}>
                        <BasicTextField id={this.props?.id}
                            className="TextField1"
                            limit={this.props.limit}
                            fieldValid={this.props.numberfieldValid === false ? true : false}
                            limitWarning={this.props.limitWarning}
                            inValidInput={this.props.inValidInput}
                            value={this.props?.fieldValue}
                            isPrimary={this.props?.isPrimary || false}
                            onChange={this.props?.onFieldChange}
                            disabled={this.props?.disabled || false}
                            showAsLabel={this.props?.showAsLabel}
                            isMandatory={this.props?.isMandatory || false}
                            showMandatory={this.props?.showMandatory || false}
                            placeholder={this.props?.placeholder || ""}
                            label={this.props?.showLabel ? this.props?.labelNew : ""} 
                            isTalent={this.props?.isTalent} />
                    </MDBCol>
                    {this.props?.ext ? <MDBCol md={2} className="TextField2">
                        <BasicTextField id={this.props?.id}
                            className="TextField2"
                            limit={this.props?.limit}
                            // fieldValid={this.props.fieldValid === false ? true : false}
                            //limitWarning={this.props.limitWarning}
                            //inValidInput={this.props.inValidInput}
                            value={this.props?.extFieldValue}
                            onChange={this.props?.onExtFieldChange}
                            disabled={this.props?.disabled || false}
                            showAsLabel={this.props?.showAsLabel}
                            placeholder={this.props?.extPlaceHolder || ""}
                            label={this.props?.showLabel ? "Ext" : ""} />
                    </MDBCol> : null}


                    {/* {error && !showAsLabel && <MDBCol md={12}><div className="errorText">{((this.props.showMandatory && (!this.props?.fieldValue || !this.props?.selectValue)) ? mandatoryWarning :
                        this.props.fieldValid ? "" : this.props.limitWarning) || ""}</div></MDBCol>} */}
                </MDBRow>

            </div>
        );
    }
}

