import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";


class ExistingDealMemoDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isNavigation: false,
        };
    }
   
    render() {
        return (
            <div>
                <h1>ExistingDealMemoDetails</h1>
            </div>
        )
    }
}
export default withUserContext(ExistingDealMemoDetails);