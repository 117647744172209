export const animationShowConfig = {
    headings: [
        {
            headingLabel: " ",
            width: "5%"
        },
        {
            headingLabel: "Show Name",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Show Type",
            width: "20%"
        },
        {
            headingLabel: "Production Company",
            width: "25%"
        },
        {
            headingLabel: "Status",
            width: "15%"
        },
        {
            headingLabel: "",
            width: "10%"
        }
    ],
    dataNodes: [" ","show_name", "show_configuration", "production_company", "status","Open"],
    primaryKey: "show_id",
    nodeFields: [" "],
    buttonWithText:["Open"],
    changeColourToRed: ["Abandoned"],
    changeColourToOrange: ["Archived"],
    changeColourToBlue: ["Active"],
    changeColourToGreen:["Inactive"]
};
export const animationShowList = [{
    show_name: "Batman",
    show_type: "Episodic",
    prod_company: "[Production Company]",
    status:"Active"
},
{
    show_name: "Lord of the Rings.Ring of power",
    show_type: "Episodic",
    prod_company: "[Production Company]",
    status:"Archieved"
    },
    {
        show_name: "Shameless",
        show_type: "Episodic",
        prod_company: "[Production Company]",
        status:"Abandoned"
    }]