export const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: '85%',
        height: '80%',
        //overflowY: 'auto',
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundMDBColor: theme.palette.background.paper,
        border: '2px solid grey',
        borderRadius: '8px',
        boxShadow: theme.shadows[5]
    },
    label: {
        fontSize: '0.75rem'
    },
    Reports: {
        maxWidth: '800px !important'
    },
});

export const agencyNodes = {
    headerName: "agency_name",
    type: "agency_type"
    
};

export const representativeNodes = {
    headerName: "first_name",
    type: "type_name",
    lastName:"last_name"
};
export const crewNodes = {
    headerName: "first_name",
    type: "crew_type",
    headerDetail:"project",
    lastName:"last_name"
};

export const talentNodes = {
    headerName: "first_name",
    akas: "akas",
    lastName:"last_name"
};

export const productionCompanyNodes = {
    headerName: "name",
    akas: "aka",
    type: "type_name",
};