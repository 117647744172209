import React, { Component } from 'react'
import { Popover } from '@material-ui/core';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import './NewTalent.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import { TalentShortDetails } from "./Config"
import { talentFCNavList } from '../../components/Talent/Config'
import ModalComponent from '../DefaultModalLayout/Modal/Modal';
import { ssnFormat, validateNumHyphenOnly } from '../Helper';
import SizeLimits from '../../Common/SizeLimits.json';
import Messages from '../../Common/Messages.json';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

export default class NewTalent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPosting: false,
            showError: false,
            genderOptions: [],
            ethnicityOptions: [],
            talentDetails: { ...TalentShortDetails },
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            showTalentPopup: false,
            clickedState: '',
            sagErrorMessage: "Loanout exists with same SAG",
            ssnErrorMessage: "Performer exists with same SSN"
        }
    }


    componentDidMount() {
        if (this.props?.talentData?.value?.length > 0) {
            let splitValue = this.props?.talentData?.value?.split(" ");
            let obj = {
                first_name: splitValue?.[0] || null,
                middle_name: null,
                last_name: null
            }
            if (splitValue?.length == 2) {
                // last_name:splitValue?.slice?.(2,splitValue?.length)?.join(" ") || null
                obj.last_name = splitValue?.[1] || null

            }
            else if (splitValue?.length == 3) {
                obj.middle_name = splitValue?.[1] || null
                obj.last_name = splitValue?.[2] || null
            }
            else {
                obj.middle_name = splitValue?.[1] || null
                obj.last_name = splitValue?.slice?.(2, splitValue?.length)?.join(" ") || null
            }

            let talentDetails = { ...this.state?.talentDetails, ...obj }
            this.setState({ talentDetails: talentDetails });
        }
        this.getLookupValues('Gender');
        this.getLookupValues('Ethnicity');

    }
    handleHideTalentPopup() {
        this.setState({ showTalentPopup: false })
    }
    getLookupValues(type) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    if (type == 'Gender') {
                        let formattedList = response?.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value }))
                        this.setState({ genderOptions: formattedList })
                    } else if (type == 'Ethnicity') {
                        let formattedList = response?.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ ethnicityOptions: formattedList })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })

    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, saveFlag: false });
        // if (this.state.saveFlag) {
        //     this.setState({ saveFlag: false }, this.closPopup)
        // }
    }


    onChange(key, value) {
        if (key == "ethnicity_list") {
            let newValue = value.filter(
                (value, index, arr) =>
                    index === arr.findIndex((item) => item.value === value.value)
            );
            this.setState((prevState) => ({
                talentDetails: {
                    ...prevState?.talentDetails,
                    [key]: newValue
                }
            }))
        } else {
            this.setState((prevState) => ({
                talentDetails: {
                    ...prevState?.talentDetails,
                    [key]: value
                }
            }))
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }
    checkDuplicate = async (objectType, searchString, checkExistingKey, talent_id) => {
        await CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&check_existing_key=${checkExistingKey}&talent_id=${talent_id}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                console.log("response", response)
                if (response?.data) {
                    let ssnError = response?.data[0]?.entity_check_name === "SSN" ?
                        response?.data[0]?.entity_check_result == 1 ? true : false : false
                    let sagError = response?.data[1]?.entity_check_name === "SAG_ID" ?
                        response?.data[1]?.entity_check_result == 1 ? true : false : false
                    this.setState({ ssnError: ssnError, sagError: sagError })
                    // return true;
                    // (ssnError || sagError || loanoutError);
                }
            },
                (err) => {
                    console.log(err, "error in option")
                    // return false;
                })
    }
    validateBeforePost(postJson) {
        let isValid = false;
        isValid = 
        // postJson?.first_name &&
         postJson?.last_name &&
            postJson?.gender && postJson?.ethnicity_list?.length > 0 &&
            (postJson?.ssn ? (ssnFormat(postJson?.ssn) ? postJson?.ssn?.length <= SizeLimits.formattedSSN : postJson?.ssn?.length <= SizeLimits.ssnCharacterLimit) : true);
        return isValid;
    }
    postTalent = async (type) => {
        // casterFeatureTalentDetails
        let postJson = this.state.talentDetails
        this.setState({ isPosting: true, showError: false, clickedState: type })
        if (postJson?.ssn || postJson?.sag_id) {
            await this.checkDuplicate('CHECK_EXISTING', `${postJson.ssn ? postJson?.ssn : ""}${postJson?.sag_id ? ',' + postJson?.sag_id :  ',' + ""}`, "SSN,SAG_ID", null)
            if (this.state.ssnError || this.state.sagError) {
                this.setState({ isPosting: false, clickedState: null })
                return;
            }
        }
        if (!this.validateBeforePost(postJson)) {
            this.setState({ isPosting: false, showError: true, clickedState: null })
            return;
        }
        let aka = `${postJson?.first_name ? postJson?.first_name : ''} ${postJson?.middle_name ? postJson?.middle_name : ''} ${postJson?.last_name ? postJson?.last_name : ''} ${postJson?.suffix ? postJson?.suffix : ''}`
        // let akaValue = {
        //     aka,
        //     aka_first_name: postJson?.first_name,
        //     aka_middle_name: postJson?.middle_name,
        //     aka_last_name: postJson?.last_name,
        //     aka_suffix: postJson?.suffix,
        //     is_primary: 1,
        //     is_delete: 0

        // }
        // postJson.akas = []
        // postJson.akas.push(akaValue);
        postJson.ethnicity_list = postJson.ethnicity_list?.map(item => ({
            ethnicity: item?.text,
            talent_ethnicity_id: item?.id,
            is_primary: 0,
            is_delete: 0
        }))
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureTalentDetails', postJson, this.state?.selecttenant)
            .then((response) => {
                if (response?.data?.error) {
                    this.setState({ isPosting: false, showError: false, clickedState: null })
                    this.notificationComponent(true, "fail");
                } else {
                    postJson.talent_id = response?.data?.[0]?.talent_id;
                    postJson.name = aka?.trim()?.replace("  ", " ") || '';
                    this.setState({ isPosting: false, showError: false, talentDetails: postJson, clickedState: null })
                    this.notificationComponent(true, "success");
                    setTimeout(() => {
                        this.props?.handleClose();
                        if (type == 'save' && this.props?.module === "Performer") {
                            this.props?.newTalentPerformer({ value: postJson?.talent_id, text: postJson?.name, headerName: aka?.replace("  ", " ") })
                            // this.setState({ showTalentPerformerPopup: true, newTalentId: {value: postJson?.talent_id, text: postJson?.name} })
                        }
                        else if (type == 'saveEdit') {
                            this.props?.setObjectSelected('talent', { text: postJson?.name, value: postJson.talent_id, aka: postJson?.name, headerName: postJson?.name })
                            // this.setState({showTalentPopup:true})
                        }
                        else {
                            this.props?.setNewObjectSelected('talent', { text: postJson?.name, optionType: 'save' })
                        }
                    }, 1500)

                    // console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ isPosting: false, showError: false, clickedState: null })
                    this.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }
    onPopoverClose() {
        this.setState({ talentDetails: { ...TalentShortDetails } })
    }
    formatSSN() {
        let ssn = this.state?.talentDetails?.ssn;
        if (ssn && !ssnFormat(ssn) && ssn?.length == SizeLimits?.ssnCharacterLimit) {
            let result = ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, ssn.length);
            if (ssnFormat(result)) {
                this.onChange('ssn', result)
            }
        }
        return ssn;
    }
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />

                <Popover
                    open={this.props.open}
                    anchorEl={this.props?.setPopOver}
                    onClose={this.onPopoverClose.bind(this)}
                    className='talentPopver'
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{ style: { width: '50%', backgroundColor: '#F2F2F2' } }}
                >
                    <MDBContainer className='NewTalentContainer'>

                        <MDBRow>
                            <MDBCol md={12}>
                                <div>New Talent will be displayed in application as:</div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField id='firstName'
                                    handleAlphabetVariantSelect={(value) => this.onChange("first_name", value)}
                                    value={this.state?.talentDetails?.first_name}
                                    // isMandatory={true}
                                    // showMandatory={this.state?.showError}
                                    onChange={(e) => this.onChange('first_name', e.target?.value)}
                                    label={"First Name"}
                                    isAlphabetVar={true}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="middleName"
                                    handleAlphabetVariantSelect={(value) => this.onChange("middle_name", value)}
                                    value={this.state?.talentDetails?.middle_name}
                                    onChange={(e) => this.onChange('middle_name', e.target?.value)}
                                    label={"Middle Name"}
                                    isAlphabetVar={true}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="lastName"
                                    handleAlphabetVariantSelect={(value) => this.onChange("last_name", value)}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    value={this.state?.talentDetails?.last_name}
                                    onChange={(e) => this.onChange('last_name', e.target?.value)}
                                    label={"Last Name"}
                                    isAlphabetVar={true}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicTextField id="suffix"
                                    handleAlphabetVariantSelect={(value) => this.onChange("suffix", value)}
                                    value={this.state?.talentDetails?.suffix}
                                    onChange={(e) => this.onChange('suffix', e.target?.value)}
                                    label={"Suffix"}
                                    isAlphabetVar={true}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <SelectField id='gender'
                                    // placeHolderText="gender(required)"
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    value={this.state?.talentDetails?.gender}
                                    options={this.state?.genderOptions || []}
                                    onChange={(e) => this.onChange('gender', e.target?.value)}
                                    label={"Gender"}
                                />
                            </MDBCol>
                            <MDBCol md={5} className={`${this.state?.talentDetails?.ethnicity_list?.length === 0 ? "searchSelectHeight searchSelect" : "searchSelect"}`}
                                id='formatDropDown'>
                                <SearchSelectField
                                    id={"ethnicity"}
                                    label={"Ethnicity"}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    value={this.state?.talentDetails?.ethnicity_list || []}
                                    options={this.state?.ethnicityOptions || []}
                                    // showAsLabel={!this.props?.isEditing}
                                    // stateLabel={!this.props?.isEditing}
                                    // limitTags={4}
                                    onChange={(e, value) => this.onChange('ethnicity_list', value)}
                                    multiple={true}
                                // isTalent={true}
                                // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                />
                                {/* <SelectField id='ethnicity'
                                    // placeHolderText="Ethnicity(required)"
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    options={this.state?.ethnicityOptions || []}
                                    value={this.state?.talentDetails?.ethnicity_name}
                                    onChange={(e) => this.onChange('ethnicity_name', e.target?.value)}
                                    label={"Ethnicity"}
                                /> */}
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField id='SSN'
                                    // placeholder="SSN"
                                    value={this.state?.talentDetails?.ssn}
                                    onChange={(e) => this.onChange('ssn', e.target?.value)}
                                    limit={ssnFormat(this.state?.talentDetails?.ssn) ? SizeLimits.formattedSSN : SizeLimits.ssnCharacterLimit}
                                    limitWarning={Messages?.ssnWarning}
                                    fieldValid={this.state?.talentDetails?.ssn ? (!validateNumHyphenOnly(this.state?.talentDetails?.ssn) ? true : false) : false}
                                    inValidInput={Messages.invalidInput}
                                    onBlur={this.state?.talentDetails?.ssn ? () => this.formatSSN() : undefined}
                                    label={"SSN/fID"}
                                    showDuplicate={this.state.ssnError}
                                    showDuplicateMesg={this.state.ssnErrorMessage}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicTextField id="sagId"
                                    // placeholder="SAG Id"
                                    value={this.state?.talentDetails?.sag_id}
                                    onChange={(e) => this.onChange('sag_id', e.target?.value)}
                                    label={"SAG ID"}
                                    showDuplicate={this.state.sagError}
                                    showDuplicateMesg={this.state.sagErrorMessage}
                                />
                            </MDBCol>

                        </MDBRow>


                        <MDBRow className=''>
                            <MDBCol md={6}></MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                {this.props?.module !== "Performer" ?
                                    <BasicButton
                                        variant="contained"
                                        text={this.state?.isPosting && this.state?.clickedState == 'saveEdit' ? <CircularProgress color="inherit" size={20} /> : "Save & Edit"}

                                        disabled={this.state?.isPosting}
                                        onClick={this.postTalent.bind(this, 'saveEdit')}
                                    /> : null}
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.isPosting && this.state?.clickedState == 'save' ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                    disabled={this.state?.isPosting}
                                    icon={"save"}
                                    onClick={this.postTalent.bind(this, 'save')}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleClose} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Popover>
                {
                    this.state.showTalentPopup ?
                        <ModalComponent
                            open={this.state.showTalentPopup}
                            handleClose={this.handleHideTalentPopup.bind(this)}
                            tabList={talentFCNavList}
                            headerText={"Talent"}
                            mode="new"
                            isProfileDiv={true}
                            addButtonText={"New Talent"}
                            objectType={'TALENT'}
                            objectId={this.state?.talentDetails?.talent_id}
                            headerName={this.state.talentDetails?.name}
                            akas={this.state.talentSelected?.akas?.aka}
                            dataUrl='talentDetails' /> : null
                }
                {/* {
                    this.state.showTalentPerformerPopup ? 
                        <TalentPerformer
                            open={this.state.showTalentPerformerPopup}
                            selectedTalent={this.state.newTalentId}
                            project_id={this.props?.project_id}
                            headerText={"New Performer Record | "}
                        /> : null
                } */}
            </>
        )
    }
}
