export const searchTalentConfig = {
    headings: [
        {
            headingLabel: "Talent",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Show Season",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Agency",
            width: "18%"
        },
        {
            headingLabel: "Prim. Representative",
            width: "18%"
        },
        {
            headingLabel: "Role Type",
            width: "14%"
        },
        {
            headingLabel: "",
            width: "10%"
        }
    ],
    dataNodes: ["talent_name", "show_season", "agency_name", "representative_name", "role_type", "Record"],
    primaryKey: "talent_id",
    buttonWithTextAndIcon: [
        {
            buttonText: "Record",
            icon: "share"
        }
    ],
    hyperlinks: ["talent_name"],
}

export const talentInitialConfig = {
    "talent_name": null,
    "gender_id": null,
    "gender": null,
    "ethnicity_id": null,
    "ethnicity": null,
    "is_minor_id": null,
    "is_minor": null,
    "loanout_company": null,
    "representative_id": null,
    "representative_name": null,
    "company_id": null,
    "company_name": null,
    "show_id": null,
    "show_name": null,
    "show_season_id": null,
    "character": null,
    "recording_date_range_start": null,
    "recording_date_range_end": null,
    "rate_range_start": null,
    "rate_range_end": null,
}

export const talentFilterJson = {
    "talent_name": null,
    "gender": null,
    "ethnicity": null,
    "is_minor": null,
    "loanout_company": null,
    "representative_name": null,
    "company_name": null,
    "show_id": null,
    "show_season_id": null,
    "character": null,
    "recording_date_range_start": null,
    "recording_date_range_end": null,
    "rate_range_start": null,
    "rate_range_end": null,
    "order_by_clause": "talent_name",
    "order_by": "ASC",
    "page_count": null,
    "page_no": null
}

export const minorOptions = [
    {
        value: 1,
        label: 'Yes'
    },
    {
        value: 0,
        label: 'No'
    },
]

export const searchRepresentativeConfig = {
    headings: [
        {
            headingLabel: "Representative Name",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Type",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Company",
            width: "18%"
        },
        {
            headingLabel: "Email",
            width: "18%"
        },
        {
            headingLabel: "Phone",
            width: "14%"
        },
    ],
    dataNodes: ["representative_name", "representative_type", "company_name", "email", "phone"],
    primaryKey: "representative_id",
    hyperlinks: ["representative_name"],
}

export const repInitialConfig = {
    "representative_name": null,
    "representative_type_id": null,
    "representative_type": null,
    "company_name": null,
    "company_type_id": null,
    "company_type": null,
    "talent_id": null,
}

export const repFilterJson = {
    "representative_name": null,
    "representative_type": null,
    "company_name": null,
    "company_type": null,
    "talent_id": null,
    "order_by_clause": "representative_name",
    "order_by": "ASC",
    "page_count": null,
    "page_no": 0
}

export const searchCompanyConfig = {
    headings: [
        {
            headingLabel: "Company",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Type",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Address",
            width: "18%"
        },
        {
            headingLabel: "Email",
            width: "18%"
        },
        {
            headingLabel: "Phone",
            width: "14%"
        },
    ],
    dataNodes: ["company_name", "company_type", "address", "email", "phone"],
    primaryKey: "company_id",
    hyperlinks: ["company_name"],
}

export const companyInitialConfig = {
    "company_name": null,
    "company_type_id": null,
    "company_type": null,
    "representative_id": null,
    "talent_id": null,
}

export const companyFilterJson = {
    "company_name": null,
    "company_type": null,
    "representative_id": null,
    "talent_id": null,
    "order_by_clause": "company",
    "order_by": "ASC",
    "page_count": null,
    "page_no": 0
}

export const talentSortByConfig = [
    { value: 1, label: 'Date Created', sortBy: 'Date Created' },
    { value: 2, label: 'Date Modified', sortBy: 'Date Modified' },
    { value: 3, label: 'Talent Name', sortBy: 'Talent' },
    { value: 4, label: 'Show Name', sortBy: 'Show Name' },
    { value: 5, label: 'Recording Date', sortBy: 'Recording' },
    { value: 6, label: 'Contract Type', sortBy: 'Contract Type' }
]

export const repSortByConfig = [
    { value: 1, label: 'Date Created', sortBy: 'Date Created' },
    { value: 2, label: 'Date Modified', sortBy: 'Date Modified' },
    { value: 3, label: 'Rep. Name', sortBy: 'Representative Name' },
    { value: 4, label: 'Rep. Type', sortBy: 'Representative Type' },
    { value: 5, label: 'Company Name', sortBy: 'Company Name' },
    { value: 6, label: 'Company Type', sortBy: 'Company Type' }
]

export const companySortByConfig = [
    { value: 1, label: 'Date Created', sortBy: 'Date Created' },
    { value: 2, label: 'Date Modified', sortBy: 'Date Modified' },
    { value: 3, label: 'Company Name', sortBy: 'Company' },
    { value: 4, label: 'Company Type', sortBy: 'Company Type' },
    { value: 5, label: 'Rep. Name', sortBy: 'Representative Name' },
]