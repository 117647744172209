import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../../Common/Helper';
class CastList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            includeRegulars: null,
            includeSSN: null,
            confidential: null,
            todayDate: null,
            clicked: false,
            setEpisode: false,
            postInitiated: false,
        }
    }

    handleSubmitCastList = () => {
        this.setState({ postInitiated: true});
        let { selectSeason, selectEpisode, selectProduction, todayDate: revised_date, includeRegulars: include_regulars, includeSSN: include_ssn, confidential } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;

        if (!production_id || !ps_id) {
            this.setState({ isPosting: false })
            return;
        }
        let payload = {
            production_id,
            ps_id,
            episode_id,
            revised_date,
            include_regulars,
            include_ssn,
            confidential
        }

        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({postInitiated: false})
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    getDateOf = () => {
        let today = new Date();
        let date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        let hours = today.getHours();
        let ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12 || 12;
        hours = hours < 10 ? '0' + hours : hours;
        let mins = today.getMinutes();
        mins = mins < 10 ? '0' + mins : mins;
        let time = hours + ':' + mins + " " + ampm;
        let dateTime = date + " , " + time;
        this.setState({
            todayDate: dateTime,
            clicked: true,
            postInitiated: false
        })
    }

    handleCheck = (e) => {
        this.setState({postInitiated: false})
        let isChecked = e.target.checked
        if (isChecked) {
            if (e.target.id === "include_regulars") {
                this.setState({ includeRegulars: 1 })
            }
            if (e.target.id === "include_ssn") {
                this.setState({ includeSSN: 1 })
            }
            if (e.target.id === "confidential") {
                this.setState({ confidential: 1 })
            }
        } else {
            if (e.target.id === "include_regulars") {
                this.setState({ includeRegulars: null })
            }
            if (e.target.id === "include_ssn") {
                this.setState({ includeSSN: null })
            }
            if (e.target.id === "confidential") {
                this.setState({ confidential: null })
            }
        }
    }


    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={false}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <BasicLabel text={"Output"} />
                        </MDBCol>
                        <MDBCol>
                            <BasicLabel text={"Revised Date"} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <MDBRow>
                                <BasicCheckbox
                                    id="include_regulars"
                                    label="Include Regulars"
                                    checked={this.state.includeRegulars === 1}
                                    align="start"
                                    onChange={e => this.handleCheck(e)}
                                    disabled={this.state.setEpisode === false ? true : false}
                                />
                            </MDBRow>
                            <MDBRow>
                                <BasicCheckbox
                                    id="include_ssn"
                                    label="Include SSN"
                                    checked={this.state.includeSSN === 1}
                                    align="start"
                                    onChange={e => this.handleCheck(e)}
                                />
                            </MDBRow>
                            <MDBRow>
                                <BasicCheckbox
                                    id="confidential"
                                    label="Confidential"
                                    checked={this.state.confidential === 1}
                                    align="center"
                                    onChange={e => this.handleCheck(e)}
                                />
                            </MDBRow>
                        </MDBCol>
                        <MDBCol className="setNewTime">
                            <BasicLabel type="dateColor" text={this.state.clicked ? this.state.todayDate : "MM/DD/YY , hh:mm AM/PM"} />
                            <BasicButton
                                variant="contained"
                                type="inline"
                                text={"Set New Time"}
                                onClick={this.getDateOf} />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.handleSubmitCastList}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default CastList;