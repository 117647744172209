import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicCheckbox from '../BasicCheckbox/BasicCheckbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import './ActionButtons.scss';

export default class ActionButtons extends React.Component {
    render() {
        return (
            <div className={this.props?.addButton  ? "g-0 ActionButtonContainer addButton" : "g-0 ActionButtonContainer noAddButton"}>
                {/* <div className='mb-1'><span className={(!this.props?.isEditing && this.props?.isPrimary) ?"primaryStarIcon":""}></span></div> */}
                {this.props?.showRadio ?
                        <input 
                        name={this.props?.radioGroupName} 
                        size="small"
                        type="radio"
                        value={this.props?.value || 1}   
                        checked={this.props?.isPrimary}
                        onChange={this.props?.primaryOnChange}
                      />
                        : null} 
                    {
                        (this.props?.showRemove && !this.props?.disabled) ? 
                                <MDBIcon icon="times" onClick={this.props?.removeCallback} className={this.props?.removeClassName || ''} />
                          :null 
                    }
                    {
                        (this.props?.showAdd && !this.props?.disabled) ?
                                <MDBIcon icon="plus-circle" onClick={this.props?.addCallback} className={this.props?.addClassName || ''} />
                            :null
                    }
                </div>
        );
    }
}

