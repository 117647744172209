import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import ReportButton from './ReportButton';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import { s2ab, genReportName } from "../../../Common/Helper";
import { withUserContext } from "../../../../contexts/UserContext";

class BillingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            last_name: 0,
            role_no: 0,
        }
    }

    handleSubmitBillingList = (docFlag = 0, excelFlag = 0) => {
        this.setState({
            postInitiated: true,
        });
        let { selectProduction, role_no, last_name } = this.state;
        let production_id = selectProduction?.value;

        if (!production_id) {
            return;
        }
        let payload = {
            production_id,
            last_name,
            role_no
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal,docFlag, excelFlag)
        this.setState({postInitiated: false})
    }

    handleCheck = (e) => {
        let isChecked = e.target.checked
        if (isChecked) {
            if (e.target.id === "last_name") {
                this.setState({ last_name: 1 })
            }
            if (e.target.id === "role_no") {
                this.setState({ role_no: 1 })
            }
        } else {
            if (e.target.id === "last_name") {
                this.setState({ last_name: 0 })
            }
            if (e.target.id === "role_no") {
                this.setState({ role_no: 0 })
            }
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated:false
        });
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <BasicLabel text={"Sort By"} />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="last_name"
                                label="Last Name"
                                checked={this.state.last_name === 1}
                                align="start"
                                onChange={e => this.handleCheck(e)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="role_no"
                                label="Role Number"
                                checked={this.state.role_no === 1}
                                align="start"
                                onChange={e => this.handleCheck(e)}
                            />
                        </MDBRow>
                    </MDBRow>
                    <ReportButton
                        generateExcel={true}
                        generateDoc={true}
                        onClickGenratePdf={this.handleSubmitBillingList}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(BillingList);