import React, { Component } from 'react'
import { Popover } from '@material-ui/core';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import '../NewRepresentative/NewRepresentative.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import { RepresentativeShortDetails } from "./config"
import { representativeFCNavList } from '../../components/Representative/config';
import ModalComponent from '../DefaultModalLayout/Modal/Modal';
export default class NewRepresentative extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPosting: false,
            saveEditPosting: false,
            showError: false,
            genderOptions: [],
            ethnicityOptions: [],
            representativeDetails: { ...RepresentativeShortDetails },
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            showRepresentativePopup: false
        }
    }

    componentDidMount() {
        //let newValue = this.props?.representativeData?.value?.split(" ");
        // let first_value = newValue[0];
        // let middle_value = newValue[1];
        // newValue.splice(0, 2);
        // this.onChange('first_name', first_value);
        // this.onChange('middle_name', middle_value);
        // this.onChange('last_name', newValue?.length > 1 ? newValue?.join(" ").toString() : newValue?.toString());
        if (this.props?.representativeData?.value?.length > 0 && this.props?.representativeData?.value) {
            let splitValue = this.props?.representativeData?.value?.split(" ");
            let obj = {
                first_name: splitValue?.[0] || null,
                middle_name: null,
                last_name: null
            }
            if (splitValue?.length == 2) {
                // last_name:splitValue?.slice?.(2,splitValue?.length)?.join(" ") || null
                obj.last_name = splitValue?.[1] || null

            }
            else if (splitValue?.length == 3) {
                obj.middle_name = splitValue?.[1] || null
                obj.last_name = splitValue?.[2] || null
            }
            else {
                obj.middle_name = splitValue?.[1] || null
                obj.last_name = splitValue?.slice?.(2, splitValue?.length)?.join(" ") || null
            }

            let representativeDetails = { ...this.state?.representativeDetails, ...obj }
            this.setState({ representativeDetails: representativeDetails });
        }
        this.getLookupValues('Gender');
        this.getLookupValues('Ethnicity');
    }

    getLookupValues(type) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value }))
                    if (type == 'Gender') {
                        this.setState({ genderOptions: formattedList })
                    } else if (type == 'Ethnicity') {
                        this.setState({ ethnicityOptions: formattedList })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })

    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }


    onChange(key, value) {
        this.setState((prevState) => ({
            representativeDetails: {
                ...prevState?.representativeDetails,
                [key]: value
            }
        }))
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }
    validateBeforePost(postJson) {
        let isValid = false;
        isValid = postJson?.first_name
        return isValid;
    }
    postRepresentative(type) {
        // casterFeatureTalentDetails
        let postJson = this.state.representativeDetails
        this.setState({ showError: false })
        if (type === 'saveEdit') {
            this.setState({ saveEditPosting: true })
        } else {
            this.setState({ isPosting: true })
        }
        if (!this.validateBeforePost(postJson)) {
            this.setState({ isPosting: false, showError: true, saveEditPosting: false })
            return;
        }
        let aka = `${postJson?.first_name ? postJson?.first_name : ''} ${postJson?.middle_name ? postJson?.middle_name : ''} ${postJson?.last_name ? postJson?.last_name : ''} ${postJson?.suffix ? postJson?.suffix : ''}`
        let akaValue = {
            aka,
            aka_first_name: postJson?.first_name,
            aka_middle_name: postJson?.middle_name,
            aka_last_name: postJson?.last_name,
            aka_suffix: postJson?.suffix,
            is_primary: 1,
            is_delete: 0

        }
        postJson.akas = []
        postJson.akas.push(akaValue);
        postJson.name = aka?.trim() || '';
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureRepresentativeDetails', postJson, this.state?.selecttenant)
            .then((response) => {
                if (response?.data?.error) {
                    this.setState({ isPosting: false, showError: false, saveEditPosting: false })
                    this.notificationComponent(true, "fail");
                } else {
                    postJson.representative_id = response?.data?.[0]?.representative_id;
                    this.props?.updateRepId(response?.data?.[0]?.representative_id)
                    this.setState({ isPosting: false, showError: false, representativeDetails: postJson, saveEditPosting: false })
                    this.notificationComponent(true, "success");
                    if (type === 'saveEdit') {
                        this.props?.setObjectSelected('representative', { headerName: postJson?.name, text: postJson?.name, value: postJson.representative_id, aka: postJson?.name })
                    } else {
                        this.props?.setNewObjectSelected('representative', { text: postJson?.name, headerName: postJson?.name, value: postJson.representative_id, aka: postJson?.name, optionType: 'save' })
                    }
                    this.props?.handleClose();
                    console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ isPosting: false, showError: false, saveEditPosting: false })
                    this.notificationComponent(true, "fail");
                    console.log("Post representative details error: " + err);
                });
    }
    onPopoverClose() {
        this.setState({ representativeDetails: { ...RepresentativeShortDetails } })
    }
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <div disableEnforceFocus>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />

                <Popover
                    open={this.props.open}
                    anchorEl={this.props?.setPopOver}
                    onClose={this.onPopoverClose.bind(this)}
                    //className='talentPopver'
                    //id="representativePopover"
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{ style: { width: '50%', backgroundColor: '#F2F2F2' } }}
                >
                    <MDBContainer className='NewRepresentativeContainer'>
                        <MDBRow>
                            <MDBCol md={12}>
                                <div>New Representative will be displayed in application as:</div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3}>
                                <BasicTextField id='firstName'
                                    label={"First Name"}
                                    handleAlphabetVariantSelect={(value) => this.onChange("first_name", value)}
                                    value={this.state?.representativeDetails?.first_name}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    isAlphabetVar={true}
                                    onChange={(e) => this.onChange('first_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="middleName"
                                    label={"Middle Name"}
                                    handleAlphabetVariantSelect={(value) => this.onChange("middle_name", value)}
                                    isAlphabetVar={true}
                                    value={this.state?.representativeDetails?.middle_name}
                                    onChange={(e) => this.onChange('middle_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="lastName"
                                    label={"Last Name"}
                                    isAlphabetVar={true}
                                    handleAlphabetVariantSelect={(value) => this.onChange("last_name", value)}
                                    value={this.state?.representativeDetails?.last_name}
                                    onChange={(e) => this.onChange('last_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicTextField id="suffix"
                                    label={"Suffix"}
                                    isAlphabetVar={true}
                                    handleAlphabetVariantSelect={(value) => this.onChange("suffix", value)}
                                    value={this.state?.representativeDetails?.suffix}
                                    onChange={(e) => this.onChange('suffix', e.target?.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-2'>
                            <MDBCol md={6}></MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.saveEditPosting ? <CircularProgress color="inherit" size={20} /> : "Save & Edit"}
                                    disabled={this.state?.saveEditPosting}
                                    onClick={this.postRepresentative.bind(this, 'saveEdit')}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                    disabled={this.state?.isPosting}
                                    icon={"save"}
                                    onClick={this.postRepresentative.bind(this, 'save')}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleClose} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Popover>
            </div>
        )
    }
}
