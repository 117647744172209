import React, { Component } from 'react'
import { Popover } from '@material-ui/core';
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import Radio from "@material-ui/core/Radio";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import './AliasComponent.scss'

export default class AliasComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPosting: false,
            showError: false,
            aliasDetails: [...this.props?.aliasDetails],
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            openAliasPopOver: false,
            currentAlias: this.props?.initialAliasConfig,
            // currentAlias: {
            //     "agency_aliases_id": null,
            //     "alias": null,
            //     "is_primary": 1,
            //     "is_delete": 0
            // }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.aliasDetails !== state.aliasDetails) {
            return {
                aliasDetails: props.aliasDetails
            }
        }
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    onAliasChange(key, value) {
        this.setState((prevState) => ({
            currentAlias: {
                ...prevState?.currentAlias,
                [key]: value
            }
        }))
    }

    onSave = () => {

        if (this.props?.module_type === "Representative" || this.props?.module_type === "Crew" || this.props?.module_type === "Talent" || this.props?.module_type === "Talent Performer") {

            let currentAlias = { ...this.state.currentAlias }
            this.setState({ showError: false })
            if (this.props?.module_type === "Representative") {
                if (!currentAlias?.aka_first_name && !currentAlias?.aka_last_name) {
                    this.setState({ showError: true })
                    return;
                }
            } else if(this.props?.module_type === "Crew"){
                if (!currentAlias?.aka_first_name || !currentAlias?.aka_last_name) {
                    this.setState({ showError: true })
                    return;
                }
            }else {
                if (!currentAlias?.aka_first_name) {
                    this.setState({ showError: true })
                    return;
                }
            }
            currentAlias.aka = `${currentAlias?.aka_first_name ? currentAlias?.aka_first_name : ''} ${currentAlias?.aka_middle_name ? currentAlias?.aka_middle_name : ''} ${currentAlias?.aka_last_name ? currentAlias?.aka_last_name : ''} ${currentAlias?.aka_suffix ? currentAlias?.aka_suffix : ''}`
            this.setState({ currentAlias }, () => {
                this.props?.onAliasSave(currentAlias)
                this.setState({ currentAlias: this.props?.initialAliasConfig }, () => {
                    this.handleCloseAlias()
                })
            })
        } else {
            this.props?.onAliasSave(this.state.currentAlias)
            this.setState({ currentAlias: this.props?.initialAliasConfig }, () => {
                this.handleCloseAlias()
            })
        }
    }

    handleOnChange = (e, index, field) => {
        console.log("radio", e.target)
        let aliasDets = this.state.aliasDetails
        if (field === "is_primary") {
            aliasDets?.map((item, aIndex) => {
                if (aIndex != index) {
                    aliasDets[aIndex].is_primary = 0
                }
                else {
                    aliasDets[aIndex].is_primary = e.target.checked === true ? 1 : 0
                }
            })
        }
        if (field === "is_delete") {
            aliasDets[index].is_delete = 1
        }
        this.setState({ aliasDetails: aliasDets })
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }

    handleOpenAlias = () => {
        this.setState({ openAliasPopOver: true })
    }
    handleCloseAlias = () => {
        this.setState({ openAliasPopOver: false })
    }
    render() {
        // let paperProps = this.props?.module_type === "Agency" || this.props?.module_type === "Representative" || this.props?.module_type === "Crew" ? { style: { width: '25%', backgroundColor: '#F2F2F2' } } : { style: { width: '50%', backgroundColor: '#F2F2F2' } }
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />

                <div className='NewAliasContainer'>
                    {this.props?.module_type != "Talent Performer" ? <div className="alias-title">Alias</div> : null}
                    <MDBRow>
                        {console.log("Alias Details", this.state?.aliasDetails)}
                        <div className={`d-flex ${this.props?.module_type != "Talent Performer" ? 'fixWidth' : ''}`}>
                            {this.state?.aliasDetails?.map((alias, index) => (alias?.is_delete != 1 &&
                                // <MDBCol>
                                <div className="alias">
                                    <div className="radio-alias">
                                        <Radio
                                            name="radio-buttons"
                                            id={index}
                                            checked={alias?.is_primary === "1" || alias?.is_primary === 1}
                                            value={index}
                                            size={"small"}
                                            // defaultChecked={alias?.is_primary === "1" || alias?.is_primary === 1}
                                            onChange={(e) => this.handleOnChange(e, index, "is_primary")}
                                        />
                                        {/* <input
                                            name='radio-buttons'
                                            id={index}
                                            size="small"
                                            type="radio"
                                            value={alias?.is_primary}
                                            // checked={alias?.is_primary === "1"}
                                            defaultChecked={alias?.is_primary === "1" || alias?.is_primary === 1}
                                            onChange={(e) => this.handleOnChange(e, index, "is_primary")}
                                        /> */}
                                    </div>
                                    <div className="mr">{alias?.aka || alias?.alias || ''}</div>
                                    <div className="icon-times">
                                        <RemoveCircleIcon onClick={(e) => this.handleOnChange(e, index, "is_delete")} />
                                        {/* // <MDBIcon
                                        //     icon="times"
                                        //     onClick={(e) => this.handleOnChange(e, index, "is_delete")} /> */}
                                    </div>
                                </div>
                            ))}
                            <div className="addAliasButton">
                                <BasicButton
                                    id='btnAddAlias'
                                    className="alias-style"
                                    text="Add New Alias"
                                    icon="plus-circle"
                                    onClick={() => { this.handleOpenAlias() }}
                                    variant="outlined"
                                />
                                <Popover
                                    id={"alias"}
                                    open={this.state.openAliasPopOver}
                                    anchorEl={document?.getElementById('btnAddAlias')}
                                    onClose={() => { this.handleCloseAlias() }}
                                    className='aliasPopOver'
                                    getContentAnchorEl={null}
                                    anchorReference="anchorOrigin"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    PaperProps={{ style: { width: '50%', backgroundColor: '#F2F2F2' } }}
                                // PaperProps={paperProps}
                                >
                                    <div className='AliasContainer'>
                                        {this.props?.module_type === "Agency" ?
                                            <MDBRow>
                                                <MDBCol md={12}>
                                                    <BasicTextField
                                                        // placeholder="Alias"
                                                        value={this.state.currentAlias?.alias}
                                                        onChange={(e) => {
                                                            this.onAliasChange("alias", e.target.value)
                                                        }}
                                                        label={"Alias"} />
                                                </MDBCol>
                                            </MDBRow>
                                            : <MDBRow>
                                                <MDBCol md={3}>
                                                    <BasicTextField
                                                        // placeholder="First Name"
                                                        value={this.state.currentAlias?.aka_first_name}
                                                        isMandatory={true}
                                                        showMandatory={this.state?.showError}
                                                        onChange={(e) => {
                                                            this.onAliasChange("aka_first_name", e.target.value)
                                                        }}
                                                        handleAlphabetVariantSelect={(value) => this?.onAliasChange('aka_first_name', value)}
                                                        isAlphabetVar={true}
                                                        label={"First Name"} />
                                                </MDBCol>
                                                <MDBCol md={3}>
                                                    <BasicTextField
                                                        // placeholder="Middle Name"
                                                        value={this.state.currentAlias?.aka_middle_name}
                                                        onChange={(e) => {
                                                            this.onAliasChange("aka_middle_name", e.target.value)
                                                        }}
                                                        isAlphabetVar={true}
                                                        handleAlphabetVariantSelect={(value) => this?.onAliasChange('aka_middle_name', value)}
                                                        label={"Middle Name"} />
                                                </MDBCol>
                                                <MDBCol md={3}>
                                                    <BasicTextField
                                                        // placeholder="Last Name"
                                                        value={this.state.currentAlias?.aka_last_name}
                                                        isMandatory={(this.props?.module_type === "Representative" || this.props?.module_type === "Crew")? true : false}
                                                        showMandatory={(this.props?.module_type === "Representative" || this.props?.module_type === "Crew")? this.state?.showError : ""}
                                                        onChange={(e) => {
                                                            this.onAliasChange("aka_last_name", e.target.value)
                                                        }}
                                                        handleAlphabetVariantSelect={(value) => this?.onAliasChange('aka_last_name', value)}
                                                        isAlphabetVar={true}
                                                        label={"Last Name"} />
                                                </MDBCol>
                                                <MDBCol md={3}>
                                                    <BasicTextField
                                                        // placeholder="Suffix"
                                                        value={this.state.currentAlias?.aka_suffix}
                                                        onChange={(e) => {
                                                            this.onAliasChange("aka_suffix", e.target.value)
                                                        }}
                                                        isAlphabetVar={true}
                                                        handleAlphabetVariantSelect={(value) => this.onAliasChange('aka_suffix', value)}
                                                        label={"Suffix"} />
                                                </MDBCol>
                                            </MDBRow>}
                                        <MDBRow>
                                            <MDBCol md={8}></MDBCol>
                                            <MDBCol md={2}>
                                                <BasicButton
                                                    variant="contained"
                                                    text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                    icon={!this.state?.isPosting ? "save" : " "}
                                                    onClick={() => { this.onSave() }}
                                                />
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <BasicButton
                                                    variant="outlined"
                                                    text=" "
                                                    icon={"times"}
                                                    onClick={() => { this.handleCloseAlias() }} />
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                        {/* <MDBCol md={3} className={this.props?.module_type === "Talent Performer" ? "performer-alias" : ""}> */}
                        {/* <div>
                            <BasicButton
                                id='btnAddAlias'
                                className="alias-style"
                                text="Add Alias"
                                icon="plus-circle"
                                onClick={() => { this.handleOpenAlias() }}
                                variant="outlined"
                            />
                        </div>
                        {/* </MDBCol> */}
                        {/* <Popover 
                            id={"alias"}
                            open={this.state.openAliasPopOver}
                            anchorEl={document?.getElementById('btnAddAlias')}
                            onClose={() => { this.handleCloseAlias() }}
                            className='aliasPopOver'
                            getContentAnchorEl={null}
                            anchorReference="anchorOrigin"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{ style: { width: '50%', backgroundColor: '#F2F2F2' } }}
                        // PaperProps={paperProps}
                        >
                            <div className='AliasContainer'>
                                {this.props?.module_type === "Agency" ?
                                    <MDBRow>
                                        <MDBCol md={12}>
                                            <BasicTextField
                                                // placeholder="Alias"
                                                value={this.state.currentAlias?.alias}
                                                onChange={(e) => {
                                                    this.onAliasChange("alias", e.target.value)
                                                }}
                                                label={"Alias"} />
                                        </MDBCol>
                                    </MDBRow>
                                    : <MDBRow>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                // placeholder="First Name"
                                                value={this.state.currentAlias?.aka_first_name}
                                                isMandatory={true}
                                                showMandatory={this.state?.showError}
                                                onChange={(e) => {
                                                    this.onAliasChange("aka_first_name", e.target.value)
                                                }}
                                                label={"First Name"} />
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                // placeholder="Middle Name"
                                                value={this.state.currentAlias?.aka_middle_name}
                                                onChange={(e) => {
                                                    this.onAliasChange("aka_middle_name", e.target.value)
                                                }}
                                                label={"Middle Name"} />
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                // placeholder="Last Name"
                                                value={this.state.currentAlias?.aka_last_name}
                                                isMandatory={this.props?.module_type === "Representative" ? true : false}
                                                showMandatory={this.props?.module_type === "Representative" ? this.state?.showError : ""}
                                                onChange={(e) => {
                                                    this.onAliasChange("aka_last_name", e.target.value)
                                                }}
                                                label={"Last Name"} />
                                        </MDBCol>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                // placeholder="Suffix"
                                                value={this.state.currentAlias?.aka_suffix}
                                                onChange={(e) => {
                                                    this.onAliasChange("aka_suffix", e.target.value)
                                                }}
                                                label={"Suffix"} />
                                        </MDBCol>
                                    </MDBRow>}
                                <MDBRow>
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            variant="contained"
                                            text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                            icon={!this.state?.isPosting ? "save" : " "}
                                            onClick={() => { this.onSave() }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2}>
                                        <BasicButton
                                            variant="outlined"
                                            text=" "
                                            icon={"times"}
                                            onClick={() => { this.handleCloseAlias() }} />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </Popover> */}

                    </MDBRow>

                </div>

            </>
        )
    }
}
