import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import SelectField from '../SharedComponents/SelectField/SelectField';
import { MDBCol, MDBRow } from 'mdbreact';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import{ validateAllPhone, formatPhoneNumbers } from '../../Common/Helper'


class CastingDataReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="CastingDataReportContainer">
             <MDBRow>
                <MDBCol md={7}>
                <SearchSelectField
                    id="project_id"
                    options={this.props?.projectList}
                    searchSelect={true}
                    multiple={false}
                    label={"Select Project"}
                    placeholder={"Select Project"}
                    detail_selected={this.props?.selectedprojectValue}
                    valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                    searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
             <MDBCol md={5}>
                <BasicCheckbox 
                    id={"stunt_only"}
                    label={"Stunt only"}
                    checked={this.state.reportDetails?.stunt_only === 1 ? true : false}
                    onChange={(e) => this.props?.handleOnChange("stunt_only", e.target.checked)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Production Company"}
                        value={this.state.reportDetails?.production_company}
                        options={this.props.companyList}
                        onChange={(e) => this.props?.handleOnChange("production_company", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5}>
                    <BasicTextField 
                         label={"Phone"}
                         placeholder="(xxx)xxx-xxxx"
                         fieldValid={this.state.reportDetails?.phone ? !validateAllPhone(this.state.reportDetails?.phone) : false}
                         inValidInput={"Please enter valid phone"}
                         value={this.state.reportDetails?.phone && formatPhoneNumbers(this.state.reportDetails?.phone) || ""}
                         onChange={(e) => this.props?.handleOnChange("phone", formatPhoneNumbers(e.target.value))}
                       
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5}>
                    <BasicTextField 
                         label={"Submitted by"}
                         value={this.state.reportDetails?.submitted_by || ""}
                         onChange={(e) => this.props?.handleOnChange("submitted_by", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(CastingDataReport));