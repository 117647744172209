export const talentFCNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Representation",
        index: 1
    },
    {
        label: "Acting History",
        index: 2
    }
]
export const talentInitialDetails = {    
        "talent_id": 176130,
        "first_name": "nehal",
        "last_name": "jethwa",
        "is_active": 1,
        "middle_name": "P",
        "suffix": null,
        "birth_date": null,
        "death_date": null,
        "sag_id": "0",
        "ssn": "1234",
        "masked_ssn": "XXX-XX",
        "gender": "Female",
        "ethnicity_name": null,
        "notes": null,
        "citizen_of": null,
        "as_of": null,
        "immigration_status": null,
        "profile_image_name": null,
        "akas": [],
        "legal_list": [],
        "email_list": [],
        "phone_list": [],
        "address_list": [],
        "guardians_list": [],
        "representation_list": []
    
}
export const ssnOptions=[
    {
        value:'ssn',
        label:'SSN'
    },
    {
        value:'fid',
        label:'FID'
    },
]

export const initialEmailConfig={    
        talent_email_id: null,
        email_type: null,
        email: null,
        is_primary: 0,
        is_delete:0    
}
export const initialObjectConfig={    
    object_id: null,
    object_type: null,
    value: null,
    is_primary: 0,
    is_delete:0,
    ext:null    
}
export const initialLoanoutObjectConfig={    
    talent_legal_id: null,
    loanout_no: null,
    loanout_company: null,
    federal_id_no: null,
    state_inc: null,
    country_inc:null,
    is_primary: 0,
    is_delete:0

}
export const initialAliasConfig = {
    "talent_aliases_id": null,
    "aka": null,
    "aka_first_name": null,
    "aka_last_name": null,
    "aka_middle_name": null,
    "aka_suffix": null,
    "is_primary": 0,
    "is_delete": 0
}

export const actingHistoryConfig = {
    headings: [
        {
            headingLabel: "Project",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Role",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Union",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "",
            border: true,
            width: "20%"
        }
    ],
    dataNodes: ["project_name", "role_name", "union", "performer_record"],
    hyperlinks: ["project_name"],
    hyperlinktexticon: ["performer_record"]
}

export const actingHistoryFilterConfig = {
    "project_name": null,
    "role_name": null,
    "union": null
}