import React from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CancelIcon from '@material-ui/icons/Cancel';
import { newLocationDetails } from './config';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { getFormattedDate, validatePlusBracketHypenNumbersOnly, formatZipCode, formatPhoneNumbers, validateEmail, checkZipCode } from '../../Common/Helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@material-ui/core/Radio";
import messages from '../../Common/Messages.json';
import './AddProjectLocation.scss';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import sizeLimits from '../../Common/SizeLimits.json';

class AddProjectLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newLocationList: [],
            akaIndex: 2,
            productionIndex: 2,
            isSubmit: false,
            akaRadio: 0
        }

    }

    componentDidMount = () => {
        this.getLookupValues('Country');
        this.getLookupValues('State');
        if (this.props.mode === "New") {
            this.setState({
                newLocationList: JSON.parse(JSON.stringify({ ...newLocationDetails }))
            })
        } else {
            if (this.props?.mode == 'edit' && this.props?.locationSelected) {
                this.setState({ locationSelected: this.props?.locationSelected },
                    () => this.getProjectLocationDetails())

            }

        }
        // this.getLookupValues('PROJECT_STUDIO');
        // this.getLookupValues('PROJECT_DISTRIBUTOR');
        // this.getLookupValues('CONTRACT_TYPE');
        // this.getLookupValues('PROJECT_TYPE');
        //     this.getFeatureType();
        //     this.getDistributionType();
        //     this.getIntendedMarket();
    }

    getLookupValues = (type) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = []
                if (type === "State") {
                    formattedList = response.data?.map(item => ({ value:item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value, text:item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value, id: item?.lookup_id }));
                } else {
                    formattedList = response.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value, id: item?.lookup_id }));
                }
                if (type == "Address") {
                    this.setState({ addressTypes: formattedList });
                } else if (type == "Country") {
                    // let defaultCountry = formattedList.filter(item => {
                    //     return item.label === "United States" || item.label === "US" || item.label === "USA"
                    // })
                    formattedList = response.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value, id: item?.lookup_id }));
                    this.setState((prevState) => ({
                        newLocationList: {
                            ...prevState?.newLocationList,
                            ["country"]: this.state.newLocationList?.country 
                        },
                        countryList: formattedList
                    }))
                } else if (type == "State") {
                    this.setState({ stateList: formattedList });
                }
            },
                (err) => {
                    console.log("Error in fetching lookup:", err)
                })
    }

    formatNewProductionDetailsResponse = (response) => {
        if (response?.aliases?.length === 0) {
            response?.aliases.push(JSON.parse(JSON.stringify({ ...aliasList })));
        }
        if (response?.production_companies?.length === 0) {
            response?.production_companies.push(JSON.parse(JSON.stringify({ ...companiesList })));
        }
        return response;
    }

    getProjectLocationDetails = () => {
        // this.setState({ isProductionDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProjectLocation?project_id=${this.props?.project_id}&project_location_id=${this.props?.id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("response", response)

                let newResponse = response.data.map(obj => {
                    obj.from_date = obj?.from_date ? getFormattedDate(obj?.from_date, true) : null;
                    obj.to_date = obj.to_date ? getFormattedDate(obj?.to_date, true) : null;
                    obj.state =  obj?.state ? ((obj?.country === 'United States' || obj?.country === 'USA' || obj?.country === 'US') ? { value: obj.state, text: obj.state } : obj?.state) : "" ;
                    obj.country = obj.country ? { value: obj.country, text: obj.country } : ""
                    return obj;
                });
                this.setState({
                    newLocationList: newResponse[0],
                    // isProductionDetails: false
                }, () => {
                    console.log("res", this.state.newLocationList)
                });

            },
                (err) => {
                    console.log("Error in fetching Production Details:", err)
                })
    }

    handleGeneralEdit = (field, value) => {
        if (field === "email") {
            this.setState({ fieldValid: value ? validateEmail(value) : true })
        }
        this.setState(prevState => ({
            newLocationList: {
                ...prevState.newLocationList,
                [field]: value
            },
            isPosting: false,
        }), () => {
            if (field == "country") {
                this.setState(prevState => ({
                    newLocationList: {
                        ...prevState.newLocationList,
                        state: null
                    }
                }))
            }
            if (field == 'state' && (this.state.newLocationList?.state?.value)) {
                let defaultCountry = this.state.countryList.find(item => {
                    return (item.text === "United States" || item.text === "USA" || item.text === "US")
                })
                this.setState((prevState) => ({
                    newLocationList: {
                        ...prevState?.newLocationList,
                        ['country']: defaultCountry,
                        // ...(field == 'country') && { 'state': null }
                    }
                }))
            }
        })
    }

    handleListEdit = (list, field, value, index) => {
        let newProductionList = this.state.newProductionList;
        let akaRadio = this.state.akaRadio
        switch (list) {
            case 'aliases':
                let akalist = newProductionList[list][index];
                if (field === "is_primary") {
                    newProductionList[list]?.map((item, aIndex) => {
                        if (aIndex != index) {
                            newProductionList[list][aIndex].is_primary = 0
                            newProductionList['akaRadio'] = 0
                        }
                        else {
                            newProductionList[list][aIndex].is_primary = value === 1 ? 1 : 0
                        }
                    })
                } else {
                    let aka_item = {
                        ...akalist,
                        [field]: value
                    };
                    newProductionList[list][index] = aka_item;
                }
                break;
            case 'production_companies':
                let prodList = newProductionList[list][index];
                let prod_item = {
                    ...prodList,
                    [field]: value
                };
                newProductionList[list][index] = prod_item;
                break;
        }

        this.setState({ newProductionList: newProductionList, postInitiated: false, formEdited: true, akaRadio });
        // this.props.fieldChanged(this.state.formEdited, "change");
    }


    // handleAutocompleteField = (id, name, field, value) => {
    //     let newProductionList = this.state.newProductionList;
    //     newProductionList[id] = !value ? null : value.value;
    //     newProductionList[name] = !value ? null : value.label;
    //     newProductionList[field] = !value ? null : value;
    //     this.setState({ newProductionList: newProductionList, postInitiated: false, formEdited: true, crewNames: [] });
    //     this.props.fieldChanged(this.state.formEdited, "change");
    // }

    addAdditionalField = (listName, index) => {
        this.setState({ formEdited: true })
        // this.props.fieldChanged(this.state.formEdited, "change");
        let newProductionList = this.state.newProductionList;
        switch (listName) {
            case 'aliases':
                if (newProductionList.aliases[index].alias_name) {
                    let akaArray = JSON.parse(JSON.stringify({ ...aliasList }));
                    let aka_list = [...newProductionList.aliases];
                    akaArray.index = this.state.akaIndex;
                    aka_list.push(akaArray);
                    newProductionList.aliases = aka_list;
                    this.setState({ akaIndex: this.state.akaIndex + 1, newProductionList: newProductionList, postInitiated: false });
                }
                break;
            case 'production_companies':
                if (newProductionList.production_companies[index].production_company) {
                    let initialProductionArray = JSON.parse(JSON.stringify({ ...companiesList }));
                    let production_list = [...newProductionList.production_companies];
                    initialProductionArray.index = this.state.productionIndex;
                    production_list.push(initialProductionArray);
                    newProductionList.production_companies = production_list;
                    this.setState({
                        productionIndex: this.state.productionIndex + 1,
                        newProductionList: newProductionList,
                        postInitiated: false
                    });
                }
                this.setState({ showFields: true });
                break;
        }
    }
    removeField = (listName, callbackItem) => {
        this.setState({
            formEdited: true,
            listName: listName, callbackItem: callbackItem, postInitiated: false
        })
        // this.props.fieldChanged(this.state.formEdited, "change");
        switch (listName) {
            case 'aliases':
                if (callbackItem.project_alias_id) {
                    this.showMessage(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;

            case 'production_companies':
                if (callbackItem.project_production_company_id) {
                    this.showMessage(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;
        }
    }

    // showMessage = () => {
    //     this.setState({ isOpen: true });
    // }

    deleteRow = (listName, callbackItem) => {
        let newProductionList = this.state.newProductionList;
        switch (listName) {
            case 'aliases':
                let aka_list = newProductionList[listName].filter(item =>
                    (item.project_alias_id !== callbackItem.project_alias_id) || (item.index !== callbackItem.index)
                );
                if (aka_list.length === 0) {
                    newProductionList[listName] = [{ ...aliasList }];
                } else {
                    newProductionList[listName] = aka_list;
                }
                break;
            case 'production_companies':
                let list = newProductionList[listName].filter(item =>
                    (item.project_production_company_id !== callbackItem.project_production_company_id) || (item.index !== callbackItem.index)
                );
                if (list.length === 0) {
                    newProductionList[listName] = [{ ...companiesList }];
                } else {
                    newProductionList[listName] = list;
                }
                break;
        }
        this.setState({
            newProductionList: newProductionList, listName: null, callbackItem: null, postInitiated: false, isOpen: false
        });
    }

    handleSubmit() {
        this.setState({ postInitiated: true, isPosting: true })
        let postJson = { ...this.state?.newLocationList }
        postJson.country = postJson?.country?.value
        postJson.state = (postJson?.country === 'United States' ||postJson?.country === 'USA' || postJson?.country === 'US') ?  postJson?.state?.value : postJson?.state;
        postJson.project_id = this.props?.project_id;
        postJson.project_location_id = this.props?.mode == 'new' ? null : this.props?.id;
        if (!(postJson?.location_name && (postJson?.postal_code?.length > 0 ? (postJson?.country === 'United States' || postJson?.country === 'USA' || postJson?.country === 'US' ? (postJson?.postal_code.length <= sizeLimits?.intlZipCharacterLimit && (postJson?.postal_code?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(postJson?.postal_code) : true)) : true) : true)) ) {
            this.setState({ isPosting: false })
            return;
        }
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureProjectLocation`, postJson)
            .then((response) => {
                this.setState({ isPosting: false, postInitiated: false })
                if (response?.data?.error) {
                    console.log('project crew submit', repsonse?.data?.error)
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.props?.notificationComponent(true, "success");
                    this.props?.toggleRefreshPage('ProjectLocation')
                }
            },
                (err) => {
                    this.setState({ isPosting: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post object details error: " + err);
                });

    }

    postProjectDetails = (postJson) => {
        if (postJson?.box_url === "") {
            postJson.box_url = null
        }
        this.setState({ isSubmit: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProject', postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    // this.props.fieldChanged(true, "fail");
                    // this.props?.handleSubmit('fail');
                    this.setState({
                        postInitiated: false, formEdited: false, isSubmit: false
                    });
                } else {
                    this.setState({
                        newProductionList: this.formatNewProductionDetailsResponse(newProjectDetails),
                        postInitiated: false, formEdited: false, isSubmit: false
                    }, () => {
                        this.props?.onClose()
                    });
                    // this.props.fieldChanged(true, "success");
                    // this.props?.handleSubmit('success');
                }
            },
                (err) => {
                    this.setState({ newProductionList: this.formatNewProductionDetailsResponse(newProjectDetails), postInitiated: false, });
                    console.log("Post representative details error: " + err);
                    // this.props?.handleSubmit('fail');
                    // this.props.fieldChanged(true, "fail");
                });
    }

    validateFields = (postJson) => {
        let submit = (
            (postJson.project_name && postJson.studio_name))
        //         && postJson.sag_id &&
        //         postJson.name && postJson.distribution_id && postJson.start_date && postJson.wrap_date) &&
        //     (postJson.gl_code.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.sag_id.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.name.length <= sizeLimits.addressCharacterLimit) &&
        //     (postJson.aka.length === 0 ? true : postJson.aka.every(aka =>
        //         (aka.aka.length <= sizeLimits.nameCharacterLimit))) &&
        //     (new Date(postJson.start_date) < new Date(postJson.wrap_date))
        // );
        return submit;
    }

    render() {
        // const { classes } = this.props;
        // let isLoading = this.state.isProductionDetails || this.state.isFetchingFeatureTypes || this.state.isFetchingDistributionTypes
        //     || this.state.isFetchingIntendedMarket
        return (
            // <div>
            //     <span>new production</span>
            // </div>
            <div >
                <MDBContainer className="newProjectLocationContainer">
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Location Name"
                                isMandatory={true}
                                //                                         limit={sizeLimits.nameCharacterLimit}
                                showMandatory={this.state.postInitiated}
                                mandatoryWarning={messages.mandatoryWarning}
                                //                                         limitWarning={messages.exceed50CharacterWarning || ""}
                                value={this.state.newLocationList?.location_name || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('location_name', e.target.value)
                                }}
                                placeholder={"Location Name"}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={3} className="fromDate">
                            <DateField
                                label={"From-To"}
                                // className="date"
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated}
                                // mandatoryWarning={messages.mandatoryWarning}
                                placeholder={"MM/DD/YYYY"}
                                value={this.state.newLocationList?.from_date || ""}
                                onChange={(e) => this.handleGeneralEdit('from_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="toDateField">
                            <DateField
                                placeholder={"MM/DD/YYYY"}
                                value={this.state.newLocationList?.to_date || ""}
                                showErrorBorder={this.state.newLocationList?.from_date > this.state.newLocationList?.to_date ? true : false}
                                onChange={(e) => this.handleGeneralEdit('to_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={5}>
                            <BasicTextField
                                label="Phone"
                                value={this.state.newLocationList?.phone || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('phone', formatPhoneNumbers(e.target.value))
                                }}
                                placeholder={"(___) ___-____"}
                            />
                        </MDBCol>
                        <MDBCol md={5}>
                            <BasicTextField
                                label="Email"
                                value={this.state.newLocationList?.email || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('email', e.target.value)
                                }}
                                fieldValid={this.state.fieldValid === false ? true : false}
                                inValidInput={"Please enter a valid email"}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6} className="address">
                            <BasicTextArea
                                label={"Address"}
                                value={this.state.newLocationList?.address || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('address', e.target.value)
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="city">
                        <MDBCol md={5}>
                            <BasicTextField
                                value={this.state.newLocationList?.city || ""}
                                onChange={(e) => {
                                    this.handleGeneralEdit('city', e.target.value)
                                }}
                                placeholder={"City"}
                            />
                        </MDBCol>
                        <MDBCol md={5}>
                            {
                                this.state?.newLocationList?.country?.value === 'United States' || this.state?.newLocationList?.country?.value === 'US' || this.state?.newLocationList?.country?.value === 'USA' || !this.state?.newLocationList?.country?.value ?
                                    <SearchSelectField
                                        id={"state"}
                                        // label={"Episode(s)"}
                                        value={this.state.newLocationList?.state || []}
                                        options={this.state?.stateList || []}
                                        // limitTags={4}
                                        onChange={(e, value) => this.handleGeneralEdit('state', value)}
                                        // multiple={true}
                                        width={'100%'}
                                        placeholder={"State"}
                                    />
                                    // <SelectField
                                    //     placeHolderText={"State"}
                                    //     value={this.state.newLocationList?.state || ""}
                                    //     options={this.state.stateList || []}
                                    //     onChange={(e) => {
                                    //         this.handleGeneralEdit('state', e.target.value)
                                    //     }}
                                    // />
                                    :
                                    <BasicTextField id="state"
                                        placeholder={"State"}
                                        value={this.state.newLocationList?.state}
                                        onChange={(e) => this.handleGeneralEdit('state', e.target.value)}
                                    />
                            }
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="postal">
                        <MDBCol md={5}>
                            <BasicTextField
                                value={this.state.newLocationList?.postal_code || ""}
                                // onChange={(e) => {
                                //     this.handleGeneralEdit('postal_code', e.target.value)
                                // }}
                                onChange={(e) => (this.state.newLocationList?.country?.value == 'United States' || this.state.newLocationList?.country?.value == 'USA' || this.state.newLocationList?.country?.value == 'US') ? this.handleGeneralEdit('postal_code', formatZipCode(e.target.value)) :
                                    this.handleGeneralEdit('postal_code', e.target.value)}
                                placeholder={"Postal Code"}
                                inValidInput={messages.invalidInput}
                                fieldValid={this.state.newLocationList?.postal_code ? ((this.state.newLocationList?.country?.value == 'United States' || this.state.newLocationList?.country?.value == 'USA' || this.state.newLocationList?.country?.value == 'US') ? (this.state.newLocationList?.postal_code?.length > 9 ? !checkZipCode(this.state.newLocationList?.postal_code) ? true : false : false) : false) : false}
                            />
                        </MDBCol>
                        <MDBCol md={5}>
                            <SearchSelectField
                                id={"country"}
                                // label={"Episode(s)"}
                                value={this.state.newLocationList?.country || []}
                                options={this.state?.countryList || []}
                                // limitTags={4}
                                onChange={(e, value) => this.handleGeneralEdit('country', value)}
                                // multiple={true}
                                width={'100%'}
                                placeholder={"Country"}
                            />
                            {/* <SelectField
                                value={this.state.newLocationList?.country || ""}
                                placeHolderText={"Country"}
                                options={this.state.countryList}
                                onChange={(e) => {
                                    this.handleGeneralEdit('country', e.target.value)
                                }}
                                placeholder={"Country"}
                            /> */}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <BasicTextArea label={"Comments"}
                            value={this.state.newLocationList?.comments || ""}
                            onChange={(e) => {
                                this.handleGeneralEdit('comments', e.target.value)
                            }}
                        />
                    </MDBRow>
                    <MDBRow className='justify-content-end topMargin'>
                        <MDBCol md={2}>
                            <BasicButton
                                // text={"Add"}
                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} />
                                    : this.props?.mode == 'edit' ? "Save" : "Create"}
                                type="inline"
                                onClick={() => this.handleSubmit()}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                variant="outlined"
                                text="Cancel"
                                onClick={() => this.props?.onClose()}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(AddProjectLocation);
