import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../../Common/Helper';
class CastWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            setEpisode: false,
            postInitiated: false,
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }


    handleSubmitCastWrap = () => {
        this.setState({ postInitiated: true });
        let { selectSeason, selectEpisode, selectProduction } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;

        if (!production_id || !ps_id) {
            this.setState({ isPosting: false })
            return;
        }
        let payload = {
            production_id,
            ps_id,
            episode_id,
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastWrap">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            onChangeCallBack={this.onChangeCallBack}
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={false}
                            postInitiated={this.state.postInitiated} />
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.handleSubmitCastWrap}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default CastWrap;