export const userConfigRecords = {
    headings: [
        {
            headingLabel: "User Name",
            width: "15%"
        },
        {
            headingLabel: "Role",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Title",
            width: "15%"
        },
        {
            headingLabel: "Login Email",
            width: "15%"
        }, {
            headingLabel: "Phone",
            width: "10%"
        }, {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: " ",
            width: "10%"
        },
    ],
    dataNodes: ["user_name", "role_name", "title", "login_email", "phone", "status", "Edit User"],
    primaryKey: "user_id",
    buttonWithText: ["Edit User"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};