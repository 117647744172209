import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import TablePagination from "@material-ui/core/TablePagination";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import TableComponent from '../../components/SharedComponents/Table';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { handlePaginationList } from "../../Common/TableHelper";
import { companyAnimationList } from '../../../constants/NavList';
import { withUserContext } from '../../../contexts/UserContext';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { searchCompanyConfig, companyFilterJson, companySortByConfig } from './Config';
import SearchFilters from './SearchFilters';
import './Search.scss';

class SearchCompanyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            config: searchCompanyConfig,
            filterValues: JSON.parse(JSON.stringify({ ...companyFilterJson })),
            rowsPerPage: 10,
            page: 0,
            sortBy: "Date Created",
            isSortByAsc: false,
            sortCount: 0,
            companyFilters: [],
            clearFilter: false,
            selectedSort: {
                value: 1,
                label: 'Date Created',
                sortBy: 'Date Created'
            },
            sortOrder: 'DESC',
            openCompanyModal: false,
        }
    }

    componentDidMount() {
        this.getCompanyDetails();
        this.getLookupData('COMPANY_TYPE', 'companyTypeOptions')
    }

    getCompanyDetails = () => {
        this.setState({ isFetchingCompanyList: true })
        let payload = { ...this.state.filterValues };
        payload['order_by_clause'] = this.state.selectedSort?.sortBy;
        payload['order_by'] = this.state.isSortByAsc === true ? 'ASC' : 'DESC';
        payload['page_count'] = this.state.rowsPerPage;
        payload['page_no'] = this.state.page + 1;
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/animationGetSearchCompany?input=${btoa(JSON.stringify(payload))}`,
            this.props.userContext.active_tenant.tenant_id, 1)
            .then(response => {
                let tableData = response?.data?.records || [];
                this.setState({
                    list: handlePaginationList(0, this.state.rowsPerPage, tableData),
                    isFetchingCompanyList: false,
                    companyCount: response?.data?.query_record_count
                })
            },
                (err) => {
                    console.log("Error in fetching performers:", err)
                    this.setState({ isFetchingCompanyList: false })
                })
    }

    getLookupData = (entity, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));
                this.setState({ [optionName]: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getEntitySearch = (entity, optionName, searchString = null, showId = null) => {
        let url = `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`
        CasterService.getData(Constants.CasterServiceBaseUrl + url,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data
                if (entity === 'REPRESENTATIVE') {
                    formattedList = formattedList?.map(item => ({ value: item.representative_id, text: item.representative_name }));
                } else if (entity === 'TALENT') {
                    formattedList = formattedList?.map(item => ({ value: item.talent_id, text: item.talent_name }));
                }
                this.setState({ [optionName]: formattedList })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleFilterOnChange = (filterField, filterName, value) => {
        let companyTypeName = this.state.companyTypeOptions?.find(item => item.value === value)?.label;
        let talentName = this.state.coveredTalentOptions?.find(item => item.value === value)?.text;
        let repName = this.state.representativeOptions?.find(item => item.value === value)?.text;
        this.setState((prevState) => ({
            filterValues: {
                ...prevState.filterValues,
                [filterField]: filterField === 'company_type' ? companyTypeName : value,
            },
        }), () => {
            let companyFilters = [...this.state.companyFilters];
            let filterValue = {};
            filterValue['filter'] = filterField;
            let filteredCompany = companyFilters?.find(item => item.filter === filterField);
            if (filteredCompany) {
                companyFilters?.map(item => {
                    if (item?.filter === filterField) {
                        item.value = filterField === 'company_type' ? (filterName + ': ' + companyTypeName) :
                            filterField === 'talent_id' ? (filterName + ': ' + talentName) :
                                filterField === 'representative_id' ? (filterName + ': ' + repName) :
                                    filterName + ': ' + value;
                    }
                    return item;
                })
            } else {
                filterValue['value'] = filterField === 'company_type' ? (filterName + ': ' + companyTypeName) :
                    filterField === 'talent_id' ? (filterName + ': ' + talentName) :
                        filterField === 'representative_id' ? (filterName + ': ' + repName) :
                            filterName + ': ' + value;
            }
            if (filterValue?.value) {
                companyFilters?.push(filterValue);
            }
            this.setState({ companyFilters: companyFilters })
            this.getCompanyDetails();
        })
    }

    handleFilterDelete = (filterIndex, filter) => {
        let companyFilters = [...this.state.companyFilters];
        companyFilters = companyFilters.filter(function (item) {
            return item?.filter !== filter
        })
        let filterValues = { ...this.state.filterValues }
        filterValues[filter] = null;
        this.setState({
            companyFilters: companyFilters,
            filterValues: filterValues,
            deletedFilter: filter
        }, () => {
            this.getCompanyDetails();
        })
    }

    handleClearCriteria = () => {
        this.setState({
            companyFilters: [],
            filterValues: JSON.parse(JSON.stringify({ ...companyFilterJson })),
            clearFilter: true
        }, () => {
            this.getCompanyDetails()
        })
    }

    handleSetClearFilter = () => {
        this.setState({ clearFilter: false })
    }

    functions = {
        handleHideCompanyPopup: () => {
            this.setState({ openCompanyModal: false });
        },
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getCompanyDetails();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getCompanyDetails();
                });
                break;
        }
    }

    navigationCallback = (dataItem, node) => {
        this.setState({ openCompanyModal: true, selectedCompanyId: dataItem.company_id })
    }

    handleSortClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSortClose = (selectedSort = null) => {
        this.setState({
            anchorEl: null,
            selectedSort: selectedSort,
            sortBy: selectedSort?.sortBy
        }, () => {
            this.getCompanyDetails()
        })
    }

    handleSortDesc = () => {
        this.setState({
            sortBy: 'Company',
            isSortByAsc: false,
            sortOrder: 'DESC'
        }, () => {
            this.getCompanyDetails();
        })
    }

    handleSortAsc = () => {
        this.setState({
            sortBy: 'Company',
            isSortByAsc: true,
            sortOrder: 'ASC'
        }, () => {
            this.getCompanyDetails();
        })
    }


    render() {
        return (
            <MDBContainer fluid className="searchCompanyContainer">
                <MDBRow>
                    <MDBCol>
                        <span className="searchCompanyHeading">
                            {'Search Companies'}
                        </span>
                    </MDBCol>
                    {this.props?.userContext?.user_profile?.role_name === 'Admin' &&
                        <MDBCol className="company-profile-btn">
                            <BasicButton
                                variant="contained"
                                type="inline"
                                className="outlined-btn"
                                icon={"plus-circle"}
                                text="New Company Profile"
                                onClick={() => this.setState({ openCompanyModal: true, selectedCompanyId: null })}
                            />
                        </MDBCol>
                    }
                </MDBRow>
                <MDBRow>
                    <span className="helper-text">
                        Search for Company profiles. To add a filter, either select a value, or type in a field below and click ENTER.
                    </span>
                </MDBRow>
                <MDBRow className="search-filters">
                    <SearchFilters
                        isCompany={true}
                        handleFilterOnChange={this.handleFilterOnChange}
                        getEntitySearch={this.getEntitySearch}
                        handleSetClearFilter={this.handleSetClearFilter}
                        companyTypeOptions={this.state.companyTypeOptions}
                        representativeOptions={this.state.representativeOptions}
                        coveredTalentOptions={this.state.coveredTalentOptions}
                        clearFilter={this.state.clearFilter}
                        deletedFilter={this.state.deletedFilter}
                    />
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBRow className="companyTableContainer">
                            <MDBCol md={8} className="margin-right">
                                {this.state.companyFilters?.length > 0 ?
                                    <div className="filter-section">
                                        <div>
                                            {this.state.companyFilters?.map((item, index) => (
                                                <Chip
                                                    label={item?.value}
                                                    onDelete={() => this.handleFilterDelete(index, item?.filter)}
                                                />
                                            ))}
                                        </div>
                                        <div className="clear-criteria-btn">
                                            <BasicButton
                                                variant="outlined"
                                                text="Clear Filters"
                                                type="inline"
                                                onClick={this.handleClearCriteria}
                                            />
                                        </div>
                                    </div> : <p className="no-filters">No Filters added</p>
                                }
                            </MDBCol>
                            <MDBCol md={3}>
                                <div className="filter-section sort-section">
                                    <span className="sort-by-label">Sort By:</span>
                                    <div className="sort-by-menu">
                                        <Button
                                            aria-controls="sort-menu"
                                            aria-haspopup="true"
                                            onClick={this.handleSortClick}
                                            endIcon={<MDBIcon icon="chevron-right" />}
                                        >
                                            {this.state.selectedSort?.label || ''}
                                        </Button>
                                        <Menu
                                            id="sort-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => this.handleSortClose(this.state.selectedSort)}
                                        >
                                            {companySortByConfig?.map(item => (
                                                <MenuItem onClick={() => this.handleSortClose(item)}>
                                                    {item?.label}
                                                </MenuItem>

                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                {this.state.sortOrder === 'ASC' ?
                                    <MDBIcon fas icon="sort-alpha-down" onClick={this.handleSortDesc} /> :
                                    <MDBIcon fas icon="sort-alpha-up" onClick={this.handleSortAsc} />
                                }
                            </MDBCol>
                            <div className="search-company-table">
                                <TableComponent
                                    list={this.state.list || []}
                                    isLoading={this.state.isFetchingCompanyList}
                                    config={this.state.config}
                                    hyperLinkNavigationCallback={this.navigationCallback}
                                />
                            </div>
                            <TablePagination
                                component="div"
                                count={this.state.companyCount}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                page={this.state.page}
                                onChangePage={(e, page) => {
                                    this.setJson('page', page)
                                }}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={(e) => {
                                    this.setJson('pagePerRow', e.target.value)
                                }}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {this.state.openCompanyModal &&
                    <ModalComponent
                        navFuns={this.functions}
                        open={this.state.openCompanyModal}
                        handleClose={this.functions.handleHideCompanyPopup}
                        tabList={companyAnimationList}
                        headerText={"Company"}
                        objectType={'COMPANY'}
                        mode={"New"}
                        companyId={this.state.selectedCompanyId}
                        toggleRefresh={this.getCompanyDetails}
                    />
                }
            </MDBContainer >
        )
    }
}

export default withRouter(withUserContext(SearchCompanyContainer));