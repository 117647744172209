import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import "./showModal.scss";
import { withUserContext } from "../../../contexts/UserContext";
import messages from "../../Common/Messages.json";
import sizeLimits from "../../Common/SizeLimits.json";
import { validateAllPhone, validateEmail } from "../../Common/Helper";
import { initialShowDetails, productionCompanuConfig } from "./Config";
import { CircularProgress } from "@material-ui/core";
import { validateCharNumWithHyphenSlash } from "../../Common/Helper";
import BasicCheckbox from "../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import DateField from "../../components/SharedComponents/DateField/DateField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Popover from '@material-ui/core/Popover';
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class Show extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: initialShowDetails,
            prodCompanyDetails: productionCompanuConfig,
            prodCompanyOptions: [],
            unionOptions: [],
            postInitiated: false,
            isPosting: false,
            showSuccessMessage: false,
            showFailMessage: false,
        };
    }

    componentDidMount() {
        if (this.props?.showId || this.props?.mode === 'Edit') {
            this.getShowDetails();
        } else {
            this.getLookupData('SHOW_CONFIGURATION', 'showConfigOptions');
            this.getLookupData('SHOW_TYPE', 'showTypeOptions');
            this.getLookupData('UNION', 'unionOptions');
            this.getLookupData('SHOW_STATUS', 'statusOptions')
            this.getProductionCompanyDetails('PRODUCTION_COMPANY', 'productionCompanyOptions')
        }
        this.getLookupData('COUNTRY', 'countryOptions');
        this.getLookupData('STATE', 'stateOptions');
    }

    getShowDetails = () => {
        this.setState({ isFetchingShowDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetShow?showId=${this.props?.showId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({
                    showDetails: response?.data[0],
                    isFetchingShowDetails: false
                }, () => {
                    this.getLookupData('SHOW_CONFIGURATION', 'showConfigOptions');
                    this.getLookupData('SHOW_TYPE', 'showTypeOptions');
                    this.getLookupData('UNION', 'unionOptions');
                    this.getLookupData('SHOW_STATUS', 'statusOptions')
                    this.getProductionCompanyDetails('PRODUCTION_COMPANY', 'productionCompanyOptions')
                })
            },
                (err) => {
                    console.log('Error is fetching Show' + err);
                    this.setState({ isFetchingShowDetails: false })
                });
    }

    getProductionCompanyDetails = (entity, optionName, prodCompanyValue = null, updatedProdCompany = false) => {
        let url = `/animationGetEntitySearch?entity=${entity}&searchString=null`;
        CasterService.getData(Constants.CasterServiceBaseUrl + url,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data;
                formattedList = formattedList?.map(item =>
                    ({ value: item.production_companies_id, label: item.production_company_name })
                );
                this.setState({ [optionName]: formattedList }, () => {
                    if (this.props?.mode === 'Edit' && !updatedProdCompany) {
                        let showDetails = { ...this.state.showDetails }
                        showDetails.production_company = prodCompanyValue || this.state.productionCompanyOptions?.find(item => item.label === showDetails.production_company)?.value;
                        let prodCompanyDetails = response.data?.find(item => item.production_companies_id === (prodCompanyValue || showDetails.production_company));
                        if (prodCompanyDetails) {
                            prodCompanyDetails['address_1'] = prodCompanyDetails?.address1;
                            prodCompanyDetails.state = this.state.stateOptions?.find(item => item.label === prodCompanyDetails.state)?.value;
                            prodCompanyDetails.country = this.state.countryOptions?.find(item => item.label === prodCompanyDetails.country)?.value;
                            this.setState({ showDetails: showDetails, prodCompanyDetails: prodCompanyDetails })
                        }
                    }
                    if (updatedProdCompany) {
                        this.handleOnchange('production_company', prodCompanyValue);
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getLookupData = (entity, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data;
                formattedList = formattedList?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active }));
                this.setState({ [optionName]: formattedList }, () => {
                    if (this.props?.mode === 'Edit') {
                        let showDetails = { ...this.state.showDetails }
                        if (entity === 'SHOW_TYPE') {
                            showDetails.show_type = this.state.showTypeOptions?.find(item => item.label === showDetails.show_type)?.value;
                        } else if (entity === 'UNION') {
                            showDetails.union = this.state.unionOptions?.find(item => item.label === showDetails.union)?.value;
                        } else if (entity === 'SHOW_STATUS') {
                            showDetails.status = this.state.statusOptions?.find(item => item.label === showDetails.status)?.value;
                        }
                        this.setState({ showDetails: showDetails })
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleOnchange = (field, value) => {
        if (field === 'production_company') {
            this.getProductionCompanyDetails('PRODUCTION_COMPANY', 'productionCompanyOptions', value, false)
        }
        this.setState((prevState) => ({
            showDetails: {
                ...prevState.showDetails,
                [field]: value || null,
            },
            postInitiated: false
        }));
    };

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success":
                return this.setState({ showSuccessMessage: value });
            case "fail":
                return this.setState({ showFailMessage: value });
        }
    };

    canSubmit = () => {
        let showDetails = { ...this.state.showDetails }
        let canSubmit = true;
        canSubmit = (showDetails?.show_type && showDetails?.status && showDetails?.show_name &&
            showDetails?.production_company && showDetails?.union
        )
        // if (showDetails?.show_configuration === 'Episodic') {
        //     canSubmit = (showDetails?.show_type && showDetails?.status && showDetails?.show_name &&
        //         showDetails?.production_company && showDetails?.union
        //     )
        // } else {
        //     canSubmit = (showDetails?.show_type && showDetails?.status && showDetails?.show_name &&
        //         showDetails?.production_company && showDetails?.union
        //         //  && showDetails?.sap_code && showDetails?.mpm
        //     )
        // }
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let payload = JSON.parse(JSON.stringify({ ...this.state?.showDetails }));
        payload.union = this.state.unionOptions?.find(item => item.value === payload.union)?.label;
        payload.status = this.state.statusOptions?.find(item => item.value === payload.status)?.label;
        payload.show_type = this.state.showTypeOptions?.find(item => item?.value === payload.show_type)?.label;
        payload.production_company = this.state.productionCompanyOptions?.find(item => item?.value === payload.production_company)?.label;
        if (this.canSubmit()) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostShow`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.notificationComponent(true, "fail");
                    } else {
                        this.props.notificationComponent(true, "success", "refresh-show");
                        this.setState({
                            isPosting: false,
                            postInitiated: false
                        })
                    }
                },
                    (err) => {
                        console.log('Error is submitting Show' + err);
                        this.props.notificationComponent(true, "fail");
                        this.setState({ isPosting: false, postInitiated: false })
                    });
        } else {
            console.log("Error in submitting show")
        }
    };

    handleOpenProdCompPopup = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleCloseProdCompPopup = () => {
        this.setState({ anchorEl: null })
    }

    handleProdCompOnchange = (field, value) => {
        this.setState((prevState) => ({
            prodCompanyDetails: {
                ...prevState.prodCompanyDetails,
                [field]: value,
            },
            postInitiated: false
        }));
    }

    handleProdCompSubmit = () => {
        this.setState({ postInitiated: true, isPostingProdCompany: true })
        let payload = JSON.parse(JSON.stringify({ ...this.state.prodCompanyDetails }));
        payload.production_companies_id = this.state.showDetails.production_company;
        payload.state = this.state.stateOptions?.find(item => item.value === payload.state)?.label;
        payload.country = this.state.countryOptions?.find(item => item.value === payload.country)?.label;
        // if (this.canSubmitProdCompany()) {
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostProductionCompany`,
            payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPostingProdCompany: false })
                } else {
                    this.setState({
                        isPostingProdCompany: false,
                        postInitiated: false,
                        anchorEl: null
                    })
                    this.getProductionCompanyDetails('PRODUCTION_COMPANY', 'productionCompanyOptions', response?.data?.[0]?.production_companies_id, true)
                }
            },
                (err) => {
                    console.log('Error is submitting Show' + err);
                    this.setState({ isPostingProdCompany: false, postInitiated: false })
                });
        // } else {
        //     console.log("Error in submitting show")
        // }
    }

    handleCloseProdComp = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        let open = Boolean(this.state.anchorEl);
        let id = open ? 'production-comp-popover' : undefined;
        return (
            <div>
                {this.state.isFetchingShowDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBContainer className="NewShowModalContainer" id="header">
                        <MDBRow className="mt-1">
                            <MDBCol md={4}>
                                {this.props?.mode === 'Edit' ?
                                    <>
                                        <BasicLabel text="Show Configration" />
                                        <p className="show-config">{this.state.showDetails?.show_configuration}</p>
                                    </> :
                                    <SelectField
                                        label={"Show Configration"}
                                        isMandatory={true}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        showMandatory={this.state.postInitiated || false}
                                        value={this.state.showDetails.show_configuration}
                                        options={this.state.showConfigOptions || []}
                                        onChange={(e) => {
                                            this.handleOnchange("show_configuration", e.target.value);
                                        }}
                                    />
                                }
                            </MDBCol>
                            <MDBCol md={4}>
                                <SelectField
                                    label={"Show Type"}
                                    isMandatory={true}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.showDetails.show_type}
                                    options={this.state.showTypeOptions || []}
                                    onChange={(e) => {
                                        this.handleOnchange("show_type", e.target.value);
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <SelectField
                                    label={"Status"}
                                    value={this.state.showDetails.status}
                                    options={this.state.statusOptions || []}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => {
                                        this.handleOnchange("status", e.target.value);
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    className="text"
                                    label={"Show Name"}
                                    isMandatory={true}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.showDetails.show_name}
                                    onChange={(e) =>
                                        this.handleOnchange("show_name", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <SelectField
                                    label="Production Company"
                                    isMandatory={true}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    showMandatory={this.state.postInitiated || false}
                                    options={this.state.productionCompanyOptions || []}
                                    value={this.state.showDetails.production_company}
                                    onChange={(e) =>
                                        this.handleOnchange("production_company", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol className="pen-icon">
                                <MDBIcon icon="pen" onClick={this.handleOpenProdCompPopup} />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleCloseProdCompPopup}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className="production-comp-popUp">
                                        <MDBRow className="prod-company-field">
                                            <MDBCol>
                                                <BasicTextField
                                                    value={this.state.prodCompanyDetails?.production_company_name || ""}
                                                    onChange={(e) => { this.handleProdCompOnchange("production_company_name", e.target.value) }}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicTextArea
                                                    value={this.state.prodCompanyDetails?.address_1 || ""}
                                                    onChange={(e) => { this.handleProdCompOnchange("address_1", e.target.value) }}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicTextField
                                                    value={this.state.prodCompanyDetails?.city || ""}
                                                    onChange={(e) => { this.handleProdCompOnchange("city", e.target.value) }}
                                                />
                                            </MDBCol>
                                            <MDBCol>
                                                <SelectField
                                                    id={"state"}
                                                    value={this.state.prodCompanyDetails?.state || ""}
                                                    defaultMenuText={"- Select -"}
                                                    options={this.state.stateOptions}
                                                    onChange={(e) =>
                                                        this.handleProdCompOnchange("state", e.target.value)
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicTextField
                                                    value={this.state.prodCompanyDetails?.zip || ""}
                                                    onChange={(e) => { this.handleProdCompOnchange("zip", e.target.value) }}
                                                />
                                            </MDBCol>
                                            <MDBCol>
                                                <SelectField
                                                    id={"state"}
                                                    value={this.state.prodCompanyDetails?.country || ""}
                                                    defaultMenuText={"- Select -"}
                                                    options={this.state.countryOptions}
                                                    onChange={(e) =>
                                                        this.handleProdCompOnchange("country", e.target.value)
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicTextField
                                                    placeholder="(xxx)xxx-xxxx"
                                                    fieldValid={
                                                        this.state.prodCompanyDetails?.phone
                                                            ? !validateAllPhone(this.state.prodCompanyDetails?.phone)
                                                            : false
                                                    }
                                                    inValidInput={"Please enter valid phone"}
                                                    value={this.state.prodCompanyDetails?.phone || ""}
                                                    onChange={(e) =>
                                                        this.handleProdCompOnchange("phone", e.target.value)
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="modal-btn">
                                            <MDBCol md={8}></MDBCol>
                                            <MDBCol md={`${!this.state.isPostingProdCompany ? 1 : 2}`} className="p-0">
                                                <BasicButton
                                                    className={"modal-btn-save"}
                                                    variant="contained"
                                                    // text=" "
                                                    text={this.state.isPostingProdCompany ?
                                                        <CircularProgress color="inherit" size={18} /> : " "
                                                    }
                                                    icon={!this.state.isPostingProdCompany ? "save" : ''}
                                                    onClick={this.handleProdCompSubmit}
                                                />
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicButton
                                                    className={"modal-cancel-btn"}
                                                    variant="outlined"
                                                    text=" "
                                                    icon={"times"}
                                                    onClick={this.handleCloseProdComp}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </Popover>
                            </MDBCol>
                            <MDBCol md={5}>
                                <SelectField
                                    label="Union"
                                    isMandatory={true}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    showMandatory={this.state.postInitiated || false}
                                    options={this.state.unionOptions || []}
                                    value={this.state.showDetails.union}
                                    onChange={(e) =>
                                        this.handleOnchange("union", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        {/* {this.state.showDetails?.show_configuration !== "Episodic" &&
                                <MDBRow>
                                    <MDBCol md={5}>
                                        <BasicTextField
                                            label="SAP/GL Code"
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            value={this.state.showDetails?.sap_code || ""}
                                            limit={sizeLimits.nameCharacterLimit}
                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                            onChange={(e) => {
                                                validateCharNumWithHyphenSlash(e.target.value) &&
                                                    this.handleOnchange(
                                                        "sap_code",
                                                        e.target.value.length > 0 ? e.target.value : null
                                                    );
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}>
                                        <BasicTextField
                                            label="MPM#"
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            value={this.state.showDetails?.mpm || ""}
                                            limit={sizeLimits.nameCharacterLimit}
                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                            onChange={(e) => {
                                                validateCharNumWithHyphenSlash(e.target.value) &&
                                                    this.handleOnchange(
                                                        "mpm",
                                                        e.target.value.length > 0 ? e.target.value : null
                                                    );
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            } */}
                        {/* <MDBRow>
                            <MDBCol md={3}>
                                <BasicTextField
                                    className="text"
                                    label={"Signatory"}
                                    value={this.state.showDetails.signatory}
                                    onChange={(e) =>
                                        this.handleOnchange("signatory", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow> */}
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    className="text"
                                    // placeholder={"eg. The Best Show Ever"}
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    label="Signatory Name"
                                    value={this.state.showDetails?.signatory_name}
                                    onChange={(e) =>
                                        this.handleOnchange("signatory_name", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicTextField
                                    label={"Signatory Email"}
                                    value={this.state.showDetails?.signatory_email || ""}
                                    fieldValid={!validateEmail(this.state.showDetails?.signatory_email) && this.state.showDetails?.signatory_email}
                                    inValidInput={messages.invalidInput}
                                    onChange={(e) => this.handleOnchange('signatory_email', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    className="text"
                                    // placeholder={"eg. The Best Show Ever"}
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    label="Production Rep Name"
                                    value={this.state.showDetails?.production_rep_name}
                                    onChange={(e) =>
                                        this.handleOnchange("production_rep_name", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicTextField
                                    label={"Production Rep Email"}
                                    value={this.state.showDetails?.production_rep_email || ""}
                                    fieldValid={!validateEmail(this.state.showDetails?.production_rep_email) && this.state.showDetails?.production_rep_email}
                                    inValidInput={messages.invalidInput}
                                    onChange={(e) => this.handleOnchange('production_rep_email', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    className="text"
                                    // placeholder={"eg. The Best Show Ever"}
                                    // isMandatory={true}
                                    // showMandatory={this.state.postInitiated}
                                    // mandatoryWarning={messages.mandatoryWarning}
                                    label="Executive Name "
                                    value={this.state.showDetails?.executive_name}
                                    onChange={(e) =>
                                        this.handleOnchange("executive_name", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicTextField
                                    label={"Executive Email"}
                                    value={this.state.showDetails?.executive_email || ""}
                                    fieldValid={!validateEmail(this.state.showDetails?.executive_email) && this.state.showDetails?.executive_email}
                                    inValidInput={messages.invalidInput}
                                    onChange={(e) => this.handleOnchange('executive_email', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    className="text"
                                    label={"Voice Director"}
                                    value={this.state.showDetails.voice_director}
                                    onChange={(e) =>
                                        this.handleOnchange("voice_director", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={5}>
                                <BasicTextField
                                    className="text"
                                    label={"Casted By"}
                                    value={this.state.showDetails.casted_by}
                                    onChange={(e) =>
                                        this.handleOnchange("casted_by", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                                <MDBCol colgroup={3}>
                                    <BasicTextArea
                                        label={"Show Notes"}
                                        value={this.state.showDetails.show_notes}
                                        onChange={(e) =>
                                            this.handleOnchange("show_notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                        <MDBRow className="p-2">
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    text={
                                        this.state.isPosting ? (
                                            <CircularProgress color="inherit" size={18} />
                                        ) : "Save"
                                    }
                                    icon={"Create"}
                                    disabled={!this.canSubmit()}
                                    onClick={() => this.handleSubmit()}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={() => {
                                        this.props?.onClose();
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                )
                }
            </div>
        );
    }
}
export default withUserContext(Show);
