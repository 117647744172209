import React from 'react';
import TableComponent from '../SharedComponents/Table';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import {WorkWeekConfig} from'./config'
import { getFormattedDate } from '../../../caster-feature-old/Common/Helper';
import MessageModal from '../SharedComponents/MessageModal';
import './WorkWeekModal.scss';

class WorkWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  
      allWorkWeekData:[],
      config: JSON.parse(JSON.stringify({ ...WorkWeekConfig})),
      isFetchingWorkWeekData:false,
      openNotification:null,
      severity:null,
      isDelete:null,
      deleteItem:null,
      editWorkWeekData:null,
      daysSum:0,
    }
  }

  componentDidMount() {
     this.getWorkWeekData(true)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps?.reloadWorkWeeks !== this.props?.reloadWorkWeeks) {
      this.getWorkWeekData(true)
    }
  }

  getWorkWeekData(loader=false) {
    this.setState({isFetchingWorkWeekData:loader})
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProjectWorkWeek?project_id=${this.props?.project_id}`).then((response) => {
      if(response?.data?.error) {
         this.setState({openNotification: 'Work week Data Load Fail', severity:'error'})
      }
      else {
          let sum = 0
          let formattedList = response?.data?.length > 0 ? response?.data?.map(item => {
            let newItem = {...item}
            sum+= +item?.days
            newItem.start_date = item?.start_date ? getFormattedDate(item?.start_date?.replaceAll('-','/')) : null
            newItem.finish_date = item?.finish_date ? getFormattedDate(item?.finish_date.replaceAll('-','/')) : null
            return newItem
          }): []
          this.props?.updateAllWorkWeek(formattedList)
          this.setState({allWorkWeekData: formattedList, isFetchingWorkWeekData:false, isDelete:null, daysSum:sum})
      }
  },
      (err) => {this.setState({openNotification: 'Work week Data Load Fail', severity:'error'})
      })
  }


 deleteWorkWeek  = (deleteData) => {
  this.setState({isDelete : deleteData?.project_work_week_id, deleteItem : null })
  CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureProjectWorkWeek`, deleteData)
  .then(response => {
      if(response?.data?.error) {
        this.setState({openNotification: response?.data?.message || 'Deletion Failed', severity:'error', isSubmit:null})
      } else {
        this.setState({openNotification: 'Deleted successfully', severity:'success'})
        this.getWorkWeekData()
      }
  },
  (err) => {
    this.setState({openNotification: 'Saving changes failed', severity:'error', isSubmit:null})
  }
  )
 }

 handleWorkWeekDelete = (val, item) => {
    this.setState({deleteItem: item})
 }

 editCallback = (id,dataItem) => {
    this.setState({editWorkWeekData:dataItem})
 }

  render() {
    return (
        <div className='casterFeatureWorkWeekSection'>
          <MessageModal
            open={this.state.deleteItem ? true : false}
            title={'Delete Work Week'}
            message={'Are you sure to delete this work week?, please click OK to confirm'}
            hideCancel={false}
            primaryButtonText={"OK"}
            secondaryButtonText={"Cancel"}
            handleClose={() => this.setState({deleteItem:null})}
            onConfirm={(e) => {
                let deleteData = {...this.state.deleteItem}
                deleteData.is_delete = '1'
                this.deleteWorkWeek(deleteData)
            }}/>
          <NotificationComponent 
            open={this.state.openNotification ? true : false} 
            message={this.state.openNotification || ""}
            severity={this.state.severity || ""}
            handleResetNotify={() => this.setState({openNotification:null,severity:null})} />
          <TableComponent 
            id={'feactureCastingWorkWeekSection'}
            list ={this.state.allWorkWeekData}
            config={this.state.config}
            isLoading={this.state.isFetchingWorkWeekData}
            postInitiated={this.state.isDelete}
            handleDelCallBack = {(val, item) => this.handleWorkWeekDelete(val, item)}
            editCallback={this.editCallback.bind(this)}
          />
          {this.state.allWorkWeekData.length > 0 && <div className='days'>{this.state.daysSum}</div>}
          {this.state?.editWorkWeekData &&
          <MainViewModalComponent
            modalName='Work Week'
            handleClose={() => { this.setState({ editWorkWeekData: null }) }}
            mode={'edit'}
            open={this.state.editWorkWeekData ? true : false}
            headerText={"Work Week"}
            allWorkWeeks={this.state.allWorkWeekData?.filter(item => item?.project_work_week_id !== this.state.editWorkWeekData?.project_work_week_id)}
            workWeekData={this.state.editWorkWeekData}
            toggleRefreshPage={this.getWorkWeekData.bind(this)}
          />}
        </div>
    )
  }
}

export default WorkWeek;
