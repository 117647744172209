import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getFormattedDate } from '../../../Common/Helper';
import BasicLabel from '../BasicLabel/BasicLabel';
import './DateField.scss';

export default class DateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatoryWarning: "Please complete this mandatory field",
            dateError: "Cannot be less than Start Date"
        }
    }
    onChange = (e) => {
        console.log("e.target.value", e.target.value);
        if (this.props?.id !== null && this.props?.id !== undefined) {
            let el = document.getElementById(this.props?.id);
            if (e.target.value === '') {
                el.classList.add("emptyValue");
            } else {
                el.classList.remove("emptyValue");
            }
            this.props?.onChange(e);
        } else {
            this.props?.onChange(e);
        }

    }
    componentDidMount() {
        if (this.props?.id !== null && this.props?.id !== undefined) {
            let el = document.getElementById(this.props?.id);
            if (el?.value === '') {
                el?.classList?.add("emptyValue");
            } else {
                el?.classList?.remove("emptyValue");
            }
        }
    }
    render() {
        //var dateValue = this.props?.value ? getFormattedDate(this.props?.value, true) : null;
        let error = (this.props.showMandatory && !this.props?.value) || this.props.showErrorBorder || false;
        let showAsLabel = this.props.showAsLabel || false;
        console.log("in datefield");
        return (
            <div className={`${error ? "DateFieldContainer error" : "DateFieldContainer"}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{this.props.value || ""} </div> :
                    <> <TextField
                        type="date"
                        //InputProps={ this.props.checkInput ? {inputProps: { max: getFormattedDate(new Date, true)} } : false}
                        id={this.props?.id}
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        variant="outlined"
                        disabled={this.props?.disabled ? this.props?.disabled : false}
                        // value={dateValue || ""}
                        value={this.props?.value || ""}
                        onChange={this.onChange}
                        {...(this.props?.onKeyUp ? { onKeyUp: this.props?.onKeyUp } : {})}
                    />
                        {error &&
                            <div className="errorText">
                                {(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                                    this.props.showErrorBorder ? this.props?.invalidDateError ?
                                        this.props?.invalidDateError : this.state.dateError : ""
                                }
                            </div>
                        }
                    </>}
            </div>
        );
    }
}
