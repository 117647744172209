export const intialReportDetails ={
    show:null,
    season:null,
    episode:null,
    contract_end_date: null,
    contract_start_date: null,
    work_date: null,
    performer:null,
    start_date:null,
    account_details:null,
    contract_text:null,
    show_season_id:null,
    episode_id:null,
    performer_id:null,
    contract_id:null,
    show_id:null,
}