import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import { AddCircle } from '@material-ui/icons';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import './ESignManager.scss';
import * as Constants from "../../../../constants/constants";
import CasterService from "../../../../services/service";
import { initialCCConfig } from './Config';
import Modal from '../../../Common/MainViewModalLayout/Modal/Modal';
import { CircularProgress } from '@material-ui/core';
import { downloadUrl, validateEmail } from '../../../Common/Helper';
import { withUserContext } from "../../../../contexts/UserContext";
class ESignManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingEsignDetails: false,
            esignDetails: null,
            initialCCObject: JSON.parse(JSON.stringify({ ...initialCCConfig })),
            openEditRider: false,
            rider_type: null,
            sentForSigning: false,
            savingEsignDetails: false,
            ccEmailValid: false
        }
    }
    componentDidMount = () => {
        this.getEsignDetails();
    }

    getEsignDetails = () => {
        this.setState({ loadingEsignDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureEsignDetails?performer_deal_contract_id=${this.props?.performer_deal_contract_id}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    this.setState({ loadingEsignDetails: false })
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data[0]
                    this.setState({ esignDetails: formattedList, sentForSigning: formattedList?.contract_adobe_status === "UNSIGNED" ? true : false, loadingEsignDetails: false })
                    console.log("Data:", response?.data[0])
                }
            }, err => {
                this.setState({ loadingEsignDetails: false })
                console.log("error from lookup")
            })
    }

    onFieldChange = (field, value) => {
        this.setState((prevState) => ({
            initialCCObject: {
                ...prevState?.initialCCObject,
                [field]: value
            },
            ccEmailValid: field === "object_email" ? false : this.state.ccEmailValid
        }))
    }

    addCCRecipients = () => {
        if (this.state.ccEmailValid != true ) {
            let esignDetails = this.state.esignDetails
            let ccObjLength = this.state.esignDetails.cc_recipients.length
            esignDetails.cc_recipients[ccObjLength] = this.state.initialCCObject
            this.setState({ esignDetails: esignDetails, initialCCObject: initialCCConfig })
        }
    }

    openEditRiderModal = (rider) => {
        let rider_type = rider.rider_name === "Custom Rider" ? "From Memos" : null
        this.setState({ openEditRider: true, rider_type: rider_type  })
    }

    closeEditRiderModal = () => {
        this.setState({ openEditRider: false})
    }

    onEsignSave = () => {
        this.setState({ savingEsignDetails: true })
        let esignDetails = this.state.esignDetails
        esignDetails.save_as_draft = 0,
        esignDetails.preview = 0,
        esignDetails.document_type = "Unsigned"
        esignDetails.project_id = this.props?.project_id
        console.log("Esign", esignDetails.esignDetails);
        let postJSON = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: btoa(JSON.stringify({ performer_deal_contract_id: this.state.esignDetails.performer_deal_contract_id, 
                template_name: this.props?.loanout_present ? this.state.esignDetails.loanout_template : this.state.esignDetails.contract_template, 
                filename:  this.state.esignDetails?.file_name+".pdf", 
                to_recipients: this.state.esignDetails?.to_recipients,  
                cc_recipients: this.state.esignDetails?.cc_recipients, 
                save_as_draft: 0,
                riders: this.state.esignDetails?.riders,
                url: this.state.esignDetails?.url,
                project_id: this.props?.project_id,
                document_type: "Unsigned",
                preview: 0
             })),
            encryptionType: 1
        }
        console.log("post",postJSON);
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                this.props?.handleClose()
                if (response?.statusCode == 200) {
                    let document_text = response?.body?.replace("\r\n\t", "")
                    let rider_info = this.state.rider_info ? this.state.rider_info : {}
                    rider_info.rider_text = document_text.toString()
                    this.props?.handleClose()
                    this.props?.getCompensationTabData()
                    this.setState({ rider_info: rider_info, savingEsignDetails: false, sentForSigning: true });
                }
            })
        ); 
    }

    removeRider = (rider_info) => {
        let esignDetails = this.state.esignDetails
        esignDetails.riders = esignDetails?.riders?.filter((item) => item?.performer_deal_contract_rider_id !== rider_info?.performer_deal_contract_rider_id)
        this.setState({ esignDetails: esignDetails })
    }

    downloadExecutedContract = () => {
        this.setState({ downloadingContract: true });
        let postJSON = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: btoa(JSON.stringify({ performer_deal_contract_id: this.props?.performer_deal_contract_id, 
                filename:  this.props?.file_name+'.pdf', 
                save_as_draft: 0,
                url: this.props?.boxURL,
                project_id: this.props?.project_id,
                preview: 1,
                contract_status: "Signed"
             })),
            encryptionType: 1
        }
        console.log("post",postJSON);
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    const filename = this.props?.file_name +'.pdf'
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(response?.body, decodedFile);
                    this.props?.handleClose()
                }
            })
        ); 
    }

    validateCCEmail = (email) => {
        let emailValid = validateEmail(email)
        this.setState({ ccEmailValid: !emailValid });
    }


    render() {
        let recipientsList = (this.props?.age !== 0 && this.props?.monthsAge) && this.props?.age > 18 ? this.state.esignDetails?.to_recipients?.filter(list => list.object_type !== "G") : this.state.esignDetails?.to_recipients
        return (
            <div className="EsignManagerModalContainer">
                {this.state.loadingEsignDetails ? <CircularProgress  color="inherit" size={20} /> : 
                <>
                <div className="contract-status-error">{this.state.esignDetails?.contract_adobe_status == null ? "Contract has not been sent" : ""}</div>
                {this.state.esignDetails?.contract_adobe_status === "SIGNED" ? null : <div>To Preview the contract to be sent, click the 'Contract PDF' icon in the Compensation Table</div>}
                {this.state.esignDetails?.contract_adobe_status === "SIGNED" || this.state.esignDetails?.contract_adobe_status === "UNSIGNED" ? <div className="contract-sent-status">Contract was sent for signing on {this.state.esignDetails?.signing_start} </div> : null}
                {this.state.esignDetails?.contract_adobe_status === "UNSIGNED" ? <div>To Cancel signing, use the Adobe console</div> : null}
                {this.state.esignDetails?.contract_adobe_status === "SIGNED" ? <div className="fully-signed-status">Contract was fully signed on {this.state.esignDetails?.signing_completed} </div> : null}
                <br />
                <div className="recipients-header"><b>Recipients</b></div>
                {this.state.esignDetails?.to_recipients?.map((item) => (
                    item?.object_type === "G" && ((this.props?.age != 0 && this.props?.monthsAge) && this.props?.age > 18) ? "" :
                     <div className="signers-list d-flex">
                        <div className="object_type">{item?.object_type === "P" ? "(1) Performer" : item?.object_type === "G" ? "Guardian" : item?.object_type === "S" ? "(2) Signatory" : ""}<span className="minor-color">{item?.object_type === "P" && ((this.props?.age && this?.props?.age < 18) || (this.props?.monthsAge && this.props?.age === 0) && (this.props?.age != 0 && this.props?.monthsAge != 0)) ? " MINOR" : ""}</span></div>
                        <div className="object_name">{item?.object_name}</div>
                        <div className="object_email">{item?.object_email}</div>
                        <div className={item?.status === "Signer" ? "signer-status" : item?.status === "Does Not Sign" ? "doesnt-status" : item?.status === "Has not signed" ? "notsigned-status" : item?.status === "Signed" ? "signed-status" : null}>{item?.status}</div>
                    </div>
                ))}
                {this.state.esignDetails?.cc_recipients?.map((item) => (
                    <div className="cc-list d-flex">
                        <div className="object_type">{item?.object_type === "R" ? "Rep.(CC)" : "CC"}</div>
                        <div className="object_name">{item?.object_name}</div>
                        <div className="object_email">{item?.object_email}</div>
                        <div className="doesnt-status">Does Not Sign</div>
                    </div>
                ))}
                <br />
                <hr /> 
                { !this.state.sentForSigning && this.state.esignDetails?.contract_adobe_status !== "SIGNED" ? 
                <MDBRow>
                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                    <div className="cc-heading"><b>Add to CC Distribution</b></div>}
                    <div className="d-flex cc-container">
                        <div className="name-container">
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                            <BasicTextField 
                                label={" "}
                                placeholder={"Name"}
                                value={this.state.initialCCObject?.object_name}
                                onChange={(e) => this.onFieldChange("object_name", e.target.value)}
                            />}
                        </div>
                        <div className="email-container">
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                            <BasicTextField 
                                label=" "
                                placeholder={"Email"}
                                value={this.state.initialCCObject?.object_email}
                                onChange={(e) => this.onFieldChange("object_email", e.target.value)}
                                onBlur={() => this.state.initialCCObject?.object_email != '' ? this.validateCCEmail(this.state.initialCCObject?.object_email) : null}
                                fieldValid={this.state.ccEmailValid}
                                inValidInput="Invalid Email Address"
                            />}
                        </div>
                        <div className="add-circle">
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                            <AddCircle onClick = {() => this.addCCRecipients()}/>}
                        </div>
                    </div>
                </MDBRow> : null}
                <br />
                <h6>{this.state.sentForSigning ? "Contract Options" : "Review Contract Options"}</h6>
                <div className="contract-options">
                    <div><b>Contract</b></div>
                    <div>{this.state.esignDetails?.contract}</div>
                    <div className="rider-header d-flex">
                        <div className="attached-rider"><b>Attached Rider(s)</b></div>
                        <div className="loanout"><b>Loanout</b></div>
                    </div>
                    {this.state.esignDetails?.riders?.map((item) => (
                        <div className="rider-row d-flex ">
                            <div className="attached-rider">
                                {item?.rider_name} 
                                <span className="rider-pen">
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                                    <MDBIcon icon="pen" onClick={() => this.openEditRiderModal(item)} />}
                                    </span><span className="rider-times">
                                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                        <MDBIcon icon="times" onClick={() => this.removeRider(item)}/>}
                                        </span>
                            </div>
                            <div className="loanout">
                                Attached
                            </div>
                        </div>
                    ))}
                </div>
                {!this.state.sentForSigning ?
                <MDBRow>
                    <MDBCol md={this.state.esignDetails?.contract_adobe_status != "SIGNED" ? 9 : 9}></MDBCol>
                        {this.state.esignDetails?.contract_signed_date === null ? 
                        <>
                        <MDBCol md={2}>
                            <BasicButton
                                variant="contained"
                                type="inline"
                                text={this.state.savingEsignDetails ? <CircularProgress  color="inherit" size={20} /> : "Initiate Signing"}
                                onClick={() => this.onEsignSave()}
                                disabled={this.state.esignDetails?.contract === "N/A" || !(this.props?.age < 18 && this.props?.birth_date != null  ? this.state.esignDetails?.to_recipients?.length > 2 : recipientsList?.length == 2) || 
                                (this.state.esignDetails?.to_recipients?.find(item => item?.object_email === null && item?.object_type === "P") ? true : false) || 
                                (this.state.esignDetails?.to_recipients?.find(item => item?.object_email === null && item?.object_type === "S") ? true : false) || 
                                ((this.props?.age === 0 && this.props?.monthsAge) || this.props?.age < 18 ? (this.state.esignDetails?.to_recipients?.find(item => item?.object_type === "G" && (item?.object_email === "" || item?.object_name === null)) ? true : false) : false)}
                            />
                        </MDBCol>
                        <MDBCol md={1}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={this.props?.handleClose}
                            />
                        </MDBCol> 
                        </> : 
                            <MDBCol md={3}>
                                <BasicButton
                                    type="inline"
                                    variant="contained"
                                    icon="eye"
                                    text={this.state.downloadingContract ? <CircularProgress  color="inherit" size={20} /> : "Executed Contract"}
                                    onClick={() => this.downloadExecutedContract()}
                                />
                            </MDBCol> 
                        }
                    </MDBRow>: null} 
                    </>}
                    {
                        this.state.openEditRider &&
                        <Modal 
                            open={this.state.openEditRider}
                            modalName={"Edit Riders"}
                            headerText={this.state.rider_type === "From Memos" ? "Edit Rider" : "Edit Exhibit A Rider"}
                            mode="Both"
                            handleClose={() => this.closeEditRiderModal()}
                            performer_deal_id={this.props?.performer_deal_id}
                            performer_deal_contract_id={this.props?.performer_deal_contract_id}
                            loanout_present={this.props?.loanout_present}
                            contract_info={this.props?.contract_info}
                            rider_type={this.state.rider_type}
                            rider_info={this.props?.contract_info.riders}
                            getCompensationTabData={this.props?.getCompensationTabData}
                        />
                    }
            </div>
        )
    }

}
export default withUserContext(ESignManager);