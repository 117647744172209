import React from 'react';
import './AddCompanyRepresentive.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import messages from "../../../Common/Messages.json";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { CircularProgress } from '@material-ui/core';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';


class AddCompanyRepresentive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addRepresentiveData: {
                representative_id: null
            },
            representiveOptions: [],
            isSubmit: false,
            selectedRep: null
        };
    }

    handleOnchange(e, newValue, field) {
        this.setState((prevState) => ({
            addRepresentiveData: {
                ...prevState.addRepresentiveData,
                [field + '_id']: newValue?.value,
                [field + '_name']: newValue?.text
            },
        }))
    }


    componentDidMount = () => {
        this.getRepresentiveData("REPRESENTATIVE", "")
    }

    getRepresentiveData = (entity, searchString) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.representative_id,
                    text : item.representative_name,
                }));
                this.setState({
                    representiveOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getRepresentiveData method");
            });
    };

    canSubmit = () => {
        let repData = { ...this.state.addRepresentiveData }
        let canSubmit = false;
        canSubmit = (repData?.representative_id) ? true
            : false;

        return canSubmit;
    }

    getcompanyDetails = (searchKey, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetCompany?searchKey=${searchKey}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("get company data", response)
            },
                (err) => {
                    console.log("Error in fetching company details", err)
                })
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        this.props?.setPostFlag(true)
        let payload = { ...this.state.addRepresentiveData };
        payload['company_id'] = this.props?.companyId;
        payload['is_delete'] = 0

        if (this.canSubmit()) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostRepCompany`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    this.props?.notificationComponent(true, "success", "refresh-company-rep");
                    this.setState({
                        isPosting: false,
                        postInitiated: false
                    })
                    this.props?.setPostFlag(false)
                },
                    (err) => {
                        console.log('Error is submitting Show' + err);
                        this.props?.notificationComponent(true, "fail");
                        this.props?.setPostFlag(false)
                        this.setState({ isPosting: false, postInitiated: false })
                    });
        } else {
            console.log("Error in submitting show")
        }
    };


    render() {
        return (
            <MDBContainer className="AddCompRepresentiveContainer">
                <span className='header'>Select a  representative to add this agency</span>
                <MDBRow>
                    <BasicLabel text={"Representative"} />
                    <SearchSelectField
                        id="representative"
                        placeholder="- Search -"
                        width="100%"
                        searchSelect={true}
                        multiple={false}
                        detail_selected={this.state.selectedRep || null}
                        options={this.state.representiveOptions || []}
                        valueSelected={(e, newValue) =>  {
                            this.setState({ selectedRep: newValue })
                            this.handleOnchange(e, newValue, 'representative')
                        }}
                        searchText={(e) => this.getRepresentiveData('REPRESENTATIVE', e.target.value)}
                    />
                </MDBRow>
                <MDBRow className="p-2 margin">
                    <MDBCol md={5} lg={7}></MDBCol>
                    <MDBCol md={2} lg={2}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            text={
                                this.state.isPosting ? (
                                    <CircularProgress color="inherit" size={18} />
                                ) : "Add"
                            }
                            icon={"Add"}
                            onClick={this.handleSubmit}
                        />
                    </MDBCol>
                    <MDBCol md={1}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            onClick={() => {
                                this.props?.onClose();
                            }}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
export default withUserContext(AddCompanyRepresentive);
