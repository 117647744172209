import React from 'react';
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import SelectField from './SelectField/SelectField';
import SearchSelectField from './SearchSelectField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './TableFilters.scss';
import DateField from './DateField/DateField';
import { getFormattedDate } from '../../../caster-feature-old/Common/Helper';
import { MDBIcon } from 'mdbreact';
import debounce from 'lodash.debounce'

class TableFilters extends React.Component {
  constructor(props) {
    super(props);
  }

  handleFilters = (e) => {
    this.props.filterChangeHandler(e.target.value);
    this.props.addFilterChangeHandler(this.props?.inputType === "datefield" ? e.currentTarget.value : e.currentTarget.outerText);
  }

  debouncedFunction = debounce((value) => {
    this.props.addFilterChangeHandler(value);
    if ((this.props?.identityKey === 'performerTable' && this.props?.inputType === "datefield") || (this.props?.config?.identityKey === 'billingTable' && this.props?.inputType === "datefield")) {
      this.props.filterChangeHandler(value);
    }

  }, 500);

  handleKeyUp = (value) => {
    this.debouncedFunction(value);
  }

  pickFilters(inputType, name) {

    switch (inputType) {
      case 'text':
      case 'textarea':
      case 'notesEdit':
        return (<>
          <BasicTextField id={name} placeholder={this.props?.placeholder}
            value={this.props.inputValue}
            onKeyUp={(event) => {
              if (this.props?.identityKey === 'Dashboard' || this.props?.identityKey === 'performerTable' || this.props?.config?.identityKey === 'billingTable') {
                this.handleKeyUp(event.target.value);
              }
              if (event.keyCode === 13) {
                this.props.addFilterChangeHandler(event.target.value);
              }
            }}
            isAlphabetVar={this.props?.isAlphabetVar || false}
            {...(this.props?.isAlphabetVar ? { handleAlphabetVariantSelect: ((val) => { this.handleKeyUp(val) }) } : {})}
            onChange={(e) => this.props.filterChangeHandler(e.target.value)}
          />
          {((this.props?.filtersLandingCasting || this.props?.identityKey === "performerTable" || this.props?.identityKey === 'Dashboard') && this.props?.inputValue?.length > 0) && <MDBIcon icon={"times"} onClick={this.props?.clearInputValues} />}
        </>);
        break;
      case 'checkbox':
        return <BasicCheckbox id={name}
          onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1" : "0"))}
        />;
        break;
      case 'select':
        return <SelectField
          id={name}
          className="FilterSelectField"
          value={this.props.inputValue}
          options={this.props?.options}
          placeHolderText={"-Select-"}
          isLandingPage={true}
          onChange={this.handleFilters}
        />;
        break;
      case 'searchselect':
        return <SearchSelectField
          id={"search-select"}
          width={"100%"}
          className="FilterSearchSelect"
          value={this.props.inputValue}
          options={this.props?.options}
          onChange={this.handleFilters}
        />;
        break;
      case 'datefield':
        return <DateField
          id={"datefield" + this.props?.identityKey}
          size="small"
          onKeyUp={(event) => {
            if (this.props?.identityKey === 'performerTable' || this.props?.identityKey === 'billingTable') {
              this.handleKeyUp((event.target.value));
            }
            if (event.keyCode === 13 && getFormattedDate(event.target.value)) {
              this.props.addFilterChangeHandler(event.target.value);
            }
          }}
          onChange={(e) => (this.props?.identityKey === 'performerTable' || this.props?.identityKey === 'billingTable') ? this.handleKeyUp(e.target.value) : this.props.filterChangeHandler(e.target.value)}
          value={this.props.inputValue || ""}
        //onChange={this.handleFilters}
        />;
        break;
      case 'autocomplete':
        return <Autocomplete
          id={"searchSelect"}
          options={this.props?.options || []}
          disablePortal
          getOptionLabel={(option) => (option.label)}
          value={this.props.inputValue ? this.props.inputValue : null}
          onChange={(e, v) => { this.props.filterChangeHandler(v); this.props.addFilterChangeHandler(v?.label) }}
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="-Search Select-"
              onChange={ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" || ev.target.value !== null) {
                  this.props.autoCompleteCallBack(this.props?.configHeading, ev.target.value)
                }
              }}
              InputProps={{
                ...params.InputProps, disableUnderline: true
              }}
            />
          )}
        />
      case 'daterange':
        return (
          <div className="d-flex DateFilter">
            <DateField
              label=""
              value={this.props.inputValue}
              onChange={(e) => this.props.filterChangeHandler(e.target.value)}
            />
            <SelectField
              id={name}
              className="FilterSelectField"
              value={this.props.inputValue}
              options={this.props?.options}
              placeHolderText={"-Select-"}
              isLandingPage={true}
              onChange={(e) => this.props.addFilterChangeHandler(e.currentTarget.outerText)}
            />
          </div>);
        break;
      default:
        return <></>;
    }

  }


  render() {
    return (
      <div className={this.props?.isExpanded === true ? "expanded casterfeatureTableFilters" : "casterfeatureTableFilters"}>
        {this.pickFilters(this.props.inputType, this.props.name)}
      </div>)
  }
}

export default TableFilters;