import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import TableComponent from "../SharedComponents/Table";
import { companyTalentConfig, companyTalentRepList } from "./config";
import { MDBRow } from "mdb-react-ui-kit";
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import { talentAnimationList, representativeAnimationList } from '../../../constants/NavList';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import './CompanyProfile.scss'

class CompanyTalent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: this.props?.renderList,
      config: companyTalentConfig,
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.renderList) });
  }

  navigationCallback = (dataItem, node) => {
    console.log("check node in navigation", node);
    if (node === 'representative_name') {
      this.setState({ openRepModal: true, selectedRepId: dataItem?.representative_id })
    } else {
      this.setState({ openTalentModal: true, selectedTalentId: dataItem.talent_id })
    }
  }

  handleHideTalentPopup = () => {
    this.setState({ openTalentModal: false });
  }

  handleHideRepPopup = () => {
    this.setState({ openRepModal: false });
  }

  handleDeleteRow = (item, node, is_delete = 1) => {
    this.setState({ selectedTalentRep: node.talent_rep_company_mtrx_id, is_delete: 1 })
    this.setState({ postInitiated: true })
    let payload = {};
    payload['talent_id'] = null;
    payload['representative_id'] = node.representative_id;
    payload['company_id'] = this.props?.companyId;
    payload['is_delete'] = is_delete
    is_delete === 1 ? payload['talent_rep_company_mtrx_id'] = node.talent_rep_company_mtrx_id : payload['talent_rep_company_mtrx_id'] = null;
    this.setState({ isPosting: true })
    CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostTalentRep`,
      payload,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        this.setState({
          isPosting: false,
          postInitiated: false
        })
        this.props?.getcompanyDetails()
      },
        (err) => {
          console.log('Error is submitting Show' + err);
          this.setState({ isPosting: false, postInitiated: false })
        });
  }

  render() {
    return (
      <div className="CompanyReprContainer">
      <MDBRow id={"companytalentTable"}>
          <TableComponent
            list={this.props?.renderList || []}
            config={this.state.config}
            sortCallback={this.props?.handleTableColumnClick}
            handleDelCallBack={(item, node) => this.handleDeleteRow(item, node)}
            hyperLinkNavigationCallback={this.navigationCallback}
          />
        </MDBRow>

        <MDBRow className="Pagination">
          <TablePagination
            component="div"
            count={this.props?.renderList ? this.props?.renderList?.length : 0}
            rowsPerPageOptions={[]}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            align={"end"}
          />
        </MDBRow>
        {this.state.openTalentModal &&
          <ModalComponent
            navFuns={this.functions}
            open={this.state.openTalentModal}
            handleClose={this.handleHideTalentPopup}
            tabList={talentAnimationList}
            bodyText={"Search for Talent to view their Data, or create a new Talent"}
            searchPlaceholder={"Search Talent by name or SSN"}
            headerText={"Talent"}
            addButtonText={"New Talent"}
            objectType={'TALENT'}
            dataUrl='talentDetails'
            talentId={this.state.selectedTalentId}
            {...this.props}
          />
        }
        {this.state.openRepModal &&
          <ModalComponent
            navFuns={this.functions}
            open={this.state.openRepModal}
            handleClose={this.handleHideRepPopup}
            tabList={representativeAnimationList}
            headerText={"Representatives"}
            objectType={'REPRESENTATIVE'}
            mode={"New"}
            repId={this.state.selectedRepId}
        />
        }
      </div>
    );
  }
}
export default withUserContext(CompanyTalent);
