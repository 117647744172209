import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact'
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { filtersSearch, landingConfig, sortByValues } from './config'
import TableComponent from '../SharedComponents/Table';
import './DashboardNew.scss'
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import TablePagination from '@material-ui/core/TablePagination';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';
import { CircularProgress } from "@material-ui/core";
import { Link } from 'react-router-dom';
class DashboardNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFilters: JSON.parse(JSON.stringify({ ...filtersSearch })),
            renderList: [],
            count: 0,
            page: 1,
            rowsPerPage: 10,
            icon: "sort-alpha-down",
            showNewProduction: false,
            isDashboardLoading: false
        }
    }

    componentDidMount() {
        this.getDashboard(this.state.inputFilters)
        this.getProdCompanyDetails('PRODUCTION_COMPANIES')
        this.getStatusTypes()
        this.setState({ sortByValue: "PROJECT" })

    }

    navigationCallback = (obj, node) => {
        if (obj?.project_id != null) {
            this.props?.history.push(`/caster-feature/project/${obj.project_id}`)
        }
    }

    handleHideProductionPopup() {
        this.setState({ showNewProduction: false })
    }

    getStatusTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=PROJECT_STATUS`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, text: item.lookup_value }));
                this.setState({ statusOptions: formattedList },
                    () => {
                        // if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                        //     this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                        //     this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        // }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getProdCompanyDetails(objectType, searchString = null) {
        this.setState({ isRepresentativeFetch: true });
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?objectType=${objectType}&searchString=`
        ).then(
            (response) => {
                this.setState({ isRepresentativeFetch: false });
                if (response?.data?.error) {
                    console.log("error from search", repsonse?.data?.error);
                } else {
                    let formattedList = response?.data?.map((i) => ({
                        value: i?.production_company,
                        text: i?.production_company,
                    }));
                    this.setState({ prodCompanyOptions: formattedList });

                }
            },
            (err) => {
                this.setState({ isRepresentativeFetch: false });
                console.log("Search from reprsentative: " + err);
            }
        );
    }

    handleFavIcon = (item, id) => {
        this.setState({ isDashboardLoading: true });
        const data = { project_id: item.project_id, user_id: this.props?.userContext?.user_profile?.user_id };
        if (item.is_favorite === 1) {
            data["is_delete"] = 1
            data["user_project_favorite_id"] = item?.user_project_favorite_id
        } else if (item.is_favorite === 0) {
            data["is_delete"] = 0
            data["user_project_favorite_id"] = null
        }
        CasterService.postDataParams(
            Constants.CasterServiceBaseUrl + "/casterFeatureProjectFavorite", data, this.props.userContext?.active_tenant?.tenant_id,
        )
            .then((res) => { this.getDashboard(this.state.inputFilters) })
            .catch((error) => {
                this.setState({ isDashboardLoading: false });
                console.log(error, "fav error");
            });
    }

    getFeatureDashboardProjects = (value = null) => {
        let inputFilters = this.state.inputFilters
        inputFilters["search_text"] = value;
        this.setState({ inputFilters }, () => {
            this.getDashboard(this.state.inputFilters)
        })
    };

    getDashboard = (inputFilters) => {
        this.setState({ isDashboardLoading: true })
        CasterService.getDataParams(Constants.CasterServiceBaseUrl +
            `/casterFeatureDashboardProject`, inputFilters, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response?.data?.data?.map(i =>
                ({
                    project_id: i?.project_id,
                    production_company: i?.production_company ? i?.production_company : "--",
                    project_name: i?.project_name ? i?.project_name : "--",
                    gl_no_prod: i?.gl_no_prod ? i?.gl_no_prod : "--",
                    gl_no_dev: i?.gl_no_dev ? i?.gl_no_dev : "--",
                    start_wrap_date: i?.start_date ? `${i?.start_date}${i?.wrap_date ? '-' + i?.wrap_date : ""}` : "--",
                    status: i?.status ? i?.status : "--",
                    is_favorite: i?.is_favorite,
                    user_project_favorite_id: i?.user_project_favorite_id
                }))
                this.setState({ renderList: formattedList, count: response?.data?.count, lastAccessedProjectId: response?.data?.last_accessed_project_id, last_accessed_project: response?.data?.last_accessed_project, backUpList: formattedList, isDashboardLoading: false })
                console.log("response", response)
            },
                (err) => {
                    this.setState({ isDashboardLoading: false })
                    console.log("Error in fetching Representative Details:", err)
                })
    }

    handleChangePage = (event, newPage) => {
        let filters = this.state.inputFilters
        filters["page"] = newPage + 1;
        this.setState({
            page: newPage + 1,
            inputFilters: filters
        }, () => {
            this.getDashboard(this.state.inputFilters)
        });
    }

    handleChangeRowsPerPage = (event) => {
        let filters = this.state.inputFilters
        filters["per_page"] = event.target.value;
        filters["page"] = 1
        this.setState({
            rowsPerPage: event.target.value, page: 1,
            inputFilters: filters
        }, () => {
            this.getDashboard(this.state.inputFilters)
        });
    }

    getProjectName = (searchString) => {
        let json = {
            per_page: 25,
            page: 1,
            order_by: "PROJECT",
            sort_by: "ASC",
            search_text: searchString
        }
        CasterService.getDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureDashboardProject`, json, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    console.log('response', response)
                    let formattedList = response?.data?.data?.map(i => ({
                        value: i?.project_id,
                        text: `${i?.project_name ? i?.project_name : ''}${i?.aka ? '(' + i?.aka + ')' : ''}${i?.gl_no_dev ? '(' + i?.gl_no_dev + ')' : ''}${i?.gl_no_prod ? '(' + i?.gl_no_prod + ')' : ''}`
                    }))
                    this.setState({ projectOptions: formattedList })
                    // console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    getSortByValues = (field, value) => {
        let inputFilters = this.state.inputFilters
        if (field === "order_by") {
            inputFilters["order_by"] = value
            this.setState({ sortByValue: value })
        } else if (field === "production_companies") {
            inputFilters["production_companies"] = value?.value
            this.setState({ productionCompanySelected: value })
        } else if (field === "project_status") {
            inputFilters["project_status"] = value?.value
            this.setState({ projectStatusSelected: value })
        }
        inputFilters["page"] = 1
        this.setState({
            inputFilters,
        }, () => {
            this.getDashboard(this.state.inputFilters)
        })
    }


    handleSelectedShow = (newValue) => {
        this.setState({
            selectedShow: newValue,
        });
    };

    tableSort = () => {
        let inputFilters = this.state.inputFilters
        let icon = this.state.icon
        if (inputFilters["sort_by"] === "ASC") {
            inputFilters["sort_by"] = "DESC"
            icon = "sort-alpha-up"
        } else {
            inputFilters["sort_by"] = "ASC"
            icon = "sort-alpha-down"
        }
        this.setState({
            inputFilters,
            icon
        }, () => {
            this.getDashboard(this.state.inputFilters)
        })
    }


    handleSelectedProject = (obj) => {
        if (obj?.value) {
            this.props.history.push(`/caster-feature/project/${obj?.value}`)
        }
    }

    render() {
        const url = `/caster-feature/project/${this.state.lastAccessedProjectId}`
        return (
            <MDBContainer fluid className="dashBoardContainer">
                <MDBRow>
                    <MDBCol md={11}><span className="landingPageUserNameFeature">
                        Welcome,{`${this.props?.userContext?.user_profile?.name ? (' ' + this.props?.userContext?.user_profile?.name) : null}`}
                    </span></MDBCol>
                    <MDBCol md={1} className="lastAccessedCol">
                        <MDBRow className="float-right">
                            <span>Last Accessed</span>
                        </MDBRow>
                        <MDBRow className="float-right">
                            {this.state.last_accessed_project !== null ?
                                <span className="floatRight">
                                    <Link to={url}>{`${this.state.last_accessed_project}`}</Link>
                                </span>
                                : ''
                            }
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}  className="searchSelect" style={{ marginLeft: "-10px", marginTop: "15px" }}>
                        <BasicTextField
                            placeholder="Search for a Project by Name, Alias, or GL#(any)"
                            value={this.state.selectedShow}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    this.getFeatureDashboardProjects(e.target.value)
                                }
                            }}
                            onChange={(e) => this.handleSelectedShow(e.target.value)}
                        />
                    </MDBCol>
                    {/* <MDBCol md={4} className="searchSelect">
                        <SearchSelectField
                            id={"search"}
                            // label={"Company"}
                            options={this.state?.projectOptions || []}
                            width={'100%'}
                            // detail_selected={this.state.selectedRep}
                            valueSelected={(e, value) => this.handleSelectedProject(value)}
                            searchText={(e) => this.getProjectName(e.target.value)}
                            multiple={false}
                            searchSelect={true}
                            placeholder={"Search for a Project by Name, Alias, or GL#(any)"}
                        />
                    </MDBCol> */}
                    <MDBCol md={2} className="newProjectButtonFeature">
                        <BasicButton
                            variant="contained"
                            type="inline"
                            className="outlined-btn"
                            text="New Project"
                            icon={"plus-circle"}
                            onClick={() => this.setState({ showNewProduction: true })}
                        // onClick={this.handleshowNewProjectModal}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="d-flex prodCompFilter">
                        <MDBCol className="prodCompLabel">
                            <span>Prod. Company:</span>
                        </MDBCol>
                        <MDBCol className="prodCompSearchSelect">
                            <SearchSelectField
                                id={"prodComp"}
                                // label={"Episode(s)"}
                                value={this.state.productionCompanySelected || []}
                                options={this.state?.prodCompanyOptions || []}
                                // limitTags={4}
                                onChange={(e, value) => this.getSortByValues('production_companies', value)}
                                // multiple={true}
                                width={'100%'}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol md={1} className="d-flex statusFilter">
                        <MDBCol className="statusLabel">
                            <span>Status:</span>
                        </MDBCol>
                        <MDBCol className="statusSearchSelect">
                            <SearchSelectField
                                id={"status"}
                                // label={"Episode(s)"}
                                value={this.state.projectStatusSelected || []}
                                options={this.state?.statusOptions || []}
                                // limitTags={4}
                                onChange={(e, value) => this.getSortByValues('project_status', value)}
                                // multiple={true}
                                width={'100%'}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol md={2} className="d-flex sortByFilter">
                        <div className="d-flex sortByDiv">
                            <span>Sort By:</span>
                            <span>
                                <SelectField
                                    value={this.state.sortByValue}
                                    options={sortByValues}
                                    onChange={(e) => this.getSortByValues('order_by', e.target.value)}
                                />
                            </span>
                        </div>
                    </MDBCol>
                    <MDBCol sm={1} className="iconSort"><MDBIcon icon={this.state.icon} onClick={this.tableSort}></MDBIcon></MDBCol>
                </MDBRow>
                <MDBRow>
                    {this.state.isDashboardLoading ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="dashBoard">
                                <TableComponent
                                    list={this.state.renderList || []}
                                    className={"dashboard-table"}
                                    config={landingConfig}
                                    hyperLinkNavigationCallback={this.navigationCallback}
                                    favIconCallback={(item, id) => this.handleFavIcon(item, id)}
                                // handleOpenInlineModal={this.handleShowSeasonPopup.bind(this)}
                                />

                            </div>
                            <TablePagination
                                component="div"
                                count={this.state.count}
                                // rowsPerPageOptions={this.state.rowsPerPage}
                                page={this.state.page - 1}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    )}
                </MDBRow>
                {this.state.showNewProduction ?
                    <Modal
                        // id={this.state.selectedCrew?.value}
                        // akas={this.state?.selectedCrew?.aka}
                        open={this.state.showNewProduction}
                        handleClose={this.handleHideProductionPopup.bind(this)}
                        // tabList={crewNavList}
                        modalName={"Project"}
                        headerText={"Project"}
                        mode="New"
                        toggleRefreshPage={this.props.toggleRefreshPage}
                        {...this.props}
                    // refreshPage={this.props?.refreshPage}
                    // isProfileDiv={true}
                    // addButtonText={"New Crew"}
                    // objectType={'CREW'}
                    // objectId={this.state?.selectedCrew?.value || 1}
                    // dataUrl='casterFeatureCrewDetails'
                    // headerName={this.state.selectedCrew?.headerName}
                    /> : null
                }
            </MDBContainer>
        )
    }

}
export default withUserContext(DashboardNew)