import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import BasicButton from './BasicButton/BasicButton';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        borderRadius: '8px',
        boxShadow: theme.shadows[5]
    },
    label: {
        fontSize: '0.75rem'
    },
    title : {
        backgroundColor:'#164f9f',
        height: '2rem',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    },
    titleLabel : {
        color: 'white',
        paddingLeft: '20px',
        paddingTop: '7px'
    },
    MessageContent : {
        height: '150px',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        padding: '16px 0 0 16px'
    },
    ButtonDiv : {
        display: 'inline-flex',
        padding: '16px 0 0 16px',
        float: 'right'
    },
    FloatRight :{
        paddingLeft: '8px',
        marginRight: '5px'
    }
}));

export default function MessageModal(props) {
    const classes = useStyles();
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
            props?.handleClose();
    }
    let hideCancel = props?.hideCancel || false;
    let hideSubmit = (props?.primaryButtonText != "" ? true : false)
    return (
        <div className="BasicModalContainerForMessage">
            <Modal
                open={props?.open ? props?.open : false}
                onClose={handleClose}
                className={classes.modal}
            >
                <div className={classes?.paper}>
                    <div className={classes?.title}>
                        <label className={classes?.titleLabel}>{props?.title}</label>
                    </div>
                    <div className={classes?.MessageContent}>
                        {props?.children ? props?.children : <>
                        <span>{props?.message}</span><br />
                        <div className={classes?.ButtonDiv}>
                            {hideSubmit ? <BasicButton
                                variant="contained"
                                text={props?.primaryButtonText}
                                type="inline"
                                onClick={props?.onConfirm}
                                disabled={false} /> : null}
                            {
                                !hideCancel && <div className={classes?.FloatRight}>
                                <BasicButton
                                    variant="outlined"
                                    type="inline"
                                    text={props?.secondaryButtonText}
                                    onClick={props?.handleClose}
                                    disabled={false} />
                            </div>
                            }
                        </div>
                        </>}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
