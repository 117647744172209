import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./RecurringRole.scss";
import { withUserContext } from "../../../contexts/UserContext";
import IconTextField from "../../components/SharedComponents/IconTextField/IconTextField";
import {recurringRoleConfig, recurringRoleList} from "./RecurringConfig";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TableComponent from "../../components/SharedComponents/Table";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import MessageModal from '../../../caster-tv-jwp/components/SharedComponents/MessageModal';
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import axios from 'axios';

class SetRecurringRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isAllTenant:false,
        renderList:[],
        isProjectEpisodeList: false,
        optionsName:[],
        selectedShow: null,
        isLoading: false,
        searchCancelToken: null,
    };
  }

  componentDidMount(){
    this.getProjectEpisodesList(this.props?.project_id)
  }

  handleSelectedShow = (newValue) => {
    this.setState({ selectedShow: newValue });

    if (newValue) {
      let selectedShow = this.state.renderList?.find(item => item?.deal_memo_id === newValue?.value);
      this.setState({
        renderList: selectedShow ? [selectedShow] : [],
        page: 0,
      });
    } else {
      this.getProjectEpisodesList(this.props?.project_id)
    }
  };

  fetchSearchDetails = (event) => {
    if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (event.target.value !== "") {
        CasterService.getDataWithCancel(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpProjectRoleList?projectId=${this.props?.project_id}&searchString=${event.target.value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    text: item.talent_name,
                    value: item.deal_memo_id
                }));
                this.setState({ optionsName: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    } else {
        this.setState({ optionsName: [] });
    }
}

  handleChange=(field,value)=>{
    if(field === "recurring" && value === "recurring_only"){
        this.setState({isAllTenant: false})
    }else if(field === "recurring" && value === "all_talent"){
        this.setState({isAllTenant: true})
    }
    this.getProjectEpisodesList(this.props?.project_id)
  }

  getProjectEpisodesList = (projectId) => {
    this.setState({isProjectEpisodeList: true})
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpProjectRoleList?projectId=${projectId}&searchString=${null}`,
        this.props?.userContext?.user_profile?.tenant_id,1
    )
      .then((res) => {
        if(res.data.length > 0){
          let regularList = res.data.filter(item=>item.role_Type === "Recurring")
          let allTalentList = res.data
          console.log("allllll",regularList,allTalentList)
          let allList  = this.state.isAllTenant ?  allTalentList : regularList
          this.setState({
            renderList: allList,
            setEditEpisodeProject: false,
            isProjectEpisodeList: false
          });
        }else{
          this.setState({setEditEpisodeProject: false,isProjectEpisodeList: false})
        }
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  selectTalent = (item, node) => {
    this.setState({ showConfirmationModal: true ,deal_memo_id: item.deal_memo_id});
  };

  confirmCancelChanges(){
    this.setState({showConfirmationModal: false})
    this.props.confirmationFun(this.state.deal_memo_id)
  }

  render() {
    return (
      <div className="SetRecurringRoleContainer">
        <div className="p-right">
            <span className="font-header">Select Talent from this project to pre-populate their information onto this Deal Memo.</span>
        <MDBRow>
          <MDBCol md={4} className="field-css">
            <SearchSelectField 
               loading={this.state.isLoading}
               id={"talent"}
               placeholder={"Search-"}
               options={this.state.optionsName || []}
               width={'100%'}
               detail_selected={this.state.selectedShow}
               valueSelected={(e, value) => this.handleSelectedShow(value)}
               searchText={this.fetchSearchDetails}
               multiple={false}
               searchSelect={true}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="radio-font">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radio-font"
                defaultValue={"recurring_only"}
                onChange={(e) => this.handleChange("recurring", e.target.value)}
              >
                <FormControlLabel
                  className="LabelSize"
                  value="recurring_only"
                  control={<Radio />}
                  label="Recurring Only"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="all_talent"
                  control={<Radio />}
                  label="All Talent"
                />
              </RadioGroup>
            </FormControl>
          </MDBCol>
        </MDBRow>
        </div>
        {this.state.isProjectEpisodeList ? <div style={{ textAlign: "center" }}>
                  <CircularProgress size={34} />
                </div> : 
                <>
        {this.state.isAllTenant ? 
        <span className="error-text">Selecting a Talent NOT marked as recurring will change role type to recurring for the below selected and new Deal Memo</span>
        : " "}
        <MDBRow id={"recurring-table"}>
            <TableComponent
            list={this.state.renderList || []}
            config={recurringRoleConfig}
            buttonWithFieldFun={(item, node) =>
              this.selectTalent(item, node)
            }
          />
        </MDBRow>
        </>
      }
       {this.state.showConfirmationModal &&
          <MessageModal
            title={"Unsaved Changes"}
            open={this.state.showConfirmationModal}
            message={" Add performer information into the new deal memo"}
            primaryButtonText={"Yes"}
            secondaryButtonText={"No"}
            onConfirm={() => this.confirmCancelChanges()}
            handleClose={(e) => this.setState({ showConfirmationModal: false })} 
            />
        }
      </div>
    );
  }
}
export default withUserContext(SetRecurringRole);
