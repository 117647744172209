import React from "react";
import { withRouter } from "react-router-dom";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import { GuardianInfoRecord } from "./DealMemoConfig";
import DateField from "../../components/SharedComponents/DateField/DateField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import BasicLabel from "../../../caster-feature/components/SharedComponents/BasicLabel/BasicLabel";
import { MDBCard, MDBCardBody, MDBCardText } from "mdbreact";
import messages from "../../Common/Messages.json";
import { validateAlpVarCharNumOnlyWithSpace, validateNumbersOnly, validateEmail, ssnFormat, validateAllPhone, formatPhoneNumbers, isWithinLast18Years, convertDateFormat ,getFormattedDate} from '../../Common/Helper'
import moment from 'moment'

class GuardianInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guardianDetails: { ...this.props?.guardianDetails },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.guardianDetails !== this.props.guardianDetails) {
      this.setState({ guardianDetails: this.props?.guardianDetails });
    }
  }

  handleChange=(listname,field,value)=>{
    this.setState({
      guardianDetails: {
        ...this.state.guardianDetails,
        [field]: value
      }
    },()=>{ this.props.getguardianDetails(this.state.guardianDetails)});
  }


  render() {
    let minorDOB = null
    if(this.props.dateFields?.minor_dob === null || this.props.dateFields?.minor_dob === "0000-00-00"){
      minorDOB = null
    }else{
      if(this.props.dateFields?.minor_dob !== "0000-00-00" || this.props.dateFields?.minor_dob !== null){
        minorDOB = getFormattedDate(this.props.dateFields?.minor_dob)
     }else{
       minorDOB = null
     }
    }
    return (
      <div className="GuardianContainer">
        {this.props?.mode === "view" ?
          <>
            <MDBCard className="mt">
              <MDBCardBody>
                <MDBCardText>
                  <BasicLabel className="mainlabel-text" text={"Guardian Information"} />
                  <MDBRow className="label-color">
                    <MDBCol md={12}>
                      <MDBRow>
                        <MDBCol md={2}>
                          <BasicLabel text={"Guardian Name"} />
                          <span className={"subText-font"}>{this.state.guardianDetails?.guardian_name}</span>
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicLabel text={"Email"} />
                          <span className={"subText-font"}>{this.state.guardianDetails?.guardian_email}</span>
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicLabel text={"Cell Number"} />
                          <span className={"subText-font"}>{this.state.guardianDetails?.guardian_phone}</span>
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicLabel text={"Minor DOB"} />
                          <span className={"subText-font"}>{minorDOB || "None"}</span>
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicLabel text={"Work Permit"} />
                          <span className={"subText-font"}>{this.state.guardianDetails?.has_work_permit === "yes" ? "yes" : "no"}{this.state.guardianDetails?.has_work_permit === "yes" ? `(Expires  ${(getFormattedDate(this.props.dateFields?.expiration_date))})` :"None"}</span>
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicLabel text={"Coogan Account"} />
                          <span className={"subText-font"}>{this.state.guardianDetails?.coogan_account}</span>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={2}></MDBCol>
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </>
          :
          <>
            <MDBCard className="mt">
              <MDBCardBody>
                <MDBCardText>
                  <MDBRow className="label-color">
                    <MDBCol md={9}>
                      <MDBRow>
                        <MDBCol md={4}>
                          <BasicTextField
                            label={"Guardian Name"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.guardianDetails?.guardian_name || ""}
                            onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('guardianDetails', 'guardian_name', e.target.value.length > 0 ? e.target.value : null) : null}
                          />
                        </MDBCol>
                        <MDBCol md={4}>
                          <BasicTextField
                            label={"Guardian Email"}
                            fieldValid={!validateEmail(this.state.guardianDetails?.guardian_email) && this.state.guardianDetails?.guardian_email}
                            inValidInput={messages.invalidInput}
                            value={this.state.guardianDetails?.guardian_email || ""}
                            onChange={(e) =>
                              this.handleChange("guardianDetails", "guardian_email", e.target.value)
                            }
                          />
                        </MDBCol>
                        <MDBCol md={4}>
                          <BasicTextField
                            label={"Guardian Phone"}
                            placeholder="(xxx)xx-xxxx"
                            fieldValid={this.state.guardianDetails?.guardian_phone ? !validateAllPhone(this.state.guardianDetails?.guardian_phone) : false}
                            inValidInput={"Please enter valid phone"}
                            value={this.state.guardianDetails?.guardian_phone || ""}
                            onChange={(e) => {
                              let formattedValue = formatPhoneNumbers(e.target.value);
                              if (formattedValue.length >  18) {
                                formattedValue = formattedValue.slice(0,18);
                              }
                              this.handleChange("guardianDetails", "guardian_phone", formattedValue);
                            }}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={2}>
                          <DateField
                            id={"minor-dob"}
                            size="small"
                            label={"Minor DOB"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={"Date must be in last 18 years based on current date"}
                            value={(this.props.dateFields?.minor_dob) || " "}
                            onChange={(e) => this.props.handleDateChange('minor_dob', e.target.value)}
                          />
                          {!isWithinLast18Years(this.props.dateFields?.minor_dob) ? (
                            <span className={"subText-font"} style={{ color: "red" }}>please Enter the date within last 18 years</span>
                          ): " "}
                        </MDBCol>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Coogan Account"}
                            value={this.state.guardianDetails?.coogan_account || ""}
                            onChange={(e) =>
                              this.handleChange("guardianDetails", "coogan_account", e.target.value)
                            }
                          />
                        </MDBCol>
                        <MDBCol md={3}>
                          <BasicLabel text={"Work Permit"} />
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              className="radio-font"
                              defaultValue={this.state.guardianDetails?.has_work_permit === "yes" ? "yes" : "no" || "no"}
                              onChange={(e) => this.handleChange("guardianDetails", "has_work_permit", e.target.value)}
                            >
                              <FormControlLabel
                                className="LabelSize"
                                value="yes"
                                control={<Radio />}
                                label="YES"
                              />
                              <FormControlLabel
                                className="LabelSize"
                                value="no"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </FormControl>
                        </MDBCol>
                        {(this.props.isExpirirationDate || this.state.guardianDetails?.has_work_permit === "yes") ?
                          <MDBCol md={3}>
                            <DateField
                              id={"exp-dob"}
                              size="small"
                              label={"Expiration Date"}
                              isMandatory={true}
                              showMandatory={this.props?.postInitiated || false}
                              mandatoryWarning={messages.mandatoryWarning}
                              value={this.props.dateFields?.expiration_date || ""}
                              onChange={(e) =>
                                this.props?.handleDateChange("expiration_date", e.target.value)
                              }
                            />
                          </MDBCol> : " "
                        }
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={1}></MDBCol>
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </>
        }

      </div>
    );
  }
}
export default withUserContext(GuardianInfo);