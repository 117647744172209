import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from 'mdbreact';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../SharedComponents/SelectField/SelectField';
import TableComponent from '../SharedComponents/Table';
import { agencyTalentsList, agencyTalentsConfig } from './Config';
// import './Agent.scss';
import TablePagination from '@material-ui/core/TablePagination';
import {
    BrowserRouter as Router, withRouter
  } from "react-router-dom";

export default withRouter(class TalentActingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.agency_talent_list !== state.agency_talent_list) {
            return {
              acting_history_list: props.acting_history_list
            }
        }
        if (props.page !== state.page) {
            return {
                page: props.page
            }
        }
        if (props.rowsPerPage !== state.rowsPerPage) {
            return {
                rowsPerPage: props.rowsPerPage
            }
        }
        if (props.actingHistoryConfig != state.actingHistoryConfig) {
            return {
              actingHistoryConfig: props?.actingHistoryConfig
            }
        }
    }

    navigationCallback =(obj, node) => {
        if(node.includes("project_name")) {
            this.props?.handleClose()
            //  this.props.navFuns.handleHideTalentPopup();
            // this.props?.history.push(`/caster-feature/project/${obj.project_id}`)
            window.open(`/caster-feature/project/${obj.project_id}`)
        }
    }

    navigationTextCallBack =(obj, node) => {
        if(node.includes("performer_record")) {
            this.props?.handleClose()
            // this.props.navFuns.handleHideTalentPopup();
            this.props.navFuns.handleOpenPerformerRecord(obj.project_id, obj.performer_deal_id);
        }
    }

    render(){
        return(
            <div className="agencyTalents">
                <MDBRow>
                    <MDBCol md={3}>
                        <BasicTextField
                            id="project_name"
                            value={this.props?.actingHistoryFilterList?.project_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("project_name", e.target.value)
                                this.props?.addFilterChangeHandler("Project", e.target.value)
                            }}
                        />
                    </MDBCol>  
                    <MDBCol md={3}>
                        <BasicTextField
                            id="role_name"
                            value={this.props?.actingHistoryFilterList?.role_name}
                            onChange={(e) => {
                                this.props?.onChangeHandler("role_name", e.target.value)
                                this.props?.addFilterChangeHandler("Role", e.target.value)
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <SelectField
                            id="union"
                            value={this.props?.actingHistoryFilterList?.union}
                            options={this.props?.unionOptions}
                            onChange={(e) => {
                                this.props?.onChangeHandler("union", e.target.value)
                                this.props?.addFilterChangeHandler("Union", e.target.value)
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow id="agencyTalentsTable">
                    <TableComponent
                        config={this.props?.actingHistoryConfig}
                        list={this.props?.acting_history_list}
                        hyperLinkNavigationCallback={this.navigationCallback}
                        hyperLinkTextIconNavigationCallback={this.navigationTextCallBack}
                        sortCallback={this.props?.handleTableColumnClick}
                    />
                    <TablePagination
                        component="div"
                        count={this.props?.current_list ? this.props?.current_list?.length : 0}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        page={this.props?.page}
                        onChangePage={this.props?.handleChangePage}
                        rowsPerPage={this.props?.rowsPerPage}
                        onChangeRowsPerPage={this.props?.handleChangeRowsPerPage}
                    />
                </MDBRow>
            </div>
        );
    }

})

