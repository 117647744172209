import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import {
    MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal,
    MDBContainer, MDBIcon, MDBBtn
} from "mdb-react-ui-kit";
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../../route/Routes';
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
// import { useHistory } from "react-router-dom";
// import { withRouter } from 'react-router-dom';
// import HeaderContent from '../../header/HeaderContent';
import SideNav from '../components/SideNav/SideNav';
import './MainContent.scss';
import ModalComponent from '../Common/DefaultModalLayout/Modal/Modal';
import MainViewModalComponent from '../Common/MainViewModalLayout/Modal/Modal';
import * as Constants from '../../constants/constants';
import CasterService from '../../services/service';
import { withUserContext } from '../../contexts/UserContext';
import { talentNavList, talentFCNavList, agentNavList, productionCompanyList, crewList, representativeNavList } 
from '../../constants/NavList';

export default withUserContext(class MainContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: true,
            isAdmin: false,
            isCreate: false,
            isFinalApprover: false,
            sideNavLeft: true,
            isSlim: false,
            user_name: localStorage.getItem("user_name"),
            activeTab: "",
            isOpen: false,
            active_route: "",
            showTalentPopup: false,
            showAgencyPopup: false,
            showEpisodePopup: false,
            agencyId: null,
            repId: null,
            epId: null,
            workId: null,
            seasonId: null,
            statusDateId: null,
            productionId: null,
            showProductionPopup: false,
            showCrewPopup: false,
            showRepresentativePopup: false,
            showRolesPopup: false,
            showMainProductionPopup: false,
            showStatusDate: false,
            showRolesPopup: false,
            showMainProductionPopup: false,
            statusDateMode: "New",
            showAddLocationPopUp: false,
            showAddStaffMemberPopUp: false,
            showAddWorkWeekPopUp: false,
            // showNewEpisodePopUp: false,
            showNewSeasonPopUp: false,
            showChangeLocationPopUp: false,
            episodeMode: "New",
            showNewShowPopUp: false,
            showLookup: false,
            showDataQuery: false,
            showNewAudition: false,
            newAuditionMode: "New",
            showPerformer: false,
            showEditWorkActivity: false,
            showReports: false,
            reportsNavList: [],
            reportsNavListPerformer: [],
            isManageUser: false,
            defaultTabValue: 0,
            talentId: null,
            disableSideNav: false,
            refreshPage: { "modal": '', "value": false },
        }
        this.mainContent = React.createRef();
    }
    toggleSideNav = () => {

        this.setState({
            isExpanded: !this.state.isExpanded
        });

    }
    handleSideNavLeft = () => {
        this.setState(
            {
                sideNavLeft: !this.state.sideNavLeft,
                slim: !this.state.isSlim,
                isExpanded: !this.state.isExpanded
            });
    }

    setActiveTab = (value) => {
        this.setState({ activeTab: value });
    }

    handleSideNavActive = (value, routePath) => {
        this.setActiveTab(value);
        this.props.history.push(routePath, this.state);
    }

    switchTenant = (tenant_id) => {
        this.props.switchTenant(tenant_id);
    }

    functions = {
        handleShowTalentPopup: (id = null) => {
            this.setState({ showTalentPopup: true, talentId: id, disableSideNav: true });
        },
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false, disableSideNav: false });
        },
        handleShowAgencyPopup: (agencyId = null) => {
            this.setState({ showAgencyPopup: true, agencyId: agencyId, disableSideNav: true });
        },
        handleHideAgencyPopup: () => {
            this.setState({ showAgencyPopup: false, disableSideNav: false });
        },
        handleShowProductionPopup: () => {
            this.setState({ showProductionPopup: true, disableSideNav: true });
        },
        handleShowCrewPopup: () => {
            this.setState({ showCrewPopup: true, disableSideNav: true });
        },
        handleHideCrewPopup: () => {
            this.setState({ showCrewPopup: false, disableSideNav: false });
        },
        handleHideProductionPopup: () => {
            this.setState({ showProductionPopup: false, disableSideNav: false });
        },
        handleShowRepresentativePopup: (repId = null) => {
            this.setState({ showRepresentativePopup: true, repId: repId, disableSideNav: true });
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false, disableSideNav: false });
        },
        handleShowRolePopup: () => {
            this.setState({ showRolesPopup: true, disableSideNav: true });
        },
        handleHideRolePopup: () => {
            this.setState({ showRolesPopup: false, disableSideNav: false });
        },
        handleShowMainProductionPopup: (mode, id) => {
            this.setState({ showMainProductionPopup: true, episodeMode: mode, productionId: id, disableSideNav: true });
        },
        handleHideMainProductionPopup: () => {
            this.setState({ showMainProductionPopup: false, disableSideNav: false });
        },
        handleShowStatusDate: (mode, id) => {
            this.setState({ showStatusDate: true, statusDateMode: mode, statusDateId: id, disableSideNav: true });
        },
        handleHideStatusDate: () => {
            this.setState({ showStatusDate: false, disableSideNav: false });
        },
        handleAddLocaion: () => {
            this.setState({ showAddLocationPopUp: true, disableSideNav: true });
        },
        handleHideAddLocation: () => {
            this.setState({ showAddLocationPopUp: false, disableSideNav: false });
        },
        handleAddStaffMember: () => {
            this.setState({ showAddStaffMemberPopUp: true, disableSideNav: true });
        },
        hideAddStaffMember: () => {
            this.setState({ showAddStaffMemberPopUp: false, disableSideNav: false });
        },
        handleAddWorkWeek: () => {
            this.setState({ showAddWorkWeekPopUp: true, disableSideNav: true });
        },
        hideAddWorkWeek: () => {
            this.setState({ showAddWorkWeekPopUp: false, disableSideNav: false });
        },
        handleNewSeason: (mode, id) => {
            this.setState({ showNewSeasonPopUp: true, episodeMode: mode, seasonId: id, disableSideNav: true })
        },
        hideNewSeason: () => {
            this.setState({ showNewSeasonPopUp: false, disableSideNav: false });
        },
        handleNewShow: (mode, id) => {
            this.setState({ showNewShowPopUp: true, episodeMode: mode, production_id: id, disableSideNav: true })
        },
        hideNewShow: () => {
            this.setState({ showNewShowPopUp: false, disableSideNav: false });
        },
        handleChangeLocation: () => {
            this.setState({ showChangeLocationPopUp: true, disableSideNav: true });
        },
        hideChangeLocation: () => {
            this.setState({ showChangeLocationPopUp: false, disableSideNav: false });
        },
        handleShowEpisode: (mode, id) => {
            this.setState({ showEpisodePopup: true, episodeMode: mode, epId: id, disableSideNav: true });
        },
        handleHideEpisode: () => {
            this.setState({ showEpisodePopup: false, disableSideNav: false });
        },
        handleNewAudition: () => {
            this.setState({ showNewAudition: true, disableSideNav: true });
        },
        hideNewAuditon: () => {
            this.setState({ showNewAudition: false, disableSideNav: false });
        },
        handleEditRider: () => {
            this.setState({ showEditRider: true, disableSideNav: true });
        },
        hideEditRider: () => {
            this.setState({ showEditRider: false, disableSideNav: false })
        },
        handlePerformer: () => {
            this.setState({ showPerformer: true, disableSideNav: true })
        },
        hidePerformer: () => {
            this.setState({ showPerformer: false, disableSideNav: false })
        },
        showLookupPopup: () => {
            this.setState({ showLookup: true, disableSideNav: true })
        },
        hideLookupPopup: () => {
            this.setState({ showLookup: false, disableSideNav: false })
        },
        handleEditWokActivity: (id) => {
            this.setState({ showEditWorkActivity: true, workId: id })
        },
        hideEditWorkActivity: () => {
            this.setState({ showEditWorkActivity: false })
        },
        handleReports: () => {
            this.getTabContent();
        },
        hideReports: () => {
            this.setState({ showReports: false, disableSideNav: false })
        },
        showDataQuery: () => {
            this.setState({ showDataQuery: true, disableSideNav: true })
        },
        hideDataQuery: () => {
            this.setState({ showDataQuery: false, disableSideNav: false })
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active_route !== this.props.active_route) {
            this.props.history.push(this.props.active_route, this.state);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {
            this.setState({
                active_route: nextProps.active_route,
            });

        }
    }

    getTabContent = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/uiControlsConfiguration?tableName=lu_reports_list`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (this.props.userContext?.active_tenant?.tenant_name !==  "Feature Casting") {
                    let formattedList = response.data?.map((item, index) => ({ label: item.report_name, index: item.report_id_ui }));
                    this.setState({ reportsNavList: formattedList, showReports: true, defaultTabValue: formattedList[0].index, disableSideNav: true });
                } else {
                    let productionList = response.data?.filter(item => item.report_type === "Production")
                    let myArray = { report_name: "Production", report_id_ui: null ,};
                    productionList.unshift(myArray)
                    let performerList = response.data?.filter(item => item.report_type === "Performers")
                    let myPerformerArray = { report_name: "Performers", report_id_ui: null };
                    performerList.unshift(myPerformerArray)
                    let formatted_list_production = productionList?.map((item, index) => ({ label: item.report_name, index: item.report_id_ui }));
                    let formatted_list_performer = performerList?.map((item, index) => ({ label: item.report_name, index: item.report_id_ui }));
                    this.setState({
                        reportsNavList: formatted_list_production, reportsNavListPerformer: formatted_list_performer,
                        showReports: true, defaultTabValue: formatted_list_production[1].index, disableSideNav: true
                    });
                }

            },
                (err) => {
                    console.log("Error in fetching Signatory:", err)
                    this.setState({ showReports: true });
                })
    }
    toggleRefreshPage = (modalName) => {
        let refreshPage = {
            "modal": modalName,
            "value": !this.state.refreshPage?.value              
        }
        this.setState({ refreshPage: refreshPage })
      }
    render() {
        return (

            <>
                <div className='caster-feature'>
                    {/* <HeaderContent {...this.props} {...this.state} 
                  authState={this.props.authState} 
                  switchTenant = {this.switchTenant.bind(this)}
                  setActiveTab = {this.setActiveTab.bind(this)} /> */}
                    <MDBContainer fluid >
                        <MDBRow className="mainContent">

                            {/* <MDBCol className="col-half" disabled={this.state.disableSideNav}>
                                <SideNav {...this.props} {...this.functions} />
                            </MDBCol> : null */}
                            <MDBCol className="col-qtr" >

                            </MDBCol>
                            <MDBCol className="w-auto main-content" id="main-content">

                                <div ref={this.mainContent} >
                                    <ErrorBoundary>
                                        <Routes authState={this.props.authState} {...this.props} {...this.state}
                                            handleSideNavActive={this.handleSideNavActive.bind(this)}
                                            toggleRefreshPage={this.toggleRefreshPage.bind(this)}
                                            refreshPage={this.state?.refreshPage}
                                            setActiveTab={this.setActiveTab.bind(this)} />
                                    </ErrorBoundary>
                                </div>
                            </MDBCol >
                            <MDBCol className="col-qtr" >

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <div className="modals">
                        {
                            this.state.showAgencyPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showAgencyPopup}
                                    handleClose={this.functions.handleHideAgencyPopup}
                                    tabList={agentNavList}
                                    bodyText={"Search for an Agency to view its Data"}
                                    searchPlaceholder={"Search by Agency name"}
                                    addButtonText={"New Agency"}
                                    agencyId={this.state.agencyId} objectType={'AGENCY'}
                                    headerText={"Agency"} dataUrl='agencyDetails' /> : null
                                    
                        }
                        {
                            this.state.showTalentPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    talentId={this.state.talentId}
                                    open={this.state.showTalentPopup}
                                    handleClose={this.functions.handleHideTalentPopup}
                                    tabList={this.props.userContext?.active_tenant?.tenant_name !==  "Feature Casting" ? 
                                        talentNavList : 
                                        talentFCNavList
                                    }
                                    bodyText={"Search for Talent to view their Data, or create a new Talent"}
                                    addButtonText={"New Talent"}
                                    searchPlaceholder={"Search Talent by name or SSN"} objectType={'TALENT'}
                                    headerText={"Talent"} dataUrl='talentDetails' /> : null
                                    
                        }
                        {
                            this.state.showProductionPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showProductionPopup}
                                    handleClose={this.functions.handleHideProductionPopup}
                                    tabList={productionCompanyList}
                                    bodyText={"Search for Production Companies to view its Data"}
                                    searchPlaceholder={"Search production companies by name"}
                                    addButtonText={"New Production Co."} objectType={'PRODUCTION_COMPANY'}
                                    headerText={"Production Companies"} dataUrl='productionCompany' /> : null
                        }
                        {
                            this.state.showCrewPopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showCrewPopup}
                                    handleClose={this.functions.handleHideCrewPopup}
                                    tabList={crewList}
                                    bodyText={"Search for a Crew Member to view their Data"}
                                    searchPlaceholder={"Search by Crew Member name"}
                                    addButtonText={"New Crew Member"} objectType={'CREW'}
                                    headerText={"Crew"} dataUrl='crewDetails' 
                                    {...this.props}/> : null
                        }
                        {
                            this.state.showRepresentativePopup ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showRepresentativePopup}
                                    handleClose={this.functions.handleHideRepresentativePopup}
                                    tabList={representativeNavList}
                                    bodyText={"Search for a Representative to view its Data"}
                                    searchPlaceholder={"Search by Representative name"}
                                    addButtonText={"New Representative"}
                                    repId={this.state.repId} objectType={'REPRESENTATIVE'}
                                    headerText={"Representatives"} dataUrl='representativeDetails' /> : null
                        }
                        {
                            this.state.showReports ?
                                <ModalComponent
                                    navFuns={this.functions}
                                    open={this.state.showReports}
                                    handleClose={this.functions.hideReports}
                                    reportsTabList={this.state.reportsNavList}
                                    reportsNavList={this.state.reportsNavListPerformer}
                                    bodyText={"Search for a Report to view its Data"}
                                    defaultTabValue={this.state.defaultTabValue}
                                    //   searchPlaceholder={"Search by Representative name or Agency name"}
                                    //   addButtonText={"New Representative"}
                                    headerText={"Reports"}
                                //   dataUrl='representativeDetails' 
                                /> : null
                        }
                        {
                            this.state.showRolesPopup &&
                            <MainViewModalComponent
                                open={this.state.showRolesPopup}
                                handleClose={this.functions.handleHideRolePopup}
                                headerText={"Add Performers by Roles"}
                                modalName={"Roles"}
                                navFuns={this.functions}
                                mode={'Both'}
                            />

                        }
                        {
                            this.state.showMainProductionPopup &&
                            <MainViewModalComponent
                                open={this.state.showMainProductionPopup}
                                handleClose={this.functions.handleHideMainProductionPopup}
                                headerText={this.state.episodeMode === "New" ? "Production Details" : "Production Details"}
                                mode={this.state.episodeMode}
                                id={this.state.productionId}
                                modalName={"Main Production"} />
                        }
                        {
                            this.state.showStatusDate &&
                            <MainViewModalComponent
                                open={this.state.showStatusDate}
                                handleClose={this.functions.handleHideStatusDate}
                                headerText={"Status Date"}
                                modalName={"Status Date"}
                                mode={this.state.statusDateMode}
                                id={this.state.statusDateId}
                            />
                        }
                        {
                            this.state.showAddLocationPopUp &&
                            <MainViewModalComponent
                                open={this.state.showAddLocationPopUp}
                                handleClose={this.functions.handleHideAddLocation}
                                headerText={"Add Location"}
                                modalName={"Add Location"}
                            // mode={this.state.addLocationMode}
                            />
                        }
                        {
                            this.state.showAddStaffMemberPopUp &&
                            <MainViewModalComponent
                                open={this.state.showAddStaffMemberPopUp}
                                handleClose={this.functions.hideAddStaffMember}
                                headerText={"Add Staff Member"}
                                modalName={"Add Staff Member"}
                                mode={"New"}
                            />
                        }
                        {
                            this.state.showAddWorkWeekPopUp &&
                            <MainViewModalComponent
                                open={this.state.showAddWorkWeekPopUp}
                                handleClose={this.functions.hideAddWorkWeek}
                                headerText={"Add Work Week"}
                                modalName={"Add Work Week"}
                            // mode={this.state.addLocationMode}
                            />
                        }
                        {
                            this.state.showNewSeasonPopUp &&
                            <MainViewModalComponent
                                open={this.state.showNewSeasonPopUp}
                                handleClose={this.functions.hideNewSeason}
                                headerText={this.state.episodeMode === "New" ? "Season" : "Season Details"}
                                modalName={"New Season"}
                                mode={this.state.episodeMode}
                                id={this.state.seasonId}
                            />
                        }
                        {
                            this.state.showNewShowPopUp &&
                            <MainViewModalComponent
                                open={this.state.showNewShowPopUp}
                                handleClose={this.functions.hideNewShow}
                                headerText={this.state.episodeMode === "New" ? "Show" : "Show Details"}
                                modalName={"New Show"}
                                mode={this.state.episodeMode}
                                id={this.state.production_id}
                                production_id={this.state.production_id}
                            />
                        }
                        {
                            this.state.showChangeLocationPopUp &&
                            <MainViewModalComponent
                                open={this.state.showChangeLocationPopUp}
                                handleClose={this.functions.hideChangeLocation}
                                headerText={"Change Location"}
                                modalName={"Change Location"}
                                mode={"Edit"}
                            />
                        }
                        {
                            this.state.showEpisodePopup &&
                            <MainViewModalComponent
                                open={this.state.showEpisodePopup}
                                handleClose={this.functions.handleHideEpisode}
                                headerText={"Episode"}
                                modalName={"Episode"}
                                mode={this.state.episodeMode}
                                id={this.state.epId}
                            />
                        }
                        {
                            this.state.showNewAudition &&
                            <MainViewModalComponent
                                open={this.state.showNewAudition}
                                handleClose={this.functions.hideNewAuditon}
                                headerText={"New Audition"}
                                modalName={"New Audition"}
                                mode={this.state.newAuditionMode}
                            />
                        }
                        {
                            this.state.showEditRider &&
                            <MainViewModalComponent
                                open={this.state.showEditRider}
                                handleClose={this.functions.hideEditRider}
                                headerText={"Edit Rider"}
                                modalName={"Edit Rider"}
                                mode={'Both'}
                            />
                        }
                        {
                            this.state.showPerformer &&
                            <MainViewModalComponent
                                open={this.state.showPerformer}
                                handleClose={this.functions.hidePerformer}
                                headerText={"Add Performer"}
                                modalName={"Add Performer"}
                            />
                        }
                        {
                            this.state.showLookup &&
                            <MainViewModalComponent
                                open={this.state.showLookup}
                                handleClose={this.functions.hideLookupPopup}
                                headerText={"Manage Lookups"}
                                modalName={"Manage Lookups"}
                                mode={'Both'}
                            />
                        }
                        {
                            this.state.showDataQuery &&
                            <MainViewModalComponent
                                open={this.state.showDataQuery}
                                handleClose={this.functions.hideDataQuery}
                                navFuns={this.functions}
                                headerText={"Data Query"}
                                modalName={"Data Query"}
                                mode={'Both'}
                            />
                        }
                        {
                            this.state.showEditWorkActivity &&
                            <MainViewModalComponent
                                open={this.state.showEditWorkActivity}
                                handleClose={this.functions.hideEditWorkActivity}
                                headerText={"Work Activity"}
                                modalName={"Edit Work Activity"}
                                id={this.state.workId}
                                mode={'Edit'}
                            />
                        }
                    </div>
                </div>
            </>

        );
    }
});

// export default MainContent;