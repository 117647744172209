import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import './TabComponent.scss';

class TabComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0
        }
    }

    a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue
            }
        }
        return null
    }

    render() {
        return (
            <Box className='TabComponent' sx={{ width: '100%' }}>
            <Badge color="primary" className='badge'>
                <Tabs
                    value={this.props.value}
                    onChange={this.props.onChange}
                    textColor={"primary"}
                    className="root"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                >
                    {this.props?.tabList.map((tabItem, index) => (
                        <Tab key={index} value={tabItem.value} label={tabItem.label} />

                    ))}
                </Tabs>
            </Badge>
        </Box>
        );
    }
}

export default TabComponent;