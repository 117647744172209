import React, { Component } from 'react'
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol } from 'mdbreact';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Reports.scss';

class ReportButton extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const content = this.props?.postInitiatedWorksheet === true
            ? this.props?.postInitiatedWorksheet
            : this.props?.postInitiated

        return (
            <MDBRow className="ButtonRow">
                <MDBCol className="ButtonCol" >
                    <BasicButton
                        variant="contained"
                        type="inline"
                        text={content ? (
                            <CircularProgress color="inherit" size={18} />
                          ) : "Generate PDF"}
                        onClick={() => this.props?.onClickGenratePdf()}
                        disabled={false}
                    />
                </MDBCol>
                <MDBCol md={3}>
                    <BasicButton
                        variant="outlined"
                        type="inline"
                        text={"Cancel"}
                        onClick={this.props?.handleClose}
                    />
                </MDBCol>
            </MDBRow>
        )
    }
}
export default withUserContext(ReportButton);
