import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
// import '../../Common/MainViewModalLayout/Modal.scss';

export default class PerformerConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return(
            <div id="confirmationModal">
            <MDBContainer>
                <MDBRow>
                    <div>{this.props?.confirmationMessage}</div>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicButton
                            text={this.props?.primaryButtonText}
                            variant="outlined"
                            type="inline"
                            onClick={this.props?.updateRecord}
                        />
                    </MDBCol>
                    <MDBCol md={5}>
                        <BasicButton 
                            text={this.props?.secondaryButtonText}
                            variant="contained"
                            type="inline"
                            onClick={this.props?.updateTalentProfile}
                        />
                    </MDBCol>
                    <MDBCol md={1}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            text="Cancel"
                            variant="outlined"
                            type="inline"
                            icon="times"
                            onClick={this.props?.onClose}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        )    
    }
}
