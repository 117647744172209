const CustomSort = (list,isSortByAsc,typefield)=>{
const order = {1:0, null:1, 0:2}
const sortList=list?.sort((a,b)=>{
    const comparison =isSortByAsc ? order[a[typefield]] -order[b[typefield]] : order[b[typefield]] -order[a[typefield]];
    return comparison;
});
 return sortList;
}
const CustomSortForString = (list,isSortByAsc,typefield) =>{
    const sortList = list?.sort((a,b)=>{
        if(a[typefield] === null && b[typefield] === null){
            return 0;
        }else if (a[typefield] === null){
            return isSortByAsc ? -1 :1;    
        }else if(b[typefield] === null){
            return isSortByAsc ? 1 : -1;   
        }else{
       const aValue = parseInt(a[typefield],10);   
       const bValue = parseInt(b[typefield],10);
       const comparison = isSortByAsc ? aValue - bValue : bValue - aValue;
       return comparison;
        }
    });
    return sortList;
}
const CustomSortForFile = (list,isSortByAsc,typefield) =>{
    const sortList = list?.sort((a,b)=>{
     if(a[typefield] === null && b[typefield] === null){
        return 0;
     }else if(a[typefield] === null){
        return isSortByAsc ? 1 : -1;
     }else if(b[typefield] === null){
        return isSortByAsc ? -1 :1;
     }else if(a[typefield] <a[typefield]){
        return isSortByAsc ? -1 :1;
     }else if (a[typefield]> a[typefield]){
        return isSortByAsc ? 1 :-1;
     }
       return 0;
    });
    return sortList;
}

export const handlePaginationList = (page, rowsPerPage, list) => {
    let startIndex = page * rowsPerPage;
    let lastIndex = startIndex + rowsPerPage;
    let paginatedList = list?.slice(startIndex, lastIndex);
    return paginatedList;
}

export const updateTableSortConfigObject = (configObject, isSortByAsc, currentSortBy, nextSortBy) => {
    let newHeadingArray = configObject.headings.map(item => {
        if ((item.headingLabel === nextSortBy || item.chipAlternateText ===nextSortBy) && nextSortBy !== "Actions" && nextSortBy !== "Attachment" && configObject?.identityKey!=="billingTable") {
            item.icon = isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
        } else if (nextSortBy !== currentSortBy && (item.headingLabel === currentSortBy || item.chipAlternateText === currentSortBy) && nextSortBy !== "Actions" && nextSortBy !== "Attachment" && configObject?.identityKey!=="billingTable") {
            item.icon = "";
        }
        return item;
    });
    delete configObject.headings
    configObject.headings = newHeadingArray;
    return configObject;
}

export const tableSortList = (type, typeField, list, isSortByAsc) => {
    let sortedList;
    switch (type) {
        case "Name":
            if(typeField === "currentFileName"){
                sortedList = CustomSortForFile(list, isSortByAsc, typeField);
            }else{
            sortedList = list.sort(function (a, b) {
                if (!a[typeField] && !b[typeField]) {
                    return 0;
                } else if (a[typeField] && !b[typeField]) {
                    return -1;
                } else if (!a[typeField] && b[typeField]) {
                    return 1;
                } else {
                    const value1 = a[typeField].toString().toUpperCase(); // ignore upper and lowercase
                    const value2 = b[typeField].toString().toUpperCase(); // ignore upper and lowercase
                    if (value1 < value2) {
                        return isSortByAsc ? -1 : 1;
                    } else if (value1 > value2) {
                        return isSortByAsc ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
            });
        }
            break;

        case "Date":
            if (isSortByAsc) { 
                sortedList = list?.sort((a, b) => (Date.parse(a[typeField]?.toString().trim()) > Date.parse(b[typeField]?.toString().trim())) ? 1 : -1);
            } else {
                sortedList = list?.sort((a, b) => (Date.parse(b[typeField]?.toString().trim()) > Date.parse(a[typeField]?.toString().trim())) ? 1 : -1);
            }
            break;
        case "Number":
            if(typeField === "role_no"){
                sortedList = CustomSortForString(list, isSortByAsc, typeField);
            }else{
            sortedList = CustomSort(list, isSortByAsc, typeField);
            }
            // if (isSortByAsc) {
            //     sortedList = list?.sort((a, b) => (a[typeField] - b[typeField]))
            // }
            // else {
            //      sortedList = list?.sort((a, b) => (b[typeField] - a[typeField]))
            // }
            break;
    }
    return sortedList;
}


export const applyFilterTable = (config, data,  filterValues, filtersNone) =>{
    var finalList = [];
    var finalResult = data.slice();
    var primaryKey = config.primaryKey;
    if(!filtersNone){

    filterValues.map((filter) => {
    var keyValue = filter.toString().split(":");
    var name = keyValue[0].trim();
    var value = keyValue[1].trim().toLowerCase();

    var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

    Object.keys(config?.headings).map((item) => {
        console.log(config?.headings[item])
        if(config?.headings[item].headingLabel == name)
            { 
              index = item;
              return;
            }
      });
    
    var nodeName = config?.dataNodes[index];    
    var filteredList = finalResult?.filter((item) => {
        if(nodeName === 'groups') {
            return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.label?.toString().toLowerCase().indexOf(value) > -1  ) : false; 
        }
        else{
            return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false; 
        }
    })   
    //finalResult = finalResult.concat(filteredList?.filter((list) => !finalResult.find(p => p[primaryKey] == list[primaryKey])));
    // let finalResultElements = finalResult.map(item => item[primaryKey])
    // let afterFilter = finalResultElements.length == 0 ? filteredList : filteredList.filter(item => finalResultElements.includes(item[primaryKey]))
    finalResult = filteredList.slice()
    });
    }
    else{
      finalResult = data;
    }
    //this.setState({filteredList : finalResult});    
    return finalResult;
  }

