import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InlineSelectField from '../../components/SharedComponents/CommonInlineSelectField/InlineSelectField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import ActionButtons from '../../components/SharedComponents/CommonActionButtons/BasicActionButtons';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { validateAllPhone, formatPhoneNumbers, validateNumbersOnly, validatePlusBracketHypenNumbersOnly, validatePlusCharacter, validateIntlNumber, validateUSNumber, validatePhoneNumber } from '../../Common/Helper';
import './PhoneComponent.scss';
import { withUserContext } from '../../../contexts/UserContext';

class PhoneComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMandatory: false,
            inValidInput: false,
            mandatoryWarning: "Please complete this mandatory field"
        }
    }

    componentDidMount() {

    }

    static getDerivedStateFromProps(props, state) {
        if (props.isEditing !== state.isEditing) {
            return {
                isEditing: props.isEditing
            }
        }
        return null
    }


    onChangeCallback = (field, value) => {
        if (value.length > 0) {
            if (!this.props?.inputValidateMethod(value)) {
                this.setState({ inValidInput: true })
            } else {
                this.setState({ showMandatory: false,inValidInput: false })
            }
        } else {
            this.setState({ showMandatory: false,inValidInput: false })
        }
    }
    onAdd(objectItem) {
        this.setState({ showMandatory: false, inValidInput: false });
        console.log("objectItem", objectItem);
        if (!objectItem?.object_type || !objectItem?.value) {
            console.log("in if");
            this.setState({ showMandatory: true })
        }
        else if (this.props?.inputValidateMethod && !this.props?.inputValidateMethod(objectItem?.value)) {
            this.setState({ inValidInput: true })
            console.log("in else if");
        }
        else {
            console.log("in else");
            this.setState({ showMandatory: false })
            this.props?.addCallback(objectItem);
        }
    }
    render() {
        const { objectItem } = this.props;
        // let error = this.props?.objectItem?.value == "" || this.props?.fieldValid || false;
        return (
            <div className="PhoneContainer">
                <MDBRow>
                    {this.props?.label && <BasicLabel text={this.props?.label || ""}
                        isMandatory={this.props?.isMandatory || false} />}
                </MDBRow>
                {/* <MDBRow className={this.props?.isTalent && this.props?.showAsLabel ? 'g-0 marginRight' : 'g-0'}> */}
                <MDBRow className={this.props?.isTalent && this.props?.showAsLabel ? '' : 'g-0'}>
                    {this.props?.showAsLabel ?
                        <>
                            {this.props?.showLabel ? <BasicLabel text={this.props?.labelNew} /> : null}
                            <div className={`displayPhoneNumbers ${objectItem?.is_primary == 1 ? 'primaryStarIcons' : ''}`}>
                                <span className={objectItem?.is_primary == 1 && 'primaryStarIcon'}>
                                {`${objectItem?.object_type ? objectItem?.object_type : ''} ${objectItem?.value ? objectItem?.value + ' ' : ' '} ${objectItem?.ext ? 'ext. ' + objectItem?.ext : ''}` || '-'}
                                </span>
                                </div>
                        </>
                        :
                        <MDBCol md={this.props?.originPoint === "newCrew" ? 10 : 9}  xl={10} className="PhoneField">
                            <InlineSelectField id={"key17"}
                                textFieldLength={8}
                                selectFieldLength={4}
                                textFieldposition="end"
                                // showAsLabel={this.props?.showAsLabel || false}
                                disabled={this.props?.disabled || false}
                                placeholder= {this.props?.placeholder || "(___) ___-____"}
                                extPlaceHolder="Ext"
                                // placeHolderText="Phone Type"
                                limit={this.props?.disabled ? this.props?.limit + 2 : this.props?.limit}
                                limitWarning={`Exceeded ${this.props?.limit} Numbers limit. Please re-enter`}
                                inValidInput={!this.props?.enableSecondaryError ? this.props?.inValidInput : ''}
                                selectValue={objectItem?.object_type}
                                fieldValue={objectItem?.value}
                                extFieldValue={objectItem?.ext}
                                onSelectChange={(e) => this.props?.editCallback(objectItem?.object_id, 'object_type', e.target.value)}
                                onFieldChange={(e) => 
                                    {
                                    this.props?.editCallback(objectItem?.object_id, 'value', validatePlusBracketHypenNumbersOnly(e.target.value) ? formatPhoneNumbers(e.target.value) : formatPhoneNumbers(e.target.value))
                                    this.props?.enableSecondaryError && this.onChangeCallback(objectItem?.phone, e.target.value?.length > 0 ? formatPhoneNumbers(e.target.value) : null)
                                }
                                    
                                }
                                // onFieldChange={(e) => this.onChangeCallback(objectItem?.phone, e.target.value?.length>0?formatPhoneNumbers(e.target.value):null)}
                                onExtFieldChange={(e) => this.props?.editCallback(objectItem?.object_id, 'ext', validateNumbersOnly(e.target.value) ? e.target.value : objectItem?.ext)}
                                options={this.props?.selectOptions || []}
                                isEditing={this.props?.isEditing}
                                ext={this.props?.ext}
                                numberfieldValid={objectItem?.value != null && objectItem?.value?.length > 1 ? objectItem?.value?.length >= 10 : true}
                                showMandatory={this.props?.enableSecondaryError ? false : this.state.showMandatory}
                                showLabel={this.props?.showLabel}
                                labelNew={this.props?.labelNew}
                                isTalent={this.props?.isTalent}
                            />
                        </MDBCol>}
                    <MDBCol md={this.props?.originPoint === "newCrew" ? 2 : ' '}  className={ `col-md-auto ${this.props?.className === "posRelative" ? "posRelative" : null}`} >
                        {this.props?.isEditing &&
                            <ActionButtons
                                keyName={this.props?.keyName}
                                showAdd={this.props?.showAdd || false}
                                showRemove={this.props?.showRemove || false}
                                showRadio={this.props?.showRadio}
                                radioGroupName={this.props?.radioGroupName}
                                isPrimary={objectItem?.is_primary == 1 ? true : false}
                                addCallback={() => this.onAdd(objectItem)}
                                removeCallback={() => this.props?.removeCallback(objectItem?.object_id, 'is_delete', 1)}
                                primaryOnChange={(e) => this.props?.editCallback(objectItem?.object_id, 'is_primary', e.target.checked)}
                                isEditing={this.props?.isEditing}
                                addButton={this.props?.addButton}
                            />
                        }
                    </MDBCol>
                </MDBRow>
                {
               this.props?.enableSecondaryError && this.props?.isEditing &&  
               <MDBRow >   
                <div className='error'>
                {(this.state?.showMandatory) ? this.state.mandatoryWarning :
                        ( (this.state?.inValidInput) ? this.props?.inValidInput: "")}
                 {(this.props.numberfieldValid === false ?  `Exceeded ${this.props?.limit} Numbers limit. Please re-enter` : '' )}
                    </div>   
                     </MDBRow>
                }




                {
                    this.props?.checkedMultiPrimary ? (
                        <MDBRow>
                            <div className="error">{"Only one phone number can be a primary. Multiple selection is not allowed"}</div>
                        </MDBRow>
                    ) : null
                }
            </div>
        );
    }
}

export default withUserContext(PhoneComponent);