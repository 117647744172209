import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel'
import SearchFieldWithAddValue from '../SharedComponents/SearchFieldWithAddValue'
import NewTalent from '../../Common/NewTalent/NewTalent'
import NewCrewModal from '../../Common/NewCrew/NewCrew'
import NewRepresentative from '../../Common/NewRepresentative/NewRepresentative'
import BasicButton from '../SharedComponents/BasicButton/BasicButton'
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal'
import { talentFCNavList } from '../Talent/Config'
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NewAgency from '../../Common/NewAgency/NewAgency'
import { agencyNavList } from '../../../constants/NavList'
import { crewNavList } from '../Crew/Config'
import { representativeFCNavList } from '../Representative/config'
import { list, config } from './config'
import TableComponent from '../SharedComponents/Table'
import "./Dashboard.scss";
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';



export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            talentOptions: [],
            representativeOptions: [],
            agencyOptions: [],
            crewOptions: [],
            talentSelected: null,
            agencySelected: null,
            representativeSelcted: null,
            selectedCrew: null,
            setPopOver: null,
            openPopOver: false,
            showTalentPopup: false,
            isTalentFetch: false,
            isRepresentativeFetch: false,
            openAgencyPopOver: false,
            openCrewPopOver: false,
            setCrewPopOver: null,
            isAgencyFetch: false,
            showAgencyPopup: false,
            showCrewPopup: false,
            representativeId: null,
            showNewProduction: false
        }


    }
    setObjectSelected(type, value) {
        value.optionType == 'edit'
        this.onChange(type, value)

    }

    setNewObjectSelected(type, value) {
        value.optionType == 'save'
        this.onChange(type, value)
    }

    onChange(type, value, e) {
        if (value) {
            switch (type) {
                case 'talent':
                    if (value?.optionType == 'new') {
                        this.setState({ setPopOver: document.getElementById('talentField'), openPopOver: true, talentSelected: value })
                    } else if (value?.optionType == 'save') {
                        this.setState({ setPopOver: document.getElementById('talentField'), talentSelected: value })
                    } else {
                        this.setState({ talentSelected: value, showTalentPopup: true })
                    }
                    break;
                case 'representative':
                    if (value?.optionType == 'new') {
                        this.setState({ setRepresentativePopOver: document.getElementById('representativeField'), openRepresentativePopOver: true, representativeSelected: value })
                    } else if (value?.optionType == 'save') {
                        this.setState({ setRepresentativePopOver: document.getElementById('representativeField'), representativeSelected: value })
                    } else {
                        this.setState({ representativeSelected: value, showRepresentativePopup: true })
                    }
                    break;
                case 'agency':
                    if (value?.optionType == 'new') {
                        this.setState({ setPopOver: document.getElementById('agencyField'), openAgencyPopOver: true, agencyOptions: [], agencySelected: value })
                    }
                    else if (value?.optionType == 'save') {
                        this.setState({ setPopOver: document.getElementById('agencyField'), agencyOptions: [], agencySelected: value })
                    }
                    else {
                        this.setState({ agencySelected: value, showAgencyPopup: true })
                    }
                    break;
                case 'crew':
                    if (value?.optionType === 'new') {
                        this.setState({ setCrewPopOver: document.getElementById('crewField'), openCrewPopOver: true, selectedCrew: value })

                    } else if (value?.optionType == 'save') {
                        this.setState({ setCrewPopOver: document.getElementById('crewField'), selectedCrew: value })
                    } else {
                        this.setState({ selectedCrew: value, showCrewPopup: true, crewOptions: [] })
                        // if (value !== null) {
                        //     this.setState({ showCrewPopup: true })
                        // }
                    }

                default:
                    break;
            }
        }
        else {
            this.setState({ setPopOver: null, openPopOver: false, openRepresentativePopOver: false, setRepresentativePopOver: null, [`${type}Selected`]: null, setCrewPopOver: null, openCrewPopOver: false, setRepresentativePopOver: null, selectedCrew: null })
        }

    }
    getObjectDetails(objectType, searchString = null) {
        this.setState({ [`is${objectType}Fetch`]: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                this.setState({ [`is${objectType}Fetch`]: false })
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = []
                    if (objectType == "Agency") {
                        formattedList = response?.data?.map(i => ({
                            value: i?.id,
                            text: i?.name,
                            aka: i?.aka,
                            address: i?.address,
                            agency_name: i?.agency_name,
                            location_name: i?.location_name,
                        }))
                    } else {
                        formattedList = response?.data?.map(i => ({
                            value: i?.id,
                            text: i?.name,
                            aka: i?.aka,
                            headerName: objectType == "Crew" ? i?.crew_name : objectType == "Representative" ? i?.representative_name : objectType == "Talent" ? i?.talent_name : null
                        }))
                    }
                    if (objectType == "Talent") {
                        this.setState({ talentOptions: formattedList })
                    }
                    if (objectType == "Representative") {
                        this.setState({ representativeOptions: formattedList })
                    }
                    if (objectType == "Crew") {
                        this.setState({ crewOptions: formattedList })
                    }
                    else if (objectType == "Agency") {
                        this.setState({ agencyOptions: formattedList })
                    }
                    console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ [`is${objectType}Fetch`]: false })
                    console.log("Post agency details error: " + err);
                });
    }
    validateTalent(value) {
        console.log(value)
        return true;
    }
    validateRepresentative(value) {
        console.log(value)
        return true;
    }
    validateAgency(value) {
        console.log(value)
        return true;
    }
    validateCrew(value) {
        console.log(value)
        return true;
    }
    handleClosePopOver() {
        if (this.state?.openPopOver) {
            this.setState({ talentSelected: null })
        }
        this.setState({ setPopOver: null, openPopOver: false, openAgencyPopOver: false })
    }
    handleShowTalentPopup() {
        this.setState({ showTalentPopup: true })
    }
    handleHideTalentPopup() {
        this.setState({ showTalentPopup: false })
    }
    handleShowRepresentativePopup() {
        this.setState({ showRepresentativePopup: true })
    }
    handleHideRepresentativePopup() {
        this.setState({ showRepresentativePopup: false })
    }
    handleShowAgencyPopup() {
        this.setState({ showAgencyPopup: true })
    }
    handleHideAgencyPopup() {
        this.setState({ showAgencyPopup: false })
    }
    handleCloseAgencyPopOver() {
        if (this.state.openAgencyPopOver) {
            this.setState({ agencySelected: null })
        }
        this.setState({ openAgencyPopOver: false })
    }
    handleShowCrewPopup() {
        this.setState({ showCrewPopup: true })
    }
    handleHideCrewPopup() {
        this.setState({ showCrewPopup: false })
    }
    handleHideProductionPopup() {
        this.setState({ showNewProduction: false })
    }
    handleCloseCrewPopOver = () => {
        // if(this.state.openCrewPopOver) {
        //     this.setState({ selectedCrew: null })
        // }
        this.setState({ setCrewPopOver: null, openCrewPopOver: false })
    }
    handleCloseRepresentativePopOver = () => {
        this.setState({ setRepresentativePopOver: null, openRepresentativePopOver: false })
    }

    updateRepId = (id) => {
        let representativeSelected = { ...this.state?.representativeSelected }
        representativeSelected['id'] = id
        this.setState({ representativeSelected })
    }

    functions = {
        handleShowTalentPopup: (id = null, talent_name) => {
            let talent = {
                value: id,
                text: talent_name,
                headerName: talent_name,
                aka: talent_name.includes('(') && talent_name.includes(')') ? talent_name?.match(/\(([^)]+)\)/)[1] : " "
            }
            this.setState({ showTalentPopup: true, talentId: id, talentSelected: talent });
        },
        handleHideTalentPopup: () => {
            this.setState({ showTalentPopup: false, talentId: null });
        },
        handleShowAgencyPopup: (id = null, agency_name) => {
            let agency = {
                value: id,
                text: agency_name
            }
            this.setState({ showAgencyPopup: true, agencyId: id, agencySelected: agency })
        },
        handleHideAgencyPopup: () => {
            this.setState({ showAgencyPopup: false });
        },
        handleShowRepresentativePopup: (id = null, representative_name) => {
            let rep = {
                value: id,
                text: representative_name
            }
            this.setState({ showRepresentativePopup: true, representativeId: id, representativeSelected: rep })
        },
        handleHideRepresentativePopup: () => {
            this.setState({ showRepresentativePopup: false, representativeId: null })
        },
        handleHideCrewPopup: () => {
            this.setState({ showCrewPopup: false });
        }
    }
    navigationCallback = (obj, node) => {
        if (node.includes("project_name")) {
            console.log("Proejct Id:", obj?.project_id)
            if (obj?.project_id) {
                this.props.history.push(`/caster-feature/project/${obj?.project_id}`)
            }
        }
    }

    componentDidMount() {
        this.getProjectAll()
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("update from episode and clearance maintitle", prevProps?.refreshPage?.value, this.props?.refreshPage?.value)
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'ProjectDetails') {
            console.log("update from episode and clearance maintitle")
            this.getProjectAll()
            this.props?.toggleRefreshPage('');
        }
    }

    getProjectAll = () => {
        this.setState({ isProductionDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProject`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map((item) => {
                    return {
                        "project_name": item?.project_name,
                        "project_class": item?.project_type,
                        "project_equation": item?.contract_type,
                        "project_id": item?.project_id,
                    }
                })
                this.setState({ projectsAllDetails: formattedList, isProductionDetails: false })
            })
    }
    render() {
        let openCrew = Boolean(this.state.openCrewPopOver)
        let open = Boolean(this.state.openPopOver);
        let openRepresentative = Boolean(this.state.openRepresentativePopOver);
        let id = open ? 'simple-popover' : undefined;
        let crewId = openCrew ? 'simple-popover' : undefined;
        return (
            <MDBContainer className="DashboardOld">
                <div>Dashboard</div>
                <MDBRow>
                    <MDBCol md={6}>
                        <BasicLabel text={"Talent"} />
                        <SearchFieldWithAddValue
                            id='talentField'
                            ref={this.talentSelectRef}
                            searchSelect={true}
                            loading={this.state.isTalentFetch}
                            searchText={ev => {
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.getObjectDetails('Talent', ev.target.value)
                                }
                                else {
                                    this.setState({ isTalentFetch: false })
                                }
                            }}
                            detail_selected={this.state?.talentSelected}
                            options={this.state?.talentOptions || []}
                            validateMethod={this.validateTalent.bind(this)}
                            newLabel={'Talent'}
                            onChange={(e, value) => this.onChange('talent', value, e)}
                            placeholder='-Search by Alias, SSN/Foreign Id or SAG ID -'
                        />

                        {this.state?.openPopOver ? <NewTalent
                            id={id}
                            open={this.state.openPopOver}
                            setPopOver={this.state?.setPopOver}
                            handleClose={this.handleClosePopOver.bind(this)}
                            talentData={this.state?.talentSelected}
                            setObjectSelected={this.setObjectSelected.bind(this)}
                            setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                        /> : null}

                    </MDBCol>
                    <MDBCol md={6}>
                        <BasicLabel text={"Representative"} />
                        <SearchFieldWithAddValue
                            id='repField'
                            value={this.state?.representativeSelected}
                            validateMethod={this.validateRepresentative.bind(this)}
                            newLabel={'Representative'}
                            onChange={(e, value) => this.onChange('representative', value, e)}
                            searchSelect={true}
                            detail_selected={this.state?.representativeSelected}
                            placeholder='-Search by Alias or Company -'
                            ref={this.talentSelectRef}
                            loading={this.state.isRepresentativeFetch}
                            searchText={ev => {
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.getObjectDetails('Representative', ev.target.value)
                                }
                                else {
                                    this.setState({ isRepresentativeFetch: false })
                                }
                            }}
                            options={this.state?.representativeOptions || []}
                        // validateMethod={this.validateTalent.bind(this)}
                        />

                        {this.state?.openRepresentativePopOver ? <NewRepresentative
                            id={id}
                            // open={true}
                            open={this.state.openRepresentativePopOver}
                            setPopOver={this.state?.setRepresentativePopOver}
                            handleClose={this.handleCloseRepresentativePopOver.bind(this)}
                            representativeData={this.state?.representativeSelected}
                            updateRepId={this.updateRepId.bind(this)}
                            setObjectSelected={this.setObjectSelected.bind(this)}
                            setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                        /> : null}
                    </MDBCol>
                    <MDBCol md={6}>
                        <BasicLabel text={"Agency"} />
                        <SearchFieldWithAddValue
                            id='agencyField'
                            ref={this.agencySelectRef}
                            searchSelect={true}
                            loading={this.state.isAgencyFetch}
                            searchText={ev => {
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.getObjectDetails('Agency', ev.target.value)
                                }
                                else {
                                    this.setState({ isAgencyFetch: false })
                                }
                            }}
                            value={this.state?.agencySelected}
                            detail_selected={this.state?.agencySelected}
                            options={this.state?.agencyOptions || []}
                            validateMethod={this.validateAgency.bind(this)}
                            newLabel={'Agency'}
                            onChange={(e, value) => this.onChange('agency', value, e)}
                            placeholder='-Search by Alias -'
                            // renderOption={(props, option) => (
                            //     <>
                            //         <li {...props}>
                            //             <SearchResultsLayout searchResult={props}></SearchResultsLayout>
                            //         </li>
                            //     </>
                            // )}
                        />
                        {this.state.openAgencyPopOver && <NewAgency
                            id={id}
                            open={this.state.openAgencyPopOver}
                            setPopOver={this.state.setPopOver}
                            handleClose={this.handleHideAgencyPopup.bind(this)}
                            handleCloseAgencyPopOver={this.handleCloseAgencyPopOver.bind(this)}
                            agencySelected={this.state.agencySelected}
                            setObjectSelected={this.setObjectSelected.bind(this)}
                            getObjectDetails={this.getObjectDetails.bind(this)}
                            setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                        />}
                    </MDBCol>
                    <MDBCol md={6}>
                        <BasicLabel text={"Crew"} />
                        <SearchFieldWithAddValue
                            id='crewField'
                            searchSelect={true}
                            ref={this.crewSelectedRef}
                            loading={this.state.isCrewFetch}
                            value={this.state?.selectedCrew}
                            detail_selected={this.state?.selectedCrew}
                            options={this.state?.crewOptions || []}
                            validateMethod={this.validateCrew.bind(this)}
                            onChange={(e, value) => this.onChange('crew', value, e)}
                            searchText={ev => {
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.getObjectDetails('Crew', ev.target.value)
                                }
                                else {
                                    this.setState({ isCrewFetch: false })
                                }
                            }}
                            newLabel={'Crew'}
                            placeholder='-Search by Alias or Occupation -'
                        />
                        {this.state.openCrewPopOver ? <NewCrewModal
                            isDashboard={true}
                            id={crewId}
                            open={this.state.openCrewPopOver}
                            setPopOver={this.state.setCrewPopOver}
                            handleClose={this.handleCloseCrewPopOver.bind(this)}
                            crewData={this.state?.selectedCrew}
                            navList={crewNavList}
                            getObjectDetails={this.getObjectDetails.bind(this)}
                            setObjectSelected={this.setObjectSelected.bind(this)}
                            setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                        /> : null}
                    </MDBCol>
                </MDBRow>
                <MDBRow className="tableRow">
                    <MDBCol className="projectCol">
                        <BasicButton
                            text={"New Project"}
                            onClick={() => this.setState({ showNewProduction: true })}
                        />
                    </MDBCol>
                    <div className="projectTableContainer">
                        {this.state.isLoading ? (
                            <div className="ContentLoader">
                                <CircularProgress size={40} />
                            </div>
                        ) : (
                            <TableComponent
                                config={config}
                                list={this.state.projectsAllDetails}
                                // stickyHeader={true}
                                // loading={this.props?.isFetchingAgencyDetails}
                                // sortCallback={this.props?.handleTableColumnClick}
                                // handleDelCallBack={this.props?.handleDeleteTalent}
                                hyperLinkNavigationCallback={this.navigationCallback}
                            />
                        )}
                    </div>
                </MDBRow>

                {
                    this.state.showTalentPopup ?
                        <ModalComponent
                            id={this.state.talentSelected?.value}
                            open={this.state.showTalentPopup}
                            handleClose={this.handleHideTalentPopup.bind(this)}
                            tabList={talentFCNavList}
                            headerText={"Talent"}
                            mode="edit"
                            isProfileDiv={true}
                            addButtonText={"New Talent"}
                            objectType={'TALENT'}
                            objectId={this.state?.talentSelected?.value}
                            headerName={this.state?.talentSelected?.headerName}
                            akas={this.state.talentSelected?.aka}
                            dataUrl='talentDetails' /> : null
                }
                {
                    this.state.showRepresentativePopup ?
                        <ModalComponent
                            id={this.state.representativeSelected?.value}
                            representativeId={this.state?.representativeId}
                            open={this.state.showRepresentativePopup}
                            handleClose={this.handleHideRepresentativePopup.bind(this)}
                            tabList={representativeFCNavList}
                            headerText={"Representative"}
                            mode="edit"
                            isProfileDiv={true}
                            addButtonText={"New Representative"}
                            objectType={'REPRESENTATIVE'}
                            objectId={this.state.representativeSelected?.optionType === "new" ? this.state?.representativeSelected?.id : this.state?.representativeSelected?.value}
                            // headerName={this.state.representativeSelected?.optionType === "new" ? this.state.representativeSelected?.value : this.state.representativeSelected?.text}
                            akas={this.state.representativeSelected?.aka ? this.state.representativeSelected?.aka : ""}
                            headerName={this.state.representativeSelected?.optionType === "new" ? this.state.representativeSelected?.value : this.state.representativeSelected?.headerName}
                            navFuns={this.functions}
                        // dataUrl='casterFeatureAgencyDetails' 
                        // objectId={this.state?.representativeSelected?.value || 9028}
                        // dataUrl='casterFeatureRepresentativeDetails'
                        />
                        : null
                }
                {
                    this.state.showAgencyPopup ?
                        <ModalComponent
                            id={this.state.agencySelected?.value}
                            agencyId={this.state?.agencySelected}
                            open={this.state.showAgencyPopup}
                            handleClose={this.handleHideAgencyPopup.bind(this)}
                            tabList={agencyNavList}
                            headerName={this.state?.agencySelected?.agency_name}
                            headerText={"Agency"}
                            mode="edit"
                            isProfileDiv={true}
                            addButtonText={"New Agency"}
                            objectType={"AGENCY"}
                            akas={this.state.agencySelected?.aka}
                            navFuns={this.functions}
                            dataUrl='casterFeatureAgencyDetails' /> : null
                }
                {this.state.showCrewPopup ?
                    <ModalComponent
                        id={this.state.selectedCrew?.value}
                        akas={this.state?.selectedCrew?.aka}
                        open={this.state.showCrewPopup}
                        handleClose={this.handleHideCrewPopup.bind(this)}
                        tabList={crewNavList}
                        headerText={"Crew"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Crew"}
                        objectType={'CREW'}
                        objectId={this.state?.selectedCrew?.value || 1}
                        dataUrl='casterFeatureCrewDetails'
                        headerName={this.state.selectedCrew?.headerName}
                        navFuns={this.functions}
                        {...this.props}
                    /> : null
                }
                {this.state.showNewProduction ?
                    <Modal
                        // id={this.state.selectedCrew?.value}
                        // akas={this.state?.selectedCrew?.aka}
                        open={this.state.showNewProduction}
                        handleClose={this.handleHideProductionPopup.bind(this)}
                        // tabList={crewNavList}
                        modalName={"Project"}
                        headerText={"Project"}
                        mode="New"
                        toggleRefreshPage={this.props.toggleRefreshPage}
                        {...this.props}
                    // refreshPage={this.props?.refreshPage}
                    // isProfileDiv={true}
                    // addButtonText={"New Crew"}
                    // objectType={'CREW'}
                    // objectId={this.state?.selectedCrew?.value || 1}
                    // dataUrl='casterFeatureCrewDetails'
                    // headerName={this.state.selectedCrew?.headerName}
                    /> : null
                }
            </MDBContainer>

        )
    }
}
