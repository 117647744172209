import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import { withRouter } from 'react-router-dom';
import TableComponent from '../../components/SharedComponents/Table';
import { performerConfig } from './config';
import './Location.scss';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import Popover from '@material-ui/core/Popover';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { getFormattedDate } from '../../Common/Helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { add } from 'date-fns';

class Location extends Component {
    constructor(props) {
        super(props)
        this.state = {
            config: JSON.parse(JSON.stringify({ ...performerConfig })),
            loadingPerformer: false,
            performerTableLocationData: [],
            performerOptions: [],
            selectedTalent: null,
            openTalentPerformerModal: false,
            setCommentsDisplayModal: false,
            showLocationPopup: false,
            anchorEl: null,
            sortCount: 0,
            sortBy: "From-To",
        }
    }

    componentDidMount() {
        this.getPerformerLocationDetails(this.props?.project_id)
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("update from project location", prevProps?.refreshPage?.value, this.props?.refreshPage?.value)
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'ProjectLocation') {
            console.log("update from project location")
            this.getPerformerLocationDetails(this.props?.project_id)
            this.props?.toggleRefreshPage('');
        }
    }

    getPerformerLocationDetails = (project_id) => {
        this.setState({ loadingPerformer: true, isLoading: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProjectLocation?project_id=${project_id}`).then((response) => {
            if (response.data.error) {
                this.setState({ loadingPerformer: false, isLoading: false })
                console.log("Getting Project Details API error");
            }
            else {
                console.log("Getting Project Details API error", response);
                let formattedList = response.data?.map((item) => {
                    let curr_state = item?.state
                    if (curr_state?.includes('-')) {
                        let splitArray = curr_state?.split("-")
                        curr_state = splitArray[1]
                      }
                    return {
                        "from-to": `${item.from_date ? getFormattedDate(item.from_date) : ''}${item.to_date ? '-' + getFormattedDate(item.to_date) : ''}`,
                        "location_name": item?.location_name,
                        "email": item?.email,
                        "phone": item?.phone,
                        "address":  `${item["address"] ? item["address"] + '\n' : ''}${item["city"] ? item["city"] + ', ' : ''}${curr_state ? curr_state + ' ' : ''}${item["postal_code"] ? item["postal_code"] : ''}${item["country"] && (item["country"] !== "United States" && item["country"] !== "USA" && item["country"] !== "US") ? (item["city"] || curr_state || item["postal_code"]) ? '\n' + item["country"] : item["country"]: ''}`,
                        "comments": item?.comments,
                        "project_id": item.project_id,
                        "project_location_id": item.project_location_id
                    }
                })
                this.setState({ loadingPerformer: false, performerTableLocationData: formattedList, isLoading: false })
                console.log("API response", response);
            }
        },
            (err) => {
                this.setState({ loadingPerformer: false })
                console.log("Getting Project Details API error" + err);
            })
    }

    getPerformerList = (objectType, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.id, text: i?.talent_name }))
                    this.setState({ performerOptions: formattedList })
                    console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    handleSelectedPerformer = (value) => {
        this.setState({ selectedTalent: value, openTalentPerformerModal: true })
    }

    getSortNode = (col) => {
        switch (col) {
            case "From-To": return "from-to";
            case "Location Name": return "location_name";
            case "Email": return "email";
            case "Phone": return "phone";
            case "Address": return "address";
        }
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            let list = tableSortList("Name", this.getSortNode(colName), this.state.performerTableLocationData, this.state.isSortByAsc)
            this.setState({
                sortBy: colName,
                performerTableLocationData: list
            });
        })
    }
    handleTableColumnClick = (column) => {
        if (column != "Phone" && column != "Email")
            if (column === this.state.sortBy) {
                this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                    this.handleTableSort(column);
                })
            }
            else this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
    }


    handleOpenLicensorInlineModals = (listEdit, rowIndex, data, dataItem, event) => {
        // console.log("dataItem", dataItem, nodeName, rowIndex, event)
        this.setState({
            commentsToDisplay: dataItem?.comments,
            setCommentsDisplayModal: true,
            commentsDisplayFlag: rowIndex,
            anchorEl: event.currentTarget
        })
    }


    handleShowHideLocationPopup(value, project_location_id = null, locationSelected = null) {
        this.setState({
            showLocationPopup: value,
            project_location_id: project_location_id,
            locationSelected: locationSelected
        })
    }

    editCallback(id, dataItem) {
        console.log("edit callback", id, dataItem);
        if (id) {
            this.handleShowHideLocationPopup(true, id, dataItem?.location_name)
        }
    }

    handleDelCallBack(id, dataItem) {
        this.setState({ isLoading: true })
        console.log("del callback", id, dataItem)
        if (id && dataItem) {
            let postDataParams = { ...dataItem, is_delete: 1 }
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProjectLocation', postDataParams)
                .then((response) => {
                    if (response?.data?.error || !response?.data) {
                        // this.setState({ fetchingCrewDetails: false})
                        this.setState({ isLoading: false })
                        this.props?.notificationComponent(true, "fail");
                    }
                    else {
                        this.props?.notificationComponent(true, "Location");
                        this.getPerformerLocationDetails(this.props?.project_id);
                    }
                }, err => {
                    this.props?.notificationComponent(true, "fail");
                    console.log("Error from crew delete")
                })
        }
    }


    render() {
        let openEpsCountModal = Boolean(this.state.setCommentsDisplayModal);
        return (
            <div>
                <div className="location">
                    <input className="hiddenButton" type="button" ref={this.props?.locationButtonRef} onClick={() => this.handleShowHideLocationPopup(true)}></input>
                    <MDBRow className='g-0'>
                        {this.state.isLoading ? (
                            <div className="ContentLoader">
                                <CircularProgress size={40} />
                            </div>
                        ) : (
                            <TableComponent
                                list={this.state.performerTableLocationData || []}
                                className={"perfomer-table"}
                                config={this.state.config}
                                loading={this.state.loadingPerformer}
                                handleEditPopOverCallBack={this.handleOpenLicensorInlineModals}
                                handleDelCallBack={this.handleDelCallBack.bind(this)}
                                editCallback={this.editCallback.bind(this)}
                                sortCallback={this.handleTableColumnClick}
                            />
                        )}
                        <Popover
                            open={openEpsCountModal}
                            className="flag-popover"
                            style={{
                                maxWidth: '1000px',
                                // height: '200px'
                            }}
                            anchorEl={this.state.anchorEl}
                            onClose={() => {
                                this.setState({
                                    setCommentsDisplayModal: null, anchorEl: null
                                })
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}

                        >
                            <div>
                                <div className={"eps-height"}>
                                    <BasicLabel
                                        text={this.state.commentsToDisplay || ""}
                                    />
                                </div>
                            </div>
                        </Popover>
                    </MDBRow>
                    {this.state?.showLocationPopup &&
                        <MainViewModalComponent
                            modalName='Add Project Location'
                            handleClose={() => this.handleShowHideLocationPopup(false)}
                            // mode={'edit'}
                            mode={this.state?.project_location_id ? 'edit' : 'new'}
                            // id={7}
                            id={this.state?.project_location_id || null}
                            project_id={this.props?.project_id}
                            locationSelected={this.state?.locationSelected || null}
                            open={this.state.showLocationPopup}
                            headerText={"Location"}
                            toggleRefreshPage={this.props?.toggleRefreshPage}
                        />}
                </div>

            </div>
        );
    }
}

export default Location;