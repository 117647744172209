import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';
import sizeLimits from '../../../Common/SizeLimits.json';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import { CircularProgress } from '@material-ui/core';
import { validatePlusCharacter, validateIntlNumber, validateUSNumber, validatePhoneNumber, validateEmail, formatPhoneNumbers, validateAllPhone } from '../../../Common/Helper';
import './NewUser.scss';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import { statusOptions, userMockData, projectConfig } from './UserConfig';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';

class NewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: this.formatUserList(JSON.parse(JSON.stringify({ ...userMockData }))),
            rowsPerPage: 10,
            page: 0,
            searchCancelToken: null,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            projectIndex: 2,
            phoneValid: true,
            roleOptions: [],
        };
    }

    formatUserList = (response) => {
        if (response?.user_projects_list?.length === 0) {
            response?.user_projects_list.push(JSON.parse(JSON.stringify({ ...projectConfig })));
        }
        return response;
    }


    deleteRow = (listName, callbackItem, type, index = null) => {
        let userDetails = this.state.userDetails;
        userDetails[listName][this.state.index]['is_delete'] = 1;
        if (type === 'edit') {
            let temp = [];
            this.state.inActiveProjects?.map((item) => {
                temp.push(item);
            });
            let user_projects_list = userDetails[listName].filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(user_projects_list[0]);
            this.setState({ inActiveProjects: temp })
        }
        let user_projects_list = userDetails[listName].filter(item =>
            (item.user_project_id !== callbackItem.user_project_id) || (item.index !== callbackItem.index)
        );
        if (user_projects_list.length === 0) {
            userDetails[listName] = [{ ...projectConfig }];
        } else {
            userDetails[listName] = user_projects_list;
        }
        this.setState({
            userDetails: userDetails,
            listName: null,
            callbackItem: null,
            postInitiated: false,
            isOpen: false,
        });
    }

    canSubmit = (userDetails) => {
        let phoneValid = userDetails?.phone ?
            (validatePlusCharacter((userDetails?.phone.trim()).charAt(0)) ?
                validateIntlNumber(userDetails?.phone) && userDetails?.phone.length <= sizeLimits.phoneLimitIntl :
                (validateUSNumber(userDetails?.phone) || validatePhoneNumber(userDetails?.phone)) && userDetails?.phone.length <= sizeLimits.phoneLimitUS) : true;
        let canSubmit = 
            // userDetails.is_active &&
            userDetails.title &&
            userDetails.login_email && validateEmail(userDetails.login_email) &&
            userDetails.notification_email && validateEmail(userDetails.notification_email) &&
            (userDetails?.phone ? phoneValid : true);
        if (userDetails?.phone) {
            this.setState({ phoneValid: phoneValid })
        }
        return canSubmit;
    }

    handleOnchange = (field, value) => {
        if (field === 'phone') {
            this.setState({
                phoneValid:
                    value.length > 0 ?
                        (validatePlusCharacter((value.trim()).charAt(0)) ?
                            validateIntlNumber(value) : validateUSNumber(value) || validatePhoneNumber(value)) : false,
            });
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value || null,
                },
            }));
        } else if (field === 'login_email' || field === 'notification_email') {
            if (value) {
                if (field === 'login_email') {
                    this.setState({ fieldValid: validateEmail(value) });
                } else {
                    this.setState({ notiEmailFieldValid: validateEmail(value) });
                }
            } else {
                this.setState({ fieldValid: true, notiEmailFieldValid: true });
            }
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        } else {
            this.setState((prevState) => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: value,
                },
            }));
        }
    };

    getFilteredJson = (userDetailsJson) => {
        let projectTemp = [];
        this.state.inActiveProjects?.map(item => {
            projectTemp?.push(item);
        });
        userDetailsJson.user_projects_list?.map(item => {
            projectTemp?.push(item);
        });
        userDetailsJson.user_projects_list = projectTemp;
        userDetailsJson.user_projects_list = userDetailsJson?.user_projects_list?.filter(item => item?.project_id !== null)
        return userDetailsJson;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        let userDetails = { ...this.state.userDetails }
        let postJson = this.getFilteredJson(userDetails);
        postJson.is_active = postJson?.is_active == 2 ? 0 : postJson?.is_active;
        postJson["projrct_id"] = (postJson.role_id === 1 || postJson.role_id === 4) ? 1 : (postJson.role_id === 2 || postJson.role_id === 3) ? 0 : null
        console.log("check the payload on submit", postJson);
        if (this.canSubmit(postJson)) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/animationPostUser', postJson,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props.notificationComponent(true, "fail");
                    } else {
                        this.setState({ postInitiated: false, isPosting: false });
                        this.props.notificationComponent(true, "success");
                        // this.props?.onClose();
                        this.props?.toggleRefresh();
                    }
                },
                    (err) => {
                        console.log("Error in posting submission:", err);
                        this.props.notificationComponent(true, "fail");
                        this.setState({ postInitiated: false, isPosting: false });
                    })
        }
    }

    componentDidMount() {
        this.gerUserRoles("USER_ROLE");
        if (this.props?.mode === 'Edit') {
            this.getUserDetails()
        }    
    }

    gerUserRoles = (entity) => {
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                let formattedList = response?.data?.map(item => ({
                    label: item?.role_name,
                    value: item.user_role_id,
                    is_active: item.is_active
                }));
                this.setState({ roleOptions: formattedList });
            },
                (err) => {
                    console.log(err, "error in option")
                })
    }

    getUserDetails = () => {
        this.setState({ isFetchingUserDetails: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetUser?user_id=${this.props?.userId}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                console.log("check the user get", response)
                let userDetails = response?.data[0];
                this.setState({ userDetails: userDetails, isFetchingUserDetails: false })
            },
                (err) => {
                    console.log(err, "error in option")
                    this.setState({ isFetchingUserDetails: false })
                })
    }

    render() {
        return (
            <MDBContainer fluid className="userContainer">
                <MessageModal
                    open={this.state.isOpen}
                    title={"Delete record"}
                    message={"Would you like to delete saved record?"}
                    primaryButtonText={"Delete"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.deleteRow('user_projects_list', this.state.callbackItem, 'edit')}
                    handleClose={(e) => this.setState({ isOpen: false })}
                />
                {this.state.isFetchingUserDetails ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <>
                        <MDBRow>
                            {this.props.mode === "Edit" ? "" : <p className="user-note">
                                Note: User must be seperately assigned OKTA Verify credentials to access the system.
                            </p>}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <MDBRow className="detail-heading">
                                    <BasicLabel text="Details" />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id={"status"}
                                            label={"Status"}
                                            // defaultMenuText={"-Select-"}
                                            options={statusOptions || []}
                                            value={this.state.userDetails?.is_active===0? 2 : this.state.userDetails?.is_active || 1}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e, value) => this.handleOnchange('is_active', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="name"
                                            label={"User Name"}
                                            value={this.state.userDetails.user_name || ""}
                                            onChange={(e, value) => this.handleOnchange('user_name', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="title"
                                            label={"Title"}
                                            value={this.state.userDetails.title || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e, value) => this.handleOnchange('title', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <SelectField
                                            id={"role"}
                                            label={"Role"}
                                            defaultMenuText={"-Select-"}
                                            options={this.state.roleOptions || []}
                                            value={this.state.userDetails.role_id || ""}
                                            onChange={(e, value) => this.handleOnchange('role_id', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="login-email"
                                            label={"Login Email"}
                                            value={this.state.userDetails.login_email || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            fieldValid={this.state.fieldValid === false ? true : false}
                                            inValidInput={messages.invalidInput}
                                            onChange={(e, value) => this.handleOnchange('login_email', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicTextField
                                            id="notification-email"
                                            label={"Notification Email"}
                                            value={this.state.userDetails.notification_email || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            fieldValid={this.state.notiEmailFieldValid === false ? true : false}
                                            inValidInput={messages.invalidInput}
                                            onChange={(e, value) => this.handleOnchange('notification_email', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <BasicTextField
                                            label="Phone"
                                            placeholder="(xxx)xxx-xxxx"
                                            value={this.state.userDetails.phone?formatPhoneNumbers(this.state.userDetails.phone):"" || ""}
                                            fieldValid={this.state.userDetails.phone ? !validateAllPhone(this.state.userDetails.phone) : false}
                                            inValidInput={messages.invalidInput}
                                            // limit={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS) : null}
                                            // limitWarning={this.state.phone ? (validatePlusCharacter((this.state.phone.trim()).charAt(0)) ? messages.phoneLimitIntl : messages.phoneLimitUs) : null}
                                            onChange={(e, value) => this.handleOnchange('phone',formatPhoneNumbers(e.target.value))}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}></MDBCol>
                            <MDBCol md={2} className="save-btn">
                                <BasicButton
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                    // disabled={!this.canSubmit(this.state.userDetails)}
                                    onClick={() => this.handleSubmit()}
                                />
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={2} className="cancel-btn">
                                <BasicButton
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.onClose} />
                            </MDBCol>
                        </MDBRow>
                    </>
                }

            </MDBContainer>
        )
    }
}
export default withUserContext(NewUser)