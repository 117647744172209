import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import { AddCircle } from '@material-ui/icons';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import * as Constants from "../../../../constants/constants";
import CasterService from "../../../../services/service";
import { downloadUrl } from '../../../Common/Helper';
import './EditRider.scss';
import RichTextEditorWithLineHeight from '../../SharedComponents/RichTextEditor/RichTextEditorWithLineHeight';
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';

let exhibitA_Index= null, custom_Index= null
class EditRider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memo_options: [],
            report_options: [],
            custom_rider: false,
            exhibit_a: false, 
            report_type: null,
            rider_text: null,
            memo_type: null,
            downloadingPDF: false,
            pdfFailMssg:null,
            pdfDownloadFailed: false,
            postRiderInfo: false,
            rider_type: this.props?.rider_type,
            selectedTemplate: null,
            rider_info: null,
            fetchingRiderText: false,
            initialRiderPostConfig : {
                "performer_deal_contract_rider_id": null,
                "rider_type": this.props?.rider_type,
                "rider_text": null,
                "is_checked": this.props?.rider_type === "Exhibit A"? 1 : 0
            },
            riderText:null,
        }
    }

    componentDidMount = () => {
        this.getSearch("PERFORMER_DEAL_PERKS")
        this.getSearch("RIDER_TEMPLATES")
        if (this.props?.rider_info?.length === 0) {
            this.setState({ rider_info: this.state?.initialRiderPostConfig })
        }else{
            this.setState({
                rider_info : JSON.parse(JSON.stringify(this.props?.rider_info[0])), riderText:this.props?.rider_info[0]?.rider_text,
            })
        }
        
    }

    getSearch = (object_type) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${object_type}&performer_deal_id=${this.props?.performer_deal_id}&performer_deal_contract_id=${this.props?.performer_deal_contract_id}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    if(object_type === "PERFORMER_DEAL_PERKS") {
                        let formattedList = response.data?.map(item => ({ label: item?.memo_type + (item?.contract_date ?  ' (' + item?.contract_date + ')' : ""), value: item?.memo_type + (item?.contract_date ?  '( ' + item?.contract_date + ' )' : ""), memo_text: item?.memo_text, contract_date: item?.contract_date }))
                        this.setState({ memo_options: formattedList })
                    } else if (object_type === "RIDER_TEMPLATES") {
                        let formattedList = response.data?.map(item => ({ report_id: item?.report_id, report_name: item?.report_name, rider_type: item?.rider_type, et: item?.et, mt_et: item?.mt_et, pa: item?.pa }))
                        this.setState({ report_options: formattedList },()=>{
                            if ( this.props?.rider_type === "Exhibit A") {
                                if (this.props?.rider_info?.length === 0) {
                                    let selectedTemplate = this.state.report_options?.find(item => item?.report_name === "exhibitA")
                                    this.setState({ report_type: "exhibitA", rider_info: this.state.initialRiderPostConfig, selectedTemplate: selectedTemplate }, () => {
                                        this.getRiderText()
                                    })
                                }
                            }
                        })
                    }
                    
                }
            }, err => {
                console.log("error from Search")
            })
    }

    getRiderText = () => {
        let encryptTedStr = btoa(JSON.stringify({performer_deal_contract_id: this.props?.performer_deal_contract_id, mt_et: this.state.selectedTemplate?.mt_et, et: this.state.selectedTemplate?.et, pa: this.state.selectedTemplate?.pa }));
        let load = {
            payload:"casterFeatureGenerateReport",
            reportName: this.state.report_type,
            fileName: this.state.report_type,
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            riderFlag: 1,
            rider_type: this.state.rider_type
        };
        this.setState({ fetchingRiderText: true })
        CasterService.getDataWS(load).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    let document_text = response?.body
                    let rider_info = JSON.parse(JSON.stringify(this.state.rider_info));
                    rider_info.rider_text = document_text
                    this.setState({ rider_info: rider_info, fetchingRiderText: false })
                }
            })
        );
    }
    
    handleCheck = (e) => {
        let rider_info = JSON.parse(JSON.stringify(this.state.rider_info));
        if(e.target.id === "exhibit_a") {
            rider_info.is_checked = !rider_info.is_checked === true ? 1 : 0
            let selectedTemplate = this.state.report_options?.find(item => item?.report_name === "exhibitA")
            e.target.checked ? null :  rider_info.rider_text = ""
            this.setState({ report_type: "exhibitA", rider_info: rider_info, selectedTemplate: selectedTemplate }, () => {
                e.target.checked ? this.getRiderText() : null
            })
        } else if(e.target.id === "custom_rider") {
            rider_info.is_checked = !rider_info.is_checked === true ? 1 : 0
            if (e.target.checked && this.props?.loanout_present) {
                let rider_type = this.state.report_options?.find(item => item?.report_name === "riderLoanout")?.rider_type
                let selectedTemplate = this.state.report_options?.filter(item => item?.report_name === "riderLoanout")
                this.setState({ report_type: "riderLoanout", selectedTemplate: selectedTemplate }, () => {
                    this.getRiderText()
            })
            } else if (e.target.checked && !this.props?.loanout_present) {
                let rider_type = this.state.report_options?.find(item => item?.report_name === "riderIndividual")?.rider_type
                let selectedTemplate = this.state.report_options?.filter(item => item?.report_name === "riderIndividual")
                this.setState({ report_type: "riderIndividual", selectedTemplate: selectedTemplate }, () => {
                    this.getRiderText()
            })
            } else if(!e.target.checked) {
                rider_info.rider_text = ""
            }
            this.setState({ rider_info: rider_info }, () => {
                this.props.fieldChanged(true, "change");
            })
        }
    }

    onMemoChange = (value) => {
        let memo_text = this.state.memo_options?.find(item => item.value === value)?.memo_text
        let memo_type = this.state.memo_options?.find(item => item?.value === value)?.label
        let rider_info = JSON.parse(JSON.stringify(this.state.rider_info));
        rider_info.rider_text = rider_info.rider_text + '<br>' + '<u>' + memo_type + '</u>' + ': ' + memo_text
        this.setState({ memo_type: value, rider_info: rider_info }, () => {
            this.props.fieldChanged(true, "change");
        })
    }

    pdfDownload = (contract,rideinfo) => {
        let rider_info = JSON.parse(JSON.stringify(this.state.rider_info));
        this.setState({ downloadingPDF: true })
        // console.log("rideinfo",rideinfo);
        // console.log("contract",contract);
        
        
        // let riderTextFromMemo=contract?.riders?.[0]?.rider_text;
        // let riderExhibitA=contract?.riders?.[1]?.rider_text;
        
        let payload = {
            performer_deal_contract_id: contract.performer_deal_contract_id,
            template_name: this.props?.loanout_present ? contract.loanout_contract_template_name : contract.contract_template_name,
            filename: contract.rider_file_name + '.pdf',
            project_id: this.props?.project_id,
            preview: 1,
            contract_status: contract?.contract_status,
            contractType: "Rider",
            rider_text:"",
            ridertext_flag:0,
          };
        //   console.log(this.props?.rider_type)
          if (this.props?.rider_type === "From Memos") {
            payload.rider_text = rider_info?.rider_text;
            payload.ridertext_flag=0;
          }
          if ( this.props?.rider_type === "Exhibit A") {
            payload.rider_text = rider_info?.rider_text;
            payload.ridertext_flag=1;
          }
        
        let payloadString = JSON.stringify(payload);
        let payloadEncoded = btoa(unescape(encodeURIComponent(payloadString)));
        
        let postJson = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: payloadEncoded,
            encryptionType: 1
        };
        // console.log("post", postJson);
        CasterService.getDataWS(postJson).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200 && response?.body != "Uploaded file not found." && response?.body != "Upload folder not found." && response?.body != "Document path not found.") {
                        const filename = contract.rider_file_name + '.pdf'
                        let decodedFile = decodeURIComponent(filename)
                        // console.log(response?.body, decodedFile);
                        downloadUrl(response?.body, decodedFile);
                        // console.log(response?.body, decodedFile);
                        this.setState({ downloadingPDF: false })
                    } else if (response?.body === "Uploaded file not found." || response?.body === "Upload folder not found." || response?.body === "Document path not found.") {
                        this.setState({ pdfDownloadFailed: true, pdfFailMssg: response?.body, downloadingPDF: false })
                    } else {
                        this.setState({ downloadingPDF: false })
                    }
                })
        );
    }

    onSave = () => {
        let postJson = JSON.parse(JSON.stringify(this.state.rider_info));
        postJson.performer_deal_id = this.props?.performer_deal_id
        postJson.performer_deal_contract_id = this.props?.performer_deal_contract_id
        // console.log("post", postJson);
        this.setState({ postRiderInfo: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureContractRider`, postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (!response || response?.data?.error) {
                    this.setState({ postRiderInfo: false })
                    this.props?.notificationComponent(true, 'fail')
                    console.log("error", response?.data?.error);
                }
                else {
                    this.setState({ postRiderInfo: false })
                    this.props?.notificationComponent(true, 'success')
                    this.props?.handleClose()
                    this.props?.getCompensationTabData()
                    console.log("Posted successfully");                    
                }
            }, err => {
                this.setState({ postRiderInfo: false })
                this.props?.notificationComponent(true, 'fail')
                console.log("error from Search");
            })
    }

    onRiderTextChange = (value) => {
        let rider_info = JSON.parse(JSON.stringify(this.state.rider_info));
        rider_info.rider_text = value
        this.setState({ rider_info: rider_info })
    }

    render() {
        return (
            <div className="EditRiderModalContainer">
                <div>For {this.props?.contract_info?.performer_type} - {this.props?.contract_info?.fee_type}</div>
                <MDBRow>
                    {this.state.rider_type !== "From Memos" ? 
                    <MDBCol md={3}>
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                        <BasicCheckbox 
                            id={"exhibit_a"}
                            label={"Attach Exhibit A Rider"}
                            checked={this.state.rider_info?.is_checked} 
                            onChange={(e) => this.handleCheck(e)} 
                        />}
                    </MDBCol> : null}
                    {this.state.rider_type === "From Memos" ? 
                    <MDBCol md={3}>
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                        <BasicCheckbox 
                            id={"custom_rider"}
                            label={"Attach Rider to Contract"}
                            checked={this.state.rider_info?.is_checked}
                            onChange={(e) => this.handleCheck(e)} />}
                    </MDBCol> : null }
                </MDBRow> 
                {this.state.rider_type === "From Memos" ? 
                <MDBRow>
                    <MDBCol md={4}>
                        <SelectField
                            label="Append Memo Text"
                            placeHolderText="-Select a Memo-"
                            options={this.state.memo_options}
                            disabled={!this.state.rider_info?.is_checked || (this.state.rider_info?.is_checked && this.state.fetchingRiderText)}
                            value={this.state.memo_type}
                            onChange={(e) => this.onMemoChange(e.target.value)}
                        />
                    </MDBCol>
                </MDBRow> : null}
                <MDBRow disabled={!this.state.rider_info?.is_checked}>
                    <MDBCol md={10}>Document Controls</MDBCol>
                    <MDBCol md={2}>Symbols</MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={12} disabled={!this.state.rider_info?.is_checked}>
                        {this.state.fetchingRiderText ? <CircularProgress color="inherit" size={20} /> : 
                        <RichTextEditorWithLineHeight
                            id="rider"
                            value={this.state.rider_info?.is_checked ? this.state.rider_info?.rider_text : " "}
                            onChange={(value) => this.onRiderTextChange(value)}
                        >                            
                        </RichTextEditorWithLineHeight>  }
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol md={10} className="d-flex justify-content-end ">
                        
                            <BasicButton
                            type="inline"
                            variant="contained"
                            text={this.state.downloadingPDF ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"}
                            icon="file-pdf"
                            // onClick={()=>this.props?.deal_contract.map((item,index)=>{ this.pdfDownload(item,this.state?.rider_info)})}
                            onClick={() => {
                                // console.log(this.props?.deal_contract);
                                // console.log(this.state.rider_info);
                                if (this.props?.deal_contract && this.state?.rider_info) {
                                    this.props.deal_contract.map((item, index) => {
                                        this.pdfDownload(item, this.state.rider_info);
                                    });
                                }
                            }}
                            className="generate-pdf-button"
                        />
                        
                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                        <BasicButton 
                            type="inline"
                            variant="contained"
                            text={this.state.postRiderInfo ? <CircularProgress color="inherit" size={20} /> : "Save"}
                            icon="save"
                            onClick={() => this.onSave()}
                        />}
                    </MDBCol>
                    <MDBCol md={2} className="d-flex justify-content-end">
                        <BasicButton 
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            icon="times"
                            onClick={() => {
                                let rider_info= JSON.parse(JSON.stringify(this.state.rider_info));
                                // rider_info.rider_text=""
                                if ( this.props?.rider_type === "Exhibit A") {
                                    rider_info.is_checked=true;
                                    rider_info.rider_text=this.state.riderText
                                }else{
                                    rider_info.is_checked=false
                                }
                                this.setState({ rider_info: rider_info })
                                this.props?.handleClose();
                            }}
                            />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}
export default withUserContext(EditRider);