import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon, MDBCardTitle } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBContainer,
} from "mdbreact";
import BasicLabel from "../../../caster-feature/components/SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import defaultImg from '../../../assets/WBDLogo.png';
import RepresentativeInfo from "./RepresentativeInfo";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import messages from "../../Common/Messages.json";
import IconTextField from '../../components/SharedComponents/IconTextField/IconTextField';
import { validateAlpVarCharNumOnlyWithSpace, validateNumbersOnly,validateNumbersAndCustomLengthOnly, validateEmail, ssnFormat, validateAllPhone, formatPhoneNumbers, validateNumbersAndHyphenOnly,validateRoleNum } from '../../Common/Helper'
import ProfilePictureUpload from "../../Common/DefaultModalLayout/ProfilePicture/ProfilePictureUpload";
import { uploadProfileParams } from '../../Common/DefaultModalLayout/ProfilePicture/Config'
import Popover from '@material-ui/core/Popover'
import moment from 'moment';
import Grid from '@mui/material/Grid'; 

class TalentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      talentDetails: { ...this.props.talentDetails },
      roleTypeOptions: [],
      setRecurringRoleModal: false,
      ssnLimit: 11,
      backupImg: null,
      showSSN: false,
      openPopOver: false,
      anchorEl: null,
      non_sag: 0
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getRoleType("lu_role_type")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.talentDetails !== this.props.talentDetails) {
      this.setState({ talentDetails: this.props?.talentDetails });
    }
  }

 

  handleshowRecurringRoleModal = () => {
    this.setState({ setRecurringRoleModal: true });
  };
  hideRecurringRoleModal = () => {
    this.setState({ setRecurringRoleModal: false });
  };

  getRoleType = (objectType) => {
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpLookupDetails?objectType=${objectType}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let filteredList = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item?.is_active
        }));
        this.setState({
          roleTypeOptions: filteredList,
        });
      })
      .catch((error) => {
        console.log(error, "error in season get method");
      });
  };

  onProfileChangeClick = () => {
    if (!this.props?.isUpload) {
      return;
    }
    this.myRef.current.click();
    // this.setState({ imgMode: 'upload' })
    this.props?.handleImgMode('upload');
  }

  onError = (e) => {
    // this.props?.handleProfileImg(null)       
    e.target.src = defaultImg;
  }

  handleImgMode(mode) {
    this.setState({ imgMode: mode })
  }
  handleProfileImg(file, result) {
    this.setState({ backupImg: file })
    this.setState({ profileImg: file })
  }
  handleProfileChange(val) {
    this.setState({ isProfileChanged: val })
  }
  handleImageType(val) {
    let fileType = val.split("/").pop();
    this.setState({ imageType: fileType })
  }

  uploadProfile = (talentId = this.props.deal_memo_id) => {
    let uploadPayload = this.generateFilePayload(talentId);
    let file = this.state.profileImg;
    console.log(uploadPayload);
    CasterService.postData(Constants.CasterServiceBaseUrl + '/casterTvJwpPreSignedUrlUpload', uploadPayload, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        if (this.state.imgMode === "upload") {
          CasterService.uploadFile(response.data.preSignedURL, file).then(response => {
            console.log("File Upload Successful:" + response.data);
            this.setState({ imgMode: "download", isProfileChanged: false });

          }, (err) => {
            this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
            console.log("File Upload Failed:" + err);
          });
        }
        else {
          CasterService.downloadFile(response.data.preSignedURL).then(response => {
            console.log("File Download Successful:" + response.data);
            this.convertBlobToImg(response.data);

          }, (err) => {
            console.log("File Download Failed:" + err);
            this.setState({ profileImg: null, imgMode: 'download' })
          });
        }

      }, (err) => {
        console.log("Pre Signed Url Failed:" + err);
      });
  }

  convertBlobToImg = (blb, objectType, objectId) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
      const text = e.srcElement.result;
      // if (objectType === "Talent") {
      this.setState({ profileImg: text, imgMode: "download" });
      // }
      // else if (objectType === "Representatives") {
      //     let imgObj = [{
      //         id: objectId,
      //         type: objectType,
      //         img: text
      //     }]
      //     let arr = [...this.state.representativeProfiles, ...imgObj];
      //     this.setState({ representativeProfiles: arr });
      //     //   this.setState((prevState)=>{ representativeProfiles:[...prevState.representativeProfiles,...imgObj  ] })

      // }
    });
    reader.readAsText(blb);
  }

  generateFilePayload = (talentId) => {
    if (talentId === null) {
      return;
    } else {
      let objectType = "Talent"
      let fileName = `${objectType.toLowerCase()}_${talentId}`
      let tenantName = this.props.userContext?.active_tenant?.tenant_name;
      let uploadPayload = {
        ...uploadProfileParams,
        signedURLType: this.state.imgMode,
        fileCategory: "profileimage",
        tenantName: tenantName,
        objectType: objectType,
        objectId: talentId,
        contentType: this.state.imageType,
        fileName,
      };
      return uploadPayload;
    }
  }


  handleShowSSN = () => {
    this.setState(prevState => ({
      showSSN: !prevState.showSSN
    }));
  };

  maskSSN = (ssn) => {
    if (ssn && ssn.length >= 9) {
      const maskedSSN = "xxx-xx-" + ssn.substr(7);
      return maskedSSN;
    }
    return ssn;
  };

  handleToggleSSN = () => {
    this.setState(prevState => ({
      showSSN: !prevState.showSSN,
      ssn: prevState.showSSN ? this.maskSSN(prevState.talentDetails?.ssn) : prevState.talentDetails?.ssn
    }));
  };

  handleClickSSN = () => {
    if (this.state.showSSN) {
      this.handleToggleSSN();
      this.setState({ showSSN: false })
    }
  };

  onProfileChangeClick = (event, node) => {
    if (this.props?.profileImg === null || node === "replace") {
      if (!this.props?.isUpload) {
        return;
      }
      this.myRef.current.click();
      // this.setState({ imgMode: 'upload' })
      this.setState({ openPopOver: false })
      this.props?.handleImgMode('upload');
    }
    else if (node === "remove") {
      this.props?.removeProfileImg();
    }
    else {
      this.setState({ openPopOver: true, anchorEl: event.currentTarget })
    }
  }

  handleCloseModal = () => {
    this.setState({ openPopOver: false, anchorEl: null })
  }

  imageHandler = e => {
    const reader = new FileReader();
    try {
      let file = e.target.files[0];
      if (file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/gif") {
        this.props?.handleProfileImg(null)
        this.props?.handleProfileChange(false);
        this.setState({ invalidImageType: true })

        return;
      }
      reader.onload = () => {

        if (reader.readyState === 2) {
          // this.setState({ profileImg: reader.result, isProfileChanged: true });
          this.props?.handleProfileImg(reader.result)
          this.props?.handleProfileChange(true);
          this.props?.handleImageType(file.type);
          // this.props.onFileUpload(reader.result);

        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      this.props?.handleProfileImg(null)
      this.props?.handleProfileChange(false);
    }

  }

  handleChange=(listname,field,value)=>{
    if(field === "role_type_id"){
      let roleName = this.state.roleTypeOptions.find((item)=>item.value === value)
      this.setState({
        talentDetails: {
          ...this.state.talentDetails,
          ["role_type_name"]: roleName.label,
          ["role_type_id"]: value
        }},()=>{this.props.gettalentDetails(this.state.talentDetails)})
    }else if(field === 'role_id' && Number(value) > 0 ){
    this.setState({
      talentDetails: {
        ...this.state.talentDetails,
        role_id: validateRoleNum(value)
        // (value.toString().indexOf(".") == -1 && value.length > 8)  ? (value.split('.').join("") / 100).toFixed(2):(value.toString().split('.').join("").length > 8 ? (value.split('.').join("") / 100).toFixed(2) : value)

      //   (value.toString().indexOf(".") == -1 && value.length > 8) ? 
      //   (value.split('.').join("") / 100).toFixed(2) :  
      //   ((value.toString().indexOf(".") == -1 && value.length <= 8) ? value : 
      //  value)
      }
    },()=>{this.props.gettalentDetails(this.state.talentDetails)})
  }
    else if (field === 'non_sag') {
      let isChecked = value.target.checked
      if (isChecked && value.target.id === "non_sag") {
        this.setState({
          talentDetails: {
            ...this.state.talentDetails,
            non_sag: 1
          }
        },()=>{this.props.gettalentDetails(this.state.talentDetails)})
      } else {
        this.setState({
          talentDetails: {
            ...this.state.talentDetails,
            non_sag: 0
          }
        },()=>{this.props.gettalentDetails(this.state.talentDetails)})
      }
    }else{
        this.setState({
          talentDetails: {
            ...this.state.talentDetails,
            [field]: value
          }
        },()=>{
        this.props.gettalentDetails(this.state.talentDetails)});
      }
  }

  render() {
    let openEFCPopover = Boolean(this.state.openPopOver);
    const { showSSN } = this.state;
    const federalIdNo = this.state.talentDetails?.federal_id_no;
    const ssn = showSSN ? this.state.talentDetails?.ssn : this.maskSSN(this.state.talentDetails?.ssn);
    let lastUpdated;
    if (this.state.talentDetails?.updated_at) {
      lastUpdated = "Last revised on " + moment(this.state.talentDetails?.updated_at).format('MM/DD/YYYY') + ((this.state.talentDetails?.updated_by) ? " by " + this.state.talentDetails?.updated_by : "");
    } else if (this.state.talentDetails?.created_at) {
      lastUpdated = "Last revised on " + moment(this.state.talentDetails?.created_at).format('MM/DD/YYYY') + ((this.state.talentDetails?.created_by) ? " by " + this.state.talentDetails?.created_by : "");
    } else {
      lastUpdated = "";
    }
    return (
      <div className="TalentDealMemo" >
        {this.props.mode === "view" ?
          <>
            <MDBRow className="container-mt">
              <MDBCol md={1} className="img" id="img">
                <ProfilePictureUpload
                  id={`talent_${this.props?.deal_memo_id}`}
                  key={`talent_${this.props?.deal_memo_id}`}
                  navObj={{ talent_id: this.props?.deal_memo_id }}
                  mode={this.props.mode}
                  imgMode={this.props.imgMode}
                  isProfileChanged={this.props.isProfileChanged}
                  profileImg={this.props.profileImg}
                  handleImgMode={this.props?.handleImgMode}
                  handleProfileChange={this.props?.handleProfileChange}
                  handleProfileImg={this.props?.handleProfileImg}
                  handleImageType={this.props?.handleImageType}
                  removeProfileImg={this.props?.removeProfileImg}
                  uploadProfile={this.props?.uploadProfile}
                  isUpload={true}
                  isEditing={true}
                />
              </MDBCol>
              <MDBCol md={11} className="basicLabel-color">
                <MDBRow>
                  <div className="display-talent">
                    <span className="color-name talentName">{this.state.talentDetails?.talent_name || ' '}</span>
                    <span className="minor-mt">{this.state.talentDetails?.is_minor ? "Minor" : " "}</span>
                  </div>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={7}>
                    <span className="recurring-dispaly"> <BasicLabel text={this.state.talentDetails?.role_type_name} /> :
                   <span className="sub-ml">{this.state.talentDetails?.role} {(this.state.talentDetails?.role_id) ? "(#" + this.state.talentDetails?.role_id + ")" : " "}</span>
                    </span>
                  </MDBCol>
                  <MDBCol md={5}>
                    <div className="text-end">
                      <BasicLabel type={"font-italic text pe-4"} text={lastUpdated || ''} />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="card-mt card-padding">
                  <MDBCard>
                    <MDBCardBody>
                      <MDBCardText>
                        <MDBRow>
                          <MDBCol md={3} className="header-position">
                            <MDBRow className="sub1-position">
                              <span className="display-labels"> <BasicLabel text={"Pref. Pronouns"} /> :
                                <span className="m-r">{this.state.talentDetails?.preferred_pronouns}</span></span>
                            </MDBRow>
                            <MDBRow className="sub2-position">
                              <span className="display-labels">
                                <BasicLabel text={"Address"} /> :
                                {this.state.talentDetails?.address && (
                                  <>
                                    {this.state.talentDetails.address.split("\n").map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </>
                                )}
                              </span>
                            </MDBRow>

                          </MDBCol>
                          <MDBCol md={3} className="header-position">
                            <MDBRow className="sub1-position">
                              <span className="display-labels"> <BasicLabel text={"Email"} /> :
                                <span className="m-r">{this.state.talentDetails?.talent_email}</span></span>
                            </MDBRow>
                            <MDBRow className="sub2-position">
                              <span className="display-labels"> <BasicLabel text={"Cell Number"} /> :
                                <span className="m-r"> {this.state.talentDetails?.cell_no}</span></span>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol md={3} className="header-position">
                            <MDBRow className="sub1-position">
                              <span className="display-labels">
                                <BasicLabel text={"Federal No:"} />
                                <span className="m-r">{federalIdNo}</span>
                              </span>
                            </MDBRow>
                            <MDBRow className="sub2-position">
                              <span className="display-labels">
                                <BasicLabel text={"Social Security:"} />
                                <span className="m-r" onClick={this.handleClickSSN}>
                                  {ssn}
                                  <MDBIcon
                                    icon={showSSN ? 'eye-slash' : "eye"}
                                    className="ml-2"
                                    onClick={this.handleToggleSSN}
                                  />
                                </span>
                              </span>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol md={3} className="header-position7">

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid xs={12}>
                                <div className="card-list-grid"> 
                                <span className="display-labels">    <BasicLabel text={"Loanout"} /> :
                                <span className="m-r">{this.state.talentDetails?.loanout}</span></span>
                                </div>
                              </Grid>
                              <Grid xs={12}>
                                <div className="card-list-grid"> 
                                <span className="display-labels">      <BasicLabel text={"Minor"} /> :
                                <span className="m-r">{this.state.talentDetails?.is_minor ? "yes" : "no"}</span></span>
                                </div>
                              </Grid>
                              <Grid xs={12}>
                                <div className="card-list-grid"> <span className="display-labels">      <BasicLabel text={"International Union Member(Non-SAG)"} /> :
                                <span className="m-r">{this.state.talentDetails?.non_sag === 1 ? "yes" : "no"}</span></span>
                                </div>
                              </Grid>                           
                            </Grid>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
          :
          <>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <BasicLabel text={"Talent Information"} />
                  <MDBRow>
                    {/* <MDBCol md={1}>
                        <img
                          src={defaultImg}
                          alt="profile image"
                          id="img" className="img"
                        //   onError={this.onError}
                            onClick={() => this.onProfileChangeClick()} 
                        />
                    </MDBCol> */}
                    <MDBCol md={1} className="img" id="img">
                      <ProfilePictureUpload
                        id={`talent_${this.props?.deal_memo_id}`}
                        key={`talent_${this.props?.deal_memo_id}`}
                        navObj={{ talent_id: this.props?.deal_memo_id }}
                        mode={this.props.mode}
                        imgMode={this.props.imgMode}
                        isProfileChanged={this.props.isProfileChanged}
                        profileImg={this.props.profileImg}
                        handleImgMode={this.props?.handleImgMode}
                        handleProfileChange={this.props?.handleProfileChange}
                        handleProfileImg={this.props?.handleProfileImg}
                        handleImageType={this.props?.handleImageType}
                        isUpload={true}
                        isEditing={true}
                        uploadProfile={this.props?.uploadProfile}
                      />
                    </MDBCol>
                    <MDBCol md={11} className="basicLabel-color">
                      <MDBRow>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Talent First Name"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.talentDetails?.first_name || ""}
                            onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('talentDetails', 'first_name', e.target.value.length > 0 ? e.target.value : null) : null}
                            // onChange={(e) => this.handleChange('talentDetails', 'first_name', e.target.value )}

                          />
                        </MDBCol>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Talent Last Name"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.talentDetails?.last_name || ""}
                            onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('talentDetails', 'last_name', e.target.value.length > 0 ? e.target.value : null) : null}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicTextField
                            label={"Role"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.talentDetails?.role || ""}
                            onChange={(e) => this.handleChange('talentDetails', 'role', e.target.value)}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicTextField
                            label={"Role#"}
                            isMandatory={false}
                            showMandatory={false}
                            value={this.state.talentDetails?.role_id || ""}
                            onChange={(e) => validateNumbersAndCustomLengthOnly(e.target.value,11) ? this.handleChange('talentDetails', 'role_id', e.target.value.length > 0 ? e.target.value:null) : null}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                          <SelectField
                            id={"episode-type"}
                            size="small"
                            label={"Role Type"}
                            isMandatory={true}
                            showMandatory={this.props?.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.talentDetails?.role_type_id}
                            options={this.state.roleTypeOptions}
                            onChange={(e) => this.handleChange('talentDetails', 'role_type_id', e.target.value)
                          }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={3} className="recurring-btn">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            text="Recurring Role"
                            onClick={this.handleshowRecurringRoleModal}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Preferred Pronouns"}
                            value={this.state.talentDetails?.preferred_pronouns || ""}
                            onChange={(e) => this.handleChange('talentDetails', 'preferred_pronouns', e.target.value)}
                          />
                        </MDBCol>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Email"}
                            value={this.state.talentDetails?.talent_email || ""}
                            fieldValid={!validateEmail(this.state.talentDetails?.talent_email) && this.state.talentDetails?.talent_email}
                            inValidInput={messages.invalidInput}
                            onChange={(e) => this.handleChange('talentDetails', 'talent_email', e.target.value)}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicTextField
                            label={"Social Security"}
                            placeholder="xxx-xx-xxxx"
                            limit={this.state.talentDetails?.ssn === ssnFormat(this.state.talentDetails?.ssn) ? true : false}
                            limitWarning={(this.state.talentDetails?.ssn === ssnFormat(this.state.talentDetails?.ssn) && this.state.talentDetails?.ssn.length > 9) ? "" : "(ex:123-12-1234)"}
                            value={this.state.talentDetails?.ssn || ""}
                            onChange={(e) => this.handleChange('talentDetails', 'ssn', ssnFormat(e.target.value))}
                          />
                          {/* <span className="error-text">{this.props?.ssnErrorCheckFlag ? "(ex:123-12-1234)" : " "}</span> */}
                        </MDBCol>
                        <MDBCol md={3}>
                          <BasicTextField
                            label={"Loan Out"}
                            value={this.state.talentDetails?.loanout || ""}
                            onChange={(e) => this.handleChange('talentDetails', 'loanout', e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={3}>
                          <BasicTextArea
                            label={"Address"}
                            value={this.state.talentDetails?.address || ""}
                            onChange={(e) => this.handleChange('talentDetails', 'address', e.target.value)}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                          <BasicTextField
                            label={"Cell Number"}
                            placeholder="(xxx)xxx-xxxx"
                            fieldValid={this.state.talentDetails?.cell_no ? !validateAllPhone(this.state.talentDetails?.cell_no) : false}
                            inValidInput={"Please enter valid phone"}
                            value={this.state.talentDetails?.cell_no || ""}
                            // onChange={(e) => this.props?.handleChange('talentDetails','cell_no', formatPhoneNumbers(e.target.value)}
                            onChange={(e) => {
                              let formattedValue = formatPhoneNumbers(e.target.value);
                              if (formattedValue.length >  18) {
                                formattedValue = formattedValue.slice(0,18);
                              }
                              this.handleChange('talentDetails', 'cell_no', formattedValue);
                            }}
                          />
                        </MDBCol>
                        <MDBCol md={1}></MDBCol>
                        <MDBCol md={2}>
                          <BasicTextField
                            label={"Federal ID No"}
                            // isMandatory={!this.state.talentDetails?.loanout ? true : false}
                            // showMandatory={!this.state.talentDetails?.loanout && (this.props?.postInitiated || false)}
                            // mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.talentDetails?.federal_id_no || ""}
                            onChange={(e) => validateNumbersAndHyphenOnly(e.target.value) ? this.handleChange('talentDetails', 'federal_id_no', e.target.value) : null}
                          />
                        </MDBCol>
                        <MDBCol md={2} className="radio-font">
                          <BasicLabel text={"Minor"} />
                          <FormControl >
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              className="radio-font"
                              defaultValue={this.state.talentDetails?.is_minor === 1 ? "yes" : "no"}
                              onChange={(e) => this.handleChange('talentDetails', "is_minor", e.target.value)}
                            >
                              <FormControlLabel
                                className="LabelSize"
                                value="yes"
                                control={<Radio />}
                                label="YES"
                              />
                              <FormControlLabel
                                className="LabelSize"
                                value="no"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </FormControl>
                        </MDBCol>
                        <MDBCol md={2} className="radio-font">
                          <BasicLabel text={"International Union Member(Non-SAG)"} /> {console.log(this.state.talentDetails)}
                          <BasicCheckbox
                            id="non_sag"
                            label=""
                            checked={this.state.talentDetails?.non_sag === 1}
                            align="start"
                            onChange={e => this.handleChange('talentDetails', 'non_sag', e)}
                          />
                          </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </>
        }
        {this.state.setRecurringRoleModal && (
          <MainViewModalComponent
            open={this.state.setRecurringRoleModal}
            handleClose={this.hideRecurringRoleModal}
            headerText={"Set Recurring Role"}
            mode={" "}
            modalName={"Set Recurring Role"}
            project_id={this.props?.project_id}
            confirmationFun={this.props?.confirmationFun}
          />
        )}
      </div>
    );
  }
}
export default withUserContext(TalentInfo);