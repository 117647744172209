export const initGuardianDetails =  {
    "talent_guardians_id": null,
    "relation": null,
    "guardian_name": null,
    "guardian_first_name": null,
    "guardian_middle_name": null,
    "guardian_last_name": null,
    "guardian_suffix": null,
    "guardian_address_type": null,
    "guardian_address": null,
    "guardian_city": null,
    "guardian_zip":null,
    "guardian_country": null,
    "guardian_email_list": [],
    "guardian_phone_list": [],
    "is_primary":0,
    "is_delete":0
}