export const lookupType = [
    { value: "lu_contract_type", label: "Contract Type" },
    { value: "lu_intended_market", label: "Intended Market" },
    { value: "lu_outlet", label: "Outlet" },
    { value: "lu_production_entity", label: "Production Entity" },
    { value: "lu_project_type", label: "Project Type" },
    { value: "lu_role_status", label: "Role Status" },
    { value: "lu_role_type", label: "Role Type" },
    { value: "lu_union", label: "Union" },
]
export const lookupConfig = {
    headings: [
        {
            headingLabel: "Value",
            icon: "sort-alpha-up",
            width: '85%'
        },
        {
            headingLabel: "Active",
            icon: "",
            width: '15%'
        }
    ],
    dataNodes: ["label", "check"],
    primaryKey: "value"
}