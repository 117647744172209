import React, { Component } from 'react';
import InterviewList from './InterviewList';
import CastList from './CastList';
import Worksheet from './Worksheet';
import SagTimeReport from './SagTimeReport';
import Station12 from "./Station12";
import Residuals from './Residuals';
import TalentVoucher from './TalentVoucher';
import EPTimesheet from './EPTimesheet';
import EPCoversheet from './EPCoversheet';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from "../../../../contexts/UserContext";
import { withNotificationContext } from "../../../../contexts/NotificationContext";
import WorkingHistory from './WorkingHistory';
import CastWrap from './CastWrap';
import DealMemo from './DealMemo';
import PostSyncTimeLog from './PostSyncTimeLogFc';
import Station12FC from './Station12Fc';
import BillingList from './BillingListFc';
import CastListFc from './CastListFc';
import CastingDataFc from './CastingDataFc';
import ContractStatusFc from './ContractStatusFc';
import FCReport from './FCReport';
import VoiceOverPayMemoFc from './VoiceOverPayMemoFc';


class ReportsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue
            }
        }
        return null
    }
    generateReport = (reportName, searchPayload, reportNameFinal,docFlag = 0, excelFlag = 0, reportRoute='getReports') => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
            "payload": excelFlag === 1 ? "generateExcel" : reportRoute, 
            "reportName": `${reportName}`,
            "searchJson": `${encryptTedStr}`, 
            "encryptionType": "1",
            "tenantId": `${this.props?.userContext?.active_tenant?.tenant_id}`
        } 
        if(excelFlag === 0 && reportRoute !== 'getResidualPackage') {
            load.docFlag = docFlag.toString()
        } 
        this.props.notificationContext.updateDownLoadCount(this.props.notificationContext.downloadCount + 1)
        console.log('showtheloadhere',load)
        CasterService.getDataWS(load)
        .then(webSocketConn => {
            this.props.notificationContext?.notificationBar(true, "Download request submitted, please continue with your work", 'success')
            this.props.notificationContext?.webSocketReportDownload(webSocketConn,reportNameFinal)
        })
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 'acworksheet': return <Worksheet key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcWorksheetReport"
                reportDownloadName="Worksheet"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'acstation12': return <Station12 key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcStation12"
                reportDownloadName="Station12"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'acsagtime': return <SagTimeReport key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcSagTime"
                reportDownloadName="SagTime"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            // case 4: return <SagTimeReport key={"AC" + this.state.tabValue}
            //     generateReport={this.generateReport}
            //     reportName="rptAcPostSyncTimeLog"
            //     reportDownloadName="PostSyncTimeLog"
            //     fieldChanged={this.props.fieldChanged}
            //     handleClose={this.props.handleClose}
            //     downloadUrl={this.downloadUrl}
            // />
            case 'accastingdata': return <Worksheet key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcCastingData"
                reportDownloadName="CastingData"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'acprintshow': return <SagTimeReport key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcPrintShowPackage"
                reportDownloadName="PrintShowPackage"
                //reportsToDownload="rptAcStation12,rptAcSagTime,rptAcPostSyncTimeLog"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'actalentvoucher': return <TalentVoucher key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcTalentVoucher"
                reportDownloadName="TalentVoucher"
                fieldChanged={this.props.fieldChanged}
                withContract={false}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'aceptimesheet': return <EPTimesheet key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptEPTimesheet"
                reportDownloadName="EPTimesheet"
                fieldChanged={this.props.fieldChanged}
                withContract={false}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />

            case 'acepcoversheet': return <EPCoversheet key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptEPCoversheet"
                reportDownloadName="EPCoversheet"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            


            case 'accontracttalentvoucher': return <TalentVoucher key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcTalentVoucherContract"
                reportsToDownload="TalentVoucherContract"
                // reportsToDownloadLoanout="rptAcTalentVoucher,rptACSagDailyLoanout"
                // reportDownloadName="TalentVoucher,SagDailyContract"
                fieldChanged={this.props.fieldChanged}
                withContract={true}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'acresidualpackage': return <Residuals key={"AC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptAcResidualsPackage"
                reportDownloadName="ResidualPackage"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />
            case 'tvinterviewlist': return <InterviewList key={"TC" + this.state.tabValue}
                generateReport={this.generateReport}
                // reportName="rptCastlist"
                reportDownloadName="InterviewList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'tvcastlist': return <CastList key={"TC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptCastlist"
                reportDownloadName="CastList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'tvdealmemo': return <DealMemo key={"TC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptDealMemo"
                reportDownloadName="DealMemo"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'tvworkinghistory': return <WorkingHistory key={"TC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptWorkingHistory"
                reportDownloadName="WorkingHistory"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'tvseasoncastwrap': return <CastWrap key={"TC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptSeasonCastWrap"
                reportDownloadName="CastWrap"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcbillinglist': return <BillingList key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcBillingList"
                reportDownloadName="BillingList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fccastlist': return <CastListFc key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcCastList"
                reportDownloadName="CastList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fccastingdata': return <CastingDataFc key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcCastingData"
                reportDownloadName="CastingData"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fccontractstatus': return <ContractStatusFc key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcContractStatus"
                reportDownloadName="ContractStatus"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
                showDealDate={true}
            />;
            case 'fccrewlist': return <FCReport key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcCrewListWorksheet"
                reportDownloadName="CrewListWorksheet"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcdealworksheet': return <ContractStatusFc key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcDealWorksheet"
                reportDownloadName="DealWorksheet"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            // case 'fcdealworksheetschedulef': return <ContractStatusFc key={"FC" + this.state.tabValue}
            //     generateReport={this.generateReport}
            //     reportName="rptFcDealWorksheetScheduleF"
            //     reportDownloadName="DealWorksheetScheduleF"
            //     fieldChanged={this.props.fieldChanged}
            //     handleClose={this.props.handleClose}
            //     downloadUrl={this.downloadUrl}
            // />;
            case 'fci9status': return <FCReport key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcI9Status"
                reportDownloadName="I9Status"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcmissingbirthdate': return <FCReport key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptfcMissingBirthDate"
                reportDownloadName="MissingBirthDate"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcpostsynctimelog': return <PostSyncTimeLog key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcPostSyncTimeLog"
                reportDownloadName="PostSyncTimeLog"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcstation12': return <Station12FC key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcStation12"
                reportDownloadName="Station12"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'fcvoiceoverpaymemo': return <VoiceOverPayMemoFc key={"FC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptFcVoiceOverPayMemo"
                reportDownloadName="VoiceOverPayMemo"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'jwpinterviewlist': return <InterviewList key={"JC" + this.state.tabValue}
                generateReport={this.generateReport}
                // reportName="rptCastlist"
                reportDownloadName="InterviewList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'jwpcastlist': return <CastList key={"JC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptJcCastlist"
                reportDownloadName="CastList"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'jwpdealmemo': return <DealMemo key={"JC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptJcDealMemo"
                reportDownloadName="DealMemo"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'jwpworkinghistory': return <WorkingHistory key={"JC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptJcWorkingHistory"
                reportDownloadName="WorkingHistory"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            case 'jwpseasoncastwrap': return <CastWrap key={"JC" + this.state.tabValue}
                generateReport={this.generateReport}
                reportName="rptJcSeasonCastWrap"
                reportDownloadName="CastWrap"
                fieldChanged={this.props.fieldChanged}
                handleClose={this.props.handleClose}
                downloadUrl={this.downloadUrl}
            />;
            default:
                <p>No Report to show</p>
                break;
        }
    }

    render() {
        return (
            <div className="allcasterReportContainer">
                {this.getTabComponent()}
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(ReportsContainer));