export const initialAliasConfig = {
    "representative_aliases_id": null,
    "aka": null,
    "aka_first_name": null,
    "aka_last_name": null,
    "aka_middle_name": null,
    "aka_suffix": null,
    "is_primary": 0,
    "is_delete": 0
}

export const representativeFCNavList = [
    {
        label: "Personal",
        index: 0
    },
    {
        label: "Talent",
        index: 1
    },
]

export const agencyNavList = [
    {
        label: "Details",
        index: 0
    },
    {
        label: "Representatives",
        index: 1
    },
    {
        label: "Talent",
        index: 2
    }
]

export const initialObjectConfig={    
    object_id: null,
    object_type: null,
    value: null,
    is_primary: 0,
    is_delete:0,
    ext:null   
}

export const phoneObjData={
    "phone_id": null,
    "phone_type": null,
    "phone": null,
    "ext": null,
    "is_primary": 0,
    "is_delete": 0,
    "index": 1
}

export const phoneConfig = {
    "phoneTypeId": "phone_type_id",
    "phoneType": "phone_type",
    "phone": "phone",
    "isPrimary": "is_primary",
    "isInternational": "is_international",
    "isDelete": "is_delete",
    "ext":"ext"
}

export const initialRepresentativeEmail = {
    "email_id": null,
    "email_type_id": null,
    "email_type": null,
    "email": null,
    "is_primary": 0,
    "index": 1
}

export const initialRepresentativeAddress = {
    "representative_address_id": null,
    "address_type": null,
    "address":null,
    "city": null,
    "state": null,
    "country": null,
    "zip": null,
    "is_primary": 0,
    "is_international": 0,
}

export const initialRepresentativePhone = {
    "phone_id": null,
    "phone_type": null,
    "phone": null,
    "ext": null,
    "is_primary": 0,
    "is_delete": 0
}


export const  initialAddressList =  [
    {
        "representative_address_id": 1,
        "address_type": "Home",
        "address": "Line 1",
        "city": "Dallas",
        "state": "Texas",
        "zip": "787945",
        "country": "USA",
        "is_primary": 1
    },
    {
        "representative_address_id": 2,
        "address_type": "Office",
        "address": "Line1, Line 2",
        "city": "Houston",
        "state": "Texas",
        "zip": "44554",
        "country": "USA",
        "is_primary": 0
    }
]

export const representativePersonalLimit = {
    "firstName": 50,
    "lastName": 50,
    "mi": 50,
    "prefix": 10,
    "suffix": 10,
    "aka": 50,
    "email": 50,
    "performerNotes": 100,
}

export const emailConfig = {
    "emailTypeId": "email_id",
    "emailType": "email_type",
    "email": "email",
    "isPrimary": "is_primary",
    "isDelete":"is_delete"
}

export const repTalentConfig = {
    headings: [
        {
            headingLabel: "Covered Talent",
            border: true,
            width: "45%"
        }
    ],
    dataNodes: ["talent_name"],
    actions: ["minus-circle"],
    hyperlinks: ["talent_name"]
}

export const talentFilterConfig = {
    "covered_talent": null,
}