import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateField from '../../../components/SharedComponents/DateField/DateField';
import TimePicker from 'rc-time-picker';
import moment from "moment";
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../../Common/Helper';
import { withUserContext } from '../../../../contexts/UserContext';

class InterviewList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            selectedValue: "landscape",
            dateValue: "",
            timeValue: "",
            postInitiated: false,
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated : false
        });
    }

    handleTime = (time) => {
        return moment(time).format("HH:mm:ss");
    }

    handleSubmitInterviewList = () => {
        this.setState({ postInitiated: true });
        let { selectSeason, selectEpisode, selectProduction, dateValue: date, timeValue } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        let time = timeValue;
        if (time) {
            time = this.handleTime(time)
        }

        if (!production_id || !ps_id || !date) {
            return;
        }
        let reportName;
        if (this.state.selectedValue === "portrait") {
            reportName = (this.props.userContext?.active_tenant?.tenant_id === 1) ? "rptInterviewListP" : "rptJcInterviewListP";
        } else {
            reportName = (this.props.userContext?.active_tenant?.tenant_id === 1) ? "rptInterviewList" : "rptJcInterviewList";
        }
        let payload = {
            production_id,
            ps_id,
            episode_id,
            date,
            time
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(reportName, payload, reportNameFinal)
        this.setState({postInitiated: false})
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer InterviewList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <ProductionSeasonEpisode
                        productionReq={true}
                        seasonReq={true}
                        episodeReq={false}
                        postInitiated={this.state.postInitiated}
                        onChangeCallBack={this.onChangeCallBack} />
                    <MDBRow className="ReportMode">
                        <MDBCol>
                            <FormControlLabel control={<Radio checked={this.state.selectedValue === 'landscape'}
                                onChange={e => this.onChangeCallBack('selectedValue', e.target.value)}
                                value="landscape"
                                name="radio-buttons" />}
                                label="Landscape" />
                        </MDBCol>
                        <MDBCol>
                            <FormControlLabel control={<Radio checked={this.state.selectedValue === 'portrait'}
                                onChange={e => this.onChangeCallBack('selectedValue', e.target.value)}
                                value="portrait"
                                name="radio-buttons" />}
                                label="Portrait" />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className='pt7'>
                            <DateField
                                id="Date"
                                label={"Date"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.dateValue || ""}
                                onChange={(e) => this.onChangeCallBack('dateValue', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                        <MDBCol className='pt5'>
                            <BasicLabel text={"Time Duration"} />
                            <TimePicker
                                className="timePickerValue"
                                value={this.state.timeValue || ""}
                                defaultValue={this.state.timeValue || ""}
                                placeholder="hh/mm/ss"
                                onChange={(e) => this.onChangeCallBack('timeValue', e)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.handleSubmitInterviewList}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(InterviewList);