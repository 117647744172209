import React, { Component } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { InitialCardData } from "./LandingPageConfig";
import { CircularProgress } from "@material-ui/core";
import LandingPageCardDetails from "./LandingPageCardDetails";
import "./LandingPage.scss";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import { withUserContext } from "../../../contexts/UserContext";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';


class LandingPageCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: InitialCardData,
      renderList: [],
      isLoading: false,     
      lastAccessedProjectId:"",
      lastAccessedProjectName:""
    }
  }
  componentDidMount = () => {
    this.getLandingPageTableData();
  };

  componentWillUnmount = () => {
    this.getLandingPageTableData();
  };


  getLandingPageTableData = () => {
    const myObject = {
      order_by_clause: "project_name",
      order_by: "asc",
      search_text: "",
    };
    this.setState({ isLoading: true })
    const base64String = btoa(JSON.stringify(myObject));
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterTvJwpDashboardLandingDetails?input=${base64String}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      true
    )
      .then((res) => {
        const tableDataWithFavorite = res.data.records.map(row => ({ ...row, is_favorite: false }))
        this.setState({
          lastAccessedProjectId: res.data.last_accessed_project_id,
          lastAccessedProjectName: res.data.last_accessed_project_name,
          renderList: tableDataWithFavorite,
          isLoading: false
        }
        );
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        console.log(error, "error in Landingpagetable get method");
      });

  };
  render() {
    const url = `/caster-tv-jwp/project-view/prid/${this.state.lastAccessedProjectId}`
    return (
      <>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div className="LandingContainer">
            <MDBRow>
              <MDBCol xs={6} md={6} sm={4}>
                <span className="landingPageUserName">
                  Welcome,{`${this.props?.userContext?.user_profile?.name ? (' ' + this.props?.userContext?.user_profile?.name) : ""}`}</span>
              </MDBCol>
              <MDBCol xs={6} md={6} sm={4}>
                <span className="floatRight">
                  <span className="firstLabel">Most Recently Viewed</span>
                  <br />
                  {this.state.lastAccessedProjectName !== null ?
                    <span className="floatRight">
                      <Link to={url}>{`${this.state.lastAccessedProjectName}`}</Link>
                    </span>
                    : ''
                  }
                </span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {this.state.renderList.map((item, index) => (
                <MDBCol xs={12} sm={12} md={4} lg={3} xl={3} key={`card_${index}`} className="padding-card">
                  <LandingPageCardDetails obj={item} />
                </MDBCol>
              ))}
            </MDBRow>
          </div>
        )
        }
      </>
    );
  }
}
export default withRouter(withUserContext(LandingPageCard));