import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import { AddCircle } from '@material-ui/icons';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import * as Constants from "../../../../constants/constants";
import CasterService from "../../../../services/service";
import './CompensationTab.scss';
import RichTextEditor from '../../SharedComponents/RichTextEditor/RichTextEditor';
import { CircularProgress } from "@material-ui/core";

let exhibitA_Index = null, custom_Index = null
export default class EditInfoAndText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoTextValue: this.props?.infoTextValue,
            dealContracts: this.props?.dealContracts
        }
    }


    // onSave = (updateddealcontract) => {
    //     console.log("onSave", updateddealcontract);
    //     this.props?.handleOnsaveRTE(this.state.dealContracts);
    // }
    componentWillUnmount = () => {
        this.props?.handleOnsaveRTE(this.state.dealContracts, this.props?.identifier);
    }

    handleOnChange = (value) => {
        let dealContracts = this.state.dealContracts;
        console.log("value in handleOnChange", value);
        dealContracts[this.props?.globalIndex][this.props?.tooltipBtnPressed] = value;
        if (document.getElementById("CompensationInfoTextquill").textContent.length > 0 || this.state?.infoTextValue.length > 0) {
            this.setState({ dealContracts, infoTextValue: value });
        } else {
            dealContracts[this.props?.globalIndex][this.props?.tooltipBtnPressed] = null;
            this.setState({ dealContracts, infoTextValue: value });
        }
    }

    render() {
        // console.log("props in edit info", this.props);
        // document?.querySelector('#backdropModal')?.querySelector("#backdropModal > div")?.addEventListener('click', function (e) {
        //     // Check if the modal is clicked, not an element inside the modal:
        //     console.log('e.currentTarget', e.currentTarget);
        //     console.log('e.target', e.target);
        //     if (e.target === e.currentTarget) {
        //         // (e) => this.props?.handleClose(e, this.state.dealContracts, this.state.dealContracts);
        //         this.onSave(this.state?.dealContracts);
        //     }
        // });
        return (
            <div className="EditRiderModalContainer" >
                <MDBRow>
                    <MDBCol md={12}>
                        <RichTextEditor
                            id={`CompensationInfoText`}
                            value={this.state?.infoTextValue || ""}
                            onChange={(value) => this.handleOnChange(value)}>
                        </RichTextEditor>
                    </MDBCol>
                </MDBRow>
                {/* <MDBRow className="marginTop10">
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2} className="d-flex justify-content-end">
                        <BasicButton
                            type="inline"
                            variant="contained"
                            text={"Save"}
                            icon="save"
                            onClick={() => this.onSave()}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="d-flex justify-content-end">
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Cancel"}
                            icon="times"
                            onClick={this.props?.handleClose}
                        />
                    </MDBCol>
                </MDBRow> */}
            </div>
        )
    }
}