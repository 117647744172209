export const talentInitialDetails = {
  talent_id: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  ssn: null,
  gender: null,
  ethinicity: null,
  dob: null,
  is_deceased: 0,
  dod: null,
  primary_guardian_name: null,
  primary_guardian_email: null,
  primary_guardian_phone: null,
  secondary_guardian_name: null,
  secondary_guardian_email: null,
  secondary_guardian_phone: null,
  loanout_company: null,
  loanout_no: null,
  state_inc: null,
  citizen_of: null,
  as_of: null,
  address_type: null,
  address_1: null,
  address_2: null,
  city: null,
  state: null,
  country: null,
  zip: null,
  email: null,
  phone: null,
  akas: [],
  representatives: [],
};

export const talentPersonalLimit = {
  firstName: 150,
  middleName: 150,
  lastName: 150,
  aka: 200,
  ssn: 9,
  guardian_name: 200,
  loanout_company: 150,
  loanout_no: 10,
  address_1: 200,
  address_2: 200,
  city: 200,
  zip: 10,
};

export const akaConfig = {
  id: "aka_id",
  name: "aka",
};

export const talentAka = {
  aka_id: null,
  aka: null,
  index: 1,
  is_delete: 0
};

export const repConfig = {
  headings: [
    {
      headingLabel: "Show",
      width: "60%",
    },
    {
      headingLabel: "Prim. Representative",
      icon: "",
      width: "30%",
    },
    {
      headingLabel: "Contracts",
      width: "10%",
    },
  ],
  dataNodes: ["show_name", "representative_name"],
  hyperlinks: ["show_name"],
  primaryKey: "show_id",
  actions: ["arrows-alt-v"],
};

export const renderRepList = [
  {
    show: "project",
    representative: "2000-2001",
  },
  {
    show: "project",
    representative: "2000-2001",
  },
  {
    show: "project",
    representative: "2000-2001",
  },
  {
    show: "project",
    representative: "2000-2001",
  },
  {
    show: "project",
    representative: "2000-2001",
  },
  {
    episode_name: "project",
    representative: "2000-2001",
  },
];
