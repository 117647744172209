import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import ReportButton from './ReportButton';
import { genReportName } from "../../../Common/Helper"
import { withUserContext } from '../../../../contexts/UserContext';

class Worksheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            postInitiated: false,
        }
    }
    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true });
        let { selectSeason, selectEpisode, selectProduction } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        if (!production_id || !ps_id || (this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting' ? !episode_id : false)) {
            return;
        }
        let payload = {
            production_id,
            ps_id,
            episode_id
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })
    }
    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={this.props.userContext?.active_tenant?.tenant_name?.toLowerCase() === 'tv animation casting'}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        )
    }
}

export default withUserContext(Worksheet)
