import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../Helper';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import DateField from '../../../components/SharedComponents/DateField/DateField';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from "../../../../contexts/UserContext";

class VoiceOverPayMemoFc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            performerOptions: [],
            dealDateOptions: [],
            pse_performer_id: null,
            pse_performer_label: null,
            contract_start_date: null,
            contract_start_date_id: null,
            disableDeals: true,
        }
    }

    getPerformerOptions = (production_id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + '/productionSeasonEpisodePerformers?productionId=' + production_id, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let filteredList = response.data?.map(item => ({ value: item.pse_performer_id, label: item.performer_role_name }));
                this.setState({
                    performerOptions: filteredList,
                })
            },
                (err) => {
                    console.log("Error in fetching performer details", err)
                })
    }


    loadPerformerDeals = (id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/performerDealContractsAll?psePerformerId=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let filteredList = [];
                response.data?.map(item => {
                    item?.deals.map(item => {
                        filteredList.push({ value: item.pse_performer_deal_id, label: item.deal_date })
                    })
                });
                this.setState({ dealDateOptions: filteredList, disableDeals: false });
            },
                (err) => {
                    console.log("Error in fetching deal details", err)
                })
    }

    handleSubmitVoiceOverPayMemoFc = (docFlag = 0) => {
        this.setState({ 
            postInitiated: true, 
        });
        let { pse_performer_id, contract_start_date, selectProduction, pse_performer_label } = this.state;
        let production_id = selectProduction?.value;

        if (!production_id || !pse_performer_id || !contract_start_date) {
            return;
        }
        let payload = {
            production_id,
            pse_performer_id,
            contract_start_date
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: pse_performer_label,
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal,docFlag)
        this.setState({postInitiated: false})
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated : false
        });
        if (field === 'selectProduction') {
            this.getPerformerOptions(val?.value);
        } else if (field === 'pse_performer_id') {
            this.setState({
                pse_performer_label: val.currentTarget.innerText,
                pse_performer_id: val.target.value
            });
            this.loadPerformerDeals(val.target.value);
        } 
        // else if (field === 'contract_start_date_id') {
        //     this.setState({
        //         contract_start_date: val.currentTarget.innerText,
        //         contract_start_date_id: val.target.value
        //     });
        // }
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow className="searchSelectFc">
                        <SelectField
                            label={"Performer Name"}
                            value={this.state.pse_performer_id}
                            options={this.state.performerOptions || []}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            onChange={(e) => this.onChangeCallBack('pse_performer_id', e)}
                        />
                    </MDBRow>
                    <MDBRow>
                        {/* <SelectField
                            label={"Deal Date"}
                            disabled={this.state.disableDeals}
                            value={this.state.contract_start_date_id}
                            options={this.state.dealDateOptions || []}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            onChange={(e) => this.onChangeCallBack('contract_start_date_id', e)}
                        /> */}
                        <DateField
                                id="selectEndDateFC"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_start_date}
                                label={"Contract Date"}
                                onChange={(e) => this.onChangeCallBack('contract_start_date', e.target.value.length > 0 ? e.target.value:null)}                        />
                    </MDBRow>
                    <ReportButton
                        generateDoc={true}
                        onClickGenratePdf={this.handleSubmitVoiceOverPayMemoFc}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(VoiceOverPayMemoFc);