import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import DateField from "../SharedComponents/DateField/DateField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import { akaConfig, repPersonalLimit, talentAka } from "./config";
import messages from "../../Common/Messages.json";
import {
  validateAlpVarCharNumOnlyWithSpace,formatPhoneNumbers,
  validateEmail,
  ssnFormat,
  validateAllPhone
} from "../../Common/Helper";
import ListField from "../SharedComponents/ListField/ListField";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import "./Representative.scss";

class RepresentativeProfilePersonal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      representativeDetails: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.representativeDetails !== state.representativeDetails) {
      return {
        representativeDetails: props.representativeDetails,
      };
    }
    return null;
  }

  render() {
    return (
     <div className="personalContainer">
         <MDBRow>
          <MDBCol md={4}>
            <BasicTextField
              label="First Name"
              limit={repPersonalLimit.firstName}
              showMandatory={this.props.postInitiated || false}
              limitWarning={messages.exceed150CharacterWarning || ""}
              isMandatory={true}
              value={this.props?.representativeDetails?.first_name || ""}
              onChange={(e) =>
                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                  ? this.props.handleGeneralEdit("first_name", e.target.value)
                  : null
              }
              isAlphabetVar={true}
            />
          </MDBCol>
          <MDBCol md={4}>
            <BasicTextField
              limit={repPersonalLimit.middleName}
              limitWarning={messages.exceed150CharacterWarning || ""}
              maxLength={10}
              label="Middle Name"
              value={this.props?.representativeDetails?.middle_name || ""}
              onChange={(e) =>
                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                  ? this.props?.handleGeneralEdit(
                      "middle_name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  : null
              }
              isAlphabetVar={true}
              handleAlphabetVariantSelect={(value) =>
                this.props?.handleGeneralEdit("middle_name", value)
              }
            />
          </MDBCol>
          <MDBCol md={4}>
            <BasicTextField
              label="Last Name"
              value={this.props?.representativeDetails?.last_name || ""}
              limit={repPersonalLimit.lastName}
              limitWarning={messages.exceed50CharacterWarning || ""}
              onChange={(e) =>
                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                  ? this.props.handleGeneralEdit("last_name", e.target.value)
                  : null
              }
              isAlphabetVar={true}
              handleAlphabetVariantSelect={(value) =>
                this.props?.handleGeneralEdit("last_name", value)
              }
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={3}>
          <SelectField
              id={"RepresentativeType"}
              value={this.props?.representativeDetails?.representative_type || ""}
              showMandatory={this.props.postInitiated || false}
              isMandatory={true}
              label={"Representative Type"}
              defaultMenuText={"-"}
              options={this.props?.representiveOption}
              onChange={(e) =>
                this.props.handleGeneralEdit("representative_type", e.target.value)
              }
            />
          </MDBCol>
          <MDBCol md={4}>
          {/* <SearchSelectField
              id={"Company"}
              value={this.props?.representativeDetails.company}
              showMandatory={this.props.postInitiated || false}
              isMandatory={true}
              label={"Company"}
              defaultMenuText={"-"}
              options={this.props?.companyOptions}
              onChange={(e,value) =>
                this.props.handleGeneralEdit("company", value)
              }
            /> */}
            <SearchSelectField
              id="company"
              label="Company"
              placeholder="- Search -"
              width="100%"
              showMandatory={this.props.postInitiated || false}
              isMandatory={true}
              searchSelect={true}
              multiple={false}
              detail_selected={this.props?.representativeDetails.company || null}
              options={this.props?.companyOptions || []}
              valueSelected={(e, newValue) =>  {
                  this.props.handleGeneralEdit("company", newValue)
              }}
              searchText={(e) => this.props?.getCompanyData('COMPANY', e.target.value)}
          />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={4}>
          <BasicTextField
              label=" Email"
              showMandatory={this.props.postInitiated || false}
              isMandatory={true}
              value={this.props?.representativeDetails?.email || ""}
              fieldValid={!validateEmail(this.props?.representativeDetails?.email) && this.props?.representativeDetails?.email}
              inValidInput={messages.invalidInput}
              onChange={(e) =>this.props.handleGeneralEdit("email",e.target.value)}
            />
          </MDBCol>
          <MDBCol md={3}>
          <BasicTextField
               label="Phone"
               placeholder={"(___) ___-____"}
              value={this.props?.representativeDetails?.phone || ""}
              limit={repPersonalLimit.phone}
              fieldValid={this.props?.representativeDetails?.phone ? !validateAllPhone(this.props?.representativeDetails?.phone) : false}
              inValidInput={"Please enter valid phone"}
              limitWarning={messages.exceed9CharacterWarning || ""}
              onChange={(e) =>this.props.handleGeneralEdit("phone", formatPhoneNumbers(e.target.value))}
            />
          </MDBCol>
        </MDBRow>
     </div>
    );
  }
}
export default withUserContext(RepresentativeProfilePersonal);
