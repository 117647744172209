import React from 'react';
import './AlphabetVarForSearch.scss';
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Popover from '@material-ui/core/Popover'
import BasicButton from '../BasicButton/BasicButton';
import BasicTextField from '../BasicTextField/BasicTextField';

export default class AlphabetVarPopover extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (
            <div className='alphabetVarForSearch'>
                <Popover
                    open={this.props.isOpen}
                    anchorEl={this.props.anchorEl}
                    onClose={this.props.onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >  <div className="autocompleteSearchpopover">
                        <MDBRow >
                            <BasicTextField
                                label="Search String"
                                value={this.props.searchString || ""}
                                onChange={(e) => e.target.value.length > 0 ? this.props.onChangeString(e.target.value) : this.props.onChangeString(null)}
                                isAlphabetVar={true}
                                handleAlphabetVariantSelect={(value) => this.props.onChangeString(value)}
                            />
                        </MDBRow>
                        <MDBRow >
                            <MDBCol md={6}>
                                <BasicButton
                                    variant="contained"
                                    type="inline"
                                    text={"Search"}
                                    onClick={this.props.onClickSearch}
                                    disabled={!this.props.searchString}
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props.onClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                </Popover>
            </div>
        );
    }
}
