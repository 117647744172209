import React from "react";
import { withRouter } from "react-router-dom";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableComponent from "../../components/SharedComponents/Table";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import messages from "../../Common/Messages.json";
import { validateAlpVarCharNumOnlyWithSpace,validateEmail,validateAllPhone,formatPhoneNumbers } from '../../Common/Helper'


class RepresentativeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        representativeDetails:{...this.props.representativeDetails},
        expandedvalue: true
    };
  }

  componentDidMount() {
    if (this.props?.userRef) {
      this.props.userRef.current = {
        getRepresentativeDetails: this.getRepresentativeDetails.bind(this)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.representativeDetails !== this.props.representativeDetails) {
      this.setState({ representativeDetails: this.props?.representativeDetails });
    }
  }

  getRepresentativeDetails=()=>{
    let repData = {...this.state.representativeDetails}
    return repData
  }

  handleChange=(listname,field,value)=>{
    this.setState({
      representativeDetails: {
        ...this.state.representativeDetails,
        [field]: value
      }
    },()=>{ this.props.handleRepresentativeChange(this.state.representativeDetails)});
   
  }


  render() {
    return (
      <div className="RepresentativeInfoContainer">
        <MDBRow>
          <div className="top accordion-display">
            <Accordion expanded={this.props?.mode !== "view" ? this.state.expandedvalue : !this.state.expandedvalue}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={()=>this.setState({expandedvalue: !this.state.expandedvalue})}
              >
                <Typography className="mainlabel-text">Representative Information</Typography>
              </AccordionSummary>
              <AccordionDetails className="borderLabel-color">
               {/* <MDBRow> */}
                 <MDBCol md={6} className="border-right">
                    <MDBRow>
                       <MDBCol md={6}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Agency"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.agency}</span>
                        </div> :
                             <BasicTextField 
                                label={"Agency"}
                                value={this.state.representativeDetails?.agency || ""}
                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('representativeDetails','agency', e.target.value.length > 0 ? e.target.value : null) : null}
                            />
                        }
                       </MDBCol>
                       <MDBCol md={5}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Office Number"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.rep_office_number}</span>
                        </div> :
                             <BasicTextField 
                                label={"Office Number"}
                                placeholder="(xxx)xxx-xxxx"
                                fieldValid={ this.state.representativeDetails?.rep_office_number ? !validateAllPhone(this.state.representativeDetails?.rep_office_number) : false}
                                inValidInput={"Please enter valid phone"}
                                value={this.state.representativeDetails?.rep_office_number || ""}
                                onChange={(e) => {
                                  let formattedValue = formatPhoneNumbers(e.target.value);
                                  if (formattedValue.length >  18) {
                                    formattedValue = formattedValue.slice(0,18);
                                  }
                                  this.handleChange("representativeDetails",'rep_office_number', formattedValue);
                                }}
                            />
                      }
                       </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={6}>
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={"Agent"}/>
                        <span className={"subText-font"}>{this.state.representativeDetails?.agent}</span>
                      </div> :
                             <BasicTextField 
                                label={"Agent"}
                                value={this.state.representativeDetails?.agent || ""}
                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('representativeDetails','agent', e.target.value.length > 0 ? e.target.value : null) : null}
                            />
                    }
                       </MDBCol>
                       <MDBCol md={5}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Email"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.rep_email}</span>
                        </div> :
                             <BasicTextField 
                                label={"Email"}
                                fieldValid={!validateEmail(this.state.representativeDetails?.rep_email) && this.state.representativeDetails?.rep_email}
                                inValidInput={messages.invalidInput}
                                value={this.state.representativeDetails?.rep_email || ""}
                                onChange={(e) => this.handleChange("representativeDetails",'rep_email', e.target.value)}
                            />
                        }
                       </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={5}>
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={"Cell Number"}/>
                        <span className={"subText-font"}>{this.state.representativeDetails?.rep_cell_number}</span>
                      </div> :
                             <BasicTextField 
                                label={"Cell Number"}
                                placeholder="(xxx)xxx-xxxx"
                                fieldValid={ this.state.representativeDetails?.rep_cell_number ? !validateAllPhone(this.state.representativeDetails?.rep_cell_number) : false}
                                inValidInput={"Please enter valid phone"}
                                value={this.state.representativeDetails?.rep_cell_number || ""}
                                onChange={(e) => {
                                  let formattedValue = formatPhoneNumbers(e.target.value);
                                  if (formattedValue.length >  18) {
                                    formattedValue = formattedValue.slice(0,18);
                                  }
                                  this.handleChange("representativeDetails",'rep_cell_number', formattedValue);
                                }}                            
                            />
                      }
                       </MDBCol>
                    </MDBRow>
                 </MDBCol>
                 <MDBCol md={6}>
                    <MDBRow>
                       <MDBCol md={6}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Management Company"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.management_company}</span>
                        </div> :
                             <BasicTextField 
                                label={"Management Company"}
                                value={this.state.representativeDetails?.management_company || ""}
                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('representativeDetails','management_company', e.target.value.length > 0 ? e.target.value : null) : null}                            />
                        }
                       </MDBCol>
                       <MDBCol md={5}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Office Number"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.mc_office_number}</span>
                        </div> :
                             <BasicTextField 
                                label={"Office Number"}
                                placeholder="(xxx)xxx-xxxx"
                                fieldValid={ this.state.representativeDetails?.mc_office_number ? !validateAllPhone(this.state.representativeDetails?.mc_office_number) : false}
                                inValidInput={"Please enter valid phone"}
                                value={this.state.representativeDetails?.mc_office_number || ""}
                                onChange={(e) => {
                                  let formattedValue = formatPhoneNumbers(e.target.value);
                                  if (formattedValue.length >  18) {
                                    formattedValue = formattedValue.slice(0,18);
                                  }
                                  this.handleChange("representativeDetails",'mc_office_number', formattedValue);
                                }}
                            />
                      }
                       </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={6}>
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={"Manager"}/>
                        <span className={"subText-font"}>{this.state.representativeDetails?.manager}</span>
                      </div> :
                             <BasicTextField 
                                label={"Manager"}
                                value={this.state.representativeDetails?.manager || ""}
                                onChange={(e) => validateAlpVarCharNumOnlyWithSpace(e.target.value) ? this.handleChange('representativeDetails','manager', e.target.value.length > 0 ? e.target.value : null) : null}                            
                            />
                    }
                       </MDBCol>
                       <MDBCol md={5}>
                        {this.props?.mode === "view" ? <div>
                          <BasicLabel text={"Email"}/>
                          <span className={"subText-font"}>{this.state.representativeDetails?.mc_email}</span>
                        </div> :
                             <BasicTextField 
                                label={"Email"}
                                fieldValid={!validateEmail(this.state.representativeDetails?.mc_email) && this.state.representativeDetails?.mc_email}
                                inValidInput={messages.invalidInput}
                                value={this.state.representativeDetails?.mc_email || ""}
                                onChange={(e) => this.handleChange("representativeDetails",'mc_email', e.target.value)}
                            />
                        }
                       </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={5}>
                      {this.props?.mode === "view" ? <div>
                        <BasicLabel text={"Cell Number"}/>
                        <span className={"subText-font"}>{this.state.representativeDetails?.mc_cell_number}</span>
                      </div> :
                             <BasicTextField 
                                label={"Cell Number"}
                                placeholder="(xxx)xxx-xxxx"
                                fieldValid={ this.state.representativeDetails?.mc_cell_number ? !validateAllPhone(this.state.representativeDetails?.mc_cell_number) : false}
                                inValidInput={"Please enter valid phone"}
                                value={this.state.representativeDetails?.mc_cell_number || ""}
                                onChange={(e) => {
                                  let formattedValue = formatPhoneNumbers(e.target.value);
                                  if (formattedValue.length >  18) {
                                    formattedValue = formattedValue.slice(0,18);
                                  }
                                  this.handleChange("representativeDetails",'mc_cell_number', formattedValue);
                                }}
                            />
                      }
                       </MDBCol>
                    </MDBRow>
                 </MDBCol>
               {/* </MDBRow> */}
              </AccordionDetails>
            </Accordion>
          </div>
        </MDBRow>
      </div>
    );
  }
}
export default withUserContext(RepresentativeInfo);