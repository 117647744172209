export const searchTalentConfig = {
    headings: [
        {
            headingLabel: "Talent",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "To Be Billed As",
            icon: "",
            width: "13%"
        },
        {
            headingLabel: "Role",
            width: "13%"
        },
        {
            headingLabel: "Project",
            width: "13%"
        },
        {
            headingLabel: "Episode",
            width: "13%"
        },
        {
            headingLabel: "Role Type",
            width: "13%"
        },
        {
            headingLabel: "",
            width: "20%"
        }
    ],
    dataNodes: ["talent_name", "to_be_billed_as", "role", "project_name", "episode_name", "role_type_name"],
    primaryKey: "deal_memo_id",
    actions:["file-contract", "file-contract"],
    actionLabels: ["Deal Memo", "Contract"],
    hyperlinks: ["talent_name"],
};