import React from "react";
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import LandingPageTable from "./LandingPageTable";

class AnimationCastingLandingPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    return (
      <div className="LandingAnimationContainer">
          <LandingPageTable history={this.props.history} />       
      </div>
    );
  }
}
export default withRouter(withUserContext(AnimationCastingLandingPage));
