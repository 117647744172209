import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import TabComponent from "../../Common/DefaultModalLayout/Tab/TabComponent";
import {DemoParentTabs} from './DealMemoConfig';
import CastList from "./CastList";
import ExistinDealMemo from "./ExistinDealMemo";
import CreateDealMemo from "./CreateDealMemo";
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
class DealMemoDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          DemoTabList:  DemoParentTabs,
          tabValue: 1,
          isNavigation: false,
          dealMemoDetails: {},
          isSaveNewDeal: false,
          dealMemoId: null,
          isContract: false,
          isRecurring: false,
          recurringValue: null,
          isClose: false,
          totalEpisodes: null
        };
    }

    componentDidMount(){
        this.getDealDetails()
        console.log("user",this.props?.userContext?.user_profile.tenant_id,this.props?.userContext?.user_profile)
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        },()=>{
            this.setState({isContract: false})
        });
    };


    getDealDetails = (dealMemoId = 1) => {
        this.setState({ isFetchingStatusDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterTvJwpDealMemo?dealMemoId=${dealMemoId}`, this.props?.userContext?.user_profile?.tenant_id,1)
            .then(response => {
                this.setState({ dealMemoDetails: response.data[0], isFetchingStatusDetails: false });
            },
                (err) => {
                    this.setState({ dealMemoDetails: {}, isFetchingStatusDetails: false });
                    console.log("Error in fetching status Details:", err)
                })
    }
    contractFun=(value)=>{
        this.setState({isContract:true,dealMemoId: value},()=>{
        this.handleTabChange(2)
        })
      
    }
    createDeal=(value,deal_memo_id)=>{
        this.setState({tabValue:1,isSaveNewDeal: value,dealMemoId: deal_memo_id})
    }
    confirmationFun=(value,data)=>{
        this.setState({isRecurring: true,recurringValue: value},()=>{
            this.handleTabChange(2)
        })
    }
    handleCancel=()=>{
        this.setState({isClose: true},()=>{
            this.handleTabChange(1)
        })
    }
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <ExistinDealMemo selectedEpisodeId={this.props?.selectedEpisodeId} handleTabChangeFun={this.handleTabChange.bind(this)} union_id={this.props?.union_id} isSaveNewDeal={this.state.isSaveNewDeal} dealMemoId={this.state.dealMemoId}
                contractFun={this.contractFun.bind(this)}  isContract={this.state.isContract } confirmationFun={this.confirmationFun.bind(this)} totalEpisodes={this.props.totalEpisodes} isSearchFlag={this.props.isSearchFlag} searchItem={this.props.searchItem}/>;
                break
            case 2:
                return <CreateDealMemo handleTabChangeFun={this.handleTabChange.bind(this)} selectedEpisodeId={this.props?.selectedEpisodeId} season_id={this.props?.season_id} project_id={this.props?.project_id} union_id={this.props?.union_id} 
                createDealFun={this.createDeal.bind(this)} isContract={this.state.isContract } dealMemoId={this.state.dealMemoId} isRecurring={this.state.isRecurring} recurringValue={this.state.recurringValue} handleCancel={this.handleCancel} 
                isClose={this.state.isClose} totalEpisodes={this.props.totalEpisodes} confirmationFun={this.confirmationFun.bind(this)} tabValue={this.state.tabValue}/>;
                break
        }
    };

    render() {
        return (
            <div>
                <div>
                <TabComponent 
                    tabList={this.state.DemoTabList}
                    value={this.state.tabValue}
                    onChange={(e, v) => this.handleTabChange(v)}
                />
                </div>
              {this.getTabComponent()}
            </div>
        )
    }
}
export default withUserContext(DealMemoDetails);