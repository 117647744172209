import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { MDBIcon } from 'mdbreact';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import './SelectAndInputCombine.scss';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';

export default class SelectAndInputCombine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            mandatoryWarning: "Please complete this mandatory field",
            selectUnion: "Please select Union",
            duplicateSSN: "Duplicate SSN",
            placeHolderText: props.placeHolderText ? props.placeHolderText : 'Select'
        }
    }

    render() {
        let selectOptions = this.props?.options || [];
        let error = (this.props.showMandatory && !this.props?.value) || this.props.fieldValid || this.props.showDuplicateSSN || this.props?.invalidSSN || false;
        let showAsLabel = this.props.showAsLabel || false;
        let stateLabel = this.props.stateLabel || false;
        const Placeholder = () => {
            return <div>{this.state.placeHolderText}</div>;
        };
        return (
            <div className={`SelectInputFieldContainer ${this.props?.className} ${error ? "error" : ""}`}>
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {showAsLabel ? <div className="showAsLabel">{this.props.showingLabel || ""} </div>:
                    <> 
                    <SelectField
                     id={this.props?.id}
                     value={this.props?.value || ""}
                     isMandatory={this.props?.isMandatory || false}
                     disabled={this.props?.disabled || false}
                     onChange={this.props?.onChange}
                     options={this.props?.options || []}
                    />
                    {/* <Select
                        id={this.props?.id}
                        value={this.props?.value || ""}
                        variant="outlined"
                        displayEmpty
                        clearButton ={true}
                        required={this.props?.isMandatory || false}
                        className={this.props?.className || ""}
                        disabled={this.props?.disabled || false}
                        onChange={this.props?.onChange}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        IconComponent={() => this.state.isOpen ? (
                            <MDBIcon icon="chevron-down" />
                        ) : (<MDBIcon icon="chevron-right" />)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }
                        }}
                    >
                         <MenuItem disabled value="">{this.props?.defaultMenuText || "- Select -"}</MenuItem> 
                        <MenuItem disabled value="">{this.props?.defaultMenuText || this.state.placeHolderText}</MenuItem>
                        {
                            selectOptions?.map((item, index) => {
                                return <MenuItem key={index} disabled={item.is_active === 0 ? true : false} value={item.value}>
                                    <span className={item?.showRed ? 'redColorOption' : null}>{item.label}</span>
                                </MenuItem>
                            })
                        }
                    </Select> */}
                    <BasicTextField 
                        id={this.props?.id+`-input`}
                        onChange={this.props?.onInputChange}
                        value={this.props?.inputValue}
                        onBlur={this.props?.onBlur}
                        Placeholder={this.props?.inputPlaceholder}
                        limit = {this.props?.inputLimit}
                        limitWarning={this.props?.inputWarning}
                    />
                    </>
                    }
                    {this.props?.infoMsg && <div className='infoText'></div>}
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning :
                    (this.props.fieldValid ? this.props.inValidInput : this.props?.invalidSSN ? "SSN should have minimum 9 digits" : (this.props.disableSignatory ? this.state.selectUnion : (this.props.showDuplicateSSN ? this.state.duplicateSSN:  "")))}</div>}
            </div>
        );
    }
}
