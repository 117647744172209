import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import moment from 'moment';
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import DateField from "../SharedComponents/DateField/DateField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import { akaConfig, talentPersonalLimit, talentAka, talentInitialDetails } from "./config";
import messages from "../../Common/Messages.json";
import {
    validateAlpVarCharNumOnlyWithSpace, formatPhoneNumbers,
    validateEmail,
    ssnFormat,
    validateAllPhone,
    getAge
} from "../../Common/Helper";
import "./Talent.scss";
import ListField from "../SharedComponents/ListField/ListField";
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';

class TalentPersonal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            talentDetails: [],
        };
    }

    componentDidMount() {
        if (this.state.talentDetails?.dob) {
            let talentIsMinor = this.checkIfMinor(this.state.talentDetails?.dob);
            if (talentIsMinor === 'Minor') {
                this.setState({ talentIsMinor: true })
            } else {
                this.setState({ talentIsMinor: false })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.talentDetails !== state.talentDetails) {
            return {
                talentDetails: props.talentDetails,
            };
        }
        return null;
    }

    checkIfMinor = (dateOfBirth) => {
        let birthday = new Date(dateOfBirth);

        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 18) {
            // this.setState({ talentIsMinor: true })
            return 'Minor';
        } else {
            // this.setState({ talentIsMinor: false })
            return '';
        }
    }

    render() {
        return (
            <div className="TalentPersonalContainer">
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label="First Name"
                            limit={talentPersonalLimit.firstName}
                            showMandatory={this.props?.postInitiated}
                            limitWarning={messages.exceed150CharacterWarning || ""}
                            isMandatory={true}
                            value={this.props?.talentDetails?.first_name || ""}
                            onChange={(e) =>
                                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                    ? this.props.handleGeneralEdit("first_name", e.target.value)
                                    : null
                            }
                            isAlphabetVar={true}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            limit={talentPersonalLimit.middleName}
                            limitWarning={messages.exceed150CharacterWarning || ""}
                            maxLength={10}
                            label="Middle Name"
                            value={this.props?.talentDetails?.middle_name || ""}
                            onChange={(e) =>
                                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                    ? this.props?.handleGeneralEdit(
                                        "middle_name",
                                        e.target.value.length > 0 ? e.target.value : null
                                    )
                                    : null
                            }
                            isAlphabetVar={true}
                            handleAlphabetVariantSelect={(value) =>
                                this.props?.handleGeneralEdit("middle_name", value)
                            }
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label="Last Name"
                            value={this.props?.talentDetails?.last_name || ""}
                            limit={talentPersonalLimit.lastName}
                            limitWarning={messages.exceed50CharacterWarning || ""}
                            onChange={(e) =>
                                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                    ? this.props.handleGeneralEdit("last_name", e.target.value)
                                    : null
                            }
                            isAlphabetVar={true}
                            handleAlphabetVariantSelect={(value) =>
                                this.props?.handleGeneralEdit("last_name", value)
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4} className="talentAka">
                        <MDBRow>
                            <BasicLabel text={"AKA's"} type={"text"} />
                        </MDBRow>
                        {this.props.talentDetails?.akas?.map((aka, index) => (
                            <ListField
                                id={"aka" + index}
                                value={aka?.aka}
                                list={aka}
                                width={280}
                                colMd={10}
                                limit={talentPersonalLimit.aka}
                                limitWarning={messages.exceed200CharacterWarning || ""}
                                showAdd={
                                    this.props?.talentDetails?.akas?.length - 1 === index &&
                                        Object.values(aka).some(
                                            (x) => x && x !== aka.index && x !== true
                                        )
                                        ? true
                                        : false
                                }
                                showRemove={aka.aka_id || index === 0 ? false : true}
                                config={akaConfig}
                                hidePrimary={true}
                                className="representativeAgencies"
                                addCallback={() => this.props.addCallback("akas")}
                                removeCallback={() => this.props.removeCallback("akas", aka)}
                                onChange={(e) =>
                                    this.props.handleListEdit(
                                        "aka",
                                        e.target.value.length > 0 ? e.target.value : null,
                                        aka,
                                        "akas"
                                    )
                                }
                            />
                        ))}
                    </MDBCol>
                    <MDBCol md={8}>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField
                                    label="SSN"
                                    placeholder={"xxx-xx-xxxx"}
                                    value={this.props?.talentDetails?.ssn || ""}
                                    limit={this.props?.talentDetails?.ssn === ssnFormat(this.props?.talentDetails?.ssn) ? true : false}
                                    limitWarning={(this.props?.talentDetails?.ssn === ssnFormat(this.props?.talentDetails?.ssn) && this.state.talentDetails?.ssn.length > 9) ? "" : "(ex:123-12-1234)"}
                                    inValidInput={"Please enter valid ssn"}
                                    // limitWarning={messages.exceed9CharacterWarning || ""}
                                    onChange={(e) => this.props.handleGeneralEdit("ssn", ssnFormat(e.target.value))}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id={"gender"}
                                    className={"gender"}
                                    value={this.props?.talentDetails?.gender || ""}
                                    showMandatory={this.props?.postInitiated}
                                    isMandatory={true}
                                    label={"Gender"}
                                    defaultMenuText={"- Select -"}
                                    options={this.props?.genderOptions}
                                    onChange={(e) =>
                                        this.props.handleGeneralEdit("gender", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={5}>
                                <SelectField
                                    id={"Ethnicity"}
                                    className={"Ethnicity"}
                                    value={this.props?.talentDetails?.ethinicity || ""}
                                    showMandatory={this.props?.postInitiated}
                                    isMandatory={true}
                                    label={"Ethnicity"}
                                    defaultMenuText={"- Select -"}
                                    options={this.props?.ethnicityOptions}
                                    onChange={(e) =>
                                        this.props.handleGeneralEdit("ethinicity", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}></MDBCol>
                    <MDBCol md={2}>
                        <DateField
                            id="dob"
                            label={"DOB"}
                            showMandatory={this.props?.postInitiated}
                            isMandatory={true}
                            value={this.props?.talentDetails?.dob || null}
                            onChange={(e) => {
                                let talentIsMinor = this.checkIfMinor(e.target.value);
                                if (talentIsMinor === 'Minor') {
                                    this.setState({ talentIsMinor: true })
                                } else {
                                    this.setState({ talentIsMinor: false })
                                }
                                this.props.handleGeneralEdit("dob", e.target.value)
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicLabel text={"Age"} />
                        <span>
                            <span className="age">
                                {this.props?.talentDetails?.dob && getAge(this.props?.talentDetails?.dob)}
                            </span>
                            <span className="is-minor">{this.checkIfMinor(moment(this.props?.talentDetails?.dob).format("YYYY-MM-DD"))}</span>
                        </span>
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicCheckbox
                            label="Deceased"
                            checked={this.props?.talentDetails?.is_deceased == 1 ? true : false}
                            onChange={(e) =>
                                this.props.handleGeneralEdit("is_deceased", e.target.checked)
                            }
                            align="start"
                            id={"deceased"}
                            disabled={!this.props?.userContext?.user_profile?.role_name === 'Admin' || false}
                        />
                    </MDBCol>
                    {this.props?.talentDetails?.is_deceased == 1 &&
                        <MDBCol md={2} className="pl-0">
                            <DateField
                                id="dateOfdeath"
                                label={"Date of Death"}
                                value={this.props?.talentDetails?.dod || null}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("dod", e.target.value)
                                }
                            />
                        </MDBCol>
                    }
                </MDBRow>
                {this.state.talentIsMinor &&
                    <>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField
                                    label="Primary Guardian Name"
                                    limitWarning={messages.exceed200CharacterWarning || ""}
                                    limit={talentPersonalLimit.primary_guardian_name}
                                    isMandatory={true}
                                    showMandatory={this.props?.postInitiated}
                                    value={this.props?.talentDetails?.primary_guardian_name || ""}
                                    onChange={(e) =>
                                        validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                            ? this.props.handleGeneralEdit(
                                                "primary_guardian_name",
                                                e.target.value
                                            )
                                            : null
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label="Guardian Email"
                                    showMandatory={this.props?.postInitiated}
                                    isMandatory={true}
                                    value={this.props?.talentDetails?.primary_guardian_email || ""}
                                    fieldValid={!validateEmail(this.props?.talentDetails?.primary_guardian_email) && this.props?.talentDetails?.primary_guardian_email}
                                    inValidInput={messages.invalidInput}
                                    onChange={(e) => this.props.handleGeneralEdit("primary_guardian_email", e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label="Phone"
                                    value={this.props?.talentDetails?.primary_guardian_phone || ""}
                                    fieldValid={this.props?.talentDetails?.primary_guardian_phone ? !validateAllPhone(this.props?.talentDetails?.primary_guardian_phone) : false}
                                    inValidInput={"Please enter valid phone"}
                                    onChange={(e) => this.props.handleGeneralEdit("primary_guardian_phone", formatPhoneNumbers(e.target.value))}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField
                                    label="Secondary Guardian Name"
                                    limitWarning={messages.exceed200CharacterWarning || ""}
                                    limit={talentPersonalLimit.secondary_guardian_name}
                                    // isMandatory={true}
                                    // showMandatory={this.props?.postInitiated}
                                    value={this.props?.talentDetails?.secondary_guardian_name || ""}
                                    onChange={(e) =>
                                        validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                            ? this.props.handleGeneralEdit(
                                                "secondary_guardian_name",
                                                e.target.value
                                            )
                                            : null
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label="Guardian Email"
                                    // showMandatory={this.props?.postInitiated}
                                    // isMandatory={true}
                                    value={this.props?.talentDetails?.secondary_guardian_email || ""}
                                    fieldValid={!validateEmail(this.props?.talentDetails?.secondary_guardian_email) && this.props?.talentDetails?.secondary_guardian_email}
                                    inValidInput={messages.invalidInput}
                                    onChange={(e) => this.props.handleGeneralEdit("secondary_guardian_email", e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    label="Phone"
                                    value={this.props?.talentDetails?.secondary_guardian_phone || ""}
                                    fieldValid={this.props?.talentDetails?.secondary_guardian_phone ? !validateAllPhone(this.props?.talentDetails?.secondary_guardian_phone) : false}
                                    inValidInput={"Please enter valid phone"}
                                    onChange={(e) => this.props.handleGeneralEdit("secondary_guardian_phone", formatPhoneNumbers(e.target.value))}
                                />
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label="Loanout Company"
                            limitWarning={messages.exceed200CharacterWarning || ""}
                            value={this.props?.talentDetails?.loanout_company || ""}
                            onChange={(e) =>
                                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                                    ? this.props.handleGeneralEdit(
                                        "loanout_company",
                                        e.target.value
                                    )
                                    : null
                            }
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicTextField
                            label="Loanout No."
                            value={this.props?.talentDetails?.loanout_no || ""}
                            onChange={(e) =>
                                this.props.handleGeneralEdit("loanout_no", e.target.value)
                            }
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicTextField
                            label="State of Inc."
                            value={this.props?.talentDetails?.state_inc || ""}
                            onChange={(e) =>
                                this.props.handleGeneralEdit("state_inc", e.target.value)
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}></MDBCol>
                    <MDBCol md={3}></MDBCol>
                    <MDBCol md={5} className="citizen-display">
                        <MDBRow>
                            <MDBCol md={6} id={"citizenof"} className="pr-0">
                                <SelectField
                                    label="Citizen Of"
                                    value={this.props?.talentDetails?.citizen_of || ""}
                                    options={this.props?.countryOptions}
                                    onChange={(e) =>
                                        this.props.handleGeneralEdit("citizen_of", e.target.value)
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={6} id={"asof"} className="pl-0">
                                <DateField
                                    id="as_of"
                                    label={"As Of"}
                                    value={this.props?.talentDetails?.as_of || null}
                                    onChange={(e) =>
                                        this.props.handleGeneralEdit("as_of", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
export default withUserContext(TalentPersonal);
