import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import './ProdCompany.scss';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { validateCharNumOnlyWithSpace, validateCharNumWithHyphenSlash, validateCharctersSpaceOnly } from '../../Common/Helper';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IndeterminateCheckBoxRounded } from '@material-ui/icons';
import { withUserContext } from '../../../contexts/UserContext';
import { checkDropDown, checkSelectDropDown, initialObjectConfig } from './config';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import Popover from '@material-ui/core/Popover';
import ProdCompanyAddress from './ProdCompanyAddress';
import SearchFieldWithAddValue from '../../components/SharedComponents/SearchFieldWithAddValue';
import axios from 'axios';
import NewProductionCompany from '../../Common/NewProductionCompany/NewProductionCompany';
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent";

export default withUserContext(class ProdCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            production_companies: props?.production_companies,
            companyNames: [],
            loading: false,
            company_details: [],
            // unionList: props.production_companies_list.union_list,
            disableUnion: true,
            disableSignatory: false,
            anchorEl: null,
            openPopover: false,
            config: initialObjectConfig,
            setProdCompanyPopOver: null,
            isProdCompanyFetch: false,
            searchCancelToken: null,
            prodCompanyOptions: [],
            productionCompanySearchedSelectedNew: null,
            productionCompanySearchedSelected: null,
            openProductionCompanyPopOver: null,
            newProdCompany: null,
            showError: false,
            showSuccess: false,
            showFail: false,
            saveFlag: false,

        }

    }

    componentDidMount = () => {
        if (this.state.production_company?.production_companies?.company_name) {
            this.formatDetails();
        }
        this.getDetails('PRODUCTION_COMPANIES', '')
        this.getDetails('PRODUCTION_COMPANIES_SIGNATOR', '')
        this.getUnionList()
    }

    getUnionList = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=UNION`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item?.lookup_value, label: item?.lookup_value, id: item?.lookup_data_id }));
                this.setState({ unionList: formattedList });

            },
                (err) => {
                    console.log("Error in fetching lookup:", err)
                })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            production_companies: nextProps.production_companies
        });
    }

    getDetails = (objectType, searchString, onFlyProdCompanyAdded = false, type = null, value = null) => {
        this.setState({ loading: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, true)
            .then(response => {
                if (objectType == 'PRODUCTION_COMPANIES') {
                    let formattedList = response.data?.map(item => ({ value: item?.production_company, text: item?.production_company, id: item?.production_company_id, signator: item?.signator, signator_email: item?.signator_email, address: item?.address, city: item?.city, country: { value: item?.country, text: item?.country }, state: (item?.country === 'United States' || item?.country === 'USA' || item?.country === 'US') ? { value: item?.state, text: item?.state } : item?.state, zip: item?.zip }));
                    this.setState({
                        companyNames: formattedList,
                        loading: false
                    }, () => {
                        if (onFlyProdCompanyAdded === true) {
                            this.handleChange(type, value)
                        }
                    })
                } else if (objectType == 'PRODUCTION_COMPANIES_SIGNATOR') {
                    let formattedList = response.data?.map(item => ({ value: item?.signator, label: item?.signator, signator_email: item?.signator_email }));
                    this.setState({
                        signatorOptions: formattedList,
                        loading: false
                    })
                }

            },
                (err) => {
                    console.log("Error in fetching Company Details:", err)
                })
    }


    addRow = () => {
        this.props?.addCallback('production_companies', this.props.index)
    }
    removeRow = () => {
        this.props?.removeCallback('production_companies', this.state.production_companies)
    }

    handleUnion = (val) => {
        let company_detail = this.state.production_company
        this.state.unionList.map((item) => {
            if (val === item.value) {
                company_detail.production_companies_list['union_name'] = item.label;
                company_detail.production_companies_list['union_id'] = item.value;
                company_detail.production_companies_list['production_company_signators_name'] = item.signatory;
                company_detail.production_companies_list['signatory_id'] = item.signatory_id;
                company_detail.production_companies_list['production_company_signators_id'] = item.production_company_signators_id;
            }
        })
        this.setState({ production_company: company_detail });
        this.props.handleListEdit('production_companies_list', this.state.production_company.production_companies_list, this.props.index)
    }

    formatProductionData = (id, name, data, newValue, selectUnion = false) => {
        let company_detail = this.state.production_company
        let val = [];
        newValue.signators.forEach(item => {
            val.push({
                label: item.union, value: item.union_id,
                signatory: item.full_name, signatory_id: item.signatory_id, production_company_signators_id: item.production_company_signators_id
            })
        })
        this.setState({ unionList: val, disableUnion: false, disableSignatory: true });
        company_detail.production_companies[id] = newValue.production_company_id;
        company_detail.production_companies[name] = newValue.name;
        company_detail.production_companies[data] = {
            'production_id': newValue.production_company_id,
            'name': newValue.name
        };
        company_detail.production_companies['union_name'] = val[0].label;
        company_detail.production_companies['union_id'] = val[0].value;
        company_detail.production_companies['production_company_signators_name'] = val[0].signatory;
        company_detail.production_companies['signatory_id'] = val[0].signatory_id;
        company_detail.production_companies['production_company_signators_id'] = val[0].production_company_signators_id;
        company_detail.production_companies['union_list'] = val;
        this.setState({ production_company: company_detail }, () => {
            if (selectUnion) {
                this.handleUnion(this.state.unionList[0]?.value)
                // this.props?.handleloader(false)
            }
        });
        this.props.handleListEdit('production_companies', this.state.production_company.production_companies, this.props.index)
    }

    formatDetails = () => {
        let val = [];
        val.push({
            label: this.state.production_company.production_companies.union,
            value: this.state.production_company.production_companies.union_id,
            signatory: this.state.production_company.production_companies.signator
        })
        this.setState({ unionList: val, disableUnion: false, disableSignatory: false });
    }

    handleOpenNotesInlineModals = (e) => {
        // console.log("dataItem", dataItem, nodeName, rowIndex, event)
        this.setState({
            // commentsToDisplay: dataItem?.comments,
            setCommentsDisplayModal: true, anchorEl: e.currentTarget
            // commentsDisplayFlag: rowIndex
        })
    }

    open = (e) => {
        // console.log("dataItem", dataItem, nodeName, rowIndex, event)
        this.setState({
            // commentsToDisplay: dataItem?.comments,
            setAddressPopover: true, anchorAddEl: e.currentTarget
            // commentsDisplayFlag: rowIndex
        })
    }

    handleChange = (field, newValue) => {
        if (field === 'production_company') {
            if (newValue) {
                let selectedProdCompData;
                if (newValue?.optionType === "new" || newValue?.production_company) {
                    if (newValue?.optionType === "new") {
                        selectedProdCompData = this.state.companyNames?.find(item => item.value === newValue.value);

                        this.setState({ openProductionCompanyPopOver: true, newProdCompany: newValue }, () => {
                            this.props.handleListEdit('production_companies', 'production_company', newValue, this.props.index)
                            this.props.handleListEdit('production_companies', 'address', newValue?.address, this.props.index)
                            this.props.handleListEdit('production_companies', 'city', newValue?.city, this.props.index)
                            this.props.handleListEdit('production_companies', 'state', newValue?.state, this.props.index)
                            this.props.handleListEdit('production_companies', 'country', newValue?.country, this.props.index)
                            this.props.handleListEdit('production_companies', 'zip', newValue?.zip, this.props.index)
                        })
                    }
                    if (newValue?.production_company) {
                        selectedProdCompData = this.state.companyNames?.find(item => item.value === newValue.production_company);

                        this.setState({ newProdCompany: newValue }, () => {
                            this.props.handleListEdit('production_companies', 'production_company', selectedProdCompData, this.props.index)
                            this.props.handleListEdit('production_companies', 'address', newValue?.address, this.props.index)
                            this.props.handleListEdit('production_companies', 'city', newValue?.city, this.props.index)
                            this.props.handleListEdit('production_companies', 'state', newValue?.state, this.props.index)
                            this.props.handleListEdit('production_companies', 'country', newValue?.country, this.props.index)
                            this.props.handleListEdit('production_companies', 'zip', newValue?.zip, this.props.index)
                        })
                    }
                } else {
                    selectedProdCompData = this.state.companyNames?.find(item => item.id === newValue.value);

                    this.setState({ selectedProdComp: newValue, selectedTalentData: selectedProdCompData }, () => {
                        this.props.handleListEdit('production_companies', 'production_company', selectedProdCompData, this.props.index);
                        this.props.handleListEdit('production_companies', 'production_company_id', selectedProdCompData?.id, this.props.index);
                        this.props.handleListEdit('production_companies', 'signator', selectedProdCompData?.signator, this.props.index)
                        this.props.handleListEdit('production_companies', 'signator_email', selectedProdCompData?.signator_email, this.props.index)
                        this.props.handleListEdit('production_companies', 'address', selectedProdCompData?.address, this.props.index)
                        this.props.handleListEdit('production_companies', 'city', selectedProdCompData?.city, this.props.index)
                        this.props.handleListEdit('production_companies', 'state', selectedProdCompData?.state, this.props.index)
                        this.props.handleListEdit('production_companies', 'country', selectedProdCompData?.country, this.props.index)
                        this.props.handleListEdit('production_companies', 'zip', selectedProdCompData?.zip, this.props.index)
                        // this.props.handleListEdit('production_companies', 'project_production_company_id', newValue?.id, this.props.index)
                    })
                }
            }
            else {
                this.setState({ selectedProdComp: null }, () => {
                    this.props.handleListEdit('production_companies', 'production_company', null, this.props.index);
                    this.props.handleListEdit('production_companies', 'production_company_id', null?.id, this.props.index);
                    this.props.handleListEdit('production_companies', 'signator', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'signator_email', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'address', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'city', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'state', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'country', '', this.props.index)
                    this.props.handleListEdit('production_companies', 'zip', '', this.props.index)
                    // this.props.handleListEdit('production_companies', 'project_production_company_id', newValue?.id, this.props.index)
                })
            }
        }
        if (field === 'union') {
            this.props.handleListEdit('production_companies', 'union', newValue, this.props.index)
        }
        if (field === 'signator') {
            let signator_email = this.state.signatorOptions?.find(item => item?.value == newValue)
            this.props.handleListEdit('production_companies', 'signator', newValue, this.props.index)
            this.props.handleListEdit('production_companies', 'signator_email', signator_email?.signator_email, this.props.index)
        }
        if (field === 'notes') {
            this.props.handleListEdit('production_companies', 'notes', newValue, this.props.index)
        }
        if (field === 'signator_email') {
            this.props.handleListEdit('production_companies', 'signator_email', newValue, this.props.index)
        }
        if (field === 'is_primary') {
            this.props.handleListEdit('production_companies', 'is_primary', newValue, this.props.index)
        }
        // if (field === 'address') {
        //     this.props.handleListEdit('production_companies', 'address', newValue, this.props.index)
        // }
        // if (field === 'city') {
        //     this.props.handleListEdit('production_companies', 'city', newValue, this.props.index)
        // }
        // if (field === 'state') {
        //     this.props.handleListEdit('production_companies', 'state', newValue, this.props.index)
        // }
        // if (field === 'zip') {
        //     this.props.handleListEdit('production_companies', 'zip', newValue, this.props.index)
        // }
        // if (field === 'country') {
        //     this.props.handleListEdit('production_companies', 'country', newValue, this.props.index)
        // }
    }

    openAddressPopover = (e) => {
        let config = {
            address: this.state?.production_companies?.address,
            city: this.state?.production_companies?.city,
            state: typeof (this.state?.production_companies?.state) === 'object' ? this.state?.production_companies?.state : this.state?.production_companies?.country?.value ?
                (this.state?.production_companies?.country?.value === 'United States' || this.state?.production_companies?.country?.value === 'USA' || this.state?.production_companies?.country?.value === 'US') ? { value: this.state?.production_companies?.state, text: this.state?.production_companies?.state } : this.state?.production_companies?.state
                : (this.state?.production_companies?.country === 'United States' || this.state?.production_companies?.country === 'USA' || this.state?.production_companies?.country === 'US') ? { value: this.state?.production_companies?.state, text: this.state?.production_companies?.state } : this.state?.production_companies?.state,
            zip: this.state?.production_companies?.zip,
            country: typeof (this.state?.production_companies?.country) === 'object' ? this.state?.production_companies?.country : { value: this.state?.production_companies?.country, text: this.state?.production_companies?.country }
            // (this.state?.production_companies?.country?.value || this.state?.production_companies?.country?.value == "" )? this.state?.production_companies?.country : {value: this.state?.production_companies?.country, text: this.state?.production_companies?.country}
        }
        this.setState({ config: config }, () => {
            this.setState({ openPopover: true })
        })
        // this.setState({
        //     // commentsToDisplay: dataItem?.comments,
        //     setAddressModal: true, anchorAddressEl: e.currentTarget
        //     // commentsDisplayFlag: rowIndex
        // })
    }

    closeAddressPopOver = () => {
        this.setState({ openPopover: false })
    }
    onSave = (obj) => {
        let postJson = JSON.parse(JSON.stringify({ ...this.state.production_companies }));
        //let tempProdCmp = JSON.parse(JSON.stringify({ ...this.state.production_companies })),

        if (typeof postJson.state == "object") {
            postJson.state = obj?.state
        }
        if (typeof postJson.country == "object") {
            postJson.country = obj?.country
        }
        if (typeof this.state.production_companies?.production_company == "object") {
            postJson.production_company = this.state.production_companies?.production_company?.text;
        }
        if (typeof this.state.production_companies?.production_company == "object" && this.state.production_companies?.production_company?.id) {
            postJson.production_company_id = this.state.production_companies?.production_company?.id;
        } else {
            postJson.production_company_id = this.state.production_companies?.production_company_id;
        }

        postJson.address = obj?.address;
        postJson.city = obj?.city;
        postJson.zip = obj?.zip;
        postJson.project_id = this.props?.project_id;
        //this.setState({ isSubmit: true })
        CasterService.postDataParams(
            Constants.CasterServiceBaseUrl + "/casterFeatureProductionCompany",
            postJson,
            this.props.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                if (response?.data?.error) {
                    this.setState({ isSubmit: false, showError: false });
                    this.notificationComponent(true, "fail");
                } else {
                    this.setState({ isSubmit: false, showError: false });
                    //this.props?.setNewObjectSelected('production_company', postJson);
                    this.notificationComponent(true, "success");
                    // this.props?.getProjectDetails("fromEditProdCompany");
                    this.getDetails('PRODUCTION_COMPANIES', '')
                    //this.props?.handleClose();
                    this.closeAddressPopOver()
                }
            },
            (err) => {
                this.setState({ isSubmit: false, showError: false });
                this.notificationComponent(true, "fail");
            }
        );
    }
    onLocationSave = (currentLocation) => {

        let tempCurrentLocation = {};
        tempCurrentLocation["state"] = {
            "value": currentLocation?.state,
            "text": currentLocation?.state
        };
        tempCurrentLocation["country"] = {
            "value": currentLocation?.country,
            "text": currentLocation?.country
        };
        tempCurrentLocation["value"] = currentLocation?.value;
        tempCurrentLocation["text"] = currentLocation?.text;
        tempCurrentLocation["id"] = currentLocation?.production_company_id;
        tempCurrentLocation["signator_email"] = currentLocation?.signator_email;
        tempCurrentLocation["address"] = currentLocation?.address;
        tempCurrentLocation["city"] = currentLocation?.city;
        tempCurrentLocation["zip"] = currentLocation?.zip;
        // if (typeof tempCurrentLocation?.production_company == "object") {
        //     delete tempCurrentLocation[production_company];
        // }
        // if (currentLocation?.address && currentLocation?.country) {
        this.props.handleListEdit('production_companies', 'address', currentLocation?.address, this.props.index)
        this.props.handleListEdit('production_companies', 'city', currentLocation?.city, this.props.index)
        this.props.handleListEdit('production_companies', 'state', currentLocation?.state, this.props.index)
        this.props.handleListEdit('production_companies', 'zip', currentLocation?.zip, this.props.index)
        this.props.handleListEdit('production_companies', 'country', currentLocation?.country, this.props.index)
        this.props.handleListEdit('production_companies', 'production_company', tempCurrentLocation, this.props.index)
        this.onSave(currentLocation);

        // }
    }

    handleCloseProdCompanyPopOver = () => {
        this.setState({ setProdCompanyPopOver: null, openProductionCompanyPopOver: false })
    }
    getObjectDetails(objectType, searchString = null) {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        this.setState({ [`is${objectType}Fetch`]: true })
        CasterService.getDataWithCancel(
            Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, cancelToken)
            .then((response) => {
                this.setState({ [`is${objectType}Fetch`]: false })
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList;
                    if (objectType == "PRODUCTION_COMPANIES") {
                        formattedList = response?.data?.map(i => ({
                            value: i?.production_company_id,
                            text: i?.production_company,
                            //aka: i?.aka,
                            //headerName: i?.crew_name
                        }))
                        this.setState({ prodCompanyOptions: formattedList })
                    }

                    console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ isProdCompanyFetch: false })
                    console.log("Post object details error: " + err);
                });
    }
    setObjectSelected(type, value) {
        //value.optionType = 'edit'
        this.handleChange(type, value)

    }
    setNewObjectSelected(type, value) {
        this.getDetails('PRODUCTION_COMPANIES', '', true, type, value);
        //value.optionType = 'save'

    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false });
        }
    };
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success":
                return this.setState({ showSuccess: value, saveFlag: true });
            case "fail":
                return this.setState({ showFail: value });
        }
    };
    render() {
        let openEpsCountModal = Boolean(this.state.setCommentsDisplayModal);
        let severity = this.state.showFail
            ? "error"
            : this.state.showSuccess
                ? "success"
                : "";
        let messageNotification = this.state.showSuccess
            ? "The changes are saved successfully!"
            : this.state.showFail
                ? "Saving changes failed!"
                : "";
        // let setPopover = Boolean(this.state.setAdd)
        return (
            <div className="newproductioncompanyinshow">
                <NotificationComponent
                    open={this.state.showSuccess || this.state.showFail}
                    message={messageNotification}
                    severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)}
                />
                <MDBRow>
                    <MDBCol md={3} id="rolesDropDown">
                        {/* <BasicLabel text={this.props.index === 0 ? "Production Company" : null} /> */}
                        {/* {this.props.showAsLabel ? <div className="showAsLabel">
                            {this.state.production_company?.production_companies?.company_name || ""} </div> : */}
                        {/* <SearchSelectField
                            id={`prodComp${this.props.index}`}
                            options={this.state.companyNames || []}
                            width={'100%'}
                            detail_selected={this.state.production_companies?.production_company}
                            valueSelected={(e, value) => this.handleChange('production_company', value)}
                            searchText={(e) => this.getCompanyDetails('PRODUCTION_COMPANIES', e.target.value)}
                            multiple={false}
                            searchSelect={true}
                            placeholder={"- Production Company -"}
                        /> */}
                        <SearchFieldWithAddValue
                            id={`prodComp${this.props.index}`}
                            //id="ProdComapnyField"
                            searchSelect={true}
                            disabled={this.props?.mode == 'edit' ? true : false}
                            loading={this.state.isProdCompanyFetch}
                            detail_selected={this.state.production_companies?.production_company}
                            //detail_selected={this.state.productionCompanySearchedSelected}
                            options={this.state.prodCompanyOptions || []}
                            onChange={(e, value) => this.handleChange('production_company', value)}
                            searchText={ev => {
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.getObjectDetails('PRODUCTION_COMPANIES', ev.target.value);
                                }
                                else {
                                    this.setState({ isProdCompanyFetch: false })
                                }
                            }}
                            newLabel={'Company'}
                            placeholder={"- Search Production Company -"}
                        />
                        {/* <Autocomplete size="medium"
                                id={this.props.index + "-companyName"}
                                className="tags-standard"
                                // options={checkSelectDropDown}
                                options={this.state.companyNames ? this.state.companyNames : []}
                                value={[]}
                                // value={(this.state.production_company?.production_companies?.production_data ?
                                //     this.state.production_company?.production_companies?.production_data : null)}
                                getOptionLabel={(option) => (option.text)}
                                fullWidth={true}
                                // onKeyPress={(event, newValue) => {
                                //     //this.expKeypress(event, newValue)
                                // }}
                                // onChange={(event, newValue) => {
                                //     this.formatProductionData('production_company_id', 'production_company_name', 'production_data', newValue)
                                // }}
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        id={"assignedTo"}
                                        placeholder="-Search or Select-"
                                        margin="normal"
                                        onChange={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if ((ev.target.value !== "" || ev.target.value !== null)) {
                                                //  this.onChangeHandle(ev.target.value);
                                                validateCharNumOnlyWithSpace(ev.target.value) &&
                                                    this.getCompanyDetails('PROJECT_PRODUCTION_COMPANIES', ev.target.value)
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            /> */}
                        {/* } */}

                    </MDBCol>
                    <MDBCol md={2} id="Union">
                        <SelectField
                            id={`prodCompUnion${this.props.index}`}
                            value={this.state.production_companies?.union || ""}
                            placeHolderText={"Union"}
                            // label={this.props.index === 0 ? "Union" : null}
                            // disabled={this.state.disableUnion}
                            // options={checkDropDown}
                            options={this.state.unionList}
                            // showAsLabel={this.props.showAsLabel}
                            // disableSignatory={this.state.disableSignatory}
                            onChange={(e) => {
                                this.handleChange('union', e.target.value);
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="signatory">
                        <SelectField
                            id={`prodCompSignatory${this.props.index}`}
                            value={this.state.production_companies?.signator || ""}
                            placeHolderText={"Signator"}
                            // label={this.props.index === 0 ? "Union" : null}
                            // disabled={this.state.disableUnion}
                            // options={checkDropDown}
                            options={this.state.signatorOptions}
                            // showAsLabel={this.props.showAsLabel}
                            // disableSignatory={this.state.disableSignatory}
                            onChange={(e) => {
                                this.handleChange('signator', e.target.value);
                            }}
                        />
                        {/* <BasicTextField
                            // label={this.props.index === 0 ? "Signatory" : null}
                            // disabled={true}
                            // showAsLabel={this.props.showAsLabel}
                            placeholder={"Signator"}
                            onChange={(e) => validateCharctersSpaceOnly(e.target.value) ? this.handleChange('signator', e.target.value.length > 0 ? e.target.value : null) : null}
                            value={this.state.production_companies?.signator || ""}
                        /> */}
                    </MDBCol>
                    <MDBCol md={5} className={this.props?.mode?.toLowerCase() == 'edit' ? "signatoryEmail" : "signatoryEmail newProj"}>
                        <BasicTextField
                            // label={this.props.index === 0 ? "Signatory" : null}
                            // disabled={true}
                            // showAsLabel={this.props.showAsLabel}
                            placeholder={"Email"}
                            onChange={(e) => validateCharctersSpaceOnly(e.target.value) ? this.handleChange('signator_email', e.target.value.length > 0 ? e.target.value : null) : null}
                            value={this.state.production_companies?.signator_email || ""}
                        />
                        <MDBIcon icon="file" title={this.state?.production_companies?.notes ? this.state?.production_companies?.notes : null}
                            className={this.state?.production_companies?.notes ? "blue-color-icon" : ""}
                            onClick={(e) => this.handleOpenNotesInlineModals(e)}
                        />
                        <MDBIcon icon="pen"
                            // className="float-right"
                            disabled={this.state.production_companies?.production_company ? true : false}
                            onClick={this.state.production_companies?.production_company ? (e) => this.openAddressPopover(e) : null}
                        />
                        <input
                            name={"prod-radio"}
                            size="medium"
                            type="radio"
                            // value={this.props?.value || 1}   
                            // defaultChecked={this.props?.isPrimary}
                            checked={this.state.production_companies?.is_primary === 1}
                            onChange={(e) => this.handleChange('is_primary', e.target.checked === true ? 1 : 0)}
                        />
                        {
                            this.props.showRemove ?
                                <RemoveCircleIcon className="removeButton"
                                    fontSize="medium"
                                    onClick={() => this.removeRow()}
                                /> : null
                        }
                        {this.props.showAdd ? <AddCircleIcon className="addButton"
                            fontSize="medium"
                            onClick={() => this.addRow()}
                        /> : null}

                    </MDBCol>
                </MDBRow>
                <Popover
                    open={openEpsCountModal}
                    className="flag-popover editProjectNotes"

                    anchorEl={this.state.anchorEl}
                    onClose={() => {
                        this.setState({
                            setCommentsDisplayModal: null, anchorEl: null
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}

                >
                    <div>
                        {/* <div style={{ fontSize: '0.65rem', fontWeight: 600 }}>Comments</div> */}
                        <BasicTextArea
                            id="Comments"
                            rows={5}
                            value={this.state.production_companies?.notes || ""}
                            onChange={(e) => {
                                this.handleChange('notes', e.target.value);
                            }}
                            showAsLabel={false}
                            placeholder={"Comments"}
                        />
                        {/* <div className={"eps-height"}> */}

                        {/* <BasicTextField
                                id="Comments"
                                //   label={"Phone Number"}
                                showAsLabel={this.props?.mode !== "Edit"}
                                placeholder={"Comments"}
                                value={this.state.production_companies?.notes || ""}
                                onChange={(e) => {
                                    this.handleChange('notes', e.target.value);
                                }}
                            // text={"Checking"}
                            //   onChange={this.props.onChange}
                            //   disabled={this.props?.disabled || false}
                            //   limitWarning={this.props.limitWarning || ""}
                            //   limit={this.props.limit}
                            //   showAsLabel={this.props.disabled || false}
                            //   placeholder={this.props?.placeholder || ""} 
                            // /> */}
                        {/* </div> */}
                    </div>
                </Popover>
                {
                    this.state.openPopover &&
                    <ProdCompanyAddress
                        production_companies={this.state.production_companies}
                        initialObjectConfig={this.state.config}
                        handleChange={this.handleChange}
                        openPopover={this.state.openPopover}
                        // locationConfig={this.props?.locationConfig}
                        // openLocationPopOver={this.props?.openLocationdialogue}
                        popOverClose={this.closeAddressPopOver}
                        onLocationSave={this.onLocationSave}
                        postInitiated={this.props?.postInitiated}
                        isSubmit={this.state.isSubmit}
                        project_id={this.props?.project_id}
                        //getProjectDetails={this.props?.getProjectDetails}
                        {...this.props}
                    // isEditing={this.props?.isEditing}
                    // initialLocationConfig={companiesList}
                    // onChangeObject={this.props.onChangeObject.bind(this)}
                    />
                }
                {/* {this.state.openProductionCompanyPopOver ? <NewProdComapnyModal
                    isDashboard={true}
                    id={'simple-popover'}
                    open={this.state.openProductionCompanyPopOver}
                    setPopOver={this.state.setProdCompanyPopOver}
                    handleClose={this.handleCloseProdCompanyPopOver.bind(this)}
                    crewData={this.state?.selectedCrew}
                    getObjectDetails={this.getObjectDetails.bind(this)}
                    setObjectSelected={this.setObjectSelected.bind(this)}
                    setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                /> : null} */}
                {
                    this.state.openProductionCompanyPopOver ? <NewProductionCompany
                        isDashboard={true}
                        id={'simple-popover'}
                        open={this.state.openProductionCompanyPopOver}
                        setPopOver={this.state.setProdCompanyPopOver}
                        handleClose={this.handleCloseProdCompanyPopOver.bind(this)}
                        prodCompanyData={this.state?.newProdCompany}
                        getObjectDetails={this.getObjectDetails.bind(this)}
                        setObjectSelected={this.setObjectSelected.bind(this)}
                        setNewObjectSelected={this.setNewObjectSelected.bind(this)}
                        prodCompanyindex={this.props.index}
                        mode={this.state.mode}
                        {...this.props}
                    /> : null
                }
            </div >

        )
    }
}
);