export const projectDetails = {
  project_name: null,
  project_title: null,
  season_no: null,
  gl_code: null,
  end_year: null,
  start_year: null,
  season: null,
  id: null,
  project_type: null,
  union: null,
  production_entity: {value:null,text:null},
  signator: null,
  outlet: null,
  intended_market: null,
  project_notes:null,
  project_type_id:null,
  outlet_id:null,
  production_entity_id:null,
  production_entity_name:null,
  intended_market_id:null,
  union_id:null,
  season_year:null,
  project_id:null,
  season_id: null,
  union_name:null,
};

export const projectConfig = {
    headings: [
        {
            headingLabel: "No",
            width: "5%"
        },
        {
            headingLabel: "Episode Name",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "GL/SAP Code",
            width: "70%"
        },
      
    ],
    dataNodes: ["episode_no","episode_name", "gl_code"],
    hyperlinks:["no","episode_name"],
    primaryKey: "episode_id",
    actions:["pen"],

};

export const renderProjectList =[
    {
        no:"1",
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
   
    },
    {
        no:"12",
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
    },
    {
        no:"3",
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
   
    },
    {
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
    },
    {
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
   
    },
    {
       episode_name : "project",
       gl_sap_code: "2000-2001",
       outlet:"123",
       type:"type",
       episodes:"episoded",
    }
   ]
