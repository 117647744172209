import React from "react";
import "./UserRoleManagement.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import TabComponent from "../../Common/DefaultModalLayout/Tab/TabComponent";
import UsersList from "./UserList";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import exportFromJSON from 'export-from-json';

class UserRoleManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DemoTabList: [
                {
                    label: "User List",
                    value: 1
                }
            ],
            tabValue: 1,
            isNavigation: false,
            isUrm: true,
            setUserModal: false,
        };
        this.userRef = React.createRef();
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <UsersList
                    userRef={this.userRef}
                    isUrm={this.state.isUrm}
                // handleUserAudit={}
                />
                break
        }
    };

    handleShowUserModal = () => {
        this.setState({ setUserModal: true });
    };
    hideUserModal = () => {
        this.setState({ setUserModal: false });
    };

    handleRefresh = () => {
        this.userRef.current.getAllUsersData();
    }

    handleUserAuditReport = () => {
        let tableData = this.userRef.current.getUserList();
        const data = tableData
        const fileName = 'UserRoleAudit'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    render() {
        return (
            <div className="ProjectContainer">
                <div className="tab-display">
                    <div>
                        <TabComponent
                            tabList={this.state.DemoTabList}
                            value={this.state.tabValue}
                            onChange={(e, v) => this.handleTabChange(v)}
                        />
                    </div>
                    {this.state.tabValue === 1 &&
                        <div className="button-display">
                            <BasicButton
                                className="new-user-btn"
                                text={"New User"}
                                variant="contained"
                                type="inline"
                                icon={"plus-circle"}
                                onClick={this.handleShowUserModal}
                            />
                            <div className="user-audit-btn" >
                                <BasicButton
                                    variant="outlined"
                                    icon="file-pdf"
                                    text={"User Audit Report"}
                                    onClick={this.handleUserAuditReport}
                                />
                            </div>
                        </div>
                    }
                </div>
                {this.getTabComponent()}
                {this.state.setUserModal && (
                    <MainViewModalComponent
                        open={this.state.setUserModal}
                        handleClose={this.hideUserModal}
                        headerText={"New User"}
                        mode={"New"}
                        modalName={"NewUser"}
                        toggleRefresh={this.handleRefresh}
                    />
                )}
            </div>
        )
    }
}
export default withUserContext(UserRoleManagement);