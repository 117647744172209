export const recurringRoleConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "15%"
        },
        {
            headingLabel: "To Be Billed As",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Role",
            width: "15%"
        },
        {
            headingLabel: "Role Type",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Episode(s)",
            width: "30%"
        },
        {
            headingLabel: " ",
            width: "10%"
        }
      
    ],
    dataNodes: ["talent_name","billed_as","role","role_Type","episode_name","Select"],
    primaryKey: "id",
    buttonWithText:["Select"]

};

export const recurringRoleList = [
    {
        name:"John Smith",
        to_be_billed_as: "John Smith",
        role:"Johnson",
        role_type:"Recurring",
        episodes:"Ep. 105: Jazz Hands"
    },
    {
        name:"John Smith",
        to_be_billed_as: "John Smith",
        role:"Johnson",
        role_type:"Recurring",
        episodes:"Ep. 105: Jazz Hands"
    },
    {
        name:"John Smith",
        to_be_billed_as: "John Smith",
        role:"Johnson",
        role_type:"Recurring",
        episodes:"Ep. 105: Jazz Hands"
    },
    {
        name:"John Smith",
        to_be_billed_as: "John Smith",
        role:"Johnson",
        role_type:"Recurring",
        episodes:"Ep. 105: Jazz Hands"
    },
    {
        name:"John Smith",
        to_be_billed_as: "John Smith",
        role:"Johnson",
        role_type:"Recurring",
        episodes:"Ep. 105: Jazz Hands"
    },
    
]