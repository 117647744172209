import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import { withUserContext } from "../../../../contexts/UserContext";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import ReportButton from './ReportButton';
import {  genReportName } from '../../Helper';
class Residuals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract_end_date: null,
            contract_start_date: null,
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            postInitiated: false,
            contract_text: null,
            account_details: null,
        }
    }
    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated : false
        });
    }

    onClickGenratePdf() {
        this.setState({ postInitiated: true });
        let { selectSeason, selectProduction } = this.state; //selectEpisode, contract_text, account_details
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        //let episode_id = selectEpisode?.value;
        if (!production_id || !ps_id ) { //|| !episode_id
            return;
        }
        let payload = {
            // contract_text,
            // account_details,
            production_id,
            ps_id,
            //episode_id
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            // episode: selectEpisode?.label,
            // episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(`rptAcWorksheetReport,rptACResidualPackageDealMemo,rptACSagDaily`, payload, reportNameFinal,0, 0, 'getResidualPackage')
        this.setState({postInitiated: false})
        // this.setState({postInitiated: false})
        // let encryptTedStr = btoa(JSON.stringify(payload));
        // CasterService.getData(Constants.CasterServiceBaseUrl + `/getResidualPackage?reportName=&searchJson=${encryptTedStr}`, this.props?.userContext?.active_tenant?.tenant_id, true).then(response => {
        //     if (response.data.error) {
        //         this.props.fieldChanged(true, "reportNotFound");
        //         this.setState({reportNotFound: true})
        //     }
        //     else {
        //         let url = response.data;
                
        //         this.props?.downloadUrl(url, reportNameFinal)
        //     }
        //     this.setState({ isPosting: false })

        // }, (err) => {
        //     console.log(err)
        //     this.setState({ isPosting: false })
        //     this.props.fieldChanged(true, "reportFail");
        // })
    }
    handleDateValidation() {
        return (this.state?.contract_end_date && (new Date(this.state?.contract_end_date) < new Date(this.state?.contract_start_date))) || false
    }
    render() {
        return (
            <div>
                <MDBContainer className="reportContainer Worksheet InterviewList1">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={false}
                            episodeHide={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack} />
                    </MDBRow>
                    {/* <MDBRow className='dealDateRange'>
                        <MDBRow>
                            <BasicLabel text={"Deal Date Range"} />
                        </MDBRow>
                        <MDBCol className='dealDateCol' md={5}>
                            <DateField
                                id="selectStartDate"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_start_date || ""}
                                label={""}
                                onChange={(e) => this.onChangeCallBack('contract_start_date', new Date(e.target.value).toISOString())}
                            />

                        </MDBCol>

                        <MDBCol className='dealDateCol' md={1}>
                            <BasicLabel text={"to"} />
                        </MDBCol>

                        <MDBCol className='dealDateCol' md={5}>
                            <DateField
                                id="selectEndDate"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_end_date || ""}
                                label={""}
                                showErrorBorder={(this.state?.contract_end_date &&
                                    (new Date(this.state?.contract_end_date) < new Date(this.state?.contract_start_date))) || false}
                                onChange={(e) => this.onChangeCallBack('contract_end_date', new Date(e.target.value).toISOString())}
                            />
                        </MDBCol>
                    </MDBRow> */}
                    {/* <MDBRow>
                        <BasicTextField id="accountDetails"
                            value={this.state.account_details}
                            onChange={(e) => this.onChangeCallBack("account_details", e.target.value.length > 0 ? e.target.value : null)}
                            label="Account Details"
                        />
                    </MDBRow>
                    <MDBRow>
                        <BasicTextArea
                            id="contractText"
                            label={"Contract Text"}
                            rows={3}
                            value={this.state.contract_text || ""}
                            onChange={(e) => this.onChangeCallBack('contract_text', e.target.value.length > 0 ? e.target.value : null)} />
                    </MDBRow> */}
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        )
    }
}

export default withUserContext(Residuals)
