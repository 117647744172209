import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MDBIcon } from "mdbreact";
import './shared.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip'
import BasicButton from './BasicButton/BasicButton';
import TableFilters from './TableFilters';
import TableInlineEdits from './TableInlineEdits';
import { withUserContext } from "../../../contexts/UserContext";
import Tooltip from '@material-ui/core/Tooltip';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { style } from '@mui/system';


class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        var headings = this.props?.config?.headings;
        var colArr = {};
        var dataTypeArr = {};
        var selectOptionsArr = {};
        headings.forEach((heading, index) => {
            colArr[heading.headingLabel] = "";
            dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
            if (heading.inputType == "select")
                selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        });
        this.state = {
            inputValues: colArr,
            filterValues: [],
            listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
            editInputValues: [],
            dataTypeValues: dataTypeArr,
            selectOptionValues: selectOptionsArr
        }
    }

    componentDidMount() {
        //var headings = this.props?.config?.headings;
        // var colArr = {};
        // var dataTypeArr = {};
        // var selectOptionsArr = {};

        // headings.forEach((heading) => {
        //     colArr[heading.headingLabel] = "";        
        //     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
        //     if(heading.inputType == "select")
        //     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        // });  
        // this.setState({
        //     inputValues : colArr,
        //     filterValues: [],
        //     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
        //     dataTypeValues: dataTypeArr,
        //     selectOptionValues: selectOptionsArr
        // });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.list !== this.props.list) {
            this.setState({ listEdits: JSON.parse(JSON.stringify(this.props?.list || [])) });
        }
        if (prevProps?.updatedItemNodeValue !== this.props?.updatedItemNodeValue) {
            this.props?.updateTableInlineValues(this.state.listEdits)
        }
    }


    addFilterChangeHandler = (index, name, value) => {

        var inputValues = this.state.inputValues;

        var filterValues = this.state.filterValues;
        inputValues[name] = "";
        if (filterValues.length > 0) {
            let presentInd = filterValues.findIndex((item) => item.split(":")[0] === name);
            if (presentInd != -1) {
                filterValues[presentInd] = name + ": " + value;
            } else {
                filterValues.push(name + ": " + value);
            }
        }
        else {
            filterValues.push(name + ": " + value);
        }

        this.setState({
            inputValues: inputValues,
            filterValues: filterValues
        });

        var filtersNone = false;
        if (filterValues.length == 0)
            filtersNone = true;
        this.props.applyFilter(filterValues, filtersNone);

    }

    filterChangeHandler = (index, name, value, filter) => {

        var inputValues = this.state.inputValues;
        inputValues[name] = value;
        this.setState({
            inputValues: inputValues
        });
    }

    handleInlineEditChange = (value, index, nodeName) => {
        var editInputValues = [...this.state.listEdits];
        editInputValues[index][nodeName] = value;
        this.setState({ listEdits: editInputValues, filterValues: [] });
    }

    handleFilterDelete = (removeNode, index) => {
        var filterValues = this.state.filterValues;
        filterValues.splice(index, 1);
        this.setState({ filterValues: filterValues });
        var filtersNone = false;
        if (filterValues.length == 0)
            filtersNone = true;
        this.props.applyFilter(filterValues, filtersNone);

    }

    render() {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        return (
            <>
                <div>
                    <div className={this.props?.maxHeight ? "tableContainer " + "max-height-" + this.props?.maxHeight : "tableContainer"}>
                        <TableContainer component={Paper} className={this.props?.tableMode == "light" ? "bg-white overflow-x-unset" : "bg-light overflow-x-unset"}>
                            <Table id={this.props.className + "-table"} stickyHeader aria-label="collapsible table" size="small" style={{ tableLayout: "fixed" }} >
                                <colgroup>
                                    {this.props?.config?.headings?.map((heading, index) => (
                                        <col key={"colWidth_" + index} width={heading.width ? heading.width : (100 / this.props?.config?.headings?.length) + "%"} />))
                                    }
                                </colgroup>
                                {this.props?.config?.isSearchBar ? <>
                                    <TableHead class="searchHeaders">
                                        <TableRow key={this.props.className + "-searchHeaderRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index + "-" + heading.headingLabel} className="searchHeaderRows">
                                                        {index === 0 ? 'Search Results for :' + '"' + this.props.searchText + '"' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableHead class="searchHeadersSecond">
                                        <TableRow key={this.props.className + "-searchHeaderSecondRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index} className="searchHeadersSecondRows">
                                                        {index === 0 ? this.props.resultsCount + ' search results for ' + '[' + this.props.searchType + ']' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead></> : null}
                                <TableHead id={this.props.className + "-tablehead"} className={this.props?.tableMode == "light" ? "bg-light" : "bg-dark"}>

                                    {this.props?.config?.filters ?
                                        <TableRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
                                            {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                                            <div style={{ display: "flex" }}>
                                                {
                                                    this.state.filterValues && this.state.filterValues?.map((item, index) =>
                                                        <Chip
                                                            label={item}
                                                            onDelete={this.handleFilterDelete.bind(this, item, index)}
                                                        />
                                                    )
                                                }
                                            </div>
                                            {/* </TableCell> */}
                                        </TableRow> :
                                        <></>
                                    }

                                    <TableRow>
                                        {
                                            this.props?.config?.headings?.map((heading, index) => (
                                                <TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
                                                    className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} >
                                                    {this.props?.config?.filters ?
                                                        <><TableFilters inputType={heading.inputType}
                                                            filterValues={this.state.filterValues}
                                                            name={heading.headingLabel} inputValue={this.state.inputValues[heading.headingLabel]}
                                                            filterChangeHandler={this.filterChangeHandler.bind(this, index, heading.headingLabel)}
                                                            addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index, heading.headingLabel)} /> </> : <> </>}
                                                    <span className="action-icon" onClick={() => this.props?.sortCallback(heading.headingLabel)}>
                                                        <b> {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                            : heading.headingLabel} </b>
                                                    </span>

                                                </TableCell>
                                            ))
                                        }
                                        {this.props?.config?.actions &&
                                            <TableCell key={this.props.className + "-" + this.props?.config?.headings?.length}
                                                className={this.props?.tableMode == "light" ? "bg-light FW800" : "bg-dark FW800"} style={{ padding: "4px" }}  >
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.props?.isLoading ? (
                                        <div className="tableLoader">
                                            <CircularProgress />
                                        </div>
                                    ) : (

                                        <TableBody key={this.props.className + "body"}>
                                            {this.props?.list?.length > 0 && this.props?.list?.map((dataItem, rowIndex) => (
                                                <TableRow id={this.props.className + dataItem[this.props.config.primaryKey]}
                                                    key={this.props.className + "-" + dataItem[this.props.config.primaryKey] + "-" + rowIndex}>
                                                    {this.props?.config?.dataNodes?.map((node, index) => (node == 'check' ?
                                                        <TableCell className='FW800'>
                                                            <input type='checkbox' checked={dataItem[node]}
                                                                onClick={(event, newValue) => {
                                                                    dataItem[node] = event.target.checked;
                                                                    this.props.arrayCheckUncheck(event.target.checked, dataItem);
                                                                }}></input>
                                                        </TableCell> :
                                                        <TableCell
                                                            key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node] + "-" + index}
                                                            className={(this.props?.config?.borders?.includes(node) ? "border-left-1-secondary " : "") +
                                                                (this.props?.config?.changeColourToRed?.includes(dataItem[node]) ? "textColorRed " : "") +
                                                                (this.props?.config?.changeColourToGreen?.includes(dataItem[node]) ? "textColorGreen " : "") +
                                                                //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                                                "border-ver-1-secondary padding-5 word-break"} >
                                                            {dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === node && e.isInputType) ?
                                                                <> </> :
                                                                node == "groups" ?
                                                                    dataItem[node]?.map(item => (<div>{<a href="javascript:void(0);" onClick={(e) => this.props.groupElementCallback(item?.value, node, dataItem)}>{item?.label === 'add a button' ? <BasicButton text="Add to Episode" /> : item?.label}</a>}</div>)) :
                                                                    node == "label" ?
                                                                        <div
                                                                            // className="labelNames" 
                                                                            className={`${(this.props?.highlightValue === dataItem[node]) ? 'highlight-value' : ''} labelNames`}
                                                                            onClick={(e) => this.props.editData(dataItem, node)}
                                                                        >
                                                                            {dataItem[node]}</div> :
                                                                        this.props?.config?.hyperlinks?.includes(node) ?
                                                                            <a href="javascript:void(0);" onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node)}>
                                                                                {dataItem[node]}</a> :
                                                                            this.props?.config?.toggleFields?.includes(node) ? (dataItem[node] != null ?
                                                                                (this.props?.config?.tooltips?.includes(node) && dataItem[node]) ?
                                                                                    <Tooltip clickable title={dataItem[node + "_text"] ? <div
                                                                                        dangerouslySetInnerHTML={{ __html: [dataItem[node + "_text"]] }}
                                                                                    /> : ""}>
                                                                                        <span>
                                                                                            <MDBIcon icon={dataItem[node] == true ? "check-circle" : "times"}
                                                                                                className={dataItem[node] == true ? "text-success" : "text-dark"} />
                                                                                        </span>
                                                                                    </Tooltip> : <MDBIcon icon={dataItem[node] == true ? "check-circle" : "times"}
                                                                                        className={dataItem[node] == true ? "text-success" : "text-dark"} /> : <> </>) :
                                                                                this.props?.config?.nodeFields?.includes(node) ?
                                                                                    <React.Fragment>
                                                                                        {dataItem.is_favorite === false ? (
                                                                                            <StarBorderIcon
                                                                                                variant="outlined"
                                                                                                id={dataItem[this.props.config.primaryKey]}
                                                                                                onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                className={dataItem.is_favorite === false ? '' : 'star-border'}
                                                                                            />
                                                                                        ) : (
                                                                                            <StarIcon
                                                                                                variant="outlined"
                                                                                                id={dataItem[this.props.config.primaryKey]}
                                                                                                onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                className={dataItem.is_favorite === false ? '' : 'star-border'}
                                                                                            />
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                    : this.props?.config?.actorFields?.some(
                                                                                        (configItem) => configItem.column === node
                                                                                    ) ? (
                                                                                        this.props?.config?.actorFields?.map(
                                                                                            (nodeItem) =>
                                                                                                nodeItem.column === node ? (
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <span><b>{dataItem[node]?.name}</b></span><br />
                                                                                                            <span>{dataItem[node].phone}</span><br />
                                                                                                            <span>{dataItem[node].email}</span><br />
                                                                                                            <span>{dataItem[node].address}</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )
                                                                                        )
                                                                                    ) : this.props?.config?.profileFields?.some(
                                                                                        (configItem) => configItem.column === node
                                                                                    ) ? (
                                                                                        this.props?.config?.profileFields?.map(
                                                                                            (nodeItem) =>
                                                                                                nodeItem.column === node ? (
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <span><b>{dataItem[node]?.name}</b></span><br />
                                                                                                            <img
                                                                                                                src={dataItem[node]?.image}
                                                                                                                alt=""
                                                                                                                width="50"
                                                                                                            /><br />
                                                                                                            <span>{dataItem[node].text}</span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )
                                                                                        )
                                                                                    ) : this.props?.config?.fileField?.includes(node) ?
                                                                                        <MDBIcon variant="outlined" icon={"file"}
                                                                                            id={dataItem[[this.props.config.primaryKey]]}
                                                                                            onClick={(e) => this.props?.fileFieldCallback(dataItem, node)}
                                                                                            className={this.props?.className} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                        /> : this.props?.config?.buttonWithText?.includes(node) ?
                                                                                            <BasicButton variant="contained" type="inline" text={node} onClick={(e) => this.props?.buttonWithFieldFun(dataItem, node)} /> :
                                                                                            this.props?.config?.buttonWithTextAndIcon?.includes(node) ?
                                                                                                <BasicButton
                                                                                                    variant="outline"
                                                                                                    type="inline"
                                                                                                    className={( node ==="Contract" && (dataItem.role_type_name === "Regular" || dataItem.contract_type_name === "Pending" ))? " disable_btn": "outlined-btn"}
                                                                                                    icon="file"
                                                                                                    disabled={( node ==="Contract" && (dataItem.role_type_name === "Regular"|| dataItem.contract_type_name === "Pending")) ? true : false}
                                                                                                    text={(this.props?.selectedDataItem?.primaryKeyId === dataItem[this.props.config.primaryKey] &&
                                                                                                        this.props?.selectedDataItem?.actionLabel === node &&
                                                                                                        this.props?.isGeneratingPdf) ?
                                                                                                        <CircularProgress color="inherit" size={20} /> :
                                                                                                        node
                                                                                                    }
                                                                                                    onClick={(e) =>{ node === "DealMemo"? this.props?.handleDealMemoListPdf(dataItem, node) : this.props?.handleExistingDealMemoPdf(dataItem, node)}}
                                                                                                /> :

                                                                                                this.props?.config?.fileAttachments?.includes(node) ?
                                                                                                    <BasicButton variant="outlined" icon="paperclip"
                                                                                                        type="inline" inputType="file" id={dataItem[[this.props.config.primaryKey]]} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                                        uploadFile={(file) => this.props?.handleFileSelect(file, dataItem)} />
                                                                                                    :
                                                                                                    this.state.selectOptionValues?.[node] ?
                                                                                                        <>{this.state.selectOptionValues?.[node].map((item) => {
                                                                                                            if (item.value == dataItem[node])
                                                                                                                return (<> {item.label} </>);
                                                                                                        })}</> :
                                                                                                        <> {(this.props?.config?.tooltips?.includes(node) && dataItem[node]) ? <Tooltip title={dataItem[node]}>
                                                                                                            <span>{dataItem[node]?.length > 40 ? dataItem[node]?.substring(0, 40) + "..." : dataItem[node]}</span>
                                                                                                        </Tooltip> : dataItem[node]}</>

                                                            }
                                                            {
                                                                this.props?.config?.dataNodeGroups?.[node] ? (
                                                                    <>
                                                                        {this.props?.config?.dataNodeGroups?.[node]?.map((item) => (
                                                                            dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ? <> </>
                                                                                : <div>{dataItem[item]}</div>))}
                                                                    </>) : <> </>
                                                            }

                                                            {dataItem["editing"] && this.props?.config?.inlineEdits?.some(e => e.dataNode === node) ?
                                                                <TableInlineEdits nodeName={node} inputType={this.state.dataTypeValues[node]}
                                                                    inputValue={this.state.listEdits?.[rowIndex]?.[node]} index={rowIndex}
                                                                    handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                    selectOptions={this.state.selectOptionValues?.[node]}
                                                                /> : <> </>}

                                                            {
                                                                this.props?.config?.dataNodeGroups?.[node] ? (
                                                                    <>
                                                                        {this.props?.config?.dataNodeGroups?.[node]?.map((item) =>
                                                                            dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ?
                                                                                (<TableInlineEdits nodeName={item} inputType={"text"}
                                                                                    inputValue={this.state.listEdits?.[rowIndex]?.[item]} index={rowIndex}
                                                                                    handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                />) : <> </>)}
                                                                    </>) : <> </>
                                                            }



                                                        </TableCell>
                                                    ))
                                                    }
                                                    {this.props?.config?.actions &&
                                                        <TableCell key={this.props.className + "action"} className={"border-ver-1-secondary"} > <div className="row">
                                                            {this.props?.config?.actions?.map((action, indexNo) => (

                                                                <div key={this.props.className + "-" + indexNo} className="col-md padding-3"
                                                                    disabled={this.props?.tabValue === 1 ? (!dataItem?.is_edit) : false}
                                                                    onClick={() => action === "pen" ?
                                                                        (dataItem["editing"] ? this.props?.saveCallback(this.state.listEdits, rowIndex, dataItem[this.props.config.primaryKey], dataItem) :
                                                                            this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem)
                                                                        ) : action === "copy" ?
                                                                            (
                                                                                this.props?.editCopyCallback(dataItem[this.props.config.primaryKey], dataItem)
                                                                            ) :
                                                                            (action === "plus" ? this.props?.handleAddCallback(dataItem[this.props.config.primaryKey]) :
                                                                                ((action === "trash-alt" || action === "times") ? this.props?.handleDelCallBack(dataItem[this.props.config.primaryKey], dataItem) : null))}>
                                                                    {action === "view" ? (<a href="#">{action}</a>) :
                                                                        action === 'file-contract' ?
                                                                            <BasicButton
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                className={(this.props?.config?.actionLabels[indexNo]==="Contract" && (dataItem.role_type_name === "Regular" || dataItem.contract_type_name === "Pending")) ?"action-btn-disabled":"action-btn"}
                                                                                size="small"
                                                                                icon={"file-contract"}
                                                                                disabled={(this.props?.config?.actionLabels[indexNo]==="Contract" && (dataItem.role_type_name === "Regular"|| dataItem.contract_type_name === "Pending")) ? true : false}
                                                                                text={(this.props?.selectedDataItem?.primaryKeyId === dataItem[this.props.config.primaryKey] &&
                                                                                    this.props?.selectedDataItem?.actionLabel === this.props?.config?.actionLabels[indexNo] &&
                                                                                    this.props?.isGeneratingPdf) ?
                                                                                    <CircularProgress color="inherit" size={20} /> :
                                                                                    this.props?.config?.actionLabels[indexNo]
                                                                                }
                                                                                onClick={() => this.props?.handleTableActions(this.props?.config?.actionLabels[indexNo], dataItem)}
                                                                            /> :
                                                                            ((action === "pen" && dataItem["editing"]
                                                                                ? <MDBIcon icon={"save"} color="primary" className="action-icon" />
                                                                                : (action === "trash-alt" ?
                                                                                    (userPermissions?.isRegular || userPermissions?.isReadOnly ?
                                                                                        null : <MDBIcon icon={action} className="action-icon" />) :
                                                                                    (userPermissions?.isReadOnly
                                                                                        ? null
                                                                                        : <MDBIcon icon={action} className="action-icon" />)
                                                                                )))}
                                                                </div>

                                                            ))
                                                            }
                                                        </div></TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                            {
                                                this.props?.list?.length <= 0 &&
                                                <TableRow >
                                                    <TableCell className="text-align-center" colSpan={this.props?.config?.headings?.length}>
                                                        {this.props?.noResultsText ?
                                                            <b>{this.props?.noResultsText}</b> :
                                                            <b>No results</b>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </>
        )
    }
}


export default withUserContext(TableComponent);