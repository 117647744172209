import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
import DateField from '../../components/SharedComponents/DateField/DateField';
import { talentInitialConfig, repInitialConfig, companyInitialConfig } from './Config';
import { withUserContext } from '../../../contexts/UserContext';
// import CasterService from '../../../services/service';
// import * as Constants from '../../../constants/constants';

class SearchFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRep: null,
            selectedShow: null,
            filterValues: props?.isTalent ?
                JSON.parse(JSON.stringify({ ...talentInitialConfig })) :
                props?.isRepresentative ?
                    JSON.parse(JSON.stringify({ ...repInitialConfig })) : JSON.parse(JSON.stringify({ ...companyInitialConfig })),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.clearFilter !== this.props?.clearFilter) {
            if (this.props?.isTalent) {
                this.setState({ 
                    filterValues: JSON.parse(JSON.stringify({ ...talentInitialConfig })), 
                    selectedRep: null,
                    selectedShow: null
                }, () => {
                    this.props?.handleSetClearFilter();
                })
            } else if (this.props?.isRepresentative) {
                this.setState({ filterValues: JSON.parse(JSON.stringify({ ...repInitialConfig })) }, () => {
                    this.props?.handleSetClearFilter();
                })
            } else {
                this.setState({ filterValues: JSON.parse(JSON.stringify({ ...companyInitialConfig })) }, () => {
                    this.props?.handleSetClearFilter();
                })
            }
        }
        if (prevProps?.deletedFilter !== this.props?.deletedFilter) {
            this.clearFieldValues();
        }
    }

    clearFieldValues = () => {
        let filterValues = { ...this.state.filterValues }
        if (this.props?.deletedFilter === 'recording_date_range_start' || this.props?.deletedFilter === 'recording_date_range_end') {
            filterValues['recording_date_range_start'] = null;
            filterValues['recording_date_range_end'] = null;
        } else if (this.props?.deletedFilter === 'rate_range_start' || this.props?.deletedFilter === 'rate_range_end') {
            filterValues['rate_range_start'] = null;
            filterValues['rate_range_end'] = null;
        } else if (this.props?.deletedFilter === 'representative_type' || this.props?.deletedFilter === 'company_type' ||
            this.props?.deletedFilter === 'gender' || this.props?.deletedFilter === 'ethnicity'
        ) {
            filterValues[this.props?.deletedFilter + '_id'] = null;
        } else {
            filterValues[this.props?.deletedFilter] = null;
        }
        this.setState({ filterValues: filterValues })
    }

    handleSearchFieldOnChange = (e, value, field, fieldName) => {
        let newValue = value;
        this.handleSelectedValue(`${field}_id`, newValue?.value || null, fieldName);
        // if (field === 'gender' || field === 'ethnicity') {
        //     this.handleSelectedValue(`${field}`, newValue?.text || null, fieldName)
        //     if (newValue?.value) {
        //         this.props?.handleFilterOnChange(`${field}`, fieldName, newValue?.text)
        //     }
        // } else {
            this.handleSelectedValue(`${field}_name`, newValue?.text || null, fieldName)
            if (newValue?.value) {
                if (field === 'show' || field === 'talent' || (this.props?.isCompany && field === 'representative')) {
                    this.props?.handleFilterOnChange(`${field}_id`, fieldName, newValue?.value)
                } else {
                    this.props?.handleFilterOnChange(`${field}_name`, fieldName, newValue?.text)
                }
            }
        // }
    }

    handleSelectedValue = (field, value, fieldName) => {
        this.setState((prevState) => ({
            filterValues: {
                ...prevState.filterValues,
                [field]: value,
            },
        }), () => {
            if (field === 'representative_id') {
                let selectedRep = this.props?.representativeOptions?.find(item => item?.value === this.state.filterValues?.representative_id);
                let agencyOptions = []
                let agency = {
                    value: selectedRep?.company_id,
                    text: selectedRep?.company_name
                }
                if (agency?.value) {
                    agencyOptions?.push(agency);
                }
                this.setState({ agencyOptions: agencyOptions })
            } else if (field === 'show_id') {
                this.props?.getEntitySearch('SHOW_SEASON', 'seasonOptions', '', this.state.filterValues?.show_id)
            }
        });
    }

    handleOnChange = (field, value, fieldName) => {
        this.setState((prevState) => ({
            filterValues: {
                ...prevState.filterValues,
                [field]: value,
            },
        }), () => {
            if (field === 'talent_name' || field === 'show_season_id' || field === 'representative_name' || field === 'company_name') {
                this.props?.handleFilterOnChange(field, fieldName, value)
            }
            if (field === 'representative_type_id') {
                this.props?.handleFilterOnChange('representative_type', fieldName, value)
            }
            if (field === 'company_type_id') {
                this.props?.handleFilterOnChange('company_type', fieldName, value)
            }
            if (field === 'gender_id') {
                let genderName = this.props?.genderOptions?.find(item => item?.value === value)?.label;
                this.props?.handleFilterOnChange('gender', 'Gender', genderName)
            }
            if (field === 'ethnicity_id') {
                let ethnicity = this.props?.ethnicityOptions?.find(item => item?.value === value)?.label;
                this.props?.handleFilterOnChange('ethnicity', 'Ethnicity', ethnicity)
            }
            if (field === 'is_minor_id') {
                // let minor = this.props?.minorOptions?.find(item => item?.value === value)?.label;
                this.props?.handleFilterOnChange('is_minor', 'Is Minor', value)
            }
            if (fieldName === 'Recording Date Range') {
                if (!this.state.filterValues?.recording_date_range_start || !this.state.filterValues?.recording_date_range_end) {
                    this.props?.handleFilterValues(field, value)
                } else {
                    this.props?.handleFilterOnChange(field, fieldName, value)
                }
            }
            if (fieldName === 'Rate Range') {
                this.props?.handleFilterValues(field, value)
            }
        });
    }

    keyPress = (field, fieldName, e) => {
        if (e.keyCode == 13) {
            if (fieldName === 'Rate Range' && (!this.state.filterValues?.rate_range_start || !this.state.filterValues?.rate_range_end)) {
                this.props?.handleFilterValues(field, this.state.filterValues[field])
            } else {
                this.props?.handleFilterOnChange(field, fieldName, this.state.filterValues[field])
            }
        }
    }

    render() {
        return (
            <MDBContainer fluid className="searchFilterContainer">
                {this.props?.isTalent &&
                    <>
                        <MDBRow>
                            <MDBCol className="pr-0">
                                <BasicTextField
                                    label={"Name + AKAs"}
                                    placeholder="- Search -"
                                    value={this.state.filterValues?.talent_name}
                                    onChange={(e) =>
                                        this.handleOnChange("talent_name", e.target.value, 'Name + AKAs')
                                    }
                                // onKeyDown={(e) => this.keyPress('talent_name', 'Name + AKAs', e)}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="gender pr-0">
                                <SelectField
                                    id="gender"
                                    label={"Gender"}
                                    value={this.state.filterValues?.gender_id}
                                    options={this.props?.genderOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("gender_id", e.target.value, 'Gender');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="ethnicity pr-0">
                                <SelectField
                                    id="ethnicity"
                                    label={"Ethnicity"}
                                    value={this.state.filterValues?.ethnicity_id}
                                    options={this.props?.ethnicityOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("ethnicity_id", e.target.value, 'Ethnicity');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="is-minor pr-0">
                                <SelectField
                                    id="is-minor"
                                    label={"Is Minor"}
                                    value={this.state.filterValues?.is_minor_id}
                                    options={this.props?.minorOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("is_minor_id", e.target.value, 'Is Minor');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol>
                                <BasicTextField
                                    label={"Loanout Company"}
                                    value={this.state.filterValues?.loanout_company}
                                    onChange={(e) =>
                                        this.handleOnChange("loanout_company", e.target.value)
                                    }
                                    onKeyDown={(e) => this.keyPress('loanout_company', 'Loanout Company', e)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3} className="representative pr-0">
                                <SearchSelectField
                                    id="representative"
                                    label="Representative"
                                    placeholder="- Search -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.state.selectedRep || null}
                                    // detail_selected={this.state.filterValues?.representative_id}
                                    options={this.props?.representativeOptions || []}
                                    valueSelected={(e, newValue) => {
                                        this.setState({ selectedRep: newValue })
                                        this.handleSearchFieldOnChange(e, newValue, 'representative', 'Representative')
                                    }}
                                    searchText={(e) => this.props?.getEntitySearch('REPRESENTATIVE', 'representativeOptions', e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="agency">
                                <SearchSelectField
                                    id="agency"
                                    label="Agency (of Representative)"
                                    placeholder="- Search -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.state.filterValues?.company_id ? {
                                        value: this.state.filterValues?.company_id,
                                        text: this.state.filterValues?.company_name
                                    } : null}
                                    options={this.state.agencyOptions || []}
                                    valueSelected={(e, newValue) => this.handleSearchFieldOnChange(e, newValue, 'company', 'Agency')}
                                // searchText={(e) => this.getAgency(e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="show pr-0">
                                <SearchSelectField
                                    id="show"
                                    label="Show"
                                    placeholder="- Search -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.state.selectedShow || null}
                                    options={this.props?.showList || []}
                                    valueSelected={(e, newValue) => {
                                        this.setState({ selectedShow: newValue })
                                        this.handleSearchFieldOnChange(e, newValue, 'show', 'Show')
                                    }}
                                    searchText={(e) => this.props?.getEntitySearch('SHOW', 'showList', e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="season pr-0">
                                <SelectField
                                    label={"Season"}
                                    value={this.state.filterValues?.show_season_id}
                                    options={this.props?.seasonOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("show_season_id", e.target.value, 'Season');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol className="pr-0">
                                <BasicTextField
                                    label={"Character"}
                                    value={this.state.filterValues?.character}
                                    onChange={(e) =>
                                        this.handleOnChange("character", e.target.value)
                                    }
                                    onKeyDown={(e) => this.keyPress('character', 'Character', e)}
                                />
                            </MDBCol>
                            <MDBCol className="pr-0">
                                <BasicLabel text="Recording Date Range" />
                                <MDBRow className="recording-date-range">
                                    <MDBCol md={6} className="pr-0 start-date-range">
                                        <DateField
                                            id="date-range1"
                                            value={this.state.filterValues?.recording_date_range_start || ""}
                                            onChange={(e) =>
                                                this.handleOnChange("recording_date_range_start", e.target.value?.length > 0 ? e.target.value : null, 'Recording Date Range')
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pl-0 end-date-range">
                                        <DateField
                                            id="date-range2"
                                            value={this.state.filterValues?.recording_date_range_end || ""}
                                            onChange={(e) =>
                                                this.handleOnChange("recording_date_range_end", e.target.value?.length > 0 ? e.target.value : null, 'Recording Date Range')
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol>
                                <BasicLabel text="Rate Range" />
                                <MDBRow className="rate-range">
                                    <MDBCol className="pr-0 start-rate-range">
                                        <BasicTextField
                                            id="rate-range1"
                                            placeholder="$ min"
                                            value={this.state.filterValues?.rate_range_start || ""}
                                            onChange={(e) =>
                                                this.handleOnChange("rate_range_start", e.target.value, 'Rate Range')
                                            }
                                            onKeyDown={(e) => this.keyPress('rate_range_start', 'Rate Range', e)}
                                        />
                                    </MDBCol>
                                    <MDBCol className="pl-0 end-rate-range">
                                        <BasicTextField
                                            id="rate-range2"
                                            placeholder="$ max"
                                            value={this.state.filterValues?.rate_range_end || ""}
                                            onChange={(e) =>
                                                this.handleOnChange("rate_range_end", e.target.value, 'Rate Range')
                                            }
                                            onKeyDown={(e) => this.keyPress('rate_range_end', 'Rate Range', e)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                {this.props?.isRepresentative &&
                    <>
                        <MDBRow>
                            <MDBCol className="pr-0">
                                <BasicTextField
                                    label={"Name"}
                                    placeholder="- Search -"
                                    value={this.state.filterValues?.representative_name}
                                    onChange={(e) =>
                                        this.handleOnChange("representative_name", e.target.value, 'Representative Name')
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={2} className="rep-type pr-0">
                                <SelectField
                                    label={"Type"}
                                    value={this.state.filterValues?.representative_type_id}
                                    options={this.props?.repTypeOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("representative_type_id", e.target.value, 'Representative Type');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="company pr-0">
                                <SearchSelectField
                                    id="company"
                                    label="Company"
                                    placeholder="- Search -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.state.filterValues?.company_id ? {
                                        value: this.state.filterValues?.company_id,
                                        text: this.state.filterValues?.company_name
                                    } : null}
                                    options={this.props?.companyOptions || []}
                                    valueSelected={(e, newValue) => this.handleSearchFieldOnChange(e, newValue, 'company', 'Company')}
                                    searchText={(e) => this.props?.getEntitySearch('COMPANY', 'companyOptions', e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="company-type pr-0">
                                <SelectField
                                    label={"Company Type"}
                                    value={this.state.filterValues?.company_type_id}
                                    options={this.props?.companyTypeOptions || []}
                                    onChange={(e) => {
                                        this.handleOnChange("company_type_id", e.target.value, 'Company Type');
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="covered-talent">
                                <SearchSelectField
                                    id="covered-talent"
                                    label="Covered Talent"
                                    placeholder="- Search -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    detail_selected={this.state.filterValues?.talent_id ? {
                                        value: this.state.filterValues?.talent_id,
                                        text: this.state.filterValues?.talent_name
                                    } : null}
                                    options={this.props?.coveredTalentOptions || []}
                                    valueSelected={(e, newValue) => this.handleSearchFieldOnChange(e, newValue, 'talent', 'Covered Talent')}
                                    searchText={(e) => this.props?.getEntitySearch('TALENT', 'coveredTalentOptions', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                {this.props?.isCompany &&
                    <MDBRow>
                        <MDBCol md={3} className="pr-0">
                            <BasicTextField
                                label={"Name"}
                                placeholder="- Search -"
                                value={this.state.filterValues?.company_name}
                                onChange={(e) =>
                                    this.handleOnChange("company_name", e.target.value, 'Company Name')
                                }
                            />
                        </MDBCol>
                        <MDBCol md={2} className="company-type pr-0">
                            <SelectField
                                label={"Company Type"}
                                value={this.state.filterValues?.company_type_id}
                                options={this.props?.companyTypeOptions || []}
                                onChange={(e) => {
                                    this.handleOnChange("company_type_id", e.target.value, 'Company Type');
                                }}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="representative pr-0">
                            <SearchSelectField
                                id="representative"
                                label="Representative"
                                placeholder="- Search -"
                                width="100%"
                                searchSelect={true}
                                multiple={false}
                                detail_selected={this.state.filterValues?.representative_id ? {
                                    value: this.state.filterValues?.representative_id,
                                    text: this.state.filterValues?.representative_name
                                } : null}
                                options={this.props?.representativeOptions || []}
                                valueSelected={(e, newValue) => this.handleSearchFieldOnChange(e, newValue, 'representative', 'Representative')}
                                searchText={(e) => this.props?.getEntitySearch('REPRESENTATIVE', 'representativeOptions', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="covered-talent">
                            <SearchSelectField
                                id="covered-talent"
                                label="Covered Talent"
                                placeholder="- Search -"
                                width="100%"
                                searchSelect={true}
                                multiple={false}
                                detail_selected={this.state.filterValues?.talent_id ? {
                                    value: this.state.filterValues?.talent_id,
                                    text: this.state.filterValues?.talent_name
                                } : null}
                                options={this.props?.coveredTalentOptions || []}
                                valueSelected={(e, newValue) => this.handleSearchFieldOnChange(e, newValue, 'talent', 'Covered Talent')}
                                searchText={(e) => this.props?.getEntitySearch('TALENT', 'coveredTalentOptions', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(SearchFilters);

