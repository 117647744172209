import React, { Component } from "react";
import { withUserContext } from "../../../contexts/UserContext";
import './UserRoleManagementContainer.scss';
import { projectInfo, projectConfigRecord, renderProjectRecord, projectAssignMentDetails, userConfigRecords } from "./Config"
import { CircularProgress } from "@material-ui/core";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdbreact";
import TableComponent from "../SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import axios from 'axios';
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";

class UserListDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProjectDetailsLoader: false,
            userList: [],
            userConfig: userConfigRecords,
            rowsPerPage: 10,
            page: 0,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            searchCancelToken: null,
            openUserModal: false,
            userId: null,
        };
    }
    componentDidMount() {
        this.getAllUsersData()
        if (this.props?.userRef) {
            this.props.userRef.current = {
                getAllUsersData: this.getAllUsersData.bind(this),
                getUserList: this.getUserList.bind(this)
            }
        }
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getAllUsersData();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getAllUsersData();
                });
                break;
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            userList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.tableData)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            userList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.tableData)
        });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                config: updateTableSortConfigObject(JSON.parse(JSON.stringify({ ...userConfigRecords })), this.state.isSortByAsc, this.state.sortBy, colName),
                sortBy: colName,
                userList: tableSortList("Name", this.getSortNode(colName), this.state.userList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "User Name": return "full_name";
            case "Role": return "role_name";
            case "Title": return "project_count";
            case "Login Email": return "login_email";
            case "Phone": return "phone";
            case "Status": return "status";
        }
    }

    handleUserEdit = (dataItem, node) => {
        console.log("check the user edit detail", dataItem, node)
        this.setState({ userId: dataItem?.user_id, openUserModal: true })
    }

    getAllUsersData = (searchString = null, clearFilter = false) => {
        if (!searchString) {
            this.setState({ isProjectDetailsLoader: true });
        }
        if (clearFilter) {
            this.setState({ full_name: null });
        }
        if (this.state.searchCancelToken != null) {
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        }
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });

        CasterService.getData(
            Constants.CasterServiceBaseUrl + "/casterFeatureGetUser",
            this.props?.userContext?.user_profile?.tenant_id,
            1
        )
            .then((res) => {
                let formattedList = res?.data;
                formattedList = formattedList.map(item => {
                    item.status = item.is_active === 1 ? "Active" : "Inactive";
                    return item;
                });
                formattedList = formattedList.sort((a, b) => {
                    return a.full_name.localeCompare(b.full_name);
                });
                if (searchString) {
                    formattedList = formattedList.filter(item =>
                        item.full_name.toLowerCase().includes(searchString.toLowerCase())
                    );
                }
                this.setState({
                    userList: handlePaginationList(0, this.state.rowsPerPage, formattedList),
                    setEditEpisodeProject: false,
                    isProjectEpisodeList: false,
                    isProjectDetailsLoader: false,
                    userCount: res?.data?.length,
                    sortCount: 0,
                    isSortByAsc: true,
                    tableData: formattedList
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    };


    getUserList = () => {
        let tableData = [...this.state.tableData]
        return tableData;
    }


    render() {
        return (
            <div className="ProjectAdmin">
                {this.state.isProjectDetailsLoader ? <div style={{ textAlign: "center" }}>
                    <CircularProgress size={34} />
                </div> :
                    <>
                        {this.props?.isUrm &&
                            <>
                                <MDBRow>
                                    <MDBCol md={3}>
                                        <BasicTextField
                                            id="full_name"
                                            placeholder={"Search by User Name"}
                                            value={this.state.full_name || ""}
                                            onChange={(e) => {
                                                this.setState({ full_name: e.target.value })
                                                this.getAllUsersData(e.target.value)
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="g-0" id={"project-table"}>
                                    <TableComponent
                                        tableType="striped"
                                        list={this.state.userList || []}
                                        config={this.state.userConfig}
                                        sortCallback={this.handleTableColumnClick}
                                        buttonWithFieldFun={(val, item) => this.handleUserEdit(val, item)}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <TablePagination
                                        component="div"
                                        count={this.state.userCount}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    />
                                </MDBRow>
                            </>}
                    </>}
                {this.state.openUserModal && (
                    <MainViewModalComponent
                        open={this.state.openUserModal}
                        handleClose={() => this.setState({ openUserModal: false })}
                        headerText={"User"}
                        mode={"Edit"}
                        modalName={"NewUser"}
                        userId={this.state.userId}
                        toggleRefresh={() => this.getAllUsersData(null, true)}
                    />
                )}
            </div>
        );
    }
}

export default withUserContext(UserListDetail);
