import React from 'react';
import './AddTalentModal.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import messages from "../../../Common/Messages.json";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { CircularProgress } from '@material-ui/core';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../../components/SharedComponents/SearchSelectField';


class AddTalentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addTalentData: {
                talent_id : null,
            },
            talentOptions: [],
            isSubmit: false,
            postInitiated: false,
            selectedTalent: null
        };
    }

    handleOnchange(field, value) {
        this.setState((prevState) => ({
          addTalentData: {
            ...prevState.addTalentData,
            [field + '_id']: value?.value || null,
            [field + '_name']: value?.text || null
          },
        }));
      }
      

    componentDidMount = () => {
        this.getTalentData("Talent"," ")
    }

    getTalentData = (entity,searchString) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetEntitySearch?entity=${entity}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.talent_id,
                    text: item.talent_name,
                }));
                this.setState({
                    talentOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getCompanyData method");
            });
    };

    canSubmit = () => {
        let talentData = { ...this.state.addTalentData }
        let canSubmit = false;
            canSubmit =(talentData?.talent_id)? true
                : false;
        
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true })
        this.props?.setPostFlag(true)
        let payload = { ...this.state.addTalentData };
        payload.talent_id = payload.talent_id;
        payload['representative_id'] = this.props?.repId;
        payload['company_id'] = this.props?.RepCompanyId;
        payload['is_delete'] = this.props?.is_delete
        this.props?.is_delete===1?payload['talent_rep_company_mtrx_id']=this.props?.selectedTalentRep: payload['talent_rep_company_mtrx_id'] = null;
        if (this.canSubmit()) {
            this.setState({ isPosting: true })
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostTalentRep`,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    // this.props.fieldChanged(true, "success", "refresh-rep-talent"); 
                    this.setState({
                        isPosting: false,
                        postInitiated: false
                    })
                    // this.props?.setPostFlag(false)
                    this.props?.notificationComponent(true, "success", "refresh-rep-talent");
                    this.props?.onClose();
                },
                    (err) => {
                        console.log('Error is submitting Show' + err);
                        // this.props.fieldChanged(true, "fail");
                        // this.props?.setPostFlag(false)
                        this.setState({ isPosting: false, postInitiated: false })
                        this.props?.notificationComponent(true, "fail");
                        this.props?.onClose();
                    });
        } else {
            console.log("Error in submitting show")
        }
    };
  
    render() {
        return (
            <MDBContainer className="AddTalentContainer">
                <span className='header'>Select a talent to be represented by representative.</span>
                <MDBRow>
                    <BasicLabel text={"Select Talent"} />
                    <SearchSelectField
                        id="talent"
                        placeholder="- Search -"
                        width="100%"
                        searchSelect={true}
                        multiple={false}
                        detail_selected={this.state.selectedTalent || null}
                        options={this.state.talentOptions || []}
                        valueSelected={(e, newValue) =>  {
                            this.setState({ selectedTalent: newValue })
                            this.handleOnchange('talent', newValue)
                        }}
                        searchText={(e) => this.getTalentData('TALENT', e.target.value)}
                    />
                </MDBRow>
                <MDBRow className="p-2 margin">
                        <MDBCol md={6} ></MDBCol>
                        <MDBCol md={2} lg={2}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                text={
                                    this.state.isPosting ? (
                                        <CircularProgress color="inherit" size={18} />
                                    ) : "Add"
                                }
                                icon={"Add"}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                        <MDBCol md={1} ></MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={() => {
                                    this.props?.onClose();
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
            </MDBContainer>
        );
    }
}
export default withUserContext(AddTalentModal);
