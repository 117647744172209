import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import DateField from '../../../components/SharedComponents/DateField/DateField';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import { s2ab, genReportName } from '../../Helper';
class Station12FC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            contract_start_date: null,
            contract_end_date: null,
            is_ssn: null,
        }
    }

    handleSubmitStation12Fc = (docFlag = 0, excelFlag = 0) => {
        this.setState({ 
            postInitiated: true 
        });
        let { contract_start_date, contract_end_date, is_ssn, selectProduction } = this.state;
        let production_id = selectProduction?.value;

        if (!production_id || !contract_start_date || !contract_end_date) {
            return;
        }
        let payload = {
            production_id,
            contract_start_date,
            contract_end_date,
            is_ssn
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal,docFlag, excelFlag)
        this.setState({postInitiated: false})
    }

    handleCheck = (e) => {
        this.setState({postInitiated : false})
        let isChecked = e.target.checked
        if (isChecked) {
            if (e.target.id === "is_ssn") {
                this.setState({ is_ssn: 1 })
            }
        } else {
            if (e.target.id === "is_ssn") {
                this.setState({ is_ssn: null })
            }
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated : false
        });
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <BasicLabel text={"Date Range"} isMandatory={true}/>
                        </MDBRow>
                        <MDBCol md={5}>
                            <DateField
                                id="selectStartDateFC"
                                isMandatory={this.state.postInitiated || false}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_start_date || ""}
                                label={""}
                                onChange={(e) => this.onChangeCallBack('contract_start_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <h6>to</h6>
                        </MDBCol>
                        <MDBCol md={5}>
                            <DateField
                                id="selectEndDateFC"
                                isMandatory={this.state.postInitiated || false}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.contract_end_date || ""}
                                label={""}
                                showErrorBorder={(this.state?.contract_end_date &&
                                    (new Date(this.state?.contract_end_date) < new Date(this.state?.contract_start_date))) || false}
                                onChange={(e) => this.onChangeCallBack('contract_end_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <BasicCheckbox
                            id="is_ssn"
                            label="SSN"
                            checked={this.state.is_ssn === 1}
                            align="start"
                            onChange={e => this.handleCheck(e)}
                        />
                    </MDBRow>
                    <ReportButton
                        generateDoc={true}
                        generateExcel={true}
                        onClickGenratePdf={this.handleSubmitStation12Fc}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default Station12FC;