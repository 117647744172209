import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import './ProjectModal.scss';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../Common/Messages.json';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import { CircularProgress } from '@material-ui/core';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { validateCharNumCommaDotWithSpace, validateNumbersOnly, validateCharNumRateOnly, validateSapGlCode } from '../../Common/Helper';
import './EpisodeModal.scss';
import MessageModal from '../../components/SharedComponents/MessageModal';

class EditEpisode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editEpisodeData: {
				gl_code: null,
				episode_no: null,
				episode_name: null,
				episode_id: null
			},
			isSubmit: false,
			showSuccessMessage: false,
			showFailMessage: false,
			isEpisodeLoader: false,
			showDeleteEpisode: false,
			isDeleteLoader: false,
			inputValue: '',
			errorMessage: '',
			showFailureMsg: false,
			showSuccessMsg: false,
			isErrorFlag: false,
			ishandle: false
		};
	}

	componentDidMount() {
		if (this.props?.mode === "Edit") {
			this.getEpisodeData(this.props?.selectedEpisodeId)
		}
	}

	getEpisodeData = (episodeId) => {
		this.setState({ isEpisodeLoader: true })
		CasterService.getData(
			Constants.CasterServiceBaseUrl +
			`/casterTvJwpEpisode?episodeId=${episodeId}`,
			this.props?.userContext?.user_profile?.tenant_id, 1
		)
			.then((res) => {
				let filteredList = res.data[0]
				this.setState({
					editEpisodeData: filteredList,
					isEpisodeLoader: false
				});
			})
			.catch((error) => {
				console.log(error, "error in season get method");
			});
	};

	handleOnchange = (field, value) => {
		this.setState((prevState) => ({
			editEpisodeData: {
				...prevState.editEpisodeData,
				[field]: value,
			},
		}),()=>{this.setState({isErrorFlag: false})});
	};

	validateBeforeSave = (data) => {
		const episodelist = this.props.episodeList || [];
		let isDuplicate = null
		if(this.props?.mode === "Edit"){
			let isDuplicateList = episodelist.filter((object1 =>object1.episode_id!==data.episode_id))
			isDuplicate = isDuplicateList.some(object1 => object1.gl_code === data.gl_code)
			this.setState({isErrorFlag: isDuplicate})
		}else{
			 isDuplicate = episodelist.some(object1 => object1.gl_code === data.gl_code)
			this.setState({isErrorFlag: isDuplicate})
		}
		const canSubmit = data.episode_no && data.episode_name && validateSapGlCode(data.gl_code) && !isDuplicate
		return canSubmit;
	};


	notificationComponent = (value, mode = 'fail') => {
		switch (mode) {
			case 'success':
				return this.setState({ showSuccessMessage: value });
			case 'fail':
				return this.setState({ showFailMessage: value });
		}
	};

	handleSubmit = () => {
		this.setState({ishandle: true})
		let payload = { ...this.state.editEpisodeData };
		payload["episode_id"] = this.props?.mode === "Edit"? this.props?.selectedEpisodeId : null
		payload["project_id"] = this.props?.match?.params?.prid
		payload["season_id"] = this.props.season_id
		if (this.validateBeforeSave(payload)) {
			this.setState({ isSubmit: true ,isErrorFlag: false});
			CasterService.postDataParams(
				Constants.CasterServiceBaseUrl + '/casterTvJwpEpisode',
				payload,
				this.props.userContext?.active_tenant?.tenant_id
			).then(
				(response) => {
					if (response.data.error) {
						this.notificationComponent(true, 'fail');
					} else {
						this.notificationComponent(true, 'success');
						this.props?.getProjectEpisodesList(this.props?.match?.params?.prid)
						// if (this.props?.mode === "New") {
						// 	this.props?.navigationCallback({"episode_id":response.data[0].episode_id}, "episode_name")
						// }
					}
					this.setState({ isSubmit: false });
				},
				(err) => {
					this.setState({ isSubmit: false });
					this.notificationComponent(true, 'fail');
				}
			);
		}


	};

	confirmDeleteChanges = () => {
		this.handleDeleteEpisode()
	}
	handleDeleteEpisode = () => {
		this.setState({ isDeleteLoader: true });
		let payload = { ...this.state.editEpisodeData ,is_delete:1};
		CasterService.postDataParams(
			Constants.CasterServiceBaseUrl + '/casterTvJwpEpisode',
			payload,
			this.props.userContext?.active_tenant?.tenant_id
		).then(
			(response) => {
				console.log(response.data[0],"res")
				if (response.data.error) {
					this.notificationComponent(true, 'fail');
				} else if(response.data[0].status==="FAILURE"){
					this.setState({showFailureMsg:true})
					this.notificationComponent(true, 'fail');	
				}else{
					this.setState({showSuccessMsg:true})
					this.notificationComponent(true, 'Success');
					this.props?.getProjectEpisodesList(this.props?.match?.params?.prid)
				}
			  this.setState({ isSubmit: false, isDeleteLoader: false, showDeleteEpisode: false});
			},
			(err) => {
			  this.setState({ isSubmit: false, isDeleteLoader: false, showDeleteEpisode: false});
			  this.notificationComponent(true, "fail");
			}
		  );
	  } ;

	render() {
		return (
			<div>
				{this.state.isEpisodeLoader ? (
					<div style={{ textAlign: "center" }}>
						<CircularProgress size={34} />
					</div>
				) :
					<MDBContainer className="EditEpisodeModalContainer">
						<MDBRow>
							<MDBCol md={3}>
								<BasicTextField
									className="text"
									label={'GL Code'}
									isMandatory={true}
									mandatoryWarning={messages.mandatoryWarning}
									value={this.state.editEpisodeData.gl_code}
									onChange={(e) => validateCharNumCommaDotWithSpace(e.target.value) ? this.handleOnchange('gl_code', e.target.value.length > 0 ? e.target.value : null) : null}
								/>
								{!validateSapGlCode(this.state.editEpisodeData.gl_code) && (
                      <span className={"subText-font"} style={{ color: "red" }}>Please enter the format as ex:(T12.34567) </span>
                    )}
					{(this.state.editEpisodeData.gl_code !==null && this.state.isErrorFlag) ?  <span className={"subText-font"} style={{ color: "red" }}>This GL Code already existed please add another one </span>: " "}
							</MDBCol>
							<MDBCol md={2}>
								<BasicTextField
									className="text"
									label={'No'}
									isMandatory={true}
									mandatoryWarning={messages.mandatoryWarning}
									value={this.state.editEpisodeData.episode_no}
									onChange={(e) => validateNumbersOnly(e.target.value) ? this.handleOnchange('episode_no', e.target.value.length > 0 ? e.target.value : null) : null}
								/>
							</MDBCol>
							<MDBCol md={4}>
								<BasicTextField
									className="text"
									label={'Episode Name'}
									isMandatory={true}
									mandatoryWarning={messages.mandatoryWarning}
									value={this.state.editEpisodeData.episode_name}
									onChange={(e) => validateCharNumRateOnly(e.target.value) ? this.handleOnchange('episode_name', e.target.value.length > 0 ? e.target.value : null) : null}
								/>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md={11} lg={11}></MDBCol>
							{this.props?.mode === "Edit" ?
								<MDBCol className='episode-display'>
									<div>
										<BasicButton
											className="btnwidth"
											variant="contained"
											text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> : this.props?.mode === "Edit" ? 'Save' : 'Create'}
											icon={' '}
											// disabled={!this.validateBeforeSave() || false}
											onClick={() => this.handleSubmit()}
										/>
									</div>
									<div className='m-r'></div>
									<div>
										<BasicButton
											type="inline"
											variant="outlined"
											text={'Cancel'}
											onClick={() => {
												this.props?.onClose();
											}}
										/>
									</div>
									<div className='m-r'></div>
									<div>
										<BasicButton
											type="inline"
											variant="outlined"
											className="red-outlined-button"
											text={this.state.isDeleteLoader ? (
												<CircularProgress color="inherit" size={18} />
											) : (
												"Delete"
											)}
											onClick={() => this.setState({ showDeleteEpisode: true })}
										/>

									</div>
								</MDBCol> :
								<MDBCol className='episode-display'>
									<div>
										<BasicButton
											className="btnwidth"
											variant="contained"
											text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> : this.props?.mode === "Edit" ? 'Save' : 'Create'}
											icon={' '}
											// disabled={this.props?.mode === "New" && !this.validateBeforeSave()}
											onClick={() => this.handleSubmit()}
										/>
									</div>
									<div className='m-r'></div>
									<div>
										<BasicButton
											type="inline"
											variant="outlined"
											text={'Cancel'}
											onClick={() => {
												this.props?.onClose();
											}}
										/>
									</div>
								</MDBCol>}

							{/* <MDBCol id="Button">
							<BasicButton text="Delete" />
						</MDBCol> */}
						</MDBRow>
					</MDBContainer>
				}
				{this.state.showDeleteEpisode && (
					<MessageModal
						title={"Confirm Deletion"}
						open={this.state.showDeleteEpisode}
						message={
							<div>
								<p>
									Are you sure you would like to delete this Episode? This action cannot be undone.
								Type "DELETE" in the field below and click Delete to confirm Deletion</p>
								<input
									type="text"
									value={this.state.inputValue}
									onChange={e => this.setState({ inputValue: e.target.value, errorMessage: '' })}
								/>
								{this.state.errorMessage && (
									<p style={{ color: 'red' }}>{this.state.errorMessage}</p>
								)}
							</div>
						}
						primaryButtonText={"Delete"}
						secondaryButtonText={"No"}
						onConfirm={() => {
							if (this.state.inputValue === 'DELETE') {
								this.confirmDeleteChanges();
							} else {
								this.setState({ errorMessage: 'Please enter DELETE in all caps' });
							}
						}}
						handleClose={(e) => this.setState({ showDeleteEpisode: false, inputValue: '', errorMessage: '' })}
					/>
				)}
				{this.state.showSuccessMsg && (
					<MessageModal
						title={"Success"}
						open={this.state.showSuccessMsg}
						message={
							"Episode deleted successful"
						}
						primaryButtonText={""}
						secondaryButtonText={"Cancel"}
						handleClose={(e) => this.setState({ showDeleteEpisode: false, showSuccessMsg:false },()=>{this.props?.onClose})}
					/>
				)}
				{this.state.showFailureMsg && (
					<MessageModal
						title={"Failure"}
						open={this.state.showFailureMsg}
						message={
							"Cannot delete this episode as it is already used in deal memo"
						}
						primaryButtonText={""}
						secondaryButtonText={"Cancel"}
						handleClose={(e) => this.setState({ showDeleteEpisode: false, showFailureMsg:false },()=>{this.props?.onClose})}
					/>
				)}
				

			</div>
		);
	}
}
export default withUserContext(EditEpisode);