import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CasterService from '../../../../services/service';
import axios from 'axios';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';

class Production extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productionDetails: [],
            selectProduction: null,
            searchCancelToken: null,
            loading: false,
        }
    }

    getProductionNames = (param) => {
        this.setState({ loading: true });
        // var cancelToken = axios.CancelToken.source();
        if (this.state.searchCancelToken != null) {
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");}
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        CasterService.getDataWithCancelWithTenant(Constants.CasterServiceBaseUrl + '/production' + '?searchString=' + param, cancelToken, this.props.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.production_id, label: item.name }));
                this.setState({
                    productionDetails: formattedList,loading: false,
                });
            },
                (err) => {
                    console.log("Error in fetching Role Details:", err)
                })
    }
    handleSearchSelect = (val) => {
        this.props?.onChangeCallBack('selectProduction', val);
        this.setState({ selectProduction: val })
    }

    render() {
        return (
            <div className="ProductionSeasonEpisode">
                <MDBRow>
                    <BasicLabel text={"Feature to report on"} isMandatory={true} />
                </MDBRow>
                <MDBRow className='searchSelectBorder'>
                    <Autocomplete
                        isMandatory={this.props?.productionReq || false}
                        showMandatory={this.props?.productionReq && this.props?.postInitiated || false}
                        options={this.state.productionDetails ? this.state.productionDetails : []}
                        getOptionLabel={(option) => (option.label)}
                        value={this.state.selectProduction ? this.state.selectProduction : null}
                        onChange={(e, v) => this.handleSearchSelect(v)}
                        style={{ width: '100%' }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder="-ShowName-"
                                margin="normal"
                                onChange={ev => {
                                    // dont fire API if the user delete or not entered anything
                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                        this.getProductionNames(ev.target.value)
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.state.loading ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </MDBRow>
                {this.props?.productionReq && this.props?.postInitiated && !this.state.selectProduction && <span className="errorText">Please complete mandatory field</span>}
            </div>
        )
    }
}
export default withUserContext(Production);