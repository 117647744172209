import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import TabComponent from "../../Common/DefaultModalLayout/Tab/TabComponent";
import {DemoParentTabs} from './DealMemoConfig';
import DealMemoDetails from "./DealMemoDetails";
import CastList from "./CastList";

class DealMemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          DemoTabList: [
            {
                label: "Deal Memos",
                value: 1
            },
            {
                label: "Cast List",
                value: 2
            }
        ],
          tabValue: 1,
          isNavigation: false,
        };
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };
    
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <DealMemoDetails selectedEpisodeId={this.props?.selectedEpisodeId}  season_id={this.props?.season_id} project_id={this.props?.project_id} union_id={this.props?.union_id} totalEpisodes={this.props.totalEpisodes}
                isSearchFlag={this.props.isSearchFlag} searchItem={this.props.searchItem}/>;
                break
            case 2:
                return <CastList selectedEpisodeId={this.props?.selectedEpisodeId} season_id={this.props?.season_id} project_id={this.props?.project_id} handleTabChangeFun={this.handleTabChange.bind(this)} />;
                break
        }
    };

    render() {
        return (
            <div>
                <div>
                <TabComponent 
                    tabList={this.state.DemoTabList}
                    value={this.state.tabValue}
                    onChange={(e, v) => this.handleTabChange(v)}
                    className="DealMemoTabs"
                />
                </div>
              {this.getTabComponent()}
            </div>
        )
    }
}
export default withUserContext(DealMemo);