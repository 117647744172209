import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import { withUserContext } from "../../../contexts/UserContext";
import DateField from '../SharedComponents/DateField/DateField';
import SelectField from '../SharedComponents/SelectField/SelectField';
//import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import ReportButton from './ReportButton';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { downloadUrl, extractFilenameFromUrl, formatDailyRate } from "../../Common/Helper";
import SearchSelectField from '../SharedComponents/SearchSelectField';
import './EPTimesheet.scss';
import MessageModal from '../SharedComponents/MessageModal';

class EPTimesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails: [],
            EPTimesheetModal: false,
            pdfRes: null
        }
    }

    onClickGenratePdf() {
        //if (this.props?.reportDetails.show_season_id && this.props?.reportDetails.episode_id && this.props?.reportDetails.performer_id && this.props?.reportDetails?.show_id){
            this.setState({ postInitiated: true });
            const selectedPerformer = this.props?.performerOption.filter(performer => performer.value === this.props?.reportDetails.performer_id);
            this.props.reportDetails.contract_id = selectedPerformer[0]?.contract_id;
            let searchPayload = {
                show_id: this.props?.reportDetails.show_id.value,
                show_season_id: this.props?.reportDetails.show_season_id,
                episode_id: this.props?.reportDetails.episode_id,
                contract_id: this.props?.reportDetails.contract_id,
                start_date: this.props?.reportDetails.start_date,
                //account_details: this.props?.reportDetails.account_details,
                //contract_text: this.props?.reportDetails.contract_text,
               // rate: this.props?.reportDetails?.rate
            };
            Object.keys(searchPayload).map((obj) => {
                if (searchPayload[obj] === undefined) {
                    searchPayload[obj] = null;
                }
            });
            let encryptTedStr = btoa(JSON.stringify(searchPayload));

            let load = {
                payload: "casterAnimationGenerateReport",
                reportName: "rptEPTimeSheetReport",
                fileName: "rptEPTimeSheetReport",
                searchJson: encryptTedStr,
                showHtml: 0,
                encryptionType: 1
            };
            CasterService.getDataWS(load).then(
                (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200 && response?.body?.message) {
                        this.setState({ EPTimesheetModal: true, pdfRes: response.body.message })
                        //console.log("EP if");
                    } else {
                        this.setState({ postInitiated: false })
                        //console.log("EP else")
                        const filename = extractFilenameFromUrl(response?.body);
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                    }
                })
            );
        }
    

    render() {
        const { talentVoucherShow } = this.props;
        return (
            <div>
                <MDBContainer className="reportContainer TalentVoucherContainer">
                    <span className="header" style={{ fontSize: "10px" }}>
                        The file will generate in your browser as PDF.
                    </span>
                    <MDBRow>
                    <MDBCol md={8}>
                            <SearchSelectField
                                id={"show"}
                                label={"Select Show"}
                                defaultMenuText={"-"}
                                options={this.props?.showOption}
                                valueSelected={(e, value) =>
                                    this.props.handleGeneralEdit("show_id", value)
                                }
                                searchSelect={true}
                                searchText={(e) => this.props?.getShow("SHOW", e.target.value)}
                                detail_selected={this.props?.reportDetails?.show_id}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"season"}
                                value={this.props?.reportDetails?.show_season_id}
                                label={"Select season"}
                                defaultMenuText={"-"}
                                options={this.props?.seasonOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("show_season_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"episode"}
                                value={this.props?.reportDetails?.episode_id}
                                label={"Select episode"}
                                defaultMenuText={"-"}
                                options={this.props?.episodeOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("episode_id", e.target.value)
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <SelectField
                                id={"performer"}
                                value={this.props?.reportDetails?.performer_id}
                                label={"Select Performer"}
                                defaultMenuText={"-"}
                                options={this.props?.performerOption}
                                onChange={(e) =>
                                    this.props.handleGeneralEdit("performer_id", e.target.value)
                                }
                                />
                        </MDBCol>
                      
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={4}>
                            <DateField
                                id="selectWorkDate"
                                value={this.props?.reportDetails.start_date || ""}
                                label={"Start Date"}
                                onChange={(e) => this.props.handleGeneralEdit('start_date', e.target.value ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    
                    <ReportButton
                        onClickGenratePdf={this.onClickGenratePdf.bind(this)}
                        handleClose={this.props?.handleClose}
                        postInitiated={this.state.postInitiated}
                    />
                </MDBContainer>
                {
                    //console.log(this.state.EPTimesheetModal),
                    this.state.EPTimesheetModal &&
                    //console.log(this.state.EPTimesheetModal),
                    ///console.log(this.state.reportDetails),
                    <MessageModal
                        title={"PDF Download Failed"}
                        open={this.state.EPTimesheetModal}
                        message={
                            this.state.pdfRes
                        }
                        primaryButtonText={""}
                        secondaryButtonText={"Cancel"}
                        handleClose={(e) => this.setState({ EPTimesheetModal: false })}
                    />
                }
            </div>

        )
    }
}

export default withUserContext(EPTimesheet)
