import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import TableComponent from "../../components/SharedComponents/Table";
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";
import TablePagination from "@material-ui/core/TablePagination";
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import "./UserRoleList.scss";
import { projectConfig, renderProjectList } from "./Config";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import ProjectAssignment from "./UsersList";
import { withUserContext } from "../../../contexts/UserContext";
import { CircularProgress } from "@material-ui/core";

class ProjectAssignmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
            renderList: [],
            projectsList: [],
            config: projectConfig,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            isNavigation: false,
            projectAssignmentList: [],
            projectDetails: {},
            project_assign_id: null,
            isProjectDetailsLoader: false,
            isUrm: false
        };
    }

    componentDidMount() {
        this.getDashboardProjectAssignment()
    }


    getDashboardProjectAssignment = (searchString = null) => {
        this.setState({ isProjectDetailsLoader: true })
        const myObject = {
            entity_search_text: searchString,
            page_count: this.state.rowsPerPage,
            page_no: this.state.page + 1,
        };
        const base64String = btoa(JSON.stringify(myObject));
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterTvJwpDashboardProjectAssignment?input=${base64String}`,
            this.props?.userContext?.user_profile?.tenant_id, 1
        )
            .then((res) => {
                console.log("res", res)
                let filteredList = res?.data?.records;
                filteredList?.sort(function (a, b) {
                    return (a.project_name > b.project_name) ?
                        1 : ((b.project_name > a.project_name) ? -1 : 0);
                });
                this.setState({
                    renderList: handlePaginationList(0, this.state.rowsPerPage, filteredList),
                    setEditEpisodeProject: false,
                    isProjectEpisodeList: false,
                    isProjectDetailsLoader: false,
                    projectCount: res?.data?.query_record_count
                });
            })
            .catch((error) => {
                console.log(error, "error in season get method");
            });
    };


    navigationCallback = (item, node) => {
        this.tabChange(true)
        this.setState({ project_assign_id: item.project_id })
    };

    tabChange = (value) => {
        this.setState({ isNavigation: value })
    }

    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getDashboardProjectAssignment();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getDashboardProjectAssignment();
                });
                break;
        }
    }

    handleProjectAccess = (projectDetails) => {
        this.tabChange(true)
        this.setState({ project_assign_id: projectDetails.project_id, project_last_updated: projectDetails?.last_updated })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                config: updateTableSortConfigObject(JSON.parse(JSON.stringify({ ...projectConfig })), this.state.isSortByAsc, this.state.sortBy, colName),
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Project Name": return "project_name";
            case "Type": return "project_type_name";
            case "#Users": return "user_count";
            case "Last Updated": return "last_updated";
        }
    }

    render() {
        return (
            <MDBContainer fluid className="projectAssignmentContainer">
                {this.state.isNavigation ?
                    <ProjectAssignment
                        project_id={this.state.project_assign_id}
                        projectLastUpdated={this.state.project_last_updated}
                        tabChangeFun={this.tabChange.bind(this)}
                        isUrm={this.state.isUrm}
                        module={this.props?.module}
                        toggleRefresh={this.getDashboardProjectAssignment}
                    /> :
                    <>
                        {/* {this.props?.module !== 'project_assignment_admin' ? */}
                            <MDBRow>
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="project_name"
                                        placeholder="Search by Project Name"
                                        value={this.state.project_name || ""}
                                        onChange={(e) => {
                                            this.setState({ project_name: e.target.value })
                                            this.getDashboardProjectAssignment(e.target.value)
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow> 
                        {/* } */}
                        <MDBRow className="g-0 projectAssignment-Table" id={"project-table"}>
                            <TableComponent
                                list={this.state.renderList || []}
                                config={this.state.config}
                                // hyperLinkNavigationCallback={(item, node) =>
                                //     this.navigationCallback(item, node)
                                // }
                                isLoading={this.state.isProjectDetailsLoader}
                                buttonWithFieldFun={this.handleProjectAccess.bind(this)}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>
                        <MDBRow>
                            <TablePagination
                                component="div"
                                count={this.state.projectCount}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                page={this.state.page}
                                onChangePage={(e, page) => {
                                    this.setJson('page', page)
                                }}
                                rowsPerPage={this.state.rowsPerPage}
                                onRowsPerPageChange={(e) => {
                                    this.setJson('pagePerRow', e.target.value)
                                }}
                            />
                        </MDBRow>
                    </>
                }
            </MDBContainer>
        );
    }
}

export default withUserContext(ProjectAssignmentTable);
