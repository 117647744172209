import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

import './Tabs.scss';

class TabComponent extends React.Component {
    render() {
        return (
            <Box id='TabComponent' sx={{ width: '100%' }} >
                <Badge badgeContent={this.props?.badgeContent ? this.props?.badgeContent : 0} color="primary" className='badge'>
                    <Tabs
                        value={this.props.value}
                        onChange={this.props.onChange}
                        textColor={"primary"}
                        className="root"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        {this.props?.tabList.map((tabItem, index) => (
                            <Tab key={index} value={tabItem.value} label={tabItem.label} />

                        ))}
                    </Tabs>
                </Badge>
            </Box>
        )
    }

}
export default TabComponent;
