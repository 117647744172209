import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import ProductionSeasonEpisode from './ProductionSeasonEpisode';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../../components/SharedComponents/SearchSelectField';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import InlineSelectField from '../../../components/SharedComponents/InlineSelectField/InlineSelectField';
import { validateNumbersOnly } from '../../Helper';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import { s2ab, genReportName } from '../../../Common/Helper';
import { withUserContext } from '../../../../contexts/UserContext';

const selectCompareOptions = [
    { label: "greater_than", value: 1 },
    { label: "less_than", value: 2 },
    { label: "greater_than_equal", value: 3 },
    { label: "less_than_equal", value: 4 },
    { label: "equals", value: 5 }
]

class DealMemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            selectSeason: "",
            selectEpisode: "",
            setEpisode: false,
            postInitiated: false,
            clicked: false,
            todayDate: "",
            compare_salary: "",
            select_salary: "",
            salary: null,
            compare_days_worked: "",
            select_days_worked: "",
            days_worked: null,
            performerOptions: [],
            selectedPerformerValues: [],
            aka_list: null,
        }
    }

    populateTalentAka = (selectedPerformerData) => {
        let akaList = [];

        this.state.performerOptions.map(performer => {
            selectedPerformerData.map(selectedItem => {
                if (selectedItem.value === performer.value && performer.aka) {
                    akaList.push(performer.aka);
                }
            })
        })

        let aka_list_string = akaList.join(', ');
        this.setState({ aka_list: aka_list_string });
    }

    onChangeCallBack = (field, val) => {
        this.setState({ postInitiated: false })
        if (field === 'select_salary') {
            this.setState({
                compare_salary: val.currentTarget.innerText,
                select_salary: val.target.value
            });
        } else if (field === 'select_days_worked') {
            this.setState({
                compare_days_worked: val.currentTarget.innerText,
                select_days_worked: val.target.value
            });
        } else if (field === 'selectedPerformerValues') {
            this.setState({
                [field]: val
            });
            this.populateTalentAka(val);
        } else if (field === 'selectProduction' || field === 'selectSeason' || field === 'selectEpisode') {
            this.setState({
                [field]: val,
                selectedPerformerValues: [],
                aka_list: null
            });
        } else {
            this.setState({
                [field]: val
            });
        }
    }

    getDateOf = () => {
        let today = new Date();
        let date = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        let hours = today.getHours();
        let ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12 || 12;
        hours = hours < 10 ? '0' + hours : hours;
        let mins = today.getMinutes();
        mins = mins < 10 ? '0' + mins : mins;
        let time = hours + ':' + mins + " " + ampm;
        let dateTime = date + " , " + time;
        this.setState({
            todayDate: dateTime,
            clicked: true,
            postInitiated: false
        })
    }

    getPerformerOptions = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + '/productionSeasonEpisodePerformers?productionId=' + this.state.selectProduction?.value + '&psId=' + (this.state.selectSeason?.value === undefined ? null : this.state.selectSeason?.value) + '&episodeId=' + (this.state.selectEpisode?.value === undefined ? null : this.state.selectEpisode?.value), this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let filteredList = response.data?.map(item => ({
                    value: item.pse_performer_id,
                    text: item.performer_name,
                    aka: item.aka
                }));
                let removeDuplicates = filteredList.filter((value, index, arr) =>
                    index === arr.findIndex((item) => (
                        item.value === value.value))
                )
                this.setState({
                    performerOptions: removeDuplicates,
                })
            })
    }


    handleSubmitDealMemo = () => {
        this.setState({ postInitiated: true });
        let { selectSeason, selectEpisode, selectProduction, salary, compare_salary, days_worked, compare_days_worked } = this.state;
        let production_id = selectProduction?.value;
        let ps_id = selectSeason?.value;
        let episode_id = selectEpisode?.value;
        let list = this.state.selectedPerformerValues?.map(item => item.value)
        let result = list.join();
        let performer_list = "(" + result + ")";
        let performerLabels = this.state.selectedPerformerValues?.map(item => item.text)
        let performer_label_list = performerLabels.join(" ");

        if (!production_id || !ps_id) {
            return;
        }
        let payload = {
            production_id,
            ps_id,
            episode_id,
            salary,
            compare_salary,
            days_worked,
            compare_days_worked,
            performer_list
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: selectSeason?.label,
            episode: selectEpisode?.label,
            performer: performer_label_list,
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer DealMemo">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <ProductionSeasonEpisode
                            report={"DealMemo"}
                            getTalentOptions={this.getPerformerOptions}
                            onChangeCallBack={this.onChangeCallBack}
                            productionReq={true}
                            seasonReq={true}
                            episodeReq={false}
                            postInitiated={this.state.postInitiated} />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={7}>
                            <BasicLabel text={"Salary"} />
                        </MDBCol>
                        <MDBCol md={5}>
                            <BasicLabel text={"Revised Date"} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='salaryDiv'>
                        <MDBCol md={7}>
                            <MDBRow className="InlineSelect">
                                <InlineSelectField
                                    className="Select"
                                    textFieldLength={6}
                                    selectFieldLength={6}
                                    options={selectCompareOptions ? selectCompareOptions : []}
                                    selectValue={this.state.select_salary}
                                    fieldValue={this.state.salary}
                                    onSelectChange={(e) => this.onChangeCallBack('select_salary', e)}
                                    onFieldChange={(e) => validateNumbersOnly(e.target.value) ? this.onChangeCallBack('salary', e.target.value.length > 0 ? e.target.value : null) : null}
                                />
                            </MDBRow>
                            <MDBRow>
                                <BasicLabel text={"Days Worked"} />
                            </MDBRow>
                            <MDBRow className="InlineSelect">
                                <InlineSelectField
                                    className="Select"
                                    textFieldLength={6}
                                    selectFieldLength={6}
                                    options={selectCompareOptions ? selectCompareOptions : []}
                                    selectValue={this.state.select_days_worked}
                                    fieldValue={this.state.days_worked}
                                    onSelectChange={(e) => this.onChangeCallBack('select_days_worked', e)}
                                    onFieldChange={(e) => validateNumbersOnly(e.target.value) ? this.onChangeCallBack('days_worked', e.target.value) : null} />
                            </MDBRow>

                        </MDBCol>
                        <MDBCol className="setNewTime" md={5}>
                            <BasicLabel type="dateColor" text={this.state.clicked ? this.state.todayDate : "MM/DD/YY , hh:mm AM/PM"} />
                            <BasicButton
                                variant="contained"
                                type="inline"
                                text={"Set New Time"}
                                onClick={this.getDateOf}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="searchSelect" id='talentsDropDown'>
                        <BasicLabel text={"Talent Name"} />
                        <SearchSelectField
                            id={"roles-select"}
                            placeholder={this.state.selectedPerformerValues.length === 0 ? "-All Performers-" : ""}
                            multiple={true}
                            value={this.state.selectedPerformerValues}
                            options={this.state.performerOptions}
                            width={300}
                            onChange={(e, value) => this.onChangeCallBack('selectedPerformerValues', value)}
                        />
                    </MDBRow>
                    <MDBRow>
                        <BasicLabel text={"AKAs"} />
                    </MDBRow>
                    <MDBRow>
                        <BasicLabel text={this.state?.aka_list || "Comma Seperated List of AKAs"} />
                    </MDBRow>
                    <ReportButton
                        onClickGenratePdf={this.handleSubmitDealMemo}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default withUserContext(DealMemo);