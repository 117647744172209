import React from "react";
import "./DealMemo.scss";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBRow, MDBCol, MDBIcon, MDBCardTitle } from "mdb-react-ui-kit";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { MDBAnimation, MDBCard, MDBCardBody, MDBCardText } from "mdbreact";
import TalentInfo from "./TalentInfo";
import RepresentativeInfo from "./RepresentativeInfo";
import DealPoints from "./DealPoints";
import GuardianInfo from "./GuardianInfo";
import {createDealMemoRecords, dealPoints} from './DealMemoConfig'
import { esES } from "@material-ui/data-grid";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import NotificationComponent from '../../../caster-tv-jwp/Common/NotificationComponent/NotificationComponent';
import { CircularProgress } from '@material-ui/core';
import MessageModal from '../../../caster-tv-jwp/components/SharedComponents/MessageModal';
import { ssnFormat,isWithinLast18Years,validateDate } from '../../Common/Helper';
import { uploadProfileParams } from '../../Common/DefaultModalLayout/ProfilePicture/Config';
import Popover from '@material-ui/core/Popover';
import {downloadUrl,extractFilenameFromUrl} from "../../Common/Helper";
import moment from 'moment';
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";

class CreateDealMemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      isNavigation: false,
      isMinor: false,
      isExpirirationDate: false,
      isDropAndPickUp: false,
      createDealMemoDetails: JSON.parse(
        JSON.stringify({ ...createDealMemoRecords })
      ),
      dealMemoDetails: {},
      showError: false,
      showSuccess: false,
      showFail: false,
      unsavedTitle: "Unsaved Changes",
      fieldChanged: false,
      failMessage: "Saving changes failed!",
      saveTitle: "Success",
      saveEditMessage: "The changes are saved successfully!",
      isSubmit: false,
      postInitiated: false,
      isEditDealMemo: false,
      mode: null,
      isFetchingDealMemoDetails: false,
      showCancelModal:false,
      isUnsaved: false,
      ssnErrorCheckFlag: false,
      deal_memo_id: null,
      imgMode: null,
      setEFCPopover: null,
      isPosting: false,
      isReplace: false,
      imageType : null,
      isProfileChanged: false,
      profileImg: null,
      representativeProfiles: [],
      isSecondContractor: false,
      isDeleteLoader: false,
      showDeleteDealmemoModal: false,
      isDealMemoPdf:false,
      isContractPdf: false,
      isPickupButton: false,
      dateFields:{
        "start_date": null,
        "firm_date" : null,
        "minor_dob" : null,
        "expiration_date": null
      },
      isContract:this.props.isContract,
      isRecurring:this.props.isRecurring,
      isRecurringValue: false,
      contractTypePopup: false,
      isRegularType: false,
      dealMemoListPdf:false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if(this.state.isContract){
      this.handlecontractFun()
    }
    if(this.state.isRecurring){
      this.getDealDetails(this.props.recurringValue)
    }
    this.checkMode()
    let dateData = {...this.state.dateFields}
    if(dateData.start_date === "0000-00-00"||dateData.start_date ===null){
       dateData.start_date = moment().format('YYYY-MM-DD')
    }
    // }if(dateData.firm_date === "0000-00-00"||dateData.firm_date ===null){
    //   dateData.firm_date = moment().format('YYYY-MM-DD')
    // }
    this.setState({dateFields: dateData})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.createDealMemoDetails !== this.state.createDealMemoDetails) {
        this.setState({ isUnsaved : true});
    }
    if (prevState.profileImg !== this.state.profileImg) {
      this.uploadProfile()
    }
   

  }

  checkMode(){
    if( this.props?.isExisitingDealMemoId){
      this.setState({mode: "view"},()=>{ this.getDealDetails(this.props?.deal_memo_id)})
    }else if(this.state.isEditDealMemo){
      this.setState({mode: "edit"},()=>{
      })
    }else if(!this.props?.isExisitingDealMemoId && ! this.state.isEditDealMemo){
      this.setState({mode: "new"})
    }
  }

  getDealDetails = (dealMemoId) => {
    this.setState({ isFetchingDealMemoDetails: true });
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpDealMemo?dealMemoId=${dealMemoId}`,
      this.props?.userContext?.user_profile?.tenant_id,
      1
    ).then(
      (response) => {
        let createDealMemoResponseData = {
          episode_id: response.data[0].episode_id,
          deal_memo_id: response.data[0].deal_memo_id,
          project_id: response.data[0].project_id,
          season_id: response.data[0].season_id,
          talentDetails: {
            talent_name: response.data[0].talent_name,
            first_name: response.data[0].first_name,
            full_name: response.data[0].full_name,
            last_name: response.data[0].last_name,
            role: response.data[0].role,
            role_id: response.data[0].role_id,
            role_type_id: this.state.isRecurringValue ? 2 : response.data[0].role_type_id,
            role_type_name: this.state.isRecurringValue ? "Recurring" : response.data[0].role_type_name,
            preferred_pronouns: response.data[0].preferred_pronouns,
            talent_email: response.data[0].talent_email,
            ssn: response.data[0].ssn,
            loanout: response.data[0].loanout,
            address: response.data[0].address,
            cell_no: response.data[0].cell_no,
            federal_id_no: response.data[0].federal_id_no,
            is_minor: response.data[0].is_minor,
            updated_at: response.data[0].updated_at,
            created_at: response.data[0].created_at,
            updated_by: response.data[0].updated_by,
            created_by: response.data[0].created_by,
            non_sag: response.data[0].non_sag
          },
          guardianDetails: {
            guardian_name: response.data[0].guardian_name,
            guardian_email: response.data[0].guardian_email,
            guardian_phone: response.data[0].guardian_phone,
            // minor_dob: getFormattedDate(response.data[0].minor_dob),
            // minor_dob: response.data[0].minor_dob,
            coogan_account: response.data[0].coogan_account,
            has_work_permit: response.data[0].has_work_permit === 1 ? "yes" : "no",
            // expiration_date:  getFormattedDate(response.data[0].expiration_date),
            // expiration_date:  response.data[0].expiration_date,
          },
          representativeDetails: {
            agency: response.data[0].agency,
            agent: response.data[0].agent,
            rep_office_number: response.data[0].rep_office_number,
            rep_email: response.data[0].rep_email,
            rep_cell_number: response.data[0].rep_cell_number,
            management_company: response.data[0].management_company,
            management_office_number: response.data[0].management_office_number,
            manager: response.data[0].manager,
            mc_email: response.data[0].mc_email,
            mc_cell_number: response.data[0].mc_cell_number,
            mc_office_number: response.data[0].mc_office_number,
          },
          dealPointDetails: {
            // start_date: getFormattedDate(response.data[0].start_date),
            // start_date: response.data[0].start_date,
            rate: response.data[0].rate,
            per: response.data[0].per,
            per_period_id: response.data[0].per_period_id,
            per_period: response.data[0].per_period,
            guarantee: response.data[0].guarantee,
            guarantee_episodes: response.data[0].guarantee_episodes,
            contract_type: response.data[0].contract_type,
            contract_type_name: response.data[0].contract_type_name,
            out_of_episodes: response.data[0].out_of_episodes,
            is_drop_pickup: response.data[0].is_drop_pickup,
            drop_pickup_count: response.data[0].drop_pickup_count,
            drop_pickup_total: response.data[0].drop_pickup_total,
            date_type: response.data[0].date_type,
            final_recal_date: response.data[0].final_recal_date,
            billing:response.data[0].role_type_id === 1 ? (response.data[0].billing? response.data[0].billing : (!response.data[0].contract_no && "Deal in Business Affairs" || null)) : response.data[0].billing,
            dressing: response.data[0].role_type_id === 1 ? (response.data[0].dressing?response.data[0].dressing: (!response.data[0].contract_no && "Deal in Business Affairs" || null)): response.data[0].dressing,
            travel_accommodations: response.data[0].travel_accommodations,
            to_be_billed_as: response.data[0].to_be_billed_as,
            additional_terms: response.data[0].additional_terms,
            work_permit: response.data[0].work_permit,
            // firm_date:  response.data[0].firm_date,
            contract_no: response.data[0].contract_no,
            is_on_about: response.data[0].is_on_about,
            drop_deal_memo_id: response.data[0].drop_deal_memo_id,
            is_10_percentage: response.data[0].is_10_percentage,
            role_type_id: this.state.isRecurringValue ? 2 : response.data[0].role_type_id,
            role_type_name: this.state.isRecurringValue ? "Recurring" : response.data[0].role_type_name,
            is_on_about_start_date: response.data[0].is_on_about_start_date,
            notes_for_production: response.data[0].notes_for_production,
            is_frim_check: response.data[0].firm_date ? 1 : 0,
            deal_id_OLD: response.data[0].deal_id_OLD,
            salary: response.data[0].salary
          },
        };
        let datedata={
          "start_date": response.data[0]?.start_date,
          "firm_date":  response.data[0].firm_date,
          "minor_dob": response.data[0].minor_dob,
          "expiration_date":  response.data[0].expiration_date,
        }
        this.setState({
          createDealMemoDetails: createDealMemoResponseData,
          dateFields: datedata,
          isFetchingDealMemoDetails: false,
          isSubmit: false,
          imgMode: "download"
        },()=>{this.uploadProfile(dealMemoId, 'Talent',dealMemoId);
        if(this.state.isContract){
          if(this.state.createDealMemoDetails?.talentDetails?.is_minor === 1){
            this.setState({isMinor: true})
          }else{
            this.setState({isMinor: false})
          }
          let data = {...this.state.createDealMemoDetails}
          this.setState({createDealMemoDetails: data})
        }
        if(this.state.createDealMemoDetails.talentDetails?.role_type_id === 1){
          this.setState({isRegularType: true})
        }else{
          this.setState({isRegularType: false})
        } if(this.state.isRecurringValue  || this.state.isContract || (this.state.isContract && this.state.isRegularType)){
          let data={...this.state.createDealMemoDetails}
          data.dealPointDetails = {...createDealMemoRecords?.dealPointDetails}
          let date = {...this.state.dateFields}
          date.start_date = null
          this.setState({ createDealMemoDetails: data,dateFields: date})
         } else{
          let data={...this.state.createDealMemoDetails}
           this.setState({ createDealMemoDetails: data})
         }if(this.state.isContract && this.state.createDealMemoDetails?.dealPointDetails?.drop_deal_memo_id === null ){
          let date = {...this.state.dateFields}
          date.start_date = date.firm_date
          date.firm_date = null
          this.setState({dateFields: date})
         } else{
          let data={...this.state.dateFields}
           this.setState({ dateFields: data})
         }
      });
      },
      (err) => {
        this.setState({ dealMemoDetails: {}, isFetchingDealMemoDetails: false });
        console.log("Error in fetching status Details:", err);
      }
    );
  };
  

  handleDateChange=(field,value)=>{
    this.setState({
      dateFields: {
        ...this.state.dateFields,
        [field]: value
      }
    });
  }

  handleEditDealMemo=()=>{
    this.setState({isEditDealMemo: true,mode: "edit"})
    this.getDealDetails(this.props?.deal_memo_id)
    if(this.state.createDealMemoDetails?.talentDetails?.is_minor === 1){
      this.setState({isMinor: true})
    }else{
      this.setState({isMinor: false})
    }
  }
 

  handleTalentChange=(talentData)=>{
      let allDetails = { ...talentData};
      let data = {};
      if (allDetails["is_minor"] === "yes") {
        this.setState({ isMinor: true });
        allDetails["is_minor"] = 1
        data = { ...allDetails};
      } else if (allDetails["is_minor"] === "no") {
        this.setState({ isMinor: false });
        allDetails["is_minor"] = 0
        data = { ...allDetails};
      }else {
        data = { ...allDetails };
      }
      data = { ...allDetails };
      let totalRecords = {...this.state.createDealMemoDetails}
      totalRecords.talentDetails = data
      this.setState({ createDealMemoDetails: totalRecords },()=>{
        if(this.state.createDealMemoDetails?.talentDetails.role_type_id === 1){
         this.setState({isRegularType: true });
        }else{
          this.setState({isRegularType: false})
        }
      });
  }

  handleGuardianChange=(guardianData)=>{
    let allDetails = { ...guardianData};
    let data = {};
    if (allDetails["has_work_permit"] === "yes") {
      this.setState({ isExpirirationDate: true });
      allDetails["has_work_permit"] === "yes"
      data = { ...allDetails};
    } else if (allDetails["has_work_permit"] === "no") {
      this.setState({ isExpirirationDate: false });
      allDetails["has_work_permit"] === "no"
      data = { ...allDetails };
    } else {
      data = { ...allDetails};
    }
    allDetails = { ...data };
    let totalRecords = {...this.state.createDealMemoDetails}
    totalRecords.guardianDetails = allDetails
    this.setState({ createDealMemoDetails: totalRecords });
  }

  handleRepresentativeChange=(data)=>{
    let allDetails = { ...data};
    let totalRecords = {...this.state.createDealMemoDetails}
    totalRecords.representativeDetails = allDetails
    this.setState({ createDealMemoDetails: totalRecords });
  }

  handleDealPointChange = (dealPointData) => {
    let allDetails = { ...dealPointData};
    let data = {};
    // if (allDetails["is_drop_pickup"] === "no") {
    //   this.setState({ isDropAndPickUp: false });
    //   allDetails["is_drop_pickup"] === 0
    //   data = { ...allDetails};
    // } else if (allDetails["is_drop_pickup"] === "yes") {
    //   this.setState({ isDropAndPickUp: true });
    //   allDetails["is_drop_pickup"] === 1
    //   data = { ...allDetails };
     if(allDetails["is_on_about"]){
      allDetails["is_on_about"] =  allDetails["is_on_about"] ? 1 : 0
      data = { ...allDetails}
    }if(allDetails["is_on_about_start_date"]){
      allDetails["is_on_about_start_date"] = allDetails["is_on_about_start_date"] ? 1 : 0
      data = { ...allDetails}
    }if(allDetails["is_frim_check"]){
      allDetails["is_frim_check"] = allDetails["is_frim_check"] ? 1 : 0
      data = { ...allDetails}
    }
    else {
      data = { ...allDetails };
    }
    allDetails = { ...data };
    let totalRecords = {...this.state.createDealMemoDetails}
    totalRecords.dealPointDetails = allDetails
    this.setState({ createDealMemoDetails: totalRecords });
  };

  contractFun=()=>{
    this.setState({isPickupButton: true},()=>{
      // this.handleSubmit()
      // let data = {...this.state.createDealMemoDetails}
      // data.dealPointDetails.contract_no = 1
      // this.setState({createDealMemoDetails: data},()=>{
      //   this.props.dropAndPickupFun(this.state.createDealMemoDetails?.deal_memo_id)
      // })
      this.props.dropAndPickupFun(this.state.createDealMemoDetails?.deal_memo_id)
    })
  }

  confirmDeleteChanges=()=>{
      this.handleDeleteDealMemo()
  }

  handlecontractFun=()=>{
    this.getDealDetails(this.props?.dealMemoId)
  }
 

 

  confirmCancelChanges(){
    if(this.state.mode === "new"){
      if(this.state.isContract){
        this.setState({showCancelModal: false})
        this.props?.handleTabChangeFun(1)
      }else if(this.state.isRecurringValue){
        this.props?.handleTabChangeFun(1)
      }
      else{
        this.props?.handleTabChangeFun(1)
      }
    this.setState({showCancelModal: false})
    }else if(this.state.mode === "edit"){
      this.setState({mode: "view"},()=>{
        this.setState({showCancelModal: false})
      })
    }
  }

  handleCancel=()=>{
    if(this.state.mode === "new" || this.state.mode === "edit"){
      if(this.state.isUnsaved){        
        this.setState({showCancelModal: true})
      }else{
        this.props?.handleTabChangeFun(1);
        this.setState({showCancelModal: false})
      }
    }else{
      this.setState({mode:"view"})
    }
  }
  closeCancelModal=()=>{
    this.setState({showCancelModal: false})
    this.props?.handleTabChangeFun(1);
  }

  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success":
        return this.setState({ showSuccess: value });
      case "fail":
        return this.setState({ showFail: value });
    }
  };

  handleResetNotify = () => {
    this.setState({
      showFail: false,
      showSuccess: false,
      checkRelationalFailure: false,
    });
    if (this.state.saveFlag) {
      this.closPopup();
    }
  };

  validateFlagError = (data) => {
    let ssnErrorCheck = ssnFormat(data?.talentDetails?.ssn) && data?.talentDetails?.ssn?.length > 9 ? true : false
    this.setState({ssnErrorCheckFlag: ssnErrorCheck});
    if (
      data?.talentDetails.first_name &&
      data?.talentDetails?.last_name &&
      data?.talentDetails?.role &&
      data?.talentDetails?.role_type_id &&
      (ssnErrorCheck || !data?.talentDetails?.ssn) &&
      (this.state.isMinor ? (data.guardianDetails?.guardian_name && isWithinLast18Years(data?.minor_dob)) : true) &&
      ((this.state.isMinor && this.state.isExpirirationDate) ? data?.expiration_date : true) &&
      ((data.talentDetails.role_type_id === 1 || this.state.isContract) ? true :      
      (data?.dealPointDetails?.contract_no > 0 ? true : data?.start_date)) &&
      (data.talentDetails.role_type_id === 1 || data?.talentDetails?.non_sag === 1 ? true : data?.dealPointDetails?.rate) &&
      (data.talentDetails.role_type_id === 1 || data?.talentDetails?.non_sag === 1 ? true : data.dealPointDetails?.per) &&
      (data.talentDetails.role_type_id === 1 || data?.talentDetails?.non_sag === 1 ? true : data?.dealPointDetails?.contract_type) &&
      (data.talentDetails.role_type_id === 1 || data?.talentDetails?.non_sag === 1 ? true : data?.start_date ? data?.dealPointDetails?.date_type : true) &&
      (data.talentDetails.role_type_id === 1 || data?.talentDetails?.non_sag === 1 ? true : data?.dealPointDetails?.per_period_id) &&
      (data.talentDetails.role_type_id === 1 ? true : data?.dealPointDetails?.billing) &&
      (data.dealPointDetails?.guarantee_episodes ? data.dealPointDetails?.out_of_episodes ? true : false : true) &&
      ((this.state.isContract && this.state.createDealMemoDetails?.dealPointDetails?.drop_deal_memo_id === null || this.state.createDealMemoDetails?.dealPointDetails?.drop_deal_memo_id !== null) ? true : (this.state.createDealMemoDetails?.dealPointDetails?.is_drop_pickup ? true : true)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = () => {
    this.setState({ postInitiated: true ,isPosting: true});
    let payload = { ...this.state.createDealMemoDetails,...this.state.dateFields};
    if (this.validateFlagError(payload)) {
      this.setState({ isSubmit: true });
      let data = {};
      data = {
        deal_memo_id:payload.deal_memo_id ? payload.deal_memo_id : null,
        episode_id: this.state.isRecurringValue ? this.props.selectedEpisodeId : payload.episode_id ? payload.episode_id : this.props.selectedEpisodeId,
        project_id: payload.project_id ? payload.project_id: this.props?.project_id,
        season_id: payload.season_id ? payload.season_id : this.props?.season_id,
        per_type: payload?.dealPointDetails?.per_period_id,
        per_period:payload?.dealPointDetails?.per_period,
        start_date: payload?.start_date ? payload?.start_date  : null,
        minor_dob: payload?.minor_dob ? payload?.minor_dob : null,
        expiration_date: payload.expiration_date ? payload.expiration_date : null,
        firm_date: payload.firm_date ? payload.firm_date : null, 
        ...payload?.representativeDetails,
        ...payload?.guardianDetails,
        ...payload?.dealPointDetails,
        role_type_name: payload.talentDetails?.role_type_name,
        role_type_id: payload.talentDetails?.role_type_id,
        role_id: payload.talentDetails?.role_id,
        is_minor:payload.talentDetails?.is_minor,
        non_sag: (payload?.talentDetails?.non_sag)? 1:0

      };
      data = {...data,...payload?.talentDetails}
      data.has_work_permit = data.has_work_permit === "yes" ? 1 : 0
      // data.is_on_about = data.is_on_about === "yes" ? 1 : 0
      data.deal_memo_id = this.state.isContract ? null : data?.deal_memo_id
      data.contract_no = this.state.isContract ? 1 :  ( payload?.dealPointDetails?.contract_no || null)
      data.dressing = data.dressing ? data.dressing : null
      // if(data.contract_no === 1){
      //   data.contract_no = 2
      // }
      if(this.state.isContract){
        data["drop_deal_memo_id"]=  this.state.isContract ? this.props?.dealMemoId : data?.deal_memo_id
      }
      if(this.state.isRecurring){
        data["drop_deal_memo_id"]=  null
        data.deal_memo_id = null
      }if(this.state.isRecurringValue){
        data.deal_memo_id = null
      }
    
      data.is_10_percentage = data.is_10_percentage ? 1 : 0
      data.guarantee_episodes = data.guarantee_episodes === "" ? null : data.guarantee_episodes
      data.guarantee = data.guarantee === "" ? null : data.guarantee
      CasterService.postDataParams(
        Constants.CasterServiceBaseUrl + "/casterTvJwpDealMemo",
        data,
        this.props.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.notificationComponent(true, "fail");
          } else {
            this.notificationComponent(true, "success");
              if(this.state.mode === "new"){
                this.notificationComponent(true, "success");
                this.props?.createDealFun(true,response.data[0].deal_memo_id)
              }
              else{
                 this.setState({ mode: "view",deal_memo_id: response.data[0].deal_memo_id},()=>{ this.notificationComponent(true, "success");});
              this.getDealDetails(response.data[0].deal_memo_id);
              }
              this.notificationComponent(true, "success");
          }
          this.uploadProfile(response.data[0].deal_memo_id);
          this.setState({ isSubmit: false, postInitiated: false ,isSecondContractor: false,isContract: false,isRecurringValue: false,isRecurring:false});
        },
        (err) => {
          this.setState({ isSubmit: false, postInitiated: false });
          this.notificationComponent(true, "fail");
        }
      );
    }else{
    console.log('validation failed');
    }
  };

  handleDeleteDealMemo = () => {
    this.setState({ isDeleteLoader: true ,isPosting: true});
    let dealMemoId = this.state.createDealMemoDetails?.deal_memo_id
    CasterService.deleteParam(
      Constants.CasterServiceBaseUrl +
        `/casterTvJwpDealMemo?dealMemoId=${dealMemoId}`,
      this.props?.userContext?.user_profile?.tenant_id,
      1
      ).then(
        (response) => {
          if (response.data.error) {
            this.notificationComponent(true, "fail");
          } else {
            this.notificationComponent(true, "success");
            this.props?.episodeCallBack()
          }
          this.setState({ isSubmit: false, postInitiated: false ,isSecondContractor: false,isDeleteLoader: false, showDeleteDealmemoModal: false});
        },
        (err) => {
          this.setState({ isSubmit: false, postInitiated: false });
          this.notificationComponent(true, "fail");
        }
      );
  } ;
  
  onError = (e) => {
    // this.props?.handleProfileImg(null)       
    e.target.src = defaultImg;
  }
  
  handleImgMode(mode) {
    this.setState({ imgMode: mode })
  }
  handleProfileImg(file, result) {
    this.setState({ backupImg: file})
    this.setState({ profileImg: file })
  }
  handleProfileChange(val) {
    this.setState({ isProfileChanged: val },()=>{
      this.uploadProfile()
    })
  }
  handleImageType(val) {
    let fileType = val.split("/").pop();
    this.setState({ imageType: fileType } )
  } 

  removeProfileImg = () => {
    this.setState({ imgMode: "" }, () => {
        let payload = this.generateFilePayload(this.props.deal_memo_id);
        let file = this.state.profileImg;
        CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterTvJwpDeleteFiles', payload, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.notificationComponent(true, "fail");
                console.log("Profile File Removal Failed:" + err);
                }
                else {
                    this.notificationComponent(true, "remove");
                    this.uploadProfile();
                }

            }, (err) => {
                this.notificationComponent(true, "fail");
                console.log("Pre Signed Url Failed:" + err);
            });
    })
  }
  
  uploadProfile = (talentId) => {
    talentId =talentId ? talentId : this.props.deal_memo_id
    let uploadPayload = this.generateFilePayload(talentId);
    let file = this.state.profileImg;
    let base64String = btoa(JSON.stringify(uploadPayload));
    if(this.state.isReplace){
      let val = "upload"
      this.setState({imgMode : val})
    }
    if(!(talentId===null || talentId===undefined)){
    CasterService.postData(Constants.CasterServiceBaseUrl + '/casterTvJwpPreSignedUrlUpload', base64String,   this.props?.userContext?.user_profile?.tenant_id, 1)
        .then(response => {
            if (this.state.imgMode === "upload") {
                CasterService.uploadFile(response.data.preSignedURL, file).then(record => {
                    console.log("File Upload Successful:" + record.data);
                    this.setState({ imgMode: "download", isProfileChanged: false });
  
                }, (err) => {
                    this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
                    console.log("File Upload Failed:" + err);
                });
            }
            else {
                CasterService.downloadFile(response.data.preSignedURL).then(response => {
                    console.log("File Download Successful:" + response.data);
                    // this.handleOpenEFCPopup(true)
                    this.convertBlobToImg(response.data);
  
                }, (err) => {
                    console.log("File Download Failed:" + err);
                    this.setState({ profileImg: null, imgMode: 'download' })
                });
            }
  
        }, (err) => {
            console.log("Pre Signed Url Failed:" + err);
        });
      }
  }

  convertBlobToImg = (blb, objectType, objectId) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result;
      this.setState({ profileImg: text, imgMode: "download" });
    });
    reader.readAsText(blb);
}
  
  generateFilePayload = (talentId) => {
    if(talentId===null || talentId===undefined){
      return;
    }else{
    let objectType = "Talent"
    let fileName =`${objectType.toLowerCase()}_${talentId}`
    let tenantName = this.props.userContext?.active_tenant?.tenant_name;
    let uploadPayload = {
        ...uploadProfileParams,
        signedURLType: this.state.imgMode,
        fileCategory: "profileimage",
        tenantName: tenantName,
        objectType: objectType,
        objectId: talentId,
        contentType: this.state.imageType,
        fileName,
    };
    return uploadPayload;
  }}
  handleDealMemoListPdf = ()=>{
    this.setState({dealMemoListPdf : true })
  }
  hideDealMemoListPdf = ()=>{
    this.setState({dealMemoListPdf : false})
  }

  // handleDealMemoPdf = (is_confidential = 1) => {
  //   this.setState({ isDealMemoPdf: true })
  //   let searchPayload = {
  //     deal_memo_id : this.props?.deal_memo_id ,
  //     is_confidential : is_confidential,
  //     role_type_name:this.state.createDealMemoDetails.talentDetails.role_type_name
  //   };

  //   Object.keys(searchPayload).map((obj) => {
  //     if (searchPayload[obj] === undefined) {
  //       searchPayload[obj] = null;
  //     }
  //   });
  //   let encryptTedStr = btoa(JSON.stringify(searchPayload));
  //   let load = {
  //     payload: "casterTvJwpGenerateReport",
  //     reportName: "rptDealMemo",
  //     fileName: "DealMemo",
  //     searchJson: encryptTedStr,
  //     showHtml: 0,
  //     encryptionType: 1,
  //     tenantId: this.props?.userContext?.active_tenant?.tenant_id,
  //     divisionId: this.props?.division_id,
  //   };
    
  //   CasterService.getDataWS(load).then(
  //     (webSocketConn) =>
  //     (webSocketConn.onmessage = (e) => {
  //       let response = JSON.parse(e.data);
  //       if (response?.statusCode == 200) {
  //         this.setState({ isDealMemoPdf: false })
  //         const filename = extractFilenameFromUrl(response?.body);
  //         let decodedFile = decodeURIComponent(filename)
  //         downloadUrl(response?.body, decodedFile);
  //       }
  //     })
  //   );

  // }
  
  handleContractPdf = () => {
    this.setState({ isContractPdf: true })
    let searchPayload = {
      deal_memo_id : this.props?.deal_memo_id 
    };
 
    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    let contactLoad = {
      payload: "casterTvJwpGenerateReport",
      reportName: "rptContract",
      // loanout : (this.state.createDealMemoDetails.talentDetails.loanout === null || this.state.createDealMemoDetails.talentDetails.loanout === "" ) ? 0 : 1,
      fileName: "contactPdf",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1,
      tenantId: this.props?.userContext?.active_tenant?.tenant_id,
      divisionId: this.props?.division_id,
    };
    if(this.state.createDealMemoDetails.dealPointDetails.contract_type_name && (this.state.createDealMemoDetails.dealPointDetails.contract_type_name.includes("Pending") || this.state.createDealMemoDetails.talentDetails.role_type_name.includes("Regular"))){
      this.setState({contractTypePopup:true,isContractPdf:false})
    }else{
    CasterService.getDataWS(contactLoad).then(
      (webSocketConn) =>
      (webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data);
        if (response?.statusCode == 200) {
          this.setState({ isContractPdf: false })
          const filename = extractFilenameFromUrl(response?.body);
          let decodedFile = decodeURIComponent(filename)
          downloadUrl(response?.body, decodedFile);
          if(this.state.createDealMemoDetails?.talentDetails?.contract_type_name !== "Regular" && this.state.createDealMemoDetails.talentDetails.loanout){
            let searchPayload = {
              deal_memo_id : this.props?.deal_memo_id,
              loanout: 1
            };
         
            Object.keys(searchPayload).map((obj) => {
              if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
              }
            });
            let encryptTedStr = btoa(JSON.stringify(searchPayload));
            let contactLoad = {
              payload: "casterTvJwpGenerateReport",
              reportName: "rptContract",
              loanout: 1,
              fileName: "contactPdf",
              searchJson: encryptTedStr,
              showHtml: 0,
              encryptionType: 1,
              tenantId: this.props?.userContext?.active_tenant?.tenant_id,
              divisionId: this.props?.division_id,
            };
            CasterService.getDataWS(contactLoad).then(
              (webSocketConn) =>
              (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                  this.setState({ isContractPdf: false })
                  const filename = extractFilenameFromUrl(response?.body);
                  let decodedFile = decodeURIComponent(filename)
                  downloadUrl(response?.body, decodedFile);
          
        
          }
        }))
      }else{
        return("No loanout")
      }
    }})
    );
    }
  }
  confirmationFun=(value)=>{
    this.setState({isRecurringValue: true},()=>{
      this.getDealDetails(value)
    })
  }

  render() {
    let severity = this.state.showFail
      ? "error"
      : this.state.showSuccess
      ? "success"
      : this.state.checkRelationalFailure
      ? "error"
      : "";
    let messageNotification = this.state.showSuccess
      ? "The changes are saved successfully!"
      : this.state.showFail
      ? "Saving changes failed!"
      : this.state.checkRelationalFailure
      ? "You can not delete this Record because it is being used"
      : "";
    return (
      <div className="CreateDealMemoContainer">
        <NotificationComponent
          open={
            this.state.showSuccess ||
            this.state.showFail ||
            this.state.checkRelationalFailure
          }
          message={messageNotification}
          severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)}
        />
        {this.state.mode === "view" ? 
        <MDBRow className="mt">
        <MDBCol md={10}>
          {/* <BasicLabel text={"Episodes"} /> */}
          <span className="episode-font"></span>
        </MDBCol>
        <MDBCol md={2} className="episode-btn">
          <BasicButton
            variant="contained"
            type="inline"
            icon={"pen"}
            text={
                this.state.isSubmit ? (
                <CircularProgress color="inherit" size={18} />
                  ) : (
                    "Edit Deal Memo"
                  )
                }
            onClick={() => this.handleEditDealMemo()}
          />
        </MDBCol>
      </MDBRow>: null
        }
        <MDBRow>
          <MDBCol md={7}></MDBCol>
          <MDBCol md={4} className={this.state.mode === "view" ? "btn-display  mtop-view":"btn-display"} style={{marginBottom:"15px"}}>
            {this.state.mode === "view" ? <>
            <div>
              <BasicButton
                className="mleft"
                variant="outlined"
                type="inline"
                icon={"file"}
                text={
                  this.state.isDealMemoPdf ? (
                  <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Deal Memo PDF"
                    )
                  }
                onClick={() => this.handleDealMemoListPdf()}
              />
            </div>
            <div className="mleft2">
              <BasicButton
                variant="outlined"
                type="inline"
                icon={"file"}
                disabled={(this.state.createDealMemoDetails?.talentDetails?.role_type_id === 1 || this.state.createDealMemoDetails?.dealPointDetails?.contract_type_name === "Pending")? true : false}
                text={
                  this.state.isContractPdf ? (
                  <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Contract PDF"
                    )
                  }
                onClick={() => this.handleContractPdf()}
              />
            </div>
            </>:
            <>
            {this.state.isFetchingDealMemoDetails ? " " :
            <>
              <div>
              <BasicButton
                className="mleft"
                variant="outlined"
                type="inline"
                icon={"times"}
                text="Cancel"
                onClick={() => this.handleCancel()}
              />
            </div>
            <div className="mleft22">
              <BasicButton
                variant="contained"
                type="inline"
                icon={"save"}
                text={
                  this.state.isSubmit ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    "Save Deal Memo"
                  )
                }
                // disabled={()=>!this.validateFlagError() || false}
                // onClick={()=>this.props?.handleTabChangeFun(2)}
                onClick={() => this.handleSubmit()}
              />
            </div>
            </>
          }
            </>
          }
          </MDBCol>
        </MDBRow>
        {/* <span className="error-text">{this.state.ssnErrorCheckFlag ? "(ex:123-12-1234)" : " "}</span> */}
        {this.state.isFetchingDealMemoDetails ?<CircularProgress color="inherit" size={30} /> : 
        <>
        <MDBRow>
          <TalentInfo
            talentDetails={this.state.createDealMemoDetails?.talentDetails}
            postInitiated={this.state.postInitiated}
            mode = {this.state.mode}
            ssnErrorCheckFlag={this.state.ssnErrorCheckFlag}
            deal_memo_id={this.state.deal_memo_id}
            imgMode={this.state.imgMode}
            isProfileChanged={this.state.isProfileChanged}
            profileImg={this.state.profileImg}
            uploadProfile={this.uploadProfile}
            handleImgMode={(mode) =>  this.setState({imgMode : mode})}
            handleProfileImg={(file) =>  this.setState({profileImg : file})}
            handleProfileChange={(val) => val ? this.setState({isProfileChanged:val}) : this.uploadProfile()}
            handleImageType={(val) => this.setState({imageType:val})}
            project_id = {this.state.createDealMemoDetails?.project_id ? this.state.createDealMemoDetails?.project_id : this.props?.project_id}
            confirmationFun={this.state.mode === "new" ? this.confirmationFun.bind(this) : this.props?.confirmationFun}
            isUpload={true}
            removeProfileImg={this.removeProfileImg}
            gettalentDetails={this.handleTalentChange.bind(this)}
          />
        </MDBRow>
        {(this.state.isMinor || this.state.createDealMemoDetails?.talentDetails?.is_minor === 1)? (
          <MDBRow>
            <GuardianInfo
              guardianDetails={this.state.createDealMemoDetails?.guardianDetails}
              isExpirirationDate={this.state.isExpirirationDate}
              postInitiated={this.state.postInitiated}
              mode = {this.state.mode}
              dateFields={this.state.dateFields}
              handleDateChange={this.handleDateChange}
              getguardianDetails={this.handleGuardianChange.bind(this)}
            />
          </MDBRow>
        ) : null}
        <MDBRow>
          <RepresentativeInfo
             userRef={this.userRef} 
            representativeDetails={this.state.createDealMemoDetails?.representativeDetails}
            postInitiated={this.state.postInitiated}
            mode = {this.state.mode}
            handleRepresentativeChange={this.handleRepresentativeChange.bind(this)}
          />
        </MDBRow>
        <MDBRow>
          <DealPoints
           userRef={this.userRef} 
            dealPointDetails={this.state.createDealMemoDetails?.dealPointDetails}
            talentDetails={this.state.createDealMemoDetails?.talentDetails}
            dateFields={this.state.dateFields}
            handleDateChange={this.handleDateChange}
            isDropAndPickUp={this.state.isDropAndPickUp}
            postInitiated={this.state.postInitiated}
            mode = {this.state.mode}
            union_id={this.props?.union_id}
            isSecondContractor={this.state.isSecondContractor}
            contractFun={this.contractFun}
            isContract={this.state.isContract}
            count={this.state.count}
            isPickupButton={this.state.isPickupButton}
            deal_memo_id={this.state.createDealMemoDetails?.deal_memo_id}
            totalEpisodes={this.props.totalEpisodes}
            handleDealPointChange={this.handleDealPointChange.bind(this)}
            isRecurringValue={this.state.isRecurringValue}
            isRegularType={this.state.isRegularType}
          />
        </MDBRow>
        </>
        }
        <MDBRow></MDBRow>
        <br />
        {this.state.mode !== "view" &&
        <div>
        <MDBRow >
          <MDBCol md={7} >
            <MDBRow>
              { this.props?.userContext.user_profile.user_role_name !== "Show User" &&
              <MDBCol md={12} className="delete-btn">
                {this.state.mode === "edit" &&
              <BasicButton
                className="mleft"
                variant="outlined"
                type="inline"
                icon={"trash"}
                text= {this.state.isDeleteLoader ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  "Delete Deal Memo"
                )}
                onClick={() => this.setState({showDeleteDealmemoModal: true})}
              />}
              </MDBCol>}
            </MDBRow>
          </MDBCol>
          <MDBCol md={4} className="btn-display">
            <div>
              <BasicButton
                className="mleft"
                variant="outlined"
                type="inline"
                icon={"times"}
                text="Cancel"
                onClick={() => this.handleCancel()}
              />
            </div>
            <div className="mleft22">
              <BasicButton
                variant="contained"
                type="inline"
                icon={"save"}
                text={
                  this.state.isSubmit ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    "Save Deal Memo"
                  )
                }
                // onClick={()=>this.props?.handleTabChangeFun(2)}
                onClick={() => this.handleSubmit()}
              />
            </div>
          </MDBCol>
        </MDBRow><br/>
        <MDBRow></MDBRow>
        </div> 
      }
       {this.state.showCancelModal &&
          <MessageModal
            title={"Unsaved Changes"}
            open={this.state.showCancelModal}
            message={"You have unsaved changes to this DealMemo.Do you want to navigate away without saving these changes?"}
            primaryButtonText={"Yes"}
            secondaryButtonText={"No"}
            onConfirm={() => this.confirmCancelChanges()}
            handleClose={(e) => this.setState({ showCancelModal: false })} 
            />
        }
         {this.state.showDeleteDealmemoModal &&
          <MessageModal
            title={"Confirm Deletion"}
            open={this.state.showDeleteDealmemoModal}
            message={"Are you sure you would like to delete this Deal Memo? This action cannot be undone"}
            primaryButtonText={this.state.isDeleteLoader ? (
              <CircularProgress color="inherit" size={18} />
            ) : (
              "Delete"
            )}
            secondaryButtonText={"No"}
            onConfirm={() => this.confirmDeleteChanges()}
            handleClose={(e) => this.setState({ showDeleteDealmemoModal: false })} 
            />
        }
         {this.state.contractTypePopup &&
        <MessageModal
        title={"Contract Type PDF Download Failed"}
        open={this.state.contractTypePopup}
        message={
          "Contract Type is an pending state not able to generate the PDF"
        }
        primaryButtonText={""}
        secondaryButtonText={"Cancel"}
        handleClose={(e) => this.setState({ contractTypePopup: false})}
      />
        }
        {this.state?.dealMemoListPdf && (
         <MainViewModalComponent
         open={this.state.dealMemoListPdf}
            handleClose={this.hideDealMemoListPdf}
            headerText={"Deal Memo PDF Options"}
            mode={" "}
            modalName={"Deal Memo PDF"}
            dealMemoId={this.props?.deal_memo_id}
            roleTypeName={this.state.createDealMemoDetails.talentDetails.role_type_name}
          />
        )}
      </div>
    );
  }
}
export default withUserContext(CreateDealMemo);