import React from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdbreact";
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import TableComponent from '../../components/SharedComponents/Table';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import InlineButtonField from '../../components/SharedComponents/InlineButtonField/InlineButtonField';
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json';
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import { withUserContext } from '../../../contexts/UserContext';
import { lookupType, lookupConfig } from './Config';
import './LookupValues.scss';

class LookupValues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: "",
            lookupType: [],
            tableData: [],
            showContent: false,
            inlineButtonField: "",
            setIndex: 0,
            sortCount: 0,
            sortBy: "Value",
            isSortByAsc: true,
            config: lookupConfig,
        }
    }

    componentDidMount() {
        this.setState({ lookupType: lookupType })
    }

    fetchDetails = (val) => {
        this.setState({ selectedType: val, isFetchingLookupValues: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + '/casterTvJwpLookupDetails?objectType=' + val, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, check: item.is_active === 1 ? true : false }));
                this.setState({ 
                    tableData: formattedList, 
                    showContent: true, 
                    renderList: formattedList, 
                    isFetchingLookupValues: false 
                });
            },
                (err) => {
                    console.log("Error in fetching Market Types:", err)
                    this.setState({ isFetchingLookupValues: false })
                })
    }

    editList = () => {
        if (this.state.setIndex === 0) {
            if (this.state.inlineButtonField && this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit) {
                let details = JSON.parse(JSON.stringify([...this.state.tableData]))
                details.push({ value: null, label: this.state.inlineButtonField, check: true })
                this.setState({ tableData: details, formEdited: true }, () => {
                    this.setState({ renderList: this.state.tableData, inlineButtonField: "", isFetchingLookupValues: true }, () => {
                        this.handleSubmit();
                    });

                })
            }
        } else {
            if (this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit) {
                let details = JSON.parse(JSON.stringify([...this.state.tableData]))
                details.map(item => {
                    if (item.value === this.state.setIndex) {
                        item.label = this.state.inlineButtonField;
                    }
                });
                this.setState({ tableData: details, formEdited: true, inlineButtonField: "", isFetchingLookupValues: true }, () => {
                    this.handleSubmit(true);
                });
            }
        }
    }

    handleSubmit = (setIndex = false) => {
        let lookupArray = this.state.tableData.map(item =>
            ({ name: item.label, id: item.value, is_active: item.check ? 1 : 0 })
        );
        let lookupJson = {
            "table_name": this.state.selectedType,
            "values": lookupArray
        };
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterTvJwpStaticData',
            lookupJson, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.fetchDetails(this.state.selectedType)
            //    if (setIndex) {
            //        this.setState({ setIndex: 0 })
            //    }
            },
                (err) => {
                    console.log("Error in fetching Work Details:", err)
                })
    }

    arrayCheckUncheck = (event, dataItem) => {
        let details = JSON.parse(JSON.stringify([...this.state.tableData]))
        details?.map(item => {
            if (item.value === dataItem.value) {
                item.check = dataItem.check;
            }
        });
        this.setState({ tableData: details, formEdited: true }, () => {
            this.handleSubmit();
        });

    }

    editData = (dataItem, node) => {
        console.log("check the data item on edit", dataItem)
        this.setState({ inlineButtonField: dataItem.label, setIndex: dataItem.value });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Value": return "label";
            case "Active": return "staticMode";
        }
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.tableData, this.state.isSortByAsc)

            });
        })
    }

    render() {
        return (
            <MDBContainer className="ManageLookupContainer">
                <MDBRow>
                    <div className="pt3 helper-text">
                        <span> Select a system field to update their value list.</span>
                    </div>
                </MDBRow>
                <MDBRow className="pt2">
                    <MDBCol md={12}>
                        <div>
                            <SelectField
                                label="Selected Field"
                                placeHolderText={"- Select -"}
                                value={this.state.selectedType}
                                options={this.state.lookupType}
                                onChange={(e) => { this.fetchDetails(e.target.value) }}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="pt3">
                    <MDBCol
                        className={`${this.state.renderList?.find(item => item.value === this.state.setIndex) ? 'highlightSelectedValue' : '' } lookup-table`}
                    >
                        <TableComponent
                            list={this.state.renderList || []}
                            config={lookupConfig}
                            isLoading={this.state.isFetchingLookupValues}
                            arrayCheckUncheck={this.arrayCheckUncheck}
                            editData={this.editData}
                            sortCallback={this.handleTableColumnClick}
                            noResultsText={"No Selection"}
                            // highlightValue={this.state.renderList?.find(item => item.value === this.state.setIndex)?.label}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    {this.state.showContent ?
                        <div>
                            <MDBRow>
                                <MDBCol md={12}>
                                    <div className="pt5">
                                        <MDBCol md={12} className="inline-block">
                                            <InlineButtonField
                                                id={"key20"}
                                                label={this.state.setIndex === 0 ? "Add Value" : "Edit Value"}
                                                value={this.state.inlineButtonField}
                                                placeholder={this.state.setIndex === 0 ? "Add Value" : "Edit Value"}
                                                onChange={(e) => this.setState({ inlineButtonField: e.target.value })}
                                                buttonOnClick={(e) => this.editList()}
                                                totalTextFieldCount={1}
                                                editIcon={this.state.setIndex === 0 ? "" : "save"}
                                                limit={sizeLimits.nameCharacterLimit}
                                                limitWarning={messages.exceed50CharacterWarning || ""}
                                            />
                                        </MDBCol>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        : null}
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(LookupValues);