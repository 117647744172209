import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import SelectField from './SelectField/SelectField';

class TableFilters extends React.Component {
  constructor(props) {
    super(props);
  }

  handleFilters = (e) => {
    this.props.filterChangeHandler(e.target.value);
    this.props.addFilterChangeHandler(e.currentTarget.outerText);
  }

  pickFilters(inputType, name) {

    switch (inputType) {
      case 'text':
      case 'textarea':
      case 'select':
        return <BasicTextField id={name}
          value={this.props.inputValue}
          onKeyUp={(event) => {
            if (event.keyCode === 13) {
              this.props.addFilterChangeHandler(event.target.value);
            }

          }}
          onChange={(e) => this.props.filterChangeHandler(e.target.value)}
        />;
        break;
      case 'checkbox':
        return <BasicCheckbox id={name}
          checked={this.props.filterValues.find(item => item.split(':')[0] == name)?.split(':')[1] == 1}
          onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1" : "0"))}
        />;
        break;
      case 'dropdown':
        return <SelectField
          id={name}
          className="FilterSelectField"
          value={this.props.inputValue}
          options={this.props?.options}
          placeHolderText={"-Select-"}
          isLandingPage={true}
          // onKeyUp={(event) => {
          //   if (event.keyCode === 13) {
          //     this.props.addFilterChangeHandler(event.target.value);
          //   }

          // }}
          onChange={this.handleFilters}
        />;
        break;
      default:
        return <div className="no-input"></div>;
    }

  }


  render() {
    return (
      <>
        {this.pickFilters(this.props.inputType, this.props.name)}
      </>)
  }
}

export default TableFilters;