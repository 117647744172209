import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import { downloadUrl, extractFilenameFromUrl } from "../../Common/Helper";
import CasterService from "../../../services/service";
import { CircularProgress } from "@material-ui/core";
import "./CastListModal.scss";

class DealMemoListModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_confidential:1,
            isDealMemoListModal:false,
        };
    }

    handleChange = (field,value) => {
        console.log(value);
      if(value==="Confidential_display_rates"){
        this.setState({is_confidential:1})
      }else if(value==="Non-Confidential"){
        this.setState({is_confidential:0})
      }else if(value === "Both-C-NC"){
        this.setState({is_confidential:2})
      }
    }

    handleSubmit = () => {
        this.setState({ isDealMemoListModal: true })
        let isConfidential = this.state.is_confidential;
      
        let generateReport = (confidentiality) => {
          let searchPayload = {
            is_confidential: confidentiality,
            role_type_name: this.props?.roleTypeName,
            deal_memo_id: this.props?.dealMemoId,
          };
      
          Object.keys(searchPayload).forEach((obj) => {
            if (searchPayload[obj] === undefined) {
              searchPayload[obj] = null;
            }
          });
      
          const encryptedStr = btoa(JSON.stringify(searchPayload));
      
          const load = {
            payload: "casterTvJwpGenerateReport",
            reportName: "rptDealMemo",
            fileName: "DealMemo",
            searchJson: encryptedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: this.props?.userContext?.active_tenant?.tenant_id,
            divisionId: this.props?.division_id,
          };
      
          CasterService.getDataWS(load).then((webSocketConn) => {
            webSocketConn.onmessage = (e) => {
              const response = JSON.parse(e.data);
              if (response?.statusCode === 200) {
                this.setState({ isDealMemoListModal: false });
                const filename = extractFilenameFromUrl(response?.body);
                const decodedFile = decodeURIComponent(filename);
                downloadUrl(response?.body, decodedFile);
              }
            };
          });
        };
      
        if (isConfidential === 2) {
          generateReport(0);
          generateReport(1);
        } else {
          generateReport(isConfidential);
        }
      };
      
    render() {
        return (
            <div className="SetRecurringRoleContainer">
                <div className="p-right">
                    <span className="font-header">Deal Memo Will be generated in your machine's browser as PDF.</span>
                    <MDBRow>
                        <MDBCol className="radio-font">
                            <FormControl>
                                <BasicLabel
                                    text={"Confidentiality"}
                                />
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className="radio-font"
                                    defaultValue={"Confidential_display_rates"}
                                    onChange={(e) => this.handleChange("Confidential_display_rates", e.target.value)}
                                >
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Confidential_display_rates"
                                        control={<Radio />}
                                        label="Confidential(display-rates)"
                                        onChange={(e) => this.handleChange("Confidential_display_rates", e.target.value)}
                                    />
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Non-Confidential"
                                        control={<Radio />}
                                        label="Non-Confidential"
                                        onChange={(e) => this.handleChange("Non-Confidential", e.target.value)}
                                    />
                                    <FormControlLabel
                                        className="LabelSize"
                                        value="Both-C-NC"
                                        control={<Radio />}
                                        label="Both"
                                        onChange={(e) => this.handleChange("Both-C-NC", e.target.value)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="p-2 margin">
                        <MDBCol md={6} lg={6}></MDBCol>
                        <MDBCol md={3} lg={3}>
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                text={this.state.isDealMemoListModal ? <CircularProgress color="inherit" size={18} /> : "Generate"}
                                icon={"Generate"}
                                onClick={this.handleSubmit}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicButton
                                type="inline"
                                variant="outlined"
                                text={"Cancel"}
                                onClick={() => {
                                    this.props?.onClose();
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        );
    }
}
export default withUserContext(DealMemoListModal);