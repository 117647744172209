import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import SelectField from '../SharedComponents/SelectField/SelectField';
import { MDBCol, MDBRow } from 'mdbreact';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';


class CastList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="CastListContainer">
             <MDBRow>
                <MDBCol md={7}>
                <SearchSelectField
                    id="project_id"
                    options={this.props?.projectList}
                    searchSelect={true}
                    multiple={false}
                    label={"Select Project"}
                    placeholder={"Select Project"}
                    detail_selected={this.props?.selectedprojectValue}
                    valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                    searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <BasicLabel  className={"optionFilterColor"} />
                <MDBCol md={10}>
                    <MDBRow style={{ marginTop: "-6px" }}>
                        <MDBCol md={5} >
                            <BasicCheckbox 
                                id={"is_performer_address"}
                                label={"Performer Address"}
                                checked={this.state.reportDetails?.is_performer_address === 1 ? true : false}
                                onChange={(e) => this.props?.handleOnChange("is_performer_address", e.target.checked)}
                           />
                        </MDBCol>
                        <MDBCol md={5}>
                            <BasicCheckbox 
                                id={"is_deal_info"}
                                label={"Deal Information"}
                                checked={this.state.reportDetails?.is_deal_info === 1 ? true : false}
                                onChange={(e) => this.props?.handleOnChange("is_deal_info", e.target.checked)}
                           />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
             </MDBRow>
             <MDBRow>
             <MDBCol md={5} className="longWidth">
                <SelectField 
                    label={"Sort By"}
                    value={this.state.reportDetails?.sort_by_clause}
                    options={this.props?.sortList}
                    onChange={(e) => this.props?.handleOnChange("sort_by_clause", e.target.value)}
                />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <span className='optionFilterColor'>Optional Filters</span>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Production Company"}
                        value={this.state.reportDetails?.production_company}
                        options={this.props.companyList}
                        onChange={(e) => this.props?.handleOnChange("production_company", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
             <MDBRow>
                <MDBCol md={5} className="longWidth">
                    <SelectField 
                        label={"Union"}
                        value={this.state.reportDetails?.union}
                        options={this.props.unionList}
                        onChange={(e) => this.props?.handleOnChange("union", e.target.value)}
                    />
                </MDBCol>
             </MDBRow>
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(CastList));