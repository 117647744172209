export const projectConfig = {
    headings: [
        {
            headingLabel: "Project Name",
            width: "20%"
        },
        {
            headingLabel: "Season",
            width: "20%"
        },
        {
            headingLabel: "Type",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "#Users",
            width: "20%"
        },
        {
            headingLabel: "Last Updated",
            width: "15%"
        },
        {
            headingLabel: " ",
            width: "15%"
        },
    ],
    dataNodes: ["project_name", "season","project_type_name", "user_count", "last_updated","Edit Access"],
    primaryKey: "project_id",
    buttonWithText:["Edit Access"],
    // hyperlinks:["project_name"],
};

export const renderProjectList =[
 {
    project_name : "project",
    season: "2000-2001",
    users:"123",
    last_updated:"last_updated",
 },
 {
    project_name : "project",
    season: "2000-2001",
    users:"123",
    last_updated:"last_updated",
 },
 {
    project_name : "project",
    season: "2000-2001",
    users:"123",
    last_updated:"last_updated",

 },
 {
    project_name : "project",
    season: "2000-2001",
    users:"123",
    last_updated:"last_updated",
 },
 {
    project_name : "project",
    season: "2000-2001",
    users:"123",
    last_updated:"type",

 },
 {
    project_name : "project",
    season: "2000-2001",
    outlet:"123",
    last_updated:"type",
 }
]

export const projectInfo = [
    {
        "ProjectType": "Limited",
        "Year":"Season 2, 2022-2023",
        "GLSAPCode": "T27.19458",
        "ProductionEntity":"Warner Brothers TV",
        "Union":"SAG-AFTRA 2018",
        "Outlet":"Amazon Prime Video"
    }
]

export const projectAssignMentDetails={
        "project_id": null,
        "user_list": [
            {
                "user_project_id":null,
                "user_id": null,
                "is_favorite": 0,
                "is_delete": 0			
            }
        ]
}

export const projectConfigRecord = {
    headings: [
        {
            headingLabel: "User Name",
            width: "20%"
        },
        {
            headingLabel: "Access Level",
            width: "20%",
            inputType: "select",
            selectOptions: []
        },
        {
            headingLabel: "Title",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "User Type",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Date Added",
            width: "20%"
        },
        {
            headingLabel: "",
            width: "5%"
        }
    ],
    dataNodes: ["user_name", "access_level_id", "title", "user_role_name", "date_added"],
    primaryKey: "user_id",
    actions: ["times"],
    hyperlinks: ["user_name"],
    inlineEdits: [
        {
            dataNode: "user_name",
            width: "18.9%"
        },
        {
            dataNode: "access_level_id",
            width: "18.9%",
            isInputType: true
        },
        {
            dataNode: "title",
            width: "18.9%"
        },
        {
            dataNode: "user_role_name",
            width: "18.9%"
        },
        {
            dataNode: "date_added",
            width: "18.9%"
        },
    ]
};

export const userConfigRecords = {
    headings: [
        {
            headingLabel: "User Name",
            width: "20%"
        },
        {
            headingLabel: "User Type",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "#Project",
            width: "15%"
        },
        {
            headingLabel: "Email",
            width: "15%"
        }, {
            headingLabel: "Phone",
            width: "10%"
        }, {
            headingLabel: "Status",
            width: "10%"
        },
        {
            headingLabel: " ",
            width: "10%"
        },
    ],
    dataNodes: ["full_name", "role_name", "project_count","login_email","phone","status","Edit User"],
    primaryKey: "user_id",
    buttonWithText:["Edit User"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};

export const renderProjectRecord =[
 {
    name : "project",
    user_type: "2000-2001",
    users:"123",
    date_added:"date_added",
 },
 {
    name : "project",
    user_type: "2000-2001",
    users:"123",
    date_added:"date_added",
 },
 {
    name : "project",
    user_type: "2000-2001",
    users:"123",
    date_added:"date_added",

 },
 {
    name : "project",
    user_type: "2000-2001",
    users:"123",
    date_added:"date_added",
 },
 {
    name : "project",
    user_type: "2000-2001",
    users:"123",
    date_added:"type",

 },
 {
    name : "project",
    user_type: "2000-2001",
    outlet:"123",
    date_added:"type",
 }
]

