import React from 'react';
import { CircularProgress } from '@material-ui/core';
import TalentPersonal from './TalentPersonal';
import TalentContacts from './TalentContacts';
import TalentRepresentative from './TalentRepresentative';
import TalentActingHistory from './TalentActingHistory';
import { withUserContext } from "../../../contexts/UserContext";
import { talentInitialDetails, talentAka } from './config';
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { ssnFormat, getFormattedDate } from '../../Common/Helper';
import sizeLimits from '../../Common/SizeLimits.json';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';

class TalentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            talentDetails: this.formatTalentDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails }))),
            postInitiated: false,
            akaIndex: 2,
            checkedMultiPrimary: {
                email_list: false,
                representation_list: false,
            },
            showError: false,
            ethnicityOptions: [],
            genderOptions: [],
            adressOptions: [],
            stateOptions: [],
            countryOptions: [],
            representativesDetails: [],
            inActiveRepresentatives: [],
            isFetchingTalentDetails: false
        }
    }

    componentDidMount() {
        if (this.props?.talentId) {
            this.getTalentDetails("id", this.props?.talentId, 1);
        } else {
            this.getEthnicityData("ETHNICITY")
            this.getGenderData("GENDER")
            this.getAdresssData("ADDRESS_TYPE")
            this.getCountryData("COUNTRY")
            this.getStateData("STATE")
        }
    }

    getTalentDetails = (searchKey, searchString) => {
        this.setState({ isFetchingTalentDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetTalent?searchKey=${searchKey}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("get talent data", response)
                let talentResponse = response.data[0];
                talentResponse.as_of = (talentResponse?.as_of === '0000-00-00' || talentResponse?.as_of === null) ? talentResponse?.as_of : getFormattedDate(talentResponse?.as_of, true)
                talentResponse.dob = (talentResponse?.dob === '0000-00-00' || talentResponse?.dob === null) ? talentResponse?.dob : getFormattedDate(talentResponse?.dob, true)
                talentResponse.dod = (talentResponse?.dod === '0000-00-00' || talentResponse?.dod === null) ? talentResponse?.dod : getFormattedDate(talentResponse?.dod, true)
                this.setState({
                    talentDetails: this.formatTalentDetailsResponse(JSON.parse(JSON.stringify({ ...talentResponse }))),
                    representativesDetails: talentResponse?.representatives || [],
                    isFetchingTalentDetails: false
                }, () => {
                    this.getEthnicityData("ETHNICITY")
                    this.getGenderData("GENDER")
                    this.getAdresssData("ADDRESS_TYPE")
                    this.getCountryData("COUNTRY")
                    this.getStateData("STATE")
                })
            },
                (err) => {
                    this.setState({ isFetchingTalentDetails: false })
                    console.log("Error in fetching talent details", err)
                })
    };

    getRepDetails = (repData) => {
        this.setState({ representativesDetails: repData })
    }

    checkIfExistMultiplePrimary = (isMultiple) => {
        this.setState({ multipleRepPrimary: isMultiple })
    }

    getAdresssData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    adressOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let talentDetails = { ...this.state.talentDetails }
                    talentDetails.address_type = this.state.adressOptions?.find(item => item.label === talentDetails.address_type)?.value;
                    this.setState({ talentDetails: talentDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getCountryData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    countryOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let talentDetails = { ...this.state.talentDetails }
                    talentDetails.country = this.state.countryOptions?.find(item => item.label === talentDetails.country)?.value;
                    talentDetails.citizen_of = this.state.countryOptions?.find(item => item.label === talentDetails?.citizen_of)?.value;
                    this.setState({ talentDetails: talentDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getStateData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    stateOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let talentDetails = { ...this.state.talentDetails }
                    if (talentDetails?.country === 'United States') {
                        talentDetails.state = this.state.stateOptions?.find(item => item.label === talentDetails?.state)?.value;
                    }
                    this.setState({ talentDetails: talentDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getEthnicityData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    ethnicityOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let talentDetails = { ...this.state.talentDetails }
                    talentDetails.ethinicity = this.state.ethnicityOptions?.find(item => item.label === talentDetails.ethinicity)?.value;
                    this.setState({ talentDetails: talentDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    getGenderData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                this.setState({
                    genderOptions: filteredList,
                    isSubmit: false
                }, () => {
                    let talentDetails = { ...this.state.talentDetails }
                    talentDetails.gender = this.state.genderOptions?.find(item => item.label === talentDetails.gender)?.value;
                    this.setState({ talentDetails: talentDetails })
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getShowconfigrationData method");
            });
    };

    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue || props.mode !== state.mode || (JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {
            return {
                tabValue: props.tabValue,
                mode: props.mode,
                navObj: props.navObj || {}
            }
        }
        return null
    }

    formatTalentDetailsResponse = (response) => {
        if (response?.akas?.length === 0) {
            response?.akas?.push(JSON.parse(JSON.stringify({ ...talentAka })));
        }
        if (this.props?.talentProfileDetails) {
            response.first_name = this.props?.talentProfileDetails?.first_name;
            response.middle_name = this.props?.talentProfileDetails?.middle_name || null;
            response.last_name = this.props?.talentProfileDetails?.last_name || null;
        }
        return response;
    }

    getTalentProfileDetails = (searchKey, searchString) => {
        this.setState({ isFetchingTalentDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetTalent?searchKey=${searchKey}&searchString=${searchString}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("get talent data", response)
                let talentResponse = response.data[0];
                talentResponse.as_of = (talentResponse?.as_of === '0000-00-00' || talentResponse?.as_of === null) ? talentResponse?.as_of : getFormattedDate(talentResponse?.as_of, true)
                talentResponse.dob = (talentResponse?.dob === '0000-00-00' || talentResponse?.dob === null) ? talentResponse?.dob : getFormattedDate(talentResponse?.dob, true)
                talentResponse.dod = (talentResponse?.dod === '0000-00-00' || talentResponse?.dod === null) ? talentResponse?.dod : getFormattedDate(talentResponse?.dod, true)
                this.setState({
                    talentDetails: this.formatTalentDetailsResponse(JSON.parse(JSON.stringify({ ...talentResponse }))),
                    representativesDetails: talentResponse?.representatives || [],
                    isFetchingTalentDetails: false
                }, () => {
                    this.getEthnicityData("ETHNICITY")
                    this.getGenderData("GENDER")
                    this.getAdresssData("ADDRESS_TYPE")
                    this.getCountryData("COUNTRY")
                    this.getStateData("STATE")
                })
            },
                (err) => {
                    this.setState({ isFetchingTalentDetails: false })
                    console.log("Error in fetching talent details", err)
                })
    };

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        if (field === "company_id") {
            newValue = value === "" ? null : value
        }
        this.setState(prevState => ({
            talentDetails: {
                ...prevState.talentDetails,
                [field]: newValue || null
            },
            postInitiated: false
        }))
    }

    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let talentDetails = { ...this.state.talentDetails };
        let id = (listName === "email_list") ? "talent_email_id" :
            (listName === "akas") ? "talent_aka_id" :
                (listName === "representation_list") ? "talent_representative_agency_mtrx_id" : "";
        let list = talentDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                if (field === "is_primary") {
                    currentVal[field] = value ? 1 : 0;
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.setState(prevState => ({
            checkedMultiPrimary: {
                ...prevState.checkedMultiPrimary,
                [listName]: this.checkMultiplePrimary(list)
            }
        }))
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    addAdditionalField = (listName) => {
        let talentDetails = { ...this.state.talentDetails };
        switch (listName) {
            case 'akas':
                let initialAkaArray = { ...talentAka };
                let akas = [...talentDetails.akas];
                initialAkaArray.index = this.state.akaIndex;
                initialAkaArray.canRemove = true;
                akas.push(initialAkaArray)
                talentDetails.akas = akas;
                this.setState({ akaIndex: this.state.akaIndex + 1, talentDetails: talentDetails });
                break;
        }
    }

    removeField = (listName, callbackItem) => {
        let talentDetails = { ...this.state.talentDetails };
        if (!callbackItem.canRemove) {
            let index = talentDetails[listName].indexOf(callbackItem);
            if (index > -1) {
                talentDetails[listName].splice(index, 1);
            }
        } else {
            let list = talentDetails[listName]?.filter(item => item.index !== callbackItem.index);
            talentDetails[listName] = list;
        }
        this.setState({ talentDetails: talentDetails });
    }

    handleInActiveReps = (temp) => {
        this.setState({ inActiveRepresentatives: temp })
    }

    getTabComponent = () => {
        switch (this.props.tabValue) {
            case 0: return <TalentPersonal
                talentDetails={this.state.talentDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                addCallback={this.addAdditionalField}
                removeCallback={this.removeField}
                handleListEdit={this.handleListEdit}
                showError={this.state.showError}
                genderOptions={this.state.genderOptions}
                ethnicityOptions={this.state.ethnicityOptions}
                countryOptions={this.state.countryOptions}
            />
            case 1: return <TalentContacts
                talentDetails={this.state.talentDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                showError={this.state.showError}
                genderOptions={this.state.genderOptions}
                adressOptions={this.state.adressOptions}
                stateOptions={this.state.stateOptions}
                countryOptions={this.state.countryOptions}
            />
            case 2: return <TalentRepresentative
                representativesDetails={this.state.representativesDetails}
                postInitiated={this.state.postInitiated}
                handleGeneralEdit={this.handleGeneralEdit}
                handleSelectedData={this.handleSelectedData}
                getRepDetails={this.getRepDetails.bind(this)}
                inActiveRepresentatives={this.state.inActiveRepresentatives}
                handleInActiveReps={this.handleInActiveReps.bind(this)}
                handleCheckMultiplePrimaryRep={this.checkIfExistMultiplePrimary.bind(this)}
            />;
            case 3: return <TalentActingHistory
                talentId={this.props?.talentId}
                {...this.props}
            />
        }
    }

    checkIfMinor = (dateOfBirth) => {
        let birthday = new Date(dateOfBirth);

        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 18) {
            return true;
        } else {
            return false;
        }
    }

    validateErrorFlags = (talentDetails) => {
        let canSubmit = talentDetails?.first_name && talentDetails?.gender &&
            talentDetails?.ethinicity && talentDetails?.dob &&
            (this.checkIfMinor(talentDetails?.dob) ?
                (talentDetails?.primary_guardian_name !== null && talentDetails?.primary_guardian_email !== null) : true) &&
            (this.state.representativesDetails?.length > 0 ?
            this.state.representativesDetails?.filter(item => item.primaryError)?.length == 0 : true);
        return canSubmit;
    }

    handleSelectedData = (selectedData) => {
        console.log(selectedData, "selectedData");
    }

    getFormttedTalentDetails = (talentDetails) => {
        let represenatativeTemp = [];
        this.state.inActiveRepresentatives?.map(item => {
            represenatativeTemp?.push(item);
        });
        talentDetails.representatives?.map(item => {
            represenatativeTemp?.push(item);
        });
        talentDetails.representatives = represenatativeTemp;
        return talentDetails;
    }

    handleTalentSubmit = () => {
        this.setState({ postInitiated: true, showError: false })
        let talentDetailsPostJson = (JSON.parse(JSON.stringify({ ...this.state?.talentDetails })));
        talentDetailsPostJson.talent_id = this.props?.talentId || null;
        talentDetailsPostJson['akas'] = (talentDetailsPostJson?.akas?.length == 1 &&
            talentDetailsPostJson?.akas?.find(item => item.aka_id == null && item.aka == null)) ? [] : talentDetailsPostJson?.akas;
        talentDetailsPostJson['ethinicity'] = this.state.ethnicityOptions?.find(item =>
            item.value === talentDetailsPostJson.ethinicity)?.label;
        talentDetailsPostJson['gender'] = this.state.genderOptions?.find(item =>
            item.value === talentDetailsPostJson.gender)?.label;
        talentDetailsPostJson['address_type'] = this.state.adressOptions?.find(item =>
            item.value === talentDetailsPostJson.address_type)?.label;
        talentDetailsPostJson['country'] = this.state.countryOptions?.find(item =>
            item.value === talentDetailsPostJson.country)?.label;
        if (talentDetailsPostJson['country'] === 'United States') {
            talentDetailsPostJson['state'] = this.state.stateOptions?.find(item =>
                item.value === talentDetailsPostJson.state)?.label;
        }
        talentDetailsPostJson['citizen_of'] = this.state.countryOptions?.find(item =>
            item.value === talentDetailsPostJson.citizen_of)?.label;
        talentDetailsPostJson.representatives = [...this.state.representativesDetails];
        talentDetailsPostJson?.representatives?.map((item, index) => {
            if (item.is_delete == 1 && item.talent_rep_company_mtrx_id == null) {
                talentDetailsPostJson?.representatives?.splice(index, 1)
            }
        })
        talentDetailsPostJson = this.getFormttedTalentDetails(talentDetailsPostJson)
        if (this.validateErrorFlags(talentDetailsPostJson)) {
            this.props?.setPostFlag(true)
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/animationPostTalent', talentDetailsPostJson,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then((response) => {
                    if (response?.data?.error) {
                        this.setState({ postInitiated: false, showError: false })
                        this.props?.notificationComponent(true, "fail");
                        this.props?.setPostFlag(false)
                    }else if (response.data[0].message==="Talent Already Exists"){
                        this.setState({showNotification: true, notificationMessage: "Another talent with same SSN exists", severity: 'error'});
                        this.props?.setPostFlag(false);  
                    } else {
                        talentDetailsPostJson['talent_id'] = response.data[0].talent_id
                        // this.setState({
                        //     talentDetails: response.data[0],
                        //     talent_id: response.data[0].talent_id
                        // });
                        this.setState({ postInitiated: false, showError: false })
                        if (this.props?.handleNewTalentCreation) {
                            this.props?.handleNewTalentCreation(response.data[0]?.talent_id)
                        }
                        this.props?.notificationComponent(true, "success", "refresh-talent");
                        this.props?.setPostFlag(false)
                    }
                },
                    (err) => {
                        this.props?.setPostFlag(false);
                        this.setState({ postInitiated: false, showError: false })
                        this.props?.notificationComponent(true, "fail");
                        console.log("Post agency details error: " + err);
                    });
        }

    }

    handleResetNotify = () => {
        this.setState({
            showNotification: false
        });
    }

    render() {
        return (
            <div className={`${this.props?.isFromTalentRecord ? 'talentPadding' : ''} TalentContainer`}>
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleTalentSubmit()}></input>
                {this.state.isFetchingTalentDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <>
                            {this.getTabComponent()}
                            <NotificationComponent
                                open={this.state.showNotification}
                                message={this.state.notificationMessage}
                                severity={this.state.severity}
                                handleResetNotify={this.handleResetNotify.bind(this)}
                            />
                        </>
                    )
                }
            </div>
        );
    }

}
export default withUserContext(TalentContainer);