import React from 'react';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.scss';
import EditorToolbar, { formats } from "./EditorToolbar";
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
Quill.register(Quill.import("attributors/style/align"), true);
var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

class RichTextEditor extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    // modules.toolbar.container = `#toolbar${this.props?.id}`
    return (
      <div className="text-editor">
        <EditorToolbar id={this.props?.id} />
        <ReactQuill id={this.props?.id + "quill"}
          {...(this.props?.value ? { value: this.props?.value ? this.props?.value : '<div></div>' } : {})}
          {...(this.props?.defaultValue ? { defaultValue: this.props?.defaultValue } : {})}
          {...(this.props?.onChange ? { onChange: this.props?.onChange } : {})}
          theme="snow"
          formats={formats}
          modules={{
            toolbar: {
              container: `#toolbar${this.props?.id}`
            }
          }}
        />
      </div>
    )
  }

}
export default RichTextEditor;
