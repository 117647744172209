import React, { Component } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import TableComponent from "../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
    handlePaginationList,
    updateTableSortConfigObject,
    tableSortList,
} from "../../Common/TableHelper";
import "./LandingPage.scss";
import { animationShowConfig, animationShowList } from "./config";
import Popover from "@material-ui/core/Popover";
import CasterService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withRouter } from "react-router-dom";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBIcon } from "mdb-react-ui-kit";
import IconTextField from "../../components/SharedComponents/IconTextField/IconTextField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import MainViewModalComponent from "../../Common/MainViewModalLayout/Modal/Modal";
import { CircularProgress } from "@material-ui/core";
import { Link } from 'react-router-dom';
import BasicTextField from "../../components/SharedComponents/BasicTextField/BasicTextField";

class LandingPageTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 20,
            page: 0,
            renderList: [],
            showList: [...animationShowList],
            config: JSON.parse(JSON.stringify({ ...animationShowConfig })),
            isSortByAsc: true,
            sortCount: 0,
            setSeasonPopover: null,
            isFetchingTableData: false,
            seasons: [],
            setShowPopover: null,
            setNewShowModal: false,
            originalRenderList: [],
            openShowPopover: false,
            selectedOption: 'All',
            total_count: [],
            isLoading: false,
            optionsName: [],
            selectedShow: null,
            searchValue: null,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            last_accessed_show_name: null,
            last_accessed_show_id: null,
            show_season_id: null,
            statusOptions: []
        };
    }

    handleShowSeasonPopup = (obj, rowIndex, event) => {
        if (obj.show_configuration === 'Episodic') {
            // if (obj.seasons && !obj.seasons?.find(item => item.season_name == null && item.sap_code == null && item.start_year == null && item.end_year == null)) {
            let seasons = [];
            obj.seasons.forEach((season) => {
                let startYear = parseInt(season.start_year);
                let endYear = parseInt(season.end_year);
                let seasonName = season.season_name;
                let sapCode = season.sap_code;
                let formattedSeason = (sapCode == null && seasonName == null && season.start_year == null && season.end_year == null) ?
                    `Season ${season.show_season_id}` :
                    `${sapCode ? (sapCode + ': ') : ''}${seasonName || ''}${(startYear && endYear) ? (' (' + startYear + ' - ' + endYear + ')') : ''}`
                seasons.push(formattedSeason);
            });
            let show_season_id = [];
            if (obj.seasons) {
                obj.seasons.forEach((season) => {
                    const seasonName = season.show_season_id;
                    show_season_id.push(seasonName);
                });
            }
            this.setState({
                setSeasonPopover: event.currentTarget,
                seasons: seasons,
                show_season_id: show_season_id,
                selectedShowId: obj.show_id
            });
            // } else {
            //   this.setState({ setSeasonPopover: null })
            // }
        } else {
            this.props?.history?.push(`/caster-animation/show-season/${obj.show_id}`);
        }
    };

    handleCloseSeasonPopup = () => {
        this.setState({ setSeasonPopover: null });
    };

    componentDidMount = () => {
        this.getAnimationDashboardShows()
        this.getStatusData("SHOW_STATUS")
    };

    componentWillUnmount = () => {
        this.getAnimationDashboardShows()
        this.getStatusData("SHOW_STATUS")
    };

    getAnimationDashboardShows = (value = null) => {
        this.setState({ isFetchingTableData: true });

        const myObject = {
            show_name: value,
            status: this.state.selectedOption === 'All' ? null : this.state.selectedOption,
            sort_by: this.state.sortBy,
            order_by: this.state.isSortByAsc ? 'asc' : 'desc',
            page_count: this.state.rowsPerPage,
            page_no: this.state.page + 1,
            search_text: this.state.searchValue,
        };

        const base64String = btoa(JSON.stringify(myObject));
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetDashboardShows?input=${base64String}`,
            this.props?.userContext?.active_tenant?.tenant_id,
            true
        )
            .then((res) => {
                console.log(res, "res in animation");
                this.setState({
                    renderList: handlePaginationList(
                        0,
                        this.state.rowsPerPage,
                        res?.data?.data
                    ),
                    isFetchingTableData: false,
                    originalRenderList: res.data.data,
                    total_count: res.data.total_count,
                    last_accessed_show_name: res.data.last_accessed_show_name,
                    last_accessed_show_id: res.data.last_accessed_show_id,
                });
            })
            .catch((error) => {
                this.setState({ isFetchingTableData: false });
                console.log(error, "error in Landingpagetable get method");
            });
    };



    setJson = (field, value) => {
        switch (field) {
            case 'pagePerRow':
                this.setState({ rowsPerPage: value, page: 0 }, () => {
                    this.getAnimationDashboardShows();
                });
                break;
            case 'page':
                this.setState({ page: value }, () => {
                    this.getAnimationDashboardShows();
                });
                break;
        }
    }

    handleOptionClick = (option) => {
        // if (option.label === "All") {
        //   this.setState({ selectedOption: null }, () => {
        //     this.getAnimationDashboardShows();
        //     this.setState({ openShowPopover: false, setShowPopover: null });
        //   });
        // } else {
        this.setState({ selectedOption: option.label }, () => {
            this.getAnimationDashboardShows();
            this.setState({ openShowPopover: false, setShowPopover: null });
        });
        // }
    };

    handleOpenNewShowModal = () => {
        this.setState({ setNewShowModal: true });
    };

    hideNewShowModal = () => {
        this.setState({ setNewShowModal: false });
    };

    handleClickShows = (event) => {
        this.setState({ openShowPopover: true, setShowPopover: event.currentTarget });
    };

    handleCloseShows = () => {
        const { originalRenderList } = this.state;
        this.setState({ openShowPopover: false, renderList: originalRenderList, setShowPopover: null });
        this.getAnimationDashboardShows();
    };

    handleSelectedShow = (newValue) => {
        this.setState({
            selectedShow: newValue,
        });
    };

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            }, () => { this.getAnimationDashboardShows() });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Show Name": return "show_name";
            case "Show Type": return "show_configuration";
            case "Production Company": return "production_company";
            case "Status": return "status";
        }
    }

    handleSeasonItemClick = (showSeasonId) => {
        // this.props.history.push(`/caster-animation/show-season/${showSeasonId}`);
        this.props?.history.push({
            pathname: `/caster-animation/show-season/${this.state.selectedShowId}`,
            state: { season_id: showSeasonId }
        });
    };

    handleFavIcon = (item) => {
        this.setState({ isFetchingTableData: true });
        const data = { show_id: item.show_id, is_favourite: item.is_favourite === 0 ? 1 : 0 };

        CasterService.postDataParams(
            Constants.CasterServiceBaseUrl + "/animationPostUserFavShow",
            data,
            this.props.userContext?.active_tenant?.tenant_id,
        )
            .then((res) => {
                const updatedItem = { ...item, is_favourite: item.is_favourite === 0 ? 1 : 0 };
                const updatedItems = this.state.renderList.map((i) =>
                    i.show_id === updatedItem.show_id ? updatedItem : i
                );
                updatedItems.sort((a, b) => b.is_favourite - a.is_favourite);
                this.setState({ renderList: updatedItems, isFetchingTableData: false },()=>{
                  this.getAnimationDashboardShows()
                });
            })
            .catch((error) => {
                this.setState({ isFetchingTableData: false });
                console.log(error, "fav error");
            });
    };

    getStatusData = (entityType) => {
        this.setState({ isSubmit: true })
        CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let filteredList = res.data?.map((item) => ({
                    value: item.lookup_data_id,
                    label: item.lookup_value,
                    is_active: item.is_active
                }));
                filteredList.unshift({ value: 'all', label: 'All', is_active: true });
                this.setState({
                    statusOptions: filteredList,
                    isSubmit: false
                });
            })
            .catch((error) => {
                this.setState({ isSubmit: false })
                console.log(error, "error in getStatusData method");
            });
    };

    render() {
        let openShowPopover = Boolean(this.state.setShowPopover);
        let openSeasonPopover = Boolean(this.state.setSeasonPopover);
        const url = `/caster-animation/show-season/${this.state.last_accessed_show_id}`
        return (
            <>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) :
                    <div>
                        <MDBRow>
                            <MDBCol xs={6} md={6} sm={4}>
                                <span className="landingPageUserName">Welcome,{`${this.props?.userContext?.user_profile?.user_name ? (' ' + this.props?.userContext?.user_profile?.user_name) : ""}`}</span>
                            </MDBCol>
                            <MDBCol xs={6} md={6} sm={4}>
                                <span className="floatRight">
                                    <span className="firstLabel">Start where you left off</span>
                                    <br />
                                    <span className="floatRight">
                                        <Link to={url}>{this.state.last_accessed_show_name}</Link>
                                    </span>
                                </span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4} className="HeaderSearchField showSearchField" style={{ marginLeft: "-10px", marginTop: "15px" }}>
                                <BasicTextField
                                    placeholder="Search for a Show"
                                    value={this.state.selectedShow}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            this.getAnimationDashboardShows(e.target.value)
                                        }
                                    }}
                                    onChange={(e) => this.handleSelectedShow(e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3} className={"newShowButton"}>
                                <BasicButton
                                    variant="contained"
                                    type="inline"
                                    className="outlined-btn"
                                    text="New Show"
                                    icon={"plus-circle"}
                                    onClick={this.handleOpenNewShowModal}
                                />
                            </MDBCol>
                            <MDBCol md={2}></MDBCol>
                            <MDBCol md={2} className="HeaderSearchField">
                                <span
                                    className="floatRight sortPadding"
                                    onClick={this.handleClickShows}
                                >
                                    Status: {this.state.selectedOption} <MDBIcon fas icon="angle-right" />
                                </span>
                                <Popover
                                    open={openShowPopover}
                                    anchorEl={this.state.setShowPopover}
                                    onClose={this.handleCloseShows}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: "15%",
                                            padding: "0.5rem",
                                            borderRadius: "1px",
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                >
                                    {this.state.statusOptions.map((option) => (
                                        <p
                                            key={option.label}
                                            className={`popover-content-value ${this.state.selectedOption === option.label ? "active-option" : ""
                                                }`}
                                            style={{
                                                color: this.state.selectedOption === option.label ? "blue" : "inherit",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => this.handleOptionClick(option)}
                                        >
                                            {option.label}
                                        </p>
                                    ))}
                                </Popover>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="showTableComponet">
                            <MDBRow className="g-0" id={"project-table"}>
                                <TableComponent
                                    list={this.state.renderList || []}
                                    config={this.state.config}
                                    buttonWithFieldFun={this.handleShowSeasonPopup.bind(this)}
                                    isLoading={this.state.isFetchingTableData}
                                    sortCallback={this.handleTableColumnClick}
                                    favIconCallback={(item, id) => this.handleFavIcon(item, id)}
                                />
                            </MDBRow>
                            <MDBRow className="Pagination">
                                <TablePagination
                                    component="div"
                                    count={this.state.total_count}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    page={this.state.page}
                                    onChangePage={(e, page) => {
                                        this.setJson('page', page)
                                    }}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onRowsPerPageChange={(e) => {
                                        this.setJson('pagePerRow', e.target.value)
                                    }}
                                />
                            </MDBRow>
                            <Popover
                                open={openSeasonPopover}
                                anchorEl={this.state.setSeasonPopover}
                                onClose={this.handleCloseSeasonPopup}
                                anchorReference="anchorOrigin"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                PaperProps={{
                                    style: {
                                        width: "20%",
                                        padding: "0.5rem",
                                        borderRadius: "1px",
                                        fontSize: "0.7rem",
                                    },
                                }}
                            >
                                <MDBRow>
                                    {this.state.seasons.map((item, index) => {
                                        const showSeasonId = this.state.show_season_id[index];
                                        const itemStyle = {
                                            color: '#0063af',
                                            cursor: 'pointer',
                                            marginBottom: '0.3rem'
                                        };
                                        return (
                                            <p
                                                className="popover-content-value"
                                                key={index}
                                                onClick={() => this.handleSeasonItemClick(showSeasonId)}
                                                style={itemStyle}
                                            >
                                                {item}
                                            </p>
                                        );
                                    })}
                                </MDBRow>
                            </Popover>
                        </MDBRow>
                        {this.state.setNewShowModal && (
                            <MainViewModalComponent
                                open={this.state.setNewShowModal}
                                handleClose={this.hideNewShowModal}
                                headerText={"Create a New Show"}
                                mode={"New"}
                                modalName={"CreateNewShow"}
                            />
                        )}
                    </div>
                }
            </>
        );

    }
}

export default withRouter(withUserContext(LandingPageTable));
