import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchFieldWithAddValue from '../SharedComponents/SearchFieldWithAddValue'
export default class TalentRepresentative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRepFetch: false,
      repSelected: null,
      selectedRepresentativeDetails: null,
      repOptions: [],
      showDuplicateError:false,
    }
  }
  componentDidMount() {

  }

  getRepresentative(searchString, objectType = "Representative") {
    this.setState({ isRepFetch: true })
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
      .then((response) => {
        this.setState({ isRepFetch: false })
        if (response?.data?.error) {
          console.log('error from search', repsonse?.data?.error)
        } else {
          let formattedList = response?.data?.map(i => ({
            value: i?.id,
            text: i?.name,
            aka: i?.aka,
            headerName: objectType == "Crew" ? i?.crew_name : objectType == "Representative" ? i?.representative_name : objectType == "Talent" ? i?.talent_name : null
          }))

          if (objectType == "Representative") {
            this.setState({ repOptions: formattedList })
          }

        }
      },
        (err) => {
          this.setState({ isRepFetch: false })
          console.log("Representative fetch" + err);
        });
  }
  getRepresentativeDetails(id) {
    this.setState({ selectedRepresentativeDetails: null })
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureRepresentativeDetails?id=${id}`, this.props.userContext?.active_tenant?.tenant_id, this.state.aesKey)
      .then(response => {
        if (response?.data?.error) {
          console.log('error from representative details', repsonse?.data?.error)
        } else {
          console.log(response?.data?.[0]);
          this.setState({ selectedRepresentativeDetails: response?.data?.[0] })
        }
      }, err => {
        console.log("Err from representative details", err);
        this.setState({ selectedRepresentativeDetails: null })
      })
  }
  onChange(value) {
    this.setState({ repSelected: value })
    if (value?.value) {      
      this.getRepresentativeDetails(value?.value);
    }
    else {
      this.setState({ selectedRepresentativeDetails: null })
    }
  }
  addRepresentative(){
    this.setState({showDuplicateError:false})
    let repIndex = this.props?.representativeList?.findIndex(i=>i?.representative_id == this.state?.selectedRepresentativeDetails?.representative_id && !i?.is_delete)
    if(this.state?.selectedRepresentativeDetails && repIndex == -1){
      let representative = {...this.state?.selectedRepresentativeDetails};
      let object ={
        agency_id:representative?.agency_id,
        agency_name:representative?.agency_name,
        representative_id:representative?.representative_id,
        representative_name:representative?.representative_name,
        representative_first_name:representative?.first_name,
        representative_last_name:representative?.last_name,
        occupation:representative?.occupation,
        representation_phone:representative?.phone_list?.filter(i=>i?.is_primary)?.[0]?.phone,
        representation_email:representative?.email_list?.filter(i=>i?.is_primary)?.[0]?.email,
        // is_active,
        is_delete:0,
        is_primary:0
      }
      this.props?.addRepresentationList(object)
      this.setState({selectedRepresentativeDetails:null,repSelected:null})
    }
    else{
      this.setState({showDuplicateError:true})
    }
  
  }
  render() {
    return (
      <MDBContainer className='TalentRepresentativeContainer'>
        {this.props?.isEditing && 
        
        <MDBRow>
          <MDBCol md={4}>
            <SearchFieldWithAddValue
              id="talentRepField"
              searchSelect={true}
              loading={this.state.isRepFetch}
              searchText={ev => {
                if (ev.target.value !== "" && ev.target.value !== null) {
                  this.getRepresentative(ev.target.value)
                }
                else {
                  this.setState({ isRepFetch: false })
                }
              }}
              detail_selected={this.state?.repSelected}
              options={this.state.repOptions || []}
              onChange={(e, value) => this.onChange(value, e)}
              placeholder='-Search by Representative Name -'
              newLabel={"Representative"}

            />
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{this.state?.selectedRepresentativeDetails?.agency_name}</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{this.state?.selectedRepresentativeDetails?.occupation}</div>
          </MDBCol>
          <MDBCol md={1}>
            <div className='labelText'>{this.state?.selectedRepresentativeDetails?.phone_list?.filter(i=>i?.is_primary)?.[0]?.phone}</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{this.state?.selectedRepresentativeDetails?.email_list?.filter(i=>i?.is_primary)?.[0]?.email}</div>            
          </MDBCol>
          <MDBCol md={1}>
          <MDBIcon icon={"plus-circle"} className={!this.state?.selectedRepresentativeDetails ? `disabled` : ''} onClick={()=> this.addRepresentative()}/>
          </MDBCol>
         {this.state?.showDuplicateError && <MDBCol md={12}><div className='errorText'>{!this.state?.selectedRepresentativeDetails ? 'Please select Representative' :'Representative already present.'}</div></MDBCol>}
        </MDBRow>
        }
        <MDBRow>
          <MDBCol md={2}>
            <div className='headerText'>Representative</div>
          </MDBCol>
          <MDBCol md={3}>
            <div className='headerText'>Company</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='headerText'>Occupation</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='headerText'>Phone</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='headerText'>Email</div>
          </MDBCol>
        </MDBRow>
        {this.props?.representativeList?.filter(i=>!i?.is_delete)?.map(item=>(
          
          <MDBRow className="repList">
          <MDBCol md={2}>
            <div className={`labelText ${item?.is_primary == 1 ? 'primaryStarIcon' : ''}`}>{item?.representative_name}</div>
          </MDBCol>
          <MDBCol md={3}>
            <div className='labelText'>{item?.agency_name}</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{item?.occupation}</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{item?.representation_phone}</div>
          </MDBCol>
          <MDBCol md={2}>
            <div className='labelText'>{item?.representation_email}</div>
          </MDBCol>
          {this.props?.isEditing && 
          <MDBCol md={1}>
          <input 
            name='repPrimaryList' 
            id={`radio_${item?.talent_representative_agency_mtrx_id}`}
            size="small"
            type="radio"
            value={1}   
            defaultChecked={item?.is_primary == 1 ? true : false}
            onChange={(e)=>this.props?.onChangeRepresentative(item?.talent_representative_agency_mtrx_id,'is_primary',1)}
          />
          <MDBIcon icon="times" onClick={()=>this.props?.onChangeRepresentative(item?.talent_representative_agency_mtrx_id,'is_delete',1)}/>
          </MDBCol>
         }
        </MDBRow>
      
      ))}
      </MDBContainer>
    )
  }
}
