import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBDropdown } from "mdbreact";
import React from "react";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import "./Crew.scss";
import {
  initialPersonalDetails,
  emailConfig,
  crewPersonalLimit,
  phoneConfig,
  initialAliasConfig, initialCrewAddress
} from "./Config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import RadioGroup from "@material-ui/core/RadioGroup";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import SearchFieldWithAddValue from "../SharedComponents/SearchFieldWithAddValue";
import LocationComponent from "../../Common/LocationComponent/LocationComponent";
import { CircularProgress } from "@material-ui/core";
import messages from "../../Common/Messages.json";
import InputSelectRadio from "../../Common/InputSelectRadioInline/InputSelectRadio";
import PhoneComponent from "../../Common/DefaultPhoneComponent/PhoneComponent";
import sizeLimits from "../../Common/SizeLimits.json";
import { validatePlusCharacter, validateEmail, validateAllPhone } from "../../Common/Helper";
import { Popover } from "@material-ui/core";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import AddressComponent from "../../Common/AddressComponent/AddressComponent";
import AliasComponent from "../../Common/AliasComponent/AliasComponent";
import axios from 'axios';
import NewCrewModal from "../../Common/NewCrew/NewCrew";
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';

class PersonalTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personalDetails: initialPersonalDetails,
      openCrewPopOver: false,
      socialOptions: [],
      crewOptions: [],
      isCrewFetch: false,
      openAddressPopOver: false,
      setNotesPopOver: null,
      openNotesPopover: false,
      showAddressPopup: false,
      setPopOver: false,
      searchCancelToken: null,
      socialMediaCount: 0,
      addressCount: 0,
      phoneListCount: 0,
      emailListCount: 0,
      disablePhoneSave: false,
      disableEmailSave: false,
      notesIndex: 0
    };
  }

  componentDidMount() {
    this.setState({
      crewDetails: this.props?.crewDetails
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.crewDetails !== this.props.crewDetails) {
      const socialMediaCount = this.props?.crewDetails?.social_list?.filter((obj) => obj.is_delete === 0).length;
      const phoneListCount = this.props?.crewDetails?.phone_list?.filter((obj) => obj.is_delete === 0).length;
      const emailListCount = this.props?.crewDetails?.email_list?.filter((obj) => obj.is_delete === 0).length;
      const addressCount = this.props?.crewDetails?.address_list?.filter((obj) => obj.is_delete === 0).length;
      this.setState({ socialMediaCount: socialMediaCount, addressCount: addressCount, phoneListCount: phoneListCount, emailListCount: emailListCount, crewDetails: this.props?.crewDetails });
    }
  }
  handleNotesPopover = (e, index) => {
    this.setState({ notesIndex: index, setNotesPopOver: e.currentTarget, openNotesPopover: true });
  };
  handleNotesClose = (e) => {
    this.setState({ setNotesPopOver: null, openNotesPopover: false });
  };
  showPopup(type, id, data = {}) {
    let htmlElement = document.getElementById(id);
    this.setState({
      setPopOver: htmlElement,
      [`${type}Selected`]: { ...data },
    });
    switch (type) {
      case "crew":
        this.setState({
          openCrewPopOver: true,
        });
        break;
      case "address":
        this.setState({ showAddressPopup: true, addressSelected: data });
        break;

      default:
        break;
    }
  }
  getObjectDetails = (objectType, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    CasterService.getDataWithCancel(
      Constants.CasterServiceBaseUrl +
      `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`, cancelToken
    ).then((response) => {
      let formattedList = response?.data?.map((i) => ({
        value: i?.id,
        text: i?.name,
      }));
      this.setState({ crewOptions: formattedList });
    });
  };
  onClosePopup(type) {
    this.setState({ setPopOver: null, [`${type}Selected`]: {} });
    switch (type) {
      case "crew":
        this.setState({ openCrewPopOver: false });
        break;
      case "address":
        this.setState({ showAddressPopup: false });
        break;

      default:
        break;
    }
  }
  ValidateSaveButton(field, value) {
    field === 'phone' && this.setState({ disablePhoneSave: value })
    field === 'email' && this.setState({ disableEmailSave: value })
  }
  onAddressSave(address) {
    this.onClosePopup("address");
    this.props?.onAddressChange(
      this.state.addressSelected?.crew_address_id,
      "address_list",
      address
    );
  }
  getFullAddress(item) {
    let { address, city, state, country, zip } = item;
    if (state?.includes('-')) {
      let splitArray = state?.split("-")
      state = splitArray[0]
    }
    return <div className="crewAddressDiv">
      {address ? <p> {address} </p> : ''}
      {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
      {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
    </div>
    // return `${address ? address + '\n' : ''}  ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${zip ? zip + '\n' : ''} ${zip ? country ? country : '' :  country ?  '\n' + country  : ''}`;
  }
  //this.handleListEdit("notes", e.target.value, item, "assistant_list", this.state?.notesIndex)
  handleListEdit = (field, value, obj, listName, selectedindex, check = "") => {
    let tempCrewDetails = JSON.parse(JSON.stringify(this.state?.crewDetails?.assistant_list));
    tempCrewDetails[selectedindex]["notes"] = value;
    this.setState(
      (prevState) => ({
        crewDetails: {
          ...prevState.crewDetails,
          ["assistant_list"]: tempCrewDetails,
        }
      })
    );
  }
  saveNotes = (field, value, obj, listName, selectedindex, check = "") => {
    this.props?.handleListEdit(field, value, obj, listName, selectedindex);
    this.handleNotesClose();
  }
  render() {
    return (
      <MDBContainer className={`PersonalTabModalContainer ${this.props?.isEditing ? ' editMode' : 'viewMode'}`}>
        {this.props?.isLoading ? (
          <CircularProgress />
        ) : (
            <>
              {this.props?.isEditing ?
                <MDBRow>
                  <MDBCol>
                    <BasicTextField
                      id="firstName"
                      label="First Name"
                      isMandatory={true}
                      showMandatory={true}
                      isAlphabetVar={true}
                      value={this.props?.crewDetails?.first_name}
                      onChange={(e) => this.props?.handleOnchange('first_name', e.target?.value)}
                      handleAlphabetVariantSelect={(value) => this.props?.handleOnchange('first_name', value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <BasicTextField
                      id="middleName"
                      label="Middle Name"
                      isAlphabetVar={true}
                      value={this.props?.crewDetails?.middle_name}
                      onChange={(e) => this.props?.handleOnchange('middle_name', e.target?.value)}
                      handleAlphabetVariantSelect={(value) => this.props?.handleOnchange('middle_name', value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <BasicTextField
                      id="lastName"
                      label="Last Name"
                      isMandatory={true}
                      showMandatory={true}
                      isAlphabetVar={true}
                      value={this.props?.crewDetails?.last_name}
                      onChange={(e) => this.props?.handleOnchange('last_name', e.target?.value)}
                      handleAlphabetVariantSelect={(value) => this.props?.handleOnchange('last_name', value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <BasicTextField
                      id="suffixTalent"
                      label="Suffix"
                      isAlphabetVar={true}
                      value={this.props?.crewDetails?.suffix}
                      onChange={(e) => this.props?.handleOnchange('suffix', e.target?.value)}
                      handleAlphabetVariantSelect={(value) => this.props?.handleOnchange('suffix', value)}
                    />
                  </MDBCol>
                </MDBRow> :
                <MDBRow>
                  <BasicLabel text={"Name"} />
                  <div className={`displayName`}>{`${this.props?.crewDetails?.first_name ? this.props?.crewDetails?.first_name : ''} ${this.props?.crewDetails?.middle_name ? this.props?.crewDetails?.middle_name + ' ' : ' '} ${this.props?.crewDetails?.last_name ? this.props?.crewDetails?.last_name : ''} ${this.props?.crewDetails?.suffix ? this.props?.crewDetails?.suffix : ''}` || '-'}</div>
                </MDBRow>
              }
              {this.props?.isEditing &&
                <MDBRow>
                  <AliasComponent
                    aliasDetails={this.props?.crewDetails?.akas}
                    onAliasSave={this.props?.onAliasSave.bind(this)}
                    module_type={"Crew"}
                    mandatory_lastName={true}
                    initialAliasConfig={initialAliasConfig}
                  />
                </MDBRow>
              }
              <MDBRow>
                <div className="tabHeading">Personal</div>

                <MDBCol md={5}>
                  <BasicTextField
                    label={"Company"}
                    placeholder="Company"
                    value={this.props?.crewDetails?.company || !this.props?.isEditing && '--'}
                    onChange={(e) =>
                      this.props?.handleOnchange("company", e.target.value)
                    }
                    showAsLabel={!this.props?.isEditing}
                  />
                </MDBCol>
                <MDBCol md={5}>
                  <BasicTextField
                    label={"Location"}
                    placeholder="Location"
                    value={this.props?.crewDetails?.location_name || !this.props?.isEditing && '--'}
                    onChange={(e) =>
                      this.props?.handleOnchange("location_name", e.target.value)
                    }
                    showAsLabel={!this.props?.isEditing}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow id="occ-field">
                <MDBCol md={4}>
                  <SelectField
                    label={"Occupation"}
                    placeHolderText="Occupation"
                    value={
                      this.props.occupationOptions?.find(
                        (item) =>
                          item?.label === this.props?.crewDetails?.occupation
                      )?.value
                    }
                    options={this.props?.occupationOptions || []}
                    onChange={(e) => {
                      this.props?.handleOnchange("occupation", this.props.occupationOptions?.find(
                        (item) =>
                          item?.value === e.target.value
                      )?.label);
                    }}
                    showAsLabel={!this.props?.isEditing}
                  />
                  {!this.props?.isEditing && !this.props?.crewDetails?.occupation && '--'}
                </MDBCol>
                {/* <MDBCol md={2}>
                <div>DOB</div>
                <div>{this.props?.crewDetails?.dob ? this.props?.crewDetails?.birth_date : "--"}</div>
              </MDBCol>
              <MDBCol md={3}>
                <div>Title</div>
                <div>{this.props?.crewDetails?.title ? this.props?.crewDetails?.title : "--"}</div>
              </MDBCol>
              <MDBCol md={3}>
                <div>Type</div>
                <div>{this.props?.crewDetails?.type ? this.props?.crewDetails?.type : "--"}</div>
              </MDBCol> */}
              </MDBRow>
              <MDBRow className="mt-2">
                <MDBCol md={6} className={this.props?.isEditing ? "mt-1" : ''}>
                  {!this.props?.isEditing ? <BasicLabel text="Social Media" /> : null}
                  {this.state?.socialMediaCount <= 1 && !this.props?.isEditing && '--'}

                  {!this.props?.isEditing && this.state?.socialMediaCount > 1 && this.props?.crewDetails?.social_list
                    ?.filter((i) => i?.is_delete == 0)
                    ?.map((item, index) => (
                      <InputSelectRadio
                        radioGroupName="crewSocialRadio"
                        // inputPlaceholder="Enter Social Handle"
                        showAsLabel={!this.props?.isEditing}
                        isEditing={this.props?.isEditing}
                        objectItem={item}
                        addCallback={(obj) =>
                          this.props?.addRemoveObject("social_list", obj)
                        }
                        removeCallback={(id, key, value) =>
                          this.props?.onChangeObject(
                            "social_list",
                            id,
                            key,
                            value
                          )
                        }
                        editCallback={(id, key, value) =>
                          this.props?.onChangeObject(
                            "social_list",
                            id,
                            key,
                            value
                          )
                        }
                        selectOptions={this.props?.socialOptions}
                        showAdd={(this.props?.isEditing && index === 0) ? true : false}
                        showRemove={
                          index != 0 && this.props?.isEditing ? true : false
                        }
                        showRadio={
                          index != 0 && this.props?.isEditing ? true : false
                        }
                        showLabel={index == 0 ? true : false}
                        labelNew={"Handle"}
                        addButton={index == 0 ? true : false}
                      />

                    ))}

                  {this.props?.isEditing && this.props?.crewDetails?.social_list
                    ?.filter((i) => i?.is_delete == 0)
                    ?.map((item, index) => (
                      <InputSelectRadio
                        radioGroupName="crewSocialRadio"
                        // inputPlaceholder="Enter Social Handle"
                        showAsLabel={!this.props?.isEditing}
                        isEditing={this.props?.isEditing}
                        objectItem={item}
                        addCallback={(obj) =>
                          this.props?.addRemoveObject("social_list", obj)
                        }
                        removeCallback={(id, key, value) =>
                          this.props?.onChangeObject(
                            "social_list",
                            id,
                            key,
                            value
                          )
                        }
                        editCallback={(id, key, value) =>
                          this.props?.onChangeObject(
                            "social_list",
                            id,
                            key,
                            value
                          )
                        }
                        selectOptions={this.props?.socialOptions}
                        showAdd={(this.props?.isEditing && index === 0) ? true : false}
                        showRemove={
                          index != 0 && this.props?.isEditing ? true : false
                        }
                        showRadio={
                          index != 0 && this.props?.isEditing ? true : false
                        }
                        showLabel={index == 0 ? true : false}
                        labelNew={"Handle"}
                        addButton={index == 0 ? true : false}
                      />

                    ))}
                </MDBCol>
                <MDBCol md={6} className="assistantsCol">
                  <BasicLabel text="Assistants" />
                  {this.props?.crewDetails?.assistant_list?.length <= 1 && !this.props?.isEditing && '--'}
                  {this.props?.crewDetails?.assistant_list?.map((item, index) => (
                    ((item?.is_delete === 0) || (item?.is_delete === 1 && item?.selectedCrew?.value === null)) && (!this.props?.isEditing && index === 0 ? null :
                      <MDBRow className={index !== 0 ? "marginNew-top" : ""}>
                        <MDBCol md={this.props?.isEditing ? 6 : 'auto'}>
                        {this.props?.isEditing ?
                          <SearchFieldWithAddValue
                            key={`crew${index}`}
                            id={`crew${index}`}
                            placeholder="Assistant"
                            searchSelect={true}
                            detail_selected={item?.selectedCrew || null}
                            options={this.state?.crewOptions || []}
                            //onChange={(e, value) => this.props?.handleSelectedCrew(e, value, item, index)}
                            onChange={(e, value) => this.props?.handleSelectedCrew('crew', value, item, index)}
                            searchText={ev => {
                              if (ev.target.value !== "" && ev.target.value !== null) {
                                this.getObjectDetails('Crew', ev.target.value)
                              }
                              else {
                                this.setState({ isCrewFetch: false })
                              }
                            }}
                            // label={index == 0 ? "Assistants": ""}
                            multiple={false}
                            showAsLabel={!this.props.isEditing}
                            newLabel={'Assistant'}
                          />
                          :
                          <>
                            {item?.selectedCrew?.text}</>
                          }
                        </MDBCol>
                        <MDBCol md={1} className="mt-1">
                          {(!this.props?.isEditing && index === 0) ? null : <MDBIcon
                            icon="file"
                            onClick={(e) => this.handleNotesPopover(e, index)}
                          />}
                        </MDBCol>
                        <MDBCol md={1} className="mt--1">

                          {(this.props?.isEditing && index === 0) ? (
                            <MDBIcon
                              icon="plus-circle"
                              onClick={() =>
                                this.props?.addCallback("assistant_list")
                              }
                            />
                          ) : null}

                          {(this.props?.isEditing && index !== 0) ? (
                            <MDBIcon
                              icon="times"
                              onClick={() =>
                                this.props?.removeCallback("assistant_list", item, index)
                              }
                            />
                          ) : null}

                        </MDBCol>
                        {this.state.openNotesPopover ? (
                          <Popover
                            id={index}
                            open={this.state.openNotesPopover}
                            anchorEl={this.state.setNotesPopOver}
                            onClose={() => { this.handleNotesClose(); }}
                            className="NotesPopver"
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            PaperProps={{
                              style: { width: "20%", backgroundColor: "#F2F2F2" },
                            }}
                          >
                            <MDBContainer className="NotesContainer">
                              <BasicTextArea
                                disabled={this.props?.isEditing ? false : true}
                                value={this.state?.crewDetails?.assistant_list?.[this.state?.notesIndex]?.notes}
                                onChange={this.props?.isEditing ? (e) => this.handleListEdit("notes", e.target.value, item, "assistant_list", this.state?.notesIndex) : null}
                              />
                              {this.props?.isEditing &&
                                <MDBRow>
                                  <MDBCol md={5}></MDBCol>
                                  <MDBCol md={2}>
                                    <BasicButton
                                      className="btnwidth"
                                      variant="contained"
                                      text={
                                        this.state.isNotesSubmit ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={18}
                                          />
                                        ) : (
                                            " "
                                          )
                                      }
                                      icon={!this.state.isNotesSubmit ? "save" : " "}
                                      onClick={() => this.saveNotes("notes", this.state?.crewDetails?.assistant_list?.[this.state?.notesIndex]?.notes, item, "assistant_list", this.state?.notesIndex)}
                                    />
                                  </MDBCol>
                                  <MDBCol md={1}></MDBCol>
                                  <MDBCol md={2}>
                                    <BasicButton
                                      variant="outlined"
                                      text=" "
                                      icon={"times"}
                                      onClick={this.handleNotesClose}
                                    />
                                  </MDBCol>
                                </MDBRow>
                              }
                            </MDBContainer>
                          </Popover>
                        ) : null}
                      </MDBRow>
                    )
                  ))}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <div className="tabHeading">Contacts</div>
                <MDBCol md={4}>
                  {this.props?.isEditing ?
                    <>
                      <BasicLabel text={"Address"} />
                      <div className='addLabel' id="newAddressBtn" onClick={() => this.showPopup('address', 'newAddressBtn', 'new')}>
                        <span>
                          <MDBIcon icon="plus-circle" />
                        Add Address
                      </span>
                      </div>
                    </>
                    :
                    // <div className='headerText'>Address</div>
                    <BasicLabel text={"Address"} />
                  }
                  {this.state?.addressCount <= 0 && !this.props?.isEditing && '--'}
                  {this.props?.crewDetails?.address_list
                    ?.filter((i) => i?.is_delete == 0 || !i?.is_delete)
                    ?.map((item, index) => (
                      <>
                        <MDBRow className="addressRow">
                          <MDBCol md={3}>
                            <p className="spanWithLeftBorder">
                              {item?.address_type}
                            </p>
                          </MDBCol>
                          <MDBCol md={6} className="crewFullAddress noPaddingLeft">
                            <p className={`spanWithLeftBorder ${item?.is_primary == 1 ? "primary" : ""}`}>
                              {this.getFullAddress(item)}
                            </p>
                          </MDBCol>
                          <MDBCol md={3} className="noPadding iconContainer">
                            {!this.props?.isEditing &&
                              <div>
                                {item?.is_primary ? <MDBIcon fas icon="star" /> : null}
                              </div>
                            }
                            {this.props?.isEditing && (
                              <>
                                <MDBIcon
                                  icon="arrows-alt-v"
                                  className="transform_arrow"
                                  id="addressField"
                                  onClick={() => this.showPopup("address", "addressField", item)}
                                />
                                <input
                                  name="addressPrimary"
                                  id={`radioAddress_${item?.crew_address_id}`}
                                  size="small"
                                  type="radio"
                                  value={item?.crew_address_id}
                                  defaultChecked={
                                    item?.is_primary === 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    this.props?.onAddressChange(
                                      item?.crew_address_id,
                                      "is_primary",
                                      e
                                    )
                                  }
                                />
                                <MDBIcon
                                  icon="times"
                                  onClick={() =>
                                    this.props?.onAddressChange(
                                      item?.crew_address_id,
                                      "is_delete",
                                      1
                                    )
                                  }
                                />
                              </>
                            )}
                          </MDBCol>
                        </MDBRow>
                      </>
                    ))}
                  {this.state?.showAddressPopup && (
                    <AddressComponent
                      open={this.state?.showAddressPopup}
                      setPopOver={this.state?.setPopOver}
                      handleClose={this.onClosePopup.bind(this, "address")}
                      onSave={(address) => {
                        this.onAddressSave(address);
                      }}
                      mode={
                        this.state.addressSelected?.crew_address_id
                          ? "edit"
                          : "new"
                      }
                      id={this.state.addressSelected?.crew_address_id || null}
                      selectedAddress={this.state?.addressSelected}
                    />
                  )}
                </MDBCol>
                <MDBCol md={this.props?.isEditing ? 4 : 3} className={this.props?.isEditing ? 'hasPhoneEdit' : 'hasPhoneView'}>
                  {/* {!this.props?.isEditing ? <BasicLabel text="Phone" /> : null} */}
                  {this.props?.crewDetails?.phone_list?.filter(i => i?.is_delete == 0)?.map((phone, index) => (
                    <MDBRow>
                      <MDBCol md={12}>
                        <PhoneComponent
                          radioGroupName='crewPhoneRadio'
                          showAsLabel={!this.props?.isEditing}
                          isEditing={this.props?.isEditing}
                          objectItem={phone}
                          placeholder={" "}
                          ext={true}
                          inValidInput={"Please enter valid phone"}
                          addCallback={(obj) => this.props?.addRemoveObject('phone_list', obj)}
                          removeCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                          editCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                          selectOptions={this.props?.phoneOptions}
                          showAdd={(this.props?.isEditing && index === 0) ? true : false}
                          showRemove={index != 0 && this.props?.isEditing ? true : false}
                          showRadio={index != 0 && this.props?.isEditing ? true : false}
                          inputValidateMethod={validateAllPhone}
                          showLabel={index == 0 ? true : false}
                          labelNew={"Phone"}
                          addButton={index == 0 ? true : false}
                          enableSecondaryError={true}
                        />
                      </MDBCol>
                      {/* <MDBCol md={1}>
                        <div>
                          {phone?.is_primary && !this.props?.isEditing ? <MDBIcon fas icon="star" /> : null }
                        </div>
                      </MDBCol> */}
                    </MDBRow>
                  ))}
                  {this.state?.phoneListCount <= 1 && !this.props?.isEditing && '--'}
                </MDBCol>
                <MDBCol md={this.props?.isEditing ? 4 : 5} className={this.state?.emailListCount <= 1 && !this.props?.isEditing ? 'emptyValueView' : 'hasEmailValue'} >
                  <MDBRow>
                    {this.state?.emailListCount <= 1 && !this.props?.isEditing && <BasicLabel text="Email" />}
                    {this.props?.crewDetails?.email_list
                      ?.filter((i) => i?.is_delete == 0)
                      ?.map((item, index) => (
                        <InputSelectRadio
                          radioGroupName="crewEmailRadio"
                          inputPlaceholder=" "
                          showAsLabel={!this.props?.isEditing}
                          isEditing={this.props?.isEditing}
                          objectItem={item}
                          addCallback={(emailObj) =>
                            this.props?.addRemoveObject("email_list", emailObj)
                          }
                          removeCallback={(id, key, value) =>
                            this.props?.onChangeObject(
                              "email_list",
                              id,
                              key,
                              value
                            )
                          }
                          editCallback={(id, key, value) =>
                            this.props?.onChangeObject(
                              "email_list",
                              id,
                              key,
                              value
                            )
                          }
                          selectOptions={this.props?.emailOptions}
                          showAdd={(this.props?.isEditing && index === 0) ? true : false}
                          showRemove={
                            index != 0 && this.props?.isEditing ? true : false
                          }
                          showRadio={
                            index != 0 && this.props?.isEditing ? true : false
                          }
                          inputValidateMethod={validateEmail}
                          showLabel={index == 0 ? true : false}
                          labelNew={"Email"}
                          addButton={index == 0 ? true : false}
                          selectFieldLength={3}
                          textFieldLength={9}
                        />
                      ))}
                    {this.state?.emailListCount <= 1 && !this.props?.isEditing && '--'}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {this.props?.isEditing ?
                <MDBRow>
                  <MDBCol md={12} className="notesArea pt-2">
                    <BasicTextArea
                      id="notes"
                      label="Notes"
                      rows={2}
                      value={this.props?.crewDetails?.notes}
                      onChange={(e) => this.props?.handleOnchange('notes', e.target?.value)}
                      showAsLabel={!this.props?.isEditing}
                    />
                  </MDBCol>
                </MDBRow>
                :
                <MDBRow>
                  <MDBCol md={5} className="pt-2">
                    <BasicLabel text={"Notes"} />
                    <div className={`displayName`}>{this.props?.crewDetails?.notes || '--'} </div>
                  </MDBCol>
                </MDBRow>
              }
            </>
          )}

      </MDBContainer>
    );
  }
}
export default PersonalTab;
