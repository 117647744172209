import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import { s2ab, genReportName, validateCharctersSpaceOnly, validateNumbersOnly } from '../../Helper';
class CastingDataFc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            submitted_by: "",
            phone_number: null,
            isStunt : 0,
        }
    }

    handleSubmitCastingDataFc = () => {
        this.setState({ postInitiated: true });
        let { submitted_by, phone_number, selectProduction ,isStunt} = this.state;
        let production_id = selectProduction?.value;

        if (!production_id) {
            this.setState({ isPosting: false })
            return;
        }
        let payload = {
            production_id,
            submitted_by,
            phone_number,
            isStunt
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal)
        this.setState({ postInitiated: false })

    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow>
                        <BasicCheckbox
                            id="Stunt_Only"
                            label="Stunt Only"
                            checked={this.state.isStunt === 1}
                            align="start"
                            onChange={(e) => {this.onChangeCallBack('isStunt',e.target.checked ? 1 : 0)}}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <BasicTextField
                                id="SubmittedBy"
                                label={"Submitted By"}
                                value={this.state.submitted_by}
                                onChange={(e) => validateCharctersSpaceOnly(e.target.value) ? this.onChangeCallBack('submitted_by', e.target.value.length > 0 ? e.target.value : null) : null}
                            />
                            <BasicTextField
                                id="PhoneNumber"
                                label={"Phone Number"}
                                placeholder="(___) ___-____"
                                value={this.state.phone_number || ""}
                                onChange={(e) => validateNumbersOnly(e.target.value) ? this.onChangeCallBack('phone_number', e.target.value.length > 0 ? e.target.value : null) : null}
                            />
                        </MDBRow>
                    </MDBRow>
                    <ReportButton
                        generateDoc={false}
                        onClickGenratePdf={this.handleSubmitCastingDataFc}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default CastingDataFc;