import React from "react";
import { withUserContext } from "../../../contexts/UserContext";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import {companyLimit} from "./config";
import messages from "../../Common/Messages.json";
import {
  validateAlpVarCharNumOnlyWithSpace,
  formatPhoneNumbers,
  validateEmail,
  validateAllPhone,validateNumbersOnly, checkZipCode, formatZipCode
} from "../../Common/Helper";

class CompanyprofileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyDetails: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.companyDetails !== state.companyDetails) {
      return {
        companyDetails: props.companyDetails,
      };
    }
    return null;
  }

  render() {
    return (
      <div className="CompanyDetailContainer">
        <MDBRow>
          <MDBCol md={5}>
            <BasicTextField
              label="Company Name"
              limit={companyLimit.companyName}
              showMandatory={this.props.postInitiated || false}
              limitWarning={messages.exceed150CharacterWarning || ""}
              isMandatory={true}
              value={this.props?.companyDetails?.company_name || ""}
              onChange={(e) =>
                validateAlpVarCharNumOnlyWithSpace(e.target.value)
                  ? this.props.handleGeneralEdit(
                      "company_name",
                      e.target.value
                    )
                  : null
              }
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label="Company Aka"
              limit={companyLimit.company_aka}
              limitWarning={messages.exceed150CharacterWarning || ""}
              value={this.props?.companyDetails?.company_aka || ""}
              onChange={(e) =>
                this.props.handleGeneralEdit("company_aka", e.target.value)
              }
            />
          </MDBCol>
          <MDBCol md={4}>
          <SelectField
              id={"Company Type"}
              className={"Company Type"}
              value={this.props?.companyDetails?.company_type || ""}
              isMandatory={true}
              showMandatory={this.props.postInitiated || false}
              label={"Company Type"}
              defaultMenuText={"-"}
              options={this.props?.companyTypeOption}
              onChange={(e) =>
                this.props.handleGeneralEdit("company_type", e.target.value)
              }
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md={2}>
            <SelectField
              id={"Address Type"}
              value={this.props?.companyDetails?.address_type || ""}
              label={"Address Type"}
              defaultMenuText={"-"}
              options={this.props?.addressTypeOptions}
              onChange={(e) =>
                this.props.handleGeneralEdit("address_type", e.target.value)
              }
            />
            </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={5}>
            <BasicTextField
            label={"Address"}
              limit={companyLimit.address_1}
              limitWarning={messages.exceed200CharacterWarning || ""}
              value={this.props?.companyDetails?.address_1 || ""}
              onChange={(e) =>
                this.props?.handleGeneralEdit(
                  "address_1",
                  e.target.value.length > 0 ? e.target.value : null
                )
              }
            />
            <BasicTextField
              limit={companyLimit.address_2}
              limitWarning={messages.exceed200CharacterWarning || ""}
              value={this.props?.companyDetails?.address_2 || ""}
              onChange={(e) =>
                this.props?.handleGeneralEdit(
                  "address_2",
                  e.target.value.length > 0 ? e.target.value : null
                )
              }
            />
          </MDBCol>
          <MDBCol md={6}>
            <MDBRow>
              <MDBCol md={6}>
                <BasicTextField
                  label={"City"}
                  limit={companyLimit.city}
                  limitWarning={messages.exceed200CharacterWarning || ""}
                  value={this.props?.companyDetails?.city || ""}
                  onChange={(e) =>
                    this.props?.handleGeneralEdit(
                      "city",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol md={3}>
                {(this.props?.countryOptions?.find(item => item.value == this.props?.companyDetails?.country)?.label == 'United States') ?
                  <SelectField
                    id={"state"}
                    value={this.props?.companyDetails?.state}
                    label={"State"}
                    defaultMenuText={"-"}
                    options={this.props?.stateOptions}
                    onChange={(e) =>
                      this.props.handleGeneralEdit("state", e.target.value)
                    }
                  /> : 
                  <BasicTextField
                    id={"state"}
                    value={this.props?.companyDetails?.state || ""}
                    label={"State"}
                    onChange={(e) =>
                      this.props.handleGeneralEdit("state", e.target.value)
                    }
                  />
                }
              </MDBCol>
              <MDBCol md={3}></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={4}>
                {/* <BasicTextField
                  label={"Zip Code"}
                  limit={companyLimit.zip}
                  limitWarning={
                    messages.exceed10CharacterWarning || "enter 10 numbers only"
                  }
                  fieldValid={
                    !validateNumbersOnly(this.props?.companyDetails?.zip) &&
                    this.props?.companyDetails?.zip
                  }
                  inValidInput={messages.invalidInput}
                  value={this.props?.companyDetails?.zip || ""}
                  onChange={(e) =>
                    this.props?.handleGeneralEdit(
                      "zip",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                /> */}
                <BasicTextField
                  label={"Zip Code"}
                  value={
                    (this.props?.countryOptions?.find(
                      item => item.value === this.props?.companyDetails?.country
                    )?.label === 'United States' ||
                      this.props?.countryOptions?.find(
                        item => item.value === this.props?.companyDetails?.country
                      )?.label === 'USA' ||
                      this.props?.countryOptions?.find(
                        item => item.value === this.props?.companyDetails?.country
                      )?.label === 'US')
                      ? formatZipCode(this.props?.companyDetails?.zip)
                      : this.props?.companyDetails?.zip || ""
                  }
                  onChange={e => {
                    const selectedCountryLabel = this.props?.countryOptions?.find(
                      item => item.value === this.props?.companyDetails?.country
                    )?.label;

                    if (
                      selectedCountryLabel === 'United States' ||
                      selectedCountryLabel === 'USA' ||
                      selectedCountryLabel === 'US'
                    ) {
                      this.props?.handleGeneralEdit('zip', formatZipCode(e.target.value));
                    } else {
                      this.props?.handleGeneralEdit('zip', e.target.value);
                    }
                  }}
                  inValidInput={messages.invalidInput}
                  fieldValid={this.props?.companyDetails?.zip ? ((this.props?.countryOptions?.find(item => item.value == this.props?.companyDetails?.country)?.label == 'United States' || this.props?.countryOptions?.find(item => item.value == this.props?.companyDetails?.country)?.label == 'USA' || this.props?.countryOptions?.find(item => item.value == this.props?.companyDetails?.country)?.label == 'US') ? (this.props?.companyDetails?.zip?.length > 9 ? !checkZipCode(this.props?.companyDetails?.zip) ? true : false : false) : false) : false}
                />
              </MDBCol>
              <MDBCol md={8}>
                <SelectField
                  id={"country"}
                  value={this.props?.companyDetails?.country || ""}
                  label={"Country"}
                  defaultMenuText={"-"}
                  options={this.props?.countryOptions}
                  onChange={(e) =>  {
                    if (this.props?.countryOptions?.find(item => item.value === e.target.value)?.label !== 'United States') {
                      this.props?.handleGeneralEdit("state", null)
                    }
                    this.props.handleGeneralEdit("country", e.target.value)
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={6}>
            <BasicTextField
              label="Email"
              value={this.props?.companyDetails?.email || ""}
              fieldValid={
                !validateEmail(this.props?.companyDetails?.email) &&
                this.props?.companyDetails?.email
              }
              inValidInput={messages.invalidInput}
              onChange={(e) =>
                this.props.handleGeneralEdit("email", e.target.value)
              }
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicTextField
              label={"Phone"}
              placeholder="(xxx)xxx-xxxx"
              fieldValid={
                this.props?.companyDetails?.phone
                  ? !validateAllPhone(this.props?.companyDetails?.phone)
                  : false
              }
              inValidInput={"Please enter valid phone"}
              value={this.props?.companyDetails?.phone ? formatPhoneNumbers(this.state.companyDetails.phone):"" || ""}
              onChange={(e) =>
                this.props.handleGeneralEdit("phone", formatPhoneNumbers(e.target.value))
              }
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
export default withUserContext(CompanyprofileDetails);
