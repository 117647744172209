import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import { withNotificationContext } from "../../../contexts/NotificationContext";
import './Reports.scss'
import { MDBCol, MDBRow } from 'mdbreact';
import SearchSelectField from '../SharedComponents/SearchSelectField';


class CrewListWorkSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetails:{},
            billingOptions:[],
            unionList:[]
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportDetails !== state.reportDetails) {
            return {
                reportDetails: props.reportDetails
            }
        }
        return null
    }
   
   
   
    render() {
        return (
            <div className="CrewListWorkSheetContainer">
                <MDBRow>
                    <MDBCol md={7}>
                        <SearchSelectField
                            id="project_id"
                            options={this.props?.projectList}
                            searchSelect={true}
                            multiple={false}
                            label={"Select Project"}
                            placeholder={"Select Project"}
                            detail_selected={this.props?.selectedprojectValue}
                            valueSelected={(e,value) => this.props?.handleOnChange("project_id", value)}
                            searchText={(e) => this.props?.getLookupValues('PROJECT',e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

}
export default withUserContext(withNotificationContext(CrewListWorkSheet));