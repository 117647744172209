import React from "react";
import "./Crew.scss";
import { withUserContext } from "../../../contexts/UserContext";
import PersonalTab from "./PersonalTab";
import ProjectHistory from "./ProjectHistory";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import {
  asistantList,
  phoneObjData,
  emailObjData,
  initialObjectConfig,
  initialCrewAddress,
  crewProjectHistoryFilterConfig,
  projectHistoryConfig,
  crewNavList
} from "./Config";
import sizeLimits from "../../Common/SizeLimits.json";
import {
  validateUSNumber,
  validateIntlNumber,
  validatePlusCharacter,
  validatePhoneNumber,
  ssnFormat,
  checkZipCode
} from "../../Common/Helper";
import { handlePaginationList, updateTableSortConfigObject, tableSortList, applyFilterTable } from '../../Common/TableHelper';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';

class CrewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      isEdit: false,
      crewDetails: {},
      emailIndex: 2,
      phoneIndex: 2,
      crewOptions: [],
      selectedCrew: null,
      countryOptions: [],
      emailOptions: [],
      phoneOptions: [],
      socialOptions: [],
      occupationOptions: [],
      filterList: JSON.parse(JSON.stringify({ ...crewProjectHistoryFilterConfig })),
      config: JSON.parse(JSON.stringify({ ...projectHistoryConfig })),
      backup_crewProjectHistory_list: [],
      filterInputs: [],
      page: 0,
      rowsPerPage: 10,
      setCrewPopOver: null,
      openCrewPopOver: null,
      selectedCrew: null,
      selectedCrewNew: null,
      showCrewModal: false,
      origin: null,
    };
    this.submitButtonRef = React.createRef();
  }
  componentDidMount() {
    
    this.getLookupValues("Country");
    this.getLookupValues("Phone");
    this.getLookupValues("Email");
    this.getLookupValues("Social");
    this.getCrewOccupation();
    this.getCrewDetails();
  }

  getCrewOccupation = () => {
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=crew_occupation`)
      .then((response) => {
        if (response?.data?.error) {
          console.log('error from search', repsonse?.data?.error)
        } else {
          let formattedList = response?.data?.map(i => ({ value: i?.lookup_value, label: i?.lookup_value, id: i?.lookup_data_id || null }))
          this.setState({ occupationOptions: formattedList })
          console.log("post response", response);
        }
      },
        (err) => {
          console.log("Post agency details error: " + err);
        });
  }
  getLookupValues(type) {
    // casterFeatureLookup
    CasterService.getData(
      Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`
    ).then(
      (response) => {
        if (!response || response?.data?.error) {
          console.log("error", response?.data?.error);
        } else {
          let formattedList = response?.data?.map((item) => ({
            id: item?.lookup_data_id,
            value: item?.lookup_value,
            label: item?.lookup_value,
          }));
          if (type == "Country") {
            this.setState({ countryOptions: formattedList });
          } else if (type == "Email") {
            this.setState({ emailOptions: formattedList });
          } else if (type == "Phone") {
            this.setState({ phoneOptions: formattedList });
          } else if (type == "Social") {
            this.setState({ socialOptions: formattedList });
          }
        }
      },
      (err) => {
        console.log("error from lookup");
      }
    );
  }
  static getDerivedStateFromProps(props, state) {
    if (props.tabValue !== state.tabValue) {
      return {
        tabValue: props.tabValue,
      };
    }
    return null;
  }
  getObjectDetails = (objectType, searchString) => {
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
      `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`
    ).then((response) => {
      let formattedList = response?.data?.map((i) => ({
        value: i?.id,
        text: i?.name,
      }));
      this.setState({ crewOptions: formattedList });
    });
  };

  getCrewDetails = () => {
    if (this.props?.crewId) {
      this.setState({ isFetchingCrewDetails: true });
      CasterService.getData(
        Constants.CasterServiceBaseUrl +
        `/casterFeatureCrewDetails?id=${this.props?.crewId}`,
        this.props.userContext?.active_tenant?.tenant_id,
        this.state.aesKey
      ).then(
        (response) => {
          console.log("response", response);
          if (response?.data?.error || !response?.data) {
            this.setState({ isFetchingCrewDetails: false });
          } else {
            let crewDetails = response?.data?.[0];
            let initObjectConfig = JSON.parse(
              JSON.stringify(initialObjectConfig)
            );
            let socialInitObject = JSON.parse(
              JSON.stringify(initialObjectConfig)
            );
            let assistantInitObject = JSON.parse(JSON.stringify(asistantList));
            let phoneInitObject = JSON.parse(
              JSON.stringify(initialObjectConfig)
            );
            let project_history_list = []
            let wholeAkaString = ''
            // if (crewDetails.akas?.length < 1) {
            //   let formattedObj = {
            //     "crew_aliases_id": null,
            //     "aka": crewDetails?.crew_name,
            //     "aka_first_name": crewDetails?.first_name,
            //     "aka_last_name": crewDetails?.last_name,
            //     "aka_middle_name": crewDetails?.middle_name,
            //     "aka_suffix": crewDetails?.suffix,
            //     "is_delete": 0,
            //     "is_primary": 1
            //   }
            //   crewDetails.akas.push(formattedObj);

            // }
            wholeAkaString = crewDetails?.akas?.map(i => i?.aka)?.join(',');
            if (crewDetails?.email_list?.length > 0) {
              let formattedObj = crewDetails?.email_list?.map((i) => {
                return {
                  object_id: i.email_id,
                  object_type: i?.email_type,
                  value: i?.email,
                  is_primary: i?.is_primary,
                  is_delete: i?.is_delete,
                };
              });
              crewDetails.email_list = [initObjectConfig, ...formattedObj];
            } else {
              crewDetails?.email_list.push(initObjectConfig);
            }
            if (crewDetails?.social_list?.length > 0) {
              let formattedObj = crewDetails?.social_list?.map((i) => {
                return {
                  object_id: i?.crew_social_media_id,
                  object_type: i?.social_media_type,
                  value: i?.social_media_account,
                  is_primary: i?.is_primary,
                  is_delete: i?.is_delete,
                };
              });
              crewDetails.social_list = [socialInitObject, ...formattedObj];
            } else {
              // (crewDetails?.social_list) && crewDetails.social_list = [];
              if (crewDetails != undefined) {
                crewDetails.social_list = [];
              }
              crewDetails?.social_list.push(socialInitObject);
            }
            if (crewDetails?.assistant_list?.length > 0) {
              let formattedObj = crewDetails?.assistant_list?.map((i) => {
                return {
                  crew_assistant_id: i?.crew_assistant_id,
                  crew_member_id: i?.crew_member_id,
                  notes: i?.notes,
                  assistant_name: i?.assistant_name,
                  is_delete: i?.is_delete,
                  selectedCrew: { value: i?.crew_member_id, text: i?.assistant_name }
                  //"is_primary": i?.is_primary
                };
              });
              crewDetails.assistant_list = [
                assistantInitObject,
                ...formattedObj,
              ];
            } else {
              if (crewDetails != undefined) {
                crewDetails.assistant_list = [];
              }
              crewDetails?.assistant_list.push(assistantInitObject);
            }
            if (crewDetails?.phone_list?.length > 0) {
              let formattedObj = crewDetails?.phone_list?.map((i) => {
                return {
                  object_id: i?.phone_id,
                  object_type: i?.phone_type,
                  value: i?.phone,
                  is_primary: i?.is_primary,
                  is_delete: i?.is_delete,
                  ext: i?.ext,
                };
              });
              crewDetails.phone_list = [phoneInitObject, ...formattedObj];
            } else {
              if (crewDetails != undefined) {
                crewDetails.phone_list = [];
              }
              crewDetails?.phone_list.push(phoneInitObject);
            }
            if (crewDetails?.project_history?.length > 0) {
              let formattedObj = crewDetails?.project_history?.map((i) => {
                return {
                  location_name: i?.location_name,
                  occupation: i?.occupation,
                  project_crew_id: i?.project_crew_id,
                  project_name: i?.project_name,
                  project_id: i?.project_id
                };
              });
              crewDetails.project_history = formattedObj
              project_history_list = formattedObj
            } else {
              if (crewDetails != undefined) {
                crewDetails.project_history = [];
              }
            }
            this.setState({
              isFetchingCrewDetails: false,
              crewDetails: crewDetails,
              crewDetailsBackup: crewDetails,
              project_history_list: project_history_list,
              backup_crewProjectHistory_list: project_history_list,
              current_crewProjectHistory_List: project_history_list
            });
            this.props?.setStateByChild({ akas: wholeAkaString })
          }
        },
        (err) => {
          this.setState({ isFetchingCrewDetails: false });
          console.log("Error in fetching Crew Details:", err);
        }
      );
    }
  };
  handleGeneralEdit = (field, value) => {
    let newValue = value;
    if (typeof value === "boolean") {
      newValue = value ? 1 : 0;
    }
    this.setState(
      (prevState) => ({
        crewDetails: {
          ...prevState.crewDetails,
          [field]: newValue,
        },
        postInitiated: false,
      }),
      () => {
        
        this.props.setModalList(this.state.crewDetails);
        this.props.fieldChanged(true, "change");
      }
    );
  };
  handleSelectedCrew = (field, newValue, obj, index) => {
    if (newValue) {
      if (newValue?.optionType === "new") {

        this.setState({ openCrewPopOver: true, selectedCrew: newValue, selectedCrewNew: newValue, currentIndex: index }, () => {
          obj = {};
          obj.assistant_name = newValue?.text;
          obj.crew_member_id = newValue?.value;
          obj.is_delete = 0;
          this.handleListEdit("crew_member_id", null, obj, "assistant_list", index);
          //this.handleListEdit("assistant_name", newValue?.value, obj, "assistant_list", index);
          //this.handleListEdit("selectedCrew", { value: newValue?.value, text: newValue?.value }, obj, "assistant_list", index)
          //this.props.setCurrentIndexForAssistant(this.state.currentIndex);
        })
      } else if (newValue?.optionType === 'edit') {
        this.setState({ selectedCrew: newValue, showCrewModal: true, selectedCrewNew: null }, () => {
          obj = {};
          obj.assistant_name = newValue?.text;
          obj.crew_member_id = newValue?.value;
          obj.is_delete = 0;
          this.handleListEdit("crew_member_id", newValue?.value, obj, "assistant_list", index);
          this.handleListEdit("assistant_name", newValue?.text, obj, "assistant_list", index);
          this.handleListEdit("selectedCrew", { value: newValue?.value, text: newValue?.text }, obj, "assistant_list", index)
        })
      } else if (newValue?.optionType === 'save') {
        this.setState({ selectedCrew: newValue, selectedCrewNew: null }, () => {
          obj = {};
          obj.assistant_name = newValue?.text;
          obj.crew_member_id = newValue?.value;
          obj.is_delete = 0;
          this.handleListEdit("crew_member_id", newValue?.value, obj, "assistant_list", this.state?.currentIndex);
          this.handleListEdit("assistant_name", newValue?.text, obj, "assistant_list", this.state?.currentIndex);
          this.handleListEdit("selectedCrew", { value: newValue?.value, text: newValue?.text }, obj, "assistant_list", this.state?.currentIndex)
        })
      } else {
        if (field === "crew") {
          this.setState({ selectedCrew: newValue, selectedCrewNew: null }, () => {
            //this.getCrewDetails();
            obj = {};
            obj.assistant_name = newValue?.text;
            obj.crew_member_id = newValue?.value;
            obj.is_delete = newValue?.is_delete ? newValue?.is_delete : 0;
            this.handleListEdit("crew_member_id", newValue?.value, obj, "assistant_list", index);
            this.handleListEdit("assistant_name", newValue?.text, obj, "assistant_list", index);
            this.handleListEdit("selectedCrew", { value: newValue?.value, text: newValue?.text }, obj, "assistant_list", index)
          })
        }
      }
    }
    //  else {
    //   if (field === "crew") {
    //     this.setState({ selectedCrew: newValue, selectedCrewNew: null }, () => {
    //       //this.getCrewDetails();
    //       // obj.assistant_name = null;
    //       // obj.crew_member_id = null;
    //       obj.is_delete = 1;
    //       //this.handleListEdit("crew_member_id", null, obj, "assistant_list", index);
    //       //this.handleListEdit("assistant_name", null, obj, "assistant_list", index);
    //       this.handleListEdit("is_delete", 1, obj, "assistant_list", index);
    //       this.handleListEdit("selectedCrew", { value: null, text: null }, obj, "assistant_list", index)
    //     })
    //   }

    // }

    // if(newValue) {
    //     let selectedCrewData = this.state.crewOptions?.find(item => item.value === newValue.value);
    //     this.setState({ selectedCrew: newValue, selectedCrewData: selectedCrewData })
    // }
    // else {
    //     this.setState({ selectedRep: null })
    // }
  };
  handleListEdit = (field, value, obj, listName, selectedindex, check = "") => {
    let crewDetails = (this.state.crewDetails);
    
    let id = listName === "email_list" ? "email_id" : listName === "phone_list" ? "phone_id" : listName === "assistant_list" ? "crew_assistant_id" : "";
    let list = crewDetails[listName]?.map((item, index) => {
      let currentVal = item;
      if (selectedindex === index) {
        if (field === "is_primary") {
          currentVal[field] = value ? 1 : 0;
        } else {
          currentVal[field] = value;
        }
        return currentVal;
      }
      return currentVal;
    });
    if (check === "Check") {
      this.initializeCountry(listName, list);
    } else {
      this.handleGeneralEdit(listName, list);
    }
  };
  initializeCountry = (field, value) => {
    this.setState(
      (prevState) => ({
        crewDetails: {
          ...prevState.crewDetails,
          [field]: value,
        },
      }),
      () => {
        this.props.setModalList(this.state.crewDetails);
      }
    );
  };
  removeField = (listName, callbackItem) => {
    let crewDetails = { ...this.state.crewDetails };
    if (!callbackItem.is_delete) {
      let index = crewDetails[listName]?.indexOf(callbackItem);
      if (index > -1) {
        crewDetails[listName][index].is_delete = 1;
      }
    } else {
      let list = crewDetails[listName]?.filter(
        (item) => item.index !== callbackItem.index
      );
      crewDetails[listName] = list;
    }
    this.setState({ crewDetails: crewDetails });
  };
  addAdditionalField = (listName) => {
    let crewDetails = { ...this.state.crewDetails };
    switch (listName) {
      case "assistant_list":
        let initialAssistantArray = { ...asistantList };
        let assistant_list = [...crewDetails?.assistant_list];
        initialAssistantArray.canRemove = true;
        assistant_list.unshift(initialAssistantArray);
        crewDetails.assistant_list = assistant_list;
        this.setState({ crewDetails: crewDetails });
        break;
    }
  };
  addRemoveObject(list, value) {
    let objectList = [...this.state?.crewDetails?.[list]];
    objectList = objectList?.filter((i, index) => index != 0);

    let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));

    value.type = 'new'
    value.object_id = Date.now();
    let objList = objectList?.filter((i, index) => i.is_delete != 1);
    value.is_primary = objList?.length == 0 ? 1 : 0
    objectList = [initObjectConfig, ...objectList, value]

    this.handleGeneralEdit(list, objectList)
  }
  onChangeObject(list, id, key, value) {
    let objectList = [...this.state?.crewDetails?.[list]];
    let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
    if (key == 'is_primary') {
      objectList?.map(i => i.is_primary = 0);
      value = 1;
    }
    if (key == "is_delete") {
      value = 1;
    }
    objectList[objectIndex][key] = value;
    this.handleGeneralEdit(list, objectList)
  }

  onChangeHandler = (type, value) => {
    let filteredUser = this.state.filterList
    filteredUser[type] = value
    this.setState({ filterList: filteredUser })
  }

  addFilterChangeHandler = (field, value) => {
    let newEntry = [`${field} :${value}`]
    let filterInputs = this.state.filterInputs
    let check = filterInputs?.filter((item, index) => {
      var keyValue = item.toString().split(":");
      if (keyValue[0].trim() === field) {
        filterInputs[index] = newEntry
        return item = newEntry
      }
      // var name = keyValue[0].trim();
      // var value = keyValue[1].trim().toLowerCase();
    })
    if (check?.length === 0) {
      filterInputs.push(newEntry)
    }
    var keyValuePair;
    filterInputs = filterInputs?.filter((item, index) => {
      keyValuePair = item.toString().split(":");
      if (keyValuePair[1] !== "") {
        return item
      }
    })
    this.setState({ filterInputs: filterInputs })
    let config = projectHistoryConfig
    let list = this.state.backup_crewProjectHistory_list
    let filteredTableData = this.applyFilterTable(config, list, filterInputs, false)
    let data = handlePaginationList(this.state.page, this.state.rowsPerPage, filteredTableData)
    this.setState({ project_history_list: data, current_crewProjectHistory_List: data })
  }

  applyFilterTable = (config, data, filterValues, filtersNone) => {
    var finalList = [];
    var finalResult = data.slice();
    var primaryKey = config.primaryKey;
    if (!filtersNone) {

      filterValues.map((filter) => {
        var keyValue = filter.toString().split(":");
        var name = keyValue[0].trim();
        var value = keyValue[1].trim().toLowerCase();

        var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

        Object.keys(config?.headings).map((item) => {
          if (config?.headings[item].headingLabel == name) {
            index = item;
            return;
          }
        });

        var nodeName = config?.dataNodes[index];
        var filteredList = data?.filter((item) => {
          if (nodeName == "Type") {
            return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
          }
          else {
            return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
          }
        })
        finalResult = filteredList.slice()
      });
    }
    else {
      finalResult = data;
    }
    return finalResult;
  }


  onAddressChange(id, key, value) {
    let addressList =
      this.state?.crewDetails?.address_list?.length > 0
        ? JSON.parse(JSON.stringify(this.state?.crewDetails?.address_list))
        : [];
    let addressIndex = addressList?.findIndex((i) => i.crew_address_id == id);
    if (key == "is_primary") {
      addressList?.map((i) => (i.is_primary = 0));
      addressList[addressIndex][key] = 1;
    } else if (key == "is_delete") {
      addressList[addressIndex][key] = 1;
    } else if (id) {
      addressList[addressIndex] = value;
    } else {
      value.crew_address_id = Date.now();
      value.type = "new";
      value.is_primary = 0;
      // if (addressList.length < 1 && value.is_primary != 1) {
      //   value.is_primary = 1
      // }
      let newAddressList = addressList.filter(item => !item.is_delete)
      if (newAddressList?.length == 0) {
        value.is_primary = 1
      } else if (value["is_primary"] === 1) {
        addressList?.map(i => i.is_primary = 0);
      }
      addressList?.push(value);
    }
    this.handleGeneralEdit("address_list", addressList);
  }
  onAliasSave = (value) => {
    let crewDetails = { ...this.state.crewDetails };
    if (crewDetails.akas.length < 1) {
      value.is_primary = 1
    }
    crewDetails.akas.push(value);
    this.setState({ crewDetails: crewDetails });
  };


  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
      project_history_list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.current_crewProjectHistory_List)
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10), page: 0,
      project_history_list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.current_crewProjectHistory_List)
    });
  }
  getModalContent = () => {
    switch (this.state?.tabValue) {
      case 0:
        return (
          <PersonalTab
            isEditing={this.props?.isEditing}
            crewDetails={this.state.crewDetails}
            postInitiated={this.state.postInitiated}
            handleOnchange={this.handleGeneralEdit}
            isLoading={this.state.isFetchingCrewDetails}
            addCallback={this.addAdditionalField}
            removeCallback={this.removeField}
            handleListEdit={this.handleListEdit}
            getObjectDetails={this.getObjectDetails}
            handleSelectedCrew={this.handleSelectedCrew.bind(this)}
            crewOptions={this.state.crewOptions}
            addRemoveObject={this.addRemoveObject.bind(this)}
            onChangeObject={this.onChangeObject.bind(this)}
            onAddressChange={this.onAddressChange.bind(this)}
            onAliasSave={this.onAliasSave.bind(this)}
            countryOptions={this.state.countryOptions}
            emailOptions={this.state.emailOptions}
            phoneOptions={this.state.phoneOptions}
            socialOptions={this.state.socialOptions}
            occupationOptions={this.state.occupationOptions}
            openCrewPopOver={this.state.openCrewPopOver}
            setCrewPopOver={this.state.setCrewPopOver}
            showCrewModal={this.state.showCrewModal}
            selectedCrew={this.state.selectedCrew}
            origin="fromCrewPersonalTab"
          />
        );
      case 1:
        return <ProjectHistory
          project_history_list={this.state.project_history_list}
          isEditing={this.state?.isEditing}
          onChangeHandler={this.onChangeHandler}
          addFilterChangeHandler={this.addFilterChangeHandler}
          config={this.state.config}
          filterList={this.state.filterList}
          current_crewProjectHistory_List={this.state.current_crewProjectHistory_List}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          navFuns={this.props.navFuns}
          {...this.props} />;
    }
  };

  formatCrewDetails = (postPayload) => {
    let crewPostData = JSON.parse(JSON.stringify(this.state.crewDetails));
    let postJson = JSON.parse(JSON.stringify(postPayload));
    postJson.email_list = postJson.email_list?.slice(1) || [];
    postJson.phone_list = postJson.phone_list?.slice(1) || [];
    postJson.social_list = postJson.social_list?.slice(1) || [];
    if (postJson.email_list?.length > 0) {
      postJson.email_list = postJson.email_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            email_id: i?.object_id,
            email_type: i?.object_type,
            email: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary

          }]
        return acc;
      }, [])
    }
    if (postJson.phone_list?.length > 0) {
      postJson.phone_list = postJson.phone_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            phone_id: i?.object_id,
            phone_type: i?.object_type,
            phone: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary,
            ext: i?.ext ? parseInt(i?.ext) : null

          }]
        return acc;
      }, [])
    }
    if (postJson?.social_list?.length > 0) {
      postJson.social_list = postJson.social_list?.reduce((acc, i) => {
        i.object_id = i?.type == 'new' ? null : i?.object_id
        if (i?.object_id || (!i?.object_id && !i?.is_delete))
          acc = [...acc, {
            crew_social_media_id: i?.object_id,
            social_media_type: i?.object_type,
            social_media_account: i?.value,
            is_delete: i?.is_delete || 0,
            is_primary: i?.is_primary,
            ext: i?.ext ? parseInt(i?.ext) : null

          }]
        return acc;
      }, [])
    }

    postJson.assistant_list = crewPostData?.assistant_list
      ?.filter((i) => i?.assistant_name !== null && i?.crew_member_id !== null)
      .map((i) => ({
        crew_assistant_id: i?.crew_assistant_id,
        crew_member_id: i?.crew_member_id,
        notes: i?.notes,
        assistant_name: i?.assistant_name,
        is_delete: i?.is_delete,
      }));
    postJson.address_list = crewPostData?.address_list?.map((item) => {
      if (item.type === "new") {
        item.crew_address_id = null
      }
      return item;
    })
    postJson.address_list = crewPostData?.address_list
      ?.filter((i) => i?.address !== null)
      .map((item) => ({
        address_type: item?.address_type,
        address: item?.address,
        city: item?.city,
        state: item?.state,
        zip: item?.zip,
        country: item?.country,
        crew_address_id: item?.crew_address_id,
        type: item.new,
        is_primary: item?.is_primary,
        is_delete: item?.is_delete,
      }));
    return postJson;
  };
  validateErrorFlags = (crewDetails) => {
    let canSubmit =
      (crewDetails?.first_name && crewDetails?.last_name) &&
      (crewDetails?.akas?.length !== 0
        ? crewDetails?.akas?.every(
          (aka) => aka?.aka && aka?.aka_first_name && aka.aka_last_name
        )
        : true) &&
      (crewDetails?.social_list?.length !== 0
        ? crewDetails?.social_list?.every(
          (social) =>
            social?.social_media_type && social?.social_media_account
        )
        : true) &&
      (crewDetails.email_list.length !== 0
        ? crewDetails.email_list?.every((email) =>
          email.email && email.email_type ? true : false
        )
        : true) &&
      // crewDetails.phone_list?.every((phone) =>
      //   !phone.phone
      //     ? true
      //     : !phone.phone_id
      //     ? validatePlusCharacter(phone.phone.trim().charAt(0))
      //       ? validateIntlNumber(phone.phone) &&
      //         phone.phone.length <= sizeLimits.phoneLimitIntl
      //       : (validateUSNumber(phone.phone) ||
      //           validatePhoneNumber(phone.phone)) &&
      //         phone.phone.length <= sizeLimits.phoneLimitUS
      //     : true
      // ) && 
      (crewDetails?.address_list.length !== 0 ? crewDetails.address_list?.every((address) =>
        address?.crew_address_id
          ? true
          : address?.address && address?.address_type &&
            address?.country && address?.zip ?
            ((address?.country === "United States" || address?.country === "USA" || address?.country === "US")
              ? (address?.zip.length <= sizeLimits?.intlZipCharacterLimit && (address?.zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(address?.zip) : true)) : true) : true
      ) : true)
      &&
      (crewDetails?.assistant_list.length !== 0
        ? crewDetails?.assistant_list?.every((assistant) =>
          assistant.crew_member_id && assistant.assistant_name ? true : false
        )
        : true);
    return canSubmit;
  };
  checkMultiplePrimary = (list) => {
    let multiplePrimary = list?.filter((item) => item.is_primary === 1);
    return multiplePrimary.length > 1;
  };
  postCrewDetails = (payload) => {
    this.props.setPostFlag(true);
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl + `/casterFeatureCrewDetails`,
      payload,
      1,
      1
    ).then(
      (response) => {
        if (response.data.error) {
          
          this.props.fieldChanged(true, "fail");
          this.props.setPostFlag(false);
        } else {
          this.props.setPostFlag(false);
          this.props.fieldChanged(true, "success");
          if (this.props.isProfileChanged) {
            this.props.uploadProfile(response.data[0].crew_id);
          }
          
          this.setObjectSelected('crew',
            {
              text: `${payload?.first_name ? payload?.first_name + ' ' : ''}${payload?.middle_name ? payload?.middle_name + ' ' : ''}${payload?.last_name ? payload?.last_name + ' ' : ''}${payload?.suffix ? payload?.suffix : ''}`,
              value: response?.data?.[0]?.id,
              aka: `${payload?.first_name ? payload?.first_name + ' ' : ''}${payload?.middle_name ? payload?.middle_name + ' ' : ''}${payload?.last_name ? payload?.last_name + ' ' : ''}${payload?.suffix ? payload?.suffix : ''}`,
              headerName: `${payload?.first_name ? payload?.first_name + ' ' : ''}${payload?.middle_name ? payload?.middle_name + ' ' : ''}${payload?.last_name ? payload?.last_name + ' ' : ''}${payload?.suffix ? payload?.suffix : ''}`,
            })
          this.handleCloseCrewPopOver("fromNewEditCrewAssistant");

          if (this.props?.fetchDetailsCallback) {
            
            this.props?.fetchDetailsCallback();

          }
          console.log("Posted successfully", response);
        }
      },
      (err) => {
        this.props.setPostFlag(false);
        this.props.fieldChanged(true, "fail");
        console.log("Error posting Crew Details data", err);
      }
    );
  };
  handleCloseCrewPopOver = (origin = null) => {
    this.setState({ setCrewPopOver: null, openCrewPopOver: false }, () => {
      if (origin !== "fromNewEditCrewAssistant") {
        this.setNewObjectSelected('crew',
          {
            text: null,
            optionType: 'cancel',
            value: null,
            is_delete: 0,
            origin: "fromNewEditCrewAssistant"
          })
      };
    })
  }
  handleCrewSubmit = () => {
    let crewDetailsPostJson = this.formatCrewDetails(JSON.parse(JSON.stringify(this.state.crewDetails)));

    this.setState({ postInitiated: true }, () => {
      if (this.validateErrorFlags(crewDetailsPostJson)) {
        this.postCrewDetails(crewDetailsPostJson);
      } else {
        console.log("Submit Fail");
      }
    });
  };
  setNewObjectSelected(type, value, isNewCrew = 0) {
    value.optionType = 'save';
    this.handleSelectedCrew(type, value);
  }
  setObjectSelected(type, value) {
    value.optionType = 'edit';
    this.handleSelectedCrew(type, value);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.isEditing !== this.props?.isEditing) {
      
      this.getCrewDetails();
    }
  }
  render() {

    return (
      <div className="CrewContainer">
        <input
          className="hiddenButton"
          type="button"
          ref={this.props.submitButtonRef}
          onClick={() => this.handleCrewSubmit()}
        ></input>
        {this.getModalContent()}
        {
          this.state?.openCrewPopOver &&
          <MainViewModalComponent
            modalName='Add New Project Crew'
            handleClose={this.handleCloseCrewPopOver.bind(this)}
            mode={'new'}
            id={this.state?.projectCrewId || null}
            project_id={this.props?.project_id || null}
            selectedCrew={this.state?.selectedCrew || null}
            open={this.state?.openCrewPopOver}
            headerText={"Crew Record"}
            toggleRefreshPage={this.props?.toggleRefreshPage}
            origin={"fromCrewPersonalTab"}
            isDashboard={true}
            getObjectDetails={this.getObjectDetails.bind(this)}
            setObjectSelected={this.setObjectSelected.bind(this)}
            setNewObjectSelected={this.setNewObjectSelected.bind(this)}
          />
        }
        {this.state.showCrewModal ?
          <ModalComponent
            id={this.state.selectedCrew?.value}
            akas={this.state?.selectedCrew?.aka}
            open={this.state.showCrewModal}
            handleClose={() => this.setState({ showCrewModal: false })}
            tabList={crewNavList}
            headerText={"Crew"}
            mode="edit"
            isProfileDiv={true}
            addButtonText={"New Crew"}
            objectType={'CREW'}
            objectId={this.state?.selectedCrew?.value}
            dataUrl='casterFeatureCrewDetails'
            headerName={this.state.selectedCrew?.headerName}
          /> : null
        }

      </div>
    );
  }
}

export default withUserContext(CrewContainer);