export const reportsList = [
    {
        label: "Billing List",
        index: 0
    },
    {
        label: "Casting Data Report",
        index: 1
    },
    {
        label: "Cast List",
        index: 2
    },
    {
        label: "Contract Status Report",
        index: 3
    },
    {
        label: "Crew List Worksheets",
        index: 4
    },
    {
        label: "Deal Worksheets",
        index: 5
    },
    {
        label: "I9 Status Report",
        index: 6
    },
    {
        label: "Missing Birthdate Report",
        index: 7
    },
    {
        label: "Post Sync Time Log",
        index: 8
    },
    {
        label: "Station 12 Report",
        index: 9
    },
    {
        label: "Voice Over Pay Memo",
        index: 10
    },
]

export const intialReportDetails = {
    project_id: null, 
    union: null,
    union_id: null,
    production_company: null,
    production_company_id: null,
    sort_by_clause: null,
    is_performer_address: 1,
    is_deal_info: 1,
    is_include_unset_contract: 0,
    performer_type: null,
    fee_type: null,
    is_deal_worksheet_schedule_f: 0,
    i9_status: null,
    location: null,
    work_date: null,
    start_date: null,
    last_date: null,
    performer_deal_id: null,
    phone: null,
    stunt_only: 0,
    selectedprojectValue:{},
    selectedTalentValue:{},
    talent_id: null,
    contract_type: null,
    submitted_by: null
}

export const sortList = [
    {label:"Role #", value: "Role #"},
    {label: "Performer Name (A-Z)", value: "Performer Name (A-Z)"},
    {label: "Performer Last Name (Z-A)", value: "Performer Last Name (Z-A)"},
    {label: "Representative Last Name (A-Z)", value: "Representative Last Name (A-Z)"},
    {label: "Billed As Name", value:"Billed As Name"},
    {label: "Main Title", value: "Main Title"},
    {label: "End Title", value: "End Title"},
    {label: "Cut", value: "Cut"},
    {label: "Paid Ads", value: "Paid Ads"}
]