import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import './Reports.scss';
import Production from './Production';
import { saveAs } from 'file-saver';
import ReportButton from './ReportButton';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import { s2ab, genReportName } from '../../Helper';

class CastListFc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectProduction: "",
            postInitiated: false,
            performer_address: 0,
            deal_information: 0,
        }
    }

    handleSubmitCastListFc = (docFlag = 0, excelFlag = 0) => {
        this.setState({ 
            postInitiated: true, 
        });
        let { performer_address, deal_information, selectProduction } = this.state;
        let production_id = selectProduction?.value;

        if (!production_id) {
            return;
        }
        let payload = {
            production_id,
            performer_address,
            deal_information
        }
        let obj = {
            reportName: this.props?.reportDownloadName,
            production: selectProduction?.label,
            season: "",
            episode: "",
            performer: "",
        }
        let reportNameFinal = genReportName(obj)
        this.props.generateReport(this.props?.reportName, payload, reportNameFinal,docFlag, excelFlag)
        this.setState({postInitiated: false})
    }

    handleCheck = (e) => {
        this.setState({postInitiated: false})
        let isChecked = e.target.checked
        if (isChecked) {
            if (e.target.id === "performer_address") {
                this.setState({ performer_address: 1 })
            }
            if (e.target.id === "deal_information") {
                this.setState({ deal_information: 1 })
            }
        } else {
            if (e.target.id === "performer_address") {
                this.setState({ performer_address: 0 })
            }
            if (e.target.id === "deal_information") {
                this.setState({ deal_information: 0 })
            }
        }
    }

    onChangeCallBack = (field, val) => {
        this.setState({
            [field]: val,
            postInitiated: false
        });
    }

    render() {
        return (
            <div>
                <MDBContainer className="reportContainer CastList">
                    <MDBRow>
                        <BasicLabel text={"Options"} />
                    </MDBRow>
                    <MDBRow>
                        <Production
                            productionReq={true}
                            postInitiated={this.state.postInitiated}
                            onChangeCallBack={this.onChangeCallBack}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <BasicLabel text={"Include"} />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="performer_address"
                                label="Performer Address"
                                checked={this.state.performer_address === 1}
                                align="start"
                                onChange={e => this.handleCheck(e)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="deal_information"
                                label="Deal Information"
                                checked={this.state.deal_information === 1}
                                align="start"
                                onChange={e => this.handleCheck(e)}
                            />
                        </MDBRow>
                    </MDBRow>
                    <ReportButton
                        generateExcel={true}
                        generateDoc={true}
                        onClickGenratePdf={this.handleSubmitCastListFc}
                        handleClose={this.props?.handleClose}
                    />
                </MDBContainer>
            </div>
        );
    }
}

export default CastListFc;