import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import './Episode.scss';
import { withUserContext } from '../../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';
import sizeLimits from "../../../Common/SizeLimits.json";
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import { CircularProgress } from '@material-ui/core';
import DateField from '../../../components/SharedComponents/DateField/DateField';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import { initialEpisodeDetails, statusDatesConfig } from '../Config';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import { validateCharNumWithHyphenSlash, getFormattedDate } from '../../../Common/Helper';

class EditEpisode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			episodeDetails: this.formatEpisodeList(JSON.parse(JSON.stringify({ ...initialEpisodeDetails }))),
			isSubmit: false,
			statusDateOptions: [],
			isFetchingEpisodeDetails: false,
			statusDateIndex: 2,
			inActiveStatusDates: [],
			showEndDateError: false,
			showStatusEndDateError: false
		};
	}

	componentDidMount() {
		if (this.props?.episodeId || this.props?.mode === 'Edit') {
			this.getEpisodeDetails();
		} else {
			this.getStatusDates();
		}
	}

	formatEpisodeList = (response) => {
		if (response?.status_dates?.length === 0) {
			response?.status_dates.push(JSON.parse(JSON.stringify({ ...statusDatesConfig })));
		}

		return response;
	}

	getStatusDates = () => {
		CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=STATUS_DATES`,
			this.props?.userContext?.active_tenant?.tenant_id)
			.then(response => {
				let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
				this.setState({ statusDateOptions: formattedList }, () => {
					if (this.props?.mode === 'Edit') {
						let episodeDetails = { ...this.state.episodeDetails }
						episodeDetails?.status_dates?.map(item => {
							item.status = this.state.statusDateOptions?.find(statusDate => statusDate?.label === item.status)?.value
							return item
						})
						this.setState({ episodeDetails: episodeDetails })
					}
				})
			},
				(err) => {
					console.log('Error is fetching details' + err);
				});
	}

	getEpisodeDetails = () => {
		this.setState({ isFetchingEpisodeDetails: true })
		CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetEpisode?episodeId=${this.props?.episodeId}`,
			this.props?.userContext?.active_tenant?.tenant_id)
			.then(response => {
				let episodeDetails = response?.data[0];
				episodeDetails.start_date = episodeDetails.start_date ?
					this.formatDate(getFormattedDate(episodeDetails.start_date, false)) : null;
				episodeDetails.end_date = episodeDetails.end_date ?
					this.formatDate(getFormattedDate(episodeDetails.end_date, false)) : null;
				episodeDetails?.status_dates?.map(item => {
					item.start_date = item.start_date ?
						this.formatDate(getFormattedDate(item.start_date, false)) : null
					item.end_date = item.end_date ?
						this.formatDate(getFormattedDate(item.end_date, false)) : null
					return item;
				})
				this.setState({
					episodeDetails: episodeDetails,
					isFetchingEpisodeDetails: false
				}, () => {
					this.getStatusDates()
				})
			},
				(err) => {
					console.log('Error is fetching Show' + err);
					this.setState({ isFetchingEpisodeDetails: false })
				});
	}

	formatDate(date) {
		return moment(date).format('YYYY-MM-DD');
	}

	handleOnchange = (field, value) => {
		if (field === 'end_date') {
			if (this.formatDate(value) < this.formatDate(this.state.episodeDetails?.start_date)) {
				this.setState((prevState) => ({
					episodeDetails: {
						...prevState.episodeDetails,
						[field]: null,
					},
					showEndDateError: true
				}));
			} else {
				this.setState((prevState) => ({
					episodeDetails: {
						...prevState.episodeDetails,
						[field]: value,
					},
					showEndDateError: false
				}));
			}
		} else {
			this.setState((prevState) => ({
				episodeDetails: {
					...prevState.episodeDetails,
					[field]: value,
				},
			}));
		}
	};

	onChangeStatus = (list, field, value, index) => {
		let episodeData = { ...this.state.episodeDetails }
		if (field === "end_date") {
			if (this.formatDate(value) < this.formatDate(episodeData.status_dates[index]?.start_date)) {
				this.setState({ showStatusEndDateError: true, selectedStatusIndex: index })
				episodeData.status_dates[index][field] = null
			} else {
				this.setState({ showStatusEndDateError: false, selectedStatusIndex: index })
				episodeData.status_dates[index][field] = value
			}
		} else {
			episodeData.status_dates[index][field] = value
		}
		this.setState({ episodeDetails: episodeData })
	}

	canSubmit = (episodeDetails) => {
		let canSubmit = episodeDetails?.gl_code && episodeDetails?.episode_name &&
			(!episodeDetails?.status_dates?.some(statusDate => (!(statusDate.start_date && statusDate?.end_date && statusDate?.status) || false)));
		return canSubmit;
	}

	getFormattedEpisodeDetails = (episodeDetails) => {
		let statusDateTemp = [];
		this.state.inActiveStatusDates?.map(item => {
			item.status = this.state.statusDateOptions?.find(item1 => item1.value === item.status)?.label;
			statusDateTemp?.push(item);
		});
		episodeDetails.status_dates?.map(item => {
			statusDateTemp?.push(item);
		});
		episodeDetails.status_dates = statusDateTemp;
		return episodeDetails;
	}

	handleSubmit = () => {
		this.setState({ postInitiated: true })
		let payload = JSON.parse(JSON.stringify({ ...this.state?.episodeDetails }));
		payload.show_id = this.props?.showId;
		payload.show_season_id = this.props?.showSeasonId;
		payload.status_dates?.map(statusDate => {
			if (statusDate.status) {
				let statusDateLabel = this.state.statusDateOptions?.find(item => item.value === statusDate.status)?.label;
				statusDate.status = statusDateLabel
			}
			return statusDate;
		})
		payload = this.getFormattedEpisodeDetails(payload);
		if (this.canSubmit(payload)) {
			this.setState({ isPosting: true })
			CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostEpisode`,
				payload,
				this.props?.userContext?.active_tenant?.tenant_id)
				.then(response => {
					if (response.data.error) {
						this.setState({ isPosting: false })
						this.props.notificationComponent(true, "fail");
					} else {
						this.props.notificationComponent(true, "success", "show-refresh");
						this.setState({
							isPosting: false,
							postInitiated: false
						})
					}
				},
					(err) => {
						console.log('Error is submitting Episode' + err);
						this.props.notificationComponent(true, "fail");
						this.setState({ isPosting: false, postInitiated: false })
					});
		} else {
			console.log("Error in submitting Episode")
		}
	};

	addCallback = (index) => {
		let episodeDetails = JSON.parse(JSON.stringify({ ...this.state.episodeDetails }));
		let initialStatusDateArray = JSON.parse(JSON.stringify({ ...statusDatesConfig }));
		let status_dates = [...episodeDetails.status_dates];
		initialStatusDateArray.index = this.state.statusDateIndex;
		initialStatusDateArray.canRemove = true;
		status_dates.push(initialStatusDateArray)
		episodeDetails.status_dates = status_dates;
		this.setState({ statusDateIndex: this.state.statusDateIndex + 1, episodeDetails: episodeDetails });
	}

	removeCallback = (listName, callbackItem, index) => {
		this.setState({
			listName: listName,
			callbackItem: callbackItem,
			postInitiated: false,
			index: index
		}, () => {
			if (callbackItem.episode_status_date_id) {
				this.setState({ isOpen: true })
			} else {
				this.deleteRow(listName, callbackItem, 'new');
			}
		})
	}

	deleteRow = (listName, callbackItem, type, index = null) => {
		let episodeDetails = this.state.episodeDetails;
		episodeDetails[listName][this.state.index].is_delete = 1;
		if (type === 'edit') {
			let temp = [];
			this.state.inActiveStatusDates?.map((item) => {
				temp.push(item);
			});
			let statusDateList = episodeDetails[listName].filter(item =>
				(item.is_delete === 1 && item.index === callbackItem.index)
			);
			temp.push(statusDateList[0]);
			this.setState({ inActiveStatusDates: temp })
		}
		let statusDateList = episodeDetails[listName].filter(item =>
			(item.episode_status_date_id !== callbackItem.episode_status_date_id) || (item.index !== callbackItem.index)
		);
		if (statusDateList.length === 0) {
			episodeDetails[listName] = [{ ...statusDatesConfig }];
		} else {
			episodeDetails[listName] = statusDateList;
		}
		this.setState({
			episodeDetails: episodeDetails,
			listName: null,
			callbackItem: null,
			postInitiated: false,
			isOpen: false,
		});
	}

	render() {
		return (
			<div>
				{this.state.isFetchingEpisodeDetails ? (
					<div className="ContentLoader">
						<CircularProgress />
					</div>
				) : (
						<MDBContainer className="EpisodeContainer">
							<MessageModal
								open={this.state.isOpen}
								title={"Delete record"}
								message={"Would you like to delete saved record?"}
								primaryButtonText={"Delete"}
								secondaryButtonText={"Cancel"}
								onConfirm={() => this.deleteRow(this.state.listName, this.state.callbackItem, 'edit')}
								handleClose={(e) => this.setState({ isOpen: false })}
							/>
							<MDBRow>
								<MDBCol md={3}>
									<BasicTextField
										className="text"
										label={'SAP/GL Code'}
										isMandatory={true}
										showMandatory={this.state.postInitiated}
										mandatoryWarning={messages.mandatoryWarning}
										value={this.state.episodeDetails.gl_code}
										onChange={(e) => this.handleOnchange('gl_code', e.target.value)}
									/>
								</MDBCol>
								<MDBCol md={8}>
									<BasicTextField
										className="text"
										label={'Episode Name'}
										isMandatory={true}
										showMandatory={this.state.postInitiated}
										mandatoryWarning={messages.mandatoryWarning}
										value={this.state.episodeDetails.episode_name}
										onChange={(e) => this.handleOnchange('episode_name', e.target.value)}
									/>
								</MDBCol>
							</MDBRow>
							<MDBCol md={6}>
								<BasicTextField
									label="MPM#"
									value={this.state.episodeDetails?.mpm || ""}
									limit={sizeLimits.nameCharacterLimit}
									limitWarning={messages.exceed50CharacterWarning || ""}
									onChange={(e) => {
										validateCharNumWithHyphenSlash(e.target.value) &&
											this.handleOnchange(
												"mpm",
												e.target.value.length > 0 ? e.target.value : null
											);
									}}
								/>
							</MDBCol>
							<MDBRow >
								<MDBCol md={3}>
									<DateField
										id="selectStartDate"
										value={this.state.episodeDetails.start_date || ""}
										label={"Start Date"}
										onChange={(e) =>
											this.handleOnchange(
												"start_date",
												e.target.value ? e.target.value : null
											)
										}
									/>
								</MDBCol>
								<MDBCol md={3}>
									<DateField
										id="selectEndDate"
										value={this.state.episodeDetails.end_date || ""}
										label={"End Date"}
										onChange={(e) =>
											this.handleOnchange(
												"end_date",
												e.target.value ? e.target.value : null
											)
										}
									/>
									{this.state.showEndDateError &&
										<p className="errorText">End Date should be greater than or equal to start date</p>
									}
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBRow>
									<BasicLabel text={"Status Dates"} isMandatory={true} />
								</MDBRow>
								{this.state.episodeDetails?.status_dates?.map((item, index) =>
									<MDBRow md={8} className="status-dates">
										<MDBCol md={3} className="start-date">
											<DateField
												id="selectStartDate"
												value={item.start_date || ""}
												label={index === 0 ? "Start" : ""}
												showMandatory={this.state.postInitiated}
												mandatoryWarning={messages.mandatoryWarning}
												onChange={(e) =>
													this.onChangeStatus("status_dates",
														"start_date",
														e.target.value ? e.target.value : null, index
													)
												}
											/>
										</MDBCol>
										<MDBCol md={3} className="end-date">
											<DateField
												id="selectEndDate"
												value={item.end_date || ""}
												label={index === 0 ? "End" : ""}
												showMandatory={this.state.postInitiated}
												mandatoryWarning={messages.mandatoryWarning}
												onChange={(e) =>
													this.onChangeStatus("status_dates",
														"end_date",
														e.target.value ? e.target.value : null, index
													)
												}
											/>
											{(index === this.state.selectedStatusIndex && this.state.showStatusEndDateError) &&
												<p className="errorText">End Date should be greater than or equal to start date</p>
											}
										</MDBCol>
										<MDBCol md={4}>
											<SelectField
												className={"status"}
												label={index === 0 ? "Status" : ""}
												value={item?.status || ""}
												showMandatory={this.state.postInitiated}
												mandatoryWarning={messages.mandatoryWarning}
												options={this.state.statusDateOptions}
												onChange={(e) => {
													this.onChangeStatus("status_dates", "status", e.target.value, index);
												}}
											/>
										</MDBCol>
										<MDBCol md={2} className={`${index === 0 ? 'firstRowIcons' : 'icons'} status-actions`}>
											{!(this.state.episodeDetails?.status_dates.length === 1) ?
												<RemoveCircleIcon fontSize="small" onClick={() => this.removeCallback('status_dates', item, index)} /> :
												null}
											{this.state.episodeDetails?.status_dates.length - 1 === index ?
												<AddCircleIcon fontSize="small" onClick={() => this.addCallback(index)} /> :
												null}
										</MDBCol>
									</MDBRow>)}
							</MDBRow>

							<MDBRow>
								<MDBCol md={8}></MDBCol>
								<MDBCol>
									<BasicButton
										className="btnwidth"
										variant="contained"
										text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : 'Save'}
										icon={'Save'}
										disabled={!this.canSubmit(this.state.episodeDetails)}
										onClick={() => this.handleSubmit()}
									/>
								</MDBCol>
								<MDBCol>
									<BasicButton
										type="inline"
										variant="outlined"
										text={'Cancel'}
										onClick={() => {
											this.props?.onClose();
										}}
									/>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					)
				}
			</div>
		);
	}
}
export default withUserContext(EditEpisode);
