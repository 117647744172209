export const initialShowDetails = {
    show_config_id: null,
    show_type: null,
    status: null,
    show_name: null,
    production_company: null,
    union: null,
    signatory: null,
    voice_director: null,
    casted_by:null,
    show_notes:null,
    show_configuration:null,
    show_id: null,
    show_season_id: null,
    season_name: null,
    is_pilot: 0,
    start_year: null,
    end_year: null,
    sap_code: null,
    mpm: null,
    season_notes: null,
    signatory_name: null,
    signatory_email: null,
    production_rep_name: null,
    production_rep_email: null,
    executive_name: null,
    executive_email: null,
}

export const productionCompanuConfig = {
    "production_companies_id": null,
    "production_company_name": null,
    "address_1": null,
    "address_2": null,
    "city": null,
    "state": null,
    "zip": null,
    "country": null,
    "phone": null
}

export const initialSeasonDetails = {
    "show_id": null,
    "show_season_id": null,
    "season_name": null,
    "is_pilot": false,
    "mpm": null,
    "season_notes": null,
}

export const initialEpisodeDetails = {
    "sap_code": null,
    "episode_name": null,
    "start_date": null,
    "end_date": null,
    "status_dates": [],
    "show_notes":null,
    "mpm": null,
}

export const statusDatesConfig = {
    "start_date": null,
    "end_date": null,
    "episode_status_date_id": null,
    "status": null,
    "index": 1,
    "is_delete": 0
}

export const talentInitialConfig = {
    "show_id": null,
    "show_season_id": null,
    "talent_id": null,
    "talent_name": null,
    "role_type": null,
    "i9_status": null,
    "sag_status": null,
    "representatives": [],
    "is_delete": 0,
    "talent_notes":null
}

export const repConfig = {
    "performer_representative_id": null,
    "representative_id": null,
    "representative_name": null,
    "company_id": null,
    "company_name": null
}

export const contractInitialConfig = {
    "contract_id": null,
    "performer_id": null,
    "episodes": [],
    "recording_date": null,
    "union": null,
    "production_company": null,
    "contract_date": null,
    "rate_breakdown": null,
    "daily_rate": null,
    "start_date": null,
    "sent_date": null,
    "returned_date": null,
    "tenth_run_buyout": null,
    "compensation": null,
    "miscellaneous": null,
    "send_check_to": "Representative",
    "send_check_to_address": null,
    "send_contract_to": "Representative",
    "agency_address_on_contract": null,
    "rep_email_on_contract": null,
    "rider": null,
    "sag_status": null,
    "i9_status": null,
    "notes": null,
    "characters": [],
    "is_rider": 0,
    "is_already_signed": 0,
    "is_delete": 0,
}

export const contractEpisodeConfig = {
    "episode_id": null,
    "is_delete": 0
}

export const contractCharactersConfig = {
    "contract_character_id": null,
    "name": null,
    "index": null,
    "is_delete": 0
}