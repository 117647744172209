import React, { Component } from 'react'
import { Popover } from '@material-ui/core';
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import './AddGuardian.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import PhoneComponent from '../DefaultPhoneComponent/PhoneComponent';
import InputSelectRadio from '../InputSelectRadioInline/InputSelectRadio';
import { initialObjectConfig } from '../../components/Talent/Config';
import { validateEmail, formatZipCode, checkZipCode } from '../Helper';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea'
import sizeLimits from '../../Common/SizeLimits.json'
import messages from '../../Common/Messages.json'
import { initGuardianDetails } from './Config'
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';
export default class AddGuardian extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPosting: false,
            showError: false,
            guardianDetails: {},
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            showAddress: false

        }
    }
    static getDerivedStateFromProps(props, state) {

    }
    setInitialGuradianDetails() {
        let guardianDetails = JSON.parse(JSON.stringify(this.state?.guardianDetails));
        guardianDetails = { ...initGuardianDetails, ...guardianDetails }
        let emailInitialConfig = JSON.parse(JSON.stringify(initialObjectConfig));
        let phoneInititalConfig = JSON.parse(JSON.stringify(initialObjectConfig));
        if (guardianDetails?.guardian_email_list && guardianDetails?.guardian_email_list?.length > 0) {

            guardianDetails.guardian_email_list = [emailInitialConfig, ...guardianDetails?.guardian_email_list]
        } else {
            guardianDetails.guardian_email_list = [];
            guardianDetails.guardian_email_list.push(emailInitialConfig)
        }
        if (guardianDetails?.guardian_phone_list && guardianDetails?.guardian_phone_list?.length > 0) {

            guardianDetails.guardian_phone_list = [phoneInititalConfig, ...guardianDetails?.guardian_phone_list]
        } else {
            guardianDetails.guardian_phone_list = [];
            guardianDetails.guardian_phone_list.push(phoneInititalConfig)
        }
        // set default Country 
        // let defaultCountry = this.props?.countryOptions?.filter(e => (e?.label == "United States" || e?.label == "USA" || e?.label == "US"))?.[0]?.label || null;
        guardianDetails.guardian_country = guardianDetails?.guardian_country ?  { value: guardianDetails?.guardian_country, text: guardianDetails?.guardian_country } : null
        guardianDetails.guardian_country?.value === 'United States' || guardianDetails?.guardian_country?.value === 'USA' || guardianDetails?.guardian_country?.value === 'US' ? 
        guardianDetails.guardian_state = guardianDetails?.guardian_state ?  { value: guardianDetails?.guardian_state, text: guardianDetails?.guardian_state } : null : null
        this.setState({ guardianDetails: guardianDetails,  showAddress: guardianDetails?.guardian_address_type && guardianDetails?.guardian_address ? true: false })
    }
    componentDidMount() {
        if (this.props?.id && this.props?.mode == 'edit') {
            this.setState({ guardianDetails: this.props?.selectedGuardian }, () => {
                this.setInitialGuradianDetails();
            })
        } else {
            this.setInitialGuradianDetails();
        }

    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }
    addRemoveObject(list, value) {
        let objectList = JSON.parse(JSON.stringify(this.state?.guardianDetails?.[list]));
        objectList = objectList?.filter((i, index) => index != 0);
        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
        let objList = objectList?.filter((i, index) => i.is_delete != 1);
        value.is_primary = objList?.length == 0 ? 1 : 0
        // if (objectList?.length == 0) {
        //     value.is_primary = 1;
        // }
        value.type = 'new'
        value.object_id = Date.now();
        objectList = [initObjectConfig, ...objectList, { ...value }]

        this.onChange(list, objectList)
    }
    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.guardianDetails?.[list]];
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
            value = value == true ? 1 : 0;
        }
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        objectList[objectIndex][key] = value;
        this.onChange(list, objectList)
    }

    onChange(key, value) {
        this.setState((prevState) => ({
            guardianDetails: {
                ...prevState?.guardianDetails,
                [key]: value,
                ...(key == 'guardian_country') && { 'guardian_state': null }    
            }
        }), () => {
            if (key == 'guardian_state' && (this.state.guardianDetails?.guardian_state?.value)) {
                let defaultCountry = this.props?.countryOptions.find(item => {
                    return (item.text === "United States" || item.text === "USA" || item.text === "US")
                })
                this.setState((prevState) => ({
                    guardianDetails: {
                        ...prevState?.guardianDetails,
                        ['guardian_country']: defaultCountry,
                        // ...(field == 'country') && { 'state': null }
                    }
                }))
            }
        })
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }
    validateBeforePost(postJson) {
        let isValid = false;
        isValid = postJson?.relation && postJson?.guardian_first_name && postJson?.guardian_last_name &&
            (postJson?.guardian_email_list?.length > 0 ? postJson?.guardian_email_list?.every(o => o?.object_type && o?.value) : true) &&
            (postJson?.guardian_phone_list?.length > 0 ? postJson?.guardian_phone_list?.every(o => o?.object_type && o?.value) : true) &&
            (this.state.showAddress ? (postJson?.guardian_address_type && postJson?.guardian_address && postJson?.guardian_country && (postJson?.guardian_zip?.length > 0 ? (postJson?.guardian_country === 'United States' || postJson?.guardian_country === 'USA' || postJson?.guardian_country === 'US' ? (postJson?.guardian_zip?.length <= sizeLimits?.intlZipCharacterLimit && (postJson?.guardian_zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(postJson?.guardian_zip) : true)) : true) : true)) : true)

        return isValid;
    }
    getGuardianFullName(item) {
        return `${item?.guardian_first_name ? item?.guardian_first_name : ''} ${item?.guardian_middle_name ? item?.guardian_middle_name : ''} ${item?.guardian_last_name ? item?.guardian_last_name : ''} ${item?.guardian_suffix ? item?.guardian_suffix : ''}`
    }
    postGuardian(type) {
        let postJson = JSON.parse(JSON.stringify(this.state.guardianDetails));

        postJson.guardian_email_list = postJson.guardian_email_list?.slice(1) || [];

        postJson.guardian_phone_list = postJson.guardian_phone_list?.slice(1) || [];
        postJson.guardian_country =  postJson.guardian_country?.value;

        postJson.guardian_country === 'United States' || postJson.guardian_country === 'USA' || postJson.guardian_country === 'US' ? postJson.guardian_state? 
        postJson.guardian_state = postJson.guardian_state?.value  : null : null

        if (!postJson.guardian_address) {
            postJson.guardian_country = null
        }

        this.setState({ isPosting: true, showError: false })
        postJson.guardian_name = this.getGuardianFullName(postJson);

        if (!this.validateBeforePost(postJson)) {
            this.setState({ isPosting: false, showError: true })
            return;
        }
        this.props?.onSave(postJson)
        this.setState({ isPosting: false, showError: false })

    }
    onPopoverClose() {
        this.setState({ guardianDetails: { ...TalentShortDetails } })
    }
    toggleAddress() {
        this.setState((prevState) => ({ ...prevState, showAddress: !prevState?.showAddress }))
    }
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />

                <Popover
                    open={this.props.open}
                    anchorEl={this.props?.setPopOver}
                    onClose={this.onPopoverClose.bind(this)}
                    className='talentPopver'
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{ style: { width: '50%', backgroundColor: '#F2F2F2' } }}
                >
                    <MDBContainer className='NewGuardianContainer'>

                        <MDBRow>
                            <MDBCol md={12}>
                                <div>Enter Guardian(s) information for minor:</div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={2}>
                                <BasicTextField id='relationShipGuardian'
                                    placeholder="Relationship"
                                    value={this.state?.guardianDetails?.relation}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    onChange={(e) => this.onChange('relation', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id='firstNameGuardian'
                                    placeholder="First Name"
                                    value={this.state?.guardianDetails?.guardian_first_name}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    onChange={(e) => this.onChange('guardian_first_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="middleNameGuardian"
                                    placeholder="Middle Name"
                                    value={this.state?.guardianDetails?.guardian_middle_name}
                                    onChange={(e) => this.onChange('guardian_middle_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField id="lastNameGuardian"
                                    placeholder="Last Name"
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    value={this.state?.guardianDetails?.guardian_last_name}
                                    onChange={(e) => this.onChange('guardian_last_name', e.target?.value)}
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicTextField id="suffix"
                                    placeholder="suffix"
                                    value={this.state?.guardianDetails?.guardian_suffix}
                                    onChange={(e) => this.onChange('guardian_suffix', e.target?.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-3'>
                            <MDBCol md={6}>
                                {this.state?.guardianDetails?.guardian_phone_list?.filter(i => !i?.is_delete)?.map((item, index) => (
                                    <PhoneComponent
                                        key={item?.object_id + index}
                                        radioGroupName='talentGuardianPhoneRadio'
                                        isEditing={this.props?.isEditing}
                                        showAsLabel={!this.props?.isEditing}
                                        objectItem={item}
                                        ext={true}
                                        addCallback={(obj) => this.addRemoveObject('guardian_phone_list', obj)}
                                        removeCallback={(id, key, value) => this.onChangeObject('guardian_phone_list', id, key, value)}
                                        editCallback={(id, key, value) => this.onChangeObject('guardian_phone_list', id, key, value)}
                                        selectOptions={this.props?.phoneOptions}
                                        showAdd={index == 0 ? true : false}
                                        showRemove={index != 0 ? true : false}
                                        showRadio={index != 0 ? true : false}
                                        isMandatory={true}
                                        showMandatory={this.state?.showError}
                                    />
                                ))}
                            </MDBCol>
                            <MDBCol md={6}>
                                {this.state?.guardianDetails?.guardian_email_list?.filter(i => !i?.is_delete)?.map((emailItem, index) => (
                                    <InputSelectRadio
                                        key={emailItem?.object_id + index}
                                        radioGroupName='talentGuardianEmailRadio'
                                        inputPlaceholder="Enter Email"
                                        // showAsLabel={index == 0 ? false : !this.props?.isEditing}
                                        isEditing={true}
                                        objectItem={emailItem}
                                        addCallback={(emailObj) => this.addRemoveObject('guardian_email_list', emailObj)}
                                        removeCallback={(id, key, value) => this.onChangeObject('guardian_email_list', id, key, value)}
                                        editCallback={(id, key, value) => this.onChangeObject('guardian_email_list', id, key, value)}
                                        selectOptions={this.props?.emailOptions}
                                        showAdd={index == 0 ? true : false}
                                        showRemove={index != 0 ? true : false}
                                        showRadio={index != 0 ? true : false}
                                        inputValidateMethod={validateEmail}
                                        isMandatory={true}
                                        showMandatory={this.state?.showError}
                                    />
                                ))}

                            </MDBCol>
                        </MDBRow>

                        <MDBRow className='mt-3'>
                            {this.state?.showAddress &&
                                <>
                                    <MDBCol md={5}>
                                        <SelectField
                                            id={"type"}
                                            isMandatory={true}
                                            showMandatory={this.state?.showError}
                                            value={this.state?.guardianDetails?.guardian_address_type}
                                            placeHolderText={"Type"}
                                            onChange={(e) => this.onChange("guardian_address_type", e.target.value)}
                                            options={this.props?.addressOptions || []}
                                            disabled={this.props.disabled || false}
                                        />
                                        <BasicTextArea
                                            id={"addressLine"}
                                            value={this.state?.guardianDetails?.guardian_address}
                                            limit={sizeLimits.addressCharacterLimit}
                                            limitWarning={messages.exceed100CharacterWarning || ""}
                                            placeholder={"Address"}
                                            isMandatory={true}
                                            showMandatory={this.state?.showError}
                                            showAsLabel={this.props.disabled || false}
                                            onChange={(e) => this.onChange("guardian_address", e.target.value.length > 0 ? e.target.value : null)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <BasicTextField
                                                    id="guardiancity"
                                                    placeholder={"City"}
                                                    value={this.state?.guardianDetails?.guardian_city}
                                                    onChange={(e) => this.onChange('guardian_city', e.target?.value)}
                                                />
                                            </MDBCol>

                                            <MDBCol md={6} className="guardianState">
                                                {(this.state.guardianDetails?.guardian_country?.value == "United States" || this.state.guardianDetails?.guardian_country?.value == "USA" || this.state.guardianDetails?.guardian_country?.value == "US" ||  !this.state.guardianDetails?.guardian_country?.value) ?
                                                    <SearchSelectField
                                                        id={"guardianstate"}
                                                        // disabled={this.props.disabled || false}
                                                        value={this.state?.guardianDetails?.guardian_state || []}
                                                        options={this.props?.stateOptions || []}
                                                        onChange={(e, value) => this.onChange('guardian_state', value)}
                                                        width={'100%'}
                                                        // showAsLabel={this.props.disabled || false}
                                                        // label={"State"}
                                                        placeholder={"State"}
                                                    />
                                                    // <SelectField
                                                    //     id="guardianstate"
                                                    //     placeholder={"State"}
                                                    //     options={this.props?.stateOptions}
                                                    //     value={this.state?.guardianDetails?.guardian_state}
                                                    //     onChange={(e) => this.onChange('guardian_state', e.target?.value)}
                                                    // /> 
                                                    :
                                                    <BasicTextField
                                                        id="guardianstate"
                                                        placeholder={"State"}
                                                        value={this.state?.guardianDetails?.guardian_state}
                                                        onChange={(e) => this.onChange('guardian_state', e.target?.value)}
                                                    />
                                                }
                                            </MDBCol>
                                            <MDBCol md={4}>
                                                <BasicTextField
                                                    id="guardianzip"
                                                    placeholder={"Zip"}
                                                    value={this.state?.guardianDetails?.guardian_zip}
                                                    onChange={(e) => (this.state?.guardianDetails?.guardian_country?.value == 'United States' ||this.state?.guardianDetails?.guardian_country?.value == 'USA' || this.state?.guardianDetails?.guardian_country?.value == 'US') ? this.onChange('guardian_zip', formatZipCode(e.target.value)) :
                                                    this.onChange('guardian_zip', e.target.value)}
                                                    inValidInput={messages.invalidInput}
                                                    fieldValid={this.state?.guardianDetails?.guardian_zip ? ((this.state?.guardianDetails?.guardian_country?.value == 'United States' ||this.state?.guardianDetails?.guardian_country?.value == 'USA' || this.state?.guardianDetails?.guardian_country?.value == 'US') ? (this.state?.guardianDetails?.guardian_zip?.length > 9 ? !checkZipCode(this.state?.guardianDetails?.guardian_zip) ? true : false : false) : false) : false}
                                                    // onChange={(e) => this.onChange('guardian_zip', e.target?.value)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={8} className="guardianCountry">
                                                <SearchSelectField
                                                    id={"guardiancountry"}
                                                    // disabled={this.props.disabled || false}
                                                    value={this.state?.guardianDetails?.guardian_country || []}
                                                    options={this.props?.countryOptions || []}
                                                    onChange={(e, value) => this.onChange('guardian_country', value)}
                                                    width={'100%'}
                                                    showMandatory={this.state?.showError}
                                                    isMandatory={true}
                                                    // showAsLabel={this.props.disabled || false}
                                                    // label={"Country"}
                                                    placeholder={"Country (required)"}
                                                />
                                                {/* <SelectField
                                                    id="guardiancountry"
                                                    placeholder={"Country (required)"}
                                                    options={this.props?.countryOptions}
                                                    isMandatory={true}
                                                    showMandatory={this.state?.showError}
                                                    value={this.state?.guardianDetails?.guardian_country}
                                                    onChange={(e) => this.onChange('guardian_country', e.target?.value)}
                                                /> */}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </>
                            }


                            <MDBCol md={2}>
                                <div className='addLabel' id="newAddressBtn" onClick={() => this.toggleAddress()}>
                                    <span>
                                        {this.state?.showAddress ? 'Remove Address' : 'Add Address'}
                                    </span>
                                </div>
                            </MDBCol>
                        </MDBRow>


                        <MDBRow className='mt-3'>
                            <MDBCol md={8}></MDBCol>

                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : 'save'}
                                    disabled={this.state?.isPosting}
                                    icon={"save"}
                                    onClick={this.postGuardian.bind(this, 'save')}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleClose} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Popover>
            </>
        )
    }
}
