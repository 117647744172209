import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import './ProfilePictureUpload.scss';
import { withUserContext } from "../../../../contexts/UserContext"
import defaultImg from '../../../assets/defaultUserImg.png';
class ProfilePictureUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navObj: {},
            invalidImageType: false
        }
        this.myRef = React.createRef();
    }
    static getDerivedStateFromProps(props, state) {
        if ((JSON.stringify(props.navObj) !== JSON.stringify(state.navObj) && props.navObj)) {          
            return {
                navObj: props.navObj || {}
            }
        }
        return null;
    }
    componentDidMount() {
        console.log("Loaded Profile");
        if (this.props.mode !== "new" && this.props.imgMode === "download") {
            if (this.props?.navObj) {
                let { talent_id,representative_id,crew_id } = this.props?.navObj;
                let id = this.props?.headerText === "Talent" ? talent_id: this.props?.headerText === "Representatives" ? representative_id : this.props?.headerText === "Crew"? crew_id : null ;
                let objectType = this.props?.headerText;                    
                this.props.uploadProfile(id,objectType);
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.navObj !== this.state.navObj) {         
            console.log("ProfilePictureUpload Updated");
            if (this.props.mode !== "new" && this.props.imgMode === "download" && !this.props.isProfileChanged) {
                if (this.props?.navObj) {
                    let { talent_id,representative_id,crew_id } = this.props?.navObj;
                    let id = this.props?.headerText === "Talent" ? talent_id: this.props?.headerText === "Representatives" ? representative_id : this.props?.headerText === "Crew"? crew_id : null ;
                    let objectType = this.props?.headerText;                    
                    this.props.uploadProfile(id,objectType);
                }
            }
            
        }
        if (this.state.invalidImageType) {
            setTimeout(()=>{
                this.setState({ invalidImageType: false })
            },10000);
        }
    }

    imageHandler = e => {
        const reader = new FileReader();
        try {
            let file = e.target.files[0];
            if(file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/gif"){
                this.props?.handleProfileImg(null)
                this.props?.handleProfileChange(false);
                this.setState({ invalidImageType: true })
               
                return;
            }
            reader.onload = () => {
         
                if (reader.readyState === 2) {
                    // this.setState({ profileImg: reader.result, isProfileChanged: true });
                    this.props?.handleProfileImg(reader.result)
                    this.props?.handleProfileChange(true);
                    this.props?.handleImageType(file.type);
                    // this.props.onFileUpload(reader.result);
                   
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            this.props?.handleProfileImg(null)
            this.props?.handleProfileChange(false);
        }
      
    }  
    
    onProfileChangeClick = () => {
        if(!this.props?.isUpload){
            return;
        }
        this.myRef.current.click();
        // this.setState({ imgMode: 'upload' })
        this.props?.handleImgMode('upload');
    }

    onError = (e) => {       
        // this.props?.handleProfileImg(null)       
        e.target.src= defaultImg;
    }  

    render() {
        return (
            <>
                    <MDBContainer fluid className="Profile-Picture-Container">
                        <MDBRow className="justify-content-md-center">
                            <MDBCol className="profilePic">
                                <img 
                                src={this.props?.profileImg || defaultImg}
                                alt="profile image" 
                                id="img" className="img" 
                                onError={this.onError}
                                onClick={() => this.onProfileChangeClick()} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <input
                                type="file"
                                ref={this.myRef}
                                accept=".jpg, .jpeg"
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                onChange={this.imageHandler}
                            />
                        </MDBRow>
                        {this.state.invalidImageType &&
                            <div className="errorText textAlignCenter">
                                {"Please upload an image file"}
                            </div>
                        }
                    </MDBContainer>
               
            </>
        );
    }
}

export default withUserContext(ProfilePictureUpload);