import React from 'react';
import Tooltip from '@mui/material/Tooltip';
//import { tooltip: {sx: {marginTop:'8px'},}, } from '../Dashboard/config';
import CircularProgress from "@material-ui/core/CircularProgress";
import { MDBIcon } from "mdbreact";
import { MDBTooltip } from 'mdb-react-ui-kit';



class TableColumnExpand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       tooltipIsOpen : false,
       showTooltip: false,
       tooltipText: '',
       tooltipPosition: { top: 0, left: 0 },
       show:false,
       tooltipIndex:0
   //   tooltipTitle:''
    }
    this.attri = '';
    this.textRef = React.createRef();    
  }
  

  setShow(flag,event,index){
    const element = event.target;
    console.log(index);
    console.log(event)
    console.log( element?.scrollWidth,element?.clientWidth)
    const isTextTruncated = element?.scrollWidth > element?.clientWidth;
    if(isTextTruncated){
      console.log('truncated',element.innerText)
      this.setState({show:flag,tooltipText: element.innerText,tooltipIndex:index});
    }else{
      console.log('nottruncated',element.innerText)
    }
   
  }
  setHide(flag,event,index){
    this.setState({show:flag,tooltipText: '',tooltipIndex:index});
  }
  
  isValueContainsArray(value) {
    if (Array.isArray(value)) {
      return true;
    } else {
      return false;
    }
  }

  expandButton(dataItem, dataNode, rowIndex) {
    return <div className='expandClick col-md-auto'><a href="javascript:void(0);"
      onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, 'expand', rowIndex, null, e, this.props.projectId)}>
      <p title="expand">
        {dataItem['expand']?.toLowerCase()}
        {/* {dataItem['expand']?.toLowerCase() === '>>' && <MDBIcon icon="angle-double-right" size='xs'></MDBIcon>}
      {dataItem['expand']?.toLowerCase() === '<<' && <MDBIcon icon="asterisk" size='xs'></MDBIcon>} */}
      </p>
    </a>
    </div>
    
  }
 
  escapeHtmlRender(value, node, dataItem, rowIndex) {
    switch (node.dataNode) {
      case 'project_name':
        return value?.toString()?.split('|').map((element, index) => {
          const pValue =  element;
          const pTitleValue = element;
          return (element?.toLowerCase() != 'null') ?
            node.hyperLink && index === 0 ?
            <div className="tooltipParent"             
            onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
            onMouseLeave={(e) => this.setHide(false,e,index)}
          >
              <Tooltip   open={this.state.show && this.state.tooltipIndex === index} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} 
                title={pTitleValue}>
                <a onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode, null, e, this.props.projectId)} className="blue cursor-pointer">
                  <span>{pTitleValue}</span>
                </a>
              </Tooltip>
              </div>
              :
              <div className="tooltipParent"             
              onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
              onMouseLeave={(e) => this.setHide(false,e,index)}
            >
              <Tooltip   open={this.state.show && this.state.tooltipIndex === index}
                clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} 
                title={pValue.replace('pka','')}>
                <span className='darkGray alias'>
                    { pValue.replace('pka','')}                   
                   </span>
                   </Tooltip> </div>
            : '';
        })
        break;
      case 'performer_name':
        const performerName = value?.toString()?.split('|');
        const pName =   performerName[0];
        const pAlias =  performerName[1];
        return <div className='performerName'>{
          (performerName[0]?.toLowerCase() != 'null') &&
          <> <div className='performerClick col-md-10'>
            {node.hyperLink ?
            <div className="tooltipParent"             
            onMouseOver={(e) => !this.state.show && this.setShow(true,e,0)}
            onMouseLeave={(e) => this.setHide(false,e,0)}
          >
              <Tooltip   open={this.state.show && this.state.tooltipIndex === 0} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={pName}>
                <a href="javascript:void(0);" className="blue cursor-pointer" onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode, null, e, this.props.projectId)}>
                  <span >{pName}</span></a>
              </Tooltip></div>
              : <span >{pName}</span>}
            {(pAlias?.toLowerCase() != 'null') && 
            <><div className="tooltipParent"             
            onMouseOver={(e) => !this.state.show && this.setShow(true,e,1)}
            onMouseLeave={(e) => this.setHide(false,e,1)}
          >
              <Tooltip   open={this.state.show && this.state.tooltipIndex === 1} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={pAlias}>
            <span  className='darkGray alias'>{pAlias}</span>
            </Tooltip></div></>
            }
          </div>
          </>
        } 
          <> {this.expandButton(dataItem, node.dataNode, rowIndex)}</>
        </div>
        break;

      default:
        if (this.isValueContainsArray()) {
          value?.map((item,index) => {
            const oValue =  item;
            return (item?.toLowerCase() != 'null') ?
              node.hyperLink ?
              <div className="tooltipParent"             
              onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
              onMouseLeave={(e) => this.setHide(false,e,index)}
            >
              <Tooltip   open={this.state.show && this.state.tooltipIndex === index} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={oValue}>
                  <a onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode, null, e, this.props.projectId)} className="blue cursor-pointer">
                    <span ref={this.textRef}>{oValue}</span>
                  </a>&nbsp;
                  { item?.is_primary === 1 && node.dataNode==='representative_name' && <MDBIcon icon="star" size='xs' className='blue primaryRep '></MDBIcon>}
                  </Tooltip> </div>:
                  <div className="tooltipParent"             
                  onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
                  onMouseLeave={(e) => this.setHide(false,e,index)}
                >
                  <Tooltip   open={this.state.show && this.state.tooltipIndex === index}
               clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={oValue}>
                  <span ref={this.textRef}>{oValue}&nbsp;
                  { item?.is_primary === 1 && node.dataNode==='representative_name' && <MDBIcon icon="star" size='xs' className='blue primaryRep '></MDBIcon>}
                  </span>
                </Tooltip></div>
              : '';
          }
          )
        } else {
          if (typeof value === 'object') {
            return value?.map((item,index) => {           
              const occValue =  item?.[node.dataNode];

              return (item?.[node.dataNode]?.toLowerCase() != 'null') ?
                node.hyperLink ?
                <div className="tooltipParent"             
                  onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
                  onMouseLeave={(e) => this.setHide(false,e,index)}
                >
                  <Tooltip   open={this.state.show && this.state.tooltipIndex === index} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={occValue}>
                    <a href="javascript:void(0);" class="blue cursor-pointer" onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode, null, e, this.props.projectId)}>
                      <span ref={this.textRef}>{occValue}</span></a>&nbsp;
                      { item?.is_primary === 1 && node.dataNode==='representative_name' && <MDBIcon icon="star" size='xs' className='blue primaryRep '></MDBIcon>}
                      </Tooltip> </div>:
                  <div className="tooltipParent"             
                  onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
                  onMouseLeave={(e) => this.setHide(false,e,index)}
                >
                  <Tooltip   open={this.state.show && this.state.tooltipIndex === index} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={occValue}>
                    <span ref={this.textRef}>{occValue}&nbsp;
                    { item?.is_primary === 1 && node.dataNode==='representative_name' && <MDBIcon icon="star" size='xs' className='blue primaryRep '></MDBIcon>}
                    </span>
                  </Tooltip></div>
                : '';
            })
          } else {            
            return value?.toString()?.split('|').map((element,index) => {
              const nValue =  element;
              return (element?.toLowerCase() != 'null') ?
                node.hyperLink ?
                <div className="tooltipParent"             
                  onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
                  onMouseLeave={(e) => this.setHide(false,e,index)}
                >
                  <Tooltip   open={this.state.show && this.state.tooltipIndex === index}
                 clickable arrow componentsProps={{tooltip: {sx: {marginTop:'8px'},}, }} title={nValue}>
                    <a onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode, null, e, this.props.projectId)} className="blue cursor-pointer">
                      <span ref={this.textRef}>{nValue}</span></a>
                      </Tooltip> </div>:
                  <div className="tooltipParent"             
                  onMouseOver={(e) => !this.state.show && this.setShow(true,e,index)}
                  onMouseLeave={(e) => this.setHide(false,e,index)}
                >
                  <Tooltip   open={this.state.show && this.state.tooltipIndex === index} clickable arrow componentsProps={{ tooltip: {sx: {marginTop:'8px'},}, }} title={nValue}>
                    <span ref={this.textRef}>{nValue}</span>
                  </Tooltip></div>
                : '';
            })
          }
        }
    }
  }

  render() {
    return (
     <> {
        this.props?.node?.dataNode !=='expand' && <div id="alignData" className="alignData" >   
         {/* {this.state.showTooltip && 
                       <div className='CustomTooltip' style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px'
                      }} >{this.state.tooltipText}</div>}     */}
        {this.props.value && this.escapeHtmlRender(this.props.value, this.props.node, this.props.dataItem, this.props.rowIndex)}
      </div>
    }</>
      )
  }
}



export default TableColumnExpand;