import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import moment from 'moment';
import TableComponent from '../../components/SharedComponents/Table';
import { WorkActivityConfig, workActivityPayload } from './ShowSeasonConfig';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { getFormattedDate, adjustTime, formatDate } from '../../Common/Helper';

class WorkActivityDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: WorkActivityConfig,
            wrapError: "",
            wrapInlineError: "",
            errorIndex:null
        }
    }

    componentDidMount() {
        this.getWorkActivityDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.contractId !== this.props?.contractId) {
            this.getWorkActivityDetails();
        }
    }
    
    getWorkActivityDetails = () => {
        this.setState({ isFetchingWorkActivities: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetWorkActivity?contractId=${this.props?.contractId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let workActivityList = response?.data;
                workActivityList?.map(item => {
                    // item.work_date = item.work_date ? moment(item.work_date).format("MM/DD/YYYY") : null
                    item.work_date = item.work_date ? getFormattedDate(item.work_date) : null
                    item.on_set = item.on_set ? item.on_set : null
                    item.wrap = item.wrap ? item.wrap : null
                    return item
                })
                this.setState({
                    workActivityList: workActivityList,
                    isFetchingWorkActivities: false
                }, () => {
                    this.getLookupData("WORK_ACTIVITY_CODE", "activityOptions");
                })
            },
                (err) => {
                    console.log('Error is fetching Show' + err);
                    this.setState({ isFetchingWorkActivities: false })
                });
    }

    getLookupData = (entity, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entity}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data;
                formattedList = formattedList?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item.is_active, description: item.lookup_value2 }));
                this.setState({ [optionName]: formattedList }, () => {
                    let workActivityList = [...this.state.workActivityList]
                    let config = this.state.config;
                    config.headings[1].selectOptions = this.state.activityOptions;
                    workActivityList?.map(item => {
                        item.activity_code = config?.headings[1]?.selectOptions?.find(item1 => item1.label === item.activity_code)?.value;
                        return item;
                    })
                    this.setState({ config: config, workActivityList: workActivityList })
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleAddNewItem = (dataItem) => {
        if (!dataItem || Object.entries(dataItem).length === 0) {
            return;
        }
        let newDataItem = JSON.parse(JSON.stringify({ ...workActivityPayload })); 
        newDataItem.episode_id = this.props?.episodeId;
        newDataItem.contract_id = this.props?.contractId;
        newDataItem.performer_id = this.props?.performerId;
        newDataItem.work_date = dataItem?.work_date;
        newDataItem.activity_code = this.state.config?.headings[1]?.selectOptions?.find(item => item.value === dataItem.activity_code)?.label;
        newDataItem.activity_description = dataItem?.activity_description;
        newDataItem.on_set = dataItem?.on_set;
        newDataItem.wrap = dataItem?.wrap;

        if(newDataItem.wrap < newDataItem.on_set){
            this.setState({wrapError:'Wrap Time must be greater than Onset Time'})
            return;
        }else{
            this.setState({wrapError:''})
        }
        newDataItem.memo_text = dataItem?.memo_text;

        this.setState({ WorkActivityDetails: newDataItem }, () => {
            this.handleSubmit();
        })
    }

    handleInlineEdits = (id, dataItem,isMinimize) => {
        let workActivityList = [...this.state.workActivityList];
        workActivityList?.map(item => {
            if (item.contract_work_activity_id === dataItem?.contract_work_activity_id) {
                item.work_date = isMinimize?formatDate(item.work_date): ( item.work_date ? moment(item.work_date).format('YYYY-MM-DD') : null);
                item.on_set = item.on_set ? item.on_set : null;
                item.wrap = item.wrap ? item.wrap : null;
                this.setState({wrapInlineError:''})
                item['editing'] = isMinimize?false:true
            }
            return item;
        })
        this.setState({ workActivityList: workActivityList })
    }

    saveInlineEdits = (listEdits, rowIndex, id, dataItem) => {
        let workActivityList = [...listEdits]
        let updatedItem = listEdits?.find(item => item.contract_work_activity_id === dataItem?.contract_work_activity_id);
        updatedItem.on_set=updatedItem.on_set?updatedItem.on_set : null;
        updatedItem.wrap=updatedItem.on_set?updatedItem.wrap : null;
        if(updatedItem.wrap < updatedItem.on_set){
            this.setState({wrapInlineError:'Wrap must be greater than Onset'})
            this.setState({errorIndex:rowIndex})
            return;
        }else{
            this.setState({wrapInlineError:''})
        }
        updatedItem.activity_code = this.state.config?.headings[1]?.selectOptions?.find(item => item.value === updatedItem.activity_code)?.label;
        this.setState({ WorkActivityDetails: updatedItem }, () => {
            this.handleSubmit();
        })
    }

    handleInlineDelete = (id, dataItem) => {
        let deletingItem = {...dataItem};
        deletingItem.activity_code = this.state.config?.headings[1]?.selectOptions?.find(item => item.value === deletingItem.activity_code)?.label;
        deletingItem['is_delete'] = 1;
        this.setState({ WorkActivityDetails: deletingItem }, () => {
            this.handleSubmit();
        })
    }

    handleSubmit = () => {
        let payload = JSON.parse(JSON.stringify({ ...this.state.WorkActivityDetails }));
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/animationPostWorkActivity`,
            payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false })
                } else {
                    // this.props.fieldChanged(true, "success", "show-refresh");
                    this.getWorkActivityDetails();
                }
            },
                (err) => {
                    console.log('Error is submitting Work activity' + err);
                    // this.props.fieldChanged(true, "fail");
                    this.setState({ isPosting: false })
                });
    }

    render() {
        return (
            <MDBContainer fluid className="workActivityContainer">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow className="work-activity-table">
                                <TableComponent
                                    id={"work-activity"}
                                    list={this.state.workActivityList || []}
                                    config={this.state.config}
                                    isLoading={this.state.isFetchingWorkActivities}
                                    handleAddNewItem={this.handleAddNewItem.bind(this)}
                                    editCallback={this.handleInlineEdits.bind(this)}
                                    saveCallback={this.saveInlineEdits.bind(this)}
                                    handleDelCallBack={this.handleInlineDelete.bind(this)}
                                    addItemToList={true}
                                    checkColumnUpdate={'activity_code'}
                                    updateColumnData={'activity_description'}
                                    WrapError={this.state.wrapError}
                                    WrapInlineError={this.state.wrapInlineError}
                                    errorIndex={this.state.errorIndex}
                                />
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        )
    }
}

export default WorkActivityDetails;